import axios from "axios";
let PQR_API_URL;
let MASTER_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  PQR_API_URL = process.env.REACT_APP_PQR_API_URL;
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  PQR_API_URL = process.env.REACT_APP_API_URL + "pqr";
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getGridHeaderData(gridParameter) {

  let url = `${PQR_API_URL}/PqrHeaderMast/gridPQR`;


  const response = await axios
    .post(url, gridParameter)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getbyprojectno = async (reqParam) => {
  let url = `${PQR_API_URL}/PqrHeaderMast/getprojectnoPQR`;

  const response = await axios
    .post(url, reqParam)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};

export const getPQRonEdit = async (reqData) => {
  let url = `${PQR_API_URL}/PqrHeaderMast/getPQRonEdit`;


  const response = await axios
    .post(url, reqData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLgrid = async (image) => {
  let url = `${MASTER_API_URL}/file/pqr_groove/${image}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLgriddelete = async (image) => {
  let url = `${MASTER_API_URL}/file/pqr_groove_delete/${image}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddNewPQR = async (WPQ_Header_Master) => {
  let url = `${PQR_API_URL}/PqrHeaderMast`;

  const response = await axios
    .post(url, WPQ_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdatePQRHeader = async (PQR_Header_MasterUpdate) => {
  let url = `${PQR_API_URL}/PqrHeaderMast/UpdatePQRHeader`;

  const response = await axios
    .post(url, PQR_Header_MasterUpdate)
    .then(function (resp) {

      return resp;
    })
    .catch(function (error) {

      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeletePQR(DeleteRow) {

  let url = `${PQR_API_URL}/PqrHeaderMast/delete`;

  const resp = await axios
    .post(url, DeleteRow)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
