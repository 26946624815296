import { Row, Col } from "react-bootstrap";
export const PQRHeader = ({ headerObj, WPStype }) => {
  var isTubesheet = WPStype === "Tubesheet" ? true : false;

  return (
    <Row style={{padding: "20px 60px", backgroundColor: "var(--bg-alt-2)" }} className="PQR-Page-row-padding">
      <Row className="mb-4">
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>PQR Record No</small>
            <strong>{headerObj?.PQR_RecordNo}</strong>
          </div>
        </Col>
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>Date</small>
           {!isTubesheet ?  (<strong>{headerObj?.PQR_DOB}</strong>) :
            (<strong>{headerObj?.PQR_Date}</strong>) }
          </div>
        </Col>
        {isTubesheet ? (
          <Col md={2}>
            <div className="d-flex flex-column">
              <small>Qualification Code</small>
              <strong>{headerObj?.PQR_Qualificationcode}</strong>
            </div>
          </Col>
        ) : (
          <Col md={2}>
            <div className="d-flex flex-column">
              <small>Revision</small>
              <strong>{headerObj?.PQR_ReviNo}</strong>
            </div>
          </Col>
        )}

        {isTubesheet ? (
          <Col md={2}>
            <div className="d-flex flex-column">
              <small>WPS No</small>
              <strong>{headerObj?.PQR_pWPSNo}</strong>
            </div>
          </Col>
        ) : (
          <Col md={2}>
            <div className="d-flex flex-column">
              <small>WPS Record Number</small>
              <strong>{headerObj?.PQR_WPS_RecordNo}</strong>
            </div>
          </Col>
        )}

        <Col md={2}>
          <div className="d-flex flex-column">
            <small>WPS Revision No</small>
            <strong>{headerObj?.PQR_WPS_ReviNo}</strong>
          </div>
        </Col>

        {isTubesheet ? (
          ""
        ) : (
          <Col md={2}>
            <div className="d-flex flex-column">
              <small>Company Name</small>
              <strong>{headerObj?.PQR_CompanyName}</strong>
            </div>
          </Col>
        )}
      </Row>
    </Row>
  );
};
