const WPS_images = ({pdf,
    Imageheaders, images}) =>{
        return(

        <table className="WPSTable12" style={{border:"0.01px solid black", width:"71.95%",position:"relative"}}>
        <tbody>
        {pdf[0]?.wld_WPSType === "Tubesheet" && (
            
              <><tr >
                            {Imageheaders.map((header, index) => (
                                <th
                                    key={index}
                                    style={{
                                        padding: "1px 4px",
                                        width: "28%",
                                        textAlign: "center",fontFamily:"GE-BodyCopyHighlight",
                                        borderLeft: index === 0 ? "none" : "0.01px dashed black",
                                       
                                    }}
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                        </>)}
                        <tr>
                                {images.map((row, index) => (
                                    <td
                                        key={index}
                                        style={{
                                            width: "30%",
                                            height: "2.5cm",
                                            borderLeft: index === 0 ? "none" : "",

                                            borderTop: "1px solid black"
                                        }} >
                                        {row !== "" && (
                                            <img className="WPSimgsizebig" src={row}  />
                                        )}
                                    </td>
                                ))}
                            </tr>
            
     
        </tbody>
      </table>
      
      
  );
 
}
export default WPS_images;