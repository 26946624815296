import {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useRef,
} from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { SearchSelectProject } from "../utility/search-select";
import { isDuplicate } from "./WPSObjects/WPS_Validations";
import {

   isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import { todayDate, maxDate } from "../utility/CommonComponents/TodayDate";
import {
  Action,
  Status,
  company_name,
  Tubesheet_WPSPage1,
  MessageTypes,
  joint_type,
} from "./WPSObjects/WPSGroove_entity";
import { checkTubeProcessAdded } from "./WPSObjects/WPS_Validations";
import { WPS_Filler_metals } from "./WPSObjects/WPS_filler_metals";
import { WPS_Base_metals } from "./WPSObjects/WPS_base_metals";
import { WPS_Image } from "./WPSObjects/WPS_Image";
import { emptyError, DisableFields } from "./WPSObjects/WPS_common";
import { AddNewDetail } from "../../Services/wps_details-service";
import {
  AddNewCount,
  AddNewGroove,

} from "../../Services/wps_groove-services";
import { getAllProject } from "../../Services/project-services";
import { getActiveAno } from "../../Services/ano-services";

import { getActiveSfa } from "../../Services/sfa-service";
import { getActiveFno } from "../../Services/fno-services";
import axios from "axios";
import { getPresignedURL } from "../../Services/Welder-services";
import { AddLogs } from "../../Services/wps-logs-services";
import { errorstyle, normalstyle } from "../../functions/welding-functions";
import { DotLoader } from "react-spinners";

const WPSTubesheetGeneral = forwardRef(
  (
    {
      headerObj,
      setheaderObj,

      detailObj,
      setdetailObj,

      WPStype,
      setKey,
      tabno,
      setIsEditedPage1,
      logObj,
    },
    ref
  ) => 
    {
    const location = useLocation();
    let Band = sessionStorage.getItem("Band");

    let folderwps = "WPS_Groove";

    let initiator = Band !== "P" && Band !== "T";



    const [loading, setLoading] = useState(false);


    const [ProjectDetails, setProjectDetails] = useState([]);

    const [SfaActive, setSfaActive] = useState([]);
    const [FnoActive, setFnoActive] = useState([]);
    const [AnoActive, setAnoActive] = useState([]);
    const [chemicalAnaError, setChemicalAnaError] = useState([]);
    const [IsEdited, setIsEdited] = useState(false);

    const [profile, setProfile] = useState("");
    const [profile1, setProfile1] = useState("");
    const [profile2, setProfile2] = useState("");

    const [previewUrl, setPreviewUrl] = useState([]);

    const [previewUrl1, setPreviewUrl1] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();

    const [ImageView1, setImageView1] = useState(false);
    const [ImageView2, setImageView2] = useState(false);
    const [ImageView3, setImageView3] = useState(false);



 
    const [classError, setClassError] = useState([]);
    const [action, setaction] = useState(location.state?.action);

    let disableButton =
      initiator && headerObj.WPSH_Status === Status.StatusPending
        ? true
        : false;
    const [isDisabled, setIsDisabled] = useState(disableButton);

    const [Readonly, setReadonly] = useState("");

    let isoverlay = WPStype === "Groove" ? false : true;

  

    let grooveclass = WPStype === "Groove" ? "row-margin" : "d-none";
    let overclass = WPStype !== "Groove" ? "row-margin" : "d-none";

    const FilemaxSize = 1 * 1024 * 1024;

    const [isOverlay, setOverlay] = useState(isoverlay);
    const [Grooveclass, setoverlayclass] = useState(grooveclass);
    const [overlaytext, setoverlaytext] = useState(overclass);



    const [error, setError] = useState({
      WPSH_Project_No: {},
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_SupportingPQR: {},
      WPSH_WPS_Date: {},
      WPSH_RefDoc: {},

      WPSH_BM_BaseMetal1_ProductForm: "",
      WPSH_BM_BaseMetal1_Spec: "",
      WPSH_BM_BaseMetal1_PNo: "",
      WPSH_BM_BaseMetal1_GNo: "",
      WPSH_BM_BaseMetal1_Thick: "",
      WPSH_BM_BaseMetal1_Diam: "",
      WPSH_BM_WeldBaseMetal2_ProductForm: "",
      WPSH_BM_WeldBaseMetal2_Spec: "",
      WPSH_BM_WeldBaseMetal2_PNo: "",
      WPSH_BM_WeldBaseMetal2_GNo: "",
      WPSH_BM_WeldBaseMetal2_Thick: "",
      WPSH_BM_WeldBaseMetal2_Diam: "",
      WPSH_JD_JointType: {},
      WPSH_JD_HoleId: {},
      WPSH_JD_TubePitch: {},
      WPSH_JD_GrooveDepth: {},
      WPSH_JD_TubeProj: {},
      WPSH_JD_WidthLigament: {},
      WPSH_JD_Pattern: {},
      WPSH_JD_GrooveAngle: {},
      WPSH_JD_GrooveType: {},
      WPSH_FM_PreFillerMetal: {},
      WPSH_FM_WithFillerMetal: {},
      WPSH_FM_FillerProduct: {},
      WPSH_FM_Inserts: {},
      WPSH_FM_FillerFlux: {},
      WPSH_FM_FillerOthers: {},
      WPSD_FM_SFA: {},
      WPSD_FM_Classification: {},
      WPSD_FM_FNo: {},
      WPSD_FM_Ano: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},
    });



    const handleChange = (e) => {
       setIsEdited(true);
      setIsEditedPage1(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangeFillerMetal = (e) => {
      setIsEdited(true);
      setIsEditedPage1(true);
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangedetailsarray = (e, i) => {
      setIsEdited(true);
      setIsEditedPage1(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
    };

    const validate = async () => {
      let all;

      if (WPStype === "Tubesheet") {
        all = Object.entries(Tubesheet_WPSPage1);
      }

      let isError = false;

      if (headerObj.WPSH_WPS_Date > todayDate) {
        isError = true;
        error.WPSH_WPS_Date = {
          status: true,
          message: "No Future Date Allowed.",
        };
      }

      all.map(([key, val]) => {
        if (headerObj[key] === "") {
    
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
        return isError;
      });


      let norows = checkTubeProcessAdded(detailObj);
      if (norows) {
        detailObj[0].errormessage =
          "Atleast one Tubesheet Process and SFA Type is required";
        isError = true;
        detailObj[0].error = true;
      }



      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
     
        if (
          await isDuplicate(headerObj.WPSH_Project_No, headerObj.WPSH_WPS_No)
        ) {
          isError = true;
          error.WPSH_Project_No.status = true;
          error.WPSH_Project_No.message =
            "Project Number & WPS Number already exists";
        } else {


          error.WPSH_Project_No.status = false;
          error.WPSH_Project_No.message = "";
        }

      }

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const validateTemp = async () => {
      let isError = false;

      let temperror = emptyError(error, setError, isError);

      if (headerObj.WPSH_Project_No.trim() === "") {
        headerObj.WPSH_Project_No = "";
        isError = true;
        error.WPSH_Project_No = {
          status: true,
          message: "This field is required",
        };
      }
      if (headerObj.WPSH_WPS_No.trim() === "") {
        headerObj.WPSH_WPS_No = "";
        isError = true;
        error.WPSH_WPS_No = {
          status: true,
          message: "This field is required",
        };
      }

      if (headerObj.WPSH_WPS_Date > todayDate) {
        isError = true;
        error.WPSH_WPS_Date = {
          status: true,
          message: "No Future Date Allowed.",
        };
      }

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await isDuplicate(headerObj.WPSH_Project_No, headerObj.WPSH_WPS_No)
        ) {
          isError = true;
          error.WPSH_Project_No.status = true;
          error.WPSH_Project_No.message =
            "Project Number & WPS Number already exists";
        } else {
          error.WPSH_Project_No.status = false;
          error.WPSH_Project_No.message = "";
        }
      }

      let norows = checkTubeProcessAdded(detailObj);

      if (norows) {
    

        detailObj[0].errormessage = "Atleast one Process and SFA is required";
        isError = true;
        detailObj[0].error = true;

        if (
          detailObj[0].WPSD_Process !== "" &&
          detailObj[0].WPSD_FM_FNo !== "" &&
          detailObj[0].WPSD_FM_SFA !== ""
        ) {
          detailObj[0].error = false;
        }
      }


      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

     
      return {isError ,temperror};
    };



    const handleCount = () => {
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj.WPSH_Status === Status.StatusApproved) {
          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCount(headerObj);
        }
      }
    };

    const handleAdd = async () => {
      let handleaddresult = true;
      let status = headerObj.WPSH_Status;
      //In case of  Approved or Rejected change State to Status Pending
      if (
        headerObj.WPSH_Status === Status.StatusApproved ||
        headerObj.WPSH_Status === Status.StatusRejected
      ) {
        if (headerObj.WPSH_Status === Status.StatusApproved) {
          
          handleCount();
        }
        headerObj.WPSH_Status = Status.StatusPending;
        headerObj.wld_Approval_Type = Status.StatusPending;
      }

      if (action === Action.ActionCopy)
        headerObj.WPSH_Status = Status.StatusTempSaved;

 
      let WpsDetail = detailObj.filter(
        (i) => i.WPSD_Process !== "NA" && i.WPSD_Process !== ""
      );



      let WPSH_WPS_Process = "";
      let WPSH_WPS_Type = "";
      let WPSH_WPS_WeldLayer = "";

      WpsDetail.map((item) => {
        item.WPSH_Project_No = headerObj.WPSH_Project_No;
        item.WPSH_WPS_No = headerObj.WPSH_WPS_No;

        item.PK = headerObj.WPSH_Project_No + "#" + headerObj.WPSH_WPS_No;


        WPSH_WPS_Process += item.WPSD_Process + ",";
        WPSH_WPS_Type += item.WPSD_Type;
        WPSH_WPS_WeldLayer += item.WPSH_WeldLayer;
        item.WPSH_WPS_Process = headerObj.WPSH_WPS_Process;
        item.WPSH_WPS_Type = headerObj.WPSH_WPS_Type;

        item.SK = headerObj.WPSH_WPS_Process + "#" + headerObj.WPSH_WPS_Type;
      });
      headerObj.WPSH_WPS_Process = WPSH_WPS_Process;
      headerObj.WPSH_WPS_Type = WPSH_WPS_Type;
      headerObj.WPSH_WPS_WeldLayer = WPSH_WPS_WeldLayer;
      debugger
     await AddNewGroove(headerObj)
        .catch((err) => {
          handleaddresult=false

        })
        .then(async (addresult) => {
          try {
        if(addresult != undefined && addresult.data != undefined && addresult.data.message == "Validate"){
             
          handleaddresult=MessageTypes.SpecialCharacters
                
          }

            if(profile !== ""){
            if (headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "") {
             
              const result = await getPresignedURL(
                folderwps,
                headerObj.Pdf_images[0].tube_Hole_ligament[0]
              );
             
              const posturl = result.url;
              setPreviewUrl1(posturl);
              
              await axios.put(posturl, profile);
           
              setImageView1(true)
            }
          }
          if(profile1 !== ""){
            
            if (headerObj?.Pdf_images[1].tube_Hole[0] !== "") {
              
   
              const result = await getPresignedURL(
                folderwps,
                headerObj?.Pdf_images[1].tube_Hole[0]
              );
             
              const posturl = result.url;
              setPreviewUrl2(posturl);
           
              await axios.put(posturl, profile1);
            
              setImageView2(true)

            }
          }
            
            if(profile2 !== ""){

            if (headerObj?.Pdf_images[2].tube_Weld_Joint[0] !== "") {
              
      
              const result = await getPresignedURL(
                folderwps,
                headerObj?.Pdf_images[2].tube_Weld_Joint[0]
              );
            
              const posturl = result.url;
              setPreviewUrl3(posturl);
       
              await axios.put(posturl, profile2);
  
              setImageView3(true)

            }
          }
          } catch (error) {
            handleaddresult=false
            if (error.request) {
              console.log("hii", error.request);
            } else {
              console.log("hiiiii", `Error: ${error.message}`);
            }
          }
          
          
       
          setaction(Action.ActionEdit);
     
          let detailres
          for (const item of WpsDetail) {
          debugger
            detailres = await AddNewDetail(item)
            .catch((err) => {
              handleaddresult=false

            })
            .then((r) => {
             
              setaction(Action.ActionEdit);
             
              if(r != undefined && r.data != undefined && r.data.message == "Validate"){
                handleaddresult=MessageTypes.SpecialCharacters
              }
            });

           
          }
          debugger
         
          if (
            action === Action.ActionEdit &&
            status === Status.StatusApproved
          ) {
         
            await AddLogs(logObj);
    
          }
        

        })

        


 
      return handleaddresult;
    };



    const fetchImagestube1 = async (item) => {
      
          if (item) {
                    
     
            const result = await getPresignedURL(
              folderwps,
              item  );

            const posturl = result.url;
            setPreviewUrl1(posturl);
     
      
        }
      }
    const fetchImagestube2 = async (item) => {
      
          if (item) {
                    

            const result = await getPresignedURL(
              folderwps,
              item
            );

            const posturl = result.url;
            setPreviewUrl2(posturl);
 
          }
      
        }
  
        const fetchImagestube3 = async (item) => {
          
              if (item) {
                        
    
                const result = await getPresignedURL(
                  folderwps,
                  item
                );
          
                const posturl = result.url;
                setPreviewUrl3(posturl);
       
              }
          
            }

        useEffect(() => {
          
          if (action === Action.ActionEdit && headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "")
            {
            fetchImagestube1(headerObj?.Pdf_images[0]?.tube_Hole_ligament[0])
            }

          if (action === Action.ActionEdit && headerObj?.Pdf_images[1]?.tube_Hole[0] !== "")
            {
            fetchImagestube2(headerObj?.Pdf_images[1]?.tube_Hole[0])
            }
          if (action === Action.ActionEdit && headerObj?.Pdf_images[2]?.tube_Weld_Joint[0] !== "")
            {
            fetchImagestube3(headerObj?.Pdf_images[2]?.tube_Weld_Joint[0])
            }  
          
        }, []);

        useEffect(() => {
          
          if (ImageView1 === true && headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "")
          {
       
            fetchImagestube1(headerObj?.Pdf_images[0]?.tube_Hole_ligament[0])
          }
        }, [ImageView1]);

        useEffect(() => {
          if (ImageView2 === true && headerObj?.Pdf_images[1]?.tube_Hole[0] !== "")
          {
 
            fetchImagestube2(headerObj?.Pdf_images[1]?.tube_Hole[0])
          }
        }, [ImageView2]);

        useEffect(() => {
        if (ImageView3 === true && headerObj?.Pdf_images[2]?.tube_Weld_Joint[0] !== "")
          {
  
            fetchImagestube3(headerObj?.Pdf_images[2]?.tube_Weld_Joint[0])
          }
        }, [ImageView3]);
      


 

    const fileInputRef = useRef(null);


    const fetchProject = async () => {
      let result = await getAllProject();
      if (result?.length > 0)
        result = result.filter((item) => item.wld_status === "Active");
      setProjectDetails(result);
    };

 

    const fetchSfa = async () => {
      const result = await getActiveSfa();
      setSfaActive(result);
    };

    const fetchfno = async () => {
      const result = await getActiveFno();
      setFnoActive(result);
    };

    const fetchAno = async () => {
      const result = await getActiveAno();
      setAnoActive(result);
    };

    useEffect(() => {
     
  
      fetchSfa();
      fetchfno();
      fetchAno();
      fetchProject();
    }, []);

    useEffect(() => {
     
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, [action]);

    useEffect(() => {
      
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, [headerObj.WPSH_Status,headerObj.WPSH_Prepared_By]);
  

    const handleSubmit = async () => {
      let result = false;
      setIsEdited(false);
      setIsEditedPage1(false);
      if (!(await validate())) {

        setLoading(true)

        result = await handleAdd(Status.StatusTempSaved);
        setLoading(false)

        if (result == true) {
          return MessageTypes.Success;
        }else if(result == MessageTypes.SpecialCharacters )
          return MessageTypes.SpecialCharacters
      else{
          return MessageTypes.Fail
        }
      } else {

        return MessageTypes.Required;
      }

     
    };

    const handleSubmitTemp = async () => {
      
      let result = false;
      let val = await validateTemp();


          if (val.isError === false){
            if ( val.temperror === false) {
        
              setLoading(true)
           result = await handleAdd(headerObj.WPSH_Status);
           setLoading(false)

          setIsEdited(false);
          setIsEditedPage1(false);
         
          if (result == true) {
            return MessageTypes.Temp;
          }
          else if(result == MessageTypes.SpecialCharacters )
            return MessageTypes.SpecialCharacters
        else{
            return MessageTypes.Fail
          }
        }
        return MessageTypes.SpecialCharacters;
      }
      return MessageTypes.Required;

      
    
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        let res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp1: async () => {
        let res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));


    const handleOndragOver = (event) => {
      event.preventDefault();
    };

    const handleFile1 = (file,index) => {
      const maxSize = FilemaxSize
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
        file = null;
        return;
      }
      let uploadextension = file.name.split(".");


      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {

        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }
      if(index == 0 ){
      setProfile(file);
      setPreviewUrl1(URL.createObjectURL(file));
      headerObj.Pdf_images[0].tube_Hole_ligament[0] = file.name;
      }
      else if(index == 1){
      setProfile1(file);
      setPreviewUrl2(URL.createObjectURL(file));
      headerObj.Pdf_images[1].tube_Hole[0] = file.name;
      }
      else if(index == 2){
      setProfile2(file);
      setPreviewUrl3(URL.createObjectURL(file));
      headerObj.Pdf_images[2].tube_Weld_Joint[0] = file.name;
      }
    };


    return (
      <Row className="font">
           {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <Row style={{ padding: "20px 60px" }} className="WPS-Page-row-padding">
          <Row className="mb-2">
            <Col md={3} style={{width:"20%"}}>
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelectProject
                  
                    id="WPSH_Project_No"
                    name="WPSH_Project_No"
                    className={error.WPSH_Project_No.status ? "err-input" : ""}
                    stylegreen={
                      error.WPSH_Project_No.status ? errorstyle : normalstyle
                    }
                    data={ProjectDetails}
                    
                    valueField={"wld_ProjectNumber"}
                    disabled={action === Action.ActionEdit ? true : false}
                    value={headerObj.WPSH_Project_No}
                    labelField={"wld_ProjectNumber"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPSH_Project_No: ProjectList.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Project_No: {},
                      }));
            
                      detailObj.WPSH_Project_No = ProjectList.value;
                    }}
                    onInput={(e) => {
                      let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Project_No: error,
                      }));
                    }}
                  ></SearchSelectProject>
                </div>
                <label>Project Number</label>
                <div className="required-text-select">
                  {error.WPSH_Project_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_Project_No.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group style={{width:"201px"}}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_WPS_No"
                    name="WPSH_WPS_No"
                    placeholder=" "
                    disabled={action === Action.ActionEdit ? true : false}
                    maxLength={17}
                    className={error.WPSH_WPS_No.status ? "err-input" : ""}
                    value={headerObj.WPSH_WPS_No}
                    onChange={(e) => {
                      handleChange(e);

         
                    }}
                    onInput={(e) => {
                      let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_WPS_No: error,
                      }));
                    }}
                  />
                  <label for="WPSH_WPS_No">WPS No.</label>
                </div>
                <div className="required-text">
                  {error.WPSH_WPS_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_WPS_No.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_WPS_Revi_No"
                    name="WPSH_WPS_Revi_No"
                    readOnly={Readonly}
                    placeholder=" "

                    maxLength={3}
                    className={error.WPSH_WPS_Revi_No.status ? "err-input" : ""}
                    value={headerObj.WPSH_WPS_Revi_No}
                    onChange={(e) => {
                      handleChange(e);
         
                    }}
                    onInput={(e) => {
                      let error =  isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_WPS_Revi_No: error,
                      }));
                    }}
                  />
                  <label for="WPSH_WPS_Revi_No">WPS Rev.No.</label>
                </div>
                <div className="required-text">
                  {error.WPSH_WPS_Revi_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_WPS_Revi_No.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col md={3} style={{width:"255px"}}>
              <div className="groove_date">
                <div class="T-input w-100">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="WPS Date"
                        className="groove_datecolor"
                        readOnly={Readonly}
                        format="DD-MM-YYYY"
                        name="WPSH_WPS_Date"
                        sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy,sans-serif" }}
                        value={
                          headerObj.WPSH_WPS_Date
                            ? dayjs(headerObj.WPSH_WPS_Date)
                            : dayjs(todayDate)
                        }
                        maxDate={dayjs(maxDate)}
                 
                        onChange={(e) => {
                          let date = dayjs(e).format("YYYY-MM-DD");
                          setheaderObj((prev) => ({
                            ...prev,
                            WPSH_WPS_Date: date,
                          }));

                          setError((prevState) => ({
                            ...prevState,
                            WPSH_WPS_Date: {
                              status: false,
                              message: "",
                            },
                          }));
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="required-text-select mt-2">
                {error.WPSH_WPS_Date.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_WPS_Date.message}
                  </Form.Text>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_SupportingPQR"
                  name="WPSH_SupportingPQR"
                  maxLength={90}
                  readOnly={Readonly}
                  placeholder=" "
                  className={error.WPSH_SupportingPQR.status ? "err-input" : ""}
                  value={headerObj.WPSH_SupportingPQR}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_SupportingPQR: error,
                    }));
                  }}
                />
                <label for="WPSH_SupportingPQR">Supporting PQR</label>
              </div>
              <div className="required-text">
                {error.WPSH_SupportingPQR.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_SupportingPQR.message}
                  </Form.Text>
                )}
              </div>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_RefDoc"
                  name="WPSH_RefDoc"
                  maxLength={90}
                  readOnly={Readonly}
                  placeholder=" "
                  className={error.WPSH_RefDoc.status ? "err-input" : ""}
                  value={headerObj.WPSH_RefDoc}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_RefDoc: error,
                    }));
                  }}
                />
                <label for="WPSH_RefDoc">Reference Document</label>
              </div>
              <div className="required-text">
                {error.WPSH_RefDoc.status && (
                  <Form.Text className="text-danger">
                    {error.WPSH_RefDoc.message}
                  </Form.Text>
                )}
              </div>
            </Col>
          
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPS_QualifiedTo"
                  name="WPS_QualifiedTo"
                  readOnly={Readonly}
                  maxLength={34}
                  placeholder=" "
                  className={error.WPS_QualifiedTo.status ? "err-input" : ""}
                  value={headerObj.WPS_QualifiedTo}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPS_QualifiedTo: error,
                    }));
                  }}
                />
                <label for="WPS_QualifiedTo">Qualified To</label>
              </div>
              <div className="required-text">
                {error.WPS_QualifiedTo.status && (
                  <Form.Text className="text-danger ">
                    {error.WPS_QualifiedTo.message}
                  </Form.Text>
                )}
              </div>
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_CompanyName"
                  name="WPSH_CompanyName"
                  value={company_name[0].value}
                  readOnly
                  placeholder=" "
                />
                <label for="WPSH_CompanyName">Company Name</label>
              </div>
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "10px 48px" }}>
          <Col md={12}>
            <p className="WPS-blue-text"> BASE METALS ( QW-403)</p>
            <WPS_Base_metals
              headerObj={headerObj}
              error={error}
              readOnly={Readonly}
              handleChange={handleChange}
              setError={setError}
            ></WPS_Base_metals>
          </Col>
        </Row>

        <Row style={{ padding: "20px 60px" }} className="WPS-Page-row-padding">
          <span className="WPS-blue-text" style={{position:"relative", right:"12px"}}>Joint Detail</span>
          <Row className="mb-4">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_JointType"
                  name="WPSH_JD_JointType"
                  value={joint_type[0].value}
                  readOnly
                  placeholder=" "
                />
                <label for="WPSH_JD_JointType">Joint Type</label>
              </div>
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_HoleId"
                  name="WPSH_JD_HoleId"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_HoleId}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_HoleId: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_HoleId">Hole ID (mm)</label>
              </div>
              {error.WPSH_JD_HoleId.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_HoleId.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_TubePitch"
                  name="WPSH_JD_TubePitch"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_TubePitch}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_TubePitch: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_TubePitch">Tube Pitch (mm)</label>
              </div>
              {error.WPSH_JD_TubePitch.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_TubePitch.message}
                </Form.Text>
              )}
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_GrooveDepth"
                  name="WPSH_JD_GrooveDepth"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_GrooveDepth}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_GrooveDepth: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_GrooveDepth">Groove Depth (mm)</label>
              </div>
              {error.WPSH_JD_GrooveDepth.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_GrooveDepth.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_TubeProj"
                  name="WPSH_JD_TubeProj"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_TubeProj}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_TubeProj: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_TubeProj">Tube Projection (mm)</label>
              </div>
              {error.WPSH_JD_TubeProj.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_TubeProj.message}
                </Form.Text>
              )}
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_WidthLigament"
                  name="WPSH_JD_WidthLigament"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_WidthLigament}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_WidthLigament: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_WidthLigament">
                  Width of Ligament (mm)
                </label>
              </div>
              {error.WPSH_JD_WidthLigament.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_WidthLigament.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_Pattern"
                  name="WPSH_JD_Pattern"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_Pattern}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_Pattern: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_Pattern">Pattern</label>
              </div>
              {error.WPSH_JD_Pattern.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_Pattern.message}
                </Form.Text>
              )}
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_GrooveAngle"
                  name="WPSH_JD_GrooveAngle"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_GrooveAngle}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_GrooveAngle: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_GrooveAngle">Groove Angle(°)/Radius(mm)</label>
              </div>
              {error.WPSH_JD_GrooveAngle.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_GrooveAngle.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_JD_GrooveType"
                  name="WPSH_JD_GrooveType"
                  readOnly={Readonly}
                  maxLength={20}
                  placeholder=" "
                  value={headerObj.WPSH_JD_GrooveType}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_JD_GrooveType: error,
                    }));
                  }}
                />
                <label for="WPSH_JD_GrooveType">Groove Type</label>
              </div>
              {error.WPSH_JD_GrooveType.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_JD_GrooveType.message}
                </Form.Text>
              )}
            </Col>
          </Row>

     
        </Row>

        <Row style={{padding: "20px 60px" }}>

        <div className="row">
          <span className="WPS-blue-text col-md-4">Tube Hole Ligament Detail</span>

          <span className="WPS-blue-text col-md-4">Tube Hole Detail</span>
         
          <span className="WPS-blue-text col-md-4"> Tube to Tubesheet Weld Joint Detail </span>
          </div>


<WPS_Image
            handleFile={handleFile1}
            previewUrl1={previewUrl1}
            previewUrl2={previewUrl2}
            previewUrl3={previewUrl3}
            headerObj={headerObj}
            setheaderObj={setheaderObj}

            name="fileInput"
            name1="fileInput1"
            name2="fileInput2"
            name3="fileInput3"
            type="file"
            accept="image/*"
            fileInputRef={fileInputRef}
            readOnly={Readonly}
           
          ></WPS_Image>
          </Row>

        <Row style={{ padding: "20px 48px" }}>
          <Col md={12}>
            <p className="WPS-blue-text">Filler Metals (QW-404)</p>
            <WPS_Filler_metals
              headerObj={headerObj}
              detailObj={detailObj}
              disabled={isDisabled}
              error={error}
              readOnly={Readonly}
              handleChangedetailsarray={handleChangedetailsarray}
              handleChangeFillerMetal={handleChangeFillerMetal}
              setError={setError}
              setdetailObj={setdetailObj}
              SfaActive={SfaActive}
              AnoActive={AnoActive}
              FnoActive={FnoActive}
              classError={classError}
              setClassError={setClassError}
              chemicalAnaError={chemicalAnaError}
              setChemicalAnaError={setChemicalAnaError}
              isOverlay={isOverlay}
              setOverlay={setOverlay}
              Grooveclass={Grooveclass}
              setoverlayclass={setoverlayclass}
              overlaytext={overlaytext}
              action={action}
              setaction={setaction}
            ></WPS_Filler_metals>
          </Col>
        </Row>

        {/* -------end FILLER------------------- */}
        <Row style={{ padding: "20px 60px" }} className="WPS-Page-row-padding">
          <Row className="mb-4">
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_FM_PreFillerMetal"
                  name="WPSH_FM_PreFillerMetal"
                  readOnly={Readonly}
                  maxLength={40}
                  placeholder=" "
                  value={headerObj.WPSH_FM_PreFillerMetal}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_FM_PreFillerMetal: error,
                    }));
                  }}
                />
                <label for="WPSH_FM_PreFillerMetal">
                  Preplaced Filler Metal
                </label>
              </div>
              {error.WPSH_FM_PreFillerMetal.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_FM_PreFillerMetal.message}
                </Form.Text>
              )}
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_FM_WithFillerMetal"
                  name="WPSH_FM_WithFillerMetal"
                  readOnly={Readonly}
                  maxLength={40}
                  placeholder=" "
                  value={headerObj.WPSH_FM_WithFillerMetal}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_FM_WithFillerMetal: error,
                    }));
                  }}
                />
                <label for="WPSH_FM_WithFillerMetal">
                  With/ Without Filler Metal
                </label>
              </div>
              {error.WPSH_FM_WithFillerMetal.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_FM_WithFillerMetal.message}
                </Form.Text>
              )}
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_FM_FillerProduct"
                  name="WPSH_FM_FillerProduct"
                  readOnly={Readonly}
                  maxLength={40}
                  placeholder=" "
                  value={headerObj.WPSH_FM_FillerProduct}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_FM_FillerProduct: error,
                    }));
                  }}
                />
                <label for="WPSH_FM_FillerProduct">
                  Filler Metal Product Form
                </label>
              </div>
              {error.WPSH_FM_FillerProduct.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_FM_FillerProduct.message}
                </Form.Text>
              )}
            </Col>
            <Col>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_FM_Inserts"
                  name="WPSH_FM_Inserts"
                  readOnly={Readonly}
                  maxLength={40}
                  placeholder=" "
                  value={headerObj.WPSH_FM_Inserts}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );

                    setError((prevState) => ({
                      ...prevState,
                      WPSH_FM_Inserts: error,
                    }));
                  }}
                />
                <label for="WPSH_FM_Inserts">Inserts</label>
              </div>
              {error.WPSH_FM_Inserts.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_FM_Inserts.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_FM_FillerFlux"
                  name="WPSH_FM_FillerFlux"
                  readOnly={Readonly}
                  maxLength={40}
                  placeholder=" "
                  value={headerObj.WPSH_FM_FillerFlux}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_FM_FillerFlux: error,
                    }));
                  }}
                />
                <label for="WPSH_FM_FillerFlux">Filler Metals - Flux</label>
              </div>
              {error.WPSH_FM_FillerFlux.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_FM_FillerFlux.message}
                </Form.Text>
              )}
            </Col>
            <Col md={9}>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPSH_FM_FillerOthers"
                  name="WPSH_FM_FillerOthers"
                  readOnly={Readonly}
                  maxLength={90}
                  placeholder=" "
                  value={headerObj.WPSH_FM_FillerOthers}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =  isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSH_FM_FillerOthers: error,
                    }));
                  }}
                />
                <label for="WPSH_FM_FillerOthers">Filler Metals - Others</label>
              </div>
              {error.WPSH_FM_FillerOthers.status && (
                <Form.Text className="text-danger">
                  {error.WPSH_FM_FillerOthers.message}
                </Form.Text>
              )}
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }

);

export default WPSTubesheetGeneral;
