import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./styles/temp.css"
import reportWebVitals from "./reportWebVitals";
//added by snehal
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Provider } from "react-redux";

import store from "./store";

const msalInstance = new PublicClientApplication(msalConfig);

//END by snehal
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
);


reportWebVitals();
