import React, {
  useState,

  forwardRef,

  useEffect,
} from "react";


import { Row, Col, Container, Form, Button } from "react-bootstrap";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {

  PRODexportreport,
  getForFilters,
} from "../../Services/WI-services";

import { SearchSelectWIProject } from "../utility/search-select";


import { ExportToExcel } from "./PRODWI_Excel";
import { Date_Input } from "./PRODExcel_Headers";
import { todayDate } from "../utility/CommonComponents/TodayDate";
import { normalstyle } from "../../functions/welding-functions";

const ProdReport = forwardRef((props, ref) => {

  const [widata, setwidata] = useState(null);

  const [reload, setReload] = useState(false);
  





  let Data = [];



  const [datevalue, setdatevalue] = useState(false);

  const [wiobj, setwiobj] = useState({
    WI_Project_No: "",
    WI_Joint_No: "",
    WI_Welder_Id: "",
    WI_From_Date: "",
    WI_To_Date: "",
    WI_Slip_Status: "",
  });

  const [error, setError] = useState({
    WI_Project_No: {},
    WI_Joint_No: {},
    WI_Welder_Id: {},
    WI_From_Date: {},
    WI_To_Date: {},
    WI_Slip_Status: {},
  });


  let Slipstatus = [
    { value: "Created", name: "Created" },
    { value: "Cancelled", name: "Cancelled" },
    { value: "Deleted", name: "Deleted" },
  ];

  let reporName = "ProdReport";




  const fetchwidata = async () => {
    const result = await getForFilters();

    setwidata(result);
  };




  useEffect(() => {
    fetchwidata();
  }, [reload]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  const uniqueProjectNumbers = [
    ...new Set(widata && widata?.map((item) => item.WI_Project_No)),
  ];

  const getJointNosForSelectedProject = () => {
    if (wiobj.WI_Project_No && widata && widata?.length > 0) {
      const selectedProjects = widata.filter(
        (item) => item.WI_Project_No === wiobj.WI_Project_No
      );
      const uniqueJointNos = new Set(
        selectedProjects &&
          selectedProjects
            .map((project) => project.WI_Joint_No)
            .filter((jointNo) => jointNo)
      );

      if (uniqueJointNos.size > 0) {
        return Array.from(uniqueJointNos).map((jointNo) => ({
          label: jointNo,
          value: jointNo,
        }));
      }
    }
    return [];
  };

  const getWelderForSelectedProject = () => {
    if (
      wiobj.WI_Project_No &&
      wiobj.WI_Joint_No &&
      widata &&
      widata.length > 0
    ) {
      const selectedWelders = widata
        .filter(
          (item) =>
            item.WI_Project_No === wiobj.WI_Project_No &&
            item.WI_Joint_No === wiobj.WI_Joint_No
        )
        .map((item) => item.WI_Welder_Id)
        .filter((welderId) => welderId);

      if (selectedWelders.length > 0) {
        const uniqueWelders = [...new Set(selectedWelders)];

        return uniqueWelders.map((welderId) => ({
          label: welderId,
          value: welderId,
        }));
      }
    }

    return [];
  };

  const validate = async (e) => {
    let isError = false;

    if (wiobj.WI_From_Date !== "" && wiobj.WI_To_Date !== "") {
      if (wiobj.WI_From_Date > wiobj.WI_To_Date) {

        isError = true;
        error.WI_From_Date = {
          status: true,
        };
        error.WI_To_Date = {
          status: true,
        };
        toast.warning("ToDate Should be Greater than or Equal to FromDate", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    }

    return isError;
  };

  const handleSubmit = async (e) => {
    if (!(await validate())) {
 

        let errorMessageShown = false;
    
        if (wiobj.WI_From_Date === "") {
          toast.warning("Slip Creation Date From is Required", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          errorMessageShown = true;
        }
        if (!errorMessageShown && wiobj.WI_To_Date === "") {
          toast.warning("Slip Creation Date To is Required", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          errorMessageShown = true;
        }
        if (!errorMessageShown && wiobj.WI_Slip_Status === "") {
          toast.warning("Slip Status is Required", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }



      
      if (
        wiobj.WI_Slip_Status !== "" &&
        wiobj.WI_From_Date !== "" &&
        wiobj.WI_To_Date !== ""
      ) {
        const result = await PRODexportreport(wiobj);
        

        let filename = "productivity measurement";
        let sheetName = "Report_WeldingProductivity";
        let excelHeader = "LIST OF Product Report";
        let displayNote = "";

        if (result?.length > 0) {
          result?.forEach((row) => {
            Data.push({
              A: "",
              B: row.WI_Project_No,
              C: row.WI_SlipDate,
              D: row.WI_plant,
              E: row.WI_Slip,
              F: row.WI_Slip_Status === "Cancelled" ? "Yes" : "No",
              G: row.WI_Joint_No,
              H: row.WI_WPS_No,
              I: row.WI_Process,
              J: row.WI_Type,
              K: row.WI_Welder_Id,
              L: row.WI_Welder_Name,
              M: row.WI_CreatedBy,
              N: row.WI_ItemCode,
              O: row.WI_ItemDesc,
              P: row.WI_ItemGroup,
              Q: row.WI_WCinKgs,
              R: row.WI_WMinKgs,
              //S: row.WI_WMinKgs,
            });
         
          });

          let titleCell = "E3";
          let titleWelderRange = "G1:Y5";
          let tbodyHeadRange = "A6:R6";
          let tbodyRange = "A7";
          let blankRow = "A5:R5";
          let width = [
            { col: "A", width: 2.5 },
            { col: "B", width: 13.7 },
            { col: "C", width: 13.7 },
            { col: "D", width: 13.7 },
            { col: "E", width: 8.5 },
            { col: "F", width: 12.5 },
            { col: "G", width: 16.5 },
            { col: "H", width: 20 },
            { col: "I", width: 13.7 },
            { col: "J", width: 13.7 },
            { col: "K", width: 15 },
            { col: "L", width: 18 },
            { col: "M", width: 20 },
            { col: "N", width: 20 },
            { col: "O", width: 20 },
            { col: "P", width: 20 },
            { col: "Q", width: 20 },
            { col: "R", width: 20 },
            //{ col: "S", width: 20 },
          ];
          let Headings = [
            { col: "G2", value: excelHeader },
            {
              col: "C4",
              value: displayNote,
            },
          ];
      
          ExportToExcel(
            filename,
            width,
            result,
            Data,
            titleCell,
            titleWelderRange,
            tbodyHeadRange,
            tbodyRange,
            blankRow,
            Headings,
            sheetName,
            reporName
          );
        } else {
          toast.info("There are no records matching to filters.", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (result.length > 0) {
          // alert("Data Exported")
          toast.success("Exported successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
      }
    }
  };


  return (
    <>
      <Container fluid>
        <Row className="RowHeight mb-4 ml-2">
          <Col md={12} sm={12} xs={12} className="ListTitle">
            Productivity Measurement Report Filters
          </Col>
        </Row>
        <div className="px-2 pb-2">
          <Row className="gx-3" style={{ marginBottom: "2rem" }}>
            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    // className="inspdrop"
                    id="WI_Project_No"
                    name="WI_Project_No"
                    className={""}
                    stylegreen={normalstyle}
                    data={
                      uniqueProjectNumbers &&
                      uniqueProjectNumbers.map((projectNo) => ({
                        WI_Project_No: projectNo,
                      }))
                    }
                    
                    valueField={"WI_Project_No"}
                    value={wiobj?.WI_Project_No}
                    labelField={"WI_Project_No"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Project_No: ProjectList.value,
                        WI_Joint_No: "",
                        WI_Welder_Id: "",
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Project Number </label>
                <label style={{ fontStyle:"italic", color:"var(--col-707070)"}}>(Optional)</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    // className="inspdrop"
                    id="WI_Joint_No"
                    name="WI_Joint_No"
                    className={""}
                    stylegreen={normalstyle}
                    data={getJointNosForSelectedProject()}
                    
                    valueField={"value"}
                    value={wiobj?.WI_Joint_No}
                    labelField={"label"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Joint_No: ProjectList.value,
                        WI_Welder_Id: "",
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Joint <span style={{fontStyle:"italic", color:"var(--col-707070)"}}>(Optional)</span></label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    id="WI_Welder_Id"
                    name="WI_Welder_Id"
                    className={""}
                    stylegreen={normalstyle}
                    data={getWelderForSelectedProject()}
                    
                    valueField={"value"}
                    value={wiobj?.WI_Welder_Id}
                    labelField={"label"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Welder_Id: ProjectList.value,
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Welder Code <span style={{fontStyle:"italic", color:"var(--col-707070)"}}>(Optional)</span></label>
              </Form.Group>
            </Col>
            </Row>
            
<Row style={{ position: "relative",
    bottom: "45px"}}>
            <Col md={3} style={{position: "relative", right:"3px"}} >
              <Date_Input
                divClass="Date-input w-100"
                label="Slip Creation Date From"
                className="groove_datecolor"
                name="WI_From_Date"
                todayDate={todayDate}
                obj={wiobj}
                setobj={setwiobj}
                datevalue={datevalue}
                setdatevalue={setdatevalue}
                todate={wiobj.WI_To_Date}
                setError={setError}
                status={error.WI_From_Date.status}
                message={error.WI_From_Date.message}
              />
            </Col>

            <Col md={3} style={{position:"relative" , right:"3px"}}>
              <Date_Input
                divClass="Date-input w-100"
                label="Slip Creation Date To"
                className="groove_datecolor"
                name="WI_To_Date"
                todayDate={todayDate}
                obj={wiobj}
                fromdate={wiobj.WI_From_Date}
                setobj={setwiobj}
                setdatevalue={setdatevalue}
                datevalue={datevalue}
                setError={setError}
                status={error.WI_To_Date.status}
                message={error.WI_To_Date.message}
              />
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    id="WI_Slip_Status"
                    name="WI_Slip_Status"
                    className={""}
                    stylegreen={normalstyle}
                    data={Slipstatus}
                    
                    valueField={"value"}
                    value={wiobj?.WI_Slip_Status}
                    labelField={"name"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Slip_Status: ProjectList.value,
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Slip Status</label>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Button
            style={{position:"relative",right:"20px",bottom:"10px"}}
              onClick={handleSubmit}
              className="btn-add-color semiBoldToggleButton rightAlignedButton"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>

      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
});

export default ProdReport;
