import { Row, Col, Form } from "react-bootstrap";
import {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from "react";
import {

  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import {
  company_name,
  MessageTypes,
  WeldedCoupon,
  WPQPage1Date,
  WPQPage1,
  Plant_Location_GO,
} from "./WPQObjects/WPQ_entity";
import { SearchSelect, SearchSelectWelder } from "../utility/search-select";
import {
  AddNewCountWPQ,
  AddNewGrooveWPQ,
  getbyrecordno,
} from "../../Services/wpq_groove-services";
import { todayDate, maxDate } from "../utility/CommonComponents/TodayDate";
import { useLocation } from "react-router-dom";
import { getPNOActive } from "../../Services/pcategory-service";
import { getActiveProcess } from "../../Services/weldingprocess-services";
import {
  checkProcessAdded,
  checkduplicateProcess,
  page1characters,
} from "./WPQObjects/WPQ_Validations";
import { getAllWPSNo } from "../../Services/wps_details-service";
import { emptyError } from "./WPQObjects/WPQ_common";
import { Action, Status } from "./WPQObjects/WPQ_entity";
import {
  General_Single_Input_select,
  Single_Input,
} from  "../utility/CommonComponents/Inputs_Transaction"
import { WPQVariableTable } from "./WPQObjects/WPQVariableTable";
import { WPQ_BaseMetalThichkness_Table } from "./WPQObjects/WPQ_BaseMetalThichkness_Table";
import { getActiveInsp } from "../../Services/inspectionagency-service";

import { getActiveWelder } from "../../Services/Welder-services";
import { AddNewDetail } from "../../Services/wpq_details-service";
import { CommonTable4 } from "./WPQObjects/WPQ_Process_Type_Table4";
import { BaseMetalTable } from "./WPQObjects/BaseMetalTable";
import { Date_Input } from "../utility/CommonComponents/Date_Component";
import { DotLoader } from "react-spinners";


const WPQ_General = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      normalstyle,
      errorstyle,
      blyestyle,
      WPStype,
      isDisabled,
      isReadonly,
      setIsReadonly,
      daysRemainingList,
      setDaysRemainingList,
    },
    ref
  ) => {
    const location = useLocation();
    let alldetail = [...detailObj];
    let alldetail1 = alldetail[0];
 
  
    let username = sessionStorage.getItem("UserName");

    const [ProcessActive, setProcessActive] = useState([]);
    const [PNoActive, setPNoActive] = useState([]);
    const [WPSNo, setWPSNo] = useState([]);
    const [checkwpsdata, setcheckwpsdata] = useState([]);
    const [WelderActive, setWelderActive] = useState([]);

    const [InspecAgencyActive, setInspecAgencyActive] = useState([]);
    const [IsEdited, setIsEdited] = useState(false);
    const [loading, setLoading] = useState(false);


    const [action, setaction] = useState(location.state?.action);

    let isoverlay = WPStype === "Groove" ? false : true;

   





  
    const [error, setError] = useState({
      WPQ_WelderId: {},
      WPQ_WelderName: {},
      WPQ_Date: {},
      WPQ_RecordNo: {},
      WPQ_Wps_RecordNo: {},
      WPQ_ReviNo: {},
      WPQ_Inspection: {},
      WPQ_DOB: {},
      WPQ_Qualificationcode: {},
      WPQ_Last_UpdatedDate: {},
      WPQ_Last_UpdatedBy: {},
      WPQ_Welded_on_coupon: {},
      WPQ_CompanyName: {},
      WPQ_Division: {},
      WPQ_PlantLocation: {},
      WPQ_specialNote: {},
      WPQ_Status: {},

      WPQ_BM_BM_ProductForm: {},
      WPQ_BM_BM_Spec: {},
      WPQ_BM_BM_Pno: {},
      WPQ_BM_BM_Gno: {},

      WPQ_BM_BM_Thk: {},
      WPQ_BM_BM_Dia: {},

      WPQ_BM_WTBM_ProductForm: {},
      WPQ_BM_WTBM_Spec: {},
      WPQ_BM_WTBM_Pno: {},
      WPQ_BM_WTBM_Gno: {},

      WPQ_BM_WTBM_Thk: {},
      WPQ_BM_WTBM_Dia: {},

      WPQ_VAR_TypeofJoint_actualValue: {},
      WPQ_VAR_TypeofJoint_RangeQualified: {},
      WPQ_VAR_BaseM_actualValue: {},
      WPQ_VAR_BaseM_RangeQualified: {},
    
      WPQ_VAR_Pcategory_RangeQualified: {},

      WPQ_BMT_Plate_thickness_ActualV_Groove: "",
      WPQ_BMT_Plate_thickness_ActualV_Fillet: "",
      WPQ_BMT_Plate_thickness_ActualV_Overlay: "",

      WPQ_BMT_Pipe_thickness_ActualV_Groove: "",
      WPQ_BMT_Pipe_thickness_ActualV_Fillet: "",
      WPQ_BMT_Pipe_thickness_ActualV_Overlay: "",

      WPQ_BMT_Pipe_Diameter_ActualV_Groove: "",
      WPQ_BMT_Pipe_Diameter_ActualV_Fillet: "",
      WPQ_BMT_Pipe_Diameter_ActualV_Overlay: "",

      WPQ_BMT_Plate_thickness_RangeQ_Groove: "",
      WPQ_BMT_Plate_thickness_RangeQ_Fillet: "",
      WPQ_BMT_Plate_thickness_RangeQ_Overlay: "",

      WPQ_BMT_Pipe_thickness_RangeQ_Groove: "",
      WPQ_BMT_Pipe_thickness_RangeQ_Fillet: "",
      WPQ_BMT_Pipe_thickness_RangeQ_Overlay: "",

      WPQ_BMT_Pipe_Diameter_RangeQ_Groove: "",
      WPQ_BMT_Pipe_Diameter_RangeQ_Fillet: "",
      WPQ_BMT_Pipe_Diameter_RangeQ_Overlay: "",

      WPQ_WP_Process_Process: {},
      WPQ_WP_Process_Type: {},
      WPQ_WP_Type_Process: {},
      WPQ_WP_Type_Type: {},
      WPSH_Project_No: {},
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_SupportingPQR: {},
      WPSH_WPS_Date: {},
      WPSH_RefDoc: {},
      WPSH_Scope: {},
      WPSH_JointDesc: {},
      WPSH_BM_Type_Type: {},
      WPSH_BM_WeldingTo_Type: {},
      WPSH_BM_Type_GNo: {},
      WPSH_BM_Type_PNo: {},
      WPSH_BM_WeldingTo_PNo: {},
      WPSH_BM_WeldingTo_GNo: {},
      WPSH_BM_Backing_Backing: {},
      WPSH_BM_Backing_GNo: {},
      WPSH_BM_Backing_PNo: {},
      WPSH_BM_Retainer: {},
      WPSH_WPS_WeldLayer: {},
      WPSH_BM_Backing_Material: {},
      WPSH_ConsuInsert_SFA: {},
      WPSH_ConsuInsert_Classification: {},
      WPSH_ConsuInsert_FNo: {},
      WPSH_ConsuInsert_ANo: {},
      WPSH_ConsuInsert_ChemicalAnalysis_TradeName: {},
      WPSH_Flux_SFA: {},
      WPSH_Flux_Classification: {},
      WPSH_Flux_FNo: {},
      WPSH_Flux_ANo: {},
      WPSH_Flux_ChemicalAnalysis_TradeName: {},
      WPSH_SupFiller_SFA: {},
      WPSH_SupFiller_Classification: {},
      WPSH_SupFiller_FNo: {},
      WPSH_SupFiller_ANo: {},
      WPSH_SupFiller_ChemicalAnalysis_TradeName: {},
      WPSH_BM_Notes: {},
      WPSH_TRQ_GroovewithoutImpact_Min: {},
      WPSH_TRQ_GroovewithoutImpact_Max: {},
      WPSH_TRQ_GroovewithImpact_Min: {},
      WPSH_TRQ_GroovewithImpact_Max: {},
      WPSH_TRQ_FilletwithoutImpact_Min: {},
      WPSH_TRQ_FilletwithoutImpact_Max: {},
      WPSH_TRQ_FilletwithImpact_Min: {},
      WPSH_TRQ_FilletwithImpact_Max: {},
      WPSH_FM_FluxType: {},
      WPSH_FM_AlloyFlux: {},
      WPSH_FM_AlloyElement: {},
      WPSH_FM_Add_Del_Filler: {},
      WPSH_FM_Add_Del_Flux: {},
      WPSH_FM_Add_Del_orchange: {},
      WPSH_FM_FluxItemBatch: {},
      WPSH_FM_FluxFromRecrush: {},
      WPSH_FM_FluxItemCode: {},
      WPSH_FM_FilerMetal: {},
      WPSD_FM_Classification: {},
      WPSH_BM_Type_HT_Con: {},
      WPSH_BM_WeldingTo_HT_Con: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},
      WPSD_FM_TRQ_Min: {},
      WPSD_FM_TRQ_Max: {},
      WPSH_ConsuInsert: {},
      WPSD_FM_Make: {},
      WPSH_Flux: [],
      WPSH_SupFiller: [],
      WPSH_MinOvlFinThick: {},

      WPQ_Process: {},
      WPSH_TRQ_BaseMetalCRO_Min: {},
      WPSH_TRQ_BaseMetalCRO_Max: {},
      WPSH_TRQ_BaseMetalHRO_Min: {},
      WPSH_TRQ_BaseMetalHRO_Max: {},
    });




    const handleChange = (e) => {

      setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const validate = async () => {
      let entries;
      let entries1;
      let entries2;
      debugger
      let isError = false;
      
      let temperror = emptyError(error, setError, isError);

 

      if (WPStype === "Groove") {
        entries = Object.entries(WPQPage1);
        entries2 = Object.entries(WPQPage1Date);
      }
      if (WPStype === "Overlay") {
        entries1 = Object.entries(WPQPage1);
        entries2 = Object.entries(WPQPage1Date);
      }

      var all = WPStype === "Groove" ? entries : entries1;

      all.map(([key, val]) => {



        if (headerObj[key] === "" ) {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
 
        }

     
        if (WPStype === "Overlay") {
          var data = page1characters(error);
          if (data) {
            isError = true;
          } else {
            error[key] = {
              status: false,
              message: "",
            };
          }
        }

        return isError;
      });

      entries2.map(([key, val]) => {
        if (headerObj[key] > todayDate) {
          isError = true;
          error[key] = {
            status: true,
            message: `No Future Date Allowed.`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
      });

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(
            WPStype,
            headerObj?.WPQ_WelderId,
            headerObj?.WPQ_RecordNo
          )
        ) {
          isError = true;
          error.WPQ_WelderId.status = true;
          error.WPQ_WelderId.message =
            "Project Number & WPQ Number already exists";
        } else {
          error.WPQ_WelderId.status = false;
          error.WPQ_WelderId.message = "";
        }
      }
  
      let norows = checkProcessAdded(detailObj);

      if (norows) {
        detailObj[0].errormessage = "Atleast one Process and Type is required";
        isError = true;
        detailObj[0].error = true;

        if (detailObj[0].WPQ_Process !== "" && detailObj[0].WPQ_Type !== "") {
          detailObj[0].error = false;
        }
      }

      let duplicateprocess = checkduplicateProcess(detailObj);
      if (duplicateprocess) isError = true;

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

    

      return {isError,temperror};
    };

    const checkDuplicate = async (type, proj, revno) => {
      
      let result = await getbyrecordno(type, proj, revno);

      return !(result[0] === undefined);
    };

    



    const validateTemp = async () => {
      let isError = false;

       let temperror = emptyError(error, setError, isError);

      if (headerObj?.WPQ_WelderId.trim() === "") {
        headerObj.WPQ_WelderId = "";
        isError = true;
        error.WPQ_WelderId = {
          status: true,
          message: "This field is required",
        };
      }
      if (headerObj?.WPQ_RecordNo.trim() === "") {
        headerObj.WPQ_RecordNo = "";
        isError = true;
        error.WPQ_RecordNo = {
          status: true,
          message: "This field is required",
        };
      }

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(
            WPStype,
            headerObj?.WPQ_WelderId,
            headerObj?.WPQ_RecordNo
          )
        ) {
          isError = true;
          error.WPQ_WelderId.status = true;
          error.WPQ_WelderId.message =
            "Combination of Welder ID & WPQ Record Number already exists";
        } else {
          error.WPQ_WelderId.status = false;
          error.WPQ_WelderId.message = "";
        }
      }

      let norows = checkProcessAdded(detailObj);

      if (norows) {
        detailObj[0].errormessage = "Atleast one Process and Type is required";
        isError = true;
        detailObj[0].error = true;

        if (detailObj[0].WPQ_Process !== "" && detailObj[0].WPQ_Type !== "") {
          detailObj[0].error = false;
        }
      }

      let duplicateprocess = checkduplicateProcess(detailObj);
      if (duplicateprocess) isError = true;

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return {isError ,temperror};
    };

    const handleCount = () => {
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.wld_Approval_Type === Status.StatusApproved) {
          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCountWPQ(headerObj);
        }
      }
    };

    let WpqDetail = detailObj?.filter(
      (i) => i.WPQ_Process !== "NA" && i.WPQ_Process !== ""
    );

    const Processstatus = async (data) => {
      
      let Process_Status = [];
      let detail_SK = [];

      data.forEach((item) => {
        Process_Status.push(item.remainingday);
        detail_SK.push(item.WPQ_Process + "#" + item.WPQ_Type);
      });
      setheaderObj({ ...headerObj, Process_Status, detail_SK });
    };

    useEffect(() => {
      
      if (WpqDetail.length > 0) {
        Processstatus(WpqDetail);
      }
    }, [detailObj]);

    const handleAdd = async (approvalstatus) => {
      
      let result = false;
      headerObj.WPQ_CompanyName = company_name[0]?.value;

      if (
        checkwpsdata !== undefined ||
        checkwpsdata !== "" ||
        checkwpsdata !== null
      ) {
        headerObj.WPS_Key = checkwpsdata?.SK;
      }
    
      headerObj.WPQ_Last_UpdatedBy = username;
      if (
        headerObj?.WPSH_Status === Status.StatusApproved ||
        headerObj?.WPSH_Status === Status.StatusRejected
      ) {
        headerObj.WPSH_Status = Status.StatusPending;
        if (headerObj?.WPSH_Status === Status.StatusApproved) handleCount();
      }

      const filteredDetails = detailObj.filter(
        (item) => item.WPQ_Process !== "NA" && item.WPQ_Process !== ""
    );


  
    


      var WPSH_WPQ_Process = "";


      var WPSH_WPQ_Type = "";


      let WQC_Process = "";
      let WQC_Type = "";
      let WQC_Pcat=""


      for (const item in filteredDetails) {
 


        headerObj.Process1 = filteredDetails[0]?.WPQ_Process || "";
        headerObj.Process2 = filteredDetails[1]?.WPQ_Process || "";
        headerObj.Process3 = filteredDetails[2]?.WPQ_Process || "";
      }



      for (const item of filteredDetails) {

let pcat = item.WPQ_Process_Category.split("#")
let category = pcat[0]
        

        item.WPQ_WelderId = headerObj?.WPQ_WelderId;
        item.WPQ_RecordNo = headerObj?.WPQ_RecordNo;
        item.wld_WPSType = headerObj?.wld_WPSType;
        item.WPQ_WelderName = headerObj?.WPQ_WelderName;
        item.WPQ_BM_WTBM_Pno = headerObj?.WPQ_BM_WTBM_Pno;
        item.WPQ_BMT_Plate_thickness_RangeQ_Overlay =
          headerObj?.WPQ_BMT_Plate_thickness_RangeQ_Overlay;
        item.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay =
          headerObj?.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay;

        item.PK = headerObj?.WPQ_WelderId + "#" + headerObj?.WPQ_RecordNo;
        WPSH_WPQ_Process += item.WPQ_Process + ",";
        WQC_Process += item.WPQ_Process + "|" ;
        WQC_Pcat += category + "|";
        WQC_Type += item.WPQ_Type + "|" ;
        WPSH_WPQ_Type += item.WPQ_Type + ",";

  
      headerObj.WPSH_WPQ_Process = WPSH_WPQ_Process;
      headerObj.WPSH_WPQ_Type = WPSH_WPQ_Type;
      headerObj.WQC_Process = WQC_Process;
      headerObj.WQC_Type = WQC_Type;
      headerObj.WQC_Pcat = WQC_Pcat




        
        item.WPSH_WPQ_Process = headerObj?.WPSH_WPQ_Process;
        item.WPSH_WPQ_Type = headerObj?.WPSH_WPQ_Type;

        item.SK = item.WPQ_Process + "#" + item.WPQ_Type;
        
    
    }


  

      try {
       let addresult = await AddNewGrooveWPQ(headerObj);
       if(addresult !== undefined){
          setaction(Action.ActionEdit);
      
        
        
        for (const item of filteredDetails) {
          
          
        
            try {
                const response =  AddNewDetail(item);
              
                setaction(Action.ActionEdit);
               
                result = true;
            } catch (error) {
                console.error("Error adding detail:", error);
            }
        }

       }
       else{
        alert(`Special Characters # & % + - ${""} ' < > = ; not allowed`);
       }
    } catch (error) {
        console.error("Error adding header:", error);
    }

    return result;
    };

    const fetchProcess = async () => {
      const result = await getActiveProcess();
      const process = [{ wld_code: "NA" }, ...result];
      setProcessActive(process);
    };

    const fetchPNo = async () => {
      const result = await getPNOActive();


      setPNoActive(result);
    };



    const fetchWPSNo = async () => {
      const result = await getAllWPSNo();
      setWPSNo(result);
    };

   

    const fetchWelder = async () => {
      const result = await getActiveWelder();
      // Check if wld_salarycode is empty, if so, use wld_code as wld_salarycode
      const modifiedResult = result.map(welder => ({
          ...welder,
          wld_salarycode: welder.wld_salarycode || welder.wld_code,
      }));
      setWelderActive(modifiedResult);
 
  };

    const fetchInspecAgency = async () => {
      const result = await getActiveInsp();
      setInspecAgencyActive(result);
    };

    useEffect(() => {
      fetchProcess();
      fetchPNo();
      fetchInspecAgency();
      fetchWPSNo();
      fetchWelder();
    }, []);

    const handleSubmit = async () => {
      
      debugger
      let result = false;
      var val = await validate();

        if (val.isError === false){
          if ( val.temperror === false) {
            setLoading(true);
        result = await handleAdd(Status.StatusTempSaved);
        setLoading(false);

        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } 
        return MessageTypes.SpecialCharacters;
      
    }
        return MessageTypes.Required;
  

      return false;
    };

    const handleSubmitTemp = async () => {
      let result = true;
      var val = await validateTemp();  
          if (val.isError === false){
            if ( val.temperror === false) {
setLoading(true);
              result =  await handleAdd();
setLoading(false);

          setIsEdited(false);

          if (result == true) {
            return MessageTypes.Temp;
          }
          else if(result == MessageTypes.SpecialCharacters )
            return MessageTypes.SpecialCharacters
        else{
            return MessageTypes.Fail
          }
          
        }
        return MessageTypes.SpecialCharacters;
      }
      else
           return MessageTypes.Required;
    };
  


    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp: async () => {
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));

    const green = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "green" : "green",
      }),
    };


    let statusprocess = detailObj?.filter((item) => item.remainingday !== "");

    let filledprocess = statusprocess.filter((item) => item.WPQ_Process !== "");



    const inactiveCount = filledprocess.filter(
      (item) => item.remainingday === "InActive"
    ).length;

    const switchChecked = inactiveCount !== filledprocess.length;


    return (
      <Row className="font">
          {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <Row style={{ padding: "20px 60px" }} className="WPQ-Page-row-padding">
          <Row className="mb-2">
            <Col md={3}>
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelectWelder
                    id="WPQ_WelderId"
                    name="WPQ_WelderId"
                    className={error.WPQ_WelderId.status ? "err-input" : ""}
                    stylegreen={
                      error.WPQ_WelderId.status ? errorstyle : normalstyle
                    }
                    data={WelderActive}
                    
                    valueField={"wld_salarycode"}
                    disabled={action === Action.ActionEdit ? true : false}
                    value={headerObj?.WPQ_WelderId || ""}
                    labelField1={"wld_salarycode"}
                    labelField2={"wld_name"}
                    placeholder=""
                    onChange={(List) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_WelderId: List.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WelderId: {},
                      }));
                      let a = WelderActive.filter((b) => {
                        return b.wld_salarycode == List.value;
                      });
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_WelderId: a[0].wld_salarycode,
                        WPQ_WelderName: a[0].wld_name,
                      }));
                    }}
                  ></SearchSelectWelder>
                </div>
                <label>Welder Id</label>
                <div className="required-text-select">
                  {error.WPQ_WelderId.status && (
                    <Form.Text className="text-danger">
                      {error.WPQ_WelderId.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPQ_WelderName"
                    name="WPQ_WelderName"
                    readOnly
                    placeholder=" "
                    disabled={action === Action.ActionEdit ? true : false}
                    maxLength={20}
                    value={headerObj?.WPQ_WelderName}
                    onChange={(e) => {
                      handleChange(e);
                      if (action === Action.ActionCopy) {
                        setIsReadonly(false);
                      }
                    }}
                  />
                  <label for="WPQ_WelderName">Welder Name</label>
                </div>
              </Form.Group>
            </Col>
            <Col md={3} style={{position:"relative",right:"3px"}}>
              <Date_Input
                divClass="T-input w-100"
                label="Welding Date"
                className="groove_datecolor"
                name="WPQ_Date"
                               todayDate={todayDate}
                maxDate={maxDate}
          
                readOnly={isReadonly}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
               
                status={error.WPQ_Date.status}
                message={error.WPQ_Date.message}
               
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="WPQ_RecordNo"
                  type="text"
                  maxLength={22}
                  status={error.WPQ_RecordNo.status}
                  disabled={action === Action.ActionEdit ? true : false}
                  value={headerObj?.WPQ_RecordNo}
                  onChange={handleChange}
                  readOnly={isReadonly}
                  label="WPQ Record Number"
                  message={error.WPQ_RecordNo.message}
                  setError={setError}
                  error={error}
                
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="WPQ_Wps_RecordNo"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.WPQ_Wps_RecordNo.status}
                  value={headerObj?.WPQ_Wps_RecordNo}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="WPS Record Number"
                  message={error.WPQ_Wps_RecordNo.message}
                 
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="WPQ_ReviNo"
                  type="text"
                  maxLength={4}
                  status={error.WPQ_ReviNo.status}
                  readOnly={isReadonly}
                  value={headerObj?.WPQ_ReviNo}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="Rev.No"
                  message={error.WPQ_ReviNo.message}
                  
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3} className="mb-1">
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelect
                    required
                    data={InspecAgencyActive}
                    stylegreen={
                      error.WPQ_Inspection.status ? errorstyle : normalstyle
                    }
                    disabled={isDisabled}
                    valueField={"wld_code"}
                    value={headerObj?.WPQ_Inspection}
                    labelField={"wld_code"}
                    placeholder=""
                    className={error.WPQ_Inspection.status ? "err-input" : ""}
                    onChange={(pnoList) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_Inspection: pnoList.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Inspection: {},
                      }));
                    }}
                  ></SearchSelect>
                </div>
                <label>Inspection Agency</label>
              </Form.Group>
              <div className="required-text-select">
                {error.WPQ_Inspection.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Inspection.message}
                  </Form.Text>
                )}
              </div>
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="T-input w-100"
                label="Date Of Birth"
                className="groove_datecolor"
                name="WPQ_DOB"
                               todayDate={todayDate}
                maxDate={maxDate}
                // disabled={Readonly}
                readOnly={isReadonly}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
               
                status={error.WPQ_DOB.status}
                message={error.WPQ_DOB.message}
              
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="WPQ_Qualificationcode"
                  type="text"
                  maxLength={22 }
                  readOnly={isReadonly}
                  status={error.WPQ_Qualificationcode.status}
                  value={headerObj?.WPQ_Qualificationcode}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="Qualification Code"
                  message={error.WPQ_Qualificationcode.message}
                 
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="T-input w-100"
                label="Last Updated Date"
                className="groove_datecolor"
                name="WPQ_Last_UpdatedDate"
                               todayDate={todayDate}
                maxDate={maxDate}
             
                readOnly={isReadonly}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
          
                status={error.WPQ_Last_UpdatedDate.status}
                message={error.WPQ_Last_UpdatedDate.message}
                
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                 
                  name="WPQ_Last_UpdatedBy"
                  type="text"
                  readOnly={isReadonly}
                  value={
                    action === Action.ActionEdit
                      ? headerObj?.WPQ_Last_UpdatedBy
                      : username
                  }
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="Last Updated By"
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3} className="mb-1">
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelect
                    required
                    data={WeldedCoupon}
                    stylegreen={
                      error.WPQ_Welded_on_coupon.status
                        ? errorstyle
                        : normalstyle
                    }
                    readOnly={isReadonly}
                    disabled={isDisabled}
                    valueField={"WPQ_Welded_on_coupon"}
                    value={headerObj?.WPQ_Welded_on_coupon}
                    labelField={"WPQ_Welded_on_coupon"}
                    placeholder=""
                    className={
                      error.WPQ_Welded_on_coupon.status ? "err-input" : ""
                    }
                    onChange={(pnoList) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_Welded_on_coupon: pnoList.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Welded_on_coupon: {},
                      }));
                    }}
                  ></SearchSelect>
                </div>
                <label>Welded On Coupon</label>
              </Form.Group>
              <div className="required-text-select">
                {error.WPQ_Welded_on_coupon.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Welded_on_coupon.message}
                  </Form.Text>
                )}
              </div>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  readOnly={isReadonly}
                  name="WPQ_CompanyName"
                  type="text"
                  value={company_name[0]?.value}
                  label="Company name"
                  setError={setError}
                  error={error}
                  
                ></Single_Input>
              </Form.Group>
            </Col>

         
            <Col md={3}>
              <Form.Group>
                <General_Single_Input_select
                  name="WPQ_Division"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  disabled={isDisabled}
                  status={error.WPQ_Division.status}
                  value={headerObj?.WPQ_Division}
                  handleSelectChange={handleChange}
                  label="Division"
                  message={error.WPQ_Division.message}
                  array={Plant_Location_GO}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="form-switch-input">
                <Row>
                  <Col>
                    {" "}
                    <div class="T-input"></div>{" "}
                    <label
                      style={{
                        fontSize: "14px",
                        padding: "10px",
                        color: "var(--col-707070)",
                        fontWeight: "bold",
                      }}
                    >
                      Status{" "}
                    </label>
                  </Col>
                  <Col className="ml-8" style={{position:"relative",right:"50px"}}>
                    <Form.Switch
                      label={
                        action === Action.ActionAdd
                          ? "Active"
                          : switchChecked
                          ? "Active"
                          : "InActive"
                      }
                      className={
                        action === Action.ActionAdd
                          ? "green"
                          : switchChecked
                          ? "green"
                          : "gray"
                      }
                      name="WPQ_Status"
                      style={{ padding: "10px" }}
                     
                      checked={
                        action === Action.ActionAdd ? true : switchChecked
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-margin mt-1">
            <Col md={3}>
              <Form.Group>
                <General_Single_Input_select
                  name="WPQ_PlantLocation"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  disabled={isDisabled}
                  status={error.WPQ_PlantLocation.status}
                  value={headerObj?.WPQ_PlantLocation}
                  handleSelectChange={handleChange}
                  label="Plant Location"
                  message={error.WPQ_PlantLocation.message}
                  array={Plant_Location_GO}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  
                  name="WPQ_specialNote"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.WPQ_specialNote.status}
                  value={headerObj?.WPQ_specialNote}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="Special Notes(for project to WPQ linking)"
                  message={error.WPQ_specialNote.message}
                 
                ></Single_Input>
              </Form.Group>
            </Col>
          </Row>
        </Row>

        <Row style={{ background: "white", padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative",top:"10px"}}>Base Metal (QW-403) </p>
              {isoverlay ? (
                <BaseMetalTable
                  headerObj={headerObj}
                  setheaderObj={setheaderObj}
                  error={error}
                  isReadonly={isReadonly}
                  handleChange={handleChange}
                  setError={setError}
                  PNoActive={PNoActive}
                  blyestyle={blyestyle}
                  errorstyle={errorstyle}
                  isDisabled={isDisabled}
                ></BaseMetalTable>
              ) : (
                <BaseMetalTable
                  headerObj={headerObj}
                  setheaderObj={setheaderObj}
                  error={error}
                  isReadonly={isReadonly}
                  handleChange={handleChange}
                  setError={setError}
                  PNoActive={PNoActive}
                  blyestyle={blyestyle}
                  errorstyle={errorstyle}
                  isDisabled={isDisabled}
                ></BaseMetalTable>
              )}
            </Col>
          </Row>

          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text "  style={{position:"relative",top:"10px"}}>Variables </p>
              {isoverlay ? (
                <WPQVariableTable

                headerObj={headerObj}
                style1={{ width: "0%" }}
                rowhead1="Actual Values"
                rowhead2="Range Qualified"
                colhead1="Type of Weld Joint"
                colhead2="Base Metal"
                colhead3="P Category"
                error={error}
                onInput1={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_VAR_TypeofJoint_actualValue: error,
                  }));
                }}
                onInput2={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_VAR_TypeofJoint_RangeQualified: error,
                  }));
                }}
                onInput3={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_VAR_BaseM_actualValue: error,
                  }));
                }}
                onInput4={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_VAR_BaseM_RangeQualified: error,
                  }));
                }}
                status1={error.WPQ_VAR_TypeofJoint_actualValue.status}
                status2={error.WPQ_VAR_TypeofJoint_RangeQualified.status}
                status3={error.WPQ_VAR_BaseM_actualValue.status}
                status4={error.WPQ_VAR_BaseM_RangeQualified.status}
                
                status6={error.WPQ_VAR_Pcategory_RangeQualified.status}
                message1={error.WPQ_VAR_TypeofJoint_actualValue.message}
                message2={error.WPQ_VAR_TypeofJoint_RangeQualified.message}
                message3={error.WPQ_VAR_BaseM_actualValue.message}
                message4={error.WPQ_VAR_BaseM_RangeQualified.message}
               
                message6={error.WPQ_VAR_Pcategory_RangeQualified.message}
                isReadonly={isReadonly}
                name1="WPQ_VAR_TypeofJoint_actualValue"
                name2="WPQ_VAR_TypeofJoint_RangeQualified"
                name3="WPQ_VAR_BaseM_actualValue"
                name4="WPQ_VAR_BaseM_RangeQualified"
               
                name6="WPQ_VAR_Pcategory_RangeQualified"
                value1={headerObj?.WPQ_VAR_TypeofJoint_actualValue}
                value2={headerObj?.WPQ_VAR_TypeofJoint_RangeQualified}
                value3={headerObj?.WPQ_VAR_BaseM_actualValue}
                value4={headerObj?.WPQ_VAR_BaseM_RangeQualified}
              
                value6={headerObj?.WPQ_VAR_Pcategory_RangeQualified}
                handleChange={handleChange}
                setError={setError}
                maxLength={35}
                PNoActive={PNoActive}
                setheaderObj={setheaderObj}
                ></WPQVariableTable>
              ) : (
                <WPQVariableTable
                  headerObj={headerObj}
                  style1={{ width: "39%" }}
                  rowhead1="Actual Values"
                  rowhead2="Range Qualified"
                  colhead1="Type of Weld Joint"
                  colhead2="Base Metal"
                  colhead3="P Category"
                  error={error}
                  onInput1={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_VAR_TypeofJoint_actualValue: error,
                    }));
                  }}
                  onInput2={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_VAR_TypeofJoint_RangeQualified: error,
                    }));
                  }}
                  onInput3={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_VAR_BaseM_actualValue: error,
                    }));
                  }}
                  onInput4={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_VAR_BaseM_RangeQualified: error,
                    }));
                  }}
                  status1={error.WPQ_VAR_TypeofJoint_actualValue.status}
                  status2={error.WPQ_VAR_TypeofJoint_RangeQualified.status}
                  status3={error.WPQ_VAR_BaseM_actualValue.status}
                  status4={error.WPQ_VAR_BaseM_RangeQualified.status}
                 
                  status6={error.WPQ_VAR_Pcategory_RangeQualified.status}
                  message1={error.WPQ_VAR_TypeofJoint_actualValue.message}
                  message2={error.WPQ_VAR_TypeofJoint_RangeQualified.message}
                  message3={error.WPQ_VAR_BaseM_actualValue.message}
                  message4={error.WPQ_VAR_BaseM_RangeQualified.message}
              
                  message6={error.WPQ_VAR_Pcategory_RangeQualified.message}
                  isReadonly={isReadonly}
                  name1="WPQ_VAR_TypeofJoint_actualValue"
                  name2="WPQ_VAR_TypeofJoint_RangeQualified"
                  name3="WPQ_VAR_BaseM_actualValue"
                  name4="WPQ_VAR_BaseM_RangeQualified"
                
                  name6="WPQ_VAR_Pcategory_RangeQualified"
                  value1={headerObj?.WPQ_VAR_TypeofJoint_actualValue}
                  value2={headerObj?.WPQ_VAR_TypeofJoint_RangeQualified}
                  value3={headerObj?.WPQ_VAR_BaseM_actualValue}
                  value4={headerObj?.WPQ_VAR_BaseM_RangeQualified}
                
                  value6={headerObj?.WPQ_VAR_Pcategory_RangeQualified}
                  handleChange={handleChange}
                  setError={setError}
                  maxLength={35}
                  PNoActive={PNoActive}
                  setheaderObj={setheaderObj}
                ></WPQVariableTable>
              )}
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "20px 60px" }} className="WPQ-Page-row-padding">
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text"  style={{position:"relative",top:"10px"}}>Base Metal Thickness</p>
              {isoverlay ? (
                <WPQ_BaseMetalThichkness_Table
                  headerObj={headerObj}
                  error={error}
                  isReadonly={isReadonly}
                  handleChange={handleChange}
                  setError={setError}
                ></WPQ_BaseMetalThichkness_Table>
              ) : (
                <WPQ_BaseMetalThichkness_Table
                  headerObj={headerObj}
                  error={error}
                  isReadonly={isReadonly}
                  handleChange={handleChange}
                  setError={setError}
                ></WPQ_BaseMetalThichkness_Table>
              )}
            </Col>
          </Row>
        </Row>

        <Row style={{ background: "white", padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text "  style={{position:"relative",top:"10px"}}>Welding Process </p>
              <CommonTable4
             
                action = {action}
                Add = {Action.ActionAdd}
                setheaderObj={setheaderObj}
                headerObj={headerObj}
                detailObj={detailObj}
                setdetailObj={setdetailObj}
                ProcessActive={ProcessActive}
                isReadonly={isReadonly}
              
              />
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }
);

export default WPQ_General;
