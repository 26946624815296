import { Form,Col } from "react-bootstrap";
import { isAlphanumericAllSpecialCharacters } from "../../../functions/validations";
export const Single_Input_textara = ({
    status,
    type,
    name,
    error,
    value,
    handleChange,
    typeclass,
    onChange,
    style,
    readOnly,
    label,
    message,
    onInput,
    maxLength,
    disabled,
  }) => {
    return (
      <>
        <div class="T-input w-100">
          <textarea
            type={type}
            typeclass={typeclass}
            name={name}
            style={style}
            id={name}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            placeholder=" "
            className={
              status
                ? " T-input placeHolderInput inputText text-field-with-error"
                : "T-input placeHolderInput inputText"
            }
            value={value}
            onChange={handleChange}
            onInput={onInput}
          />
          <label for={name}>{label}</label>
        </div>
        <div className="required-text1">
          {status && <Form.Text className="text-danger">{message}</Form.Text>}
        </div>
      </>
    );
  };
  
  
  export const Single_Input = ({
    status,
    name,
    error,
    value,
    handleChange,
    typeclass,
    onChange,
    style,
    readOnly,
    label,
    message,
    onInput,
    maxLength,
    disabled,
    width,
    setError,
    md,
    divstyle,
  }) => {
    return (
      <>
       <Col md={md} style={style}>
  <Form.Group style={{width}}>
     <div class="T-input w-100">
      <input
        type="text"
        id={name}
        name={name}
        placeholder=" "
        disabled={disabled}
        maxLength={maxLength}
        className={status ? "err-input" : ""}
        value={value}
        // readOnly={readOnly}  
        onChange={onChange}
        // onInput={onInput}
        onInput={(e) => {
          let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            [name]: error1,
          }));
        }}
      />
      <label for={name}>{label}</label>
    </div>
    <div className="required-text">
      {status && (
        <Form.Text className="text-danger">
          {message}
        </Form.Text>
      )}
    </div>
  </Form.Group>
  </Col>
      </>
    );
  };
  
  
  export const Single_Input_textaraNotes = ({
    status,
    type,
    name,
    error,
    value,
    handleChange,
    typeclass,
    onChange,
    style,
    readOnly,
    label,
    message,
    onInput,
    maxLength,
    disabled,
    onKeyDown,
    className
  }) => {
    return (
      <>
        <div class="T-input w-100">
          <textarea
            type={type}
            typeclass={typeclass}
            name={name}
            style={style}
            id={name}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            placeholder=" "
            className={
              status
                ? " T-input placeHolderInput inputText text-field-with-error"
                : "T-input placeHolderInput inputText"
            }
            value={value}
            onChange={handleChange}
            onInput={onInput}
           
          />
          <label for={name}>{label}</label>
        </div>
        <div className="required-text1">
          {status && <Form.Text className="text-danger">{message}</Form.Text>}
        </div>
      </>
    );
  };

  export const General_Single_Input_select = ({
    status,
    type,
    name,
    value,
    handleSelectChange,
    readOnly,
    label,
    message,
    array,
  }) => {
    return (
      <>
        <div class="T-input w-100">
          <select
            id={name}
            name={name}
            readOnly={readOnly}
            placeholder=" "
            value={value}
            className={status ? "err-input" : ""}
            onChange={handleSelectChange}
          >
            {array &&
              array.map((e, id) => {
                return (
                  <option key={id} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
          </select>
          <label for={name}>{label}</label>
        </div>
        <div className="required-text">
          {status && <Form.Text className="text-danger">{message}</Form.Text>}
        </div>
      </>
    );
  };

  export const General_Single_Input_select_green = ({
    status,
    type,
    name,
    value,
    handleSelectChange,
    readOnly,
    label,
    message,
    array,
  }) => {
    return (
      <>
        <div class="green-input w-100">
          <select
            id={name}
            name={name}
            readOnly={readOnly}
            placeholder=" "
            value={value}
            className={status ? "err-input" : ""}
            onChange={handleSelectChange}
          >
          
            {array &&
              array.map((e, id) => {
                return (
                  <option key={id} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
          </select>
          <label for={name}>{label}</label>
        </div>
        <div className="required-text">
          {status && <Form.Text className="text-danger">{message}</Form.Text>}
        </div>
      </>
    );
  };