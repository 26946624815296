import { useState, useRef, useEffect } from "react";
import { Row,  Tabs, Tab, Button } from "react-bootstrap";
import WeldingProcedure from "./WeldingProcedure";
import General from "./General";
import WeldingProcedureSpec from "./WeldingProcedureSpec";
import { AddRowsDetailsoBj, DisableFields,createDetailObj } from "./WPSObjects/WPS_common";
import { useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";


import {

  getbyprojectno,
} from "../../Services/wps_groove-services";
import { getdetailsById } from "../../Services/wps_details-service";
import {
  MessageTypes,
  
  WPSHeader,
} from "./WPSObjects/WPSGroove_entity";
import { ToastContainer, toast } from "react-toastify";
import { Action, Status } from "./WPSObjects/WPSGroove_entity";
const GrooveTransaction = () => {
  const [key, setKey] = useState(1);

  const firstChildRef = useRef();
  const secondChildRef = useRef();
  const thirdChildRef = useRef();

  const location = useLocation();

  const [loading, setLoading] = useState(true);


  let companyname = "Godrej & Boyce Mfg. Co. Ltd";
  let UserName = sessionStorage.getItem("UserName");
  let UserId = sessionStorage.getItem("UserId");

  const [copydata, setcopydata] = useState("");
  const [action, setaction] = useState(location.state?.action);
  const [originalaction, setoriginalaction] = useState(location.state?.action);
  const [WPStype, setWPStype] = useState(location.state?.WPStype);

  const height = "200px";
  const [headerObj, setheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : WPSHeader(WPStype)
  );
  const [IsEditedPage1, setIsEditedPage1] = useState(false);
  const [IsEditedPage2, setIsEditedPage2] = useState(false);
  const [IsEditedPage3, setIsEditedPage3] = useState(false);
  const [isReadonly,setreadOnly] = useState(false)
  const [isBtnDisabled,setBtnDisabled] = useState("")
  let Message = MessageTypes.Message;

  const [detailObj, setdetailObj] = useState([]);
  const temparray = [];
  useEffect(() => {
    if (action === Action.ActionAdd) {
      let temparray = createDetailObj(1,WPStype);

      setdetailObj(temparray);
    }
  }, []);



  const [logObj, setlogObj] = useState({
    WPSH_Project_No_log:
      headerObj != undefined ? headerObj.WPSH_Project_No : "",
    WPSH_WPS_No_log: headerObj != undefined ? headerObj.WPSH_WPS_No : "",
    WPSH_User_Name_log: UserName,
    WPSH_User_Id_log: UserId,
    WPSH_Status_log: "",
  });

  

  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    const result = await getbyprojectno(WPStype,IdprojectPK, IdprojectSK);
    
    if(result != undefined) setheaderObj(result[0]);
  };

  const fetchbyid = async (IddetailPK, IddetailSK) => {
    const result = await getdetailsById(IddetailPK, IddetailSK);

    if (action !== Action.ActionAdd) {
  

      AddRowsDetailsoBj(result,setdetailObj)
    
    }


  };

  useEffect(() => {
   
    
    if (action != Action.ActionAdd) {
      let IdprojectPK = location.state?.data.WPSH_Project_No;
      let IdprojectSK = location.state?.data.WPSH_WPS_No;

      fetchprojectbyid(IdprojectPK, IdprojectSK);
     
      headerObj.WPSH_UpdatedBy = (new Date()).toLocaleDateString('en-GB')
      headerObj.WPSH_UpdatedOn = sessionStorage.getItem("User")
      fetchbyid(IdprojectPK, IdprojectSK);


   
    }

    setTimeout(() => {

      setLoading(false);
    }, 500);


 
  }, []);

  useEffect(()=>{

    let read = DisableFields(headerObj,Status,Action,action)

    if (action === Action.ActionCopy)
      setBtnDisabled("")
    else if(read)
      setBtnDisabled("disabled")
    else
       setBtnDisabled("")

    setreadOnly(read) 

  },[headerObj.WPSH_Status,headerObj.WPSH_Prepared_By])
 

  useEffect(() => {

    const alertUser = e => {
      e.preventDefault()
      e.returnValue = ''
    }
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', alertUser)
        return () => {
          window.removeEventListener('beforeunload', alertUser)
          window.removeEventListener('unload', alertUser)
         
        }
   
  }, [IsEditedPage1, IsEditedPage2, IsEditedPage3]);



  
  const next = async (e) => {
    let res = false;


    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNext();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNext2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNext();
        break;
      default:
        break;
    }
    

    if (res == MessageTypes.Success) {
      toast.success("WPS Data Added ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (key !== 3) setKey(+key + 1);
    } else if (res == MessageTypes.Fail) {
      toast.warning("Failed, WPS Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning(process.env.REACT_APP_SPECIAL_CHARACTER_MSG, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.RequiredAll) {
      toast.warning(
        "Required Fields not filled.Please check and Fill Required Fields for all 3 Pages.",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res == MessageTypes.Band) {
      toast.warning(
        "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  const handleSelect = (e) => {

    Temp(e, "tab");

  };
  const Temp = async (e, tabaction) => {
    let res = false;

    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNextTemp();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNextTemp2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNextTemp();
        break;
      default:
        break;
    }
    if (res == MessageTypes.Temp) {
      toast.success("Temporary Saved WPS Data Successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (tabaction == "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction == "next") {
        if (key !== 3) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res == MessageTypes.None) {
      if (tabaction == "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction == "next") {
        if (key !== 3) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res == MessageTypes.Fail) {
      toast.warning("Failed, WPS Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Required) {
      toast.warning("Required Fields not filled", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning("Special Characters # & % + - ' < > = ; not allowed", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Band) {
      toast.warning(
        " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  return (
     <>
          {loading && (
  <div className="loader-overlay">
    <GridLoader size={10} className="pageloader" />
  </div>
)}
      <div className="position-absolute ms-3" style={{ top: "15px", display:"flex" }}>
        <strong>Add New</strong>
        &nbsp;&nbsp;
        <span className="ms-1 warning-text" style={{ fontSize: "small",  position:"relative", top:"3px"  }}>
          (All fields are mandatory)  
        </span>
        &nbsp;
        <div style={{ height:"30px" , width:"425px"}} className="info_bg">
        <span className="ms-1 transnote info-text" style={{ fontSize: "small" }}>
        NOTE: Green color fields will be used for validation in WI session
        </span>
        </div>
      </div>

      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <div className="mt-4">

        <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div>

        <Tabs
          activeKey={key}
          onSelect={(e) => handleSelect(e)}
          className="mb-2 w-100 border-end"
          fill
        >
          {WPStype === "Overlay" ?
          <Tab
            eventKey={1}
            title="General (Overlay)"
            className="bordertab"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
              borderBottom:"1px solid var(--col-ddd) "
            }}

          >
            <General
              ref={firstChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              logObj={logObj}
              createDetailObj={createDetailObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              setIsEditedPage1={setIsEditedPage1}
              isReadonly={isReadonly}
            />
          </Tab>
          : 
          <Tab
          eventKey={1}
          title="General (Groove)"
          className="bordertab"
          style={{
            height: window.innerHeight - 180,
            overflowY: "auto",
            overflowX: "hidden",
            borderBottom:"1px solid var(--col-ddd) "
          }}

        >
          <General
            ref={firstChildRef}
            headerObj={headerObj}
            setheaderObj={setheaderObj}
            logObj={logObj}
            createDetailObj={createDetailObj}
            detailObj={detailObj}
            setdetailObj={setdetailObj}
            WPStype={WPStype}
            setKey={setKey}
            tabno={key}
            toast={toast}
            setIsEditedPage1={setIsEditedPage1}
            isReadonly={isReadonly}
          />
        </Tab>
}
          <Tab
            eventKey={2}
            title="Welding Procedure"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
              width:"99%",
              borderBottom:"1px solid var(--col-ddd)"
            }}
            disabled={action == Action.ActionAdd ? true : false}
          >
            <WeldingProcedure
              ref={secondChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              setIsEditedPage2={setIsEditedPage2}
              isReadonly={isReadonly}
            />
          </Tab>
          <Tab
            eventKey={3}
            title="Welding Procedure Specification"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
              borderBottom:"1px solid var(--col-ddd)"

            }}
            disabled={action == Action.ActionAdd ? true : false}
          >
            <WeldingProcedureSpec
              ref={thirdChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              setIsEditedPage3={setIsEditedPage3}
              setBtnDisabled={setBtnDisabled}
            />
          </Tab>
        </Tabs>

        <Row style={{ padding: "5px 90px" }}>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              className="me-2"
              style={{ display: key === 1 ? "none" : "  ", width:"100px", height:"38px"}}
              onClick={(e) => Temp(e, "prev")}
              disabled={isBtnDisabled}
            >
              Prev
            </Button>
            <Button
              size="sm"
              className="float-end  me-2 btn-primary"
              onClick={(e) => next(e)}
              disabled = {isBtnDisabled}
              style={{width:'100px', height:"38px"}}

            
            >
              {key === 3 ? "Done" : "Next"}
            </Button>
            {originalaction !== Action.ActionAdd ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary "
                className="float-end  me-2"
                onClick={(e) => Temp(e, "next")}
                style={{width:'100px', height:"38px"}}

                
              >
                {key === 3 ? "Temp Save" : "Temp Save"}
              </Button>
            )}
          </div>
        </Row>
        
     
      </div>
    </>
  );
};

export default GrooveTransaction;
