import { useState } from "react";
import {  BiX } from "react-icons/bi";
import SearchIcon from "../../icons/SearchIcon.svg";


const GolbalSearch = (props) => {


  const [globalText, setGlobalText] = useState("");

  return (
    <div
      className={`float-end m-1 wcs-search-box`}
      //className={`m-1 wcs-search-box`}
    >
      <div>
        <img src={SearchIcon} alt="search" style={{ cursor: "pointer" }} />
        <input
          style={{ width: "270px" }}
          type="text"
          placeholder={props.searchplaceholdertext}
          value={globalText}
          onChange={(e) => {
            setGlobalText(e.target.value);
            props.filterFunc(e.target.value);
          }}
        />
        <BiX
          size={20}
          color="black"
          style={{ cursor: "pointer", color: "var(--col-707070)", float: "right", margin: "6px"}}
          onClick={(e) => {
            setGlobalText("");
            props.filterFunc("");
          }}
        />
      </div>
    </div>
  );
};

const handleGlobalSearch1 = (
  text,
  customers,
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  col7
) => {
  let searchText = text.toLowerCase();
  if (searchText) {
    let result = customers.filter((item) => {
      return (
        item[col1]?.toLowerCase().indexOf(searchText) >= 0 ||
        item[col2]?.toLowerCase().indexOf(searchText) >= 0 ||
        item[col3]?.toLowerCase().indexOf(searchText) >= 0 ||
        item[col4]?.toLowerCase().indexOf(searchText) >= 0 ||
        item[col5]?.toLowerCase().indexOf(searchText) >= 0 ||
        item[col6]?.toLowerCase().indexOf(searchText) >= 0 ||
        item[col7]?.toLowerCase().indexOf(searchText) >= 0
      );
    });


    return result;
  } else {
    return customers;
  }
};

export default GolbalSearch;

export { handleGlobalSearch1 };
