import {  Row, Col, Container } from "react-bootstrap";

import {
  getAllRoles,
  UpdateRole
} from "../../Services/role-service";

import {
  useEffect,

  forwardRef,
  useImperativeHandle,
  useRef,
  useState,

} from "react";
import AddEditRoleMaster from "./AddEditRoleMaster";
import GolbalSearch from "../utility/GolbalSearch";

import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";

import GlobalColumnDefinations , { getHeader_FieldName } from "../utility/AgGridDefination";
import { useNavigate } from "react-router-dom";
import { handleExport1 } from "../utility/GlobalExport";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import GridMaster from "../utility/GridMaster";
import ActionRenderer from "../utility/ActionRenderer";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";


const RoleMaster = forwardRef((props, ref) => {

  const [roles, setRoles] = useState(null);
  const [filteredResult, setFilteredResult] = useState([]);

  const [locObj, setLocObj] = useState({
    rm_status: "",
    SK: "",
    rm_rolename: "",
    rm_menuaccess: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [rowData, setRowData] = useState({});

  const [reload, setReload] = useState(false);

  const [data, setdata] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [show1, setShow1] = useState(false);
 const msg = "";
  const [columnDefs, setcolumnDefs] = useState([]);


  const [excelData1, setExcelData] = useState([]);
  const childRef = useRef();

  let formName = "Role And Authorization";
  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "rm_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "statusRenderer",
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: "Role Code",
      field: "SK",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Role Description",
      field: "rm_name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "List of session",
      field: "rm_role_session",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];
  const fetchRoleMaster = async () => {
    const result1 = await getAllRoles();
    setRoles(result1);
    setFilteredResult(result1);
  };

  useEffect(() => {
    
    fetchRoleMaster();
    fetchColumnDefns();
  }, [reload]);

  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ field: "", cellRenderer: actionRenderer, width: 250 ,  suppressMenu: true,
    sortable:false,});
    setcolumnDefs(result);
  };

  const handleDelete = async (row) => {
    let result;
    let indication;
    data.rm_status = "Inactive";
    UpdateRole(data)
      .then((resp) => {
        if (action === "Delete") {
          setShowForm(false);
          toast.success(`Selected Role has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        } else {
          toast.warning("Error!!!, Not able to deactivate Role.", {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        console.log("Unable to process request");
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  const resetForm = () => {
    document.filterForm.reset();
    setLocObj({
      rm_status: "",
      SK: "",
      rm_name: "",
    });
    setFilteredResult(roles);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };



  const handleGlobalSearch = (text) => {
    
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);

   
  };

  const handleGlobalExport = () => {
    let filename = "RoleMaster";
    setExcelData(filteredResult);
    handleExport1(excelData1, filename, tmpcolumnDefs);
  };



  const history = useNavigate();
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setUser",
    });
  };
  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      handleAdd: async () => {

        setAction("Add");
        setShowForm(true);
      },
    }),
    []
  );

  return (
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Roles list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for Role Code, Role Description,List of Sessions"
              }
              filterFunc={handleGlobalSearch}
            />
            {/* <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
        paginationPageSize={6}
        pagination={true}
        // height={500}
      />

      {showForm && (
        <AddEditRoleMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          roles={roles}
          setReload={setReload}
          reload={reload}
          resetForm={resetForm}
          onHide={() => setShowForm(false)}
          formName={formName}
        />
      )}

      <Toaster />
    </Container>
  );
});

export default RoleMaster;
