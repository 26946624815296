export const CommanTable_4Rows_WPQTTS_ROTest = ({pdf, field1,heading,field2,field3,field4,Notes,Common_Testing_Examination,type,reportvalue,
    value1,value2,value3,value4,ReportNumber}) => {
    return(
    <>
          <div className="WPQgroup" style={{  width: "570px"}}>
                <div className="WPQbasemetal" >
                {heading}
                </div>

            </div>

            <div>
                <div style={{ paddingTop: "1px" }}></div>
                <table id="table" className="pdfwpqTable" style={{ width: "570px" }}>
                    <tbody>
                        <tr>
                            <th className="pdfLeftBorder pdfTopBorder pdfBorderBottom" rowSpan="2" style={{ width: "28%" }}>
                            {field1}
                            </th>
                            <th className="pdfLeftBorder pdfTopBorder pdfBorderBottom" rowSpan="2" style={{ width: "20%" }}>
                                {field2}
                            </th>
                            <th className="pdfLeftBorder pdfTopBorder pdfBorderBottom" rowSpan="2" style={{ width: "28%" }}>
                            {field3}
                            </th>
                            <th className="pdfLeftBorder pdfTopBorder pdfBorderBottom pdfRightBorder" rowSpan="2" style={{ width: "25%" }}>
                                {field4}
                            </th>
                        </tr>
                        <br></br>
                        {Common_Testing_Examination(type,value1,value2,value3,value4)}
                    </tbody>
                   
                </table>
                
                <table id="table" className="pdfwpqTable" style={{ width: "570px" }}>
                    <tbody>
                    {ReportNumber && <tr><th className="WPQbasemetalreport pdfTopBorder pdfLeftBorder">
                         {ReportNumber}
                     </th>
                         <td colspan="3" className="pdfTopBorder pdfRightBorder pdfLeftBorder"  style={{ padding: "1px 4px", width:"272%" }}>

                             {pdf[1]?.[reportvalue]}
                         </td></tr>}

                       
                    <tr>
                        <th className="WPQbasemetal1  pdfLeftBorder pdfTopBottomRight " style={{  padding: "1px 4px"}}>
                        {Notes}
                    </th>
                        <td colspan="3"  className="pdfTopBottomRight  " style={{ padding: "1px 4px", width:"270%" }}>

                            {pdf[1]?.WPQ_Radio_Notes}
                        </td>

                    </tr>
                    </tbody>
                </table>
            </div>

       
            
    </>
   )
   }