import axios from "axios";

let WCS_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  WCS_API_URL = process.env.REACT_APP_WCS_API_URL;
} else {
  WCS_API_URL = process.env.REACT_APP_API_URL + "wcs";
}



export async function getAllWCS(type) {
  const response = await axios
    .get(WCS_API_URL + `/WcsHeaderMast/${type}`)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getGridHeaderData(band, user) {

  const response = await axios

    .get(WCS_API_URL + `/WcsHeaderMast/grid/${band}/${user}`)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getGridDetailData(WCS_Project_No, user) {

  let reqData = { WCS_Project_No: WCS_Project_No, user: user };
  const response = await axios
    // .get(WCS_API_URL + `/WcsHeaderMast/grid/${type}/${band}/${user}`)
    .post(WCS_API_URL + `/WcsHeaderMast/detailGrid`,reqData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllWPSNo() {
  const response = await axios
    .get(WCS_API_URL + "/WcsHeaderMast/allwpsno")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getbyprojectno = async (IdprojectPK, IdprojectSK) => {
  let url = `${WCS_API_URL}/WcsHeaderMast/getprojectnowcs`;
  let reqData = { PK: IdprojectPK};

  const response = await axios
    .post(url,reqData)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};

export const AddNewWCS = async (WCS_Req) => {


  let url = `${WCS_API_URL}/WcsHeaderMast`;
  const response = await axios
    .post(url, WCS_Req)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};

export const AddNewCount = async (WPS_Header_Master) => {


  let url = `${WCS_API_URL}/WcsHeaderMast`;
  const response = await axios
    .post(url, WPS_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateWCS = async (WCS_Header_Master) => {
  let url = `${WCS_API_URL}/WcsHeaderMast/update/${WCS_Header_Master.SK}`;
  const response = await axios
    .post(url, WCS_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteWCSDetail(gridDetail) {
  let url = `${WCS_API_URL}/WcsHeaderMast/deleteDetail`;

  const resp = await axios
    .post(url, gridDetail)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export const UpdateWCSStatus = async (WCS_Header_Master) => {

  let url = `${WCS_API_URL}/WcsHeaderMast/updateStatus`;
  const response = await axios
    .post(url, WCS_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getWCSDataHeader(wcsheader) {
  
  let url = `${WCS_API_URL}/WcsHeaderMast/getwcsforWI`;

  const resp = await axios
    .post(url, wcsheader)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}

export const UpdateDetailWCS = async (WCS_Detail_Master) => {

  let url = `${WCS_API_URL}/WcsHeaderMast/updateDetail`;
  const response = await axios
    .post(url, WCS_Detail_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};

//Added by Amol Pawar on 25/10/23
//To upload excel data
//from here
export const uploadWCSexceldata = async (data) => {

  let url = `${WCS_API_URL}/WcsExcelDataUpload/uploadwcsexceldata`;
  
  const response = await axios
    .post(url, data)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};
//till here

export const checkValidJointNo = async (reqData) => {

  let url = `${WCS_API_URL}/WcsHeaderMast/getjointno`;
  const response = await axios
    .post(url, reqData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });


  let resArray = response.data["foundJointNo"] || response.data["abc"]


  return resArray;
};

export async function checkWPSNoPresent(gridDetail) {

  let url = `${WCS_API_URL}/validateWPSinWCS/checkWPSNo`;

  const resp = await axios
    .post(url, gridDetail)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
