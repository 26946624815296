import { useEffect, useState } from "react";
import SearchIcon from "../../icons/Back Arrow-1.png";


import SingleValuePopUp from "./SingleValuePopUp";

export const ItemGroup = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [WPS, setWPS] = useState(props.data[props.colDef.field]);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });
  useEffect(() => {

  }, []);
  const showPopUp = (name, type, key, value) => {

    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = (data) => {


    setWPS(data.result.WPSH_WPS_No);
    props.node.setDataValue(props.colDef.field, data.result.WPSH_WPS_No);
    props.stopEditing();
    //props.node.setDataValue("isModified",true)
    props.data["isModified"] = true;
    props.data["hasError"] = false;

    // props.api.refreshCells({columns: [props,props.colDef.field] })
  };

  const onChange = (e) => {

    setWPS(e.target.value);

    props.node.setDataValue(props.colDef.field, e.target.value);
    props.data["isModified"] = true;

    //props.stopEditing()
  };
  return (
    <>
      <div className="itemGroup"
      >
        <div style={{ width: "88%", height: "100%", verticalAlign: "top" }}>
          <input
            type="text"
            value={WPS}
            style={{
              width: "100%",
              height: "100%",
              verticalAlign: "top",
              border: "0",
              outline: "none",
            }}
            onChange={(e) => onChange(e)}
            onBlur={(e) => {
              props.stopEditing();
            }}
          />
        </div>
        <div style={{ width: "20%", height: "90%", verticalAlign: "top" }}>
          <img
            src={SearchIcon}
            alt="search"
            style={{ cursor: "pointer", verticalAlign: "top" }}
            onClick={() =>
              showPopUp("ItemGroup", "Single", "ItemGroup", popUp.value)
            }
          />
        </div>
        {showForm && (
          <SingleValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            backdrop="static"
            Project={props.data.SK}
          />
        )}
      </div>
    </>
  );
};
