//TABLE WITH 2 ROWS AND 2 COLUMNS (WPQ- VARIABLES AND WELDING PROCESS)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";

import Error from "../../../icons/ErrorIcon.svg";
import { TypeData,LayerData } from "../../WPSTransaction/WPSObjects/WPSGroove_entity";
import { SearchSelect } from "../../utility/search-select";
import { Action } from "./PQR_entity";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { ToastContainer, toast } from "react-toastify";
import { DeletePQR } from "../../../Services/pqr-details-service";
import 
{createDetailObjPQR
} from "./PQR_common";
export const PQRProcess = ({

  headerObj,
  detailObj,

  setdetailObj,
  ProcessActive,
  isReadonly,

}) => {
  const location = useLocation();
  const [show, setShow] = useState();
  const [action, setaction] = useState(location.state?.action);
  const[processdelete,setprocessDelete] = useState(99)

  let formName = "PQR Process";


  const processDisabled = (i) => {
    if (
      action === Action.ActionEdit
    ) {
      return true;
    } else if (headerObj?.WPSH_Status === "Temporary Saved") {
      return true;
    }
  };

  const processDelete = async() => {
  
  setShow(false)
  

  await DeletePQR(detailObj[processdelete].PK,detailObj[processdelete].SK).then(
    toast.success("PQR Process Deleted ", {
      position: toast.POSITION.TOP_LEFT,
      className: "toast-message",
    })
  ).catch((ex) =>{
    toast.success("PQR Process could not be deleted ", {
      position: toast.POSITION.TOP_LEFT,
      className: "toast-message",
    });
  })
  
  let newdet = detailObj.filter((det,i) => 
    !(i == processdelete))
  
  let temparray1 = createDetailObjPQR(4, 4);

  setdetailObj([...newdet,...temparray1])

  }
  return (
    <>
     <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    <Table className="text-center tablewidth" style={{position:"relative", bottom: "8px"}}>
      <thead>
        <tr className="border thead-color ">
       
          <th className="thickness_title" style={{ width: "21%" }}></th>
          <th className="thickness_title" style={{ width: "22%" }} >Process</th>
          <th className="thickness_title" style={{ width: "22%" }}>Type</th>
         { headerObj?.wld_WPSType === "Overlay" ? 
         <th className="thickness_title" style={{ width: "22%" }}>Layer</th> : 
          <th className="thickness_title"></th>}
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
        </tr>
      </thead>

      <tbody>
        {detailObj &&
          detailObj?.map((item, i) => {
            return (
              <tr className={detailObj[i]?.error ? "border  error" : "border "}>
                <th
                  className={
                    detailObj[i]?.error
                      ? "text-start border thead-color error-head"
                      : "text-start border thead-color"
                  }
                >
                  <div key={i}>
                    <label>Process {i + 1}</label>
                    {detailObj[i]?.error && (
                      <Tooltip content={detailObj[i]?.errormessage}>
                        <img src={Error} alt="Error" className="ms-1" />
                      </Tooltip>
                    )}
                  </div>
                </th>
                <td className="PQRSelect" style={{ paddingLeft: "2%" }}>
                  <SearchSelect
                    className="inspdrop "
                    data={ProcessActive}
                    key={i}
                    readOnly={
                      action === Action.ActionCopy ||
                      action === Action.ActionEdit
                        ? isReadonly
                        : false
                    }
                    valueField={"wld_code"}
                   
                    placeholder=""
                    value={item?.PQR_Process}
                    labelField={"wld_code"}
                    onChange={(e) => {

                      item.PQR_Process = e.value;

                      if (e.value === "NA") {
                        setShow(true)
                        setprocessDelete(i)
                      
                      }
                      let a = ProcessActive.filter((b) => {
                        return b.wld_code === e.value;
                      });
                      item.PQR_Process_Category = a[0]?.wld_p_category;
                      

                      setdetailObj([...detailObj]);
                      
                    }}
                  ></SearchSelect>
                </td>
                <td className="PQRSelect" style={{ paddingLeft: "2%" }}>
                  <SearchSelect
                    data={TypeData}
                    readOnly={action === Action.ActionCopy ? isReadonly : false}
                    valueField={"wld_type"}
                   
                    value={item?.PQR_Type}
                    labelField={"wld_type"}
                    placeholder=""
                    onChange={(e) => {
                      item.PQR_Type = e.value;
                      setdetailObj([...detailObj]);

                      item.PQR_Process !== "" || item.PQR_Type !== ""
                        ? (detailObj[i].error = false)
                        : (detailObj[i].error = true);
                                         }}
                  ></SearchSelect>
                </td>
                { headerObj?.wld_WPSType === "Overlay" ? 
                 <td className="PQRSelect" style={{ paddingLeft: "2%" }}>
                 <SearchSelect
                   data={LayerData}
                   readOnly={action === Action.ActionCopy ? isReadonly : false}
                   valueField={"wld_layer"}
                   value={item?.PQR_Layer}
                   labelField={"wld_layer"}
                   placeholder=""
                   onChange={(e) => {
                     item.PQR_Layer = e.value;
                     setdetailObj([...detailObj]);
                     item.PQR_Process !== "" || item.PQR_Layer !== ""
                       ? (detailObj[i].error = false)
                       : (detailObj[i].error = true);
                   }}
                 ></SearchSelect>
               </td> 
               : 
                <td style={{ width: "39%" }}></td>}
                <td></td>
                <td></td>
                <td></td>
              </tr>
            );
          })}
      </tbody>
    </Table>
      <ConfirmAlertModal
      handleYes={processDelete}
      formName={formName}
      action="Delete"
      show={show}
      handleClose={() => setShow(false)}
    />
    </>
  );
};