import { UpdateWPQDetail } from "../../../Services/wpq_details-service";
import { MessageTypes } from "./WPQ_entity";

export const checkProcessAdded = (detailObj) => {
  
  let norows = true;

  for (let i = 0; i < 2; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;

    if (detailObj[i].WPQ_Process !== "" && detailObj[i].WPQ_Process !== "NA") {
      norows = false;

      if (detailObj[i].WPQ_Type === "") {
        detailObj[i].errormessage = "Type is required";
        detailObj[i].error = true;
        norows = true;
      }
    }

  }
  return norows;
};

export const checkTestAdded = (array) => {
  
  let norows = true;

  for (let i = 0; i < 4; i++) {
    array[i].errormessage = "";
    array[i].error = false;


    if (
      array[i].WPQ_WP_TypeOfTest !== "" ||
      array[i].WPQ_WP_Acceptance_Criteria !== "" ||
      array[i].WPQ_WP_Result !== "" ||
      array[i].WPQ_WP_Comments !== "" ||
      array[i].WPQ_WP_Report_Nummber !== ""
    ) {
      norows = false;

      if (array[i].WPQ_WP_TypeOfTest === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Acceptance_Criteria === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Result === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Comments === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Report_Nummber === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Pag3_VEX = (Visual_Examination) => {
  

  let norows = true;

 

  for (let i = 0; i < 2; i++) {
    Visual_Examination[i].errormessage = "";
    Visual_Examination[i].error = false;

    if (
      Visual_Examination[i].WPQ_VEX_WeldPass !== "" ||
      Visual_Examination[i].WPQ_VEX_Acceptance_Criteria !== "" ||
      Visual_Examination[i].WPQ_VEX_Observation !== "" ||
      Visual_Examination[i].WPQ_VEX_Results !== ""
    ) {
      norows = false;

      if (Visual_Examination[i].WPQ_VEX_WeldPass === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        norows = true;
        return norows;
      }
      if (Visual_Examination[i].WPQ_VEX_Acceptance_Criteria === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        norows = true;
        return norows;
      }
      if (Visual_Examination[i].WPQ_VEX_Observation === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        norows = true;
        return norows;
      }
      if (Visual_Examination[i].WPQ_VEX_Results === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Pag3_LPEX = (Liquid_Penetrant_Examination) => {
  
  
  let norows = true;

  for (let i = 0; i < 2; i++) {
    Liquid_Penetrant_Examination[i].errormessage = "";
    Liquid_Penetrant_Examination[i].error = false;


    if (
      Liquid_Penetrant_Examination[i].WPQ_LPEX_WeldPass !== "" ||
      Liquid_Penetrant_Examination[i].WPQ_LPEX_Acceptance_Criteria !== "" ||
      Liquid_Penetrant_Examination[i].WPQ_LPEX_Observation !== "" ||
      Liquid_Penetrant_Examination[i].WPQ_LPEX_Results !== ""
    ) {
      norows = false;

      if (Liquid_Penetrant_Examination[i].WPQ_LPEX_WeldPass === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        norows = true;
        return norows;
      }
      if (Liquid_Penetrant_Examination[i].WPQ_LPEX_Acceptance_Criteria === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        norows = true;
        return norows;
      }
      if (Liquid_Penetrant_Examination[i].WPQ_LPEX_Observation === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        norows = true;
        return norows;
      }
      if (Liquid_Penetrant_Examination[i].WPQ_LPEX_Results === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Pag3_MEX = (Macro_Examination1) => {
  

  let norows = true;

  for (let i = 0; i < 4; i++) {
    Macro_Examination1[i].errormessage = "";
    Macro_Examination1[i].error = false;


    if (
      Macro_Examination1[i].WPQ_MEX_Tube_No !== "" ||
      Macro_Examination1[i].WPQ_MEX_LocA !== "" ||
      Macro_Examination1[i].WPQ_MEX_LocB !== "" ||
      Macro_Examination1[i].WPQ_MEX_LocC !== "" ||
      Macro_Examination1[i].WPQ_MEX_LocD !== ""
    ) {
      norows = false;

      if (Macro_Examination1[i].WPQ_MEX_Tube_No === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        norows = true;
        return norows;
      }
      if (Macro_Examination1[i].WPQ_MEX_LocA === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        norows = true;
        return norows;
      }
      if (Macro_Examination1[i].WPQ_MEX_LocB === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        norows = true;
        return norows;
      }
      if (Macro_Examination1[i].WPQ_MEX_LocC === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        norows = true;
        return norows;
      }
      if (Macro_Examination1[i].WPQ_MEX_LocD === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Page4_Radio = (Radiography_Test) => {
  

  let norows = true;

  for (let i = 0; i < 4; i++) {
    Radiography_Test[i].errormessage = "";
    Radiography_Test[i].error = false;


    if (
      Radiography_Test[i].WPQ_Radio_TubeNo !== "" ||
      Radiography_Test[i].WPQ_Radio_Acceptance_Criteria !== "" ||
      Radiography_Test[i].WPQ_Radio_Observation !== "" ||
      Radiography_Test[i].WPQ_Radio_Results !== ""
    ) {
      norows = false;

      if (Radiography_Test[i].WPQ_Radio_TubeNo === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        norows = true;
        return norows;
      }
      if (Radiography_Test[i].WPQ_Radio_Acceptance_Criteria === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        norows = true;
        return norows;
      }
      if (Radiography_Test[i].WPQ_Radio_Observation === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        norows = true;
        return norows;
      }
      if (Radiography_Test[i].WPQ_Radio_Results === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Page4_OT = (Other_Test) => {
  

  let norows = true;

  for (let i = 0; i < 4; i++) {
    Other_Test[i].errormessage = "";
    Other_Test[i].error = false;


    if (
      Other_Test[i].WPQ_OT_TypeofTest !== "" ||
      Other_Test[i].WPQ_OT_Acceptance_Criteria !== "" ||
      Other_Test[i].WPQ_OT_Observation !== "" ||
      Other_Test[i].WPQ_OT_Results !== ""
    ) {
      norows = false;

      if (Other_Test[i].WPQ_OT_TypeofTest === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
        norows = true;
        return norows;
      }
      if (Other_Test[i].WPQ_OT_Acceptance_Criteria === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
        norows = true;
        return norows;
      }
      if (Other_Test[i].WPQ_OT_Observation === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
        norows = true;
        return norows;
      }
      if (Other_Test[i].WPQ_OT_Results === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Page4_MLP = (Minimum_Leak_Path1) => {
  

  let norows = true;

  for (let i = 0; i < 4; i++) {
    Minimum_Leak_Path1[i].errormessage = "";
    Minimum_Leak_Path1[i].error = false;


    if (
      Minimum_Leak_Path1[i].WPQ_MLP_Tube_No !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocA !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocB !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocC !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocD !== ""
    ) {
      norows = false;

      if (Minimum_Leak_Path1[i].WPQ_MLP_Tube_No === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        norows = true;
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocA === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        norows = true;
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocB === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        norows = true;
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocC === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        norows = true;
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocD === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Wpq_Page4_MLPT5 = (Macro_Examination5) => {
  

  let norows = true;

  for (let i = 0; i < 2; i++) {
    Macro_Examination5[i].errormessage = "";
    Macro_Examination5[i].error = false;


    if (
      Macro_Examination5[i].WPQ_MEX_Magnification !== "" ||
      Macro_Examination5[i].WPQ_MEX_Acceptance_Criteria !== "" 

    ) {
      norows = false;

      if (Macro_Examination5[i].WPQ_MEX_Magnification === "") {
        Macro_Examination5[i].errormessage = "All Fields are required";
        Macro_Examination5[i].error = true;
        norows = true;
        return norows;
      }
      if (Macro_Examination5[i].WPQ_MEX_Acceptance_Criteria === "") {
        Macro_Examination5[i].errormessage = "All Fields are required";
        Macro_Examination5[i].error = true;
        norows = true;
        return norows;
      }
   
    }
  }

  return norows;
};

export const page1characters = (error) => {
  let data = true;
  if (
    error.WPQ_WelderId.status === true ||
    error.WPQ_WelderName.status === true ||
    error.WPQ_Date.status === true ||
    error.WPQ_RecordNo.status === true ||
    error.WPQ_Wps_RecordNo.status === true ||
    error.WPQ_ReviNo.status === true ||
    error.WPQ_Inspection.status === true ||
    error.WPQ_Qualificationcode.status === true ||
    error.WPQ_BM_BM_ProductForm === true 
  )
    return data;
};

export const checkduplicateProcess = (detailObj) => {
  let isError = false;
  const unique = detailObj.filter(
    (obj, index) =>
      detailObj.findIndex(
        (item) =>
          item.WPQ_Process + "#" + item.WPQ_Type ===
          obj.WPQ_Process + "#" + obj.WPQ_Type
      ) !== index
  );

  if (
    unique.length >= 1 &&
    unique[0].WPQ_Process !== "" &&
    unique[0].WPQ_Process !== "NA"
  ) {
    detailObj[0].errormessage =
      "Process '" +
      unique[0].WPQ_Process +
      "' and Type '" +
      unique[0].WPQ_Type +
      "' is  Duplicate";
    isError = true;
    detailObj[0].error = true;
  } else {
    if (!detailObj[0].error) {
      detailObj[0].errormessage = "";
      // isError = false;
      detailObj[0].error = false;
    }
  }
  return isError;
};

export const checkFirstPageValidation = (
  radio,
  ANo,
  ChemicalAnalysis_TradeName,
  Classification,
  FNo,
  SFA,
  errObj,
  seterrobj
) => {
  if (
    radio === "Required" &&
    (ANo === "" ||
      ChemicalAnalysis_TradeName === "" ||
      Classification === "" ||
      FNo === "" ||
      SFA === "" ||
      ANo === undefined ||
      ChemicalAnalysis_TradeName === undefined ||
      Classification === undefined ||
      FNo === undefined ||
      SFA === undefined)
  ) {

    seterrobj((result) => [
      ...result,
      {
        id: "all",
        msg: "All fields are Mandatory",
        className: "border radioInput error",
      },
    ]);
  } else {
    
    seterrobj((result) => result.filter((i) => i.id !== "all"));
  }
};

//Tubesheet validation Started

export const checkTubeProcessAdded = (detailObj) => {
  

  let norows = true;

  detailObj.errormessage = "";
  detailObj.error = false;

  if (detailObj.WPQ_Process !== "" && detailObj.WPQ_Process !== "NA") {
    norows = false;

    if (detailObj.WPSD_FM_FNo === "") {
      detailObj.errormessage = "FNo is required";
      detailObj.error = true;

    }

    if (detailObj.WPSD_FM_SFA === "") {
      detailObj.errormessage = "WPSD_FM_SFA is required";
      detailObj.error = true;
      norows = true;
    }
  }

  

  return norows;
};

export const checkduplicateTubeProcess = (detailObj) => {
  let isError = false;
  const unique = detailObj.filter(
    (obj, index) =>
      detailObj.findIndex(
        (item) =>
          item.WPQ_Process + "#" + item.WPSD_FM_SFA ===
          obj.WPQ_Process + "#" + obj.WPSD_FM_SFA
      ) !== index
  );

  if (
    unique.length >= 1 &&
    unique[0].WPQ_Process !== "" &&
    unique[0].WPQ_Process !== "NA"
  ) {
    detailObj[0].errormessage =
      "Process '" +
      unique[0].WPQ_Process +
      "' and WPSD_FM_SFA '" +
      unique[0].WPSD_FM_SFA +
      "' is  Duplicate";
    isError = true;
    detailObj[0].error = true;
  } else {
    if (!detailObj[0].error) {
      detailObj[0].errormessage = "";

      detailObj[0].error = false;
    }
  }
  return isError;
};


export const UpdateWPQDetailComplete = async (postList) => {


  let results = [],
    checkarray = [];
  for (var eachTable of postList) {
    let result =  UpdateWPQDetail(eachTable).catch((error) => {
    });
    if (result !== undefined) {results.push(result);}
    else{
      return result
    }
    
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, check];
  }
  console.log("chhha",checkarray)
debugger
  if (checkarray.length === 0) {

    return false;
  } 
  else if(checkarray.filter((i) => i.message == MessageTypes.SpecialCharacters).length >0)
    {

      return MessageTypes.SpecialCharacters
    }
  else {
    return true;
  }
};