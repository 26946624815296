import {
  getbyprojectno,
  getbyprojectnoWPS,
  getbywpsno,
  checkValidWPS,
  getWPSbyJoint,
} from "../../../Services/wps_groove-services";


export const GrooveType = ["Groove", "Tubesheet", "Overlay"];

export const checkProcessAdded = (detailObj) => {
  
  let norows = true;

  for (let i = 0; i < 3; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;

    if (
      detailObj[i].WPSD_Process !== "" &&
      detailObj[i].WPSD_Process !== "NA"
    ) {
      norows = false;

      if (detailObj[i].WPSD_FM_FNo === "") {
        detailObj[i].errormessage = "FNo is required";
        detailObj[i].error = true;

      }

      if (detailObj[i].WPSD_Type === "") {
        detailObj[i].errormessage = "Type is required";
        detailObj[i].error = true;
        norows = true;
      }

      if (
        detailObj[i].wld_WPSType === "Overlay" &&
        detailObj[i].WPSH_WeldLayer === ""
      ) {
        detailObj[i].errormessage = "Weld Layer is required";
        detailObj[i].error = true;
 
      }
    }

  }
  return norows;
};

export const checkduplicateProcess = (detailObj) => {
  let isError = false;
  const unique = detailObj.filter(
    (obj, index) =>
      detailObj.findIndex(
        (item) =>
          item.WPSD_Process + "#" + item.WPSD_Type ===
          obj.WPSD_Process + "#" + obj.WPSD_Type
      ) !== index
  );

  if (
    unique.length >= 1 &&
    unique[0].WPSD_Process != "" &&
    unique[0].WPSD_Process != "NA"
  ) {
    detailObj[0].errormessage =
      "Process '" +
      unique[0].WPSD_Process +
      "' and Type '" +
      unique[0].WPSD_Type +
      "' is  Duplicate";
    isError = true;
    detailObj[0].error = true;
  } else {
    if (!detailObj[0].error) {
      detailObj[0].errormessage = "";
      // isError = false;
      detailObj[0].error = false;
    }
  }
  return isError;
};

export const checkFirstPageValidation = (
  radio,
  ANo,
  ChemicalAnalysis_TradeName,
  Classification,
  FNo,
  SFA,
  errObj,
  seterrobj
) => {
  let result = true
  if (
    radio == "Required" &&
    (ANo === "" ||
      ChemicalAnalysis_TradeName === "" ||
      Classification === "" ||
      FNo === "" ||
      SFA === "" ||
      ANo === undefined ||
      ChemicalAnalysis_TradeName === undefined ||
      Classification === undefined ||
      FNo === undefined ||
      SFA === undefined)
  ) {

    seterrobj((result) => [
      ...result,
      {
        id: "all",
        msg: "All fields are Mandatory",
        className: "border radioInput error",
      },
    ]);
  } else {
   
    seterrobj((result) => result.filter((i) => i.id !== "all"));
    
    result= false
  
  }
  return result
};

export const checkTubeProcessAdded = (detailObj) => {


  
  let norows = true;

  for (let i = 0; i < 1; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;
    

    if (
      detailObj[i].WPSD_Process !== "" &&
      detailObj[i].WPSD_Process !== "NA"
    ) {
      norows = false;

      if (detailObj[i].WPSD_FM_FNo === "") {
        detailObj[i].errormessage = "FNo is required";
        detailObj[i].error = true;
  
      }

      if (detailObj[i].WPSD_FM_SFA === "") {
        

        detailObj[i].errormessage = "SFA is required";
        detailObj[i].error = true;
        norows = true;
      }
    }
  }
  return norows;
};
export const isDuplicate = async (proj, wpsno) => {
  let results = [],
    checkarray = [];
  for (let groove of GrooveType) {
    let result = getbyprojectno(groove, proj, wpsno).catch((error) => {
      console.log("Error isDuplicate", error);
    });

  
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  
  if (checkarray.length == 0) {
    
    return false;
  } else {
  
    return true;
  }
};

export const getWPS = async (proj) => {
  let results = [],
    checkarray = [];
  for (let groove of GrooveType) {
    
    let result = getbyprojectnoWPS(groove, proj).catch((error) => {
      console.log("Error isDuplicate", error);
    });

   
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }

  return checkarray;
};

export const getWPSbyWPSNo = async (groove, wpsno) => {
  let results = [],
    checkarray = [];
  for (let groove of GrooveType) {
    let result = getbywpsno(groove, wpsno).catch((error) => {
      console.log("Error getWPSbyWPSNo", error);
    });


    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }

  return checkarray;
};

export const checkWPSinArray = async (wpsno) => {
  
  let results = [],
    checkarray = [];

  for (let groove of GrooveType) {
    let groovetype = { GrooveType: groove };
    let request = { wpsno, groovetype };
   
    let result = checkValidWPS(request).catch((error) => {
      console.log("Error getWPSbyWPSNo", error);
    });


    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    if (check != undefined) checkarray = [...checkarray, ...check];
  }
 
  return Promise.resolve(checkarray);
};


export const checkWPS = async (proj) => {
 

  let results = [],
    checkarray = [];
  let reqData = [];
  for (let groove of GrooveType) {

    reqData.push({
      grooveType: groove,
      WPSNo_Array: proj.WPSNo_Array,
      project: proj.project,
    });
  
    let result = checkValidWPS(reqData).catch((error) => {
      console.log("Error isDuplicate", error);
    });

    
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  
  return checkarray;
};

export const getWPSonType = async () => {
  let results = [],
    checkarray = [];
  for (let jointType of GrooveType) {
    
    let result = getWPSbyJoint(jointType).catch((error) => {
      console.log("Error isDuplicate", error);
    });

   
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
 
  return checkarray;
};
