const Tubesheet_Post_weld_heat_Treatment =({pdf}) =>{
    return(
        <table id="table" className="WPSTable8"  style={{position:"relative",top:"5px"}}>
            <tr>
              <th
                style={{
                  width: "15%",
                  borderTop: ".1px solid black",
                  borderLeft: ".1px solid black",
                  borderBottom: ".1px solid black",
                }}
              >
                Temperature <div className="WPSrcorner">°C </div>
              </th>
              <td style={{ width: "26%", borderBottom:"1px solid black", borderTop:"1px solid black", paddingLeft:"3px"}}>
                {pdf[0]?.WPSH_PWHeatTre_Temperature}
              </td>
              <th
                style={{
                  width: "17%",
                  borderTop: "1px solid black",
                  borderBottom:"1px solid black",
                }}
              >
                Time <div className="WPSrcorner"> Min </div>
              </th>

              <td style={{ width: "25%", borderBottom:"1px solid black",borderTop:"1px solid black", borderRight:"none" ,borderLeft:"none", paddingLeft:"3px" }}>
                {pdf[0]?.WPSH_PWHeatTre_Time}
              </td>
              <th
                style={{
                  width: "5%",
                  borderTop: ".1px solid black",
                  borderLeft: ".1px solid black",
                }}
                rowSpan="3"
              >
                Type
              </th>
              <td rowSpan="3" style={{ width: "20%" ,borderTop:"1px solid black",borderRight:"1px solid black",borderBottom:"1px solid black", paddingLeft:"3px"}}>{pdf[0]?.WPSH_PWHeatTre_Type}</td>
            </tr>
            <tr>
              <th style={{ width: "25%", borderLeft: ".1px solid black"}}>
                Heating Rate <div className="WPSrcorner">°C/hr </div>
              </th>
              <td
                style={{
                  width: "15%",
                  borderRight: "none", paddingLeft:"3px",borderBottom:"1px solid black"
                  
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Heating_Rate}
              </td>

              <th style={{ width: "25%" }}>
                Cooling Rate <div className="WPSrcorner">°C/hr </div>
              </th>
              <td style={{ width: "15%" , paddingLeft:"3px",borderBottom:"1px solid black"}}>
                {pdf[0]?.WPSH_PWHeatTre_Cooling_Rate}
              </td>
              
            </tr>

            <tr>
              <th style={{ width: "25%", borderLeft: ".1px solid black"}}>
              Loading Temp. <div className="WPSrcorner">°C </div>
              </th>
              <td
                style={{
                  width: "15%",
                  borderRight: "none", paddingLeft:"3px",borderBottom:"1px solid black"
                  
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_LoadingTemp}
              </td>

              <th style={{ width: "25%" }}>
                Unloading Temp. <div className="WPSrcorner">°C/hr </div>
              </th>
              <td style={{ width: "15%" , paddingLeft:"3px",borderBottom:"1px solid black"}}>
                {pdf[0]?.WPSH_PWHeatTre_UnloadingTemp}
              </td>
              
            </tr>
            <tr>
              <th style={{ width: "25%", borderLeft: ".1px solid black"}}>
              Notes <div className="WPSrcorner"> </div>
              </th>
              <td
              colSpan={5}
                style={{
                  width: "45%",
                  borderRight: "1px solid black", paddingLeft:"3px",borderBottom:"1px solid black"
                  
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_PWHTNotes}
              </td>

            
              
            </tr>
          
          </table>
    )
}
export default Tubesheet_Post_weld_heat_Treatment;