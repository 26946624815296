const Tubesheet_FillerMetal =({pdf}) =>{
    function FillMetals_Processes() {
        let rows = [];
    
        for (let i = 1; i <= 3; i++) {
          if (pdf[i] !== undefined) {
            rows.push(
              <tr style={{borderBottom: "1px solid black"}} >
                <th colspan="2" className="WPSleft-th-nobottom" style={{borderBottom: "1px solid black",borderRight:"none", borderLeft:"0.5px solid black" ,borderTop:"1px  solid black",marginBottom:'0px'}}>
                  {pdf[i]?.WPSD_Process}
                </th>
                <td className="WPSleft-td-nobottomnoleft" style={{borderLeft:"1px solid black" ,textAlign:"center"}}>{pdf[i]?.WPSD_FM_SFA}</td>
    
                <td className="WPSleft-td-nobottomnoleft" style={{textAlign:"center"}}>
                  {pdf[i]?.WPSD_FM_Classification === undefined ||
                  pdf[i]?.WPSD_FM_Classification === ""
                    ? "--"
                    : pdf[i]?.WPSD_FM_Classification}
                </td>
    
                <td className="WPSleft-td-nobottomnoleft" style={{textAlign:"center"}}>{pdf[i]?.WPSD_FM_FNo}</td>
                <td className="WPSleft-td-nobottomnoleft" style={{textAlign:"center"}}>{pdf[i]?.WPSD_FM_Ano}</td>
                <td className="WPSleft-td-nobottomnoleft" style={{textAlign:"center"}}>
                  {pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName === "" ||
                  pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName === undefined
                    ? "--"
                    : pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName}
                </td>
              </tr>
            );
          }
        }
        return <>{rows}</>;
      }
    return(
      <div>
        
        
        <table id="table" className="WPSTable6" style={{ width: "72%" }}>
          <tbody>
            <tr>
              <th
                colspan="2"
                rowSpan="1"
                style={{ width: "21.8%", background: "white", borderRight: "none",}}
              ></th>
              <th className="WPSleft-th" rowSpan="1" style={{ width: "15%", borderLeft: "1px solid black", textAlign: "center" }}>
                SFA
              </th>
              <th className="WPSleft-th" rowSpan="1" style={{ width: "27%", textAlign: "center" }}>
                Classification
              </th>

              <th className="WPSleft-th" rowSpan="1" style={{ width: "15%", textAlign: "center" }}>
                F-no
              </th>
              <th className="WPSleft-th" rowSpan="1" style={{ width: "15%", textAlign: "center" }}>
                A-no
              </th>
              <th className="WPSleft-th" rowSpan="1" style={{ width: "28%", textAlign: "center" }}>
                Chemical Analysis or Trade name
              </th>
            </tr>

            {FillMetals_Processes()}
          </tbody>
        </table>
        <table id="table" className="WPSTable8" style={{width: "15.1cm",top: "0px", position: "relative",marginTop:'0px' }}>
          <tr style={{borderTop:"none",}}>
            <th style={{ width: "18%",paddingTop:'0px',marginTop:'0px' }}

            >
              Preplaced filler metal  
            </th>
            <td className="WPSleft-td-nobottomnoleft" style={{borderTop:"0", paddingLeft: "3px"}}>
              {pdf[0]?.WPSH_FM_PreFillerMetal === "" ||
                pdf[0]?.WPSH_FM_PreFillerMetal === undefined
                ? "--"
                : pdf[0]?.WPSH_FM_PreFillerMetal}
            </td>
          </tr>

          <tr>
            <th

            >
              With/without filler metal
            </th>
            <td className="WPSleft-td-nobottomnoleft" style={{ paddingLeft: "3px" }}>
              {pdf[0]?.WPSH_FM_WithFillerMetal === "" ||
                pdf[0]?.WPSH_FM_WithFillerMetal === undefined
                ? "--"
                : pdf[0]?.WPSH_FM_WithFillerMetal}
            </td>
          </tr>
          <tr>
            <th

            >
              Filler metal Product form
            </th>
            <td className="WPSleft-td-nobottomnoleft" style={{ paddingLeft: "3px" }}>
              {pdf[0]?.WPSH_FM_FillerProduct === "" ||
                pdf[0]?.WPSH_FM_FillerProduct === undefined
                ? "--"
                : pdf[0]?.WPSH_FM_FillerProduct}
            </td>
          </tr>
          <tr>
            <th

            >
              Inserts
            </th>
            <td className="WPSleft-td-nobottomnoleft" style={{ paddingLeft: "3px" }}>
              {pdf[0]?.WPSH_FM_Inserts === "" ||
                pdf[0]?.WPSH_FM_Inserts === undefined
                ? "--"
                : pdf[0]?.WPSH_FM_Inserts}
            </td>
          </tr>
          <tr>
            <th

            >
              Filler Metals - Flux
            </th>
            <td className="WPSleft-td-nobottomnoleft" style={{ paddingLeft: "3px" }}>
              {pdf[0]?.WPSH_FM_FillerFlux === "" ||
                pdf[0]?.WPSH_FM_FillerFlux === undefined
                ? "--"
                : pdf[0]?.WPSH_FM_FillerFlux}
            </td>
          </tr>
          <tr>
            <th style={{ borderBottom: "1px solid black"}}>
              Filler Metals - Others
            </th>
            <td className="WPSleft-td-nobottomnoleft" style={{ borderBottom: "1px solid black", paddingLeft: "3px" }}>
              {pdf[0]?.WPSH_FM_FillerOthers === "" ||
                pdf[0]?.WPSH_FM_FillerOthers === undefined
                ? "--"
                : pdf[0]?.WPSH_FM_FillerOthers}
            </td>
          </tr>
        </table>
      
       </div>
    )
}
export default Tubesheet_FillerMetal;