
import React, { useState, useEffect } from "react";

import "./Assests/Css/app.css";
import "./styles/AgGrid.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
//Added  By Snehal
import "./App.scss";
import LoginPage from "./Components/LoginPage";
import "./interceptor";

import { authenticate } from "./Services/user-service";
import { getRoleDescByRoleId } from "./Services/role-service";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
//END  By Snehal
import Home from "./Components/Home";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [validUser, setValidUser] = useState();
  const [showLoginError, setLoginError] = useState(false);
  const [idToken, setIdToken] = useState(null);

  

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken !== "") {
      getUserData();
    }
  }, [isAuthenticated, idToken]);
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
 
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        sessionStorage.setItem("Token", response.idToken);
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setIdToken(response.idToken);
        });
      });
  }
  //Code for Live

  async function getUserData() {
    let email = accounts[0].username;

    sessionStorage.setItem("EmailId", email);
    let result = await authenticate(email, idToken).catch((err) =>
      console.log("Error in fetching details", err)
    );

    if (result && result.UserData.length > 0 && result.UserData[0] !== "") {
      sessionStorage.setItem("UserName", result.UserData[0].um_name);
      sessionStorage.setItem("UserProfile", result.UserData[0].wld_profile);
      sessionStorage.setItem("UserId", result.UserData[0].um_email);
      sessionStorage.setItem("UserId1", result.UserData[0].um_email);
      sessionStorage.setItem("Role", result.UserData[0].um_role);
      sessionStorage.setItem("Band", result.UserData[0].um_band);
      sessionStorage.setItem("Token", result.Token);
      sessionStorage.setItem("Location", result.UserData[0].um_location);
     
      sessionStorage.setItem("User", result.UserData[0].SK);
      sessionStorage.setItem("SalCode", result.UserData[0].um_empSalCode);
   
      let roledata = await getRoleDescByRoleId(result.UserData[0].um_role);

      let roleactive = undefined;
      if (roledata.length > 0) roleactive = roledata[0].rm_status;

      if (
        result.UserData[0].um_status === "Active" &&
        roleactive === "Active"
      ) {
        setValidUser(true);
        setLoginError(false);
      } else {
          setValidUser(false);
        setLoginError(true);
      }
    } else {
      setValidUser(false);
      setLoginError(true);
    }
  }

  return (
    <>
      <AuthenticatedTemplate>
        {validUser === true && <Home />}
        {validUser === false && <LoginPage showLoginError={showLoginError} />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
