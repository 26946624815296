const PDFBaseMetalTable = ({ pdf , from }, props, wld)  => {




    const styles = {
        container: {
          display: "flex",
        },
    
        group: {
          display: "flex",
        },
    
        title3: {
          width: "50%",
        },
      };
    return (
    <>
    <div style={styles.group}>
    <div className="WPQbasemetal">BASE METALS (QW - 403)</div>
    </div>
   
    <div>
    <div style={{ paddingTop: "1px" }}></div>
    </div>
    <table className="pdfttsbasemetal" style={{ width: "570px" }}>
    <tbody>
        <tr>
       
        <td className="pdfBorderBottom pdfRightBorder" style={{width:"16%"}}>{""}</td>
            <td className="pdfwpqFontBold pdfBorderBottom" style={{width:"10%"}}>
                Product Form
            </td>
            <td className="pdfwpqFontBold pdfBorderBottom"  style={{width:"22%"}}>
                Spec. {"(Type or Grade)"}, UNS #
            </td>
            <td className="pdfwpqFontBold pdfBorderBottom" style={{width:"6%"}}>
            P No/A No
            </td>
            <td className="pdfwpqFontBold pdfBorderBottom" style={{width:"6%"}}> 
                Grp-no.
            </td>

           
            <td className="pdfwpqFontBold pdfBorderBottom" style={{width:"8%"}}>
                Thick. {"(mm)"}
            </td>
            <td className="pdfwpqFontBold pdfRightBorder pdfBorderBottom" style={{width:"6%"}}>
                Dia. {"(mm)"}
            </td>
        </tr>
        <tr>
        {from == "WPQ" && <>  <th className="pdfwpqallborders RightleftBottom " rowSpan="2" style={{width:"16%", borderTop:"none"}}>
                <span  className= "pdfwpqallborders"style={{position:"relative", top:"5px", right:"2px"}}>Welded to</span>
            </th>
            </>}
            
            {from == "WPQTTS" && <>  <th className="pdfwpqallborders pdfwpqLeftRightBorder PdfNoTopBorder"  style={{width:"16%"}}>
               Base Metal-1
            </th></>}
            
            <td className="pdfwpqNoBorderBottom" style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_BM_ProductForm}
            </td>
            <td className="pdfwpqNoBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_BM_Spec}
            </td>
            <td className="pdfwpqNoBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_BM_Pno}
            </td>
            <td className="pdfwpqNoBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_BM_Gno}
            </td>

            <td className="pdfwpqNoBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_BM_Thk}
            </td>
            <td className="pdfwpqNoBorderBottom pdfRightBorder"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_BM_Dia}
            </td>
        </tr>
        <tr>
            
        {from == "WPQTTS" && <>  <th className="pdfwpqallborders pdfwpqBorderLeftRightBottom PdfNoTopBorder" style={{width:"16%"}}>
               Welded to Base Metal-2
            </th></>}
            
            <td className="pdfwpqBorderRightTopRemoved pdfBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_WTBM_ProductForm}
            </td>
            <td className="pdfwpqBorderLeftTopRemoved pdfBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_WTBM_Spec}
            </td>
            <td className="pdfwpqBorderLeftTopRemoved pdfBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_WTBM_Pno}
            </td>
            <td className="pdfwpqBorderLeftTopRemoved pdfBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_WTBM_Gno}
            </td>

            <td className="pdfwpqBorderLeftTopRemoved pdfBorderBottom"  style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_WTBM_Thk}
            </td>
            <td className="pdfwpqBorderLeftTopRemoved pdfBorderBottom pdfRightBorder"   style={{textAlign:"center"}}>
                {pdf[0]?.WPQ_BM_WTBM_Dia}
            </td>
        </tr>
      
        {from == "WPQ" && <>  <th className="pdfwpqallborders pdfBorderBottom pdfwpqLeftRightBorder PdfNoTopBorder"  style={{width:"16%"}}>
            Joint Type 
            </th>
            <td colspan="6" className="pdfBorderBottom pdfRightBorder"  style={{textAlign:"left"}}>
                {pdf[0]?.wld_WPSType}
            </td></>}
            </tbody>
    </table>
        
        {from == "WPQTTS" && <>
            <table className="pdfttsbasemetal" style={{ width: "570px" }}>
            
         <tr style={{ width: "570px" }}>
         <th className="pdfwpqallborders pdfBorderBottom pdfwpqLeftRightBorder PdfNoTopBorder"  style={{width:"20%"}}>
         Range Qualified tube thickness 
            </th>
            <td className="pdfBorderBottom"  style={{textAlign:"left",width:"20%"}}>
                {pdf[0]?.WPQ_BM_RangeQualified_Thk}
            </td>
            <th className=" pdfBorderBottom pdfwpqLeftRightBorder PdfNoTopBorder"  style={{width:"20%"}}>
            Range Qualified tube diameter
            </th>
            <td className="pdfBorderBottom pdfRightBorder"  style={{textAlign:"left",width:"20%"}}>
                {pdf[0]?.WPQ_BM_RangeQualified_Dia}
            </td> 
         </tr>
         
         </table>
        </>}
       
    
    </>
    )
}

export default PDFBaseMetalTable