import "../Css/Download_pdf.css";




const PageFooter = ({
  pdf,
  PreviewUrl,
  previewuser,
  previewapproved,
  Band,
}) => {



return(<>

<div style={{position:"relative", top:"0px"}}>
<table className="WPSnoborder" style={{ width: "70%"}}>
<tr>
<td className="WPSSignatureTable" style={{ width: "230px"}}>Welding Engineer</td>
<td className="WPSSignatureTable" style={{ position:"relative", left: "60px" }}>Approved By</td>
</tr>
</table>
 <table id="table" className="WPSTable11">
 <tbody>
   <tr style={{borderBottom: "none" }}>
     <th style={{ borderBottom: "none" }}>Name</th>
     <th style={{ borderBottom: "none", borderLeft:"none", borderRight:"none" }}>Signature</th>
     <th style={{ borderBottom: "none" }}>Name</th>

     <th style={{ borderBottom: "none", borderLeft:"none" }}>Signature</th>
   </tr>
   <td style={{ borderBottom: "none",height:"13px" }}>{pdf[0]?.WPSH_PreparedBy_Name ? pdf[0]?.WPSH_PreparedBy_Name: ""}</td>

   <td rowSpan={3} style={{ borderLeft: "none" ,borderRight:"none"}}>
   {previewuser != "" &&  (
      <img style={{ width: "95%", marginTop: "4%", marginLeft: "2%", marginBottom:"3%", height:"30px" }}  src={previewuser} />
    )}

     
   </td>

   <td style={{ borderBottom: "none",height:"13px" }}>{pdf[0]?.WPSH_ApprovedBy_Name ? pdf[0]?.WPSH_ApprovedBy_Name : " " }</td>
   <td rowSpan={3} style={{ borderLeft: "none" }}>
     {previewapproved != "" && (
       <img style={{ width: "95%", marginTop: "4%", marginLeft: "2%", marginBottom:"3%", height:"30px" }}  src={previewapproved} />
     )}
      
   </td>

   <tr>
     <th style={{ borderBottom: "none", }}>Date</th>

     <th style={{ borderBottom: "none" }}>Date</th>
   </tr>

   <tr>
     <td style={{height:"13px"}}>{pdf[0]?.WPSH_PreparedBy_Date ? pdf[0]?.WPSH_PreparedBy_Date : " "}</td>
     <td style={{height:"13px"}}>
       {pdf[0]?.WPSH_ApprovedBy_Date
         ? pdf[0]?.WPSH_ApprovedBy_Date
         : ""}
     </td>
   </tr>
 </tbody>
</table>
</div>

       
      
</>)
}

export default PageFooter; 