import React, { useEffect, useState } from "react";
import "./Css/groovePQR_pdf.css";
import { Modal } from "react-bootstrap";
import { jsPDF } from "jspdf";
import { getPresignedURL } from "../../../Services/Welder-services";
import BarLoader from "react-spinners/BarLoader";

import Groove_pdf_page1PQR from "./Pages/Groove_pdf_page1PQR";
import Groove_pdf_page2PQR from "./Pages/Groove_pdf_page2PQR";
import Groove_pdf_page2 from "./Pages/Groove_pdf_page2";
import { getdetailsById } from "../../../Services/pqr-details-service";
import { getbyprojectno } from "../../../Services/pqr-header-service";
import PQRTubeToTubesheet_pdf_page1 from "./Pages/PQRTubeToTubesheet_pdf_page1";
import PQRTubeToTubesheet_pdf_page2 from "./Pages/PQRTubeToTubesheet_pdf_page2";
import PQRTubeToTubesheet_pdf_page3 from "./Pages/PQRTubeToTubesheet_pdf_page3";

import Jspdf_font from "../../../Assests/font/jsPdf_font";

const Pdf_DownloadPQR = (props, wld) => {

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);

  const [PreviewUrl, setPreviewUrl] = useState([]);
  const [previewUrl1, setPreviewUrl1] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  const [previewUrl3, setPreviewUrl3] = useState();



  const [previewUrlgroove1, setpreviewUrlgroove1] = useState();
  const [previewUrlgroove2, setpreviewUrlgroove2] = useState();
  const [pdf, setpdf] = useState([]);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");


  let folderwld = "wld_signatures";
  let folderwps = "WPS_Groove";
  let folderpqr = "PQR_GrooveOverlay";
  let folderpqrtube = "PQR_Tubesheet";





  const fetchbyid = async (IddetailPK, IddetailSK) => {
    

    let reqDetailParam = {
      id1: props.rowdata.wld_WPSType,
      id2: IddetailSK,
    };
    const result = await getdetailsById(reqDetailParam);

    let reqParam = {
      groovetype: props.rowdata.wld_WPSType,
      pkid: IddetailPK,
      skid: IddetailSK,
    };
    const pqrhead = await getbyprojectno(reqParam);


    setpdf([...pqrhead, ...result]); //... means clone/duplicate
  };

  const createPDF = () => {
    fetchbyid(props?.rowdata?.PK, props?.rowdata?.PQR_RecordNo);
  };

  useEffect(() => {
    createPDF();
  }, []);



  const fetchimagespdf = async () => {
    try {
     
      if (props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0] !== "") {
        
        const result = await getPresignedURL(
          folderpqrtube,
          props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0]
        );

        const geturl = result.url;
        setPreviewUrl1(geturl);
  
      }

      if (props?.rowdata?.Pdf_images[1].tube_Hole[0] !== "") {
  
        const result = await getPresignedURL(
          folderpqrtube,
          props?.rowdata?.Pdf_images[1].tube_Hole[0]
        );

        const geturl = result.url;
        setPreviewUrl2(geturl);
 
      }

      if (props?.rowdata?.Pdf_images[2].tube_Weld_Joint[0] !== "") {
    
        const result = await getPresignedURL(
          folderpqrtube,
          props?.rowdata?.Pdf_images[2].tube_Weld_Joint[0]
        );

        const geturl = result.url;
        setPreviewUrl3(geturl);

      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchimagespdfgroove = async () => {
    try {
     
      if (props?.rowdata?.Pdf_images[0].pqr_image1[0] !== "") {
    
        const result = await getPresignedURL(
          folderpqr,
          props?.rowdata?.Pdf_images[0].pqr_image1[0]
        );
    
        const geturl = result.url;
        setpreviewUrlgroove1(geturl);
      
      }

      if (props?.rowdata?.Pdf_images[1].pqr_image2[0] !== "") {
     
        const result = await getPresignedURL(
          folderpqr,
          props?.rowdata?.Pdf_images[1].pqr_image2[0]
        );
 
        const geturl = result.url;
        setpreviewUrlgroove2(geturl);
   
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchapproveUserProfileswld = async () => {
    try {


      if (
        props?.rowdata?.PQR_SignaturePrepared !== "" &&
        props?.rowdata?.PQR_SignaturePrepared !== undefined
      ) {
      
        const result = await getPresignedURL(
          folderwld,
          props?.rowdata?.PQR_SignaturePrepared
        );
  
        const geturl = result.url;
        setPreviewuser(geturl);

      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchapproveUserProfileswldapproved = async () => {
    try {


      if (
        props?.rowdata?.PQR_SignatureApproved !== "" &&
        props?.rowdata?.PQR_SignatureApproved !== undefined
      ) {
  

        const result = await getPresignedURL(
          folderwld,
          props?.rowdata?.PQR_SignatureApproved
        );

        const geturl = result.url;
        setPreviewapproved(geturl);

      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  }; 
  const downloadPdf = () => {
    setTimeout(() => {
      let doc = new jsPDF("p", "pt", [595.28, 1020]);
      doc = Jspdf_font(doc)
      let pdfjs = document.querySelector("#MainDiv");
      doc.html(pdfjs, {
        callback: function (doc) {
       
          var Filename = `PQR Report ${pdf[0].wld_WPSType}`;
          doc.save(Filename);
          props.onHide();
          props.setReload(!props.reload);
          setLoading(false);

          doc.setFontSize(10);
        },
        margin: [-25, 10, 10, 10],
      });

    }, 9000);
  };

  useEffect(() => {
    fetchimagespdf();
    fetchimagespdfgroove()

    fetchapproveUserProfileswld();
    fetchapproveUserProfileswldapproved();
    if (pdf.length > 0) {
      downloadPdf();
    }
  }, [pdf]);

  return (
    <>
      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}
          </p>
        </Modal.Body>
      </Modal>

      {pdf.length > 0 && (
        <div id="MainDiv">
          <br></br>
          {pdf[0]?.wld_WPSType === "Tubesheet" ? (
            <>
              <PQRTubeToTubesheet_pdf_page1
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                previewUrl1={previewUrl1}
                previewUrl2={previewUrl2}
                previewUrl3= {previewUrl3}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
              <PQRTubeToTubesheet_pdf_page2
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
               <div style={{backgroundColor:"none", height:"410px", position: "relative" ,top:"70px"}}></div>
             
              <PQRTubeToTubesheet_pdf_page3
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
            </>
          ) : (
            <>
              <Groove_pdf_page1PQR
                pdf={pdf}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
                previewUrl1={previewUrlgroove1}
                previewUrl2={previewUrlgroove2}
              />
                {pdf[0]?.wld_WPSType === "Overlay" ? (
 <div style={{backgroundColor:"none",height:"110px", position: "relative",top:"50px"}}></div>):(
 <div style={{backgroundColor:"none",height:"30px", position: "relative",top:"10px"}}></div>)}
               {pdf[4] && <Groove_pdf_page2
                pdf={pdf}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />}
            {pdf[4] && <> {pdf[0]?.wld_WPSType === "Overlay" ? (
 <div style={{height:"115px", position: "relative",top:"115px"}}></div>):<div style={{height:"54px", position: "relative",top:"54px"}}></div>}</>}
              <Groove_pdf_page2PQR
                pdf={pdf}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Pdf_DownloadPQR;
