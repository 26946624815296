import axios from "axios";

let MASTER_API_URL
if (process.env.REACT_APP_NODE_ENV === "local") {
   MASTER_API_URL = process.env.REACT_APP_TRAINING_API_URL;
} else {
   MASTER_API_URL = process.env.REACT_APP_API_URL + "training";
}

export async function getReqByType(tmpObj) {
  const response = await axios
    .post(MASTER_API_URL + "/trainingrequisition/getTrainingReqData", tmpObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });


  return response.data;
}

export async function getTrainingRecordbyID(SK,trainingSub,fromdate,todate,month,faculty) {

  let userdata = {
    SK : SK,
    trainingSub: trainingSub,
    fromdate:fromdate,
    todate:todate,
    month:month,
    faculty:faculty,

  }
  const response = await axios
    .post(MASTER_API_URL + "/trainingrequisition/getApprovedtraining",userdata)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddTrainingRequest = async (Request) => {
  


  let url = `${MASTER_API_URL}/trainingrequisition/`;
  const response = await axios
    .post(url, Request)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const updateTraining = async (item) => {






  // for (let i = 0; i < item.length; i++) {
  //   const element = item[i];
  let url = `${MASTER_API_URL}/trainingrequisition/updater`;
  const response = await axios
    .post(url, item)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//}

export async function updateTrainingRequest(data) {

  let url = `${MASTER_API_URL}/trainingrequisition/workflow`;
  const response = await axios
    .post(url, data)

    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

//////Started Qualification Requisition
export const AddQualificationRequest = async (Request) => {
  



  //   "AddQualificationRequest_1",
  //   JSON.stringify(Request.wld_TrainingReqNumber)
  // );
  let url = `${MASTER_API_URL}/qualificationrequisition/`;

  const response = await axios
    .post(url, Request)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddFinalApprover = async (Request) => {
  


  let url = `${MASTER_API_URL}/qualificationrequisition/FinalApprover`;

  const response = await axios
    .post(url, Request)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getQualReqByType(tmpObj) {

  const response = await axios
    .post(
      MASTER_API_URL + "/qualificationrequisition/getTrainingReqData",
      tmpObj
    )
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });


  return response.data;
}

export async function getFinalApprover(tmpObj) {

  const response = await axios
    .post(
      MASTER_API_URL + "/qualificationrequisition/getFinalApprover",
      tmpObj
    )
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });


  return response.data;
}


export async function getAllDataQCEExport() {
  const response = await axios
    .get(MASTER_API_URL + "/qualificationrequisition/qcegetalldataexport")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function updateQualificationRequest(data) {

  let url = `${MASTER_API_URL}/qualificationrequisition/workflow`;
  const response = await axios
    .post(url, data)

    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}
