import React, { useEffect, useState } from "react";
import "../Css/Download_pdf.css";
import Groove_pdf_header from "./Groove_pdf_header WPS";
import WPS_images from "./WPS_image";
import Tubesheet_Basemetal from "./WPS_Tubesheet_BaseMetal";
import Tubesheet_JointDetails from "./WPS_Tubesheet_Joint_details";
import Tubesheet_FillerMetal from "./WPS_Tubesheet_FillerMetal.jsx";
import Tubesheet_Pdf_Fields from "./WPS_Tubesheet_WeldingProcedure_Json.jsx";

const TubeToTubesheet_pdf_page1 = (
  { pdf, setpdf, PreviewUrl,page, previewUrl1, previewUrl2, previewUrl3 },
  props,
  wld
) => {
  //pdf image header and img arrays
  let type = [{ Type: pdf[0]?.wld_WPSType}]; 

  
  let Imageheaders = ["Tube Hole Ligament Detail", "Tube Hole Detail", "Tube to Tubesheet Weld Joint Detail"];
  let images = [ previewUrl1 ,previewUrl2 ,previewUrl3];

    let
    col1Class

  
  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };


  var WPType= pdf[0].wld_WPSType === "Tubesheet" && (Tubesheet_Pdf_Fields)
 
 

 

  
  return (
    <div className="WPSMainPDF">
 {/* <!-- ----------------------Table 1-Header--------------------------------------------------- --> */}
      <Groove_pdf_header page={page} pdf={pdf}></Groove_pdf_header>

      {/* <!-- ----------------------Table 2-BASE METAL--------------------------------------------------- --> */}
      
      <div style={{position:"relative",top:"7px"}}>
      <div style={styles.group}>
        <div className="WPSbasemetal"> BASE METALS ( QW-403)</div>
      </div>
      <Tubesheet_Basemetal pdf={pdf}></Tubesheet_Basemetal>

        {/* <!-- ------------------Table 3-Joint deatil-------------------------------------------------------------> */}
      
      <div
        className="WPSgroup"
        style={{ align: "right", textAlign: "right", width: "50%" }} >
        <div className="WPStitle5">JOINT DETAIL</div>
      </div>
     <Tubesheet_JointDetails pdf={pdf}></Tubesheet_JointDetails>
     
{/* -------------------------------------Images------------------------------------------------------------------ */}
      {/* <div style={{paddingTop:"0px",top:"0px"}}> */}
        <WPS_images
          type={[type]}
          Imageheaders={Imageheaders}
          images={images}
          pdf={pdf}
        />
          {/* </div> */}
            
 {/*--------------------------------------Table 4-Filler metals----------------------------------------// */}

      <div
        className="WPSgroup"
        style={{ align: "right", textAlign: "right", width: "50%" }} >
        <div className="WPStitle5">FILLER METALS (QW-404)</div>
      </div>
        <Tubesheet_FillerMetal pdf={pdf}></Tubesheet_FillerMetal>
      
      {/* <!-- -------------------------Table 5-Welding Procedure----------------------------------------------- --> */}
      <div
        className="WPSgroup"
        style={{ align: "right", textAlign: "right", width: "45%" }} >
        <div className="WPStitle5">WELDING PROCEDURE</div>
      </div>
      <table id="table" className="WPSTable7" >
        {WPType.map((i,count) => {
          var value1 = pdf[1]?.[i.FieldName] !== "" ? pdf[1]?.[i.FieldName] : "--"
          var value2 =""
          // var value3 = pdf[3]?.[i.FieldName] !== "" ? pdf[3]?.[i.FieldName] : "--"
          var colsspan=2
          var colwidth="200px"
          col1Class = "processdatatube"
         
          if(i.Colums==2){

           value1= pdf[1]?.[i.FieldName][0]
           value2= pdf[1]?.[i.FieldName][1]
           col1Class = "processdatatube"
           colsspan=1
           colwidth="100px"
          }
          var paddingleft = i.ColumnDesc != "" ? "WPSwithLeftrcorner1" :"WPSwithoutLeftrcorner1"
          var middleLeft  = i.ColumnDesc != "" ? "WPSMidwithLefttext1" :"WPSMidwithoutLefttext1" 
           var midtext = i.Suffix === "Shielding" ? 
          <span style={{ marginLeft: '26px',fontFamily:"WorkSans-Regular" }}>{i.MiddleDesc}</span> : 
          <>{i.MiddleDesc}</>;
      
          var rowClass = "WPStrhide";
          var ThClass = "WPSthhide"
           if (count === 0) {
               rowClass = "WPStrFirstRow";
               ThClass ="WPSthhideFirstRow"
          }
          var lastrowstyle ="none"
          
          if(count == WPType.length-1){
            ThClass="WPSthhideLastRow"
            rowClass = "WPStrLastRow"
      
        
          }
          return(
          
          <tr className={rowClass}>
          
            <th
              className={ThClass}
              style={{  width: "27%" }}
            >
              {i.MiddleDesc != undefined ?  
              <div className="WPSgroup">
              <div className="WPSlcorner" >
             {i.ColumnDesc}
             </div>
            <div className={middleLeft}>
                  {midtext}
                </div>
             <div className={paddingleft} > {i.Unit} </div>
             </div>
            :
            <>
            <div className="WPSlcorner" >
             {i.ColumnDesc}
             </div>
           
             <div className="WPSrcorner1"> {i.Unit} </div>
             </>
            }
            </th>
             
 
           
            {i.Colums==2 ? 
              <td className="processdatatube"  style={{width: "200px"}}>
                    <div className="WPSgroup" style={{width: "100%"}}>
                      <div className="WPStdlcorner">
                       {value1} 
                       </div>
                      <div className="WPStdrcorner">
                       {value2}
                       </div>
                     </div>
                     </td>
          
            
          :
          <td className="processdatatube"  style={{width: "200px"}}>

          {value1}
       
        </td>
          }
          </tr>)
        })}

          </table>
</div>
        <br></br>
        <div id="footer" style={{position:"fixed",top:"825px"}} >
        <table className="WPSfooter">
          <tr>
            <td>Page 1 of 2</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default TubeToTubesheet_pdf_page1;
