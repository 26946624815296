import { useState, useRef, useEffect } from "react";
import { Row, Tabs, Tab, Button } from "react-bootstrap";
import WPSTubesheetGeneral from "./WPSTubesheetGeneral";
import WPSTubesheetProcedureSpec from "./WPSTubesheetProcedureSpec";
import { useLocation } from "react-router-dom";
import { getbyprojectno } from "../../Services/wps_groove-services";
import { getdetailsById } from "../../Services/wps_details-service";
import { MessageTypes, WPSHeader } from "./WPSObjects/WPSGroove_entity";
import { ToastContainer, toast } from "react-toastify";
import { Action, Status } from "./WPSObjects/WPSGroove_entity";
import { DisableFields } from "./WPSObjects/WPS_common";

const WPSTubesheetTransaction = () => {
  const [key, setKey] = useState(1);

  const firstChildRef = useRef();
  const secondChildRef = useRef();

  const location = useLocation();

  let UserName = sessionStorage.getItem("UserName");
  let UserId = sessionStorage.getItem("UserId");
  let Band = sessionStorage.getItem("Band");

  const [copydata, setcopydata] = useState("");
  const [action, setaction] = useState(location.state?.action);
  const [originalaction] = useState(location.state?.action);
  const [WPStype] = useState(location.state?.WPStype);
  const [IsEditedPage1, setIsEditedPage1] = useState(false);
  const [IsEditedPage2, setIsEditedPage2] = useState(false);
  const [isReadonly, setreadOnly] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState("");

  const [headerObj, setheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : WPSHeader(WPStype)
  );
  
  const [detailObj, setdetailObj] = useState([]);

  const temparray = [];
  useEffect(() => {
    if (action === Action.ActionAdd) {
      let temparray = createDetailObj(1);
      setdetailObj(temparray);
    }
  }, []);

  function createDetailObj(start) {
    for (let i = start; i <= 1; i++) {
      temparray.push({
        SK: "",
        PK: "",
        WPSH_Project_No: "",
        WPSH_WPS_No: "",
        WPSD_Process: "GTAW",
        WPSD_FM_SFA: "",
        WPSD_FM_Classification: "",
        WPSD_FM_FNo: "",
        WPSD_FM_Ano: "",
        WPSD_FM_ChemicalAnalysis_TradeName: "",
        wld_createdby: "",
        wld_createdon: "",
        wld_updatedby: "",
        wld_updatedon: "",

        WPSD_WP_WeldingProcess: "GTAW",
        WPSD_WP_Type: "",
        WPSD_WP_MinimumPreheatTemp: "",
        WPSD_WP_MaximumInterpassTemp: "",
        WPSD_WP_PostHeating: "",
        WPSD_WP_TungstenType: "",
        WPSD_WP_WeldPosition: "",
        WPSD_WP_WeldProgression: "",
        WPSD_WP_NoofPasses: "",

        WPSD_WP_LayerNumber: [],
        WPSD_WP_FillerMetalSIze: [],
        WPSD_WP_ItemCode: [],
        WPSD_WP_BatchNumber: [],
        WPSD_WP_Amperes: [],
        WPSD_WP_Pulsing_current: [],
        WPSD_WP_Pulsing_current_others: [],
        WPSD_WP_Volts: [],
        WPSD_WP_TravelSpeed: [],
        WPSD_WP_HeatInput: [],
        WPSD_WP_CurrentPolarity: [],

        WPSD_WP_ArcVoltageControl: "",
        WPSD_WP_ShieldingGasType: "",
        WPSD_WP_ShieldingFlowRate: "",
        WPSD_WP_BackingGasType: "",
        WPSD_WP_BackingFlowRate: "",
        WPSD_WP_AuxiGasShieldSystem: "",

        status: "Active",
        wld_WPSType: WPStype,
      });
    }
    return temparray;
  }

  const [logObj, setlogObj] = useState({
    WPSH_Project_No_log:
      headerObj !== undefined ? headerObj.WPSH_Project_No : "",
    WPSH_WPS_No_log: headerObj !== undefined ? headerObj.WPSH_WPS_No : "",
    WPSH_User_Name_log: UserName,
    WPSH_User_Id_log: UserId,
    WPSH_Status_log: "",
  });

  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    const result = await getbyprojectno("Tubesheet",IdprojectPK, IdprojectSK);
    setheaderObj(result[0]);
  };

  const fetchbyid = async (IddetailPK, IddetailSK) => {
    const result = await getdetailsById(IddetailPK, IddetailSK);
    if (action !== Action.ActionAdd) {
      

      let len = result.length; //1
      let loop = 1 - len;

      let temparray1 = [];
      if (loop > 0) {
        temparray1 = createDetailObj(len + 1);

        setdetailObj([...result, ...temparray1]);
      } else {
        setdetailObj([...result]);
      }
    }
  };

  useEffect(() => {
    if (action !== Action.ActionAdd) {
      let IdprojectPK = location.state?.data.WPSH_Project_No;
      let IdprojectSK = location.state?.data.WPSH_WPS_No;

      fetchprojectbyid(IdprojectPK, IdprojectSK);
      headerObj.WPSH_UpdatedBy = new Date().toLocaleDateString("en-GB");
      headerObj.WPSH_UpdatedOn = sessionStorage.getItem("User");
      fetchbyid(IdprojectPK, IdprojectSK);
    }
  }, []);

  useEffect(() => {
    
    let read = DisableFields(headerObj, Status, Action, action);
    if (action === Action.ActionCopy) setBtnDisabled("");
    else if (read) setBtnDisabled("disabled");
    else setBtnDisabled("");

    setreadOnly(read);


  }, [headerObj.WPSH_status,headerObj.WPSH_Prepared_By]);

  useEffect(() => {
    const alertUser = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", alertUser);
    };
  }, [IsEditedPage1, IsEditedPage2]);

  const next = async (e) => {
    let res = false;

    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNext();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNext();
        break;
      default:
        break;
    }

    if (res === MessageTypes.Success) {
      toast.success("WPS Data Added ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (key !== 2) 
        setKey(+key + 1);
     
    } else if (res === MessageTypes.Fail) {
      toast.warning("Failed, WPS Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning(process.env.REACT_APP_SPECIAL_CHARACTER_MSG, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.RequiredAll) {
      toast.warning(
        "Required Fields not filled.Please check and Fill Required Fields for all 2 Pages.",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res === MessageTypes.Band) {
      toast.warning(
        "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  const handleSelect = (e) => {
    Temp(e, "tab");
  };

  const Temp = async (e, tabaction) => {
    
    let res = false;
    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNextTemp1();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNextTemp();
        break;
      default:
        break;
    }

    if (res === MessageTypes.Temp) {
      toast.success("Temporary Saved WPS Data Successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (tabaction === "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction === "next") {
        if (key !== 2) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res === MessageTypes.None) {
      if (tabaction === "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction === "next") {
        if (key !== 2) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res === MessageTypes.Fail) {
      toast.warning("Failed, WPS Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning("Special Characters # & % + - ' < > = ; not allowed", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Band) {
      toast.warning(
        " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  return (
    <>
      <div className="position-absolute ms-3" style={{ top: "15px" }}>
        <strong>Add New</strong>
        <span className="ms-1 warning-text" style={{ fontSize: "small"}}>
          (All fields are mandatory)
        </span>
      </div>

      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <div className="mt-4">

                 <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div>

        <Tabs
          activeKey={key}
          onSelect={(e) => handleSelect(e)}
          className="mb-2 w-100 border-end"
          fill
        >
          <Tab
            eventKey={1}
            title="WPS - Page 1 ( Tube to Tubesheet)"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <WPSTubesheetGeneral
              ref={firstChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              setIsEditedPage1={setIsEditedPage1}
            />
          </Tab>
          <Tab
            eventKey={2}
            title="WPS-Page 2"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action === Action.ActionAdd ? true : false}
          >
            <WPSTubesheetProcedureSpec
              ref={secondChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              setIsEditedPage2={setIsEditedPage2}
              setBtnDisabled={setBtnDisabled}
            />
          </Tab>
        </Tabs>

        <Row style={{ padding: "5px 90px" }}>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              className="me-2"
              style={{ display: key === 1 ? "none" : "  " ,
              width: "100px",
              height: "38px",}}
              onClick={(e) => Temp(e, "prev")}
              disabled = {isBtnDisabled}

            >
              Prev
            </Button>
            <Button
              size="sm"
              className="float-end  me-2 btn-primary"
              onClick={(e) => next(e)}
              style={{
                width: "100px",
                height: "38px",
              }}
              disabled = {isBtnDisabled}
            >
              {key === 2 ? "Done" : "Next"}
            </Button>
            {originalaction !== Action.ActionAdd ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary "
                className="float-end  me-2"
                onClick={(e) => Temp(e, "next")}
                style={{ width: "100px", height: "38px" }}
                disabled = {isBtnDisabled}
              >
                {key === 2 ? "Temp Save" : "Temp Save"}
              </Button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default WPSTubesheetTransaction;
