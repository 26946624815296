//TABLE WITH 3 ROWS AND 6 COLUMNS (WPQ- BASE METAL TABLE)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import { isAlphanumericAllSpecialCharacters } from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { SearchSelect } from "../../utility/search-select";
import { useState } from "react";

import { useLocation } from "react-router-dom";

export const BaseMetalTable = ({
  headerObj,
  error,
  isReadonly,
  isDisabled,
  handleChange,
  setError,
  PNoActive,
  setheaderObj,
  errorstyle,
  blyestyle,
  id,
}) => {

  const [IsEdited, setIsEdited] = useState(false);
  const location = useLocation();


  return (
    <Table className="text-center  ">
      <thead>
        <tr className="border thead-color ">
        
          <th className="thickness_title" style={{width:"150px"}}></th>
          <th className="thickness_title">Product Form</th>
        
          <th className="thickness_title">
            Spec (type of Grade),UNS#
          </th>
          <th
            className="thickness_title"
            style={{width:"120px"}}
          >
            P No. /A No.
          </th>
          <th className="thickness_title">G No.</th>
         
          <th className="thickness_title ">Thk.mm</th>
          <th className="thickness_title ">Dia.mm</th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            error.WPQ_BM_BM_ProductForm &&
            (error.WPQ_BM_BM_ProductForm.status ||
              error.WPQ_BM_BM_Spec.status ||
              error.WPQ_BM_BM_Gno.status ||
              error.WPQ_BM_BM_Thk.status ||
        
              error.WPQ_BM_BM_Dia.status ||
              error.WPQ_BM_BM_Pno.status)
              ? "border error"
              : "border"
          }
        >
          <th
            className={
              error.WPQ_BM_BM_ProductForm &&
              (error.WPQ_BM_BM_ProductForm.status ||
                error.WPQ_BM_BM_Spec.status ||
                error.WPQ_BM_BM_Gno.status ||
                error.WPQ_BM_BM_Thk.status ||
              
                error.WPQ_BM_BM_Dia.status ||
                error.WPQ_BM_BM_Pno.status)
                ? "text-start border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>Base Metal 1</label>
              {error.WPQ_BM_BM_ProductForm &&
                (error.WPQ_BM_BM_ProductForm.status ||
                  error.WPQ_BM_BM_Spec.status ||
                  error.WPQ_BM_BM_Gno.status ||
     
                  error.WPQ_BM_BM_Thk.status ||
                  error.WPQ_BM_BM_Dia.status ||
                  error.WPQ_BM_BM_Pno.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BM_BM_ProductForm.message ||
                      error.WPQ_BM_BM_Spec.message ||
                      error.WPQ_BM_BM_Gno.message ||
                      error.WPQ_BM_BM_Thk.message ||
                    
                      error.WPQ_BM_BM_Dia.message ||
                      error.WPQ_BM_BM_Pno.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>

          <td className=" WpsInput-md">
            <input
              id="WPQ_BM_BM_ProductForm"
              style={{ height: "38px", width:"152px"}}
              type="text"
              className={
                error.WPQ_BM_BM_ProductForm &&
                error.WPQ_BM_BM_ProductForm.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_ProductForm"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 15}
              value={headerObj.WPQ_BM_BM_ProductForm}
              readOnly={isReadonly}
              disabled={isDisabled}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_ProductForm: error,
                }));
              }}
            />
          </td >
  
          <td  className="WpsInput-md">
            <input
              id="WPQ_BM_BM_Spec"
              type="text"
              style={{ height: "38px" ,width:"250px"}}
              className={
                error.WPQ_BM_BM_Spec && error.WPQ_BM_BM_Spec.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Spec"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 50 : 24}
              value={headerObj.WPQ_BM_BM_Spec}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
               if(headerObj.wld_WPSType === "Tubesheet"){
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Spec: error,
                }));
              }else{
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Spec: error,
                }));
              }
              }}
            />
          </td>
          <td className=" WPSSelect" >
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.WPQ_BM_BM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj.WPQ_BM_BM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={error.WPQ_BM_BM_Pno.status ? "error-input WPSSelect " : "WPSSelect"}
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  WPQ_BM_BM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md ">
            <input
              type="text"
              style={{ height: "38px" }}
              className={
                error.WPQ_BM_BM_Gno && error.WPQ_BM_BM_Gno.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Gno"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 4}
              value={headerObj.WPQ_BM_BM_Gno}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Gno: error,
                }));
              }else{
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Gno: error,
                }));
              }
            }}
            />
          </td>
      
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_BM_Thk && error.WPQ_BM_BM_Thk.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Thk"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 6}
              value={headerObj.WPQ_BM_BM_Thk}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Thk: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Thk: error,
                 }));
               }
               }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_BM_Dia && error.WPQ_BM_BM_Dia.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Dia"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 6}
              value={headerObj.WPQ_BM_BM_Dia}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Dia: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Dia: error,
                 }));
               }
               }}
              
            />
          </td>
        </tr>
        <tr
          className={
            error.WPQ_BM_WTBM_ProductForm &&
            (error.WPQ_BM_WTBM_ProductForm.status ||
              error.WPQ_BM_WTBM_Spec.status||
              error.WPQ_BM_WTBM_Pno.status||
              error.WPQ_BM_WTBM_Gno.status||
              
              error.WPQ_BM_WTBM_Thk.status||
              error.WPQ_BM_WTBM_Dia.status)
              ? "border  error"
              : "border "
          }
        >
          <th
            className={
              error.WPQ_BM_WTBM_ProductForm &&
            (error.WPQ_BM_WTBM_ProductForm.status ||
              error.WPQ_BM_WTBM_Spec.status||
              error.WPQ_BM_WTBM_Pno.status||
              error.WPQ_BM_WTBM_Gno.status||
            
              error.WPQ_BM_WTBM_Thk.status||
              error.WPQ_BM_WTBM_Dia.status)
                ? "text-start  border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              Welded To - Base Metal 2
              { error.WPQ_BM_WTBM_ProductForm &&
            (error.WPQ_BM_WTBM_ProductForm.status ||
              error.WPQ_BM_WTBM_Spec.status||
              error.WPQ_BM_WTBM_Pno.status||
              error.WPQ_BM_WTBM_Gno.status||
     
              error.WPQ_BM_WTBM_Thk.status||
              error.WPQ_BM_WTBM_Dia.status) && (
                  <Tooltip
                    content={
                    error.WPQ_BM_WTBM_ProductForm.message ||
                        error.WPQ_BM_WTBM_Spec.message||
                        error.WPQ_BM_WTBM_Pno.message||
                        error.WPQ_BM_WTBM_Gno.message||
                     
                        error.WPQ_BM_WTBM_Thk.message||
                        error.WPQ_BM_WTBM_Dia.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px", width:"152px"  }}
              type="text"
              className={
                error.WPQ_BM_WTBM_ProductForm &&
                error.WPQ_BM_WTBM_ProductForm.status 
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_ProductForm"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 15}
              value={headerObj.WPQ_BM_WTBM_ProductForm}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_WTBM_ProductForm: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" ,width:"250px"}}
              type="text"
              className={
                error.WPQ_BM_WTBM_Spec && error.WPQ_BM_WTBM_Spec.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Spec"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 50 : 24}
              value={headerObj.WPQ_BM_WTBM_Spec}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Spec: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Spec: error,
                 }));
               }
               }}
            />
          </td>
          <td className="WPSSelect">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.WPQ_BM_WTBM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj.WPQ_BM_WTBM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={
                error.WPQ_BM_WTBM_Pno.status
                  ? "err-input WPSSelect"
                  : "WPSSelect"
              }
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  WPQ_BM_WTBM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_WTBM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_WTBM_Gno && error.WPQ_BM_WTBM_Gno.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Gno"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 4}
              value={headerObj.WPQ_BM_WTBM_Gno}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_WTBM_Gno: error,
                }));
              }}
            />
          </td>
       
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_WTBM_Thk && error.WPQ_BM_WTBM_Thk.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Thk"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 6}
              value={headerObj.WPQ_BM_WTBM_Thk}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Thk: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Thk: error,
                 }));
               }
               }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_WTBM_Dia && error.WPQ_BM_WTBM_Dia.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Dia"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 20 : 6}
              value={headerObj.WPQ_BM_WTBM_Dia}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Dia: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Dia: error,
                 }));
               }
               }}
            />
          </td>
        </tr>
        <tr className="border"
        >
          <th
            className={"text-start border thead-color"
            }
          >
            <div>
              Joint Type
            </div>
          </th>
          <td className="WpsInput-md">
            <span style={{ fontWeight: "500" }}>{headerObj.wld_WPSType}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{borderRight:"1px solid var(--col-ddd)"}}></td>



        </tr>
      </tbody>
    </Table>
  );
};

export const BaseMetalTableTTS = ({
  headerObj,
  error,
  isReadonly,
  isDisabled,
  handleChange,
  setError,
  PNoActive,
  setheaderObj,
  errorstyle,
  blyestyle,
  id,
}) => {


  const location = useLocation();



  return (
    <Table className="text-center">
      <thead>
        <tr className="border thead-color ">

          <th className="thickness_title" style={{ width: "150px" }}></th>
          <th className="thickness_title  ">Product Form</th>
          <th className="thickness_title ">
            Spec (type of Grade),UNS#
          </th>
          <th
            className="thickness_title "
            style={{ width: "120px" }}
          >
            P No. /A No.
          </th>
          <th className="thickness_title  ">G No.</th>
          <th className="thickness_title ">Thk.mm</th>
          <th className="thickness_title ">Dia.mm</th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            error.WPQ_BM_BM_ProductForm &&
            (error.WPQ_BM_BM_ProductForm.status ||
              error.WPQ_BM_BM_Spec.status ||
              error.WPQ_BM_BM_Gno.status ||
              error.WPQ_BM_BM_Thk.status ||
              error.WPQ_BM_BM_Dia.status ||
              error.WPQ_BM_BM_Pno.status)
              ? "border error"
              : "border"
          }
        >
          <th
            className={
              error.WPQ_BM_BM_ProductForm &&
              (error.WPQ_BM_BM_ProductForm.status ||
                error.WPQ_BM_BM_Spec.status ||
                error.WPQ_BM_BM_Gno.status ||
                error.WPQ_BM_BM_Thk.status ||
                error.WPQ_BM_BM_Dia.status ||
                error.WPQ_BM_BM_Pno.status)
                ? "text-start border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>Base Metal 1</label>
              {error.WPQ_BM_BM_ProductForm &&
                (error.WPQ_BM_BM_ProductForm.status ||
                  error.WPQ_BM_BM_Spec.status ||
                  error.WPQ_BM_BM_Gno.status ||
                  error.WPQ_BM_BM_Thk.status ||
                  error.WPQ_BM_BM_Dia.status ||
                  error.WPQ_BM_BM_Pno.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BM_BM_ProductForm.message ||
                      error.WPQ_BM_BM_Spec.message ||
                      error.WPQ_BM_BM_Gno.message ||
                      error.WPQ_BM_BM_Thk.message ||
                      error.WPQ_BM_BM_Dia.message ||
                      error.WPQ_BM_BM_Pno.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>

          <td className=" WpsInput-md">
            <input
              id="WPQ_BM_BM_ProductForm"
              style={{ height: "38px", width:"152px"}}
              type="text"
              className={
                error.WPQ_BM_BM_ProductForm &&
                error.WPQ_BM_BM_ProductForm.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_ProductForm"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 15 : 16}
              value={headerObj.WPQ_BM_BM_ProductForm}
              readOnly={isReadonly}
              disabled={isDisabled}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_ProductForm: error,
                }));
              }}
            />
          </td>

          <td className="WpsInput-md">
            <input
              id="WPQ_BM_BM_Spec"
              type="text"
              style={{ height: "38px" ,width:"250px"}}
              className={
                error.WPQ_BM_BM_Spec && error.WPQ_BM_BM_Spec.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Spec"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 35 : 25}
              value={headerObj.WPQ_BM_BM_Spec}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
               if(headerObj.wld_WPSType === "Tubesheet"){
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Spec: error,
                }));
              }else{
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Spec: error,
                }));
              }
              }}
            />
          </td>
          <td className="WPSSelect">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.WPQ_BM_BM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj.WPQ_BM_BM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={error.WPQ_BM_BM_Pno.status ? "error-input WPSSelect" : "WPSSelect"}
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  WPQ_BM_BM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md ">
            <input
              type="text"
              style={{ height: "38px" }}
              className={
                error.WPQ_BM_BM_Gno && error.WPQ_BM_BM_Gno.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Gno"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 5 : 4}
              value={headerObj.WPQ_BM_BM_Gno}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Gno: error,
                }));
              }else{
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_BM_Gno: error,
                }));
              }}
            }
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_BM_Thk && error.WPQ_BM_BM_Thk.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Thk"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj.WPQ_BM_BM_Thk}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Thk: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Thk: error,
                 }));
               }
               }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_BM_Dia && error.WPQ_BM_BM_Dia.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BM_BM_Dia"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj.WPQ_BM_BM_Dia}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Dia: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_BM_Dia: error,
                 }));
               }
               }}
              
            />
          </td>
        </tr>
        <tr
          className={
            error.WPQ_BM_WTBM_ProductForm &&
            (error.WPQ_BM_WTBM_ProductForm.status ||
              error.WPQ_BM_WTBM_Spec.status||
              error.WPQ_BM_WTBM_Pno.status||
              error.WPQ_BM_WTBM_Gno.status||
              error.WPQ_BM_WTBM_Thk.status||
              error.WPQ_BM_WTBM_Dia.status)
              ? "border  error"
              : "border "
          }
        >
          <th
            className={
              error.WPQ_BM_WTBM_ProductForm &&
            (error.WPQ_BM_WTBM_ProductForm.status ||
              error.WPQ_BM_WTBM_Spec.status||
              error.WPQ_BM_WTBM_Pno.status||
              error.WPQ_BM_WTBM_Gno.status||
              error.WPQ_BM_WTBM_Thk.status||
              error.WPQ_BM_WTBM_Dia.status)
                ? "text-start  border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              Welded To - Base Metal 2
              { error.WPQ_BM_WTBM_ProductForm &&
            (error.WPQ_BM_WTBM_ProductForm.status ||
              error.WPQ_BM_WTBM_Spec.status||
              error.WPQ_BM_WTBM_Pno.status||
              error.WPQ_BM_WTBM_Gno.status||
              error.WPQ_BM_WTBM_Thk.status||
              error.WPQ_BM_WTBM_Dia.status) && (
                  <Tooltip
                    content={
                    error.WPQ_BM_WTBM_ProductForm.status ||
                        error.WPQ_BM_WTBM_Spec.status||
                        error.WPQ_BM_WTBM_Pno.status||
                        error.WPQ_BM_WTBM_Gno.status||
                        error.WPQ_BM_WTBM_Thk.status||
                        error.WPQ_BM_WTBM_Dia.status
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px", width:"152px"  }}
              type="text"
              className={
                error.WPQ_BM_WTBM_ProductForm &&
                error.WPQ_BM_WTBM_ProductForm.status 
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_ProductForm"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 15 : 16}
              value={headerObj.WPQ_BM_WTBM_ProductForm}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_WTBM_ProductForm: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" ,width:"250px"}}
              type="text"
              className={
                error.WPQ_BM_WTBM_Spec && error.WPQ_BM_WTBM_Spec.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Spec"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 35 : 25}
              value={headerObj.WPQ_BM_WTBM_Spec}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Spec: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Spec: error,
                 }));
               }
               }}
            />
          </td>
          <td className="WPSSelect">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.WPQ_BM_WTBM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj.WPQ_BM_WTBM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={
                error.WPQ_BM_WTBM_Pno.status
                  ? "err-input WPSSelect"
                  : "WPSSelect"
              }
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  WPQ_BM_WTBM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_WTBM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_WTBM_Gno && error.WPQ_BM_WTBM_Gno.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Gno"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 5 : 4}
              value={headerObj.WPQ_BM_WTBM_Gno}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BM_WTBM_Gno: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_WTBM_Thk && error.WPQ_BM_WTBM_Thk.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Thk"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj.WPQ_BM_WTBM_Thk}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Thk: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Thk: error,
                 }));
               }
               }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.WPQ_BM_WTBM_Dia && error.WPQ_BM_WTBM_Dia.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BM_WTBM_Dia"}
              maxLength={headerObj.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj.WPQ_BM_WTBM_Dia}
              onChange={handleChange}
              onInput={(e) => {
                if(headerObj.wld_WPSType === "Tubesheet"){
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Dia: error,
                 }));
               }else{
                 let error = isAlphanumericAllSpecialCharacters(e.target.value);
                 setError((prevState) => ({
                   ...prevState,
                   WPQ_BM_WTBM_Dia: error,
                 }));
               }
               }}
            />
          </td>
        </tr>
        {/* <tr
        >
          <th
            className={"text-start border thead-color"
            }
          >
            <div>
              Joint Type
            </div>
          </th>
          <td className="WpsInput-md">
            <span style={{ fontWeight: "500" }}>{headerObj.wld_WPSType}</span>
          </td>
        </tr> */}
      </tbody>
    </Table>
  );
};