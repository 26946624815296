import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";
import SuccessAlertModal from "../../Components/Modals/successAlertModal";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import { getAllFno,  UpdateFno } from "../../Services/fno-services";
import AddEditFnoMaster from "./AddEditFnoMaster";

import GlobalColumnDefinations,{ getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GridMaster from "../utility/GridMaster";
import ActionRenderer from "../utility/ActionRenderer";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";

const Fno = forwardRef((props, ref) => {
  let formName = "F No.";

  const [rowData, setRowData] = useState({});

  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const [excelData1, setExcelData] = useState([]);

  const [Fno, setFno] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);


  const childRef = useRef();
  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "wld_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "statusRenderer",
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: "Fno Number",
      field: "SK",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Fno Category",
      field: "wld_category",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];


  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchFno = async () => {
    const result = await getAllFno();
    setFno(result);
    setFilteredResult(result);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ headerName: "", cellRenderer: actionRenderer, width: 250,   suppressMenu: true,
    sortable:false,});
    setColumnDefs(result);
  };

  useEffect(() => {
    fetchFno();
    fetchColumnDefns();
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Fno);
  };


  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";
  const history = useNavigate();
  const [show1, setShow1] = useState(false);


  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setFno",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text);

  };

  const handleGlobalExport = () => {
    let filename = "FnoMaster";
    setExcelData(filteredResult);
    handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  const handleDelete = (row) => {
    let result;
    data.wld_status = "Inactive";
    result = UpdateFno(data);
    result
      .then((resp) => {
        if (resp.message === "Success") {
        setShowForm(false);
          toast.success(`Selected F No. has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        } else {
          toast.warning("Error!!!, Not able to deactivate F No.", {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        toast.warning("Error!!!, Not able to Add or deactivate Data", {
          position: toast.POSITION.TOP_LEFT,

          className: "toast-message",
        }); 
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  return (
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />

      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          F No. List
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              filterFunc={handleGlobalSearch}
              searchplaceholdertext={"Search for Fno Number, Fno Description"}
            />
            {/* <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
        paginationPageSize={6}
        pagination={true}
        // height={500}
      />

      {showForm && (
        <AddEditFnoMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          fetchUserMaster={fetchFno}
          allRecs={Fno}
          backdrop="static"
        />
      )}
      <Toaster />
    </Container>
  );
});

export default Fno;
