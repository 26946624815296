import axios from "axios";



let MASTER_API_URL = ""
if (process.env.REACT_APP_NODE_ENV === "local") {
   MASTER_API_URL = process.env.REACT_APP_TRAINING_API_URL;
}
else{
   MASTER_API_URL = process.env.REACT_APP_API_URL + "training";
}

export async function getTrainingRecordApproved() {
    const response = await axios
      .get(MASTER_API_URL + "/trainRecord/getapprove")
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response.data;
  }

 

export const addTraining = async (trainingRecords) => {
    

    let url =  `${MASTER_API_URL}/trainRecord/`;
    const response = await axios
      .post(url, trainingRecords)
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response.data;
  };


  export async function updateTrainingRecord(skid) {

    let url = `${MASTER_API_URL}/trainRecord/update`;
    const response = await axios
        .post(url,skid)
        .then(function (resp) {
        return resp;
        })
        .catch(function (error) {
        return { message: "Unable to find request", status: false };
        });
    return response.data;
};

export const DeleteTrainingRecord = async (training) => {
  let url = `${MASTER_API_URL}/delete`;
  const resp = await axios
    .post(url,training)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}

