import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";
import SuccessAlertModal from "../../Components/Modals/successAlertModal";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import {
  getAllItem,

  UpdateItem,
} from "../../Services/item-services";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";

import GlobalColumnDefinations, { getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import AddEditItemMaster from "./AddEditItemMaster";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";


const Item = forwardRef((props, ref) => {
  let formName = "Item";

  const [rowData, setRowData] = useState({});


  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [Item, setItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);
  const [excelData1, setExcelData] = useState([]);


  const childRef = useRef();

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "wld_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "statusRenderer",
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: " Project",
      field: "wld_code",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Item Code",
      field: "wld_itemcode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Description",
      field: "wld_description",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Item Group",
      field: "wld_itemgroup",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Item Type",
      field: "wld_itemtype",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Weight of consumable (kg/Electrode)",
      field: "wld_weight",
      type: "Number",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];


  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchItem = async () => {
    const result = await getAllItem();
    setItem(result);
    setFilteredResult(result);
    setExcelData(result);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ field: "", cellRenderer: actionRenderer, width: 250 ,   suppressMenu: true,
    sortable:false,});
    setcolumnDefs(result);
  };

  useEffect(() => {
    fetchItem();
    fetchColumnDefns();
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Item);
  };


  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";
  const history = useNavigate();
  const [show1, setShow1] = useState(false);


  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setItem",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text);
    
  };

  const handleGlobalExport = () => {
    let filename = "ItemMaster";
    setExcelData(filteredResult);
    handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const actionRenderer = (params) => {
    return (
      <div className="cell-action">
        <img
          src={Edit_gray}
          onMouseOver={(e) => (e.currentTarget.src = Edit)}
          onMouseOut={(e) => (e.currentTarget.src = Edit_gray)}
          alt="search"
          className="p-1"
          onClick={() => handleEdit(params.data)}
        />
        <img
          src={Delete_gray}
          onMouseOver={(e) => (e.currentTarget.src = Delete)}
          onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
          alt="delete"
          className="p-1"
          onClick={() => {
            setdata(params.data);
            setShow1(true);
            setAction("Delete");
          }}
        />
      </div>
    );
  };

  const handleDelete = (row) => {
    let result;
    data.wld_status = "Inactive";
    result = UpdateItem(data);
    result
      .then((resp) => {
        if (resp.message === "Success") {
          setShowForm(false);
          toast.success(`Selected Item  has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        } else {
          toast.warning("Error!!!, Not able to deactivate Item.", {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        toast.warning("Error!!!, Not able to Add or deactivate Data", {
          position: toast.POSITION.TOP_LEFT,

          className: "toast-message",
        }); 
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  return (
    <Container fluid className="role-master-container">
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />{" "}
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Item Code List
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for Project Number, Item Code , Description, Item Group"
              }
              filterFunc={handleGlobalSearch}
            />
            {/* <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>
      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        
        setExcelData={setExcelData}
        ref={childRef}
        paginationPageSize={6}
        pagination={true}
        // height={500}
      />
      {showForm && (
        <AddEditItemMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          fetchUserMaster={fetchItem}
          allRecs={Item}
          backdrop="static"
        />
      )}
      <Toaster />
    </Container>
  );
});

export default Item;
