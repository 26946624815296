import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { Container } from "react-bootstrap";


import GlobalColumnDefinations,{ getHeader_FieldName } from "../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";

import {
  getQualReqByType,
} from "../../Services/trainingRequisition-service";

import ActioRenderReq from "../utility/ActioRenderReq";
import {
  FormatYYYYMMDD,getDefaultDate,
  DateDiff
} from "../../functions/welding-functions";

export const QualificationReqApproval = forwardRef((props, ref) => {
  const childRef = useRef();

  let formName = "Welder Qualification Requisition";
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const [action, setAction] = useState("");

  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);

  const [show1, setShow1] = useState(false);


  const handleEdit = (row) => {
    props.setAction("Edit");
    props.setRowData(row);
    props.setShowForm(true);
    props.setCalledFrom("Approval");
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, props.tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, props.tmpcolumnDefs, "getField");
    return name;
  };

  function setRefre() {
    props.setRefe(childRef);
  }

 // fetch data of
  const fetchTrainingRequisition = async () => {
    let tmpObj = { fieldValue: sessionStorage.getItem("Role"), flag: "pending" };

    const result = await getQualReqByType(tmpObj);
    
    result.forEach((i) =>{
      i.wld_pendingSince = DateDiff(FormatYYYYMMDD(i.wld_TrainingReqDate),getDefaultDate())
      i.wld_CycleTime = 0
    })
    setFilteredResult(result);
  };

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(props.tmpcolumnDefs);
    result[result.length - 4].cellRenderer = actionRenderer;
    setColumnDefs(result);
  };

  useImperativeHandle(ref, () => ({
    reload: async () => {
      fetchTrainingRequisition();
      setRefre();
      fetchColumnDefns();
    },
  }));

  useEffect(() => {
    fetchTrainingRequisition();
    setRefre();
    fetchColumnDefns();
  }, reload);

  const handleClose = () => {
    setShow1(false);
  };

  const actionRenderer = (params) => {
    return (
      <>
        <ActioRenderReq
          selectedData={params}
          handleEditEvent={(params) => handleEdit(params.data)}
          handleDeleteEvent={(params) => {
            setdata(params.data);
            setShow1(true);
            setAction("Delete");
            
          }}
        />
      </>
    );
  };



  return (
    <Container fluid>
      <ConfirmAlertModal
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={props.setExcelData}
        ref={childRef}
        paginationPageSize={4}
        pagination={true}
        height={350}
      />

      <Toaster />
    </Container>
  );
});
