
import "../Css/Download_pdf.css";

import Groove_pdf_header from "./Groove_pdf_header_common";
import PageFooter from "./PageFooter";
import TechniqueTable_Groove from "./WPS_groove_TechniqueTable";
import TechniqueTable_overlay from "./WPS_overlay_TechniqueTable";
import WPS_images from "./WPS_image";

const Groove_pdf_page2 = ({
  pdf,
  PreviewUrl,
  previewuser,
  previewapproved,
  Band,
  previewUrl1,
  previewUrl2,
  previewUrl3,
}) => {
  var cols = 3,
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class;
    let WPSProcessDataWidth

    //pdf images array
    var images = [ previewUrl1 ,previewUrl2 ,previewUrl3];
    var type = [{ Type: pdf[0]?.wld_WPSType}]; 


  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";

  col1Class = "WPSleft7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "WPSleft7-td"
        : "WPSleft7-td-noright"
      : "WPStdhidden";
  col3Class = pdf[3] !== undefined ? "WPSleft7-td-noright1" : "WPStdhidden";


  WPSProcessDataWidth = pdf.length < 3 ? "process1" : ( pdf.length < 4 ? "process2" : "process3") 

  const width = "72.6%";
  const height = 595 - window.innerHeight + "px";

  const currentDate = new Date(pdf[0]?.WPSH_WPS_Date);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;


  return (
    <div className="WPSMainPDF">
      <div id="container" className="WPSflexb"> 
         <div className="WPSheaderRow">
          {window.innerHeight < 595 ? (
            <div style={{ height: { height } }}> </div>
          ) : (
            ""
          )}

          <Groove_pdf_header from={"WPS"} pdf={pdf}></Groove_pdf_header>
        </div>


        {/* <!-- ----------------------------------Table 4---------------------------------------------------- --> */}

        <div id="body" className="WPSContent" style={{ height: "670px" }}>
          <br></br>
          <table id="table" className="WPSTable1">
            <tbody>
             <tr>
            <th style={{width: "13%", borderTop:"1px solid",borderBottom:"1px solid",borderRight:"1px solid",borderLeft:"1px solid"}}>
              WPS record number
              <br></br>
              Date
            </th>
            <td style={{ width: "25%" ,borderTop:"1px solid",borderBottom:"1px solid" }}>
              <span className="WPSforfont" style={{ float: "left"  }}>
                {pdf[0]?.WPSH_WPS_No}
              </span>
              <span className="WPSforfont" style={{ float: "right"  }}>
                Revision: {pdf[0]?.WPSH_WPS_Revi_No}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <br />
              <span className="WPSforfont" style={{ float: "left" }}>
                {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
              </span>
            </td>
            <th className="WPSright-th" style={{ width: "15%",borderLeft:"1px solid",borderBottom:"1px solid" ,borderTop:"1px solid",borderRight:"1px solid"}}>
              Qualified to
              <br></br>
              Company Name
            </th>

            <td style={{ width: "35%",borderTop:"1px solid",borderBottom:"1px solid", borderRight:"1px solid" }}>
              {pdf[0]?.WPS_QualifiedTo}
              <br></br>
              {pdf[0]?.WPSH_CompanyName}
            </td>
          </tr>
            </tbody>
          </table>
          {pdf[0]?.wld_Joints_Type === "Joint section" ? (
            <>
              <div className="WPSgroup">
                <div className="WPSbasemetal" style={{ width: "70%" }}>
                  JOINTS ( QW-402) Typical joints(s). See actual production
                  drawings and engineering specification for details
                </div>
              </div>
              <WPS_images  type={[type]} images={images} pdf={pdf}/>
              </>
          ) : (
            ""
          )}

          <div className="WPSgroup">
            <div className="WPSbasemetal" style={{ width: "100%" }}>
              POST WELD HEAT TREATMENT (QW-407)
            </div>
          </div>

          {/* <!-- ---------------------------------------------------------------------------------------- --> */}

          <table
            id="table"
            className="WPSTable8"
            style={{ width: { width }, }}
          >
            <tr>
              <th
                style={{
                  width: "10.5%",
                  borderBottom: "0",
                  borderTop: "1px solid black",
                }}
              >
                Temperature
                <div className="WPSrcorner"> °C </div>
              </th>
              <td
                style={{ width: "19%", borderBottom: "0", borderLeft: "none", borderTop: "1px solid black", }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Temperature}
              </td>
              <th
                style={{
                  width: "10%",
                  borderBottom: "0",
                  borderTop: "1px solid black",
                }}
              >
                Time
                <div className="WPSrcorner"> Min </div>
              </th>

              <td
                style={{ width: "13%", borderBottom: "0", borderLeft: "none", borderTop: "1px solid black", }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Time}
              </td>

              <th
                rowSpan="3"
                style={{
                  width: "3%",
                  borderBottom: "0",
                  borderLeft: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                Type
              </th>

              <td
                rowSpan="3"
                style={{ width: "15%", borderBottom: "0", borderLeft: "none", borderTop: "1px solid black", borderRight: "1px solid black", }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Type}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%", borderBottom: "0", borderTop: "0" }}>
                Heating Rate
                <div className="WPSrcorner"> °C/hr </div>
              </th>
              <td
                style={{
                  width: "15%",
                  borderBottom: "0",
                  borderTop: "0",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Heating_Rate}
              </td>
              <th style={{ width: "15%", borderBottom: "0", borderTop: "0" }}>
              Loading Temp.<div className="WPSrcorner"> °C </div>
 
              </th>

              <td
                style={{
                  width: "25%",
                  borderBottom: "0",
                  borderTop: "0",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Heating_Method}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%", borderTop: "0", borderBottom: "0" }}>
                Cooling Rate
                <div className="WPSrcorner"> °C/hr </div>
              </th>
              <td
                style={{
                  width: "15%",
                  borderTop: "0",
                  borderBottom: "0",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Cooling_Rate}
              </td>
              <th style={{ width: "15%", borderTop: "0", borderBottom: "0" }}>
              Unloading Temp.  <div className="WPSrcorner"> °C </div>

              </th>

              <td
                style={{
                  width: "25%",
                  borderTop: "0",
                  borderBottom: "0",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Cooling_Method}
              </td>
            </tr>
            <tr style={{ borderTop: "1px" }}>
              <th  style={{
                  borderTop: "1px solid black", borderBottom: "1px solid black",}}> PWHT Notes</th>
              <td
                colSpan="5"
                style={{
                  borderLeft: "none",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Notes != undefined
                  ? pdf[0]?.WPSH_PWHeatTre_Notes.toString().replace(
                      "\n",
                      "<BR/>"
                    )
                  : pdf[0]?.WPSH_PWHeatTre_Notes}
              </td>
            </tr>
            {pdf[0]?.wld_WPSType !== "Overlay" ? (
              <tr
                style={{ borderTop: "none", borderBottom: "1px solid black" }}
              >
                <th style={{ width: "25%", borderTop: "0"}}>
                  ISR Temprature
                  <div className="WPSrcorner"> °C/hr </div>
                </th>
                <td
                  style={{
                    width: "15%",
                    borderTop: "0",
                    borderBottom: "0",
                  }}
                >
                  {pdf[0]?.WPSH_PWHeatTre_ISR_Temp}
                </td>
                <th
                  style={{
                    width: "20%",
                    borderTop: "0",
                    borderBottom: "1px solid black",
                  }}
                >
                  ISR Time
                  <div className="WPSrcorner"> Min </div>
                </th>

                <td colSpan="3"
                  style={{
                    width: "25%",
                    borderTop: "0",
                    borderBottom: "0",
                    borderLeft: "none",  borderRight: "1px solid black",
                  }}
                >
                  {pdf[0]?.WPSH_PWHeatTre_ISR_Time}
                </td>
              </tr>
            ) : (
              ""
            )}
            {pdf[0]?.wld_WPSType !== "Overlay" ? (
              <tr>
                <th>ISR Notes</th>
                <td colSpan="5" style={{ borderLeft: "none", borderRight: "1px solid black",  borderBottom: "1px solid black"}}>
                  {pdf[0]?.WPSH_PWHeatTre_Weld_Notes}
                </td>
              </tr>
            ) : (
              ""
            )}
          </table>

          <table className="WPSTable6" style={{ width: "570px" }}>
            <tr>
              <td style={{ width: "60%" }}>
                &nbsp; DHT after completion of welding before cooling from
                Preheat prior to PWHT (QW-406.2)
              </td>
              <th
                style={{
                  width: "14%",
                  borderLeft: ".1px solid black",
                  borderRight: ".1px solid black",
                  borderBottom: ".1px solid black",
                }}
              >
                Temperature
                <div className="WPSrcorner"> °C </div>
              </th>
              <td
                style={{
                  borderLeft: "none",
                  borderRight: ".1px solid black",
                  borderBottom: ".1px solid black",
                }}
              >
                {pdf[0]?.WPSH_PWHeatTre_Post_Heat_temp == undefined
                  ? "-"
                  : pdf[0]?.WPSH_PWHeatTre_Post_Heat_temp}{" "}
                °C for {" "}
                {pdf[0]?.WPSH_PWHeatTre_Post_Heat_time == undefined
                  ? "-"
                  : pdf[0]?.WPSH_PWHeatTre_Post_Heat_time}{" "}
                Minutes
              </td>
            </tr>
          </table>
          <br></br>
          <div className="WPSgroup">
            <div className="WPSbasemetal">TECHNIQUE (QW-410)</div>
          </div>
          {pdf[0].wld_WPSType === "Groove" ? (
        <TechniqueTable_Groove pdf={pdf}> </TechniqueTable_Groove>):

       ( <TechniqueTable_overlay pdf={pdf}> </TechniqueTable_overlay>)}
        


          <div className="WPSgroup" >
            <div className="WPSbasemetal">NOTES</div>
          </div>

          <table id="table" className="WPSTable10">
            <tbody>
              <tr>
                <td className="Noteswrap">
                  {pdf[0]?.WPSH_Notes != undefined
                    ? pdf[0]?.WPSH_Notes.split("\n").map((i) => {
                        return (
                          <>
                            {i}

                            <br></br>
                          </>
                        );
                      })
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>

          <br></br>

          <br></br>

  
          <div style={{position:"fixed",top:"1590px"}}>
          <PageFooter
            pdf={pdf}
            previewuser={previewuser}
            Band={Band}
            previewapproved={previewapproved}
          ></PageFooter>
          </div>
    {/* <!-- --------------------------Welding Procedure----------------------------------------------- --> */}
        </div>
        <div  style={{position:"fixed", top:"1680px"}}>
          <table className="WPSfooter">
            <tr>
              <td>Page 2 of 2</td>
            </tr>
          </table>
        </div>
      </div>
     </div>
  );
};

export default Groove_pdf_page2;
