const Tubesheet_JointDetails =({pdf}) =>{
  
   return(

<table id="table" className="WPSTable2" style={{width: "72%",paddingBottom:"0px",bottom:"0px",marginBottom:"0px"}}>
  <tbody style={{  }}>
    <tr style={{marginBottom:"0px" }}>
      <th
        style={{
          borderTop: "0.5px solid black",

          width:"15%",
          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        Joint Type
      </th>
      <td class="table2firstcolumn" style={{ borderTop: "0.5px solid black", padding: "1px 4px" , width:"28%"}}>
        {pdf[0]?.WPSH_JD_JointType}
      </td>
      <th
        style={{
          borderTop: "0.5px solid black",

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
          width:"20%"
        }}
      >
        Hole Id <div className="WPSrcorner"> mm </div>
      </th>
      <td class="table2firstcolumn" style={{  borderTop: "0.5px solid black",borderRight: "0.5px solid black",width:"35%" }}>
        {pdf[0]?.WPSH_JD_HoleId}
      </td>
    </tr>
    <tr>
      <th
        style={{

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        Tube Pitch
        <div className="WPSrcorner"> mm </div>
      </th>
      <td class="table2firstcolumn" style={{ padding: "1px 4px" }}>
        {pdf[0]?.WPSH_JD_TubePitch}
      </td>
      <th
        style={{

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        Groove Depth
        <div className="WPSrcorner"> mm </div>
      </th>
      <td class="table2firstcolumn" style={{ borderRight: "0.5px solid black" }}>
        {pdf[0]?.WPSH_JD_GrooveDepth}
      </td>
    </tr>
    <tr style={{ borderBottom: "0" }}>
      <th
        style={{

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        Tube Projection
        <div className="WPSrcorner"> mm </div>
      </th>
      <td class="table2firstcolumn" style={{ padding: "1px 4px" }}>
        {pdf[0]?.WPSH_JD_TubeProj}
      </td>
      <th
        style={{

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        Width of the ligament
        <div className="WPSrcorner"> mm </div>
      </th>
      <td class="table2firstcolumn" style={{ borderRight: "0.5px solid black", }}>
        {pdf[0]?.WPSH_JD_WidthLigament}
      </td>
    </tr>
    <tr style={{ borderBottom: "0" }}>
      <th
        style={{

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >
        Pattern
      </th>
      <td class="table2firstcolumn" style={{ padding: "1px 4px" }}>
        {pdf[0]?.WPSH_JD_Pattern}
      </td>
      <th
        style={{

          borderRight: "0.5px solid black",
          borderLeft: "0.5px solid black",
        }}
      >Groove Angle(°)/Radius(mm)
      </th>
      <td class="table2firstcolumn" style={{ borderRight: "0.5px solid black",}}>
        {pdf[0]?.WPSH_JD_GrooveAngle}
      </td>
    </tr>
  </tbody>
</table>

)}

export default Tubesheet_JointDetails;