import { useState, useEffect, useMemo } from "react";
import PaginationComp from "../utility/PaginationComp";

const CustomPopUpTable = ({
  data,
  type,
  masterName,
  setSelectedCard,
  ...props
}) => {
  const [tableData, setTableData] = useState([]);
  const [header, setHeader] = useState([]);
  const [temp, setTemp] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;

  useEffect(() => {
    if (data?.length > 0) {
      setTableData([...data]);
    }

    if (props.locationData?.length > 0 && data?.length > 0) {
      let temp = data.filter((value) =>
        props.locationData.includes(value.lm_loc)
      );

      setTemp(temp);
    }


  }, [data]);

  useEffect(() => {
    
    switch (masterName) {
      case "PED User":
        setHeader([
          {
            id: "empid",
            name: "Employee Code",
          },
          {
            id: "emailid",
            name: "Email ID",
          },
          {
            id: "empname",
            name: "Employee Name",
          },
        ]);
        break;
      case "User Role":
        setHeader([
          {
            id: "ram_rolecode",
            name: "Role Code",
          },
          {
            id: "ram_rolename",
            name: "Role Name",
          },
        ]);
        break;
      case "Location":
        setHeader([
          {
            id: "lm_loccode",
            name: "Location Code",
          },
          {
            id: "lm_loc",
            name: "Location",
          },
          {
            id: "lm_loctype",
            name: "Location Type",
          },
          {
            id: "lm_country",
            name: "Country",
          },
        ]);
        break;   
        case "WPQ":
          setHeader([
            {
              id: "WI_Welder_Id",
              name: "Welder ID",
            },
            {
              id: "WI_Welder_Name",
              name: "Welder Name",
            },
          
          ]);
          break;  
      default:
        setHeader([]);
    }
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return tableData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tableData, PageSize]);

  const setChecked = (e, item) => {

    if (e.target.checked) {
      setTemp([...temp, item]);
    } else {
      let arr = temp.filter((value) => value.lm_loccode !== item.lm_loccode);
      setTemp(arr);
    }
  };

  const setRadio = (e, item) => {
    if (e.target.checked) {
      setTemp([item]);
    } else {
      let arr = temp.filter((value) => value.lm_loccode !== item.lm_loccode);
      setTemp(arr);
    }
  };

  useEffect(() => {
    if (temp?.length > 0) setSelectedCard(temp);
  }, [temp]);

  return (
    <>
      <div className="tableWrapperCustomPopupModal mobileTableModal">
        <div className="table-body tableBodyCompressorModal" id="DataTable">
          {data?.length > 0 &&
            currentTableData.map((item, index) => {
              return (
                <div className="outerTabDiv">
                  <div
                    className={
                      "table-row1 mobileTableRow " +
                      (item.show ? "showLess" : "showFull")
                    }
                    key={`row_${index}`}
                    id={`row_${index}`}
                  >
                    <div className="table-cell">
                      {type === "single" ? (
                        <div className="form-check align-text1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            defaultChecked={
                              props.paramvalue.includes(item[props.paramkey])
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setRadio(e, item);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="form-check align-text1">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            value=""
                            defaultChecked={
                              props.locationData?.includes(item.lm_loc)
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setChecked(e, item);
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {header.length > 0 &&
                      header.map((value) => {
                        return (
                          <div className="table-cell">
                            <div className="span-text">{value.name}</div>
                            <div className="align-text1">{item[value.id]}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}

          <PaginationComp
            currentPage={currentPage}
            totalCount={tableData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <br />
        </div>
      </div>
    </>
  );
};

export default CustomPopUpTable;
