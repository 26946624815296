import React from "react";


export default (props) => {

  return (
    <>
    <div className="d-flex align-items-center">
    <span className="action-status-color"  onClick={() => props.handleEditEvent(props.selectedData)}
    >
     


    {props.selectedData.data.wld_TrainingReqNumber}

  </span>
  </div>
   
    </>
  )

};
