import axios from "axios";

let GROOVE_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  GROOVE_API_URL = process.env.REACT_APP_WPS_GROOVE_API_URL;
} else {
  GROOVE_API_URL = process.env.REACT_APP_API_URL + "wps";
}

export async function getAllDetails() {
  const response = await axios
    .get(GROOVE_API_URL + "/WpsDetailsMast")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllWPSNo() {
  const response = await axios
    .get(GROOVE_API_URL + "/WpsDetailsMast/allwpsno")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getGridData(type) {
  const response = await axios
    .get(GROOVE_API_URL + "/WpsHeaderMast/grid/:type")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export const getdetailsById = async (IddetailPK, IddetailSK) => {
  

  let url = `${GROOVE_API_URL}/WpsDetailsMast/getdetailsById`;
  let reqbody = { PK: IddetailPK, SK: IddetailSK };

  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};


export const getdetailsByIdWI = async (IddetailPK, IddetailSK) => {
  

  let url = `${GROOVE_API_URL}/WpsDetailsMast/forWI`;
  let reqbody = { PK: IddetailPK, SK: IddetailSK };

  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};



export const AddNewDetail =  (WPS_Details_Master) => {
  

 let url = `${GROOVE_API_URL}/WpsDetailsMast`;
try {
      const response =  axios.post(url, WPS_Details_Master);

      return response;
  } catch (error) {
      console.error("Error in AddNewDetail:", error);
      return { message: "Unable to add detail", status: false };
  }
};

export const AddNewDetailTubesheet = async (WPS_Details_Master) => {
  

 let url = `${GROOVE_API_URL}/WpsDetailsMast`;
try {
      const response = await axios.post(url, WPS_Details_Master);

      return response;
  } catch (error) {
      console.error("Error in AddNewDetail:", error);
      return { message: "Unable to add detail", status: false };
  }
};

export const Updatedetails = async (WPS_Details_Master) => {
  

  let url = `${GROOVE_API_URL}/WpsDetailsMast/update`;

  const response = await axios
    .post(url, WPS_Details_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteWPS(pkid , skid) {
  

  let url = `${GROOVE_API_URL}/WpsDetailsMast/delete`;
  let body  = {PK:pkid,SK:skid}
  const resp = await axios
    .post(url,body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}
