
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { getCurrentDateTime } from "../../functions/welding-functions";

export async function exportToExcel(fileName, excelData,) {
  let wb = XLSX.utils.book_new();
  let todayDate = getCurrentDateTime("-").substring(0, 10);

  let tableHeader = [{
    A: "Issue Slip No",
    B: "Project No",
    C: "Deviation No",
    D: "Plant No",
    E: "Joint No",
    F: "Welder/ Operator Code",
    G: "Welder/ Operator Name",
    H: "Observer Code",
    I: "Observer Department",
    J: "Observation Date",
    K: "Observation Time (hh:mm)",
    L: "Supervisor Code",
    M: "Supervisor Department",
    N: "Deviation Category",
    O: "Category Description",
    P: "Severity Level",
    Q: "Deviation Text",
    R: "Corrective Action",
    S: "Action Supervisor Code",
    T: "Action Taken Date",
    U: "Action Taken Time (hh:mm)",
    V: "Root Cause Analysis",
    W: "Preventive Action",
    X: "Status",
    Y: "Deviation Status"
  }]

  excelData.forEach(row => {
    if (row.devstatus === "Pending for CAPA Analysis" || row.devstatus === "Pending for Closure") {
      row.capaStatus = "Open"
    }
    else {
      row.capaStatus = "Closed"
    }
    tableHeader.push({
      A: row.slipNumber,
      B: row.projectNumber,
      C: row.deviationNumber,
      D: row.plantNumber,
      E: row.jointNumber,
      F: row.welderCode,
      G: row.welderName,
      H: row.observerCode,
      I: row.observerDept,
      J: row.observationDate,
      K: row.observationTime,
      L: row.supervisorCode,
      M: row.supervisorDept,
      N: row.deviationCategory,
      O: row.deviationCategoryDesc,
      P: row.deviationSeverityLevel,
      Q: row.deviationText,
      R: row.correctiveAction,
      S: row.actionTakenBy,
      T: row.actionTakenDate,
      U: row.actionTimeTaken,
      V: row.rootCauseAnalysis,
      W: row.preventiveAction,
      X: row.capaStatus,
      Y: row.devstatus
    })
  })

  let alldata = [''].concat([''])
    .concat([''])
    .concat([''])
    .concat([''])
    .concat([''])
    .concat([''])
    .concat(tableHeader);

  let ws = XLSX.utils.json_to_sheet(alldata, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "Deviation Report");

  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  const dataInfo1 = {
    titleCell: "E4",
    titleWelderRange: "E4:Y6",
    tbodyHeadRange: 'A8:Y8',
    tbodyRange: `A9:Y${alldata.length}`,
    blankRow: "A7:Y7",
  }

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];
    sheet.row(8).style('wrapText', true);

    sheet.gridLinesVisible(false);

    sheet.freezePanes(0, 6);

    sheet.column("A").width(15);
    sheet.column("B").width(15);
    sheet.column("C").width(15);
    sheet.column("D").width(15);
    sheet.column("E").width(15);
    sheet.column("F").width(15);
    sheet.column("G").width(15);
    sheet.column("H").width(15);
    sheet.column("I").width(15);
    sheet.column("J").width(15);
    sheet.column("K").width(15);
    sheet.column("L").width(15);
    sheet.column("M").width(15);
    sheet.column("N").width(15);
    sheet.column("O").width(15);
    sheet.column("P").width(15);
    sheet.column("Q").width(15);
    sheet.column("R").width(15);
    sheet.column("S").width(15);
    sheet.column("T").width(15);
    sheet.column("U").width(15);
    sheet.column("V").width(15);
    sheet.column("W").width(15);
    sheet.column("X").width(15);
    sheet.column("Y").width(22);

    sheet.cell("B3").value("Godrej & Boyce Mfg. Co. Ltd");
    sheet.cell("I3").value("As on: " + todayDate);
    sheet.cell("F4").value("Welding Deviation Report").style("underline");

    sheet.range(dataInfo1.titleWelderRange).style({
      bold: true,
      underline: true
    })

    sheet.range(dataInfo1.tbodyHeadRange).style({
      bold: true,
      horizontalAlignment: 'center',
      border: "thin",
    })
    sheet.range(dataInfo1.tbodyRange).style({
      border: "thin",
    })

    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  });

}


const s2ab = (s) => {

  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};