import { Table} from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {

  isAlphanumericAllSpecialCharacters
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
export  const  WPS_Thickness_Groove = (({headerObj,error,isReadonly,handleChange,setError}) => {

    return(
        <Table className="text-center">
<thead>
  <tr className="border thead-color ">
    <th className="thickness_title"></th>
    <th colSpan={2} className="thickness_title">
      {headerObj.wld_TRQ_Type === "PWHT"
        ? "With PWHT"
        : "As Welded"}
    </th>
  </tr>
  <tr className="border thead-color ">
    <th className="thickness_title"></th>
    <th className="thickness_title">Min</th>
    <th className="thickness_title">Max</th>
  </tr>
</thead>

<tbody>
  <tr
    className={
      error.WPSH_TRQ_GroovewithoutImpact_Min &&( error.WPSH_TRQ_GroovewithoutImpact_Min.status ||
      error.WPSH_TRQ_GroovewithoutImpact_Max.status)
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={
        error.WPSH_TRQ_GroovewithoutImpact_Min &&( error.WPSH_TRQ_GroovewithoutImpact_Min.status ||
        error.WPSH_TRQ_GroovewithoutImpact_Max.status)
          ? "text-start error-head"
          : "text-start"
      }
    >
      <div>
        <label className="Groove_green">
          Groove without Impact
        </label>
        {error.WPSH_TRQ_GroovewithoutImpact_Min && (error.WPSH_TRQ_GroovewithoutImpact_Min.status ||
          error.WPSH_TRQ_GroovewithoutImpact_Max.status) && (
          <Tooltip
            content={
              error.WPSH_TRQ_GroovewithoutImpact_Min.message ||
              error.WPSH_TRQ_GroovewithoutImpact_Max.message
            }
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td className="Thickness_data">
      <input
        type="text"
        className={
          error.WPSH_TRQ_GroovewithoutImpact_Min &&( error.WPSH_TRQ_GroovewithoutImpact_Min.status)
            ? "error-input"
            : ""
        }
        name={"WPSH_TRQ_GroovewithoutImpact_Min"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_GroovewithoutImpact_Min}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_GroovewithoutImpact_Min: error,
          }));
        }}
      />
    </td>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_GroovewithoutImpact_Max && error.WPSH_TRQ_GroovewithoutImpact_Max.status
            ? "error-input"
            : ""
        }
        name={"WPSH_TRQ_GroovewithoutImpact_Max"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_GroovewithoutImpact_Max}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_GroovewithoutImpact_Max: error,
          }));
        }}
      />
    </td>
  </tr>
  <tr
    className={
      error.WPSH_TRQ_GroovewithImpact_Min && (  error.WPSH_TRQ_GroovewithImpact_Min.status ||
      error.WPSH_TRQ_GroovewithImpact_Max.status)
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={
        error.WPSH_TRQ_GroovewithImpact_Min &&(error.WPSH_TRQ_GroovewithImpact_Min.status ||
        error.WPSH_TRQ_GroovewithImpact_Max.status)
          ? "text-start error-head"
          : "text-start"
      }
    >
      <div>
        Groove with Impact
        {error.WPSH_TRQ_GroovewithImpact_Min && (error.WPSH_TRQ_GroovewithImpact_Min.status ||
          error.WPSH_TRQ_GroovewithImpact_Max.status) && (
          <Tooltip
            content={
              error.WPSH_TRQ_GroovewithImpact_Min.message ||
              error.WPSH_TRQ_GroovewithImpact_Max.message
            }
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_GroovewithImpact_Min && ( error.WPSH_TRQ_GroovewithImpact_Min.status && error.WPSH_TRQ_GroovewithImpact_Min.status)
            ? "error-input"
            : ""
        }
        readOnly={
          isReadonly
        }
        name={"WPSH_TRQ_GroovewithImpact_Min"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_GroovewithImpact_Min}
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_GroovewithImpact_Min: error,
          }));
        }}
      />
    </td>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_GroovewithImpact_Max && error.WPSH_TRQ_GroovewithImpact_Max.status
            ? "error-input"
            : ""
        }
        readOnly={
          isReadonly
        }
        name={"WPSH_TRQ_GroovewithImpact_Max"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_GroovewithImpact_Max}
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_GroovewithImpact_Max: error,
          }));
        }}
      />
    </td>
  </tr>
  <tr
    className={
      error.WPSH_TRQ_FilletwithoutImpact_Min &&(error.WPSH_TRQ_FilletwithoutImpact_Min.status  && (error.WPSH_TRQ_FilletwithoutImpact_Min.status ||
      error.WPSH_TRQ_FilletwithoutImpact_Max.status))
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={
        error.WPSH_TRQ_FilletwithoutImpact_Min &&( error.WPSH_TRQ_FilletwithoutImpact_Min.status && (error.WPSH_TRQ_FilletwithoutImpact_Min.status ||
        error.WPSH_TRQ_FilletwithoutImpact_Max.status))
          ? "text-start error-head"
          : "text-start"
      }
    >
      <div>
        Fillet without Impact
        {error.WPSH_TRQ_FilletwithoutImpact_Min && (error.WPSH_TRQ_FilletwithoutImpact_Min.status ||
          error.WPSH_TRQ_FilletwithoutImpact_Max.status) && (
          <Tooltip
            content={
              error.WPSH_TRQ_FilletwithoutImpact_Min.message ||
              error.WPSH_TRQ_FilletwithoutImpact_Max.message
            }
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_FilletwithoutImpact_Min && error.WPSH_TRQ_FilletwithoutImpact_Min.status
            ? "error-input"
            : ""
        }
        readOnly={
          isReadonly
        }
        name={"WPSH_TRQ_FilletwithoutImpact_Min"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_FilletwithoutImpact_Min}
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_FilletwithoutImpact_Min: error,
          }));
        }}
      />
    </td>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_FilletwithoutImpact_Max &&  error.WPSH_TRQ_FilletwithoutImpact_Max.status
            ? "error-input"
            : ""
        }
        readOnly={
          isReadonly
        }
        name={"WPSH_TRQ_FilletwithoutImpact_Max"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_FilletwithoutImpact_Max}
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_FilletwithoutImpact_Max: error,
          }));
        }}
      />
    </td>
  </tr>
  <tr
    className={
      error.WPSH_TRQ_FilletwithImpact_Min &&(error.WPSH_TRQ_FilletwithImpact_Min.status ||
      error.WPSH_TRQ_FilletwithImpact_Max.status)
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={
        error.WPSH_TRQ_FilletwithImpact_Min && (error.WPSH_TRQ_FilletwithImpact_Min.status ||
        error.WPSH_TRQ_FilletwithImpact_Max.status)
          ? "text-start error-head"
          : "text-start"
      }
    >
      <div>
        Fillet with Impact
        {error.WPSH_TRQ_FilletwithImpact_Min &&(error.WPSH_TRQ_FilletwithImpact_Min.status ||
          error.WPSH_TRQ_FilletwithImpact_Max.status) && (
          <Tooltip
            content={
              error.WPSH_TRQ_FilletwithImpact_Min.message ||
              error.WPSH_TRQ_FilletwithImpact_Max.message
            }
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_FilletwithImpact_Min &&  error.WPSH_TRQ_FilletwithImpact_Min.status
            ? "error-input"
            : ""
        }
        readOnly={
          isReadonly
        }
        name={"WPSH_TRQ_FilletwithImpact_Min"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_FilletwithImpact_Min}
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_FilletwithImpact_Min: error,
          }));
        }}
      />
    </td>
    <td>
      <input
        type="text"
        className={
          error.WPSH_TRQ_FilletwithImpact_Max && error.WPSH_TRQ_FilletwithImpact_Max.status
            ? "error-input"
            : ""
        }
        readOnly={
          isReadonly
        }
        name={"WPSH_TRQ_FilletwithImpact_Max"}
        maxLength={6}
        value={headerObj.WPSH_TRQ_FilletwithImpact_Max}
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumericAllSpecialCharacters(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPSH_TRQ_FilletwithImpact_Max: error,
          }));
        }}
      />
    </td>
  </tr>
</tbody>
</Table>)})