import React, { useState, useEffect, useRef } from "react";
import "../Assests/Css/header.css";
import "../Assests/Css/account.css";
import { getMenuDetails } from "../Services/menu-service";
import Account from "./Account";
import { useLocation } from "react-router-dom";
import MainComponent from "./MainComponent";

const Header = (props) => {
  const childRef = useRef();

  const [Labels, setLabel] = useState({
    title: "Dashboard",
    button: false,
    buttontext: "",
    currentMenu: "DASHBOARD",
    heading: "",
  });


  var location = useLocation().pathname;

  let lastElement;
  var Title;

  const GetNav = () => {
    lastElement = location.replace(/[\W_]/g, " > ");

    lastElement = lastElement.slice(1, lastElement.lastIndexOf(">"));
     lastElement = lastElement.replace("weldingmaster", "Welding Master");
    lastElement = lastElement.replace("wps", "WPS Transaction");
    lastElement = lastElement.replace("maintenance", "Maintenance");
    // lastElement = lastElement.replace("dashboard", "Dashboard");
    lastElement = lastElement.replace("raiseDeviation", "Raise Deviation");
    lastElement = lastElement.replace("groove", "Raise Deviation");
    lastElement = lastElement.replace("PQRTransaction", "PQR Transaction");
    lastElement = lastElement.replace("TubetoTubesheet", "Tube to Tubesheet");

    return lastElement;
  };
  const getTitle = (location) => {
    var innerpage = location.slice(
      location.lastIndexOf("/") + 1,
      location.length
    );
  
    Title = Labels?.title;
    switch (innerpage) {
      case "WCSdetail":
        Title = "WCS Detail";
        break;
      case "/":
        Title = "";
        break;
    }
    return Title;
  };



  const next = async (e) => {

    if (Labels.button.toLowerCase() === "true") await childRef.current.handleAdd();
  };

  useEffect(() => {
    let result1;
    result1 = sessionStorage.getItem("UserAccess");
    if (result1 !== undefined)
      setLabel(getMenuDetails(result1, window.location.pathname));

    if (location.indexOf("unauthorized") !== -1)
      setLabel({
        title: "UnAuthrorized",
        button: false,
        buttontext: "",
        currentMenu: "UnAuthorized",
        heading: "Un Authorized",
      });

 
  }, [useLocation().pathname]);

  return (
    <>
      <div className="top">
        <div class="outertitle">
          <div
            className="title"
            style={{
              fontSize: "24px",
              width: "max-content",
              position: "relative",
              top: "8px",
            }}
          >
            <b>
              {Labels?.heading !== undefined
                ? Labels?.heading
                : Labels?.title === undefined && location === "/"
                ? ""
                : Labels?.title}
            </b>
          </div>
          <div class="btnn">
            <div className="add_btn">
             
              {Labels?.button === "TRUE" && (
                <button class="button button1" onClick={(e) => next(e)}>
                  {" "}
                 
                  <div className="text">
                    <img src="../sidebar/add.png"></img>
                    {Labels?.buttontext}
                    &nbsp;
                  </div>
                </button>
              )}
            </div>
          </div>
          <div className="account1">
            <Account />
          </div>
        </div>
      </div>
      {getTitle(location) !== undefined && (
        <div className={"location"}>
          <div className="ltext">
            {" "}
            Home
      
            {GetNav()}
            {"> "}
            <a>{getTitle(location)}</a>
          </div>
        </div>
      )}
      <MainComponent childRef={childRef}></MainComponent>
    </>
  );
};

export default Header;