import React, { useState } from "react";

import "../Css/groovePQR_pdf.css"
import Groove_pdf_headerPQR from "./Groove_pdf_headerPQR";
import Wld_Consolelog from "../../../utility/CommonComponents/ConsoleLog";



const Groove_pdf_page1PQR = (
  { pdf, setpdf, previewuser, previewapproved ,PQRtype,previewUrl1,previewUrl2},
  props,
  wld
) => {
 
  let isoverlay = pdf[0]?.wld_WPSType === "Groove" ? false : true;
  let WPSProcessDataWidth;
  
Wld_Consolelog("typeeee",pdf[0]?.wld_WPSType, isoverlay )

  Wld_Consolelog("pdf", pdf[1]);
  Wld_Consolelog("pdfffffffffffff", pdf);
  
  function  bindewldprocess(type,index) {
    

    let ArryMap = [];
 
     pdf[index] && pdf[index][type]?.forEach((element, index1) => {
      
       ArryMap?.push( element);
    });

    
 

  
    Wld_Consolelog('lelelele', pdf, pdf.length)
    Wld_Consolelog("ArrayTomap11", ArryMap[0], ArryMap[1], ArryMap[2], ArryMap[3]);

    const rows = [];
    rows.push(
      <tr key={1} style={{background:"none"}} >
        <td
          className="process1"
         
        >
          {ArryMap[0]}
        </td>
        <td
          className="process2"
        >
          {ArryMap[1]}
        </td>
        <td
          className="process3"
          
        >
          {ArryMap[2]}
        </td>
        {/* <td
          className="process4"
         
        >
          {ArryMap[3]}
        </td> */}
         </tr>
    );
    Wld_Consolelog("Rowwwww", rows);
    Wld_Consolelog("Arryryryryryry", ArryMap[0]);
    return rows;
  }



  const [detailObj, setdetailObj] = useState([]);
  let cols = 4,
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class,
    col4Class;
    // col1botmclass;

 
  const styles = {
    container: {
      display: "flex",
    },
    group: {
      display: "flex",
    },
    title3: {
      width: "50%",
    },
  };


      cols = pdf[2] !== undefined ? pdf[3] !== undefined ? 3 : 2 : 1
      colwidth = pdf[2] !== undefined ? (pdf[3] !== undefined ? "24%" : "90%"): "70%"
      Wld_Consolelog("PDFFDF",pdf[2])
      col1Class =  "left7-td-noleft"
      col2Class =   pdf[2] !== undefined  ? (pdf[3] !== undefined ? "left7-td" : "left7-td-noright") : "tdhidden"
      col3Class =    pdf[3] !== undefined ?  "left7-td-noright1" : "tdhidden"
      col4Class =    pdf[4] !== undefined ?  "left7-td-noright2" : "tdhidden"

  return (
    <div className="MainPDF" style={{position:"relative", top:"15px"}}>
    <Groove_pdf_headerPQR pdf={pdf}></Groove_pdf_headerPQR>
     
    <><div style={styles.group}>
  <div className="PQRbasemetal">WELDING PROCESSES</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>


        <tr className="PQRtrhide PQR_noleft ">
          <th className=" PQR_border PQRfillerMetalth" >
            Welding Process & Type
          </th>
          {isoverlay ? 
          <td className={col4Class} style={{ width: { colwidth }}}>
            {pdf[4] && pdf[4]?.PQR_Process} {pdf[4] && " & "}{" "}
            {pdf[4] && pdf[4]?.PQR_Type}{pdf[4] && " & "}{pdf[4] && pdf[4]?.PQR_Layer}
          </td> : 
          <td className={col4Class} style={{ width: { colwidth }}}>
            {pdf[4] && pdf[4]?.PQR_Process} {pdf[4] && " & "}{" "}
            {pdf[4] && pdf[4]?.PQR_Type}
          </td>}
        </tr>

      </tbody>
    </table></div></>
                  {/* --------------------------------table 6------------------------------------ */}
                  <><div style={styles.group}>
  <div className="PQRbasemetal">FILLER METALS (QW-404)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>
        
        <tr className="PQRtrhide PQR_Toprt">
          <th className="PQRfillerMetalth PQR_noBotm">
            SFA specification
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_SFA}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Classification
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_Classification}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
            
          >
           Filler Metal F No/ Weld Metal A No
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_FNo}{"/"}{pdf[4] && pdf[4]?.PQRD_FM_Ano}

          </td>
        </tr>
       

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Filler metal nominal composition
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_Nominal}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Filler metal trade name
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_TradeName}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Filler metal product form
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_ProductForm}

          </td>
        </tr>


        <tr className="PQRtrhide PQR_right">
          <th  className="PQRfillerMetalth PQR_rt_lft" > Filler metal size <div className="PQRrcorner"> mm </div></th>
       
          <td className={col4Class} style={{ width: { colwidth } }}>
            {bindewldprocess("PQRD_FM_FillerMetalSize",4)}
          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Deposited thickness     <div className="PQRrcorner"> mm </div>
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_DepositedThickness}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Maximum pass thickness     <div className="PQRrcorner"> mm </div>
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_MaxPassThickness}

          </td>
        </tr>
        {isoverlay ? <>
        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Weld deposit chemistry
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_WeldDepositChemistry}

          </td>
        </tr> </> : " "}

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux AWS specification & classification
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_FluxAWS}

          </td>
        </tr>

       
        <tr className="PQRtrhide PQR_right" >
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux trade name
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_FluxTradeName}

          </td>
        </tr>
        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux type
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_FluxType}

          </td>
        </tr>
        <tr className="PQRtrhide PQR_right" >
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux from recrushed slag
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_FluxRecrushed}

          </td>
        </tr>
        {isoverlay ? 
        <><tr className="PQRtrhide  PQR_right">
                  <th
                    className="PQRfillerMetalth PQR_rt_lft"

                  >
                    Supplemental filler metal & Insert
                  </th>
                  <td className={col4Class} style={{ width: { colwidth } }}>
                    {pdf[4] && pdf[4]?.PQRD_FM_Supplement}

                  </td>
                </tr><tr className="PQRtrhide PQR_Botmrt">
                    <th
                      className="PQRfillerMetalth PQR_noTop">
                      Filler metal others
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_FM_FillerMetalOthers}

                    </td>
                  </tr></>
        :
        <tr className="PQRtrhide  PQR_Botmrt" >
          <th
            className="PQRfillerMetalth PQR_noTop"
           
          >
             Supplemental filler metal & Insert
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_Supplement}

          </td>
        </tr>}

      </tbody>
    </table></div></>

  {/* --------------------------------table 7------------------------------------ */}
   <><div style={styles.group}>
  <div className="PQRbasemetal">POSITION(WQ-405)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>
        <tr className="PQRtrhide PQR_Toprt">
          <th className="PQRfillerMetalth PQR_noBotm" >
            Position of groove
          </th>
          
          <td className={col4Class}>
            {bindewldprocess("PQRD_Position_PositionofGroove",4)}
          </td>
        </tr>


        <tr className="PQRtrhide PQR_Botmrt" >
          <th className="PQRfillerMetalth PQR_noTop"> Weld progression</th>
          
          <td className={col4Class}>
            {bindewldprocess("PQRD_Position_WeldProgression",4)}
          </td>
        </tr>
      </tbody>
    </table></div></>

 {/* --------------------------------table 8------------------------------------ */}
    <><div style={styles.group}>
  <div className="PQRbasemetal">PREHEAT (QW-406)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>

        <tr className="PQRtrhide PQR_Toprt" >
          <th
            className="PQRfillerMetalth PQR_noBotm"
            
          >
            Minimum preheat temperature  <div className="PQRrcorner">  °C </div>
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_Preheat_MinimumPreheatTemp}

          </td>
        </tr>
        <tr className="PQRtrhide PQR_Botmrt">
          <th
            className="PQRfillerMetalth PQR_noTop" >
            Maximum interpass temperature <div className="PQRrcorner">  °C </div>
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_Preheat_MaximumInterpassTemp}

          </td>
        </tr>




      </tbody>
    </table></div></>
                {/* --------------------------------table 9------------------------------------ */}
                <><div style={styles.group}>
  <div className="PQRbasemetal">GAS(QW-408)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>
      { isoverlay ? 
      <><tr className="PQRtrhide PQR_noleft PQR_Botm">
      <th className="PQRfillerMetalth PQR_noBotm PQR_border"  style={{width: "27%" }}>
            <div className="PQRgroup">
              <div className="PQRlcorner">Sheilding:Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div>
            </div>
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_Gas_ShieldingType}{" & "} {pdf[4] && pdf[4]?.PQRD_Gas_ShieldingFlowRate}</td>
        </tr> </>
       :
     <> <tr className="PQRtrhide PQR_Toprt">
      <th className="PQRfillerMetalth PQR_noBotm "  style={{width: "27%" }}>
            <div className="PQRgroup">
              <div className="PQRlcorner">Sheilding:Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div>
            </div>
          </th>
          <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_Gas_ShieldingType}{" & "} {pdf[4] && pdf[4]?.PQRD_Gas_ShieldingFlowRate}</td>
        </tr> 
     
      <tr className="WPStrhide ">
                  <th className="PQRfillerMetalth PQR_rt_lft" style={{ width: "20%" }}>
                    <div className="PQRgroup">
                      <div className="PQRlcorner">Trailing <div className="PQRrcorner" style={{ paddingLeft: "8px", float: "right" }}>:Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div></div>

                    </div>
                  </th>
                  <td className={col4Class} style={{ width: { colwidth } }}>
                    {pdf[4] && pdf[4]?.PQRD_Gas_TrailingType}{" & "} {pdf[4] && pdf[4]?.PQRD_Gas_TrailingFlowRate}</td>
                </tr><tr className="WPStrhide " style={{ borderBottom: "1px solid black" }}>
                    <th className="PQRfillerMetalth PQR_rt_lft" style={{ width: "27%", borderBottom: "1px solid black" }}>
                      <div className="PQRgroup">
                        <div className="PQRlcorner">Backing{" "} <div className="PQRrcorner" style={{ paddingLeft: "6px", float: "right" }}> :Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div></div>

                      </div>
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Gas_BackingType}{" & "}   {pdf[4] && pdf[4]?.PQRD_Gas_BackingFlowRate}</td>
                  </tr></> 
        }
      

      
      </tbody>
    </table></div></>
   {/* --------------------------------table 9------------------------------------ */}
                <><div style={styles.group}>
  <div className="PQRbasemetal">ELECTRICAL(QW-409)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>
        {!isoverlay ? (<><tr className="PQRtrhide PQR_Toprt" >
          <th
            className="PQRfillerMetalth PQR_noBotm"
            style={{ width: "27%" }} >
            Filler metal size <div className="PQRrcorner"> mm</div>
          </th>
          <td className={col4Class}>
            {bindewldprocess("PQRD_Electrical_FillerMetalSize",4)}
          </td>
        </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Amperes <div className="PQRrcorner"> Amps</div></th>
           
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_Amperes",4)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Volts <div className="PQRrcorner"> Volts </div></th>
       
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_Volts",4)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Travel speed <div className="PQRrcorner"> mm/min </div></th>
       
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_TravelSpeed",4)}
            </td>
          </tr>
          <tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Minimum bead Length (SMAW) <div className="PQRrcorner"> mm</div></th>
       
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_minBeadLength",4)}
            </td>
          </tr>
          <tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Minimum bead Length (SMAW) <div className="PQRrcorner"> mm</div></th>
       
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_ArcTransferMode",4)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Maximum heat input <div className="PQRrcorner"> KJ/mm </div> </th>
     
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_MaxHeatInput",4)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Tungsten type &  size</th>
           
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_TungstenType",4)}
            </td>   
                  </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Current/polarity</th>
          
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_CurrentPolarity",4)}
            </td>
          </tr><tr className="PQRtrhide PQR_Botmrt" >
            <th className="PQRthhide2 PQR_noTop" >DC pulsing current</th>

            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",4)}
            </td>
          </tr></>) : (<><tr className="PQRtrhide PQR_Toprt" >
            <th
              className="PQRfillerMetalth PQR_noBotm" >
              Filler metal size   <div className="PQRrcorner"> mm </div>
            </th>
            <td className={col4Class}>
              {bindewldprocess("PQRD_Electrical_FillerMetalSize",4)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">
                Amperes      <div className="PQRrcorner"> Amps </div></th>
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_Amperes",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">
                Volts         <div className="PQRrcorner"> Volts</div></th>
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_Volts",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Minimum bead Length (SMAW) <div className="PQRrcorner"> mm </div></th>

              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_TravelSpeed",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Maximum heat input   <div className="PQRrcorner"> KJ/mm </div> </th>
              
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_MaxHeatInput",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Tungsten type &  size</th>
        
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_TungstenType",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Current/polarity</th>
           
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_CurrentPolarity",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">DC pulsing current</th>
              
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Wire Feed Type</th>
              
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedType",4)}
              </td>
            </tr><tr className="PQRtrhide PQR_Botmrt" >
              <th className="PQRthhide2 PQR_noTop">Wire Feed Speed  <div className="PQRrcorner">  m/min </div></th>
              
              <td className={col4Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedSpeed",4)}
              </td>
            </tr></>)}
      </tbody>
    </table></div></>


   {/* --------------------------------table 10------------------------------------ */}
                <><div style={styles.group}>
                  <div className="PQRbasemetal">TECHNIQUE(QW-410)</div>
                </div>

                  <div style={styles.container}>
                    <table id="table" className="PQRTable2">
                      <tbody>
                      {!isoverlay ? (
                        <><tr className="PQRtrhide PQR_Toprt">
                  <th
                    className="PQRfillerMetalth PQR_noBotm"> String or weave
                  </th>
                  <td className={col4Class} style={{ width: { colwidth } }}>
                    {pdf[4] && pdf[4]?.PQRD_Technique_StringOrWeave}   </td>
                </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Orifice/gas cup size
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Orifice_GasCupSize}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> C.T.W.D <div className="PQRrcorner"> mm </div>
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_CTWD}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Oscillation
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Oscillation}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Multi/Single electrode
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Multi_SingleElectrode}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Electrode angle
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_ElectrodeAngle}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"
                      style={{ width: "27%" }}>
                      Multi/Single pass per side
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_SinglePassPerSide}   </td>
                  </tr><tr className="PQRtrhide PQR_right">
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Multiple or single layer
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Multiple_SingleLayer}   </td>
                  </tr><tr className="PQRtrhide PQR_right">
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Peening
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Peening}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th className="PQRfillerMetalth PQR_rt_lft" >
                      Initial/interpass cleaning
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_InitialInterpassCleaning}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Back gouging method
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_BackGaugingMethod}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Groove preparation
                    </th>
                    
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_GroovePreparation}   </td>
                   
                  </tr>
                  <tr className="PQRtrhide PQR_Botmrt">
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Technique Others
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Technique_Other}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_Botmrt">
                    <th
                      className="PQRfillerMetalth PQR_noTop">Technique Others  
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                    {pdf[4] && pdf[4]?.PQRD_Technique_Technique_Other}   </td>
                  </tr></>
                        
                        ) : (<>
                  <tr className="PQRtrhide PQR_Toprt">
                  <th
                    className="PQRfillerMetalth PQR_noBotm"
                    style={{ width: "27%" }}>
                    Oscillation
                  </th>
                  <td className={col4Class} style={{ width: { colwidth } }}>
                    {pdf[4] && pdf[4]?.PQRD_Technique_Oscillation}   </td>
                </tr>
                  {/* -------------------------------------- */}
                  <tr className="PQRtrhide PQR_right" >
                  <th
                    className="PQRfillerMetalth PQR_rt_lft">
                    No. of electrodes on same puddle
                  </th>
                  <td className={col4Class} style={{ width: { colwidth } }}>
                    {pdf[4] && pdf[4]?.PQRD_Technique_ElectrodesSamePuddle}   </td>
                </tr>
        
                  <tr className="PQRtrhide PQR_right">
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Multiple or single layer
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Multiple_SingleLayer}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">Supplemental device
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_SupplimentalDevice}   </td>
                  </tr>
                 
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Initial/interpass cleaning
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_InitialInterpassCleaning}   </td>
                  </tr>
               
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Overlapping
                    </th>
                    <td className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_Overlaping}   </td>
                  </tr>
              
                  <tr className="PQRtrhide PQR_Botmrt" >
                    <th
                      className="PQRfillerMetalth PQR_noTop"> C.T.W.D <div className="PQRrcorner"> mm </div>
                    </th>
                    <td  className={col4Class} style={{ width: { colwidth } }}>
                      {pdf[4] && pdf[4]?.PQRD_Technique_CTWD}   </td>
                  </tr></>)}





                      </tbody>
                    </table>
                    </div>
                    <div id="footer" style={{position:"fixed",top:"2040px"}} >
        <table className="WPSfooter">
          <tr>
            <td>Page 2 of 3</td>
          </tr>
        </table>
      </div>
      <div style={{height:"260px"}}></div>
                  </>
              </div>
              );
              };
              export default Groove_pdf_page1PQR;