import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";

import SuccessAlertModal from "../Modals/successAlertModal";

import { useLocation, useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";

import { handleExport1 } from "../utility/GlobalExport";
import {  Row, Col, Container } from "react-bootstrap";
import Export from "../../icons/Export.svg";

import {

  getGridHeaderData,

  UpdateRecord,
} from "../../Services/wpq_groove-services";


import GlobalColumnDefinations,{ getHeader_FieldName } from "../utility/AgGridDefination";

import "react-toastify/dist/ReactToastify.css";

import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";

import BarLoader from "react-spinners/BarLoader";

import {
  ImageUploadRenderer,
  QualificationDueInRenderer,
} from "../utility/ImageUploadRenderer";
import axios from "axios";
import Pdf_DownloadWPQ from "./PDF/Pdf_DownloadWPQ";
import { getPresignedURL } from "../../Services/Welder-services";
import ActionRendererWpq from "../utility/ActionRendererWpq";
import { GridLoader } from "react-spinners";

const WPQGroove_Overlay = forwardRef((props, ref) => {

  const [rowData, setRowData] = useState({});

  const [filteredResult, setFilteredResult] = useState([]);


  const [columnDefs, setColumnDefs] = useState([]);
  const [Groove, setGroove] = useState(null);


  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");


  const [reload, setReload] = useState(false);

  const [excelData1, setExcelData] = useState([]);



  const [profileRename, setProfileRename] = useState("");

  const [Dateresult, setDateResult] = useState(''); // State to hold the result


  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();


  let folderwpq = "WPQ_Groove"


  let userprofile = sessionStorage.getItem("UserProfile");

  const [WPStype, setWPStype] = useState(props.type);

  const [userObj, setuserObj] = useState("");



  const [loading, setLoading] = useState(true);

  const [Imgloading, setImgLoading] = useState(true);

  const childRef = useRef();

  const year = new Date().getFullYear()
  const[selectedYear,setSelectedYear] = useState(year) 

  
  let  Band = sessionStorage.getItem("Band");

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "WPSH_Status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WPSCellRenderer",
      sort:"desc",
      minWidth:200

      
    },
    
    {
      headerName: "Welder ID",
      field: "WPQ_WelderId",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Welder Name",
      field: "WPQ_WelderName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Welding Date",
      field: "WPQ_Date",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "WPQ Record No.",
      field: "WPQ_RecordNo",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },

    {
      headerName: "Process",
      field: "WPSH_WPQ_Process",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      // floatingFilter: false,
      // suppressFilter: true,
    },
  ];


  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchGroove = async () => {
    
    let result = await getGridHeaderData(
      WPStype,
      sessionStorage.getItem("Band"),
      sessionStorage.getItem("User"),
      selectedYear
    );

    setGroove(result);
    setLoading(false);
    setFilteredResult(result);
  };

  const fetchColumnDefns =  (profileOriginal) => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({
      headerName: "Qualification Due In",
      field: "wpq_QualificationDueIn",
      cellRenderer: QualificationDueInRenderer1,
      minWidth: 200,
    });
    result.push({
      headerName: "WPQ Documents",
      field: "WPQ_DocImageOriginal",
      cellRenderer: ImageUploadRenderer1,
      minWidth: 250,
    });
    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      minWidth: 250,
      suppressMenu: true,
      sortable:false,
    });
    setColumnDefs(result);
  };

  useEffect(() => {
    fetchGroove();
    fetchColumnDefns();
  }, [location.key,selectedYear]);

  function handleClick() {
    if (WPStype === "Groove") {
      navigate("/wpq/Groove/Groovetrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    } else if (WPStype === "Overlay") {
      navigate("/wpq/Overlay/Overlaytrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    } else if (WPStype === "Tubesheet") {
      navigate("/wpq/TubetoTubesheet/TubetoTubesheettrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    }
  }

  function handleClickedit(row) {
    

    if (row.WPSH_Status === "Approved") {
      row.WPS_Change_No = String(Number(row.WPS_Change_No) + 1);
    }
    if (WPStype === "Groove") {
      navigate("/wpq/Groove/Groovetrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    } else if (WPStype === "Overlay") {
      navigate("/wpq/Overlay/Overlaytrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    } else if (WPStype === "Tubesheet") {
      navigate("/wpq/TubetoTubesheet/TubetoTubesheettrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    }
  }

  function handleClickTemp(row) {
    navigate("/wpq/Groove/Groovetrans", {
      state: { data: row, action: "Temp", WPStype: WPStype },
    });
  }
  useImperativeHandle(ref, () => ({
    handleAdd: () => {
      handleClick();
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Groove);
  };


  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setGroove",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);
  };

  const handleGlobalExport = () => {
    if (WPStype === "Groove") {
      let filename = "WpqGrooveTransaction";
       handleExport1(filteredResult, filename, columnDefs);
    } else if (WPStype === "Overlay") {
      let filename = "WpqOverlayTransaction";
       handleExport1(filteredResult, filename, columnDefs);
    } else {
      let filename = "WpqTubesheetTransaction";
       handleExport1(filteredResult, filename, columnDefs);
    }
  };

  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
  };

  const handlepdf = (row) => {
    if(row?.WPSH_Status === "Approved"){
    setAction("pdf");
    setRowData(row);
    setShowForm(true);
    setuserObj(userprofile);
    }else{
      alert("Cannot Print PDF.Please Approve the Record")
    }
  
  };


  const actionRenderer = (params) => {
    return (
      <ActionRendererWpq
        selectedData={params}
        handleEditEvent={(params) => handleClickedit(params.data)}
        // handleCopyEvent={(params) => handleClickcopy(params.data)}
        handleTempEvent={(params) => handleClickTemp(params.data)}
        handlePdfEvent={(params) => handlepdf(params.data)}
      />
    );
  };

  const handleimage = (row) => {
    setRowData(row);
    setShowForm(true);
  };

  const QualificationDueInRenderer1 =  (params) => {
    const [selectedDate, setSelectedDate] = useState(params.data.WPQ_Date);
    const [futureDate, setFutureDate] = useState(null);
    const [pDate, setpDate] = useState(null);
    const [months, setMonths] = useState(0);
    const [days, setDays] = useState(0);


    const [result, setResult] = useState(''); 

    const handleResult = (resultValue) => {
      setResult(resultValue); 
    };


    setDateResult(result)

    useEffect(() => {
      handleResult(result);
    }, [result, params.handleResult]);
    return (
      <>
        <QualificationDueInRenderer
          handleResult={handleResult} 
          selectedData={params}
          setFutureDate={setFutureDate}
          futureDate={futureDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          pDate={pDate}
          setpDate={setpDate}
          months={months}
          setMonths={setMonths}
          days={days}
          setDays={setDays}
        />
      </>
    );
  };

  const ImageUploadRenderer1 = (params) => {
    

    const [profileOg, setProfileOg] = useState("");
    const [ImageObjectURL, setImageObjectURL] = useState("");
    const [PreviewUrl, setPreviewUrl] = useState([]);
    const [PreUrl, setPreUrl] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [profile, setProfile] = useState("");
    const [progress, setProgress] = useState(0);
    const [deleteCounter, setDeleteCounter] = useState(0);
    const [downloadloading, setdownloadLoading] = useState(false);

    //////////////////// ADD FUNCTION ///////////////////////////////////////////

    const handleFile = async (file, row, data) => {
      

      let res;
      const maxSize = 40 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. upto 40Mb is Allowed");
        file = null;
        return;
      }
      

      let uploadextension = file.name.split(".")


      if (uploadextension[1] !== "pdf" ) {
        alert("Please Upload PDF file and dot(.) is not allowed in between pdf name , only .pdf extension is allowed");
        file = null;
        return;
      }
      setProfileOg(file);
      let abc = new File([file], `${row.WPQ_WelderId}_${row.WPQ_RecordNo}.pdf`);

      setProfile(abc);

      const objectURL = URL.createObjectURL(abc);
      setImageObjectURL(objectURL);

      setRowData(row);

      row.WPQ_DocImage = abc.name;
      row.WPQ_DocImageOriginal = file.name;

      setProfileRename(row.WPQ_DocImage);

      if (row.WPQ_DocImageUrl === "") {
        let startByte = 0;
        while (startByte < file.size) {
          const endByte = Math.min(startByte + chunkSize, file.size);
          await add(row, abc, res, startByte, endByte);
          startByte = endByte;
        }
        res = await UpdateRecord(row);
        setProgress(0);
        alert("File upload complete!");
      }
    };
    //////////////////// END //////////////////////////////////////////////////////////

    //////////////////// DOWNLOAD FUNCTION ///////////////////////////////////////////

    const handleDownload = async (row, data) => {
      
      const result = await getPresignedURL(folderwpq,row.WPQ_DocImage);
      const geturl = result.url;
      setPreviewUrl(geturl);

      setdownloadLoading(true);
      fetch(geturl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.download = row.WPQ_DocImageOriginal;

          anchor.click();
          window.URL.revokeObjectURL(url);

          setdownloadLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
        });
      // setDeleteCounter(deleteCounter + 1);
    };
    //////////////////// END /////////////////////////////////////////////

    ///////////////////// DELETE FUNCTION ///////////////////////////////////////////
    const handleDelete = async (row, abc, res) => {
      
      row.WPQ_DocImageOriginal = "";
      row.WPQ_DocImageUrl = "";
      row.WPQ_DocImage = "";
      row.WPQ_DocImageData.pdfsize = "";
      row.WPQ_DocImageData.pdfname = "";
      row.WPQ_DocImageData.pdftype = "";
      res = await UpdateRecord(row);
      setDeleteCounter(deleteCounter + 1);
    };
    ///////////////////// END ////////////////////////////////////////////////////////

    const chunkSize = 10000 * 10000;
    let uploadedBytes = 0;

    const add = async (row, abc, res, start, end) => {
      const chunk = profile.slice(start, end);
      const formData = new FormData();
      formData.append("chunk", chunk);
      formData.append("filename", profile.name);

      const result = await getPresignedURL(folderwpq,row.WPQ_DocImage);
      setPreUrl(result);
      row.WPQ_DocImageUrl = result.url;
      const posturl = result.url;
      setPreviewUrl(posturl);

      try {
        await axios.put(posturl, abc, {
          onUploadProgress: (progressEvent) => {
            uploadedBytes += progressEvent.loaded;
            const completed = Math.min(
              100,
              Math.round((uploadedBytes / end) * 100)
            );
            setProgress(completed);
          },
        });
      } catch (error) {
        console.error("Error uploading chunk:", error);
        alert("File upload failed.");
        return;
      }
    };

    return (
      <>
        <ImageUploadRenderer
          Imgloading={Imgloading}
          downloadloading={downloadloading}
          setdownloadLoading={setdownloadLoading}
          key={deleteCounter}
          imageData={imageData}
          progress={progress}
          PreviewUrl={params.data.WPQ_DocImageUrl}
          profileOg={params.data.WPQ_DocImageOriginal}
          id={`fileUpload_${params.data.SK}`}
          name="fileInput"
          type="file"
          accept="application/pdf"
          ref={fileInputRef}
          selectedData={params}
          handleFile={(e, params) =>
            handleFile(e.target.files[0], params.data, imageData)
          }
          handleDownload={(params) => handleDownload(params.data, imageData)}
          handleDelete={(params) => handleDelete(params.data, imageData)}
          handleimage={(params) => handleimage(params.data)}
        />
      </>
    );
  };

  
  const handleYearChange = (e) =>{

     setSelectedYear(e.value)
   }

  return (
    <>
    {loading && (
      <div className="loader-overlay">
        <GridLoader size={10} className="pageloader" />
      </div>
    )}
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          WPQ Transaction list
        </Col>

        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for " +
                WPStype +
                " WelderId, Welder Name ,Record No. , Revision No."
              }
              // filterFunc={handleGlobalSearch}
              withYear={true}
              filterFunc={handleGlobalSearch}
              YearHandleChange={handleYearChange}
              YearSelected={selectedYear} 
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>


      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
      />

      {showForm && (
        <Pdf_DownloadWPQ
          WPQtype = {WPStype}
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          userObj={userObj}
          allRecs={Groove}
          backdrop="static"
          Band={Band}
        />
      )}
      <Toaster />
    </Container>
    </>
  );
});

export default WPQGroove_Overlay;
