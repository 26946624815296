
import React, { useEffect, useState } from "react";
import { getPresignedURL } from "../../../../Services/Welder-services";
import "../Css/WPQgroove_pdf.css"
import "../Css/WPQTTS.css"
import Groove_pdf_headerWPQ from "./Groove_pdf_headerWPQ";
import PDFBaseMetalTable from "./PDFBaseMetalTable";




const WPQTubeToTubesheet_pdf_page1 = (
  {
    pdf,
    setpdf,
    PreviewUrl,
    previewUrl1,
    previewUrl2,
    previewuser,
    previewapproved,
  },
  props,
  wld
) => {


  let folderwpq = "WPQ_Groove";

  const [previewUrl3, setPreviewUrl3] = useState();

  const fetchimages3 = async () => {
    try {
      if (pdf[0].Pdf_images[2].tube_Weld_Joint[0] !== "") {
        

        const result = await getPresignedURL(
          folderwpq,
          pdf[0].Pdf_images[2].tube_Weld_Joint[0]
        );

        const geturl = result.url;
        setPreviewUrl3(geturl);

  
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    fetchimages3();
  }, [pdf]);




  let 
    col1Class

 




  col1Class = "pdfLeftBorder aligncenter";


  function FillMetals_Processes() {
  let rows = [];

    for (let i = 1; i <= 3; i++) {
      if (pdf[i] != undefined) {
        rows.push(
          <tr>
            <th
             
              className="pdfLeftBorder pdfRightBorder pdfBorderBottom pdfTopBorder"
              style={{width:"20%" , alignItems:"center"}}
              
            >
              {pdf[i]?.WPQ_Process}
            </th>
            <td className="pdfBorderBottom" style={{textAlign:"center"}}>{pdf[i]?.WPSD_FM_SFA}</td>

            <td className="pdfBorderBottom" style={{textAlign:"center"}}>
              {pdf[i]?.WPSD_FM_Classification === undefined ||
              pdf[i]?.WPSD_FM_Classification === ""
                ? "--"
                : pdf[i]?.WPSD_FM_Classification}
            </td>

            <td className="pdfBorderBottom" style={{textAlign:"center"}}>{pdf[i]?.WPSD_FM_FNo}</td>
            <td className="pdfBorderBottom" style={{textAlign:"center"}}>{pdf[i]?.WPSD_FM_Ano}</td>
            <td className="pdfBorderBottom pdfRightBorder" style={{textAlign:"center"}}>
              {pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName === "" ||
              pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName === undefined
                ? "--"
                : pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName}
            </td>
          </tr>
         
        );
      }
    }
    return <>{rows}</>;
  }


  return (
    <div className="MainPDF">
        <div className="WPQflexb">
        <div className="WPQheaderRow" style={{height:"85px"}}>
     <Groove_pdf_headerWPQ pdf={pdf} from="WPQ" Page="1"></Groove_pdf_headerWPQ>
     </div>
     <div className="WPQContent" style={{height:"480px",marginTop:"24px"}}>
      <PDFBaseMetalTable  pdf={pdf} from="WPQTTS"></PDFBaseMetalTable>
     
      {/* ---------------------------TESTS------------------------------------------- */}
     
        <table
          id="table"
          className="WPQTTStable"  >
             <tr style={{height:"15px"}}>
                        <td style={{fontSize:"9px"}} className="WPQbasemetal">JOINT DETAIL
                        </td>
                        <td className="pdfwpqBorderLeftRighTopRemoved " >
                        <span className="JointDetailSpan WPQbasemetal" style={{position:"relative", right:"12px"}}> {" "} {"Actual Values"}</span>
                        <span className="JointDetailSpan WPQbasemetal" >{" "}  {"Range Qualified"}</span>
                        </td>
                        <td className="WPQbasemetal  ">
                            
                        </td>
                        <td className="pdfwpqBorderLeftRighTopRemoved " >
                        
                        <span className="JointDetailSpan WPQbasemetal" style={{position:"relative", right:"8px"}}> {" "} {"Actual Values"}</span>
                        <span className="JointDetailSpan WPQbasemetal" >{" "}  {"Range Qualified"}</span>
                        </td>
                    </tr>
                    </table>
               <table id="table" className="pdfwpqTable" >    
            <tr >
              <th className="pdfTopBorder pdfLeftBorder pdffont"
              style={{borderTop:"1px solid black", borderLeft:"1px solid black", width:"110px"}}
              >
                Joint Type
              </th>
              <td  className="pdfLeftBorder pdfTopBorder pdfTopBorder pdffont" style={{borderTop:"1px solid black", textAlign:"center"}}>
            {" "}  {pdf[0]?.WPQ_Joint_Type[1]?.Range_Qualified?.[0] || "--"}
              </td>
              <th
                className="pdfTopBorder pdfLeftBorder pdffont"
                style={{borderTop:"1px solid black",borderLeft:"1px solid black", width:"110px"}} 
              >
                Hole Id <div className="WPQrcorner"> mm </div>
              </th>
              <td className="pdfLeftBorder pdfTopBorder pdfRightBorder pdffont" style={{borderTop:"1px solid black"}}>
                <div style={{display:"flex"}}>
                <span style={{width:"85px"}}className="JointDetailSpan pdffont" >
                  {" "}
                  {pdf[0]?.WPQ_Hole_Id[0]?.Actual_Value?.[0] || "--"}
                </span>
                <span style={{width:"85px"}}className="JointDetailSpan pdffont" >
                  {" "}
                  {pdf[0]?.WPQ_Hole_Id[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
            </tr>
            <tr>
              <th style={{borderLeft:"1px solid black"}}
              className="pdfLeftBorder PdfRightBorder pdffont"
              >
                Tube Pitch
                <div className="WPQrcorner"> mm </div>
              </th>
              <td style={{borderLeft:"1px solid black"}} className="pdfLeftBorder pdffont ">
              <div style={{display:"flex"}}>
              <span className="JointDetailSpan pdffont">
                  {" "}
                  {pdf[0]?.WPQ_Tube_Pitch[0]?.Actual_Value?.[0] || "--"}
                </span>
                <span className="JointDetailSpan pdffont">
                  {" "}
                  {pdf[0]?.WPQ_Tube_Pitch[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
              <th style={{borderLeft:"1px solid black"}}
               className="pdfLeftBorder PdfRightBorder pdffont"
              >
                Groove Depth
                <div className="WPQrcorner"> mm </div>
              </th>
              <td style={{borderLeft:"1px solid black"}} className=" pdfRightBorder">
              <div style={{display:"flex"}}>
              <span style={{width:"85px"}} className="JointDetailSpan pdffont">
                  {pdf[0]?.WPQ_Groove_Depth[0]?.Actual_Value?.[0] || "--"}
                </span>
                <span style={{width:"85px"}}className="JointDetailSpan pdffont" >
                  {pdf[0]?.WPQ_Groove_Depth[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
            </tr>
            <tr style={{ borderBottom: "0" }}>
              <th
              className="pdfLeftBorder pdffont" style={{borderLeft:"1px solid black"}}
              >
                Tube Projection
                <div className="WPQrcorner"> mm </div>
              </th>
              <td className="pdfLeftBorder pdffont">
              <div style={{display:"flex"}}>
              <span className="JointDetailSpan">
                               {pdf[0]?.WPQ_Tube_Projection[0]?.Actual_Value?.[0] || "--"}
                </span>
                <span className="JointDetailSpan">
                               {pdf[0]?.WPQ_Tube_Projection[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
              <th
               className="pdfLeftBorder pdffont" style={{width:"30%",borderLeft:"1px solid black"}}
              >
                Width of the ligament
                <div className="WPQrcorner"> mm </div>
              </th>
              <td className="pdfLeftBorder pdfRightBorder pdffont">
              <div style={{display:"flex"}}>
              <span className="JointDetailSpan"  style={{width:"85px"}}>
                               {pdf[0]?.WPQ_Width_Ligament[0]?.Actual_Value?.[0] || "--"}
                </span>
                <span className="JointDetailSpan" style={{width:"85px"}}>
                               {pdf[0]?.WPQ_Width_Ligament[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
            </tr>
            <tr style={{ borderBottom: "0" }}>
              <th
             className="pdfLeftBorder pdffont" style={{borderLeft:"1px solid black"}}
              >
                Pattern
              </th>
              <td className="pdfLeftBorder">
                <div style={{display:"flex"}}>
                <span className="JointDetailSpan">
                  {pdf[0]?.WPQ_Pattern[0]?.Actual_Value?.[0] || "--"}</span>
                  <span className="JointDetailSpan">
                     {pdf[0]?.WPQ_Pattern[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
              <th
             className="pdfLeftBorder pdffont" style={{borderLeft:"1px solid black"}}
              >
                Groove Angle(°)/Radius
                <div className="WPQrcorner"> mm </div>
              </th>
              <td className="pdfLeftBorder pdfRightBorder pdffont">
              <div style={{display:"flex"}}>
              <span style={{width:"85px"}} className="JointDetailSpan">
                               {pdf[0]?.WPQ_Groove_Angle[0]?.Actual_Value?.[0] || "--"}
                </span>
                <span style={{width:"85px"}} className="JointDetailSpan">
                                 {pdf[0]?.WPQ_Groove_Angle[1]?.Range_Qualified?.[0] || "--"}
                </span>
                </div>
              </td>
            </tr>
         
        </table>

      
      <div>
        <table
          className="pdfwpqTable"
        >
          <tbody>
            <tr className="pdfTopBorder pdfBorderBottom pdfLeftBorder PdfRightBorder">
              <th  style={{textAlign:"center"}}
                className="pdfTopBorder pdfBorderBottom pdfLeftBorder PdfRightBorder"
              >
                Tube Hole Ligament Detail
              </th>
              <th style={{textAlign:"center"}}
                className="pdfTopBorder pdfBorderBottom pdfLeftBorder PdfRightBorder"
              >
                Tube Hole Detail
              </th>
              <th style={{borderRight:"1px solid black",textAlign:"center"}}
                 className="pdfTopBorder pdfBorderBottom pdfLeftBorder "
              >
                Tube to Tubesheet Weld Joint Detail
              </th>
            </tr>

            <tr style={{height:"100px"}}>
              <td
               className="pdfLeftBorder pdfWPQRightdashedborder pdfBorderBottom"
               style={{borderRight: "1px dashed black"}}
              >
                {previewUrl1 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "0", marginLeft: "22%" }}
                    src={previewUrl1}
                  />
                )}
              </td>
              <td
               className="pdfWPQRightdashedborder pdfBorderBottom"
               style={{borderRight: "1px dashed black"}}
              >
                {previewUrl2 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "0", marginLeft: "25%" }}
                    src={previewUrl2}
                  />
                )}
              </td>
              <td
               className="pdfRightBorder pdfBorderBottom"
              >
                {previewUrl3 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "0", marginLeft: "32%" }}
                    src={previewUrl3}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* //-------------------------------------END OF JOIN-------------------------// */}
{/*  */}
         <div
        className="WPQgroup"
       
      >
        <div className="WPQbasemetal">FILLER METALS (QW-404)</div>
      </div>
      <div>
        
        <table id="table" className="pdfwpqTable" >
          <tbody>
            <tr>
              <th
              
              
                style={{backgroundColor:"white",width:"20%",border:"none"}}
              ></th>
              <th className="pdfTopBorder pdfLeftBorder pdfBottomBorder " style={{ width: "15%", textAlign:"center" }} >
                SFA
              </th>
              <th   className="pdfTopBorder pdfBottomBorder" style={{ width: "11%" ,textAlign:"center"}}>
                Classification
              </th>
              <th  className="pdfTopBorder pdfBottomBorder" style={{ width: "11%" ,textAlign:"center"}}>
                F-no
              </th>
              <th   className="pdfTopBorder pdfBottomBorder" style={{ width: "11%" ,textAlign:"center"}}>
                A-no
              </th>
              <th   className="pdfTopBorder  pdfRightBorder pdfBottomBorder" style={{ width: "31%",textAlign:"center" }}>
                Chemical Analysis or Trade name
              </th>
            </tr>
            {FillMetals_Processes()}
            </tbody>
            </table>
            <table className="pdfwpqTable" >
              <tbody>
            <tr>
                <th
                 style={{width:"20.2%"}}
                 className="pdfRightBorder pdfLeftBorder "
                >
                  Filler Metals - Others
                </th>
                <td
                  className="pdfRightBorder"
                 colSpan={7}
                >
                  {pdf[0]?.WPQ_FM_FillerOthers === "" ||
                  pdf[0]?.WPQ_FM_FillerOthers == undefined
                    ? "--"
                    : pdf[0]?.WPQ_FM_FillerOthers}
                </td>
              </tr>
              </tbody>
              </table>
              <table  className="pdfwpqTable">
                <tbody>
              <tr>
                <th
                className="pdfBorderBottom pdfLeftBorder pdfTopBorder "
                style={{width:"16.8%"}}
                >
                  Preplaced filler metal
                </th>
                <td  className="pdfLeftBorder pdfBorderBottom pdfTopBorder" style={{ width: "5%" }}>
                  {pdf[0]?.WPQ_FM_PreFillerMetal === "" ||
                  pdf[0]?.WPQ_FM_PreFillerMetal == undefined
                    ? "--"
                    : pdf[0]?.WPQ_FM_PreFillerMetal}
                </td>

                <th
                  className="pdfTopBorder pdfRightBorder pdfBorderBottom pdfTopBorder pdfLeftBorder" style={{width:"18%"}}
                >
                  With/without filler metal
                </th>
                <td  className="pdfRightBorder pdfBorderBottom pdfTopBorder" style={{ width: "8%" }}>
                  {pdf[0]?.WPQ_FM_WithFillerMetal === "" ||
                  pdf[0]?.WPQ_FM_WithFillerMetal == undefined
                    ? "--"
                    : pdf[0]?.WPQ_FM_WithFillerMetal}
                </td>

                <th
                  className="pdfTopBorder pdfRightBorder pdfBorderBottom"
                  style={{width:"18%"}}
                >
                  Filler metal Product form
                </th>
                <td className="pdfBorderBottom pdfTopBorder"  style={{ width: "8%" }}>
                  {pdf[0]?.WPQ_FM_FillerProduct === "" ||
                  pdf[0]?.WPQ_FM_FillerProduct == undefined
                    ? "--"
                    : pdf[0]?.WPQ_FM_FillerProduct}
                </td>

                <th
                  className="pdfTopBorder pdfBorderBottom pdfLeftBorder"
                  style={{ width: "5%" }}
                >
                  Inserts
                </th>
                <td className="pdfRightborder pdfBorderBottom pdfTopBorder pdfLeftBorder" style={{ width: "5%",borderRight:"1px solid black" }} >
                  {pdf[0]?.WPQ_FM_Inserts === "" ||
                  pdf[0]?.WPQ_FM_Inserts == undefined
                    ? "--"
                    : pdf[0]?.WPQ_FM_Inserts}
                </td>
              </tr>

   
          </tbody>
        </table>
      </div>
      
      {/* <!-- --------------------------Welding Procedure----------------------------------------------- --> */}
      
        <div className="WPQbasemetal">WELDING PROCEDURE</div>
        <table id="table" className="WPQTable8">
        <tr  style={{ borderTop: ".1px solid black" }}>
          <th 
            className="pdfLeftBorder "
            style={{ borderTop: ".1px solid black", width: "30%" }}
          >
            Welding Process & Type
          </th>
          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_WeldingProcess === "GTAW" ? pdf[1]?.WPQD_WP_WeldingProcess : "GTAW"} {" & "} {pdf[1]?.WPQD_WP_Type} </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder">
          Min. Preheat/Max. Interpass Temp.
            <div className="WPQrcorner"> °C </div>
          </th>
          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_MinimumPreheatTemp}{' / '}{pdf[1]?.WPQD_WP_MaximumInterpassTemp}</td>
        </tr>
      
        <tr >
          <th className="pdfLeftBorder ">Post Heating / DHT</th>
          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_PostHeating}</td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">
            Tungsten Size & Type
            <div className="WPQrcorner"> mm </div>
          </th>

          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_TungstenType}</td>
        </tr>{" "}
        <tr >
          <th className="pdfLeftBorder ">Position</th>
          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_WeldPosition}</td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Progression</th>

          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_WeldProgression}</td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">No of Passes</th>

          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_NoofPasses}</td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Layer Number</th>

          <td className={col1Class} id="weldpdata3">   
            
              {pdf[1]?.WPQD_WP_LayerNo[0]?.Value_A?.[0] || "--"}{" "}
            </td>
            <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_LayerNo[1]?.Value_B?.[0] || "--"}
            

            
          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">
            Filler Metal Size
            <div className="WPQrcorner"> mm </div>
          </th>

          <td className={col1Class} id="weldpdata3">  
           
              {pdf[1]?.WPQD_WP_Filler_Metal_Size[0]?.Value_A?.[0] || "--"}{" "}
            </td>
            <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_Filler_Metal_Size[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Amperes 
          <div className="WPQrcorner"> Amps</div> </th>

          <td className={col1Class} id="weldpdata3">
            
              {pdf[1]?.WPQD_WP_Amperes[0]?.Value_A?.[0] || "--"}{" "}
           </td>
              <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_Amperes[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">DC pulsing Current
          <div className="WPQrcorner"> Amps</div> </th>

          <td className={col1Class} id="weldpdata3">
            
              {pdf[1]?.WPQD_WP_DC_Pulsing_current[0]?.Value_A?.[0] || "--"}{" "}
        </td>
            <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_DC_Pulsing_current[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">DC pulsing Current-Others
          <div className="WPQrcorner"> </div> </th>

          <td className={col1Class} id="weldpdata3">
            
              {pdf[1]?.WPQD_WP_DC_Pulsing_current_Others[0]?.Value_A?.[0] || "--"}{" "}
        </td>
            <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_DC_Pulsing_current_Others[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
       
        <tr >
          <th className="pdfLeftBorder ">Voltage  <div className="WPQrcorner"> Volts</div></th>
          <td className={col1Class}>
 
              {pdf[1]?.WPQD_WP_Volatage_Volts[0]?.Value_A?.[0] || "--"}{" "}

          </td>
          <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_Volatage_Volts[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Travel Speed 
          <div className="WPQrcorner"> mm/min</div></th>

          <td className={col1Class} id="weldpdata3">

              {pdf[1]?.WPQD_WP_Travel_Speed[0]?.Value_A?.[0] || "--"}{" "}
          </td>
          <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_Travel_Speed[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Heat Input
          <div className="WPQrcorner"> KJ/mm</div> </th>

          <td className={col1Class} id="weldpdata3">
  
              {pdf[1]?.WPQD_WP_Heat_Input[0]?.Value_A?.[0] || "--"}{" "}
          </td>
          <td className={col1Class}>

              {" "}
              {pdf[1]?.WPQD_WP_Heat_Input[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Polarity or current type</th>

          <td className={col1Class} id="weldpdata3">

              {pdf[1]?.WPQD_WP_PolarityOrCurrent[0]?.Value_A?.[0] || "--"}{" "}
          </td>
          <td className={col1Class}>
              {" "}
              {pdf[1]?.WPQD_WP_PolarityOrCurrent[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="pdfLeftBorder ">Automatic Arc Voltage control</th>

          <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_Automatic_Arc_Voltage}</td>
        </tr>
   
           <tr >
            <th className="WPSthhide" style={{borderRight:"0px"}}>
              <div className="WPQgroup" style={{borderRight:"0px"}}>
                <div className="WPQlcorner">Shielding:</div>
                <div className="WPQmidGas" style={{ paddingLeft: "33px", width:"100px" }}>
                  Gas Type
                </div>
                <div className="WPQrcorner" style={{width:"40px", borderRight:"0px"}}>
                  %Comp
                </div>
              </div>
            </th>

            <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_ShieldingGasType}</td>
        
          </tr>{" "}
          <tr >
            <th className="WPSthhide" style={{borderRight:"0px"}}>
              <div className="WPSgroup" style={{borderRight:"0px"}}>
                <div className="WPSlcorner"></div>
                <div className="WPSmidGas" style={{ paddingLeft: "63px", width:"200px" }}>
                  Flow Rate
                </div>
                <div
                  className="WPSrcorner"
                  style={{width:"30px", borderRight:"0px" }}
                >
                  I/min
                </div>
              </div>
            </th>

            <td className={col1Class} colSpan={2}>{pdf[1]?.WPQD_WP_ShieldingFlowRate}</td>
          
           
          </tr>
          <tr className="WPStrhide">
            <th className="WPSthhide" style={{borderRight:"0px"}}>
              <div className="WPSgroup" style={{borderRight:"0px"}}>
                <div className="WPSlcorner">Backing/Trailing:</div>
                <div className="WPSmidGas" style={{ width:"80px" }}>
                  Gas Type
                </div>
                <div className="WPSrcorner" style={{ width:"30px",borderRight:"0px" }}>
                  %Comp
                </div>
              </div>
            </th>
            <td className={col1Class} colSpan={2}> {pdf[1]?.WPQD_WP_BackingGasType}</td>
           
          </tr>{" "}
          <tr className="WPStrhide">
            <th className="WPSthhide" style={{borderRight:"0px"}}>
              <div className="WPSgroup" style={{borderRight:"0px"}}>
                <div className="WPSlcorner"></div>
                <div className="WPSmidGas" style={{ paddingLeft: "63px",  width:"200px" }}>
                  Flow Rate
                </div>
                <div
                  className="WPSrcorner"
                  style={{width:"30px", borderRight:"0px" }}
                >
                  I/min
                </div>
              </div>
            </th>

            <td className={col1Class} colSpan={2}> {pdf[1]?.WPQD_WP_BackingFlowRate}</td>
           
           
          </tr>
        <tr >
          <th
            className="pdfLeftBorder "
            style={{ width: "30%" }}
          >
            Auxiliary gas shield system.
          </th>
          <td
            className={col1Class} colSpan={2}
          
          >
            {pdf[1]?.WPQD_WP_Auxiliarygas}
          </td>
        </tr>

        <tr >
          <th
            className="pdfLeftBorder "
            style={{ width: "30%" }}
          >
            Expansion
          </th>
          <td
            className={col1Class} colSpan={2}
         
          >
            {pdf[1]?.WPQ_OTV_Expansion}
          </td>
        </tr>

        <tr >
          <th
            className="pdfLeftBorder "
            style={{ width: "30%" }}
          >
            Orifice Cup Size
          </th>
          <td
            className={col1Class} colSpan={2}
          
          >
            {pdf[1]?.WPQ_OTV_OrifceCupSize}
          </td>
        </tr>

        <tr >
          <th
            className="pdfLeftBorder "
            style={{ width: "30%", borderBottom:"1px solid black" }}
          >
            Method of cleaning
          </th>
          <td
            className={col1Class} colSpan={2}
            style={{ borderBottom:"1px solid black" }}


          >
            {pdf[1]?.WPQ_OTV_MethodofCleaning}
          </td>
        </tr>


      </table>
   
      <div className="WPQgroup">
        <div className="WPQbasemetal" >
          POST WELD HEAT TREATMENT (QW407)
        </div>
      </div>
      <table id="table" className="pdfwpqTable"  >
        <tr>
          <th className="pdfTopBorder pdfLeftBorder pdfRightBorder"
       style={{width:"11%"}}
          >
            Temperature
            <div className="WPQrcorner"> °C </div>
          </th >
          <td className="pdfTopBorder pdfBorderBottom pdfRightBorder" style={{width:"18%"}}>{pdf[1]?.WPQ_Post_WHT_Temp}</td>

          <th
          style={{width:"11%"}}  className="pdfTopBorder  pdfRightBorder "
          >
            Time
            <div className="WPQrcorner"> Min </div>
          </th>

          <td style={{width:"14%"}}  className="pdfTopBorder pdfBorderBottom">
            {pdf[1]?.WPQ_Post_WHT_Time}
          </td>
          <th
         style={{width:"3%"}}
         className="pdfTopBorder pdfLeftBorder pdfRightBorder pdfBottomBorder"
         rowSpan={2}
          >
            Type
          </th>
          <td rowSpan={2} className="pdfTopBorder  pdfRightBorder pdfBottomBorder" style={{width:"15%"}}>
          {pdf[1]?.WPQ_Post_WHT_Type}</td>
        </tr>

        <tr>
          <th className="pdfLeftBorder  pdfRightBorder pdfBottomBorder">
            Heating Rate
            <div className="WPQrcorner"> °C/hr </div>
          </th>
          <td 
           className=" pdfRightBorder pdfBottomBorder"
          >
            {pdf[1]?.WPQ_Post_WHT_Heating_Rate}
          </td>

          <th className="pdfRightBorder pdfBottomBorder">
            Cooling Rate
            <div className="WPQrcorner"> °C/hr </div>
          </th> 
          <td className="pdfBottomBorder">
            {pdf[1]?.WPQ_Post_WHT_Cooling_Rate}
          </td>
         
        </tr>
      </table>
      <div className="wpqgroup">
        <div className="WPQbasemetal">NOTES</div>
      <table id="table" className="pdfwpqTable" style={{ top:"20px"}}>
        <tr>
          <td style={{height:"70px"}}
            className="pdfBottomBorder pdfRightBorder pdfLeftBorder pdfTopBorder Noteswrap"
          
          >    {pdf[1]?.WPQ_Page2_Notes != undefined
                    ? pdf[1]?.WPQ_Page2_Notes.split("\n").map((i) => {
                        return (
                          <>
                            {i}

                            <br></br>
                          </>
                        );
                      })
                    : ""}
                      </td>
               
        </tr>
      </table>
      </div>
     
      </div>
          
      
      </div>
      <div  style={{position:"fixed", top:"1010px"}}>
          
          <table className="WPQfooter1" >
          <tr>
            <td>Page 1 of 2</td>
          </tr>
        </table>
      </div>
              
    </div>
  );
};

export default WPQTubeToTubesheet_pdf_page1;
