import { React } from "react";
import Button from "react-bootstrap/Button";

const ButtonModal = ({ action,training, resetForm }) => {
  return (
    <div className="divindex">
      <div
        
        class={
          training === "training"
             ? " float-start m-1 visible"
             :  action === "Edit"
        ? " float-start m-1 invisible" :
             "float-start m-1 visible"
         }
      >
        &nbsp;&nbsp;&nbsp;
        {
          action ==="Upload"?
          null
          :
        <Button className="resetbtn" onClick={resetForm}>
          Reset
        </Button>
        }
      </div>
      <div class="float-end m-1">
        <Button type="submit" className="submitbtn">
          {action === "Edit" ? "Update" : "Add"}
        </Button>
        &nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
};

export default ButtonModal;
