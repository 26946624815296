import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";
import SuccessAlertModal from "../../Components/Modals/successAlertModal";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";

import { Row, Col, Container, Form, Button } from "react-bootstrap";

import GlobalColumnDefinations, { getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import { SearchSelectProject } from "../utility/search-select";
import {
  getAllOnlySlipNos,
  getWIDataHeader,
  addNewProductivity,
  getProdMeasure,
} from "../../Services/WI-services";
import ActionRenderer_inlinEdit from "../utility/ActionRenderer_inlinEdit";
import {
  numberMax6Digits,
  isAlphanumericWithDashDot,
} from "../../functions/validations";
import { errorstyle, normalstyle } from "../../functions/welding-functions";

const PRODLandPage = forwardRef((props, ref) => {



  const [filteredResult, setFilteredResult] = useState([]);


  const [columnDefs, setcolumnDefs] = useState([]);


  const [showFormadd, setShowFormadd] = useState(false);

  const [action, setAction] = useState("");
 
  const [reload, setReload] = useState(false);
  const [gridApi, setgridAPI] = useState([]);


  const [excelData1, setExcelData] = useState([]);
  const [ProjectDetails, setProjectDetails] = useState([]);
  const [wiDetails, setWIDetails] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [displayMsg, setDisplayMsg] = useState(false);

  //differentiate obj in slip fields and table fields
  const [prodMObj, setProdMObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          Processes: [
            {
              SK: "",
              WPSD_WP_FillerMetalProductForm: "",
              WPSH_consumRadio: "",
              WPSD_WP_ArcTransferMode: "",
              WPSD_WP_CurrentPolarity: "",
              WPSD_WP_Multiple_SinglePassPerSide: "",
            },
          ],
          PK: "WI_HEADER",
          SK: "",
          WI_Slip: "",
          WI_Project_No: "",
          WI_Joint_No: "",
          WI_JointDesign: "",
          WI_WPS_No: "",
          WI_Process: "",
          WI_Type: "",
          WI_Overlay_Joint_BMT: "",
          WI_PipePlate: "",
          WI_Pipe_Diameter: "",
          WI_Plate_No: "",
          WI_Welding_Position: "",
          WI_Welder_Id: "",
          WI_Welder_Name: "",
          wld_WPSType: "",
          WPSH_TRQ_BaseMetalCRO_Max: "",
          WPSH_BM_WeldingTo_PNo: "",
          WI_24hrs: "",
          WI_Slip_Status: "",
          WI_print: "",
          WCS_Project_No: "",
          WI_plant: "",
        }
  );


  const [datevalue, setdatevalue] = useState(false);

  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_p_category: {},
    wld_description: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
  });

  const [WiObj, setWiObj] = useState({
    Processes: [
      {
        SK: "",
        WPSD_WP_FillerMetalProductForm: "",
        WPSH_consumRadio: "",
        WPSD_WP_ArcTransferMode: "",
        WPSD_WP_CurrentPolarity: "",
        WPSD_WP_Multiple_SinglePassPerSide: "",
      },
    ],
    PK: "WI_HEADER",
    SK: "",
    WI_Slip: "",
    WI_Project_No: "",
    WI_Joint_No: "",
    WI_JointDesign: "",
    WI_WPS_No: "",
    WI_Process: "",
    WI_Type: "",
    WI_Overlay_Joint_BMT: "",
    WI_PipePlate: "",
    WI_Pipe_Diameter: "",
    WI_Plate_No: "",
    WI_Welding_Position: "",
    WI_Welder_Id: "",
    WI_Welder_Name: "",
    wld_WPSType: "",
    WPSH_TRQ_BaseMetalCRO_Max: "",
    WPSH_BM_WeldingTo_PNo: "",
    WI_24hrs: "",
    WI_Slip_Status: "",
    WI_print: "",
    WCS_Project_No: "",
    WI_plant: "",
  });
 

  const childRef = useRef();
  let isEditable = false;

  const tmpcolumnDefs = [
    {
      headerName: "Filler Metal Size",
      field: "WI_FM_Size",
      type: "Text",
      isCheckbox: false,
    },
    {
      headerName: "Item Code",
      field: "WI_ItemCode",
      type: "Text",
      isCheckbox: false,
    },
    {
      headerName: "Item Description",
      field: "WI_ItemDesc",
      type: "Text",
      isCheckbox: false,
    },
    {
      headerName: "Batch Number",
      field: "WI_WP_BatchNumber",
      type: "Text",
      isCheckbox: false,
      editable: true,
    },
    {
      headerName: "WC in Kgs/Nos",
      field: "WI_WCinKgsNos",
      type: "PRODSelect",
      isCheckbox: false,
      editable: true,
      cellStyle:{borderRadius:"4px"},
      cellClass:"hover-border"
   
    },
    {
      headerName: "WC in Kgs",
      field: "WI_WCinKgs",
      type: "Number",
      isCheckbox: false,
      editable: "dependentField",
 
      groupDefaultExpanded: 1,
      suppressAggFuncInHeader: true,
      enableCellChangeFlash: true,
      cellStyle:{borderRadius:"4px"},
      cellClass:"hover-border"
    },
    {
      headerName: "WC in Nos",
      field: "WI_WCinNos",
      type: "Number",
      isCheckbox: false,
      editable: "dependentField",
      cellStyle:{borderRadius:"4px"},
      cellClass:"hover-border"
    },
    {
      headerName: "In Kg/Electrode",
      field: "WI_WCinKgElectrode",
      type: "Number",
      isCheckbox: false,
    },
    {
      headerName: "Item Group",
      field: "WI_ItemGroup",
      isCheckbox: false,
      type: "ItemGroup",
      editable: true,
      cellStyle:{borderRadius:"4px"},
      cellClass:"hover-border"
    },
    {
      headerName: "Correction Factor",
      field: "WI_CorrectionFactor",
      type: "Text",
      isCheckbox: false,
      editable: false,
      cellStyle:{borderRadius:"4px"},
      cellClass:"hover-border"
    },
    {
      headerName: "WM in Kgs",
      field: "WI_WMinKgs",
      type: "Number",
      isCheckbox: false,
      cellRenderer: "",
      valueSetter: true,
      valueGetter: true,
      editable: false,
    },
  ];



  const actionRenderer = (params) => {
    setgridAPI(params);
    return (
      <ActionRenderer_inlinEdit
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params)}
      />
    );
  };
  

  const handleEdit = (params) => {
 
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: params.columnApi.getDisplayedCenterColumns()[3].colId,
    });
  };

  const fetchSlipNos = async () => {
    let result = await getAllOnlySlipNos();

    setProjectDetails(result);
  };
 
  let wiResult;
  let prodResult;
  const fetchSlipDetails = async (SlipList) => {
    try {
      prodResult = await getProdMeasure({
        WI_Slip: SlipList,
      });

      if (prodResult.length > 0) {
        setDisplayMsg(true);
      } else if (prodResult.length === 0) {
        setDisplayMsg(false);
        wiResult = await getWIDataHeader({
          WI_Slip: SlipList,
        });
      }


      const result = prodResult.length !== 0 ? prodResult : wiResult;

      if (result.length !== 0 && prodResult.length > 0) {
        setFilteredResult(result);
        setWIDetails(result);
      } else if (result.length !== 0 && prodResult.length === 0) {
        await showGridDetails(result);
        setWIDetails(result);
      }

      if (!result || result.length === 0) {
        setWiObj((prev) => ({
          ...prev,
          WI_Slip: "",
          WI_Welder_Id: "",
          WI_Project_No: "",
          WI_WPS_No: "",
          WI_Joint_No: "",
          WI_JointDesign: "",
          WI_Process: "",
          WI_CreatedBy: "",
          WI_Welding_Position: "",
          WI_FM_AWS: "",
          WI_FM_Brand: "",
          WI_Flux: "",
          WI_Flux_Brand: "",
          WI_SlipDate: "",
          WI_reprintDate: "",
          WI_plant: "",
        }));
      } else {

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  let tempArray = [];
  const showGridDetails = async (wiDetails) => {


    let fillerlength = wiDetails[0]?.WI_FillerMetalSize.filter(item => item !== "")
    try {
      if (wiDetails) {
        for (let i = 0; i < fillerlength?.length; i++) {
          let gridData = {
            PK: "PROD_REPORT",
            SK: wiDetails[0].WI_Slip + "#SK#" + [i],
            WI_Slip: wiDetails[0].WI_Slip,
            WI_Welder_Name: wiDetails[0].WI_Welder_Name,
            WI_SlipDate: wiDetails[0].WI_SlipDate,
            WI_reprintDate: wiDetails[0].WI_reprintDate,
            WI_plant: wiDetails[0].WI_plant,
            WI_Slip_Status: wiDetails[0].WI_Slip_Status,
            WI_Type: wiDetails[0].WI_Type,
            WI_FM_Size: wiDetails[0]?.WI_FillerMetalSize[i],
            WI_ItemCode: wiDetails[0].WI_WeldConsumable[i],
            WI_ItemDesc: wiDetails[0].WI_ItemDesc[i],
            WI_WP_BatchNumber: wiDetails[0].WI_WP_BatchNumber[i],
            WI_WCinKgsNos: "",
            WI_WCinKgs: "",
            WI_WCinNos: "",
            WI_WCinKgElectrode: wiDetails[0].WI_WCinKgElectrode[i],
            WI_ItemGroup: "",
            WI_CorrectionFactor: "",
            WI_WMinKgs: "",
            WI_Welder_Id: wiDetails[0].WI_Welder_Id,
            WI_Project_No: wiDetails[0].WI_Project_No,
            WI_WPS_No: wiDetails[0].WI_WPS_No,
            WI_Joint_No: wiDetails[0].WI_Joint_No,
            WI_JointDesign: wiDetails[0].WI_JointDesign,
            WI_Process: wiDetails[0].WI_Process,
            WI_CreatedBy: wiDetails[0].WI_CreatedBy,
            WI_Welding_Position: wiDetails[0].WI_Welding_Position,
            WI_FM_AWS: wiDetails[0].WI_FM_AWS,
            WI_FM_Brand: wiDetails[0].WI_FM_Brand,
            WI_Flux: wiDetails[0].WI_Flux,
            WI_Flux_Brand: wiDetails[0].WI_Welding_Position,
          };
          tempArray.push(gridData);
        }

        setFilteredResult(tempArray);
      }
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const gridOptions = {
    singleClickEdit: true,
    // Other grid options...
  };
  const CellEditing = (e) => {
    if(e.colDef.field == "WI_WCinNos"){
      e.api.getRowNode(e.node.rowIndex).setDataValue('WI_WCinKgs', e.value * e.data.WI_WCinKgElectrode)
    }
  };

  const fetchColumnDefns = async (params) => {
    const result1 = GlobalColumnDefinations(tmpcolumnDefs);
    let result = [];

    result1.forEach((e) => {
      result.push(e);
    });
    setcolumnDefs(result);
  };

  useEffect(() => {
    fetchColumnDefns();
  }, [reload]);

  useEffect(() => {
    fetchSlipNos();
  }, []);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      setAction("Add");
      setShowFormadd(true);
      setReload(true);
    },
  }));



  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";

  const [show1, setShow1] = useState(false);

  const validate = async (e) => {
    let isError = false;

    if (WiObj.WI_Slip === "") {
      isError = true;
      error.WI_Slip = {
        status: true,
        // message:"Selected date cannot be less than From date"
      };

      toast.warning("Please Select Mandatory field Slip No", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }

    return isError;
  };

  const handlesubmition = async (e) => {
    e.preventDefault();

    if (!(await validate())) {
      try {
        let hasValidationErrors = false;
        const updatedResult = filteredResult.map((item) => {
          var BatchNo = item.WI_WP_BatchNumber.toString();
          var WCinKgs = parseFloat(item.WI_WCinKgs);
          var WCinNos = parseFloat(item.WI_WCinNos);
          var WCinKgsNos = item.WI_WCinKgsNos;
          var ItemGroup = item?.WI_ItemGroup.split("-")[0];
          var CorrectionFactor = item.WI_CorrectionFactor;

          var WMinKgs = item.WI_WMinKgs;
    

          if (BatchNo !== "") {
     
            if (isAlphanumericWithDashDot(BatchNo).status === true) {
              hasValidationErrors = true;
              toast.error(
                "The BatchNo field allowed with alpahanumeric data with dot and dash only  " +
                  item.WI_FM_Size,
                {
                  position: toast.POSITION.TOP_LEFT,
                  className: "toast-message",
                }
              );
              return item;
            }
          }

          if (ItemGroup === "" || CorrectionFactor === "") {
          

            hasValidationErrors = true;
            toast.error("Please Select Item Group for " + item.WI_FM_Size, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
            return item;
          }

          if (WCinKgsNos === "") {
            hasValidationErrors = true;
            toast.error(
              "Please Select WCinKgsNos as Kgs or Nos: " + item.WI_FM_Size,
              {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              }
            );
            return item;
          } 
          else if (WCinKgsNos !== "") {
            if (WCinKgsNos === "Kgs") {
            

              if (
                isNaN(WCinKgs) ||
                WCinKgs < 0 ||
                WCinKgs === 0 ||
                WCinKgs === "" ||
                WCinKgs === undefined
              ) {
                hasValidationErrors = true;
                toast.error(
                  "The WCinKgs should be an Integer greater than 0 for Filler Metal: " +
                    item.WI_FM_Size,
                  {
                    position: toast.POSITION.TOP_LEFT,
                    className: "toast-message",
                  }
                );
                return item;
              }

     
              if (numberMax6Digits(WCinKgs).status === true) {
                hasValidationErrors = true;
                toast.error(
                  "The WCinKgs should be an Integer with max 6 digits " +
                    item.WI_FM_Size,
                  {
                    position: toast.POSITION.TOP_LEFT,
                    className: "toast-message",
                  }
                );
                return item;
              }

              const updatedItem = {
                ...item,
                WI_WMinKgs: (WCinKgs / CorrectionFactor).toFixed(2),
              };

              return updatedItem;
            } 
             if (WCinKgsNos === "Nos") {
              if (
                isNaN(WCinNos) ||
                WCinNos < 0 ||
                WCinNos === "" ||
                WCinNos === undefined
              ) {
                hasValidationErrors = true;
                toast.error(
                  "The WC in Nos should be an Integer : " + item.WI_FM_Size,
                  {
                    position: toast.POSITION.TOP_LEFT,
                    className: "toast-message",
                  }
                );
                return item;
              }
           

              const updatedItem = {
                ...item,
                WI_WMinKgs: (WCinKgs / CorrectionFactor).toFixed(2),

              };
              return updatedItem;
            }
          }
        });


        if (!hasValidationErrors) {
          setFilteredResult(updatedResult);
          setShowConfirmModal(true);
        }
      } catch (error) {
        console.error("Failed to update data:", error);
        toast.error("Failed to update data");
      }
    }
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleConfirmAction = async () => {
    try {
    
      const updatedData = await addNewProductivity(filteredResult);

      toast.success("Data updated successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    setShowConfirmModal(false);
  };

  const handleClose = () => {
    setShow1(false);
  };
  const handleCancelAction = () => {
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  return (
    <>
    <Container fluid className="Contain">
      <SuccessAlertModal
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={"Add"}
        handleYes={handleConfirmAction}
        handleNo={handleCancelAction}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        formName={"Productivity Measurement"}
      />

      <Row className="RowHeight mb-4 ml-2">
        <Col md={12} sm={12} xs={12} className="ListTitle">
          Productivity Measurement Filters
        </Col>
      </Row>


      {/* <Row className="gx-4"> */}
      <Row>
        <Col md={3}>
          <Form.Group className="ListW-input-select">
            <div className="search-select">
              <SearchSelectProject
                id="WI_Slip"
                name="WI_Slip"
                stylegreen={error.wld_code.status ? errorstyle : normalstyle}
                data={ProjectDetails}
                
                valueField={"WI_Slip"}
                value={WiObj.WI_Slip}
                labelField={"WI_Slip"}
                placeholder=""
                onChange={async (SlipList) => {

                  setWiObj((prev) => ({
                    ...prev,
                    WI_Slip: SlipList.value,
                    WI_Welder_Id: "",
                    WI_Project_No: "",
                    WI_WPS_No: "",
                    WI_Joint_No: "",
                    WI_JointDesign: "",
                    WI_Process: "",
                    WI_CreatedBy: "",
                    WI_Welding_Position: "",
                    WI_FM_AWS: "",
                    WI_FM_Brand: "",
                    WI_Flux: "",
                    WI_Flux_Brand: "",
                    WI_SlipDate: "",
                    WI_reprintDate: "",
                    WI_plant: "",
                  }));
                  fetchSlipDetails(SlipList.value);
                }}
              ></SearchSelectProject>
            </div>
            <label>Slip No</label>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_SlipDate"
                name="WI_SlipDate"
                disabled
                placeholder=" "
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_SlipDate}
              />
              <label for="wld_code">Creation Date</label>
            </div>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_reprintDate"
                name="WI_reprintDate"
                disabled
                placeholder=" "
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_reprintDate}
              />
              <label for="wld_code">Reprinted On</label>
            </div>
          </Form.Group>
        </Col>

        {displayMsg && (
          <Col
            md={12}
            sm={12}
            xs={12}
            className="ListTitle"
            style={{
              fontSize: "small",
              position: "relative",
              bottom: "17px",
              color: "var(--godrej-red-alt)",
            }}
          >
            Data already saved
          </Col>
        )}
      </Row>

      <Row style={{ position: "relative", bottom: "20px" }}>


        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_Welder_Id"
                name="WI_Welder_Id"
                disabled
                placeholder=" "
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_Welder_Id}
              />
              <label for="wld_code">Welder Code</label>
            </div>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_Project_No"
                name="WI_Project_No"
                disabled
                placeholder=" "
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_Project_No}
              />
              <label for="wld_code">Project No.</label>
            </div>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_WPS_No"
                name="WI_WPS_No"
                placeholder=" "
                disabled
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_WPS_No}
              />
              <label for="wld_code">WPS No.</label>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row style={{ position: "relative", bottom: "17px" }}>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_JointDesign"
                name="WI_JointDesign"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_JointDesign}
              />
              <label for="wld_code">Joint Design</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_Process"
                name="WI_Process"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_Process}
              />
              <label for="wld_code">Process</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_CreatedBy"
                name="WI_CreatedBy"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_CreatedBy}
              />
              <label for="wld_code">Created By</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_Welding_Position"
                name="WI_Welding_Position"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_Welding_Position}
              />
              <label for="wld_code">Welding Position</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row style={{ position: "relative", bottom: "14px" }}>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_FM_AWS"
                name="WI_FM_AWS"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_FM_AWS}
              />
              <label for="wld_code">Filler Metal(AWS)</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_FM_Brand"
                name="WI_FM_Brand"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_FM_Brand}
              />
              <label for="wld_code">Filler Metal Brand</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_Flux"
                name="WI_Flux"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_Flux}
              />
              <label for="wld_code">Flux</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <div class="T-input w-100">
              <input
                type="text"
                id="WI_Flux_Brand"
                name="WI_Flux_Brand"
                placeholder=" "
                disabled
                // disabled={Readonly}
                maxLength={10}
                className={
                  error.wld_code.status ? "err-input prodheight" : "prodheight"
                }
                value={wiDetails[0]?.WI_Flux_Brand}
              />
              <label for="wld_code">Flux Brand</label>
            </div>
            <div className="required-text">
              {error.wld_code.status && (
                <Form.Text className="text-danger">
                  {error.wld_code.message}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col
          md={12}
          sm={12}
          xs={12}
          // className="redNoteMsg"
          style={{
            fontSize: "small",
            color: "var(--godrej-red-alt)",
            position: "relative",
            bottom: "20px",
          }}
        >
          Each item to be saved row by row : Flux item is displayed with Filler
          Metal Size 99999
        </Col>
      </Row>

  

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        defaultColDef={{ resizable: true }}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
        gridOptions={gridOptions}
        onCellValueChanged={CellEditing}
        pagination={false}
        height={250}

        
      />
      
      <Toaster />
    </Container>

    <Row>
        <Col>
          <div>
            {!displayMsg && (
              <Button onClick={handlesubmition} className="prodsave">
                Save
              </Button>
            )}
          </div>
        </Col>
      </Row>

    </>
    
  );
});

export default PRODLandPage;
