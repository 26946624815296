const Tubesheet_Basemetal = ({pdf}) =>{
    return(
<div>
        <table
          id="table"
          className="WPSTable2"
          style={{ width: "72%"}}
        >
          <tbody>
            <tr>
              <th
                rowSpan="2"
                className="WPSnoborder"
                style={{
                  width: "2%",

                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  backgroundColor: "white",
                }}
              ></th>
              <th
                className="WPSleft-th"
                rowSpan="2"
                style={{
                  width: "15%",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  textAlign:"center"

                }}
              >
                Product Form
              </th>
              <th
                className="WPSleft-th"
                rowSpan="2"
                style={{
                  width: "35%",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  textAlign:"center"
                }}
              >
                Spec (Type or Grade)
              </th>

              <th
                className="WPSleft-th"
                rowSpan="2"
                style={{
                  width: "7%",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  textAlign:"center"

                }}
              >
                P No/A No
              </th>
              <th
                className="WPSleft-th"
                rowSpan="2"
                style={{
                  width: "1%",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  textAlign:"center", 
                }}
              >
                G No
              </th>
              <th
                className="WPSleft-th"
                rowSpan="2"
                style={{
                  width: "1%",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  textAlign:"center",

                }}
              >
                Thk.mm
              </th>
              <th
                className="WPSleft-th"
                rowSpan="2"
                style={{
                  width: "14%",
                  borderBottom: ".1px solid black",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  textAlign:"center"

                }}
              >
                Dia.mm
              </th>
            </tr>
            <br />
            <tr>
            <th
                style={{
                  width: "15%",
                  borderRight: "1px solid black",
                  borderLeft: "1px solid black",
                }}
              >
              </th>
             
              <td style={{ width: "10%", wordBreak:"break-all",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_BaseMetal1_ProductForm}
              </td>
              <td style={{ width: "15%", wordBreak:"break-all",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_BaseMetal1_Spec}
              </td>
              <td style={{ width: "10%",wordBreak:"break-all",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_BaseMetal1_PNo}
              </td>
              <td style={{ width: "0%",wordBreak:"break-all",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_BaseMetal1_GNo}
              </td>
              <td style={{ width: "0%",wordBreak:"break-all",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_BaseMetal1_Thick}
              </td>
              <td style={{ width: "25%",wordBreak:"break-all",borderRight: "1px solid black",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_BaseMetal1_Diam}
              </td>
            </tr>
         <tr>
         <th
                style={{
                  width: "15%",textAlign:"Left",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  borderLeft: "1px solid black",
                }}
              >
                Welded To
              </th>
              <td style={{ width: "10%", wordBreak:"break-all",borderBottom: "1px solid black", textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_WeldBaseMetal2_ProductForm}
              </td>
              <td style={{ width: "15%", wordBreak:"break-all",borderBottom: "1px solid black",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_WeldBaseMetal2_Spec}
              </td>
              <td style={{ width: "10%",wordBreak:"break-all",borderBottom: "1px solid black",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_WeldBaseMetal2_PNo}
              </td>
              <td style={{ width: "9%",wordBreak:"break-all",borderBottom: "1px solid black",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_WeldBaseMetal2_GNo}
              </td>
              <td style={{ width: "9%",wordBreak:"break-all",borderBottom: "1px solid black",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_WeldBaseMetal2_Thick}
              </td>
              <td style={{ width: "20%",wordBreak:"break-all",borderBottom: "1px solid black", borderRight: "1px solid black",textAlign:"center"
 }}>
                {pdf[0]?.WPSH_BM_WeldBaseMetal2_Diam}
              </td>
            </tr>
          </tbody>
        </table>

        {/*End table 2 */}
        {/* <!-- -------------------------------------------Thickness Table 3 --------------------------------------------------------------> */}
      </div>
)}    
export default Tubesheet_Basemetal;