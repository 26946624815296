import axios from "axios";

let PQR_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  PQR_API_URL = process.env.REACT_APP_PQR_API_URL;
} else {
  PQR_API_URL = process.env.REACT_APP_API_URL + "pqr";
}


export async function getAllWPSNo() {
  const response = await axios
    .get(PQR_API_URL + "/PqrDetailsMast/allwpsno")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export const getdetailsById = async (reqParam) => {
  
  let url = `${PQR_API_URL}/PqrDetailsMast/getdetailsByIdPQR`;
  const response = await axios
    .post(url, reqParam)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getdetailsById_url_detail", response.data);
  return response.data;
};



export const AddNewDetail = async (PQR_Details_Master) => {
  let url = `${PQR_API_URL}/PqrDetailsMast`;
  console.log("AddNewDetail_url", url,PQR_Details_Master);
  const response = await axios
    .post(url, PQR_Details_Master)
    .finally(() => {
      console.log("second page service");
    })
    .then(function (resp) {
      console.log("second page service", resp);
      return resp;
    })
    .catch(function (error) {
      console.log("second page service", error);
      return { message: "Unable to find request", status: false };
    });
  console.log("second page in servuce", response);
};



export async function DeletePQR(pkid) {
  debugger
  console.log("pkkkk",pkid)

  let url = `${PQR_API_URL}/PqrDetailsMast/delete`;
  let reqbody = {PK:pkid.PK,SK:pkid.SK}
  console.log("pkkkk",pkid)
  const resp = await axios
    .post(url,reqbody)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export const getPQRDetailsEdit = async (reqData) => {
  let url = `${PQR_API_URL}/PqrDetailsMast/getPQRDetailsEdit`;
  console.log("getPQRDetailsEdit_url", url);
  console.log("reqData", reqData);
  const response = await axios
    .post(url, reqData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
