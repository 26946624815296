import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";

import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";

import GolbalSearch from "../../utility/GolbalSearch";

import { handleExport1 } from "../../utility/GlobalExport";
import {  Row, Col, Container } from "react-bootstrap";
import Export from "../../../icons/Export.svg";

import GlobalColumnDefinations,{ getHeader_FieldName } from "../../utility/AgGridDefination";
  import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../../utility/GridMaster";
import Toaster from "../../utility/Toaster";


import { getCompletedTraining } from "../../../Services/training-service";

import { getTrainingRecordbyID } from "../../../Services/trainingRequisition-service";
import ActionRenderer from "../../utility/ActionRenderer";
import { toast } from "react-toastify";
import { DeleteTrainingRecord } from "../../../Services/trainingRecord-service";
import AddEditAttendanceDetails from "./AddEditAttendanceDetails";
import { GridLoader } from "react-spinners";

const TrainingRecord = forwardRef((props, ref) => {
  let formName = "Training";

  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [combinedArray, setCombinedArray] = useState([]);
  const [training, setTraining] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showFormexcel, setShowFormexcel] = useState(false);

  const [action, setAction] = useState("");

  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);

  const [excelData1, setExcelData] = useState([]);

  const [loading, setLoading] = useState(true);



 
  const childRef = useRef();

 
 const navigate = useNavigate();


  const tmpcolumnDefs = [
   
    {
      headerName: "Training subject",
      field: "wld_trainingSub",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Trainee Name",
      field: "wld_TrainingReqEmployeeName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Salcode",
      field: "wld_TrainingReqSalaryEmployeeCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
   
    {
      headerName: "Month of Training",
      field: "wld_trainingMonth",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "From Date",
      field: "wld_fromDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "To Date",
      field: "wld_toDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "Faculty",
      field: "wld_faculty",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
    {
      headerName: "Requester Department",
      field: "wld_TrainingReqRequesterDepartment",
      type: "text",
      isCheckbox: false,
      cellRenderer: "",

    },
    {
      headerName: "Attendance",
      field: "wld_attendance",
      type: "text",
      isCheckbox: false,
    },
    {
      headerName: "Score",
      field: "wld_score",
      type: "Text",
      isCheckbox: false,
    },
    {
      headerName: "Total Marks",
      field: "wld_marks",
      type: "Text",
      isCheckbox: false,

    },
    {
      headerName: "Percentage",
      field: "wld_percentage",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  

  ];
  



  const handleHyperlink = (row) => {
   
    navigate("/weldingmaster/TrainingRecord/TrainingDetails", { state: {
      data: row,
      action: "Trainee",
    },
    });
  };


  const handleView = (row) => {

    setAction("View");
    setRowData(row);
    setShowForm(true);
  }




  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };


  




  const fetchTraining = async () => {
  try {
    const result = await getCompletedTraining();
    console.log("resultresult",result)

    setFilteredResult(result);

    let detailResults = await Promise.all (result.map(item => getTrainingRecordbyID(item.SK,item.wld_trainingSub,item.wld_fromDate,item.wld_toDate,item.wld_trainingMonth,item.wld_faculty)));
    console.log("detailResult",detailResults)

   detailResults = detailResults.filter((record)=> Array.isArray(record) && record.length > 0)

   //flat() - removes nested array and conbines only objects
   const nestedarray = detailResults.flat()

    setCombinedArray(nestedarray);


  } catch (error) {
    console.error("Error fetching training data:", error);
  }
  setLoading(false);

};

 

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
   
    result.push({ headerName: "", cellRenderer: actionRenderer, width: 250,   suppressMenu: true,})
   
    setColumnDefs(result);
  };

  useEffect(() => {
    fetchTraining();
    fetchColumnDefns();
  }, [reload]);



  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));
  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  const handleEdit = (row) => {
    debugger

    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };
  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(training);
  };

  
 
  const handleDelete = (row) => {
    DeleteTrainingRecord(data)
      .then((result) => {
        if (result.data.message === "Success") {
          setShowForm(false);
          toast.success(`Selected Training has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          setReload(!reload);
        }
      })
      .catch((error) => {

      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  const [show1, setShow1] = useState(false);

  

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text)

  };
 
 
  const handleGlobalExport = (selectedRow) => {
    let filename = "TrainingMaster";
    setExcelData(filteredResult)
     handleExport1(combinedArray, filename, tmpcolumnDefs);
  };


debugger
  return (
    <>
      {loading && (
      <div className="loader-overlay">
        <GridLoader size={10} className="pageloader" />
      </div>
    )}
    
    <Container fluid>
    
      <ConfirmAlertModal
        action={action}
        handleYes={handleView}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
       <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      
     
    
        <>
         <Row className="RowHeight">
         <Col md={2} sm={12} xs={12} className="ListTitle">
           Training Record list
           </Col>
         <Col>
           <div className="global-search-container">
             <GolbalSearch
               searchplaceholdertext={
                 "Search for Training subject, Faculty"
               }
               filterFunc={handleGlobalSearch}
             />
             <img
               className="allexport"
               src={Export}
               alt="Export"
               onClick={handleGlobalExport}
             />
           </div>
         </Col>
       </Row>
    

      <GridMaster
        rowDetails={combinedArray}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
      />
      </>
        
      <Row className="RowHeight">
         <Col md={2} sm={12} xs={12} className="ListTitle">
           Training Record list
           </Col>
         <Col>
         
         </Col>
       </Row>
       {showForm && (
        <AddEditAttendanceDetails
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
       
          backdrop="static"
        />
      )}


        

      <Toaster />

    </Container>
    </>
  );
});

export default TrainingRecord;
