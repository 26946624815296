import React from "react";
import "../Css/WPQgroove_pdf.css";



import Groove_pdf_headerWPQ from "./Groove_pdf_headerWPQ";

import {

  CommanTable_4Rows_WPQTTS_ROTest,
} from "./CommanTable_4Rows_WPQTTS";
import {
  CommanTable_5Rows_WPQTTS,
  CommanTable_minimumRows_WPQTTS,
} from "./CommanTable_5Rows_WPQTTS";
import PageFooter from "../../WPQObjects/PageFooter";

const WPQTubeToTubesheet_pdf_page2 = (
  { pdf, setpdf, PreviewUrl, previewuser, previewapproved },
  props,
  wld
) => {

  const width = "72%";

  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };







  function Common_Min_ProcessesTB(
    test,
    tableno,
    field1,
    field2,
    field3,
    field4,
    field5
  ) {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const Examination = pdf[i]?.[test] || [];
      const table1Data = Examination[0]?.[tableno] || [];

      for (let j = 0; j < table1Data.length; j++) {
        const item = table1Data[j];
        var className = "pdfLeftBorder  pdfRightBorder";
        var className1 = "pdfLeftBorder ";
        if (j == table1Data.length - 1) {
          className = "pdfLeftBorder  pdfRightBorder pdfBorderBottom";
          className1 = "pdfLeftBorder pdfBorderBottom";
        }

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className={className1}>
              {item[field1] !== "" ? item[field1] : "--"}
            </td>
            <td className={className1}>
              {item[field2] !== "" ? item[field2] : "--"}
            </td>
            <td className={className1}>
              {item[field3] !== "" ? item[field3] : "--"}
            </td>
            <td className={className1}>
              {item[field4] !== "" ? item[field4] : "--"}
            </td>
            <td className={className}>
              {item[field5] !== "" ? item[field5] : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Common_Max_ProcessesTB(test, field6, field7) {
    debugger;
    const rows = [];

    for (let i = 1; i < pdf.length; i++) {
      const Examination = pdf[i]?.[test] || [];
      const table5Data = Examination || [];

      for (let j = 0; j < table5Data.length; j++) {
        const item = table5Data[j];

        rows.push(
          <tr key={`${1}-${j}`}>
            <td className="pdfLeftBorder ">
              {item[field6] !== "" ? item[field6] : "--"}
            </td>
            <td className="pdfLeftBorder ">
              {item[field7] !== "" ? item[field7] : "--"}
            </td>
            {/* <td className="pdfLeftBorder  pdfRightBorder">{item.WPQ_MEX_Observation !== "" ? item.WPQ_MEX_Observation : '--'}</td> */}
          </tr>
        );
      }
    }

    return rows;
  }

  function Common_Testing_Examination(
    test,
    firscol,
    secondcol,
    thirdcol,
    fourthcol
  ) {
    const rows = [];
    // alert(test.toString())

    const Examination = pdf[1][test] || [];

    for (let j = 0; j < Examination?.length; j++) {
      const item = Examination[j];

      rows.push(
        <tr key={`${1}-${j}`}>
          <td className="pdfLeftBorder ">
            {item[firscol] !== "" ? item[firscol] : "--"}
          </td>
          <td className="pdfLeftBorder ">
            {item[secondcol] !== "" ? item[secondcol] : "--"}
          </td>
          <td className="pdfLeftBorder ">
            {item[thirdcol] !== "" ? item[thirdcol] : "--"}
          </td>
          <td className="pdfLeftBorder pdfRightBorder">
            {item[fourthcol] !== "" ? item[fourthcol] : "--"}
          </td>
        </tr>
      );
    }

    return rows;
  }

  return (
    // ------------------------------------------------------------------------------------//

    <div className="MainPDF">
      <div className="WPQflexb" style={{position:"relative", top:"40px"}}>
        <div className="WPQheaderRow">
          {/* <br></br>
          <br></br> */}
          <Groove_pdf_headerWPQ
            pdf={pdf}
            from="WPQ"
            Page="2"
          ></Groove_pdf_headerWPQ>

          {/* ------------------------------Visual examination---------------------- */}
        </div>
        <div
          className="WPQContent"
          style={{
            height: "430px",
            position: "relative",
            top: "7px",
            bottom: "30px",
          }}
        >
          <CommanTable_4Rows_WPQTTS_ROTest
            width={width}
            pdf={pdf}
            heading="VISUAL EXAMINATION"
            field1="Weld Pass"
            field2="Acceptance Criteria"
            field3="Observation"
            Notes="Notes"
            field4="Result"
            ReportNumber="Report Number"
            type="Visual_Examination"
            value1="WPQ_VEX_WeldPass"
            value2="WPQ_VEX_Acceptance_Criteria"
            value3="WPQ_VEX_Observation"
            value4="WPQ_VEX_Results"
            reportvalue="WPQ_VEX_Report"
            Common_Testing_Examination={Common_Testing_Examination}
          ></CommanTable_4Rows_WPQTTS_ROTest>

          <CommanTable_4Rows_WPQTTS_ROTest
            width={width}
            pdf={pdf}
            heading="LIQUID PENETRANT EXAMINATION"
            field2="Acceptance Criteria"
            field3="Result"
            Notes="Notes"
            field1="Weld Pass"
            field4="Comment"
            ReportNumber="Report Number"
            type="Liquid_Penetrant_Examination"
            value1="WPQ_LPEX_WeldPass"
            value2="WPQ_LPEX_Acceptance_Criteria"
            value3="WPQ_LPEX_Results"
            value4="WPQ_LPEX_Observation"
            reportvalue="WPQ_LPEX_Report"
            Common_Testing_Examination={Common_Testing_Examination}
          ></CommanTable_4Rows_WPQTTS_ROTest>

          {/* -------------------------MACRO EXAMINATION---------------------------------*/}
          <div style={{ display: "flex"}}>
            <div style={{width:"365px"}}>
              <CommanTable_5Rows_WPQTTS
                heading="MACRO EXAMINATION"
                TubeNo="Tube No"
                test="Macro_Examination"
                tablename="macroExamination"
                tableno="Table1"
                LocA="Loc A"
                LocB="Loc B"
                LocC="Loc C"
                LocD="Loc D"
                width={width}
                pdf={pdf}
                styles={styles}
                field1="WPQ_MEX_Tube_No"
                field2="WPQ_MEX_LocA"
                field3="WPQ_MEX_LocB"
                field4="WPQ_MEX_LocC"
                field5="WPQ_MEX_LocD"
                Common_Min_ProcessesTB={() =>
                  Common_Min_ProcessesTB(
                    "Macro_Examination",
                    "Table1",
                    "WPQ_MEX_Tube_No",
                    "WPQ_MEX_LocA",
                    "WPQ_MEX_LocB",
                    "WPQ_MEX_LocC",
                    "WPQ_MEX_LocD"
                  )
                }

                
              ></CommanTable_5Rows_WPQTTS>
            </div>

            <div style={{ position: "relative", right: "70px",width:"365px" }}>
              <CommanTable_5Rows_WPQTTS
                heading=""
                TubeNo="Tube No"
                test="Macro_Examination"
                tablename="macroExamination"
                tableno="Table2"
                LocA="Loc A"
                LocB="Loc B"
                LocC="Loc C"
                LocD="Loc D"
                width={width}
                pdf={pdf}
                styles={styles}
                field1="WPQ_MEX_Tube_No"
                field2="WPQ_MEX_LocA"
                field3="WPQ_MEX_LocB"
                field4="WPQ_MEX_LocC"
                field5="WPQ_MEX_LocD"
                Common_Min_ProcessesTB={() =>
                  Common_Min_ProcessesTB(
                    "Macro_Examination",
                    "Table2",
                    "WPQ_MEX_Tube_No",
                    "WPQ_MEX_LocA",
                    "WPQ_MEX_LocB",
                    "WPQ_MEX_LocC",
                    "WPQ_MEX_LocD"
                  )
                }
              ></CommanTable_5Rows_WPQTTS>
            </div>
          </div>

          <CommanTable_minimumRows_WPQTTS
            heading=""
            test="Macro_Examination5"
            tableno="Table2"
            width={width}
            pdf={pdf}
            styles={styles}
            field6="WPQ_MEX_Magnification"
            field7="WPQ_MEX_Acceptance_Criteria"
            Common_Max_ProcessesTB={() =>
              Common_Max_ProcessesTB(
                "Macro_Examination5",
                "WPQ_MEX_Magnification",
                "WPQ_MEX_Acceptance_Criteria"
              )
            }
            Magnification="Magnification"
            AcceptanceCriteria="Acceptance Criteria"
            Observation="Observation"
            Result="Result"
            Observationvalue="WPQ_MEX_Observation"
            resultvalue="WPQ_MEX_Result"

          ></CommanTable_minimumRows_WPQTTS>



{/* {=========================================MINIMUM LEAK PATH============================================} */}


<div style={{ display: "flex" }}>
            <div  style={{width:"365px"}}>
              <CommanTable_5Rows_WPQTTS
                heading="MINIMUM LEAK PATH"
                TubeNo="Tube No"
                test="Minimum_Leak_Path"
                tablename="macroExamination"
                tableno="Table1"
                LocA="Loc A"
                LocB="Loc B"
                LocC="Loc C"
                LocD="Loc D"
                width={width}
                pdf={pdf}
                styles={styles}
                field1="WPQ_MLP_Tube_No"
                field2="WPQ_MLP_LocA"
                field3="WPQ_MLP_LocB"
                field4="WPQ_MLP_LocC"
                field5="WPQ_MLP_LocD"
                Common_Min_ProcessesTB={() =>
                  Common_Min_ProcessesTB(
                    "Minimum_Leak_Path",
                    "Table1",
                    "WPQ_MLP_Tube_No",
                    "WPQ_MLP_LocA",
                    "WPQ_MLP_LocB",
                    "WPQ_MLP_LocC",
                    "WPQ_MLP_LocD"
                  )
                }
              ></CommanTable_5Rows_WPQTTS>
            </div>

            <div style={{ position: "relative", right: "70px",width:"365px" }}>
              <CommanTable_5Rows_WPQTTS
                heading=""
                TubeNo="Tube No"
                test="Minimum_Leak_Path"
                tableno="Table2"
                LocA="Loc A"
                LocB="Loc B"
                LocC="Loc C"
                LocD="Loc D"
                width={width}
                pdf={pdf}
                styles={styles}
                field1="WPQ_MLP_Tube_No"
                field2="WPQ_MLP_LocA"
                field3="WPQ_MLP_LocB"
                field4="WPQ_MLP_LocC"
                field5="WPQ_MLP_LocD"
                Common_Min_ProcessesTB={() =>
                  Common_Min_ProcessesTB(
                    "Minimum_Leak_Path",
                    "Table2",
                    "WPQ_MLP_Tube_No",
                    "WPQ_MLP_LocA",
                    "WPQ_MLP_LocB",
                    "WPQ_MLP_LocC",
                    "WPQ_MLP_LocD"
                  )
                }
              ></CommanTable_5Rows_WPQTTS>
            </div>
          </div>

          <CommanTable_minimumRows_WPQTTS
            heading=""
            test="Minimum_Leak_Path5"
            tableno="Table2"
            width={width}
            pdf={pdf}
            styles={styles}
            field6="WPQ_MLP_Magnification"
            field7="WPQ_MLP_Acceptance_Criteria"
            Common_Max_ProcessesTB={() =>
              Common_Max_ProcessesTB(
                "Minimum_Leak_Path5",
                "WPQ_MLP_Magnification",
                "WPQ_MLP_Acceptance_Criteria"
              )
            }

            Magnification="Magnification"
            AcceptanceCriteria="Acceptance Criteria"

            Observation="Observation"
            Result="Result"
            Observationvalue="WPQ_MLP_Observation"
            resultvalue="WPQ_MLP_Result"
          ></CommanTable_minimumRows_WPQTTS>

          <CommanTable_4Rows_WPQTTS_ROTest
            width={width}
            pdf={pdf}
            heading="RADIOGRAPHY TEST"
            filed1="Tube No"
            field2="Acceptance Criteria"
            field3="Observation"
            field4="Result"
            type="Radiography_Test"
            value1="WPQ_Radio_TubeNo"
            value2="WPQ_Radio_Acceptance_Criteria"
            value3="WPQ_Radio_Acceptance_Criteria"
            value4="WPQ_Radio_Results"
            Notes="Notes"
            Common_Testing_Examination={(
              type,
              value1,
              value2,
              value3,
              value4
            ) =>
              Common_Testing_Examination(
                "Radiography_Test",
                "WPQ_Radio_TubeNo",
                "WPQ_Radio_Acceptance_Criteria",
                "WPQ_Radio_Acceptance_Criteria",
                "WPQ_Radio_Results"
              )
            }
          ></CommanTable_4Rows_WPQTTS_ROTest>

          <CommanTable_4Rows_WPQTTS_ROTest
            width={width}
            pdf={pdf}
            heading="OTHER TEST"
            field2="Acceptance Criteria"
            field3="Result"
            Notes="Notes"
            field1="Type of Test"
            field4="Remarks"
            type="Other_Test"
            value1="WPQ_OT_TypeofTest"
            value2="WPQ_OT_Acceptance_Criteria"
            value3="WPQ_OT_Observation"
            value4="WPQ_OT_Results"
            Common_Testing_Examination={Common_Testing_Examination}
          ></CommanTable_4Rows_WPQTTS_ROTest>



        <div className="wpqgroup">
        <div className="WPQbasemetal" >
          CERTIFICATION
        </div>
        </div>
           <table id="table" className="pdfwpqallborders" >
        <tr>
          {" "}
        
          <th
           style={{width:"20%"}}
           className="pdfBorderBottom pdfLeftBorder pdfTopBorder" >
            Test Conducted By
          </th>
          <td  style={{width:"25%"}} className=" pdfBottomLeft pdfTopBorder ">
            {pdf[1]?.WPQ_Certification_ConductedBy}
          </td>
          <th
            style={{width:"20%"}}
            className="pdfBorderBottom pdfLeftBorder pdfTopBorder"
          >
            Mechanical Test By
          </th>
          <td  style={{width:"25%"}} className="RightBottom pdfLeftBorder pdfTopBorder">
            {pdf[1]?.WPQ_Certification_Mechanical_TestsBy}
          </td>
        </tr>
        <tr>
          
          <th  className="pdfLeftBorder pdfBorderBottom">
            Laboratory Test No.
          </th>
          <td colspan="3"
          className="pdfLeftBorder RightBottom"
          >
            {pdf[1]?.WPQ_Certification_Lab_RefNo}
          </td>
        </tr>
      
      </table>
        <div className="WPQfooterRow" >
      <p className="WPQcertifont" >We certify that the statement in this record are correct and that the test welds were prepared, welded and tested in the accordance with the requirements of Section IX of the ASME code </p>
        
    </div> 

</div>
      
        <div style={{ position: "fixed", top: "1945px" }}>
     
          {/* <br></br> <br></br> */}
          <PageFooter
            pdf={pdf}
            previewuser={previewuser}
            previewapproved={previewapproved}
          ></PageFooter>
        </div>
        <div style={{ position: "fixed", top: "2040px" }}>
          <table className="WPQfooter1">
            <tr>
              <td>Page 2 of 2</td>
            </tr>
          </table>
        </div>
      </div>

      {/* <!-- --------------------------Welding Procedure----------------------------------------------- --> */}
    </div>
  );
};

export default WPQTubeToTubesheet_pdf_page2;
