export const emptyError = (err, seterr, iserror) => {
  
  var entries = Object.keys(err);
 
  entries.forEach((i) => {
 
    if(err[i].message ===  process.env.REACT_APP_SPECIAL_CHARACTER_MSG){
      iserror = true
      err[i] = {
        status: true,
        message: err[i].message,
      };    
    }
    else{
   
    err[i] = {
      status: false,
      message: ``,
    };
  }
   });

  seterr((prevState) => ({
    ...prevState,
    ...err,
  }));

  return iserror
};

export const emptyErrorWPQDetail = (err, seterr, iserror) => {
  
  var entries = Object.keys(err);


  entries.forEach((i) => {

    let errmessage = process.env.REACT_APP_SPECIAL_CHARACTER_MSG
      if(err[i][0].Actual_Value[0].message === errmessage  ||  err[i][1].Range_Qualified[0].message === errmessage){
      iserror = true
      seterr((prevState) => ({
        ...prevState,
        [entries]: [
          { Actual_Value: [{status: true,
            message: process.env.REACT_APP_SPECIAL_CHARACTER_MSG}] },
          { Range_Qualified: [{status: true,
            message: process.env.REACT_APP_SPECIAL_CHARACTER_MSG}] },
        ],
      }));  
    }
    else{
    seterr((prevState) => ({
      ...prevState,
      [entries]: [
        { Actual_Value: [{status: false,
          message: ""}] },
        { Range_Qualified: [{status: false,
          message: ""}] },
      ],
    }));
  }
  });

  return iserror
};

export const emptyErrorWPQDetailTubesheet = (err, seterr, iserror) => {
  
  var entries = Object.keys(err);


  entries.forEach((i) => {

    let value = err[i]
    if(Array.isArray(value)){
    let errmessage = process.env.REACT_APP_SPECIAL_CHARACTER_MSG
      if(err[i][0].Value_A[0].message === errmessage ||  err[i][1].Value_B[0].message === errmessage){
      iserror = true
      seterr((prevState) => ({
        ...prevState,
        [entries]: [
          { Value_A: [{status: true,
            message: process.env.REACT_APP_SPECIAL_CHARACTER_MSG}] },
          { Value_B: [{status: true,
            message: process.env.REACT_APP_SPECIAL_CHARACTER_MSG}] },
        ],
      }));  
    }
    else{
    seterr((prevState) => ({
      ...prevState,
      [entries]: [
        { Value_A: [{status: false,
          message: ""}] },
        { Value_B: [{status: false,
          message: ""}] },
      ],
    }));
  }
}
else{

  if(err[i].message ===  process.env.REACT_APP_SPECIAL_CHARACTER_MSG){
    iserror = true
    err[i] = {
      status: true,
      message: err[i].message,
    };    
  }
  else{

  err[i] = {
    status: false,
    message: ``,
  };
}


seterr((prevState) => ({
  ...prevState,
  ...err,
}));


}
  });

  return iserror
};