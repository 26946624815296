import "../../../WPSTransaction/PDF/Css/Download_pdf.css";

const PQR_Footer = ({
  pdf,
  PreviewUrl,
  previewuser,
  previewapproved,
  Band,
}) => {

  const currentDate = new Date(pdf[0]?.WPSH_ApprovedBy_Date);

  const day1 = currentDate.getDate();
  const month1 = currentDate.getMonth() + 1;
  const year1 = currentDate.getFullYear();
  const WitnessedByDate = `${day1}-${month1}-${year1}`;

  const currentDate1 = new Date(pdf[0]?.WPSH_PreparedBy_Date);
  const day = currentDate1.getDate();
  const month = currentDate1.getMonth() + 1;
  const year = currentDate1.getFullYear();
  const PreparedByDate = `${day}-${month}-${year}`;

return(<>
<div style={{position:"relative", top:"0px"}}>
<table className="WPSnoborder" style={{ width: "70%"}}>
<tr>
<td className="WPSSignatureTable" style={{ width: "230px"}}>Welding Engineer</td>
<td className="WPSSignatureTable" style={{ position:"relative", left: "60px" }}>Approved By</td>
</tr>
</table>
 <table id="table" className="WPSTable11">
 <tbody>
   <tr style={{borderBottom: "none" }}>
     <th style={{ borderBottom: "none" }}>Name</th>
     <th style={{ borderBottom: "none", borderLeft:"none", borderRight:"none" }}>Signature</th>
     <th style={{ borderBottom: "none" }}>Name</th>

     <th style={{ borderBottom: "none", borderLeft:"none" }}>Signature</th>
   </tr> 
   {pdf[0]?.wld_WPSType === "Tubesheet" ? 
   <td style={{ borderBottom: "none",height:"13px" }}>{pdf[0]?.WPSH_WeldingEnggBy_Name}</td>
   :
   <td style={{ borderBottom: "none",height:"13px" }}>{pdf[0]?.WPSH_PreparedBy_Name}</td> }

   <td rowSpan={3} style={{ borderLeft: "none" ,borderRight:"none"}}>
   {previewuser != "" &&  (
      <img style={{ width: "95%", marginTop: "4%", marginLeft: "2%", marginBottom:"3%", height:"30px" }}  src={previewuser} />
    )}

       {/* <img style={{ width: "95%", height:"30px", marginTop: "4%", marginLeft: "2%", marginBottom:"3%" }} src={image1} /> */}

   </td>
   {pdf[0]?.wld_WPSType === "Tubesheet" ?  <><td style={{ borderBottom: "none" }}>{pdf[0]?.WPSH_WitnessBy_Name}</td></> : <><td style={{ borderBottom: "none" }}>{pdf[0]?.WPSH_ApprovedBy_Name}</td></>}
    
   
   <td rowSpan={3} style={{ borderLeft: "none" }}>
     {previewapproved != "" && (
       <img style={{ width: "95%", marginTop: "4%", marginLeft: "2%", marginBottom:"3%", height:"30px" }}  src={previewapproved} />
     )}
       {/* <img style={{ width: "95%", marginTop: "4%", marginLeft: "2%", marginBottom:"3%", height:"30px" }} src={image} /> */}
   </td>

   <tr>
     <th style={{ borderBottom: "none" }}>Date</th>

     <th style={{ borderBottom: "none" }}>Date</th>
   </tr>
   <tr>
     <td style={{height:"13px"}}> {PreparedByDate === "NaN-NaN-NaN" ? "" : PreparedByDate}</td>
     <td style={{height:"13px"}}> {WitnessedByDate === "NaN-NaN-NaN" ? "" : WitnessedByDate}</td>
   </tr>
 </tbody>
</table>
</div>

       
      
</>)
}

export default PQR_Footer; 
