import React, {
  useState,
  useRef,
  forwardRef,


  useEffect,
} from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useLocation, useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { getGridDetailData } from "../../Services/wcs-services";

import GlobalColumnDefinations, { getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import { Action, Status } from "./WCSObjects/WCS_entity";
import AddEditWCS from "./AddEditWCS";
import { getAllProject } from "../../Services/project-services";
import ActionRenderer_inlinEdit from "../utility/ActionRenderer_inlinEdit";
import {
  checkWPSinArray,
} from "../WPSTransaction/WPSObjects/WPS_Validations";

import {
  UpdateDetailWCS,
  getbyprojectno,
  DeleteWCSDetail,
} from "../../Services/wcs-services";

import WCSFormStatus from "../utility/WCSFormStatus";
import viewNote from "../../icons/viewNote.svg";
import uploadImage from "../../icons/uploadImage.svg";
import { isAlphanumericWithDash } from "../../functions/validations";
import { SearchSelectProject } from "../utility/search-select";
import WCSViewNote from "./WCSViewNote";
import { checkJointNoPresent } from "../../Services/WI-services";
import { errorstyle, normalstyle } from "../../functions/welding-functions";

const WCSDetail = forwardRef((props, ref) => {

  const location = useLocation();
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [gridApi, setgridAPI] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [Groove, setGroove] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);

  const [excelData, setExcelData] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  let Band = sessionStorage.getItem("Band");

  const [WCSStatus, setWCSStatus] = useState("");
  const [preStatus, setPreStatus] = useState("");
  const [WCSChangeNo, setWCSChangeNo] = useState(0);
  let approver = Band === "P" || Band === "T";
  let initiator = Band !== "P" && Band !== "T";

  let userName = sessionStorage.getItem("UserName");
 
  let userEmailId = sessionStorage.getItem("User");
  const [WPSNoList, setWPSNoList] = useState("");

  const [ProjectDetails, setProjectDetails] = useState([]);
  const [showViewNote, setshowViewNote] = useState(false);


  let myResult;




  const [error, setError] = useState({
    WCS_Project_No: {},
  });

  const childRef = useRef();


  const [headerObj, setheaderObj] = useState([]);
  const [WCSProjectNo, seWCSProjectNo] = useState(location.state?.data);

  const tmpcolumnDefs = [
    {
      headerName: "Project Number",
      field: "WCS_Project_No",
      cellRenderer: "",
      // valueGetter: (params) => {
      //   return parseFloat(params.data.SK);
      // },
      Error: true,
    },
    {
      headerName: "Joint Number",
      field: "WCS_Joint_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      Error: true,
    },
    {
      headerName: "WPS Number 1",
      field: "WCS_WPS_No1",
      type: "WPSearch",
      isCheckbox: false,

      editable: true,
      Error: true,
      cellStyle:{borderRadius:"4px",lineheight:"2.5px"},
      cellClass:"hover-border"
    },

    {
      headerName: "WPS Number 2",
      field: "WCS_WPS_No2",
   
      isCheckbox: false,
      cellRenderer: "",
      editable: true,
      type: "WPSearch",
      Error: true,
      cellStyle:{borderRadius:"4px",lineheight:"2.5px"},
      cellClass:"hover-border"
    },
    {
      headerName: "WPS Number 3",
      field: "WCS_WPS_No3",

      isCheckbox: false,
      //    cellRenderer: "",
      editable: true,
      type: "WPSearch",
      Error: true,
      cellStyle:{borderRadius:"4px",lineheight:"2.5px"},
      cellClass:"hover-border"
    },
    {
      headerName: "WPS Number 4",
      field: "WCS_WPS_No4",

      isCheckbox: false,
      cellRenderer: "",
      type: "WPSearch",
      editable: true,
      Error: true,
      cellStyle:{borderRadius:"4px",lineheight:"2.5px"},
      cellClass:"hover-border"
    },
    {
      headerName: "Change ID No",
      field: "WCS_ChangeDetail_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      Error: true,
    },

    {
      headerName: "Updated by",
      field: "WCS_UpdatedBy_Name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
      Error: true,
    },
    {
      headerName: "Updated On",
      field: "WCS_UpdatedOn",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      Error: true,
    },
  ];

  const actionRenderer = (params) => {
    setgridAPI(params);
    return (
      <ActionRenderer_inlinEdit
        selectedData={params}
        gridPage="WCS"
        handleEditEvent={(params) => handleEdit(params)}
        handleDeleteEvent={(params) => handleDeleteEvent(params.data)}
       
      />
    );
  };

  const handleEdit = (params) => {
  
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      // gets the first columnKey
      colKey: params.columnApi.getDisplayedCenterColumns()[3].colId,
    });

  };



  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchWCSHeader = async () => {
    let result = await getbyprojectno(WCSProjectNo);

    setheaderObj(result);


    if (result !== undefined && result[0] !== undefined) {
      setWCSStatus(result[0].WCS_Status);
      setPreStatus(result[0].WCS_Status);
      setWCSChangeNo(
        result[0].WCS_Change_No !== "" || result[0].WCS_Change_No !== undefined
          ? parseInt(result[0].WCS_Change_No)
          : 0
      );
    }
  };
  const fetchWCSDetail = async () => {

    let result = await getGridDetailData(
      WCSProjectNo,
      sessionStorage.getItem("User")
    );

    if (result !== undefined && result !== "undefined" && result.length > 0) {
      for (let i = 0; i < result.length; i++) {
        result[i].isModified = false;
        result[i].hasError = false;
        result[i].ErrorMessage = "";
        result[i].WCS_ChangeDetail_No =
          result[i].WCS_ChangeDetail_No === undefined
            ? 1
            : parseInt(result[i].WCS_ChangeDetail_No);
      }

    }  
    setFilteredResult(result);
  };

  const fetchColumnDefns = async () => {
    const result1 = GlobalColumnDefinations(tmpcolumnDefs);
    let result = [];
    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      width: 0,
      Error: true,
      sortable:false,
      suppressMenu: true,
    });
    result1.forEach((e) => {
      result.push(e);
    });

    setColumnDefs(result);
  };

  useEffect(() => {
    fetchWCSHeader();
    fetchWCSDetail();
    fetchColumnDefns();
    fetchProject();

  }, [reload]);

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();

    childRef.current.setSearch(searchText);
  };
  const handleSubmit = (e) => {
    fetchWCSHeader();
    fetchWCSDetail();
  };
  const handleGlobalExport = () => {
    let filename = "WCSDetail";
    setExcelData(filteredResult);
    handleExport1(filteredResult, filename, tmpcolumnDefs);
  };

  const handlesubmition = async (buttonClick) => {
    setWCSStatus(buttonClick);

    try {
      
      let hasValidationErrors = false;

      let updateditems = filteredResult.filter((e) => e.isModified === true);

      filteredResult
        .filter((e) => e.isModified === true)
        .map((item) => {
          
          

          let WCS_WPS_No1 = item.WCS_WPS_No1;
          let WCS_WPS_No2 = item.WCS_WPS_No2;
          let WCS_WPS_No3 = item.WCS_WPS_No3;
          let WCS_WPS_No4 = item.WCS_WPS_No4;
       
          item.ErrorMessage = "";
          item.hasError = false;
          debugger
          if (WCS_WPS_No1 === undefined || String(WCS_WPS_No1).trim() === "") {
            hasValidationErrors = true;
            item.hasError = true;
            item.ErrorMessage += "WPS No 1 is mandatory for Joint No., ";
            //return item;
          }

          if (
            WCS_WPS_No2 !== undefined &&
            String(WCS_WPS_No2).trim() !== "" &&
            WCS_WPS_No2 === WCS_WPS_No1
          ) {
            hasValidationErrors = true;
            item.hasError = true;
            item.ErrorMessage += "WPS No 2 is duplicate for Joint No., ";
            // return item;
          }

          if (
            WCS_WPS_No3 !== undefined &&
            String(WCS_WPS_No3).trim() !== "" &&
            (WCS_WPS_No3 === WCS_WPS_No1 || WCS_WPS_No3 === WCS_WPS_No2)
          ) {
            hasValidationErrors = true;
            item.hasError = true;
            // return item;
            item.ErrorMessage += " WPS No 3 is duplicate for Joint No., ";
          }

          if (
            WCS_WPS_No4 !== undefined &&
            String(WCS_WPS_No4).trim() !== "" &&
            (WCS_WPS_No4 === WCS_WPS_No1 ||
              WCS_WPS_No4 === WCS_WPS_No2 ||
              WCS_WPS_No4 === WCS_WPS_No3)
          ) {
            hasValidationErrors = true;
            item.hasError = true;
            item.ErrorMessage += "WPS No 4 is duplicate for Joint No., ";
           
          }

         
        });


      let wpsnos = getArrayList(updateditems);

      if (updateditems.length > 0) {

        let finaled = {
          project: updateditems[0].WCS_Project_No,
          WPSNo_Array: wpsnos,
         
        };


        myResult = await checkWPSinArray(finaled);


        let notfounds = [];
        if (myResult) {
          notfounds = wpsnos.filter((x) => !myResult.includes(x.wps));



          notfounds.forEach((element) => {


            let WCSError = updateditems.filter(
              (e) =>
                String(e.WCS_WPS_No1) === element.wps ||
                String(e.WCS_WPS_No2) === element.wps ||
                String(e.WCS_WPS_No3) === element.wps ||
                String(e.WCS_WPS_No4) === element.wps
            );

          

            WCSError.forEach((element1) => {
              hasValidationErrors = true;

              element1.hasError = true;
              element1.ErrorMessage += "WPS invalid";
            });
          });
        }
      }



      if (buttonClick === "Approved") {
        setAction(Action.ActionApprove);
      } else if (buttonClick === "Rejected") {
        setAction(Action.ActionReject);
      } else if (
        updateditems &&
        updateditems.length > 0 &&
        buttonClick === "Pending For Approval"
      ) {
        setAction(Action.ActionEdit);
      } else if (updateditems.length === 0 && "Pending For Approval") {
        setAction(Action.ActionSend);
      }

      if (hasValidationErrors) {

        gridApi.api.applyTransaction({ update: updateditems });
      } else {
        gridApi.api.applyTransaction({ update: updateditems });
        setShowConfirmModal(true);
      }
    } catch (error) {
      console.error("Failed to update data:", error, filteredResult);
      toast.error("Failed to update data");
    }
  };

  function getArrayList(start) {
    let arr_list = [];
    start.forEach((element, index) => {


      arr_list.push({ wps: element.WCS_WPS_No1 });
      if (element.WCS_WPS_No2 !== "" && element.WCS_WPS_No2 !== undefined)
        arr_list.push({ wps: element.WCS_WPS_No2 });
      if (element.WCS_WPS_No3 !== "" && element.WCS_WPS_No3 !== undefined)
        arr_list.push({ wps: element.WCS_WPS_No3 });
      if (element.WCS_WPS_No4 !== "" && element.WCS_WPS_No4 !== undefined)
        arr_list.push({ wps: element.WCS_WPS_No4 });
    });

    return arr_list;
  }

  const handleConfirmAction = async () => {




    try {
      
      let WCSHeader = {
        WCS_Status: "",
        WCS_ApprovedBy_Name: "",
        WCS_ApprovedBy: "",
        SK: WCSProjectNo,
        WCS_UpdatedBy_Name: "",
        WCS_UpdatedBy: "",
        WCS_Change_No: 0,
      };
      // if (preStatus === Status.StatusApproved) {
      //   setWCSChangeNo(WCSChangeNo + 1);
      // }

      let upDater = {};
      upDater.WCS_UpdatedBy_Name = userName;
      upDater.WCS_UpdatedBy = userEmailId;
      let updateditems = filteredResult.filter((e) => e.isModified === true);

      if (approver) {


        WCSHeader.WCS_Status = WCSStatus;
        // WCSHeader.WCS_Change_No = WCSChangeNo;
        WCSHeader.WCS_Change_No =
          updateditems.length > 0 ? WCSChangeNo + 1 : WCSChangeNo;
        WCSHeader.WCS_ApprovedBy_Name = userName;
        WCSHeader.WCS_ApprovedBy = userEmailId;
      } else if (initiator) {



        WCSHeader.WCS_Status = Status.StatusPending;
        WCSHeader.WCS_Change_No =
          updateditems.length > 0 ? WCSChangeNo + 1 : WCSChangeNo;
        // WCSHeader.WCS_Change_No = WCSChangeNo;

        WCSHeader.WCS_UpdatedBy_Name = userName;
        WCSHeader.WCS_UpdatedBy = userEmailId;
      }

      let upDateRequest = { updateditems, upDater, headerObj: WCSHeader };

      if (preStatus === Status.StatusApproved) {
        updateditems.map((i) => {
          i.WCS_ChangeDetail_No = parseInt(i.WCS_ChangeDetail_No) + 1;
        });
      }
      if (updateditems.length > 0) {
        setWCSChangeNo(WCSChangeNo + 1);
      }
      

      await UpdateDetailWCS(upDateRequest);


      toast.success("Data updated successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    setShowConfirmModal(false);

  };

  const handleCancelAction = () => {
    setShowConfirmModal(false);
  };


  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Groove);
  };




  const fetchProject = async () => {
    //Review use GetAllProjects
    let result = await getAllProject();
    if (result?.length > 0)
      result = result.filter((item) => item.wld_status === "Active");
    setProjectDetails(result);
  };


  const gridOptions = {
    singleClickEdit: true,
    // Other grid options...
  };

  const hanldeExcelFileUpload = (e) => {
    
    setAction(Action.ActionUpload);
    setShowForm(true);
  };

  const hanldeViewNote = (e) => {
    
    setshowViewNote(true);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setAction(Action.ActionAdd);
    setShowForm(true);
  };

  let isPresent;
  const handleDeleteEvent = async (row) => {
    try {

      setAction("Delete");
      isPresent = await checkJointNoPresent(row);


      if (isPresent) {
        toast.warning("WCS already in use, cannot be deleted", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      } else {
        setRowData(row);
        let gridDetail = { PK: row.PK, SK: row.SK };
        await DeleteWCSDetail(gridDetail);
        toast.success(`Selected DetailGrid is Deleted`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        //window.location.reload();
      }
      setReload(true);
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  return (
    <>
      <div className="title1">
        {" "}
        WCS
        <div className="add_btn">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <button class="button button1" onClick={(e) => handleAdd(e)}>
            {" "}
            <div className="text">
              <img src="../sidebar/add.png"></img>
              Add WCS
            </div>
          </button>
        </div>
      </div>
      <Container fluid>
        <ConfirmAlertModal
          handleYes={handleConfirmAction}
          handleNo={handleCancelAction}
          action={action}
          //handleDelete={handleDelete}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          formName={"WCS Details"}
        />
        <Row xs="auto" className="align-items-center">
          <Col>
            <Form.Group>
              <SearchSelectProject
                id="WCS_Project_No"
                name="WCS_Project_No"
                className={error.WCS_Project_No.status ? "err-input" : ""}
                stylegreen={
                  error.WCS_Project_No.status ? errorstyle : normalstyle
                }
                data={ProjectDetails}
                
                valueField={"wld_ProjectNumber"}
                value={WCSProjectNo}
                labelField={"wld_ProjectNumber"}
                placeholder=""
                onChange={(ProjectList) => {

                  setheaderObj((prev) => ({
                    ...prev,
                    WCS_Project_No: ProjectList.value,
                  }));
                  setError((prevState) => ({
                    ...prevState,
                    WCS_Project_No: {},
                  }));
                  seWCSProjectNo(ProjectList.value);
                }}
                onInput={(e) => {
                  let error = isAlphanumericWithDash(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WCS_Project_No: error,
                  }));
                }}
              ></SearchSelectProject>
            </Form.Group>
          </Col >
          <Col style={{position:"relative", right:"12px"}}>
            <Button className="showresult" onClick={handleSubmit} style={{height:"37px", width:"104px"}}>
              <p style={{position:"relative", top:"8px", fontSize: "14px"}}>Show Result</p>
            </Button>
          </Col>
          <Col ColSize="auto" style={{position:"relative",left:"5px"}}>
            <Form.Group>
              <div class="wcsT-input w-100 mb-0">
                <input
                  type="text"
                  id="WCS_Change_No"
                  name="WCS_Change_No"
                  readOnly
                  placeholder=" "
                  disabled={action === Action.ActionEdit ? true : false}
                  maxLength={20}
                  value={WCSChangeNo}
                 
                />
                <label for="WCS_Change_No">WCS Change No.</label>
              </div>
            </Form.Group>
          </Col>
          <Col style={{position:"relative",right:"10px"}}>

              <Button
                variant="outline-success"
                className="uploadexcelbtn"
                onClick={hanldeExcelFileUpload}
                style={{height:"34px"}}
              >
                <img src={uploadImage}></img>
                &nbsp;
                <b>Upload Excel</b>
              </Button>
          </Col>
          <Col style={{position:"relative",right:"23px"}}>
              <label className="viewNoteLbl" onClick={hanldeViewNote}>
                <img src={viewNote}></img>
                &nbsp;
                <b>View Note</b>
              </label>
          </Col>
        </Row>
        <Row
          className="RowHeight align-items-center"
          style={{ position: "relative", marginTop: "5px" }}
        >
          <Col xs={1}>
            <WCSFormStatus formStatus={WCSStatus} />
          </Col>
          <Col>
            <div className="global-search-container">
              <GolbalSearch
                searchplaceholdertext={
                  "Search for Project No.,Joint no.,WPS No 1,2,3 and 4"
                }
                filterFunc={handleGlobalSearch}
              />
              <img
                className="allexport"
                src={ExportIcon}
                alt="ExportIcon"
                onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
                onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
                onClick={handleGlobalExport}
              />
            </div>
          </Col>
        </Row>
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          defaultColDef={{ resizable: true }}
          fieldNames={fieldName}
          headerNames={headerName}
        
          setExcelData={setExcelData}
          ref={childRef}
          gridOptions={gridOptions}
          paginationPageSize={4}
          // height={430}
          height={340}
        />

        <Toaster />
        {showForm && (
          <AddEditWCS
            show={showForm}
            action={action}
            rowdata={rowData}
            onHide={() => setShowForm(false)}
            resetForm={resetForm}
            setReload={setReload}
            reload={reload}
            headerObj={headerObj}
            setheaderObj={setheaderObj}
            // allRecs={WCS}
            backdrop="static"
            selectProject={WCSProjectNo}
            WPSNoList={WPSNoList}
          />
        )}
        {showViewNote && (
          <WCSViewNote
            show={showViewNote}
            onHide={() => setshowViewNote(false)}
            backdrop="static"
          />
        )}
      </Container>
      <Row className="RowHeightWCS">

    

        <Col>
          <div>
            {approver ? (
              <>
                <Button
                  onClick={(event) => handlesubmition(Status.StatusRejected)}
                  className="wcsreject"
                  style={{  width:"109px",
                  height:"40px",
                  paddingTop:"5px"
                }}
                >
                  Reject
                </Button>
                <Button
                  onClick={(event) => handlesubmition(Status.StatusApproved)}
                  className="wcsapprove"
                  style={{  width:"109px",
                  height:"40px",
                  paddingTop:"5px"
                }}
                >
                  Approve
                </Button>
              </>
            ) : (
              <>
<Col style={{position:"absolute", right:"25px", bottom:"30px"}}>
              <Button  
                onClick={(event) => handlesubmition(Status.StatusPending)}
                className="sendapprovald btn-primary"
              >
                Send For Approval
              </Button>
              </Col>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
});

export default WCSDetail;
