import React, {  useState } from "react";
import { Button, Alert, Navbar, Container } from "react-bootstrap";




import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export default function LoginPage({ showLoginError }) {


  const [alertMessage, setAlertMessage] = useState("");
  
  const [errorMessage, setErrorMessage] = useState(
    "Oh! User does not exist / inactive in application"
  );
  const { instance } = useMsal();
  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }
  return (
    <div className="login-container">
      {alertMessage && (
        <Alert className="alertBoxLogin" variant="success">
          {alertMessage}
        </Alert>
      )}
      <Navbar>
        <Container>
          <Navbar.Brand>
            <img
              alt=""
              src="/godrej-logo.svg"
            />
          </Navbar.Brand>
          <p className="login-text">
            Godrej Enterprises Group
          </p>
        </Container>
      </Navbar>
      <div className="place-right">
          <div className="loginLeftImage" >
            <img src="/WeldingLogin.png" style={{width:"100%", height: "100%", objectFit: "cover"}} alt="WeldingLogin" />
          </div>
        <div  className="rightSideLoginPage" > 
   
            <div>
          
              <p className="login-text">Welding System</p>
            </div>
           
            <div className="mt-3">
              {showLoginError && (
                <div className="loginErrorDiv">
                  <div className="loginErrorDivHeader">Login Failed</div>
                  <div className="loginErrorDivBody">{errorMessage}</div>
                </div>
              )}
            </div>
            <div className="LoginRightInput">
              <Button
                onClick={() => handleLogin(instance)}
                className={"forgotButton"}
              >
                Login
              </Button>
            </div>
        
        </div>
      </div>
    </div>
  );
}
