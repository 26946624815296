
import "../Css/groovePQR_pdf.css"

import Groove_pdf_header_common from "../../../WPSTransaction/PDF/Pages/Groove_pdf_header_common";



const TTS_pdf_headerWPQpage1 = ({pdf, setpdf},props,wld) => {
    const currentDate = new Date(pdf[0]?.PQR_Date);
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
 return(
     
       <header>
      <Groove_pdf_header_common
        currentDate={currentDate}
        from="PQR"
      ></Groove_pdf_header_common>
       
<table id="table" className="WPSTable1">
        <tbody>
          <tr>
            <th
              style={{
                width: "28%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
                borderLeft: "1px solid",
              }}
            >
              PQR record number
              <br></br>
              Date
              <br></br>
              Qualification Code
            </th>
            <td
              style={{
                width: "40%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <span className="WPSforfont" style={{ float: "left" }}>
                {pdf[0]?.PQR_RecordNo}
              </span><br></br>
              <span className="WPSforfont" style={{ float: "left" }}>
                {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
                {/* {pdf[0]?.PQR_Date} */}
              </span><br></br>
              <span className="WPSforfont" style={{ float: "left" }}>
                {pdf[0]?.PQR_Qualificationcode}
              </span>
            </td>
            <th
              className="WPSright-th"
              style={{
                width: "32%",
                borderLeft: "1px solid",
                borderBottom: "1px solid",
                borderTop: "1px solid",
                borderRight: "1px solid",
                paddingTop:"0px",
                position:"relative"
              }}
            >
              pWPS Record Number
              <br></br>
              Company Name <br></br>
            </th>

            <td
              style={{
                width: "55%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <span className="WPSforfont" style={{ float: "left" }}>
                {pdf[0]?.PQR_pWPSNo} 
              </span><div className="PQRcorner">   Revision: {pdf[0]?.PQR_WPS_ReviNo}</div>
              <br></br>
              <span className="WPSforfont" style={{ float: "left" }}>
                {pdf[0]?.PQR_Address} <br></br>
              </span><br></br>
              <span className="WPSforfont" style={{ float: "left" }}>
             
              </span>
            </td>

            <td
              style={{
                width: "35%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
              }}
            >
              {pdf[0]?.WPS_QualifiedTo}
              <br></br>
              {pdf[0]?.WPSH_CompanyName}<br></br>
              
            </td>
          </tr>
        </tbody>
      </table>




</header> )
}
export default TTS_pdf_headerWPQpage1
