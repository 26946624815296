//TABLE WITH 2 ROWS AND 2 COLUMNS (WPQ- VARIABLES AND WELDING PROCESS)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import Error from "../../../icons/ErrorIcon.svg";
import { TypeData } from "../../WPSTransaction/WPSObjects/WPSGroove_entity";
import { SearchSelect } from "../../utility/search-select";
import { Action } from "./WPQ_entity";
import { useState } from "react";
import { useLocation } from "react-router-dom";
export const CommonTable4 = ({
  headerObj,
  detailObj,
  setdetailObj,
  ProcessActive,
  isReadonly,
}) => {
  const location = useLocation();

  const [action, setaction] = useState(location.state?.action);

 
  const processDisabled = (i) => {
    if(action === Action.ActionEdit && headerObj?.Process_Status[i] !== undefined){
    if (
      action === Action.ActionEdit &&
      headerObj?.Process_Status[i] === "InActive"
    ) {
      return true;
    } else if (headerObj?.WPSH_Status === "Pending For Approval") {
      return true;
    }
  }
  else{
    return false
  }
  }

  return (
    <Table className="text-center tablewidth">
      <thead>
        <tr className="border thead-color ">
          
          <th className="thickness_title" style={{ width: "21%" }}></th>
          <th className="thickness_title" style={{ width: "21%" }}>Process</th>
          <th className="thickness_title" style={{ width: "21%" }}>Type</th>
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
        </tr>
      </thead>

      <tbody>
        {detailObj &&
          detailObj?.map((item, i) => {
            return (
              <tr className={detailObj[i]?.error ? "border  error" : "border "} >
                <th
                  className={
                    detailObj[i]?.error
                      ? "text-start border thead-color error-head"
                      : "text-start border thead-color"
                  }
                >
                  <div key={i}>
                    <label>Process {i + 1}</label>
                    {detailObj[i]?.error && (
                      <Tooltip content={detailObj[i]?.errormessage}>
                        <img src={Error} alt="Error" className="ms-1" />
                      </Tooltip>
                    )}
                  </div>
                </th>
                <td className="WPQSelect" style={{ paddingLeft: "2%" , width: "100%"}}>
                  <SearchSelect
                    className="inspdrop "
                    data={ProcessActive}
                    key={i}
                    style={{width: "100%"}}
                    readOnly={
                      action === Action.ActionCopy ||
                      action === Action.ActionEdit
                        ? isReadonly
                        : false
                    }
                    valueField={"wld_code"}
                    
                   
                    disabled={processDisabled(i)}
                    placeholder=""
                   
                    value={item?.WPQ_Process}
                    labelField={"wld_code"}
                    onChange={(e) => {
                    
                      let a = ProcessActive.filter((b) => {
      
                        return b.wld_code === e.value;
                      });
                    
                      let c = a[0];

                      item.WPQ_Process = e.value;
                      item.WPQ_Process_Category = c.wld_p_category
                      
                      if (e.value === "NA") {
                        detailObj[i].WPQ_Process = "";
                        detailObj[i].WPQ_Type = "";
                      }
                   

                      setdetailObj([...detailObj]);
                  
                    }}
                  ></SearchSelect>
                </td>
                <td className="WPQSelect" style={{ paddingLeft: "2%", width: "21%"  }}>
                  <SearchSelect
                    data={TypeData}
                    readOnly={action === Action.ActionCopy ? isReadonly : false}
                    valueField={"wld_type"}
                   
                    disabled={processDisabled(i)}
                    value={item?.WPQ_Type}
                    labelField={"wld_type"}
                    placeholder=""
                    onChange={(e) => {
                      
                      item.WPQ_Type = e.value;
                      setdetailObj([...detailObj]);
                      item.WPQ_Process !== "" || item.WPQ_Type !== ""
                        ? (detailObj[i].error = false)
                        : (detailObj[i].error = true);

                      let a = ProcessActive.filter((b) => {
                          return b.wld_code === item.WPQ_Process;
                        });
                        item.WPQ_Process_Category = a[0]?.wld_p_category + "#" + e.value;
                    }}
                  ></SearchSelect>
                </td>
                <td style={{ width: "39%" }}></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};