import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

import GolbalSearch from "../utility/GolbalSearch";

import { handleExport1 } from "../utility/GlobalExport";
import {  Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import Export from "../../icons/Export.svg";

import "react-toastify/dist/ReactToastify.css";

import Toaster from "../utility/Toaster";
import { QualificationReqApproval } from "./QualificationReqApproval";
import {
  
  QualificationReqInitiated,
} from "./QualificationReqInitiated";
import AddEditQualificationRequisition from "./AddEditQualificationRequisition";
import { getAllDataQCEExport } from "../../Services/trainingRequisition-service";
import { DateDiff, FormatYYYYMMDD, getDefaultDate } from "../../functions/welding-functions";

const QualificationRequisition = forwardRef((props, ref) => {
  const [key, setKey] = useState(1);
  const [refe, setRefe] = useState();
  const [excelData1, setExcelData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [mainReload, setMainReload] = useState(false);
  const [username, setusername] = useState(sessionStorage.getItem("UserName"));

  const [rowData, setRowData] = useState({});
  const Qualificationinit = useRef();
  const Qualificationpend = useRef();
  const [calledFrom, setCalledFrom] = useState("");




  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "wld_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "trainingReqCellRenderer",
      width: 50,
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: "Welder Qualification Req",
      field: "wld_TrainingReqNumber",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Salary/Employee Code",
      field: "wld_TrainingReqSalaryEmployeeCode",
      isCheckbox: false,
      cellRenderer: "",
      width: 50,
    },
    {
      headerName: "Welder Name",
      field: "wld_TrainingReqEmployeeName",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
    {
      headerName: "Welder Process",
      field: "wld_ProcessQualified",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Welder Position",
      field: "wld_PositionQualified1",
      isCheckbox: false,

    },
    {
      headerName: "Qualification Material",
      field: "wld_BaseMaterial",
      isCheckbox: false,
      cellRenderer: "",
    },

    {
      headerName: "Company Name",
      field: "wld_TrainingReqCompanyName",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Welding Date",
      field: "wld_TrainingReqDate",
     
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Approved/Rejected Date",
      field: "wld_ApprovedRejectedQualDate",
     
      isCheckbox: false,
      cellRenderer: "",
      width: 250,
    },
    {
      headerName: "Welder Qualification Pending Since",
      field: "wld_pendingSince",
      isCheckbox: false,
      cellRenderer: "",
      width: 250,
      type:"Number"
    },
    {
      headerName: "Qualification Total Cycle Time",
      field: "wld_CycleTime",
      isCheckbox: false,
      cellRenderer: "",
      width: 50,
      type:"Number"
    },
  ];

  const handleGlobalSearch = (text) => {
    refe.current.setSearch(text);
  };




  const handleGlobalExport = async () => {
debugger

let AllResult



    const allColumnDefs=[]
  
    // Add a column for WPQ_WelderName
    allColumnDefs.push({
      headerName: 'Status',
      field: 'wld_status',
    });

    allColumnDefs.push({
      headerName: 'Welder Qualification Req',
      field: 'wld_TrainingReqNumber',
    });

    allColumnDefs.push({
      headerName: 'Requisition Date',
      field: 'wld_TrainingReqDate',
    });

    allColumnDefs.push({
      headerName: 'Salary/Employee Code',
      field: 'wld_TrainingReqSalaryEmployeeCode',
    });

    allColumnDefs.push({
      headerName: 'Welder Name',
      field: 'wld_TrainingReqEmployeeName',
    });

    allColumnDefs.push({
      headerName: 'Employee Type',
      field: 'wld_TrainingReqEmployeeType',
    });

    allColumnDefs.push({
      headerName: 'Welder Process',
      field: 'wld_ProcessQualified',
    });

    allColumnDefs.push({
      headerName: 'Welder Position',
      field: 'wld_PositionQualified1',
    });

    allColumnDefs.push({
      headerName: 'Qualification Material',
      field: 'wld_BaseMaterial',
    });

    allColumnDefs.push({
      headerName: 'Joint Category',
      field: 'wld_JointCategory',
    });


    allColumnDefs.push({
      headerName: 'Assigning Dept',
      field: 'wld_AssgnDept',
    });

    allColumnDefs.push({
      headerName: 'Godrej Supervisor Name',
      field: 'wld_SupervisorName',
    });

    allColumnDefs.push({
      headerName: 'Godrej Supervisor Date',
      field: 'wld_TrainingReqDate',
    });


    allColumnDefs.push({
      headerName: 'TPI',
      field: 'wld_Tpi',
    });

    allColumnDefs.push({
      headerName: 'Company Name',
      field: 'wld_TrainingReqCompanyName',
    });

    allColumnDefs.push({
      headerName: 'Welding Date',
      field: 'wld_TrainingReqDate',
    });

    allColumnDefs.push({
      headerName: 'Visual Examination',
      field: 'wld_VisualExam',
    });

    allColumnDefs.push({
      headerName: 'Visual Examination Date',
      field: 'wld_VisualExamDate',
    });

    allColumnDefs.push({
      headerName: 'RT Offer Wrench',
      field: 'wld_RTOfferWrench',
    });

    allColumnDefs.push({
      headerName: 'RT Offer Wrench Date',
      field: 'wld_RTOfferWrenchDate',
    });

    allColumnDefs.push({
      headerName: 'NDE Examination',
      field: 'wld_NDEExam',
    });

    allColumnDefs.push({
      headerName: 'NDE Examination Date',
      field: 'wld_NDEExamDate',
    });

    allColumnDefs.push({
      headerName: 'Comments',
      field: 'wld_TrainingReqApproverFinalComment',
    });

    allColumnDefs.push({
      headerName: 'WPQ No',
      field: 'wld_WpqNo',
    });

    allColumnDefs.push({
      headerName: 'Approved/Rejected Date',
      field: 'wld_ApprovedRejectedQualDate',
    });

    allColumnDefs.push({
      headerName: 'Welder Qualification Pending Since',
      field: 'wld_pendingSince',
      type:"Number"

    });

 
  
    let filename = "QualificationRequisitionMaster";

    if(sessionStorage.getItem("Role") === process.env.REACT_APP_ROLE_QCENGINEER){
      AllResult =  await getAllDataQCEExport();
      AllResult.forEach((i) =>{
        i.wld_pendingSince = DateDiff(FormatYYYYMMDD(i.wld_TrainingReqDate),getDefaultDate())
        i.wld_CycleTime = 0
      })
      allColumnDefs.push({
        headerName: 'Welder Qualification Pending Since',
        field: 'wld_pendingSince',
        type:"Number"
  
      });
  
      allColumnDefs.push({
        headerName: 'Qualification Total Cycle Time',
        field: 'wld_CycleTime',
        type:"Number"
  
      });
    
      handleExport1(AllResult, filename, allColumnDefs);
    }
    else{
      handleExport1(excelData1, filename, allColumnDefs);
    }

  
  };

  const handleSelect = (e) => {


    setKey(e);
  };
  useEffect(() => {
    Qualificationinit.current.reload();
    Qualificationpend.current.reload();
  }, [mainReload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setMainReload(!mainReload);
    },
  }));

  function funcclose(boolVar) {
     setMainReload(!mainReload);
  }

  return (
    <>
    <Container fluid>

      <Row className="RowHeightTREQ" style={{position:"relative",top:"5px"}}>
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Welder Qualification Request list
        </Col>
        <Col style={{position:"relative",right:"5px"}}>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for Status, WelderQualification Request No, Salary/Employee Code,Welder Name,etc"
              }
              filterFunc={handleGlobalSearch}
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <Tabs
      style={{position:"relative", top:"10px"}}
        activeKey={key}
        onSelect={(e) => handleSelect(e)}
        className="mb-3 w-100 border-end"
      >
        <Tab
          eventKey={1}
          title="Requests pending for your Approval"
          style={{
            alignItems: "left",
            height: window.innerHeight - 750,
          }}
        >
          <div className="wqrNote">NOTE: To Approve or Reject the Welder Qualification Request, please click on Welder Qualification Request Details Hyperlink</div>
          <div style={{position:"relative", top:"12px",right:"15px"}}>
          <QualificationReqApproval
            tmpcolumnDefs={tmpcolumnDefs}
            setRefe={setRefe}
            key={key}
            close={funcclose}
            setExcelData={setExcelData}
            mainReload={mainReload}
            ref={Qualificationpend}
            showAddEditForm={() => setShowForm(true)}
            setShowForm={setShowForm}
            setAction={setAction}
            setRowData={setRowData}
            setCalledFrom={setCalledFrom}
          />
          </div>
        </Tab>

        <Tab
          eventKey={2}
          title="Requests sent for Approval"
          style={{
            height: window.innerHeight - 750,
          }}
        >
           <div className="wqrNote">NOTE: To Approve or Reject the Welder Qualification Request, please click on Welder Qualification Request Details Hyperlink</div>
          <div style={{position:"relative", top:"12px",right:"15px"}}>
          <QualificationReqInitiated
            username={username}
            tmpcolumnDefs={tmpcolumnDefs}
            setRefe={setRefe}
            setExcelData={setExcelData}
            key={key}
            close={funcclose}
            mainReload={mainReload}
            ref={Qualificationinit}
            setShowForm={setShowForm}
            setAction={setAction}
            setRowData={setRowData}
            setCalledFrom={setCalledFrom}
          />
          </div>
        </Tab>
      </Tabs>

      {showForm && (
        <AddEditQualificationRequisition
          show={showForm}
          action={action}
          onHide={() => {
            setShowForm(false);
          }}
          setMainReload={setMainReload}
          mainReload={mainReload}
          rowData={rowData}
          calledFrom={calledFrom}
        />
      )}
      <Toaster />
      </Container>
    </>
  );
});

export default QualificationRequisition;
