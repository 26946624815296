import { useState, useRef, useEffect } from "react";
import { Row,  Tabs, Tab, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getbyprojectno } from "../../Services/pqr-header-service";
import {
  PQRHeader,
  
  MessageTypes,
} from "./PQRObjects/PQR_entity";
import { ToastContainer, toast } from "react-toastify";
import { Action, Status } from "./PQRObjects/PQR_entity";
import PQR_Page1 from "./PQR_Page1";
import PQR_Page2 from "./PQR_Page2";
import { getdetailsById } from "../../Services/pqr-details-service";
import { Tensile_Test_Obj,Other_Test_Obj,Certification_Obj
  ,GuidedBend_Test_Obj,GuidedBend_Test_Overlay_Obj,Hardness_Test_Groove_Obj 
,Toughness_Test_Obj,createDetailObjPQR,
CertificationLabRef_Obj
} from "./PQRObjects/PQR_common";

import { normalstyle,bluestyle,errorstyle } from "../../functions/welding-functions";
const PQRGrooveTransaction = () => {


  const [key, setKey] = useState(1);

  const firstChildRef = useRef();
  const secondChildRef = useRef();

  const location = useLocation();


  let UserName = sessionStorage.getItem("UserName");
  let UserId = sessionStorage.getItem("UserId");

  const [copydata, setcopydata] = useState("");
  const [action, setaction] = useState(location.state?.action);
  const [originalaction, setoriginalaction] = useState(location.state?.action);
  const [WPStype, setWPStype] = useState(location.state?.WPStype);

  const [daysRemainingList, setDaysRemainingList] = useState([]);

  const [headerObj, setheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : PQRHeader(WPStype)
  );

  const [editheaderObj, seteditheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
    ? location.state?.data
    : PQRHeader(WPStype)
  );

  let Band = sessionStorage.getItem("Band");

  let initiator = Band !== "P" && Band !== "T";



  let readonly =
    location.state?.action === Action.ActionCopy ||
    (initiator && headerObj.WPSH_Status === Status.StatusPending)
      ? true
      : false;

  var disableButton =
    initiator && headerObj.WPSH_Status === Status.StatusPending ? true : false;
  const [isDisabled, setIsDisabled] = useState(disableButton);

  const [isReadonly, setIsReadonly] = useState(readonly);


  const [detailObj, setdetailObj] = useState([]);

  const [prevdetailobj,setprevdetailObj]  = useState([]);
  const [testObj, setTestObj] = useState([]);



  useEffect(() => {
    let temparray = []
    if (action === Action.ActionAdd) {
      let Tensile_Test ;
      let GuidedBend_Test ;
      let Toughness_Test ;
      let Hardness_Test_Groove ;
      let Other_Test ;

      let Certification ;
      let CertificationLabRef ;
      let GuidedBend_Test_Overlay ;
      let Other_testCount= WPStype === "Overlay" ? 10 : 6;
      
       temparray = createDetailObjPQR(1, 4);
      
      Tensile_Test = Tensile_Test_Obj(1, 8);
      GuidedBend_Test = GuidedBend_Test_Obj(1, 4);
      Toughness_Test = Toughness_Test_Obj(1, 6);
      
      Hardness_Test_Groove = Hardness_Test_Groove_Obj(1, 4);
      Certification = Certification_Obj(1, 6);
      CertificationLabRef = CertificationLabRef_Obj(1, 4);
      Other_Test = Other_Test_Obj(1, Other_testCount);

      GuidedBend_Test_Overlay = GuidedBend_Test_Overlay_Obj(1, 8);
      setdetailObj(temparray);

     
      
      setheaderObj({
        ...headerObj,
        Tensile_Test,
        GuidedBend_Test,
        Toughness_Test,
        Hardness_Test_Groove,
        Other_Test,
        // Other_Test_overlay,
        CertificationLabRef,
        Certification,
        GuidedBend_Test_Overlay,
      });
    }
    else{
      let IdprojectPK = location.state?.data.PK;
      let IdprojectSK = location.state?.data.PQR_RecordNo;


      let process = location.state?.data.detail_SK
      let abc = process[0].split("#")
      let pqrprocess = abc[0]
      let pqrtype = abc[1]

      let IdPK = pqrprocess;
      let IdSK = pqrtype;

      fetchprojectbyid(IdprojectPK, IdprojectSK);


       fetchbyid(IdprojectPK, IdprojectSK, IdPK, IdSK);

      
      let Tensile_Test = editheaderObj.Tensile_Test == undefined ? Tensile_Test_Obj(1, 8) :headerObj.Tensile_Test ;
      let GuidedBend_Test = editheaderObj.GuidedBend_Test == undefined ?  GuidedBend_Test_Obj(1, 4) : headerObj.GuidedBend_Test;
      let Toughness_Test = editheaderObj.Toughness_Test == undefined ? Toughness_Test_Obj(1, 6) : headerObj.Toughness_Test;
     
      let Hardness_Test_Groove = editheaderObj.Hardness_Test_Groove == undefined ?  Hardness_Test_Groove_Obj(1, 4) : headerObj.Hardness_Test_Groove ;
      let othertest_count = WPStype === "Overlay" ? 10 : 6
      let Other_Test = editheaderObj.Other_Test == undefined ? Other_Test_Obj(1, othertest_count) :headerObj.Other_Test ;
      // let Other_Test_overlay = editheaderObj.Other_Test_overlay == undefined ? Other_Test_overlay_Obj(1, 10) :headerObj.Other_Test_overlay ;
      let Certification = editheaderObj.Certification == undefined ? Certification_Obj(1, 6) :headerObj.Certification ;
      let CertificationLabRef = editheaderObj.CertificationLabRef == undefined ? CertificationLabRef_Obj(1, 4) :headerObj.CertificationLabRef ;
      let GuidedBend_Test_Overlay = editheaderObj.GuidedBend_Test_Overlay == undefined ?  GuidedBend_Test_Overlay_Obj(1, 8) : headerObj.GuidedBend_Test_Overlay;
    
      editheaderObj.Tensile_Test = Tensile_Test 
      editheaderObj.GuidedBend_Test = GuidedBend_Test
      editheaderObj.Toughness_Test = Toughness_Test
      editheaderObj.Hardness_Test_Groove = Hardness_Test_Groove
      editheaderObj.Other_Test  = Other_Test
      // editheaderObj.Other_Test_overlay  = Other_Test_overlay
      editheaderObj.Certification  = Certification
      editheaderObj.CertificationLabRef  = CertificationLabRef
      editheaderObj.GuidedBend_Test_Overlay  = GuidedBend_Test_Overlay

    
      setheaderObj(editheaderObj);
      

    }

   
  }, []);

 function  CreatePreviousProcess(detail) {
    let oldval = []
    debugger
    detail?.forEach(process =>{

      let prc=  {Process :process.PQR_Process, Type: process.PQR_Type}
      oldval.push(prc)
     })

     debugger
    setprevdetailObj(oldval)
  }

  const [logObj, setlogObj] = useState({
    WPSH_Project_No_log:
      headerObj !== undefined ? headerObj.WPSH_Project_No : "",
    WPSH_WPS_No_log: headerObj !== undefined ? headerObj.WPSH_WPS_No : "",
    WPSH_User_Name_log: UserName,
    WPSH_User_Id_log: UserId,
    WPSH_Status_log: "",
  });



  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    let reqParam = {
      groovetype: WPStype,
      pkid: IdprojectPK,
      skid: IdprojectSK,
    };
    const result = await getbyprojectno(reqParam);
    if (result !== undefined && result[0] !== undefined) seteditheaderObj(headerObj);
  };

  const fetchbyid = async (IddetailPK, IddetailSK, IdPK, IdSK) => {
    
    let reqDetailParam = {
      id1: WPStype,
      id2: IddetailSK,
    };
    const result = await getdetailsById(reqDetailParam);


    let temparray1 = [];
    debugger
    if (action !== Action.ActionAdd) {
     
      var len = result.length;
      var loop = 5 - len;
      
      if (loop > 0) {
        temparray1 = createDetailObjPQR(len + 1, 4);
       setdetailObj([...result, ...temparray1]);
       temparray1=[...result, ...temparray1]
      } else {
        temparray1=result
        setdetailObj([...result]);
      }

    }
    
    CreatePreviousProcess(temparray1)

  };

  useEffect(() => {
    
   
    if (action === Action.ActionCopy) {
      setcopydata(true);
    }
  }, []);

  const next = async (e) => {
    var res = false;
    var val = false;
    
    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNext();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNext2();
        break;
      default:
        break;
    }

    if (res === MessageTypes.Success) {
      toast.success("PQR Data Added ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (key !== 2) setKey(+key + 1);
    } else if (res === MessageTypes.Fail) {
      toast.warning("Failed, PQR Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.RequiredAll) {
      toast.warning(
        "Required Fields not filled.Please check and Fill Required Fields for all 2 Pages.",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning(process.env.REACT_APP_SPECIAL_CHARACTER_MSG, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Band) {
      toast.warning(
        "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  const handleSelect = (e) => {
    Temp(e, "tab");
  };
  const Temp = async (e, tabaction) => {
    var res = false;
    var val = false;
    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNextTemp();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNextTemp2();
        break;
      default:
        break;
    }

    if (res === MessageTypes.Temp) {
      toast.success("Temporary Saved PQR Data Successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (tabaction === "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction === "next") {
        if (key !== 2) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res === MessageTypes.None) {
      if (tabaction === "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction === "next") {
        if (key !== 2) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res === MessageTypes.Fail) {
      toast.warning("Failed, PQR Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning(process.env.REACT_APP_SPECIAL_CHARACTER_MSG, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Band) {
      toast.warning(
        " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  return (
    <>
      <div className="position-absolute ms-3" style={{ top: "15px" }}>
        <strong>Add New</strong>
      </div>

      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <div className="mt-4">
        <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div>
        <Tabs
          activeKey={key}
          onSelect={(e) => handleSelect(e)}
          className="mb-2 w-100 border-end"
          fill
        >
          <Tab
            eventKey={1}
            title= {WPStype === "Groove" ? ( "PQR-Page 1 (Weld Groove)") : ("PQR-Page 1 (Weld Overlay)")}
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "auto",
              width: window.innerWidth - 50,
              
            }}
          >
            <PQR_Page1
              ref={firstChildRef}
              headerObj={headerObj}
              daysRemainingList={daysRemainingList}
              setDaysRemainingList={setDaysRemainingList}
              setheaderObj={setheaderObj}
              createDetailObjPQR={createDetailObjPQR}
              detailObj={detailObj}
              prevdetailobj={prevdetailobj}
              setprevdetailObj={setprevdetailObj}
              setdetailObj={setdetailObj}
              normalstyle={normalstyle}
              errorstyle={errorstyle}
              blyestyle={bluestyle}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          <Tab
            eventKey={2}
            title="PQR-Page 2"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action === Action.ActionAdd ? true : false}
          >
            <PQR_Page2
              ref={secondChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              testObj={testObj}
              setTestObj={setTestObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
        </Tabs>

        <Row style={{ padding: "5px 90px" }}>
          <div>
            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary"
                className="me-2"
                disabled={isDisabled}
                style={{ display: key === 1 ? "none" : "  " ,    width: "100px",
                height: "38px",}}
                onClick={(e) => Temp(e, "prev")}
              >
                Prev
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                className="float-end  me-2 btn-primary"
                style={{
                  width: "100px",
                  height: "38px",
                }}
                onClick={(e) => next(e)}
              >
                {key === 2 ? "Done" : "Next"}
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : originalaction !== Action.ActionAdd ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary "
                className="float-end  me-2"
                onClick={(e) => Temp(e, "next")}
                style={{ width: "100px", height: "38px" }}

              >
                {key === 2 ? "Temp Save" : "Temp Save"}
              </Button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default PQRGrooveTransaction;
