import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { useLocation, useNavigate } from "react-router-dom";

import GolbalSearch from "../../utility/GolbalSearch";
import { handleExport1 } from "../../utility/GlobalExport";
import { Button, Row, Col, Container, Form } from "react-bootstrap";
import Export from "../../../icons/Export.svg";


import GlobalColumnDefinations,{ getHeader_FieldName } from "../../utility/AgGridDefination";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../../utility/GridMaster";
import Toaster from "../../utility/Toaster";
import "ag-grid-community/styles/ag-grid.css";
import ActionRenderer_inlinEdit from "../../utility/ActionRenderer_inlinEdit";
import {
  getTrainingRecordbyID,
 
} from "../../../Services/trainingRequisition-service";
import { updateTrainingScore } from "../../../Services/training-service";
const TrainingDetails = forwardRef((props, ref) => {
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);

  const [excelData1, setExcelData] = useState([]);

  const [gridApi, setgridAPI] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const childRef = useRef();

  const location = useLocation();

  const [train, setTrain] = useState({
    wld_ScoreUpdate:
      props.wld_ScoreUpdate === undefined ? props.wld_ScoreUpdate : true,
  });

  const navigate = useNavigate();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/weldingmaster/TrainingRecord");
  };

  const tmpcolumnDefs = [
    {
      headerName: "Trainee Name",
      field: "wld_TrainingReqEmployeeName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Salcode",
      field: "wld_TrainingReqSalaryEmployeeCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Requester Department",
      field: "wld_TrainingReqRequesterDepartment",
      type: "text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Attendance",
      field: "wld_attendance",
      type: "Attendance",
      isCheckbox: false,
      editable: true,

      cellEditor: "agSelectCellEditor",
      Error: true,
    },
    {
      headerName: "Score",
      field: "wld_score",
      type: "Text",
      isCheckbox: false,
      editable: true,
      Error: true,
    },
    {
      headerName: "Total Marks",
      field: "wld_marks",
      type: "Text",
      isCheckbox: false,
      editable: true,
      Error: true,
    },
    {
      headerName: "Percentage",
      field: "wld_percentage",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      valueGetter: (params) => {
        return (params.data.wld_score / params.data.wld_marks) * 100;
      },
    },
  ];


  
  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchTraining = async () => {
    const result = await getTrainingRecordbyID(location.state?.data.SK);
    if (result !== undefined && result !== "undefined" && result.length > 0) {
      for (let i = 0; i < result.length; i++) {
        result[i].isModified = false;
        result[i].hasError = false;
        result[i].ErrorMessage = "";
      }
    }
    setFilteredResult(result);
  };

  const fetchColumnDefns = async () => {
    const result1 = GlobalColumnDefinations(tmpcolumnDefs);
    let result = [];
    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      minWidth: 5,
      Error: true,
      suppressMenu: true,
      sortable:false,
    });
    result1.forEach((e) => {
      result.push(e);
    });

    setColumnDefs(result);
  };

  useEffect(() => {
    fetchTraining();
    fetchColumnDefns();
  }, []);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));

 
  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text);
  };
  const handleGlobalExport = () => {
    let filename = "TrainingMaster";
    setExcelData(filteredResult);
     handleExport1(filteredResult, filename, tmpcolumnDefs);
  };

  const actionRenderer = (params) => {
    setgridAPI(params);
    return (
      <ActionRenderer_inlinEdit
        selectedData={params}
        delete={false}
        cancel={false}
        edit={false}
     
      />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      let hasValidationErrors = false;
      const updatedResult = filteredResult.map((item) => {
        var score = parseFloat(item.wld_score);
        var marks = parseFloat(item.wld_marks);
        var attendance = item.wld_attendance;
       
        if (!isNaN(score)) item.isModified = true;
        if (isNaN(marks)) item.isModified = true;
        if (attendance !== undefined || attendance !== "")
          item.isModified = true;
        item.hasError = false;
        item.ErrorMessage = "";




        if (attendance === "Absent") {
          item.wld_score = "0";
          item.wld_marks = "0";
          item.isModified = true;
        }

        if ((isNaN(score) || score < 0) && item.isModified) {
          hasValidationErrors = true;
          item.hasError = true;
          item.ErrorMessage += "Score should be an Integer";

        }

        if ((isNaN(marks) || marks < 0 || marks < score) && item.isModified) {
          hasValidationErrors = true;

          item.hasError = true;
          item.ErrorMessage += "Marks should be an Integer";
        }


        const percentage =
          marks === 0 || score === 0 || marks === "0" || score === "0"
            ? 0
            : (score / marks) * 100;
        item.wld_ScoreUpdate = train.wld_ScoreUpdate;
        item.wld_percentage = percentage;
      });

      if (!hasValidationErrors) {
        setShowConfirmModal(true);
      } else {
        gridApi.api.applyTransaction({ update: filteredResult });
      }
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data");
    }
  };

  const handleConfirmAction = async () => {
    try {
      

      let notupdated = filteredResult.filter(
        (i) =>
          i.wld_score === undefined ||
          i.wld_attendance === "" ||
          i.wld_marks === undefined
      );
      let ScoreUpdate = true;

      if (notupdated.length !== 0) ScoreUpdate = false;
      let tmpObj = {
        SK: location.state?.data.SK,
        wld_scoreupdated: ScoreUpdate,
      };
      let updatedScore = await updateTrainingScore(tmpObj);
      toast.success("Data updated successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }

    setShowConfirmModal(false);
    setReload(true);
  };

  const handleCancelAction = () => {
    setShowConfirmModal(false);
  };

  const gridOptions = {
  };


  const statusChange2 = () => {
    setTrain((prevState) => ({
      ...prevState,
      wld_ScoreUpdate: !prevState.wld_ScoreUpdate,
    }));
  };

 

  return (
    <>
      <div className="title1"> Training Details </div>
      <Container fluid>
        <ConfirmAlertModal
          action={"Add"}
          handleYes={handleConfirmAction}
          handleNo={handleCancelAction}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          formName={"Trainee Details"}
        />
        <Row>
          <Col md={2} sm={12} xs={12} className="ListTitle">
            Training : {location.state?.data.wld_trainingSub}
          </Col>
          <Col>
            <div className="global-search-container">
              <GolbalSearch
                searchplaceholdertext={"Search for Training subject, Faculty"}
                filterFunc={handleGlobalSearch}
              />
              <img
                className="allexport"
                src={Export}
                alt="Export"
                onClick={handleGlobalExport}
              />
            </div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Col>
            <Form.Group className="form-switch-input">
              <Row></Row>
            </Form.Group>
          </Col>
          <GridMaster
            rowDetails={filteredResult}
            colDetails={columnDefs}
            defaultColDef={{ resizable: true }}
            fieldNames={fieldName}
            headerNames={headerName}
          
            setExcelData={setExcelData}
            ref={childRef}
            gridOptions={gridOptions}
            paginationPageSize={5}
            height={400}
          />
        </Form>

        <Toaster />
      </Container>
      <Row style={{position:"relative", top:"15px"}}>
        <Col>
          <div >
            <Button
              type="submit"
              className="trainingsavebtn"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <button onClick={handleBackClick} className="trainingcancelbtn">
              Cancel
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default TrainingDetails;
