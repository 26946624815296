
import React from "react";

export const YearDropDown = ({selectedyear,onChange})=> {

  selectedyear = selectedyear == "" ? new Date().getFullYear() : selectedyear
 // const[yearsarr,setyearsarr] = useState([])

 const years = (()=>{
    var year =  parseInt(process.env.REACT_APP_START_YEAR);

    var yearsarr =  Array.from(new Array(20),( val, index) => index + year);
    return yearsarr.map((i)=>{
      return (
        <option value={i}>
          {i}
        </option>
      )
    })

   
   })

    return(
        <div  align="right" style={{align:"right",border: "none"}}>
            <select
      // styles={customstyles}
     
   
      //styles={props.stylegreen}
      required
      value={selectedyear}
      
      style={{width:"9vw",align:"right", height:"32px", borderColor:"var(--col-ddd)",borderRadius:"3px"}}
      onChange={(opt) => {

        onChange(opt.target);
      }}
      // className={props.className}
      >
        
        {years()}
      </select>
        </div>
        )
}