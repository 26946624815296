

import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";


import { Welder_Record_tableHeader,tableHeader } from "./Excel_Headers";


export async function ExportToExcel(fileName,colwidth, excelData,Data,titleCell,titleWelderRange,tbodyHeadRange,tbodyRange,blankRow,Headings) {


  let wb = XLSX.utils.book_new();  

 
  let data = [...tableHeader, ...Data]

  let alldata = [''].concat([''])
  .concat([''])    
    .concat([''])
    .concat([''])
    .concat(data);


  let ws = XLSX.utils.json_to_sheet(alldata, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "List of Welders report");


  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  const dataInfo1 = {
    titleCell: titleCell,
    titleWelderRange: titleWelderRange,
    tbodyHeadRange: tbodyHeadRange, 
    tbodyRange: `${tbodyRange}:L${alldata.length}`,
    blankRow: blankRow, 
  };

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];    

    sheet.row(5).style('wrapText', true);    
    
    sheet.gridLinesVisible(false);

    sheet.freezePanes(0, 2);

     for(let i=0;i<colwidth.length;i++){

      sheet.column(colwidth[i].col).width(colwidth[i].width)
    } 

       for(let i=0;i<Headings.length;i++){
    
     sheet.cell(Headings[i].col).value(Headings[i].value)
   } 

    sheet.range(dataInfo1.titleWelderRange).style({            
      bold:true ,
      underline: true
    })

    sheet.range(dataInfo1.tbodyHeadRange).style({
      bold:true,
      horizontalAlignment:'center',
      border: "thin",      
    })
    sheet.range(dataInfo1.tbodyRange).style({           
      border: "thin",      
    })
 
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
    
  });
  

}


export async function ExportToExcel_Welder_Record(fileName,colwidth, excelData,Data,titleCell,titleWelderRange,tbodyHeadRange,tbodyRange,blankRow,Headings,imageBase64) {




  let wb = XLSX.utils.book_new();  



  let data = [...Welder_Record_tableHeader, ...Data]

  let alldata = [''].concat([''])
  .concat([''])    
    .concat([''])
    .concat([''])
    .concat(data);


  let ws = XLSX.utils.json_to_sheet(alldata, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "List of Welders report");


  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });



  const dataInfo1 = {
    titleCell: titleCell,
    titleWelderRange: titleWelderRange,
    tbodyHeadRange: tbodyHeadRange, 
    tbodyRange: `${tbodyRange}:F${alldata.length}`,
    blankRow: blankRow, 
  };

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];    

    sheet.row(5).style('wrapText', true);    
    
    sheet.gridLinesVisible(false);

    sheet.freezePanes(0, 2);
     for(let i=0;i<colwidth.length;i++){
      sheet.column(colwidth[i].col).width(colwidth[i].width)
    } 

 
    for(let i=0;i<Headings.length;i++){
     sheet.cell(Headings[i].col).value(Headings[i].value)
     if(Headings[i].col === "A1")
     {
      sheet.cell(Headings[i].col).style({bold : true})
     }
     if(Headings[i].col === "D1")
     {
      sheet.cell(Headings[i].col).style({italic : true})
     }
     if(Headings[i].col === "D2")
     {
      sheet.cell(Headings[i].col).style({italic : true})
     }
   } 

    sheet.range(dataInfo1.titleWelderRange).style({            
      bold:true ,
    })

    sheet.range(dataInfo1.tbodyHeadRange).style({
      bold:true,
      horizontalAlignment:'center',
      border: "thin", 
      fontSize: 16,
    })
    sheet.range(dataInfo1.tbodyRange).style({           
      border: "thin",      
    })

    
 
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
    
  });
  

}





const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);
  //create a 8 bit integer array
  const view = new Uint8Array(buf);
  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};