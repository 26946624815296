const FillerMetal = ({pdf}) =>{
    
  
  function FillMetals_Processes() {
    let rows = [];

    for (let i = 1; i <= 3; i++) {
      if (pdf[i] != undefined) {
        let split = pdf[i]?.WPSD_ProcessCategory.split("#")
        let procat = split[0]

        rows.push(
          <tr>
            <th colspan="2" className="WPSleft-th-nobottom">
              {procat}
            </th>
            <td className="WPSleft-td-nobottom">{pdf[i]?.WPSD_FM_SFA}</td>

            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none" }}>
              {pdf[i]?.WPSD_FM_Classification === undefined ||
              pdf[i]?.WPSD_FM_Classification === ""
                ? "--"
                : pdf[i]?.WPSD_FM_Classification}
            </td>
            {pdf[0].wld_WPSType == "Overlay" ? (
              <td
                className="WPSleft-td-nobottom"
                style={{ borderLeft: "none" }}
              >
                {pdf[i]?.WPSH_WeldLayer}
              </td>
            ) : (
              ""
            )}
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none" }}>
              {pdf[i]?.WPSD_FM_FNo}
            </td>
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none" }}>
              {pdf[i]?.WPSD_FM_Ano === "" || pdf[i]?.WPSD_FM_Ano === undefined
                ? "--"
                : pdf[i]?.WPSD_FM_Ano}
            </td>
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none" }}>
              {pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName === "" ||
              pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName === undefined
                ? "--"
                : pdf[i]?.WPSD_FM_ChemicalAnalysis_TradeName}
            </td>
            <>

            {pdf[0]?.wld_TRQ_Type === "Welded" ? 
            (
              <>
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none", textAlign:"center" }}>
              {pdf[0]?.wld_TRQ_Type === "Welded"
                ? pdf[i]?.WPSD_FM_TRQ_Min === "" ||
                  pdf[i]?.WPSD_FM_TRQ_Min === undefined
                  ? "--"
                  : pdf[i]?.WPSD_FM_TRQ_Min
                : "--"}
            </td>
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none", textAlign:"center"}}>
              {pdf[0]?.wld_TRQ_Type === "Welded"
                ? pdf[i]?.WPSD_FM_TRQ_Max === "" ||
                  pdf[i]?.WPSD_FM_TRQ_Max === undefined
                  ? "--"
                  : pdf[i]?.WPSD_FM_TRQ_Max
                : "--"}
            </td>
            </>
            ):(
              <>
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none",textAlign:"center" }}>
              {pdf[0]?.wld_TRQ_Type === "PWHT"
                ? pdf[i]?.WPSD_FM_TRQ_Min === "" ||
                  pdf[i]?.WPSD_FM_TRQ_Min === undefined
                  ? "--"
                  : pdf[i]?.WPSD_FM_TRQ_Min
                : "--"}
            </td>
            <td className="WPSleft-td-nobottom" style={{ borderLeft: "none",textAlign:"center"  }}>
              {pdf[0]?.wld_TRQ_Type === "PWHT"
                ? pdf[i]?.WPSD_FM_TRQ_Max === "" ||
                  pdf[i]?.WPSD_FM_TRQ_Max === undefined
                  ? "--"
                  : pdf[i]?.WPSD_FM_TRQ_Max
                : "--"}
            </td>
            </>
            )
            }
             </>

          </tr>
        );
      }
    }
    return <>{rows}</>;
  }


return(<table id="table" className="WPSTable6">
<tbody>
  <tr>
    <th
      colspan="2"
      rowSpan="2"
      className="WPSnoborder widthth3table"
      style={{
        borderBottom: ".1px solid black",
        backgroundColor: "white",
      }}
    ></th>
    <th
      className="WPSleft-th"
      rowSpan="2"
      style={{ width: "8%", borderLeft: ".1px solid black" }}
    >
      SFA
    </th>
    <th
      className="WPSleft-th"
      rowSpan="2"
      style={{ width: "16%", borderRight: ".1px solid black" }}
    >
      Classification
    </th>
    {pdf[0]?.wld_WPSType == "Overlay" ? (
      <th
        className="WPSleft-th"
        rowSpan="2"
        style={{ width: "12%" }}
      >
        Weld Layer
      </th>
    ) : (
      ""
    )}
    <th
      className="WPSleft-th"
      rowSpan="2"
      style={{ width: "4.5%" }}
    >
      F-no
    </th>
    <th
      className="WPSleft-th"
      rowSpan="2"
      style={{ width: "4.5%" }}
    >
      A-no
    </th>
    <th className="WPSleft-th" rowSpan="2" style={{ width: "35%" }}>
      Chemical Analysis or Trade name
    </th>
    {pdf[0]?.wld_TRQ_Type === "Welded" ? 

    <th
      className="WPSleft-th-nobottom"
      colspan="2"
      style={{
        width: "30%",
        textAlign: "center",
        borderLeft: "none",
        borderTop: "1px solid black",
        borderRight: "1px solid black",
      }}
    >
      As-Welded
    </th>
 :

    <th
      className="WPSleft-th-nobottom"
      colspan="2"
      style={{
        width: "30%",
        textAlign: "center",
        borderLeft: "0px solid black",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
      }}
    >
      With PWHT
    </th>
}
  </tr>
  <tr>
  {pdf[0]?.wld_TRQ_Type === "Welded" ? 
<>
    <th
      className="WPSleft-th-nobottomnoright"
      style={{ textAlign: "center", borderLeft: "none",}}
    >
      Min
    </th>
    <th
      className="WPSleft-th-notopnoleft"
      style={{ textAlign: "center", borderRight: "1psx solid black" }}
    >
      Max
    </th>
    </>
    :
<>
    <th
      className="WPSleft-th-nobottomnoright"
      style={{
        textAlign: "center",
        borderRight: "none",
        borderLeft: "1px solid black",
      }}
    >
      Min
    </th>
    <th
      className="WPSleft-th-notopnoleft"
      style={{
        textAlign: "center",
        borderRight: "1px solid black",
        borderLeft: "none",
      }}
    >
      Max
    </th>
    </>
}
  </tr>

  {FillMetals_Processes()}

  <tr>
    <th colspan="2" className="WPSleft-th-nobottomflux">
      Flux
    </th>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderTop: "1px solid black" }}
    >
      {pdf[0]?.WPSH_Flux_SFA === "" ||
      pdf[0]?.WPSH_Flux_SFA == undefined
        ? "--"
        : pdf[0]?.WPSH_Flux_SFA}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
      // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""}
    >
      {pdf[0]?.WPSH_Flux_Classification === "" ||
      pdf[0]?.WPSH_Flux_Classification == undefined
        ? "--"
        : pdf[0]?.WPSH_Flux_Classification}
    </td>
    {/* // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""} */}
    {pdf[0].wld_WPSType === "Overlay" && (
      <>
        <td
          className="WPSleft-td-nobottomtop"
          style={{
            borderLeft: "none",
            borderTop: "1px solid black",
          }}
          // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""}
        >
          {pdf[0]?.WPSH_Flux_Classification === "" ||
          pdf[0]?.WPSH_Flux_Classification == undefined
            ? "--"
            : "--"}
        </td>
      </>
    )}
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
    >
      {pdf[0]?.WPSH_Flux_FNo === "" ||
      pdf[0]?.WPSH_Flux_FNo == undefined
        ? "--"
        : pdf[0]?.WPSH_Flux_ANo}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
    >
      {pdf[0]?.WPSH_Flux_ANo === "" ||
      pdf[0]?.WPSH_Flux_ANo == undefined
        ? "--"
        : pdf[0]?.WPSH_Flux_FNo}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
    >
      {pdf[0]?.WPSH_Flux_ChemicalAnalysis_TradeName === "" ||
      pdf[0]?.WPSH_Flux_ChemicalAnalysis_TradeName == undefined
        ? "--"
        : pdf[0]?.WPSH_Flux_ChemicalAnalysis_TradeName}
    </td>
    {pdf[0]?.wld_TRQ_Type === "Welded" ? 
<>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "Welded"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Min
          "--"
        : "--"}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "Welded"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Max
          "--"
        : "--"}
    </td>
    </>
    :
    <>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "PWHT"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Min
          "--"
        : "--"}{" "}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "1px solid black" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "PWHT"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Max
          "--"
        : "--"}{" "}
    </td>
    </>
}
  </tr>
  {pdf[0]?.wld_WPSType !== "Overlay" ? (
 <><tr>
    <th colspan="2" className="WPSleft-th-nobottom">
        Cons. Insert
      </th><td className="WPSleft-td-nobottom">
          {pdf[0]?.WPSH_ConsuInsert_SFA === "" ||
            pdf[0]?.WPSH_ConsuInsert_SFA == undefined
            ? "--"
            : pdf[0]?.WPSH_ConsuInsert_SFA}
        </td>
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
      // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""}
    >
      {pdf[0]?.WPSH_ConsuInsert_Classification === "" ||
      pdf[0]?.WPSH_ConsuInsert_Classification === undefined
        ? "--"
        : pdf[0]?.WPSH_ConsuInsert_Classification}
    </td>
    {pdf[0].wld_WPSType === "Overlay" && (
      <>
        <td
          className="WPSleft-td-nobottomtop"
          style={{ borderLeft: "none", borderTop: "none" }}
          // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""}
        >
          {pdf[0]?.WPSH_Flux_Classification === "" ||
          pdf[0]?.WPSH_Flux_Classification == undefined
            ? "--"
            : "--"}
        </td>
      </>
    )}
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
    >
      {pdf[0]?.WPSH_ConsuInsert_FNo === "" ||
      pdf[0]?.WPSH_ConsuInsert_FNo == undefined
        ? "--"
        : pdf[0]?.WPSH_ConsuInsert_FNo}
    </td>
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
    >
      {pdf[0]?.WPSH_ConsuInsert_ANo === "" ||
      pdf[0]?.WPSH_ConsuInsert_ANo == undefined
        ? "--"
        : pdf[0]?.WPSH_ConsuInsert_ANo}
    </td>
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
    >
      {pdf[0]?.WPSH_ConsuInsert_ChemicalAnalysis_TradeName === "" ||
      pdf[0]?.WPSH_ConsuInsert_ChemicalAnalysis_TradeName ==
        undefined
        ? "--"
        : pdf[0]?.WPSH_ConsuInsert_ChemicalAnalysis_TradeName}
    </td>
    {pdf[0]?.wld_TRQ_Type === "Welded" ? 
<>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "Welded"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Min
          "--"
        : "--"}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "Welded"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Max
          "--"
        : "--"}
    </td>
    </>
    :
    <>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "PWHT"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Min
          "--"
        : "--"}{" "}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "PWHT"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Max
          "--"
        : "--"}{" "}
    </td>
    </>
    }
  </tr></> ):("")}
  <tr> 
    <th className="WPSleft-th-nobottom" colSpan="2">
      Sup. Filter
    </th>
    <td className="WPSleft-td-nobottom">
      {pdf[0]?.WPSH_SupFiller_SFA === "" ||
      pdf[0]?.WPSH_SupFiller_SFA == undefined
        ? "--"
        : pdf[0]?.WPSH_SupFiller_SFA}
    </td>
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
      // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""}
    >
      {pdf[0]?.WPSH_SupFiller_Classification === "" ||
      pdf[0]?.WPSH_SupFiller_Classification == undefined
        ? "--"
        : pdf[0]?.WPSH_SupFiller_Classification}
    </td>
    {pdf[0].wld_WPSType === "Overlay" && (
      <>
        <td
          className="WPSleft-td-nobottomtop"
          style={{ borderLeft: "none", borderTop: "none" }}
          // colspan={pdf[0].wld_WPSType == "Overlay" ? "2" : ""}
        >
          {pdf[0]?.WPSH_Flux_Classification === "" ||
          pdf[0]?.WPSH_Flux_Classification == undefined
            ? "--"
            : "--"}
        </td>
      </>
    )}
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
    >
      {pdf[0]?.WPSH_SupFiller_FNo === "" ||
      pdf[0]?.WPSH_SupFiller_FNo == undefined
        ? "--"
        : pdf[0]?.WPSH_SupFiller_FNo}
    </td>
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
    >
      {pdf[0]?.WPSH_SupFiller_ANo === "" ||
      pdf[0]?.WPSH_SupFiller_ANo == undefined
        ? "--"
        : pdf[0]?.WPSH_SupFiller_ANo}
    </td>
    <td
      className="WPSleft-td-nobottom"
      style={{ borderLeft: "none" }}
    >
      {pdf[0]?.WPSH_SupFiller_ChemicalAnalysis_TradeName === "" ||
      pdf[0]?.WPSH_SupFiller_ChemicalAnalysis_TradeName ==
        undefined ||
      pdf[0]?.WPSH_SupFiller_ChemicalAnalysis_TradeName == undefined
        ? "--"
        : pdf[0]?.WPSH_SupFiller_ChemicalAnalysis_TradeName}
    </td>
    {pdf[0]?.wld_TRQ_Type === "Welded" ? 
<>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "Welded"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Min
          "--"
        : "--"}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "Welded"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Max
          "--"
        : "--"}
    </td>
    </>
    :
    <>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "PWHT"
        ? // ? pdf[1]?.WPSD_FM_TRQ_Min
          "--"
        : "--"}{" "}
    </td>
    <td
      className="WPSleft-td-nobottomtop"
      style={{ borderLeft: "none", borderTop: "none" }}
      // colspan="4"
    >
      {pdf[0]?.wld_TRQ_Type === "PWHT" ? "--" : "--"}{" "}
    </td>
    </>
}
  </tr>

  {pdf[0]?.wld_WPSType === "Overlay" ? (
    <>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="2"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "none",
          }}
        >
          Flux Type
        </th>
        <td
          style={{ borderBottom: "none" }}
          className="WPSleft-td"
          colspan="5"
        >
          {pdf[0]?.WPSH_FM_FluxType}
        </td>

        <th className="WPSleft-th" style={{ borderBottom: "none" }}>
          Flux from recrush slag
        </th>

        <td
          className="WPSleft-td"
          style={{ borderLeft: "none", borderBottom: "none" }}
          colspan="4"
        >
          {pdf[0]?.WPSH_FM_FluxFromRecrush}
        </td>
      </tr>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="2"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "none",
          }}
        >
          Alloy Element
        </th>
        <td
          style={{ borderBottom: "none" }}
          className="WPSleft-td"
          colspan="5"
        >
          {pdf[0]?.WPSH_FM_AlloyElement}
        </td>

        <th className="WPSleft-th" style={{ borderBottom: "none" }}>
          Add/Del or Chg {">"} 10% in suppl. FM
        </th>

        <td
          className="WPSleft-td"
          style={{ borderLeft: "none", borderBottom: "none" }}
          colspan="4"
        >
          {pdf[0]?.WPSH_FM_Add_Del_orchange}
        </td>
      </tr>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="2"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          Nominal Flux
        </th>
        <td
          style={{ borderBottom: "1px solid black" }}
          className="WPSleft-td"
          colspan="5"
        >
          {pdf[0]?.WPSH_FM_NominalFlux}
        </td>

        <th
          className="WPSleft-th"
          style={{ borderBottom: "1px solid black" }}
        >
          Filler Metal Others
        </th>

        <td
          className="WPSleft-td"
          style={{
            borderLeft: "none",
            borderBottom: "1px solid black",
          }}
          colspan="4"
        >
          {pdf[0]?.WPSH_FM_FilerMetal}
        </td>
      </tr>
    </>
  ) : (
    <>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="3"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "none",
          }}
        >
          Flux Type
        </th>
        <td
          style={{ borderBottom: "none" }}
          className="WPSleft-td"
          colspan={pdf[0]?.wld_WPSType == "Overlay" ? "6" : "3"}
        >
          {pdf[0]?.WPSH_FM_FluxType}
        </td>

        <th className="WPSleft-th" style={{ borderBottom: "none" }}>
          Flux from recrush slag
        </th>

        <td
          className="WPSleft-td"
          style={{ borderLeft: "none", borderBottom: "none" }}
          colspan="4"
        >
          {pdf[0]?.WPSH_FM_FluxFromRecrush}
        </td>
      </tr>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="3"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "none",
          }}
        >
          Alloy Flux
        </th>
        <td
          style={{ borderBottom: "none" }}
          className="WPSleft-td"
          colspan={pdf[0]?.wld_WPSType == "Overlay" ? "6" : "3"}
        >
          {pdf[0]?.WPSH_FM_AlloyFlux}
        </td>

        <th className="WPSleft-th" style={{ borderBottom: "none" }}>
          Alloy Element{" "}
        </th>

        <td
          className="WPSleft-td"
          style={{ borderLeft: "none", borderBottom: "none" }}
          colspan="4"
        >
          {pdf[0]?.WPSH_FM_AlloyElement}
        </td>
      </tr>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="3"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "none",
          }}
        >
          Add. of Filler
        </th>
        <td
          style={{ borderBottom: "none" }}
          className="WPSleft-td"
          colspan={pdf[0]?.wld_WPSType == "Overlay" ? "6" : "3"}
        >
          {pdf[0]?.WPSH_FM_Add_Del_Filler}
        </td>

        <th className="WPSleft-th" style={{ borderBottom: "none" }}>
          Change in electrode dia.{" "}
        </th>

        <td
          className="WPSleft-td"
          style={{ borderLeft: "none", borderBottom: "none" }}
          colspan="4"
        >
          {pdf[0]?.WPSH_Tech_Change_Electrode_dia}
        </td>
      </tr>
      <tr>
        <th
          className="WPSleft-th"
          colSpan="3"
          style={{
            width: "44px",
            borderRight: "none",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          Add. of Flux (GTAW)
        </th>
        <td
          style={{ borderBottom: "1px solid black" }}
          className="WPSleft-td"
          colspan={pdf[0]?.wld_WPSType == "Overlay" ? "6" : "3"}
        >
          {pdf[0]?.WPSH_FM_Add_Del_Flux}
        </td>

        <th
          className="WPSleft-th"
          style={{ borderBottom: "1px solid black" }}
        >
          Filler Metal others{" "}
        </th>

        <td
          className="WPSleft-td"
          style={{
            borderLeft: "none",
            borderBottom: "1px solid black",
          }}
          colspan="4"
        >
          {pdf[0]?.WPSH_FM_FilerMetal}
        </td>
      </tr>
    </>
  )}
</tbody>
</table>)
}

export default FillerMetal