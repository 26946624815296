

import "../Css/groovePQR_pdf.css";
import TTS_pdf_headerWPQpage1 from "./TTS_pdf_headerPQRpage1";


const PQRTubeToTubesheet_pdf_page1 = (
  {
    pdf,
    previewUrl1,
    previewUrl2,
    previewUrl3,

  },
  props,
  wld
) => {

  const width = "72.6%";

  let cols = 3,
    colwidth = "24%",
    col1Class1,
    col1Class,
    col2Class,
    col3Class;

  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };

  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";
  col1Class1 = "PQRpdfLeftBorder aligncenter";
  col1Class = "PQRleft7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "PQRleft7-td"
        : "PQRleft7-td-noright"
      : "PQRtdhidden";
  col3Class = pdf[3] !== undefined ? "PARleft7-td-noright1" : "PQRtdhidden";


  return (
    <div className="PQRMainPDF">
      <TTS_pdf_headerWPQpage1 pdf={pdf}></TTS_pdf_headerWPQpage1>

      {/* ---------------------------------Base metal---------------------------------- */}
     <div style={styles.group}>
        <div className="PQRbasemetal">BASE METALS (QW-403)</div>
        
      </div>
            <div style={styles.group}>
        <div className="PQRemptyTitle" style={{ width: "31%" }}></div>  
      </div>
      <div style={styles.container}>
        <table id="table" className="PQRTable2">
          <tbody>
            <tr>
              <td  style={{ width: "53px"}}></td>
              <th className="PQR_border"style={{textAlign:"center", width: "33px"}}>Product Form</th>
              <th className="PQR_noleft"style={{textAlign:"center", width: "93px"}}>Spec.( Type or Grade),UNS#</th>
              <th className="PQR_noleft"style={{textAlign:"center", width: "23px"}}>P No.</th>
              <th className="PQR_noleft"style={{textAlign:"center", width: "23px"}}>G No</th>
              <th className="PQR_noleft"style={{textAlign:"center", width: "23px"}}>Thk.mm</th>
              <th className="PQR_noleft"style={{textAlign:"center", width: "23px"}} >Dia.mm</th>
            </tr>
            <tr >
              <th className="PQR_noBotm"
              style={{ width: "63px"}} >
              </th>
              <td className="PQR_right"> {pdf[0]?.PQR_BM_BM_ProductForm !== "" ? pdf[0]?.PQR_BM_BM_ProductForm : "-"} </td>
              <td className="PQR_right">{pdf[0]?.PQR_BM_BM_Spec !== "" ? pdf[0]?.PQR_BM_BM_Spec : "-"}</td>
              <td className="PQR_right">{pdf[0]?.PQR_BM_BM_Pno !== "" ? pdf[0]?.PQR_BM_BM_Pno : "-"}</td>
              <td className="PQR_right"> {pdf[0]?.PQR_BM_BM_Gno !== "" ? pdf[0]?.PQR_BM_BM_Gno : "-"}</td>
              <td className="PQR_right"> {pdf[0]?.PQR_BM_BM_Thk !== "" ? pdf[0]?.PQR_BM_BM_Thk : "-"} </td>
              <td className="PQR_right">  {pdf[0]?.PQR_BM_BM_Dia !== "" ? pdf[0]?.PQR_BM_BM_Dia : "-"}  </td>
          
            </tr>
            <tr>
              <th className="PQR_Botm PQR_rt_lft" style={{ width: "63px"}}>Welded To</th>
              <td className="PQR_Botmrt">{pdf[0]?.PQR_BM_WTBM_ProductForm !== "" ? pdf[0]?.PQR_BM_WTBM_ProductForm :"-"} </td>
              <td className="PQR_Botmrt">{pdf[0]?.PQR_BM_WTBM_Spec !== "" ? pdf[0]?.PQR_BM_WTBM_Spec : "-"}</td>
              <td className="PQR_Botmrt">{pdf[0]?.PQR_BM_WTBM_Pno !== "" ? pdf[0]?.PQR_BM_WTBM_Pno : "-"}</td>
              <td className="PQR_Botmrt"> {pdf[0]?.PQR_BM_WTBM_Gno !== "" ? pdf[0]?.PQR_BM_WTBM_Gno :"-"}</td>
              <td className="PQR_Botmrt"> {pdf[0]?.PQR_BM_WTBM_Thk !== "" ? pdf[0]?.PQR_BM_WTBM_Thk :"-"} </td>
              <td className="PQR_Botm PQR_right">{pdf[0]?.PQR_BM_WTBM_Dia !== "" ? pdf[0]?.PQR_BM_WTBM_Dia :"-"}  </td>
            </tr>

          </tbody>
        </table>

        {/* <!-- -------------------------------------------Thickness Table 3 --------------------------------------------------------------> */}
      </div>
     
      <div style={styles.group}>
        <div className="PQRbasemetal">JOINT DETAIL</div>

      </div>
            <div style={styles.group}>
        <div className="PQRemptyTitle" style={{ width: "31%" }}></div>  
      </div>
      <div style={styles.container}>
        <table id="table" className="PQRTable2">
        <tbody>
      <th className="PQR_border" style={{width: "25%"}}>
        Joint Type	<br></br>
        Tube Pitch         <div className="PQRrcorner"> mm </div><br></br>
        Tube Projection       <div className="PQRrcorner"> mm </div> <br></br>
        Pattern
      </th>
      <td className="PQR_noleft" style={{ width: "25%",textAlign:"left"}}>
        <span className="PQRforfontJD" >{pdf[0]?.PQR_JD_JointType} </span>
        <br />
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_TubePitch} </span>
        <br />
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_TubeProj} </span>
        <br />
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_Pattern} </span>
        <br />  </td>
      <th style={{width: "25%"}} className="PQR_noleft">
        Hole Id                  <div className="PQRrcorner"> mm </div>	<br></br>
        Groove depth             <div className="PQRrcorner"> mm </div>
        <br></br>
        Width of the ligament  <div className="PQRrcorner"> mm </div>
         <br></br>
         Groove Angle(°)/Radius(mm)        
      </th>
      <td className="PQR_noleft" style={{textAlign:"left"}}>
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_HoleId} </span>
        <br />
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_GrooveDepth} </span>
        <br />
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_WidthLigament} </span>
        <br />
        <span className="PQRforfontJD">{pdf[0]?.PQR_JD_GrooveAngle} </span>
        <br />  </td>
      </tbody>
        </table>
      </div>

      <div>
        <table
          className="PQRTablePg2  " 
          style={{  width: "72%"}} >
          <tbody>
            <tr>
              <th className="PQR_noTop" style={{ width: "30%" }}>
                Tube Hole Ligament Detail
              </th>
              <th className="PQR_Botmrt"
                style={{
                  width: "30%" }} >
                Tube Hole Detail
              </th>
              <th className="PQR_Botmrt"
                style={{
                  padding: "1px 4px",
                  width: "30%",
                 }} >
                Tube to Tubesheet Weld Joint Detail
              </th>
            </tr>

            <tr >
              <td className="TTSimg PQR_noTop" style={{height:"80px"}}>
              
                {previewUrl1 && ( 
                  <img
                    className="PQRimgsizebig" 
                    style={{ marginTop: "2%", marginLeft: "2%" ,marginBottom:"2%" ,marginRight:"2%" }}
                    src={previewUrl1}
                    alt="image"
                  />
                )}
              </td>
              <td className="TTSimg PQR_Botmrt" style={{height:"80px"}}>
            
                {previewUrl2 && (
                  <img
                    className="PQRimgsizebig"
                    style={{ marginTop: "2%", marginLeft: "2%" ,marginBottom:"2%" ,marginRight:"2%" }}
                    src={previewUrl2}
                  />
                )}
              </td>
              <td className="PQR_Botmrt TTSimg" style={{height:"80px"}}>
            
                {previewUrl3 && (
                  <img
                    className="PQRimgsizebig"
                    style={{ marginTop: "2%", marginLeft: "2%" ,marginBottom:"2%" ,marginRight:"2%" }}
                    src={previewUrl3}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
            <table
          className="PQRTablePg2 " 
          style={{  width: "72%"}} >
          <tbody>
            <tr>
              <th className="PQR_noTop" scope="row" style={{textAlign:"left",width:"40px"}}>
                Notes
              </th>
              <td className="PQR_right PQR_Botm" style={{textAlign:"left"}} >
                {pdf[0]?.PQR_Image_Notes}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     

<div style={styles.group}>
  <div className="PQRbasemetal">FILLER METALS (QW-404)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2" >
      <tbody>
            <tr>
               <td style={{width:"20%"}}></td>
              <th style={{textAlign:"center"}} className="PQR_noRgt">SFA</th>
              <th style={{textAlign:"center"}} className="PQR_topBotm">Classification</th>
              <th style={{textAlign:"center"}} className="PQR_topBotm"> F-no</th>
              <th style={{textAlign:"center"}} className="PQR_topBotm"> A-no</th>
              <th style={{textAlign:"center"}} className="PQR_noleft"> Chemical Analysis or Trade name</th>
            </tr>
            <tr>
              <th className="PQR_border" >GTAW </th>
              <td className="PQR_Botm"> {pdf[1]?.WPSD_FM_SFA} </td>
              <td className="PQR_Botm">{pdf[1]?.WPSD_FM_Classification}</td>
              <td className="PQR_Botm">{pdf[1]?.WPSD_FM_FNo}</td>
              <td className="PQR_Botm">{pdf[1]?.WPSD_FM_Ano}</td>
              <td className="PQR_rt_botm"> {pdf[1]?.WPSD_FM_ChemicalAnalysis_TradeName}</td>
            </tr>

                    </tbody>
        </table>
      </div>

      {/* <!-- --------------------------Welding Procedure----------------------------------------------- --> */}
       

      <div className="PQRbasemetal" >WELDING PROCEDURE</div>
        <table id="table" className="PQRTable8"  >
        <tr  style={{ borderTop: ".1px solid black"}}>
          <th 
            className="PQRpdfLeftBorder "
            style={{ borderTop: ".1px solid black", width: "30%" }}
          >
            Welding Process & Type
          </th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_WeldingProcess}</td>
        </tr>
        <tr>
          <th 
            className="PQRpdfLeftBorder "
            style={{ width: "30%" }}
          >
           Min. Preheat Temperature
           <div className="PQRcorner"> °C </div>
          </th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_MinimumPreheatTemp}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder">
          Max. Interpass temperature
            <div className="PQRcorner"> °C </div>
          </th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_MaximumInterpassTemp}</td>
        </tr>
      
        <tr >
          <th className="PQRpdfLeftBorder ">Post Heating / DHT</th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_PostHeating}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">
          Tungsten  Type & Size 
            <div className="PQRcorner"> mm </div>
          </th>

          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_TungstenType}</td>
        </tr>{" "}
        <tr >
          <th className="PQRpdfLeftBorder ">Welding Position	</th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_WeldPosition}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Progression</th>

          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_WeldProgression}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">No of Passes</th>

          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_NoofPasses}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Layer Number</th>

          <td className={col1Class1} id="weldpdata3">   
            
              {pdf[1]?.PQRD_WP_LayerNo[0]?.Value_A?.[0] || "--"}{" "}
            </td>
            <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_LayerNo[1]?.Value_B?.[0] || "--"}
             </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">
            Filler Metal Size
            <div className="PQRcorner"> mm </div>
          </th>

          <td className={col1Class1} id="weldpdata3">  
           
              {pdf[1]?.PQRD_WP_FillerMetalSize[0]?.Value_A?.[0] || "--"}{" "}
            </td>
            <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_FillerMetalSize[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Amperes 
          <div className="PQRcorner"> Amps</div> </th>

          <td className={col1Class1} id="weldpdata3">
            
              {pdf[1]?.PQRD_WP_Amperes[0]?.Value_A?.[0] || "--"}{" "}
           </td>
              <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_Amperes[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">DC pulsing Current
          <div className="PQRcorner"> Amps</div> </th>

          <td className={col1Class1} id="weldpdata3">
            
              {pdf[1]?.PQRD_WP_DC_Pulsing_current[0]?.Value_A?.[0] || "--"}{" "}
        </td>
            <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_DC_Pulsing_current[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">DC pulsing Current - Others
          <div className="PQRcorner"> Amps</div> </th>

          <td className={col1Class1} id="weldpdata3">
            
              {pdf[1]?.PQRD_WP_DC_Pulsing_current_Others[0]?.Value_A?.[0] || "--"}{" "}
        </td>
            <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_DC_Pulsing_current_Others[1]?.Value_B?.[0] || "--"}
            
          </td>
        </tr>
       
        <tr >
          <th className="PQRpdfLeftBorder ">Voltage  <div className="PQRcorner"> Volts</div></th>
          <td className={col1Class1}>
 
              {pdf[1]?.PQRD_WP_Volatage_Volts[0]?.Value_A?.[0] || "--"}{" "}

          </td>
          <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_Volatage_Volts[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Heat Input
          <div className="PQRcorner"> KJ/mm</div> </th>

          <td className={col1Class1} id="weldpdata3">
  
              {pdf[1]?.PQRD_WP_Heat_Input[0]?.Value_A?.[0] || "--"}{" "}
          </td>
          <td className={col1Class1}>

              {" "}
              {pdf[1]?.PQRD_WP_Heat_Input[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Travel Speed 
          <div className="PQRcorner"> mm/min</div></th>

          <td className={col1Class1} id="weldpdata3">

              {pdf[1]?.PQRD_WP_Travel_Speed[0]?.Value_A?.[0] || "--"}{" "}
          </td>
          <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_Travel_Speed[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
       
        <tr >
          <th className="PQRpdfLeftBorder ">Polarity or current type</th>

          <td className={col1Class1} id="weldpdata3">

              {pdf[1]?.PQRD_WP_PolarityOrCurrent[0]?.Value_A?.[0] || "--"}{" "}
          </td>
          <td className={col1Class1}>
              {" "}
              {pdf[1]?.PQRD_WP_PolarityOrCurrent[1]?.Value_B?.[0] || "--"}

          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Automatic Arc Voltage control</th>

          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_ArcVoltageControl}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Shielding Gas Type - <div className="PQRcorner"> %Comp</div>  	<br></br>	 <div className="PQRmidGas" style={{paddingLeft:"42px"}}>Flow Rate - 
          <div className="PQRrcorner" style={{paddingLeft:"30px"}}>l/min</div> </div></th>

          <td className={col1Class1} colSpan={2}>
            {pdf[1]?.PQRD_WP_ShieldingGasType} <br></br>
            {pdf[1]?.PQRD_WP_ShieldingFlowRate}
          </td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Auxiliary gas shield system.</th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_Auxiliarygas}</td>
        </tr>
        <tr >
          <th className="PQRpdfLeftBorder ">Expansion</th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_Expansion}</td>
        </tr>
        <tr style={{borderBottom:"1px solid"}}>
          <th className="PQRpdfLeftBorder" style={{borderBottom:"1px solid"}}>Method of cleaning		</th>
          <td className={col1Class1} colSpan={2}>{pdf[1]?.PQRD_WP_MethodofCleaning}</td>
        </tr>
      </table>


{/* --------------------------post weld heat--------------------------------------------------------------------- */}
<div style={styles.group}>
  <div className="PQRbasemetal">POST WELD HEAT TREATMENT(QW-407)</div>
  </div>
  <div style={styles.container}>
<table id="table" className="PQRTable2">
<tbody>
  <tr>
    <th style={{width: "16%"}} className="PQR_border">
    Temperature   <div className="PQRrcorner">°C </div>
      <br />
    Heating rate  <div className="PQRrcorner">°C/Hr </div>
    </th>
    <td style={{ width: "13%"}} className="PQR_noleft">
      <span className="PQRforfont" style={{ float: "left" }}>
        {pdf[0]?.PQR_PWHeatTre_Temperature}
      </span>
      <br />
      <span className="PQRforfont" style={{ float: "left" }}>
        {pdf[0]?.PQR_PWHeatTre_Heating_Rate}
      </span>
      
    </td>
    <th style={{width: "16%"}} className="PQR_noleft">
     Time  Minutes
     <br />
    Cooling rate  <div className="PQRrcorner">°C/Hr </div>
    </th>
    <td style={{ width: "17%" }} className="PQR_noleft">
      <span className="PQRforfont" style={{ float: "left"  }}>
        {pdf[0]?.PQR_PWHeatTre_Time}
      </span>
      <br />
      <span className="PQRforfont" style={{ float: "left" }}>
        {pdf[0]?.PQR_PWHeatTre_Cooling_Rate}
      </span>
    
    </td>
    <th style={{width: "15%"}} className="PQR_noleft">
    Type
    </th>
    <td style={{ width: "13%" }} className="PQR_noleft">
      <span className="PQRforfont" style={{ float: "left"  }}>
        {pdf[0]?.PQR_PWHeatTre_Type}
      </span>
      <br />
    </td>
  </tr>
  <tr>
              <th className="PQR_noTop"
                scope="row">  Notes
              </th> 
              <td className="Noteswrap PQR_rt_botm" colspan="5" style={{textAlign:"left"}} >
                  {pdf[0]?.PQR_PWHeatTre_Notes != undefined
                    ? pdf[0]?.PQR_PWHeatTre_Notes.split("\n").map((i) => {
                        return (
                          <>
                            {i}

                            <br></br>
                          </>
                        );
                      })
                    : ""}
                </td>
            </tr> 
</tbody>
</table></div>

{/* ----------------------------------Certification ------------------------------------------------------------- */}
<div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            CERTIFICATION
            </div>
          </div>
           <table  className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border">Welder's Name</th>
              <th className="PQR_noleft" >ID </th>
              <th className="PQR_noleft"  >Tubes Welded</th>
              <th className="PQR_noleft" style={{ width: "22%"}}>Tests conducted by</th>
                <td className="PQR_noleft" style={{ width: "17%" }}>
                  <span className="PQRforfont" style={{ float: "left"  }}>
                    {pdf[0]?.PQR_Certification_ConductedBy}
                  </span> </td>
            </tr> 
            
            <tr>
              <td 
                className="PQR_rt_lft "
                style={{  width:"20%",whiteSpace:"nowrap" }} >
                {pdf[0]?.PQR_Certification_Welder_Name} </td>
      
              <td 
                className="PQR_right "
                style={{ width:"15%",whiteSpace:"nowrap"}} >
                {pdf[0]?.PQR_Certification_ID} </td>
               
      
              <td 
                className="PQR_right "
                style={{ width:"17%",whiteSpace:"nowrap"}} >
                 {pdf[0]?.PQR_Certification_Process} </td>

                <th className="PQR_right" style={{ width: "30px"}}>Mechanical tests by</th>
                <td className="PQR_right" style={{ width: "17%" }}>
                  <span className="PQRforfont" style={{ float: "left",whiteSpace:"nowrap"  }}>
                    {pdf[0]?.PQR_Certification_Mechanical_TestsBy}
                  </span> </td>
                  </tr>
                
            <tr>
              <td 
                className="PQR_noTop "
                style={{  width:"20%" }} > </td>
      
              <td 
                className="PQR_rt_botm "
                style={{ width:"20%"}} > </td>
      
              <td 
                className="PQR_rt_botm "
                style={{ width:"20%"}} ></td>

                <th className="PQR_noleft" style={{ width: "22%"}}>Laboratory  ref. number</th>
                <td className="PQR_noleft" style={{ width: "17%" }}>
                  <span className="PQRforfont" style={{ float: "left"  }}>
                    {pdf[0]?.PQR_Certification_Lab_RefNo}
                  </span> </td>
                  </tr>
          </table>
{/* ------------------------------------------------------------------------------------------------------------------- */}
{/* <div id="footer" style={{position:"fixed",top:"925px"}} >
  
<PQR_Footer pdf={pdf} previewuser={previewuser} previewapproved={previewapproved}></PQR_Footer>

</div> */}
      <br></br><br></br> 
      <div id="footer" style={{position:"fixed",top:"1015px"}} >
        <table className="WPSfooter">
          <tr>
            <td>Page 1 of 3</td>
          </tr>
        </table>
      </div>
    

    </div>
  );
};

export default PQRTubeToTubesheet_pdf_page1;
