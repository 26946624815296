import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import { isAlphanumericAllSpecialCharacters,isAlphanumericWithSpaceDotDash } from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { SearchSelect } from "../../utility/search-select";

export const PQR_Filler_metals = ({
  detailObj,
  setdetailObj,
  disabled,
  error,
  readOnly,
  setError,
  handleChangedetailsarray,
  SfaActive,
  setSfaActive,
  classError,
  setClassError,
  FnoActive,
  AnoActive,
  chemicalAnaError,
  setChemicalAnaError,
}) => {


  return (
    <Table className="text-center">
      <thead>
        <tr className="border thead-color ">
          <th className="thickness_title"></th>
          <th className="thickness_title">Process</th>
          <th className="thickness_title">SFA</th>
          <th className="thickness_title">Classification</th>
          <th className="thickness_title">F. No.</th>
          <th className="thickness_title">A. No.</th>
          <th className="thickness_title">Chemical Analysis or TradeName</th>
        </tr>
      </thead>
      <tbody>
        <tr
          className={
            detailObj?.error ? "border WPSSelect error" : "border WPSSelect"
          }
        >
          <td>
            <Tooltip content={detailObj?.errormessage}>
              <img
                src={Error}
                alt="Error"
                style={{
                  display: detailObj?.error ? "block" : "none",
                }}
              />
            </Tooltip>
          </td>
          <td style={{ width: "100px" }}>
            <label>GTAW</label>
          </td>
          <td>
            <SearchSelect
              className="inspdrop"
              data={SfaActive}
              readOnly={readOnly}
              disabled={disabled}
              valueField={"wld_code"}
              value={detailObj?.WPSD_FM_SFA}
              labelField={"wld_code"}
              placeholder=""
              onChange={(e) => {
            
                setdetailObj((prev) => ({
                  ...prev,
                  WPSD_FM_SFA: e.value,
                }));
            
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput">
            <input
              type="text"
              className={classError?.status ? "error-input" : ""}
              maxLength={12}
              id="WPSD_FM_Classification"
              name="WPSD_FM_Classification"
              placeholder=" "
              value={detailObj?.WPSD_FM_Classification}
              onChange={(e) => handleChangedetailsarray(e)}
              onInput={(e) => {
                let tempError = classError;
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                tempError = error;
           
                setClassError(tempError);
                setError((prevState) => ({
                  ...prevState,
                  WPSD_FM_Classification: classError,
                }));
              }}
            />
            <div
              style={{
                position: "relative",
                top: "6px",
                right: "25px",
              }}
              className={classError?.status ? "d-block" : "d-none"}
            >
              {error.WPSD_FM_Classification.status || (
                <Tooltip
                  content={
                    error.WPSD_FM_Classification.message || classError?.message
                  }
                >
                  <img src={Error} alt="Error" className="ms-1 imgplace" />
                </Tooltip>
              )}
            </div>
          </td>
          <td>
            <SearchSelect
              className="inspdrop"
              data={FnoActive}
              valueField={"wld_code"}
              disabled={disabled}
              readOnly={readOnly}
              value={detailObj?.WPSD_FM_FNo}
              labelField={"wld_code"}
              placeholder=""
             
              onChange={(e) => {
                setdetailObj((prev) => ({
                  ...prev,
                  WPSD_FM_FNo: e.value,
                }));
              }}
            ></SearchSelect>
          </td>
          <td>
            <SearchSelect
              className="inspdrop"
              data={AnoActive}
              valueField={"wld_code"}
              disabled={disabled}
              readOnly={readOnly}
              value={detailObj?.WPSD_FM_Ano}
              labelField={"wld_code"}
              placeholder=""
              onChange={(e) => {
                setdetailObj((prev) => ({
                  ...prev,
                  WPSD_FM_Ano: e.value,
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput">
            <input
              type="text"
              className={chemicalAnaError?.status ? "error-input" : ""}
              maxLength={50}
              id="WPSD_FM_ChemicalAnalysis_TradeName"
              name="WPSD_FM_ChemicalAnalysis_TradeName"
              placeholder=" "
              value={detailObj?.WPSD_FM_ChemicalAnalysis_TradeName}
              onChange={(e) => handleChangedetailsarray(e)}
              onInput={(e) => {
                let tempError = chemicalAnaError;
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                tempError = error;
                setChemicalAnaError(tempError);
                setError((prevState) => ({
                  ...prevState,
                  WPSD_FM_ChemicalAnalysis_TradeName: chemicalAnaError,
                }));
              }}
            />
             <div
              style={{
                position: "relative",
                top: "6px",
                right: "25px",
              }}
              className={chemicalAnaError?.status ? "d-block" : "d-none"}
            >
              {error.WPSD_FM_ChemicalAnalysis_TradeName.status || (
                <Tooltip
                  content={
                    error.WPSD_FM_ChemicalAnalysis_TradeName.message || chemicalAnaError?.message
                  }
                >
                  <img src={Error} alt="Error" className="ms-1 imgplace" />
                </Tooltip>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};