import React from "react";


const UnAuthorized = () => {

  return (
    <>
      <main className="box">
        <div className="app-content">You don't have access to the page</div>
      </main>
    </>
  );
};

export default UnAuthorized;
