import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";

import ConfirmAlertModal from "../Modals/confirmAlertModal";

import {  Container } from "react-bootstrap";


import GlobalColumnDefinations,{ getHeader_FieldName } from "../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import {

  getQualReqByType,
} from "../../Services/trainingRequisition-service";

import ActioRenderReq from "../utility/ActioRenderReq";
import {
  FormatYYYYMMDD,
  DateDiff,
  getDefaultDate
} from "../../functions/welding-functions";


export const QualificationReqInitiated = forwardRef((props, ref) => {
  const childRef = useRef();

  let formName = "Welder Qualification Requisition";
  const [reload, setReload] = useState(false);
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [action, setAction] = useState("");

  const [data, setdata] = useState("");


  let userId = sessionStorage.getItem("User");

  const handleEdit = (row) => {

    props.setAction("Edit");
    props.setRowData(row);
    props.setShowForm(true);

    props.setCalledFrom("Initiated");
  };

  useImperativeHandle(ref, () => ({
    reload: async () => {
      fetchTrainingRequisition();
      setRefere();
      fetchColumnDefns();
    },
  }));

  useEffect(() => {
    fetchTrainingRequisition();
    setRefere();
    fetchColumnDefns();
  }, reload);

  const headerName = (field) => {
    let name = getHeader_FieldName(field, props.tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, props.tmpcolumnDefs, "getField");
    return name;
  };
  function setRefere() {
    props.setRefe(childRef);
  }
  const fetchTrainingRequisition = async () => {
    let tmpObj = { fieldValue: userId, flag: "initiated" };
    let result = await getQualReqByType(tmpObj);
    result.forEach((i) =>{
      
      i.wld_pendingSince = (i.wld_status == "In Progress" || i.wld_status == "Initiated") ? 
      DateDiff(FormatYYYYMMDD(i.wld_TrainingReqDate), getDefaultDate()) : 
      0
      i.wld_CycleTime = (i.wld_status != "In Progress" &&  i.wld_status != "Initiated") ? 
      i.wld_ApprovedRejectedQualDate != undefined && i.wld_ApprovedRejectedQualDate != "undefined"?
      DateDiff(FormatYYYYMMDD(i.wld_TrainingReqDate),FormatYYYYMMDD(i.wld_ApprovedRejectedQualDate)) :0
       : 0
    })

    setFilteredResult(result);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(props.tmpcolumnDefs);
    result[result.length - 4].cellRenderer = actionRenderer;
    setColumnDefs(result);
  };

  const [show1, setShow1] = useState(false);

  const handleClose = () => {
    setShow1(false);
  };


  const actionRenderer = (params) => {
    return (
      <ActioRenderReq
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };


  return (
    <Container fluid>
      <ConfirmAlertModal
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={props.setExcelData}
        ref={childRef}
        paginationPageSize={4}
        pagination={true}
        height={350}
      />

     
      <Toaster />
    </Container>
  );
});
