import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { Form } from "react-bootstrap";
import {
  SearchProcessSelect,
  SearchProcessSelect1,
} from "../../utility/search-select";
import { isAlphanumericAllSpecialCharacters } from "../../../functions/validations";

export const Single_Input_Superviser_remark = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? "T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const WPQ_process_Input_1 = ({
  wpq_process,
  maxlength,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  onInput,
  setError,
  error,
}) => {
  
  return (
    <tr
      className={
        status || status1 ? "border WpqInput-md error" : "border WpqInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {




            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {

                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 

return (
<div key={i}> */}

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {


                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "14px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                // onInput={onInput}

                                onInput={(e) => {
                                  let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                  setError((prevState) => ({
                                    ...prevState,
                                    [name]: [
                                      { Actual_Value: [error1] },
                                      { Range_Qualified: [error[name][1].Range_Qualified[0]] },
                                    ],
                                  }));
                                }}


                               
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

      
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
           

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                          

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                name={name}
                                readOnly={readOnly}
                                type="text"
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                               
                                onInput={(e) => {
                                  let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                  setError((prevState) => ({
                                    ...prevState,
                                    [name]: [
                                      { Actual_Value: [error[name][0].Actual_Value[0]]},
                                      { Range_Qualified: [error1] },
                                    ],
                                  }));
                                }}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

      
      </td>
    </tr>
  );
};

export const WPQ_process_Input_1Actual = ({
  wpq_process,
  maxlength,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  onInput,
  setError,
  error,
}) => {
  
  return (
    <tr
      className={
        status || status1 ? "border WpqInput-md error" : "border WpqInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
        
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
          
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {

                    return (
                      <div key={id}>
                       

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                                                       return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "14px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                              
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

       
      </td>

      <td>
     
      </td>
    </tr>
  );
};


export const WPQ_process_Input_1_row_3 = ({
  wpq_process,
  maxlength,
  disabled,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  handleOtherChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  SfaActive,
  valueField,
  customstyles,
  onInput,
  setError,
  error
}) => {

  return (
    <tr
      className={
        status || status1 ? "border WPSSelectsm error" : "border WPSSelectsm"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td className="WpqInput-md">
        {wpq_process &&
          wpq_process.map((item, Index) => {
     

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                  
                    return (
                      <div key={id}>
                       
                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                           

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                type="text"
                                maxLength={maxlength}
                                readOnly={readOnly}
                                name={name}
                                value={value}
                               
                                onInput={(e) => {
                                  let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                  setError((prevState) => ({
                                    ...prevState,
                                    [name]: [
                                      { Actual_Value: [error1] },
                                      { Range_Qualified: [error[name][1].Range_Qualified[0]] },
                                    ],
                                  }));
                                }}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

   
      </td>

      <td className="WpqInput-md">
        {wpq_process &&
          wpq_process.map((item, Index) => {
            

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                  
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                       
                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                readOnly={readOnly}
                                maxLength={maxlength}
                                name={name}
                                type="text"
                                value={value}
                              
                                onInput={(e) => {
                                  let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                  setError((prevState) => ({
                                    ...prevState,
                                    [name]: [
                                      { Actual_Value: [error[name][0].Actual_Value[0]] },
                                      { Range_Qualified: [error1] },
                                    ],
                                  }));
                                }}
                                onChange={(e) =>
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

       
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
          

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {

                    return (
                      <div key={id}>
                        {element.Other &&
                          element.Other.map((value, valueIndex) => {
                        
                            return (
                              <SearchProcessSelect
                                key={valueIndex}
                                customstyles={customstyles}
                                disabled={disabled}
                        
                                data={SfaActive}
                                name={name}
                               
                                valueField={valueField}
                                labelField={valueField}
                                value={value}
                             
                                placeholder=""
                                       onChange={(e) => {
                                  
                                  value = e.value;
                                  handleOtherChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    value
                                  );
                                }}
                              ></SearchProcessSelect>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

      
      </td>
    </tr>
  );
};

export const WPQ_process_select = ({
  wpq_process,
  disabled,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  array,
}) => {
  return (
    <tr
      className={
        status || status1 ? "border WpsInput-md error" : "border WpsInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
          

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                
                    return (
                      <div key={id}>
                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                         

                            return (
                              <div class="T-input w-20">
                                <select
                                  style={{
                                    width: "120px",
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                  }}
                                  key={valueIndex}
                                  // type="text"
                                  name={name}
                                  disabled={disabled}
                                  value={value}
                                  onChange={(e) =>
                                    handleActualValueChange(
                                      Index,
                                      name,
                                      id,
                                      valueIndex,
                                      e.target.value
                                    )
                                  }
                                >
                                  {array &&
                                    array.map((e, id) => {
                                      return (
                                        <option key={id} value={e.value}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                              
                                </select>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
          
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                  
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                           

                            return (
                              <div class="T-input w-20">
                                <select
                                  style={{
                                    width: "120px",
                                    width: "120px",
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                  }}
                                  key={valueIndex}
                                  name={name}
                                  disabled={disabled}
                                  value={value}
                                  onChange={(e) =>
                                    handleRangeQualifiedChange(
                                      Index,
                                      name,
                                      id,
                                      valueIndex,
                                      e.target.value
                                    )
                                  }
                                >
                                  {array &&
                                    array.map((e, id) => {
                                      return (
                                        <option key={id} value={e.value}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export const WPQ_process_select_data = ({
  wpq_process,
  status,
  disabled,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  ColumnHeader,
  array,
  SfaActive,
  valueField,
  customstyles,
  customstyles2,
}) => {
  return (
    <tr className={status ? "border WPSSelectsm error" : "border WPSSelectsm"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}
      <td className="WPSSelectsm">
        {wpq_process &&
          wpq_process.map((item, Index) => {
         
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                      return (
                      <div key={id} className="selectstyle">
                   
                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                         
                            return (
                              <SearchProcessSelect
                                key={valueIndex}
                              
                                data={SfaActive}
                                disabled={disabled}
                                name={name}
                     
                                valueField={valueField}
                                labelField={valueField}
                                customstyles={customstyles}
                                value={value}
                             
                                placeholder=""
                             
                                onChange={(e) => {
                                  
                                  value = e.value;
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    value
                                  );
                                }}
                              ></SearchProcessSelect>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>

      <td className="WPSSelectsm">
        {wpq_process &&
          wpq_process.map((item, Index) => {
           
            return (
              <div key={Index} className="selectstyle">
                {item[name] &&
                  item[name].map((element, id) => {
                  
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                  
                            return (
                              <SearchProcessSelect1
                                key={valueIndex}
                  
                                data={SfaActive}
                                disabled={disabled}
                                name={name}
                           
                                valueField={valueField}
                                labelField={valueField}
                                value={value}
                                customstyles2={customstyles2}
                            
                                placeholder=""
                             
                                onChange={(e) => {
                                  
                                  value = e.value;
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    value
                                  );
                                }}
                              ></SearchProcessSelect1>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export const WPQ_process_CheckBox = ({
  wpq_process,
  status,
  disabled,
  readOnly,
  handleActualValueChange,
  name,
  handleRangeQualifiedChangeCheckBox,
  message,
  ColumnHeader,
  array,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}
      <td></td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
        
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                 
                    return (
                      <div key={id}>
                   

                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                   
                            return (
                              <>
                                <input
                                  className="checkboxcolor"
                                  style={{
                                    marginRight: "8px",
                                    marginLeft: "8px",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                  disabled={disabled}
                                  readOnly={readOnly}
                                  key={valueIndex}
                                  type="checkbox"
                                  name={name}
                                  value={value}
                                  checked={value[2] === "Checked"}
                                  onChange={(e) => {
                                    handleRangeQualifiedChangeCheckBox(
                                      Index,
                                      name,
                                      id,
                                      valueIndex,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="checkbox_label">
                                  {value[3]}
                                </label>
                              </>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export const WPQ_process_input_and_checkbox = ({
  wpq_process,
  disabled,
  status,
  readOnly,
  status1,
  handleRangeQualifiedChange2,
  name,
  checkedbox,
  setcheckedbox,
  handleRangeQualifiedChangeCheckBox2,
  message,
  message1,
  ColumnHeader,
  error,
  setError,
  onInput,
  onInput1,
  onInputcheck,
  twodecimal,
  array,
  maxlength,
  handleActualValueChange,
}) => {
  return (
    <tr
      className={
        status || status1 ? "border WpsInput-md error" : "border WpsInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}
      <td className="WpqInput-md">
        {wpq_process &&
          wpq_process.map((item, Index) => {
          
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
          
                    return (
                      <div key={id}>
                       

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                 

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "14px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}

                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
      {/* <td></td> */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
        

          
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
             
                    return (
                      <div key={id} className="input_checkbox">
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                          

                            return (
                              <div key={valueIndex}>
                                {value.Range_Qualified1 &&
                                  value.Range_Qualified1.map(
                                    (value2, valueIndex2) => {
                                     
                                      return (
                                        <>
                                          {element.Range_Qualified[2]
                                            .Range_Qualified2[0][2] ===
                                          "Checked" ? (
                                            <input
                                              style={{
                                                marginRight: "8px",
                                                marginLeft: "60px",
                                                border: "none",
                                              }}
                                              key={valueIndex2}
                                              type="input"
                                              name={name}
                                              value="9.999.00"
                                              readOnly={
                                                element.Range_Qualified[2]
                                                  .Range_Qualified2[0][2] ===
                                                "Checked"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          ) : (
                                            <input
                                              style={{
                                                marginRight: "8px",
                                                marginLeft: "60px",
                                              }}
                                              key={valueIndex2}
                                              type="text"
                                              name={name}
                                              value={value2}
                                              readOnly={
                                                element.Range_Qualified[2]
                                                  .Range_Qualified2[0][2] ===
                                                "Checked"
                                                  ? true
                                                  : false
                                              }
                                              placeholder="Enter Value"
                                              onChange={(e) => {
                                                handleRangeQualifiedChange2(
                                                  Index,
                                                  name,
                                                  id,
                                                  valueIndex,
                                                  valueIndex2,
                                                  checkedbox,
                                                  e.target.value
                                                );
                                              }}
                                              onInput={onInput1}
                                            />
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}

                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                          

                            return (
                              <div key={valueIndex}>
                                {value.Range_Qualified3 &&
                                  value.Range_Qualified3.map(
                                    (value2, valueIndex2) => {
                                      return (
                                        <>
                                          <label className="OR_label">
                                            {value2[0]}
                                          </label>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}

                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                          
                            return (
                              <div key={valueIndex}>
                                {value.Range_Qualified2 &&
                                  value.Range_Qualified2.map(
                                    (value2, valueIndex2) => {
                                      return (
                                        <>
                                          <input
                                            className="checkboxcolor"
                                            style={{
                                              marginRight: "8px",
                                              marginLeft: "50px",
                                              marginTop: "5px",
                                              width: "15px",
                                              height: "15px",
                                            }}
                                            key={valueIndex2}
                                            type="checkbox"
                                            disabled={disabled}
                                            name={name}
                                            value={
                                              value2[2] === "Checked"
                                                ? (value2[0] = "9999.00")
                                                : value2[0]
                                            }
                                            checked={value2[2] === "Checked"}
                                            onClick={() => {
                                              setcheckedbox(!checkedbox);
                                            }}
                                            onChange={(e) => {
                                              handleRangeQualifiedChangeCheckBox2(
                                                Index,
                                                name,
                                                id,
                                                0,
                                                valueIndex,
                                                valueIndex2,
                                                checkedbox,
                                                e.target.value
                                              );

                                              setError((prevState) => ({
                                                ...prevState,
                                                [name]: [
                                                  { Actual_Value: [""] },
                                                  {
                                                    Range_Qualified: [
                                                      {
                                                        Range_Qualified1: [""],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              }));
                                            }}
                                          />
                                          <label className="checkbox_label">
                                            {value2[3]}
                                          </label>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};