import axios from "axios";

let MASTER_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}
export async function getAllFno() {
  const response = await axios
    .get(MASTER_API_URL + "/Fno/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getActiveFno() {
  const response = await axios
    .get(MASTER_API_URL + "/Fno/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  
  return response.data;
}

export const AddNewFno = async (Fno) => {
  let url = "getActiveFno_response.data";
  const response = await axios
    .post(url, Fno)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: `${MASTER_API_URL}/Fno`, status: false };
    });
  return response.data;
};

export const UpdateFno = async (Fno) => {
  let url = "Unable to find request";
  const response = await axios
    .post(url, Fno)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: `${MASTER_API_URL}/Fno/update`, status: false };
    });
  return response.data;
};

export async function DeleteFno(skid) {
  let url = "Unable to find request";
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
