import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect
} from "react";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { Container } from "react-bootstrap";

import GlobalColumnDefinations,{ getHeader_FieldName } from "../../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../../utility/GridMaster";
import Toaster from "../../utility/Toaster";


import { getReqByType } from "../../../Services/trainingRequisition-service";


import ActioRenderTrainingReq from "../../utility/ActioRenderTrainingReq";


export const TrainingReqApproval = forwardRef((props,ref) =>  {
  const childRef = useRef();
  let formName = "TrainingRequisition";
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [action, setAction] = useState("");

  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);

  const [show1, setShow1] = useState(false);

  let userId = sessionStorage.getItem("User");

  const handleEdit = (row) => {
    props.setAction("Edit");
    props.setRowData(row);
    props.setShowForm(true);
    props.setCalledFrom("Approval")
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, props.tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, props.tmpcolumnDefs, "getField");
    return name;
  };

  function setRefre() {
    props.setRefe(childRef)
  }

  const fetchTrainingRequisition = async () => {
    let tmpObj = {fieldValue : userId, flag : "pending"}
    const result = await getReqByType(tmpObj);
    setFilteredResult(result);
  };

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(props.tmpcolumnDefs);
    result[result.length - 2].cellRenderer = actionRenderer;
    setColumnDefs(result);
  };



  useImperativeHandle(ref, () => ({
    reload: async () => {
      fetchTrainingRequisition();
      setRefre();
      fetchColumnDefns();
    }, 
   }));

   useEffect( ()=>{
    fetchTrainingRequisition();
    setRefre();
    fetchColumnDefns();
  },reload)

  const handleClose = () => {
    setShow1(false);
  };

  const actionRenderer = (params) => {
    return (
      <>
        <ActioRenderTrainingReq
          selectedData={params}
          handleEditEvent={(params) => handleEdit(params.data)}
          handleDeleteEvent={(params) => {
            setdata(params.data);
            setShow1(true);
            setAction("Delete");
          }}
        />
      </>
    );
  };

  


  return (
    <Container fluid>
      <ConfirmAlertModal
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={props.setExcelData}
        ref={childRef}
        height = "380px"
      />
     
      <Toaster />
    </Container>
  )
})