export const CommanTable_5Rows_WPQTTS = ({
  pdf,
  width,
  styles,
  heading,
  TubeNo,
  LocA,
  LocB,
  LocC,
  LocD,
  test,
  tableno,
  field1,
  field2,
  field3,
  field4,
  field5,
  field6,
  field7,
  Common_Min_ProcessesTB,
  Magnification,
  AcceptanceCriteria,
  Observation,
  Result,
  Common_Max_ProcessesTB,
}) => {

  return (
    <>
      <div className="WPQgroup" style={{ width: "570px" }}>
        <div className="WPQbasemetal">
          {heading !== "" ? (
            heading
          ) : (
            <>
              <br></br>
            </>
          )}
        </div>
      </div>
   
      <div style={styles.container}>
        <div></div>
        <table id="table" className="pdfwpqTable" style={{ width: "570px" }}>
          <tbody>
            <tr>
              <th
                className="pdfLeftBorder pdfTopBorder pdfBorderBottom "
                rowSpan="2"
                style={{ width: "15%" }}
              >
                {TubeNo}
              </th>
              <th
                className="pdfLeftBorder pdfTopBorder pdfBorderBottom"
                rowSpan="2"
                style={{ width: "15%" }}
              >
                {LocA}
              </th>
              <th
                className="pdfLeftBorder pdfTopBorder pdfBorderBottom"
                rowSpan="2"
                style={{ width: "15%" }}
              >
                {LocB}
              </th>
              <th
                className="pdfLeftBorder pdfTopBorder pdfBorderBottom"
                rowSpan="2"
                style={{ width: "15%" }}
              >
                {LocC}
              </th>
              <th
                className="pdfLeftBorder pdfTopBorder pdfBorderBottom pdfRightBorder"
                rowSpan="2"
                style={{ width: "15%" }}
              >
                {LocD}
              </th>
            </tr>
            <br></br>

            {Common_Min_ProcessesTB(
              test,
              tableno,
              field1,
              field2,
              field3,
              field4,
              field4,
              field5
            )}
          </tbody>
        </table>
      </div>
      {/* <br></br><br></br><br></br> */}
    </>
  );
};

export const CommanTable_minimumRows_WPQTTS = ({
  pdf,
  width,
  styles,
  heading,
  TubeNo,
  LocA,
  LocB,
  LocC,
  LocD,
  test,
  tableno,
  field1,
  field2,
  field3,
  field4,
  field5,
  field6,
  field7,
  Common_Min_ProcessesTB,
  Magnification,
  AcceptanceCriteria,
  Observation,
  Result,
  Common_Max_ProcessesTB,
  Observationvalue,
  resultvalue,
}) => {

  return (
    <>
      <div>
        <div style={styles.container}>
          <table
            id="table"
            className="pdfwpqTable"
          
          >
            <tbody>
              <tr>
                <th
                  className="pdfLeftBorder pdfTopBorder pdfBorderBottom"
                  rowSpan="2"
                  style={{ width: "32%" }}
                >
                  {Magnification}
                </th>
                <th
                  className="pdfLeftBorder  pdfTopBorder pdfBorderBottom"
                  rowSpan="2"
                  style={{ width: "23%" }}
                >
                  {AcceptanceCriteria}
                </th>
              </tr>
              <br></br>
              {Common_Max_ProcessesTB(test, field6, field7)}
            </tbody>
          </table>

          <table
            id="table"
            className="pdfwpqTable"
            style={{ position: "relative", right: "257px",width:"257px" }}
          >
            <tbody>
              <tr>
                <th
                  className="pdfLeftBorder pdfRight pdfTopBorder pdfBorderBottom"
                  style={{ height: "5px" }}
                >
                  {Observation}
                </th>
              </tr>
              <td className="pdfLeftBorder  pdfRight" rowSpan="3">
   

                {pdf[1]?.[Observationvalue] != undefined
                    ?  pdf[1]?.[Observationvalue].split("\n").map((i) => {
                        return (
                          <>
                            {i}

                           
                          </>
                        );
                      })
                    : ""}
              </td>
            </tbody>
          </table>
        </div>
        <table id="table" className="pdfwpqTable" style={{ width: { width } }}>
          <tr>
            <th
              className="pdfLeftBorder pdfBorderBottom pdfTop"
              style={{ padding: "1px 4px", width: "27.6%" }}
            >
              {Result}
            </th>
            <td colspan="2" class="pdfTopBottomRight pdfLeftBorder">
              {pdf[1]?.[resultvalue]}
            </td>
          </tr>
        </table>
      </div>

 
     


     
    </>
  );
};
