import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { isAlphanumericAllSpecialCharacters } from "../../../functions/validations";

export const WPQ_Process_Grid_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  detailObj,
  maxLength,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>


          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
          >
            <input
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={detailObj?.[name]}
              style={{width:"512px"}}
      
              maxLength={maxLength}
                onChange={(e) => {
                handleChangedetailsarray(e);
              }}
              onInput={onInput}
            />
          </td>
          

    </tr>
  );
};

export const WPQ_PQR_Process_Grid_Input2_tube = ({
    status,
    status1,

    message,

    ColumnHeader,
    Columndimension,

    name,
    setError,
    error,
  
    readOnly,

    detailObj,
    handle_2Input_ChangeA,
    handle_2Input_ChangeB,
    maxLength,
  }) => {
    

   


    return (
      <tr className={status || status1 ? "border WpsInput-md error" : "border WpsInput-md"}>
        <th
          className={status || status1 ? "text-start thead-color" : "text-start thead-color"}
        >
          <div>
            <div className="headwidth">{ColumnHeader} </div>
            <span>{Columndimension}</span>
            {(status || status1) && (
              <Tooltip content={message}>
                <img src={Img} alt="Error" className="ms-1" />
              </Tooltip>
            )}
          </div>
        </th>

        <td 
                    className={status ? "border WPSSelect error" : "border WPSSelect"}
                    >
            <div>
              {detailObj?.[name] &&
                detailObj?.[name].map((element, id) => {
               
                  return (
                    <div key={id}>
                    
                      {element.Value_A &&
                        element.Value_A.map((value, valueIndex) => {
                          

                          return (
                            <input
                            className={status ? "error-input" : ""}

                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"250px"
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handle_2Input_ChangeA(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                          
                              onInput={(e) => {
                                debugger
                                let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                setError((prevState) => ({
                                  ...prevState,
                                  [name]: [
                                    { Value_A: [error1] },
                                    { Value_B: [error[name][1].Value_B[0]] },
                                  ],
                                }));
                               
                              }}
                           
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

          </td>

          <td                     className={status1 ? "border WPSSelect error" : "border WPSSelect"}
>
            <div>
              {detailObj?.[name] &&
                detailObj?.[name].map((element, id) => {
              
                  return (
                    <div key={id}>
                     

                      {element.Value_B &&
                        element.Value_B.map((value, valueIndex) => {
                        

                          return (
                            <input
                            className={status1 ? "error-input" : ""}

                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"250px",
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handle_2Input_ChangeB(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                            
                              onInput={(e) => {
                                let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                setError((prevState) => ({
                                  ...prevState,
                                  [name]: [
                                    { Value_A: [error[name][0].Value_A[0]] },
                                   { Value_B: [error1] },
                                  ],
                                }));
                              }}
                           
                              
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>


          </td>
  
  
 
            
            
            
  
      </tr>
    );
  };

export const WPQ_TTS_Process_Grid = ({
  status,
  message,
  detailObj,
  ColumnHeader,
  Columndimension,
  array,
  name,
  handleChangedetailsarray,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
          >
            <input
              style={{
                width: "512px",
                height: "30px",
                borderRadius: "3px",
              }}
              readOnly
              className={status ? "error-input" : ""}
              name={name}
              value="GTAW"
              // onChange={(e) => {
              //   handleChangedetailsarray(e);
              // }}
            />
              {/* {array &&
                array.map((e) => {
                  return (
                    <option value={e.value}>
                      {e.name}
                    </option>
                  );
                })} */}

              {/* <option value="1">1</option>
              <option value="1.6">1.6</option>
              <option value="2.4">2.4</option>
              <option value="3.2">3.2</option>
              <option value="2.4/3.2">2.4/3.2</option> */}
          </td>

    </tr>
  );
};

export const WPQ_Process_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  detailObj,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  obj,
  setfunction,
  onInput,
  ColumnHeader1,
  name1,
}) => {
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3">
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-center">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

   
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                type={type}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                style={{width:"512px"}}
                value={detailObj?.[name]}
                readOnly={readOnly}
                maxLength={maxLength}
                onChange={(e) => {
                  handleChangedetailsarray(e);
                }}
                onInput={onInput}
              />

              <input
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                style={{width:"512px"}}
                value={detailObj?.[name1]}
                readOnly={readOnly}
                maxLength={maxLength}
                onChange={(e) => {
                  handleChangedetailsarray(e);
                }}
                onInput={onInput1}
              />
            </div>
          </td>

    </tr>
  );
};


