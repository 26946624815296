import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("Token");

 

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }


    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);
