import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "../../Assests/Css/header.css";
import {  getApprovedPendingRepairWorkflow, getProjectReqCount } from "../../Services/weldingRepairAnalysis-service";
import GridMaster from "../utility/GridMaster";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

import GlobalColumnDefinations, { getHeader_FieldName } from "../utility/AgGridDefination";
import { handleExport1 } from "../utility/GlobalExport";
import GolbalSearch from "../utility/GolbalSearch";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import {  useLocation, useNavigate } from "react-router";
import { getCurrentDateTime } from "../../functions/welding-functions";


import backarrow from "../../icons/Back Arrow-3.png"
import { getWIReqCount } from "../../Services/WI-services";


const WeldingRepairAnalysisViewAll = forwardRef((props, ref) => {

  const location = useLocation();
  const navigate = useNavigate();

  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState(location.state?.action);

  const [reload, setReload] = useState(false);

  const [excelData1, setExcelData] = useState([]);
  const [allPendingRepairAnalysis, setAllPendingRepairAnalysis] = useState([]);
  const [allRaisedRepairAnalysis, setAllRaisedRepairAnalysis] = useState([]);
  const [columnReceivedforApproval, setColumnReceivedforApproval] = useState(
    []
  );
  const [columnRaisedforApproval, setColumnRaisedforApproval] = useState([]);

  const [ReqCount, setReqCount] = useState([]);
  const [WIReqCount, setWIReqCount] = useState([]);




  const childRef = useRef();


  let userId = sessionStorage.getItem("UserId")?.split("@")[0];
  let userRole = sessionStorage.getItem("Role");
  let todayDate = getCurrentDateTime("-");


  

  const HyperLink = (params, row) => {


    return (
      <Button
        variant="link"
        onClick={() => {
          navigate("/weldingRepair/WeldingRepairAnalysisEntry", {
            state: {
              data: params.data,
              action: "Edit",
            },
          });
        }}
        size="sm"
      >
        {params.data.reportNumber}
      </Button>
    );
  };


  const tableReceivedforApproval = [
    {
      headerName: "Status",
      field: "repairAnalysisStatus",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WeldRepairRenderer",
      sortable: true,
    },
    {
      headerName: "Report Number",
      field: "reportNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: HyperLink,
      minWidth: 50,
    },
    {
      headerName: "Ageing (Days)",
      field: "aging(days)",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      sort: "desc",
    },
  ];

  const tableRaisedforApproval = [
    {
      headerName: "Status",
      field: "repairAnalysisStatus",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WeldRepairRenderer",
      sortable: true,
    },
    {
      headerName: "Report Number",
      field: "reportNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: HyperLink,
      minWidth: 50,
    },
    {
      headerName: "Pending with",
      field: "approvalPendingWith",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      minWidth: 50,
    },
    {
      headerName: "Ageing (Days)",
      field: "aging(days)",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      sort: "desc",
    },
  ];


  const tmpcolumnDefs = [
    {
      headerName: "Project Number",
      field: "projectNo",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      sortable: true,
      sort: "asc",
    },
    {
      headerName: "Repair Count",
      field: "ApprovedCount",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      minWidth: 50,
    },
    {
      headerName: "% First Attempt Clear",
      field: "percentage",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];


  const headerReceivedforApproval = (field) => {
    let name = getHeader_FieldName(
      field,
      tableReceivedforApproval,
      "getHeader"
    );
    return name;
  };

  const fieldReceivedforApproval = (header) => {
    let name = getHeader_FieldName(
      header,
      tableReceivedforApproval,
      "getField"
    );
    return name;
  };

  const fetchColumnReceivedforApproval = async () => {
    const result = GlobalColumnDefinations(tableReceivedforApproval);

    result[1].cellRenderer = HyperLink;
    setColumnReceivedforApproval(result);
  };



  const headerRaisedforApproval = (field) => {
    let name = getHeader_FieldName(field, tableRaisedforApproval, "getHeader");
    return name;
  };

  const fieldRaisedforApproval = (header) => {
    let name = getHeader_FieldName(header, tableRaisedforApproval, "getField");
    return name;
  };


  const fetchColumnRaisedforApproval = async () => {
    const result = GlobalColumnDefinations(tableRaisedforApproval);

    result[1].cellRenderer = HyperLink;

    setColumnRaisedforApproval(result);
  };


  const fetchApprovedPendingRepair = async () => {
    let temp = [];
    let newDate = new Date();
    let yeardata = newDate.getFullYear();

    let userObj = {
      userId: userId,
      userRole: userRole,
      flag: "AllUserRoleWise",
      year: yeardata
    };
    let repairresult = await getApprovedPendingRepairWorkflow(userObj);
    
    if (repairresult?.length > 0) {
      for (let item of repairresult) {
        var date1 = new Date(item.initiatedDate);
        var date2 = new Date(todayDate);
        var time_difference = date2.getTime() - date1.getTime();
        item["aging(days)"] = Math.round(
          time_difference / (1000 * 60 * 60 * 24)
        );
        if (item.approvalPendingWith === userRole) {
          temp.push(item);
        }
      }
    }
    setAllPendingRepairAnalysis(temp);
  };




  const fetchAllRepairWorkflowByUserIdWise = async () => {
    let raised = [];
    let newDate = new Date();
    let yeardata = newDate.getFullYear();
    let userObj = { userId: userId, userRole: userRole, flag: "AllUserIdWise", year: yeardata };
    let repairresult = await getApprovedPendingRepairWorkflow(userObj);
    if (repairresult?.length > 0) {
      for (let item of repairresult) {
        var date1 = new Date(item.initiatedDate);
        var date2 = new Date(todayDate);
        var time_difference = date2.getTime() - date1.getTime();
        item["aging(days)"] = Math.round(
          time_difference / (1000 * 60 * 60 * 24)
        );
        if (item.createdBy === userId)
          raised.push(item);
      }
    }
    setAllRaisedRepairAnalysis(raised);
  };





  useEffect(() => {
    fetchApprovedPendingRepair()
    fetchColumnReceivedforApproval();
    fetchAllRepairWorkflowByUserIdWise()
    fetchColumnRaisedforApproval()
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));


  

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);
  };

  const handleGlobalExport = () => {
    let filename = "WeldingRepairAnalysis";
    setExcelData(filteredResult);
     handleExport1(excelData1, filename, tableReceivedforApproval);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };


  const fetchprojectreqcount = async () => {
    
    const Projectcount = await getProjectReqCount();

    let WICounts = [];

    // Aggregating project counts by projectNo
    const aggregatedProjects = {};
    for (let project of Projectcount) {
        const abc = project.SK.split("#");
        const projectNo = abc[0]



        if (!aggregatedProjects[projectNo]) {
            aggregatedProjects[projectNo] = { ...project };
        } else {
            // Check if ApprovedCount property exists before adding
            if (project.hasOwnProperty("ApprovedCount")) {
                if (aggregatedProjects[projectNo].hasOwnProperty("ApprovedCount")) {
                    aggregatedProjects[projectNo].ApprovedCount += project.ApprovedCount;
                } else {
                    aggregatedProjects[projectNo].ApprovedCount = project.ApprovedCount;
                }
            }
        }
    }



    // Create an array to hold aggregated projects
    const finalcount = [];

    // Loop through aggregated projects
    for (let proj in aggregatedProjects) {
        const project = aggregatedProjects[proj];
        const count = await getWIReqCount(project.SK);
        WICounts.push(count);

        // Calculate the percentage
        if (count !== 0) {
            const percentage = ((count - project.ApprovedCount) / count) * 100;
            const roundedPercentage = Math.round(percentage);
            project.count = count.toString();
            project.percentage = `${roundedPercentage}%`;
        } else {
            project.percentage = "100%";
        }

        // Split the SK and add the first part to the project object as projectNo
        const [projectNo, year, month] = project.SK.split("#");
        project.projectNo = projectNo;
        project.year = year;
        project.month = month;

        // Add the project to the final array
        finalcount.push(project);
    }



    setReqCount(finalcount);
    setWIReqCount(WICounts);

    fetchColumnDefns();
}


  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);

    if (result) {
      setColumnDefs(result);
    }
  };


  useEffect(() => {
    fetchprojectreqcount();
  }, []);


  return (
    <Container fluid className="main-container">
      <Row className="RowHeight">
        <Col md={4} sm={12} xs={12} className="ListTitle">
        <button
        className="Repairviewallbackbutton"
        onClick={() => {
          navigate("/weldingRepair/WeldingRepairAnalysis", {
            state: {
              data: "",
              action: "",
            },
          });
        }}
        size="sm"
      >
        <img src = {backarrow} />
      </button> 

      {

      }
            {action === "ViewReceivedforapproval" ? (

      <>List of workflow received for approval</>
      ): action === "viewReqCount" ? (

        <>Project Wise Repair</>
      ):(      <>List of workflow raised by logged in user for approval</>
      )
}
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={"Search for Report Number, Project Number"}
              filterFunc={handleGlobalSearch}
            />
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>
      {action === "ViewReceivedforapproval" ? (
  <GridMaster
    rowDetails={allPendingRepairAnalysis}
    colDetails={columnReceivedforApproval}
    fieldNames={fieldReceivedforApproval}
    headerNames={headerReceivedforApproval}
   
    setExcelData={setExcelData}
    ref={childRef}
    paginationPageSize={5}
    pagination={true}
  />
) : action === "viewReqCount" ? (
  <GridMaster
    rowDetails={ReqCount}
    colDetails={columnDefs}
    fieldNames={fieldName}
    headerNames={headerName}
   
    setExcelData={setExcelData}
    ref={childRef}
    paginationPageSize={5}
    pagination={true}
  />
) : (
  <GridMaster
    rowDetails={allRaisedRepairAnalysis}
    colDetails={columnRaisedforApproval}
    fieldNames={fieldRaisedforApproval}
    headerNames={headerRaisedforApproval}
   
    setExcelData={setExcelData}
    ref={childRef}
    paginationPageSize={5}
    pagination={true}
  />
)}
    </Container >
  );
});

export default WeldingRepairAnalysisViewAll;
