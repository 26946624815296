import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import NavItem from "./NavItem";
import NavImage from "./NavImage";


import MenuIcon from "../../icons/menu.svg";

import "../../Assests/Css/sidebar.css";
import Account from "../Account";
import { useLocation } from "react-router-dom";
import { getUserMenu, getMenuDetails } from "../../Services/menu-service";


const SideBar = forwardRef((props, ref) => {

  let mobile = window.matchMedia("(max-width: 600px)").matches;


  const [isOpen, setIsOpen] = useState(true);
  const [mobileOpen, setmobileOpen] = useState(mobile);


  const [activeIndex, setactiveIndex] = useState(0);

  const toggle = () => {
    
    setIsOpen(!isOpen);
    let mobile = window.matchMedia("(max-width: 600px)").matches;
    if (mobile) setmobileOpen(!mobileOpen);


    props.closemenu();
    setactiveIndex(0);
    setLabel(getMenuDetails(JSON.stringify(getmenudata), window.location.pathname));
  };

  useEffect(() => {

    setLabel(getMenuDetails(JSON.stringify(getmenudata), window.location.pathname));

  }, [useLocation().pathname]);
  let c = 0;
  let d = 0;

  function onHide(count, menupath,children) {
  //alert(count)
    if (activeIndex !== count) setactiveIndex(count);
    else setactiveIndex(0);
   
    if(children==undefined || children == "")
      toggle()
  }
  const [getmenudata, setmenudata] = useState([]);
  const [Labels, setLabel] = useState({
    title: "",
    button: false,
    buttontext: "",
    currentMenu: "",
    path: "",
    component: "",
  });

  const fetchMenuMaster = async () => {
    
    let result1;
    if (sessionStorage.getItem("UserAccess") === null) {

      let webDisplay = "W"
      let mobileDisplay = "WM"
      if(mobileOpen) webDisplay =""
      result1 = await getUserMenu(sessionStorage.getItem("Role"),webDisplay);
      result1 = result1.filter((i) => (i.menu_display == webDisplay || i.menu_display == mobileDisplay)  )
    } else result1 = JSON.parse(sessionStorage.getItem("UserAccess"));

    
    setmenudata(result1);

    sessionStorage.setItem("UserAccess", JSON.stringify(result1));
  };

  useEffect(() => {
    if (getmenudata !== undefined) {
      fetchMenuMaster();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    mobiletoggle: () => {
      setmobileOpen(!mobileOpen);
      setIsOpen(!isOpen);
    },
  }));

  useEffect(() => {

    setLabel(getMenuDetails(JSON.stringify(getmenudata), window.location.pathname));

  }, [getmenudata]);






  const handleHover = (isHovered) => {
    const sidebar = document.querySelector(".sidebar")
    sidebar.style.overflowY = isHovered ? "auto" : "hidden";
  }

  let currentMenu


  // onWheel={handleScroll}

  return (
    <>
      <div className="sidebar"  >
        <div className={isOpen ? "menu-mobile" : "menu-mobile-close"}>
          <div className="profileimage"></div>
          <div
            style={{ display: isOpen ? "inline-block" : "none" }}
            className="profilemobile"
          >
            <Account />
          </div>
        </div>

        <div className={isOpen ? "sideopen" : "sideclose"} onMouseEnter={()=> handleHover(true)} onMouseLeave={() => handleHover(false)} style={{maxHeight:"90vh"}}>
          <div style={{ display: "flex", alignItems: "end", gap: "1rem" }}>
            <div>
              <img
                className="menu-toplogo"
                src={MenuIcon}
                onClick={toggle}
                style={{ padding: "1rem 0 0 0.5rem" }}
                alt=""
              ></img>
            </div>
            <div style={{ display: isOpen ? "inline-block" : "none" }}>
              <p className="login-text">Welding System</p>
            </div>
          </div>

          <div className="mt-4">
            <div style={{ display: isOpen ? "block" : "none" }}>
              {getmenudata.filter((i)=> i.menu_parent_id == undefined).sort((a, b) => Number(a.menu_id) > Number(b.menu_id) ? 1 : -1).map((listmenu, index) => {
                {
                  
              var item = listmenu


              c = c + 1;
              var children = getmenudata.filter((c)=>c.menu_parent_id == item.menu_id)
              }
                return (
                  <NavItem
                    item={item}
                    children={children}
                    counter={c}
                    key={"Nav" + item.menu_id}
                    IsChildopen={activeIndex === item.menu_id}
                    onHide={() => onHide(item.menu_id, item.menu_path,children)}
                    toggle={() => toggle()}
                    currentMenu={currentMenu}
                  ></NavItem>
                );
              })}
            </div>

            <div style={{ display: isOpen || mobileOpen ? "none" : "block" }}>
            {getmenudata.filter((i)=> i.menu_parent_id == undefined).sort((a, b) => Number(a.menu_id) > Number(b.menu_id) ? 1 : -1).map((listmenu, index) => {
                {
                  d = d + 1;
                  var item = listmenu
                  currentMenu = Labels ? Labels.currentMenu : "DASHBOARD"


                }
                return (
                  
                  <NavImage
                    item={item}
                    isSelected={item.menu_group === currentMenu}
                    icon={
                      item.menu_group !== currentMenu
                        ? "/sidebar/" + item.menu_name + "_Default.svg"
                        : "/sidebar/" + item.menu_name + "_Active.svg"
                    }
                    counter={d}
                    setIsOpen={setIsOpen}
                    onHide={() => onHide(d, item.menu_path,0)}
                  >
                    <a href={item.menu_path}></a>
                  </NavImage>
                );
              })}
            </div>
            <div style={{ display: isOpen ? "none" : "block" }}>
              <img className="menu-gicons" src="/godrej-logo.svg" alt=""></img>
            </div>
            <div style={{ display: isOpen ? "block" : "none" }}>
              <img className="menu-gicons1" src="/godrej-logo.svg" alt=""></img>
            </div>
          </div>
  
        </div>
      </div>

      
    
    </>
  );
});
export default SideBar;
