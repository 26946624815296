import React, { useEffect, useState } from "react";
import "../Css/groove_pdf.css";
import { Modal } from "react-bootstrap";



import BarLoader
from "react-spinners/BarLoader";

import WI_PDF_1 from "./WI_PDF_1";


import html2pdf from 'html2pdf.js';
import WI_PDF_TTS_1 from "./WI_PDF_TTS1";
import WI_PDF_TTS_2Hindi from "./WI_PDF_TTS_2Hindi";
import WI_PDF_TTS_3Hindi from "./WI_PDF_TTS_3Hindi";
import WI_PDF_3Hindi from "./WI_PDF_3Hindi";
import WI_PDF_2Hindi from "./WI_PDF_2Hindi";


  const   WI_PDF_Download = (props) => {



  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const downloadPdf = () => {

    


  const element = document.querySelector('#MainDiv');
  const options = {
    filename: `WI Report ${props?.rowdata[1]?.wld_WPSType}`,
    margin: [0,0.5,0,0],
    
    html2canvas: { scale: 3 },
    jsPDF: { unit: 'in', format: "A4", orientation: 'portrait',compressPDF: true },
  };
  html2pdf().set(options).from(element).save();
  props.onHide();
         props.setReload(!props.reload);
         setLoading(false)
}



  useEffect(() => {

    downloadPdf();

  }, [props?.rowdata]);



return(

<>


      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(1000vh - 1000px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}

            {/* {loading &&  <Spinner animation="border" className="spinner">

</Spinner>}      */}
          </p>
        </Modal.Body>
        {/* <WI_PDF_1 /> */}

      </Modal>

      <div id="MainDiv">
    <br></br>
    <>
    {props?.rowdata[1].wld_WPSType !== "Tubesheet" ?
<>
    <WI_PDF_1 
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
    <div style={{pageBreakInside: "avoid", paddingTop:"30px"}}>

    <WI_PDF_2Hindi
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
    </div>

    <div style={{pageBreakInside: "avoid", paddingTop:"30px"}}>

    <WI_PDF_3Hindi
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
    </div>
    </>
    : 
    <>
     <WI_PDF_TTS_1
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
<div style={{pageBreakInside: "avoid", paddingTop:"30px"}}>

    <WI_PDF_TTS_2Hindi
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
    </div>

    <div style={{pageBreakInside: "avoid", paddingTop:"30px"}}>

    <WI_PDF_TTS_3Hindi
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
    </div>
    </>
}
    </>

 
  </div>


       
         
         
      
      

</>
)
    }


export default WI_PDF_Download;
