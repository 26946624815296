import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import {
  AddNewInspectionAgency,
  UpdateInspectionAgency,
} from "../../Services/inspectionagency-service";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumeric,
  isAlphanumericWithDotSpaceDashCommaBrackets,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../utility/FormControl";
import ButtonModal from "../Modals/buttonModal";

const AddEditInspectionAgencyMaster = (props) => {
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "Inspection Agency";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  const [InspectionAgencyObj, setInspectionAgencyObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          wld_status: true,
          wld_code: "",
          wld_description: "",
          SK: "",
          PK: "InspectionAgency_Master",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
        }
  );
  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_description: {},
    wld_createdby: {},
    wld_createdon: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setInspectionAgency",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInspectionAgencyObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setInspectionAgencyObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  const resetForm = () => {
    if (action === "Add") {
      setInspectionAgencyObj((prevState) => ({
        ...prevState,
        wld_status: true,
        wld_code: "",
        wld_description: "",
      }));
    } else if (action === "Edit") {
      setInspectionAgencyObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_code: props.rowdata.wld_code,
        wld_description: props.rowdata.wld_description,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_code: {},
      wld_description: {},
      SK: {},
    }));
  };

  const validateInspectionAgency = (action) => {
    let result = false;
    let isError = false;
    if (InspectionAgencyObj.wld_code.trim() === "") {
      InspectionAgencyObj.wld_code = "";
      isError = true;
      error.wld_code = {
        status: true,
        message: "Inspection Agency Code is required.",
      };
    }
    if (InspectionAgencyObj.wld_description.trim() === "") {
      InspectionAgencyObj.wld_description = "";
      isError = true;
      error.wld_description = {
        status: true,
        message: "Inspection Agency Name is required.",
      };
    }
    if (InspectionAgencyObj.wld_status === "") {
      isError = true;
      error.wld_status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (action === "Add") {
      result =
        props.allRecs &&
        props.allRecs.find(
          (item) =>
            item.PK.toLowerCase() === InspectionAgencyObj.PK.toLowerCase() &&
            item.SK.toLowerCase() === InspectionAgencyObj.wld_code.toLowerCase()
        );
    }

    if (result) {
      setAlertMessage("Inspection Agency Number Already Exists!");
      isError = true;
    }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.SK.status ||
      error.wld_code.status ||
      error.wld_description.status
    ) {
      isError = true;
    }
    return isError;
  };
  const handleAdd = (e) => {
    let result;
    if (InspectionAgencyObj.wld_status === true) {
      InspectionAgencyObj.wld_status = "Active";
    } else {
      InspectionAgencyObj.wld_status = "Inactive";
    }
    if (action === "Add") {
      InspectionAgencyObj.SK = InspectionAgencyObj.wld_code;
      InspectionAgencyObj.wld_createdby = username;

      result = AddNewInspectionAgency(InspectionAgencyObj);
    } else {
      InspectionAgencyObj.wld_updatedby = InspectionAgencyObj.wld_createdby;

      result = UpdateInspectionAgency(InspectionAgencyObj);
    }
    result
      .then((resp) => {
        setshowConfirmModel(false);
        setShowSuccessModel(false);
        if (action === "Edit") {
          toast.success(
            `Selected Inspection Agency has been Updated successfully`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
        }
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success(
            `Selected Inspection Agency  has been Added successfully`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
        }

        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateInspectionAgency(action)) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Inspection Agency successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Inspection Agency</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        type="text"
                        id="wld_code"
                        name="wld_code"
                        placeholder=" "
                        disabled={action === "Edit" ? true : false}
                        maxLength="15"
                        className={
                          error.wld_code.status
                            ? " g-input placeHolderInput inputText text-field-with-error allow"
                            : "g-input placeHolderInput inputText allow"
                        }
                        value={InspectionAgencyObj.wld_code}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumeric(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_code: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_code">
                        Inspection Code{" "}
                      </label>
                    </div>
                    {error.wld_code.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "20px" }}>
                          {error.wld_code.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_description"
                        name="wld_description"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_description.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={InspectionAgencyObj.wld_description}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_description: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_description">
                        Agency Name{" "}
                      </label>
                    </div>
                    {error.wld_description.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "8px" }}>
                          {error.wld_description.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        <label
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            color: "var(--col-707070)",
                            fontWeight: "bold",
                          }}
                          class="required-field"
                        >
                          Status{" "}
                        </label>
                      </Col>
                      <Col>
                        <Form.Switch
                          label={
                            InspectionAgencyObj.wld_status
                              ? "Active"
                              : "Inactive"
                          }
                          className={
                            InspectionAgencyObj.wld_status ? "green" : "gray"
                          }
                          name="wld_status"
                          style={{ padding: "10px" }}
                          checked={InspectionAgencyObj.wld_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

         

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>

      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditInspectionAgencyMaster;
