import React from "react";



import "../Assests/Css/sidebar.css";
import "../Assests/Css/header.css";


const Dashboard = () => {
 
  return (
    <>
      <main className="box">
        <div className="app-content">Refer menu to browse respective module</div>
      </main>
    </>
  );
};

export default Dashboard;
