import "../Css/groovePQR_pdf.css";
import Groove_pdf_headerPQR from "./Groove_pdf_headerPQR";
import PQR_Footer from "./PQR_Footer";

const Groove_pdf_page2PQR = ({
  pdf,
  PtewUrl,
  previewuser,
  previewapproved,
}) => {
  let isoverlay = pdf[0]?.wld_WPSType === "Groove" ? false : true;

  const width = "72.6%";
  const height = "400px";
  const bottom = 10 - window.innerHeight;


  function Guided_Bend() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.GuidedBend_Test || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft">
              {item.PQR_GB_TypeofTest !== "" ? item.PQR_GB_TypeofTest : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GB_Acceptance_Criteria !== ""
                ? item.PQR_GB_Acceptance_Criteria
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GB_Results !== "" ? item.PQR_GB_Results : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GB_Remarks !== "" ? item.PQR_GB_Remarks : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }
  function Guided_Bendoverlay() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.GuidedBend_Test_Overlay || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft">
              {item.PQR_GBV_SpecimenNo !== "" ? item.PQR_GBV_SpecimenNo : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GBV_Thickness !== "" ? item.PQR_GBV_Thickness : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GBV_BM_WeldOverlay !== ""
                ? item.PQR_GBV_BM_WeldOverlay
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GBV_FormerDiam !== "" ? item.PQR_GBV_FormerDiam : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GBV_BendAngle !== "" ? item.PQR_GBV_BendAngle : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GBV_Acceptance_Criteria !== ""
                ? item.PQR_GBV_Acceptance_Criteria
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_GBV_Remarks !== "" ? item.PQR_GBV_Remarks : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }

  function Toughness_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.Toughness_Test || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft">
              {item.PQR_TT_SpecimenNo !== "" ? item.PQR_TT_SpecimenNo : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_NotchLocation !== ""
                ? item.PQR_TT_NotchLocation
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_NotchType !== "" ? item.PQR_TT_NotchType : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_SpecimenSize !== ""
                ? item.PQR_TT_SpecimenSize
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_TestTemp !== "" ? item.PQR_TT_TestTemp : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_ImpactValues !== ""
                ? item.PQR_TT_ImpactValues
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_Avg !== "" ? item.PQR_TT_Avg : "--"}
            </td>
            <td className="PQR_right"> 
              {item.PQR_TT_LateralExpansion !== ""
                ? item.PQR_TT_LateralExpansion
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_TT_Shear !== "" ? item.PQR_TT_Shear : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }
  function Hardness_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.Hardness_Test_Groove || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft">
              {item.PQR_HT_Type !== "" ? item.PQR_HT_Type : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_Location !== "" ? item.PQR_HT_Location : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_ParentMetal_Left !== ""
                ? item.PQR_HT_ParentMetal_Left
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_HAZ_Left !== "" ? item.PQR_HT_HAZ_Left : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_Weld !== "" ? item.PQR_HT_Weld : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_HAZ_Right !== "" ? item.PQR_HT_HAZ_Right : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_ParentMetal_Right !== ""
                ? item.PQR_HT_ParentMetal_Right
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_HT_Remarks !== "" ? item.PQR_HT_Remarks : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }
  function Tensile_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.Tensile_Test || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft">
              {item.PQR_Tensile_SpecimenNo !== ""
                ? item.PQR_Tensile_SpecimenNo
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Tensile_Width !== "" ? item.PQR_Tensile_Width : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Tensile_Thickness !== ""
                ? item.PQR_Tensile_Thickness
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Tensile_Area !== "" ? item.PQR_Tensile_Area : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Tensile_UltimateLoad !== ""
                ? item.PQR_Tensile_UltimateLoad
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Tensile_UltimateStress !== ""
                ? item.PQR_Tensile_UltimateStress
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Tensile_FailureLocation !== ""
                ? item.PQR_Tensile_FailureLocation
                : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }

  function Other_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.Other_Test || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft">
              {item.PQR_OT_TypeofTest !== "" ? item.PQR_OT_TypeofTest : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_OT_Acceptance_Criteria !== ""
                ? item.PQR_OT_Acceptance_Criteria
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_OT_Results !== "" ? item.PQR_OT_Results : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_OT_Remarks !== "" ? item.PQR_OT_Remarks : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }
  function Certification() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.Certification || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`} >
            <td className="PQR_rt_lft">
              {item.PQR_Certification_Welder_Name !== ""
                ? item.PQR_Certification_Welder_Name
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Certification_ID !== ""
                ? item.PQR_Certification_ID
                : "--"}
            </td>
            <td className="PQR_right">
              {item.PQR_Certification_Process !== ""
                ? item.PQR_Certification_Process
                : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }
  function CertificationLabRef() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.CertificationLabRef || [];
      for (let j = 0; j < visualExamination.length; j++) {
        const item = visualExamination[j];
        rows.push(
          <tr key={`${i}-${j}`} style={{height:"14.2px"}}>
            <td className="PQR_Botmrt" colspan={2}>
              {item.PQR_Certification_Lab_RefNo !== ""
                ? item.PQR_Certification_Lab_RefNo
                : "--"}
            </td>
          </tr>
        );
      }
    }
    return rows;
  }

  return (  
    <div className="MainPDF" style={{paddingTop:"15px",position:"relative"}}>
      <div id="container" className="flexb" >


        <div className="headerRow" >
          
          <Groove_pdf_headerPQR pdf={pdf} from="PQR"></Groove_pdf_headerPQR>
        </div>

        <div id="body" className="Content">
          

          {/* <!-- -----------------------------------------Table 1----------------------------------------------- --> */}
          {!isoverlay ? (
            <>
              <div className="">
                <div className="PQRbasemetal">TENSILE TEST(QW-150)</div>
              </div>
              <table className="PQRTablePg2">
                <tr>
                  <th className="PQR_border">Specimen number</th>
                  <th className="PQR_noleft">
                    Width <br />
                    mm
                  </th>
                  <th className="PQR_noleft">
                    Thickness <br />
                    mm
                  </th>
                  <th className="PQR_noleft">
                    Area <br />
                    mm2
                  </th>
                  <th className="PQR_noleft">
                    Ultimate load <br />N
                  </th>
                  <th className="PQR_noleft">
                    Ultimate stress <br />
                    Mpa
                  </th>
                  <th className="PQR_noleft">Type of failure and location</th>
                </tr>

                {Tensile_Test()}
              </table>
              <table className="PQRTablePg2" >
                <tr>
                  <th className="PQR_border" style={{width:"80px" , textAlign:"left"}}>Remarks</th>
                  <td
                    colSpan=""
                    className="PQR_noleft"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_Tensile_RemarksHorizontal}
                  </td>
                </tr>
                <tr>
                  <th className="PQR_noTop" style={{width:"80px" , textAlign:"left"}}>Report Number</th>
                  <td
                    colSpan=""
                    className="PQR_Botmrt"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_Tensile_ReportNumHorizontal}
                  </td>
                </tr>
              </table>
            </>
          ) : (
            <>
              <div className="group">
                <div className="PQRbasemetal" style={{ width: { width } }}>
                GUIDED BEND TEST(QW-160)
                </div>
              </div>
              <table className="PQRTablePg2" style={{ width: { width } }}>
                <tr>
                  <th className="PQR_border" style={{ width: "30%" }}>
                    Nos. of specimen
                  </th>
                  <th className="PQR_noleft" style={{ width: "13%" }}>
                    Thk <br />(mm)
                  </th>
                  <th className="PQR_noleft" style={{ width: "15%" }}>
                    Breadth <br /> (mm)
                  </th>
                  <th className="PQR_noleft" style={{ width: "17%" }}>
                    Former Diameter <br />(mm)
                  </th>
                  <th className="PQR_noleft" style={{ width: "15%" }}>
                    Bend Angle <br />(deg)
                  </th>
                  <th className="PQR_noleft" style={{ width: "30%" }}>
                    Acceptance Criteria
                  </th>
                  <th className="PQR_noleft" style={{ width: "30%" }}>
                    Remark
                  </th>
                </tr>
                {Guided_Bendoverlay()}
              </table>
              <table className="PQRTablePg2" style={{ width: { width } }}>
                <tr style={{ width: "15%" }}>
                  <th className="PQR_border" style={{width:"80px" , textAlign:"left", textAlign:"left"}}>Remarks</th>
                  <td
                    colSpan=""
                    className="PQR_noleft"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_GB_RemarksHorizontal}
                  </td>
                </tr>
                <tr style={{ width: "15%" }}>
                  <th className="PQR_noTop" style={{width:"80px" , textAlign:"left"}}>Report Number</th>
                  <td
                    colSpan=""
                    className="PQR_Botmrt"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_GB_ReportNumHorizontal}
                  </td>
                </tr>
              </table>
            </>
          )}

          {/* --------------------------------------------Table 2 --------------------------------------------------------------*/}
          {!isoverlay ? (
            <>
              <div className="group">
                <div className="PQRbasemetal">GUIDED BEND TEST(QW-160)</div>
              </div>
              <table className="PQRTablePg2">
                <tr>
                  <th className="PQR_border">Type of test</th>
                  <th className="PQR_noleft">Acceptance criteria</th>
                  <th className="PQR_noleft">Result</th>
                  <th className="PQR_noleft">Remarks</th>
                </tr>
                {Guided_Bend()}
              </table>
              <table className="PQRTablePg2" style={{ width: { width } }}>
                <tr style={{ width: "15%" }}>
                  <th className="PQR_border" style={{width:"80px" , textAlign:"left"}}>Remarks</th>
                  <td
                    colSpan=""
                    className="PQR_noleft"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_GB_RemarksHorizontal}
                  </td>
                </tr>
                <tr style={{ width: "15%" }}>
                  <th className="PQR_noTop" style={{width:"80px" , textAlign:"left"}}>Report Number</th>
                  <td
                    colSpan=""
                    className="PQR_Botmrt"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_GB_ReportNumHorizontal}
                  </td>
                </tr>
              </table>
            </>
          ) : (
            ""
          )}

          {/* -----------------------------------------------------Table 3--------------------------------------------------------------- */}
          {!isoverlay ? (
            <>
              <div className="group">
                <div className="PQRbasemetal" style={{ width: { width } }}>
                  TOUGHNESS TEST(QW-170)
                </div>
              </div>
              <table className="PQRTablePg2" style={{ width: { width } }}>
                <tr>
                  <th className="PQR_noBotm">Specimen number</th>
                  <th className="PQR_Toprt">Notch location</th>
                  <th className="PQR_Toprt">Notch type</th>
                  <th className="PQR_Toprt">Specimen size</th>
                  <th className="PQR_Toprt">
                    Test temp <br />
                  </th>
                  <th className="PQR_Toprt" colSpan="2">
                    Impact values in J{" "}
                  </th>
                  <th className="PQR_Toprt">Lateral Expansion</th>
                  <th className="PQR_Toprt">% of shear</th>
                </tr>
                <tr>
                  <th className="PQR_noTop" style={{ width: "15%" }}>
                    {" "}
                  </th>
                  <th className="PQR_rt_botm" style={{ width: "12%" }}>
                    {" "}
                  </th>
                  <th className="PQR_rt_botm" style={{ width: "12%" }}></th>
                  <th className="PQR_noleft_topDot" style={{ width: "13%" }}>
                    mm
                  </th>
                  <th className="PQR_noleft_topDot" style={{ width: "10%" }}>
                    °C
                  </th>
                  <th className="PQR_noleft_topDot" style={{ width: "8%" }}>
                    Values
                  </th>
                  <th className="PQR_noleft_topDot" style={{ width: "8%" }}>
                    {" "}
                    Avg{" "}
                  </th>
                  <th className="PQR_noleft_topDot" style={{ width: "13%" }}>
                    mm
                  </th>
                  <th className="PQR_rt_botm" style={{ width: "13%" }}>
                    {" "}
                  </th>
                </tr>

                {Toughness_Test()}
              </table>
              <table className="PQRTablePg2" style={{ width: { width } }}>
                <tr>
                  <th className="PQR_border" style={{width:"80px" , textAlign:"left"}}>Remarks</th>
                  <td
                    colSpan=""
                    className="PQR_noleft"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_TT_RemarksHorizontal}
                  </td>
                </tr>
                <tr>
                  <th className="PQR_noTop" style={{width:"80px" , textAlign:"left"}}>Report Number</th>
                  <td
                    colSpan=""
                    className="PQR_Botmrt"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_TT_ReportNumHorizontal}
                  </td>
                </tr>
              </table>
            </>
          ) : (
            ""
          )}

          {/* --------------------------------------------Table 4-------------------------------------------------------- */}
          {!isoverlay ? (
          <><div className="group">
              <div className="PQRbasemetal" style={{ width: { width } }}>
                HARDNESS TEST
              </div>
            </div><table className="PQRTablePg2" style={{ width: { width } }}>
                <tr>
                  <th style={{ width: "20%" }}className="PQR_border">Type <br />(Scale)</th>
                  <th style={{ width: "10%" }}className="PQR_noleft">Location</th>
                  <th style={{ width: "15%" }}className="PQR_noleft">Parent Metal <br />(Left)</th>
                  <th style={{ width: "10%" }}className="PQR_noleft">HAZ <br /> (Left)</th>
                  <th style={{ width: "10%" }}className="PQR_noleft">Weld</th>
                  <th style={{ width: "10%" }}className="PQR_noleft">HAZ <br />(Right)</th>
                  <th style={{ width: "15%" }}className="PQR_noleft">Parent Metal <br />(Right)</th>
                  <th style={{ width: "20%" }}className="PQR_noleft">Remarks</th>
                </tr>

                {Hardness_Test()}
              </table><table className="PQRTablePg2" style={{ width: { width } }}>
                <tr>
                  <th className="PQR_border" style={{ width: "80px", textAlign: "left" }}>Remarks</th>
                  <td
                    colSpan=""
                    className="PQR_noleft"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_HT_RemarksHorizontal}
                  </td>
                </tr>
                <tr>
                  <th className="PQR_noTop" style={{ width: "80px", textAlign: "left" }}>Report Number</th>
                  <td
                    colSpan=""
                    className="PQR_Botmrt"
                    style={{ textAlign: "left" }}
                  >
                    {pdf[0]?.PQR_HT_ReportNumHorizontal}
                  </td>
                </tr>
              </table></>) : ""}

          {/* --------------------------------------------Table 5-------------------------------------------------------- */}
          <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
              OTHER TEST
            </div>
          </div>
          <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "15%" }}>
                Type of test
              </th>
              <th className="PQR_noleft" style={{ width: "18%" }}>
                Acceptance criteria
              </th>
              <th className="PQR_noleft" style={{ width: "33%" }}>
                Result
              </th>
              <th className="PQR_noleft" style={{ width: "34%" }}>
                Remarks
              </th>
            </tr>

            {Other_Test()}
          </table>
          <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{width:"80px" , textAlign:"left"}}>Remarks</th>
              <td
                colSpan=""
                className="PQR_noleft"
                style={{ textAlign: "left" }}
              >
                {pdf[0]?.PQR_OT_RemarksHorizontal}
              </td>
            </tr>
            <tr>
              <th className="PQR_noTop" style={{width:"80px" , textAlign:"left"}}>Report Number</th>
              <td
                colSpan=""
                className="PQR_Botmrt"
                style={{ textAlign: "left" }}
              >
                {pdf[0]?.PQR_OT_ReportNumHorizontal}
              </td>
            </tr>
          </table>
          {/* -----======================================================== */}
          <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
              CERTIFICATION
            </div>
          </div>

          <div style={{ width: "100%", }}>
            {/* <div style={{ float: "left", width: "50%" }}> */}
              <table
                className="PQRTablePg2"
                style={{ width: "310px", float: "left" ,borderBottom:"1px solid black"}}
              >
                <tr style={{borderBottom:"1px solid black"}}>
                  <th className="PQR_border" style={{width:"100px"}}>
                    Welders Name
                  </th>
                  <th className="PQR_noleft" style={{width:"45px"}}>
                    Id
                  </th>
                  <th className="PQR_noleft" style={{width:"60px"}}>
                    Process
                  </th>
                </tr>
 
                {Certification()}
              </table>
              <table
                className="PQRCertificationTable"
                style={{width:"260px" }}
              >
                <tr>
                  <th className="PQR_noleft" style={{ textAlign:"left",width:"85px",paddingLeft:"0px" }}>
                    Tests conducted by
                  </th>
                  <td className="PQR_noleft" style={{ textAlign:"left",wordBreak:"none",width:"50px" }} >
                      {pdf[0]?.PQR_Certification_ConductedBy}
                   
                  </td>
                </tr>
                <tr>
                  <th className="PQR_right" style={{ textAlign:"left" }}>
                    Mechanical tests by
                  </th>
                  <td className="PQR_right" style={{ textAlign:"left",wordBreak:"none"}}>
                      {pdf[0]?.PQR_Certification_Mechanical_TestsBy}
                  </td>
                </tr>
                <tr>
                  <th className="PQR_noleft" style={{ textAlign:"left" }} colspan={2}>
                    Laboratory ref. number
                  </th>
                  </tr>
                  {CertificationLabRef()}
              </table>
              {/* <br /> */}
               
         
          {pdf[4] ? 
          <div id="footer" style={{ position: "fixed", top: "3000px" }}>        
           <PQR_Footer
          pdf={pdf}
          previewuser={previewuser}
          previewapproved={previewapproved}
        ></PQR_Footer>
</div>  : 
        <div id="footer" style={{ position: "fixed", top: "1980px" }}>
          <PQR_Footer
            pdf={pdf}
            previewuser={previewuser}
            previewapproved={previewapproved}
          ></PQR_Footer>
</div> }
          {/* <!-- -------------------------------------------Table 3 --------------------------------------------------------------> */}
          {/* <br></br> */}
          {/* <br></br> */}
          <br></br>
        </div>
         </div>
             <div className="PQRcertifont">We certify that the statement in this record are correct and that the test welds were prepared, welded and tested in the accordance with the requirements of Section IX of the ASME code
             </div>
        {pdf[4] ?
        <div id="footer" style={{ position: "fixed", top: "3080px" }}>
          <table className="WPSfooter">
            <tr>
             <td>Page 3 of 3</td> 
            </tr>
          </table>
        </div> : <div id="footer" style={{ position: "fixed", top: "2055px" }}>
          <table className="WPSfooter">
            <tr>
             <td>Page 2 of 2</td> 
            </tr>
          </table>
        </div>}
      </div>
      
    </div>
  );
};

export default Groove_pdf_page2PQR;
