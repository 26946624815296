
import Export from "../../icons/Export.svg";

import exportFromJSON from "export-from-json";
const GolbalExport = (props) => {

  return (
    <img
      // hidden={excel === false ? true : false}
      src={Export}
      alt="Export"
    // onClick={handleExport}
    />
  );
};

const handleExport1 = (filteredResult, filename, colName, Fieldname, tmpcolumnDefs) => {

  let data = [];
  for (let n = 0; n < filteredResult.length; n++) {
    let tmpdata = []
    for (let i = 0; i < colName.length; i++) {

      let tmpcol = colName[i]["headerName"];
      let tmpfield = colName[i]["field"];
      if (i == 0) {
        tmpdata.push({
          [tmpcol]: filteredResult[n][tmpfield]
        })
      }
      else {
        tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: filteredResult[n][tmpfield] }));
      }

    }

    data = [...data, ...tmpdata]


  }


  const fileName = filename;
  const exportType = "csv";

  exportFromJSON({ data, fileName, exportType });
};
export default GolbalExport;
export { handleExport1 };
