import React, { useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { WCRexportreport, getLastWeldDate } from "../../Services/WI-services";
import { toast } from "react-toastify";

const WCR_PDF = (props) => {

  const pdfRef = useRef();

  const exportWcr = async (data) => {
    try {


      const result = await WCRexportreport(data);
      
      if (props.rowdata?.WI_Last_Weld) {
        const latestData = await getLastWeldDate(props?.rowdata);
       
        generatePdf(latestData);

      } else {

        generatePdf(result);

      }
    } catch (error) {
      console.error("Error exporting WCR report:", error);
    }
  };


  const createPDF = () => {
    exportWcr(props?.rowdata);
  };

  useEffect(() => {
    createPDF();
  }, []);

  const generatePdf = (Data) => {
    if (Data.length > 0) {
      const doc = new jsPDF();
      const groupedData = groupBy(Data, "WI_Welder_Id");
  
      Object.keys(groupedData).forEach((welderId, index) => {
        if (index > 0) {
          doc.addPage();
        }
  
        const heading = "WELDER CONTINUITY RECORD";
        doc.setFontSize(10);
        doc.setFont("bold");
        doc.text(heading, 90, 20);
  
        const Companyname = "Godrej & Boyce Mfg. Co. Ltd";
        doc.setFontSize(10);
        doc.text(Companyname, 15, 20);
  
        const CurrentDate = `As on : ${new Date().toLocaleDateString("en-GB")}`;
        doc.setFontSize(10);
        doc.text(CurrentDate, 167, 25);
  
        const header = [
          "Sr No",
          "Welder Code",
          "Welder Name",
          "Process / Type",
          "Plant / Slip No",
          "Weld Date",
          "Valid Upto",
        ];
  
        const uniqueWelderInfo = {
          [welderId]: {
            srNo: index + 1,
            id: groupedData[welderId][0].WI_Welder_Id,
            name: groupedData[welderId][0].WI_Welder_Name,
          },
        };
        for(let welderId in groupedData) {

          groupedData[welderId].sort((a,b) => 
            a.WI_Process.localeCompare(b.WI_Process)
          || new Date(b.WI_SlipDate) - new Date(a.WI_SlipDate));
        }
        const tableData = groupedData[welderId].map((data, dataIndex) => {
          const slipDate = new Date(data.WI_SlipDate);
          const formattedSlipDate = slipDate.toLocaleDateString("en-GB");
          const validUptoDate = new Date(
            slipDate.getTime() + 178 * 24 * 60 * 60 * 1000
          );
          const formattedValidUpto = validUptoDate.toLocaleDateString("en-GB");
          let processcat = (data.WI_ProcessCategory || "").split("#")
          let category = processcat[0]
  
          return [
            dataIndex === 0 ? uniqueWelderInfo[welderId].srNo + dataIndex : "", 
            dataIndex === 0 ? uniqueWelderInfo[welderId].id : "",
            dataIndex === 0 ? uniqueWelderInfo[welderId].name : "", 
            `${data.WI_Process === "GTAW" ? data.WI_Process : category} / ${data.WI_Type || ""}`, 
            `${data.WI_plant} / ${data.WI_Slip}`, 
            formattedSlipDate,
            formattedValidUpto,
          ];
        });
  
        const startYPosition = 28;
        const footerheight = 40;
  
        doc.autoTable({
          head: [header],
          body: tableData,
          theme: "plain",
          startY: startYPosition,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            cellPadding: { top: 0, right: 2, bottom: 0, left: 2 },
            lineWidth: 0.1,
            font: "bold",
            textColor: "#333333",
          },  
          margin: {top:20, bottom: footerheight},
          didDrawPage: (data) => {
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text("____________________________________________________________________________________________________", 15, doc.internal.pageSize.height - 32);
            doc.text("Note : The Qualification of above welder will expire & will have to requalified if he has not welded with the above", 15, pageHeight - 28);
            doc.text("process during the period of next six months", 15, pageHeight - 23);
            doc.text("Maintained By : Welding Engineering Department", 15, pageHeight - 18);
          }
        });
      });
  
      // Fetch the total number of pages once all pages have been added
      const pageCount = doc.internal.getNumberOfPages();

  
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
  
        // Add the page number
        doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 10);
      }
  
      toast.success("Successfully Exported.", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
  
      doc.save("WCR_Report.pdf");
  
    } else {
      toast.info("There are no records matching to filters.", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
  };
  
  
  const groupBy = (arr, key) => {
    return arr.reduce((acc, obj) => {
      const groupKey = obj[key];
      (acc[groupKey] = acc[groupKey] || []).push(obj);
      return acc;
    }, {});
  };

  return <div ref={pdfRef}></div>;
};

export default WCR_PDF;
