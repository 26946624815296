import React from "react";

import Delete_gray from "../../icons/Delete_gray.svg";

import Error from "../../icons/ErrorIcon.svg";

import Delete from "../../icons/DeleteIcon.svg";
export default (props) => {

  return (
    <>
      {props.selectedData.data.ErrorMessage &&
        props.selectedData.data.ErrorMessage != "" && (
<>
          <button style={{background:"none", border:"none"}} type="button" data-bs-toggle="tooltip" data-bs-placement="top" title= {props.selectedData.data.ErrorMessage}>
       
            <img src={Error} alt="Error" className="ms-1" />
        
          </button>
         
          </>
        )}

      <div className="cell-action">
       

        {props.gridPage ? (
          <img
            src={Delete_gray}
            onMouseOver={(e) => (e.currentTarget.src = Delete)}
            onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
            alt="delete"
            className="p-1"
            onClick={() => props.handleDeleteEvent(props.selectedData)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
