import Tooltip_detail from "../../utility/Tooltip_detail";
import Img from "../../../icons/ErrorIcon.svg";
import { Row } from "react-bootstrap";


export const MessageTypes = {
  Success: "Success",
  Fail: "Failure",
  Required: "Required",
  RequiredAll: "RequiredAll",
  Temp: "Temp",
  Band: "Band",
  None: "None",
  SpecialCharacters : "SpecialCharacters"
};

export var PQR_Test = [
  { array: "testerror", Column: "PQR_WP_TypeOfTest", Type: "S" },
  { array: "testerror", Column: "PQR_WP_Acceptance_Criteria", Type: "S" },
  { array: "testerror", Column: "PQR_WP_Result", Type: "S" },
  { array: "testerror", Column: "PQR_WP_Comments", Type: "S" },
  { array: "testerror", Column: "PQR_WP_Report_Nummber", Type: "S" },
];

export var FM_Product_Form = [
  { value: "", name: "Select" },
  { value: "Not Applicable", name: "Not Applicable" },
  { value: "Solid", name: "Solid" },
  { value: "FLuxcored", name: "FLuxcored" },
  { value: "Metalcored", name: "Metalcored" },
];

export var Plant_Location = [
  { value: "", name: "Select" },
  { value: "Vikhroli", name: "Vikhroli" },
  { value: "Dahej", name: "Dahej" },
];
export var Company_Name = [
  { value: "", name: "Select" },
  { value: "Godrej & Boyce Mfg. Co Ltd", name: "Godrej & Boyce Mfg. Co Ltd" },
  { value: "ABC", name: "ABC" },
];
export var Layer_No = [
  { value: "NA", name: "NA" },
  { value: "As Req.", name: "As Req." },
  { value: "Root", name: "Root" },
  { value: "Hot Pass", name: "Hot Pass" },
  { value: "Fill Up", name: "Fill Up" },
  { value: "Capping", name: "Capping" },
];






export var Current_Polarity = [
  { value: "", name: "Select" },
  { value: "AC", name: "Ac" },
  { value: "Any", name: "Any" },
  { value: "DC", name: "DC" },
  { value: "DCEP", name: "DCEP" },
  { value: "DCEN", name: "DCEN" },
];

export var Backing = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "With", name: "With" },
  { value: "With/Without", name: "With/Without" },
];

export var Backing_Gas = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "With", name: "With" },
  { value: "With/Without", name: "With/Without" },
  { value: "Without", name: "Without" },
];



export var Backing_Material = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  {
    value: "Base Metal and/or Weld metal",
    name: "Base Metal and/or Weld metal",
  },
];







export var TypeData = [
  { wld_type: "Automatic" },
  { wld_type: "Machine" },
  { wld_type: "Manual" },
  { wld_type: "SemiAuto" },
];



export const Action = {
  ActionEdit: "Edit",

  ActionCopy: "Copy",

  ActionAdd: "Add",
};

export const Status = {
  StatusPending: "Pending For Approval",
  StatusApproved: "Approved",
  StatusRejected: "Rejected",
  StatusTempSaved: "Temporary Saved",
  StatusFreezed: "Freezed",
};

export var company_name = [
  { value: "Godrej & Boyce Mfg. Co. Ltd", name: "WPSH_CompanyName" },
];

export var division = [{ value: "PED" }];

// export var Process = [{ value: "GTAW", name: "WPSD_Process" }];

export var WeldedCoupon = [
  { PQR_Welded_on_coupon: "WPQ Test Coupon" },
  { PQR_Welded_on_coupon: "PQR Test Coupon" },
];


export var process_name = [{ value: "GTAW", name: "WPSD_Process" }];





export const ToolError = ({ title }) => {
  return (
    <Tooltip_detail
      content="All Fields are Mandatory"
      id="tooltip-tab1"
      className="front"
    >
      <img src={Img} alt="Error" className="ms-1 front" />
      {title}
    </Tooltip_detail>
  );
};

export const ProcessError = ({ title }) => {
  return (
    <Row className="m" style={{ padding: "0px 43px 0px 20px" }}>
      <div className="errorstatusbg">
        <span className="errorstatusborder float-start">
          All Fields are Mandatory of {title}{" "}
        </span>
      </div>
    </Row>
  );
};



export const PQRHeader = (wpstype) => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const todayDate = yyyy + "-" + mm + "-" + dd;
  const maxDate = today;
  const minDate = yyyy + "-" + mm + "-" + dd;
  return {
    Wld_createdon_year: new Date().getFullYear().toString(),
    wld_WPSType: wpstype,
    CheckboxPostWHT:"",
    PQR_DOB: todayDate,
    PWHT:"Yes",
    Impact:"Yes",
    Hardess:"Yes",
    PQR_RecordNo: "",
    PQR_ReviNo: "",
    PQR_WPS_RecordNo: "",
    PQR_WPS_ReviNo: "",
    PQR_Date: todayDate,
    PQR_CompanyName: "",
    PQR_WeldingStandard: "",
    PQR_Status: "Active",
    wld_createdon: "",
    PQR_Last_UpdatedBy: "",

    PQR_BM_BM_ProductForm: "",
    PQR_BM_BM_Spec: "",
    PQR_BM_BM_Pno: "",
    PQR_BM_BM_Gno: "",
    PQR_BM_BM_Thk: "",
    PQR_BM_BM_Dia: "",
    PQR_BM_BM_Sch:"",
    PQR_BM_BM_Size:"",

    PQR_BM_WTBM_ProductForm:"",
    PQR_BM_WTBM_Spec: "",
    PQR_BM_WTBM_Pno: "",
    PQR_BM_WTBM_Gno: "",
    PQR_BM_WTBM_Thk: "",
    PQR_BM_WTBM_Dia: "",
    PQR_BM_WTBM_Sch:"",
    PQR_BM_WTBM_Size:"",

    // PQR_BM_BM_AndTested: "",
    PQR_BM_BM_Condition1: "",
    PQR_BM_BM_Condition2: "",
    PQR_BM_BM_Notes: "",

    PQR_PWHeatTre_Temperature: "",
    PQR_PWHeatTre_Time: "",
    PQR_PWHeatTre_Type: "",
    PQR_PWHeatTre_Heating_Rate: "",
    PQR_PWHeatTre_Heating_Method: "",
    PQR_PWHeatTre_Cooling_Rate: "",
    PQR_PWHeatTre_Cooling_Method: "",
    PQR_PWHeatTre_Notes: "",
    PQR_PWHeatTre_Maintenance: "",

    PQR_JD_JointDesign: "",
    PQR_JD_Backing: "",
    PQR_JD_Retainers: "",
    PQR_JD_GrooveAngle: "",
    // PQR_JD_RootOpening: "",
    // PQR_JD_RootFace: "",
    PQR_JD_Notes: "",

    WPSH_Status: Status.StatusTempSaved,
    WPSH_Approval_Status: Status.StatusTempSaved,

    PQR_DocImage: "",
    PQR_DocImageOriginal: "",

    PQR_DocImageUrl: "",

    PQR_DocImageData: {
      pdfsize: "",
      pdfname: "",
      pdftype: "",
    },
    wld_pdf_image: [],
    Pdf_images: [{ pqr_image1: [""] }, { pqr_image2: [""] }],

    WPSH_PreparedBy_Date: todayDate,
    WPSH_PreparedBy_Name: "",
    WPSH_ApprovedBy_Date: todayDate,
    WPSH_ApprovedBy_Name: "",
    WPSH_ApprovedBy_Remark: "",
    WPSH_PreparedBy_Remark: "",
    WPSH_PreparedBy: sessionStorage.getItem("User"),
    WPSH_UpdatedBy: "",
    WPSH_UpdatedOn: "",
    WPSH_ApprovedBy: sessionStorage.getItem("UserName"),

    PQR_Tensile_RemarksHorizontal: "",
    PQR_GB_RemarksHorizontal: "",
    PQR_TT_RemarksHorizontal: "",
    PQR_HT_RemarksHorizontal: "",
    PQR_OT_RemarksHorizontal: "",

    PQR_Certification_Welder_Name: "",
    PQR_Certification_ID: "",
    PQR_Certification_ConductedBy: "",
    PQR_Certification_Mechanical_TestsBy: "",
    PQR_Certification_Lab_RefNo: "",

    PQR_SignaturePrepared:"",
    PQR_SignatureApproved:"",

    WPSH_WeldingEnggBy_Date: todayDate,
    WPSH_WeldingEnggBy_Name: "",
    WPSH_WeldingEnggBy: "",
    WPSH_WitnessBy_Date: todayDate,
    WPSH_WitnessBy_Name: "",
    WPSH_WitnessBy: "",
    PQR_WelderId: "",
    WPS_Key: "",
    PQR_WelderName: "",
    WPSH_PQR_Classification:"",
    WPSH_PQR_TradeName:"",
    WPSH_PQR_Process:"",
    WPSH_PQR_Type:"",
  };
};

export var PQR_GroovePage1 = {
  //now commenting non mandatory fields
  PQR_RecordNo: "",
  // PQR_BM_BM_AndTested: "",
  PQR_BM_BM_Condition1: "",
  PQR_BM_BM_Condition2: "",
  PQR_BM_BM_Notes: "",

  PQR_JD_JointDesign: "",
  PQR_JD_Backing: "",
  PQR_JD_Retainers: "",
  PQR_JD_GrooveAngle: "",
  PQR_JD_RootOpening: "",
  PQR_JD_RootFace: "",
  PQR_JD_Notes: "",


};

export var PQR_GrooveCheckbox = {
  //now commenting non mandatory fields
  PQR_PWHeatTre_Temperature: "",
  PQR_PWHeatTre_Time: "",
  PQR_PWHeatTre_Type: "",
  PQR_PWHeatTre_Heating_Rate: "",
  PQR_PWHeatTre_Heating_Method: "",
  PQR_PWHeatTre_Cooling_Rate: "",
  PQR_PWHeatTre_Cooling_Method: "",
  PQR_PWHeatTre_Notes: "",
  PQR_PWHeatTre_Maintenance: "",
};

export var PQR_Mandatory_Process = {
  PQR_Process: "",
  PQR_Type: "",
  PQR_Layer: "",
};

export var PQR_Detail_OverlayPage1 = [
  { Column: "PQRD_FM_SFA", Type: "S" },
  { Column: "PQRD_FM_Classification", Type: "S" },
  { Column: "PQRD_FM_FNo", Type: "S" },
  { Column: "PQRD_FM_Ano", Type: "S" },
  { Column: "PQRD_FM_Nominal", Type: "S" },
  { Column: "PQRD_FM_TradeName", Type: "S" },
  { Column: "PQRD_FM_ProductForm", Type: "S" },
  { Column: "PQRD_FM_FillerMetalSize", Type: "A" },
  { Column: "PQRD_FM_DepositedThickness", Type: "S" },
  { Column: "PQRD_FM_MaxPassThickness", Type: "S" },

  { Column: "PQRD_FM_WeldDepositChemistry", Type: "S" },
  { Column: "PQRD_FM_FluxAWS", Type: "S" },
  { Column: "PQRD_FM_FluxType", Type: "S" },
  { Column: "PQRD_FM_FluxNominalComposition", Type: "S" },
  { Column: "PQRD_FM_FluxTradeName", Type: "S" },
  { Column: "PQRD_FM_FluxRecrushed", Type: "S" },
  { Column: "PQRD_FM_Supplement", Type: "S" },
  { Column: "PQRD_FM_FillerMetalOthers", Type: "S" },
  { Column: "PQRD_Position_PositionofGroove", Type: "A" },
  { Column: "PQRD_Position_WeldProgression", Type: "A" },

  { Column: "PQRD_Preheat_MinimumPreheatTemp", Type: "S" },
  { Column: "PQRD_Preheat_MaximumInterpassTemp", Type: "S" },

  { Column: "PQRD_Gas_ShieldingType", Type: "S" },
  { Column: "PQRD_Gas_ShieldingFlowRate", Type: "S" },

  // { Column: "PQRD_Gas_BackingType", Type: "S" },
  // { Column: "PQRD_Gas_BackingFlowRate", Type: "S" },

  // { Column: "PQRD_Gas_TrailingType", Type: "S" },
  // { Column: "PQRD_Gas_TrailingFlowRate", Type: "S" },

  { Column: "PQRD_Electrical_FillerMetalSize", Type: "A" },
  { Column: "PQRD_Electrical_Amperes", Type: "A" },
  { Column: "PQRD_Electrical_Volts", Type: "A" },
  { Column: "PQRD_Electrical_TravelSpeed", Type: "A" },
  { Column: "PQRD_Electrical_MaxHeatInput", Type: "A" },
  { Column: "PQRD_Electrical_TungstenType", Type: "A" },
  { Column: "PQRD_Electrical_CurrentPolarity", Type: "A" },
  { Column: "PQRD_Electrical_DCPulsatingCurrent", Type: "A" },

  { Column: "PQRD_Electrical_WireFeedType", Type: "A" },
  { Column: "PQRD_Electrical_WireFeedSpeed", Type: "A" },

  { Column: "PQRD_Technique_Oscillation", Type: "S" },
  { Column: "PQRD_Technique_ElectrodesSamePuddle", Type: "S" },
  { Column: "PQRD_Technique_Multiple_SingleLayer", Type: "S" },
  { Column: "PQRD_Technique_SupplimentalDevice", Type: "S" },
  { Column: "PQRD_Technique_InitialInterpassCleaning", Type: "S" },
  { Column: "PQRD_Technique_Overlaping", Type: "S" },
  { Column: "PQRD_Technique_CTWD", Type: "S" },



];

export var PQR_Detail_GroovePage1 = [
  { Column: "PQRD_FM_SFA", Type: "S" },
  { Column: "PQRD_FM_Classification", Type: "S" },
  { Column: "PQRD_FM_FNo", Type: "S" },
  { Column: "PQRD_FM_Ano", Type: "S" },
  { Column: "PQRD_FM_Nominal", Type: "S" },
  { Column: "PQRD_FM_TradeName", Type: "S" },
  { Column: "PQRD_FM_ProductForm", Type: "S" },
  { Column: "PQRD_FM_FillerMetalSize", Type: "A" },
  { Column: "PQRD_FM_DepositedThickness", Type: "S" },
  { Column: "PQRD_FM_MaxPassThickness", Type: "S" },

  // { Column: "PQRD_FM_WeldDepositChemistry", Type: "S" },
  { Column: "PQRD_FM_FluxAWS", Type: "S" },
  // { Column: "PQRD_FM_FluxNominalComposition", Type: "S" },
  { Column: "PQRD_FM_FluxTradeName", Type: "S" },
  { Column: "PQRD_FM_FluxType", Type: "S" },

  { Column: "PQRD_FM_FluxRecrushed", Type: "S" },
  { Column: "PQRD_FM_Supplement", Type: "S" },
  { Column: "PQRD_FM_FillerMetalOthers", Type: "S" },
  { Column: "PQRD_Position_PositionofGroove", Type: "A" },
  { Column: "PQRD_Position_WeldProgression", Type: "A" },

  { Column: "PQRD_Preheat_MinimumPreheatTemp", Type: "S" },
  { Column: "PQRD_Preheat_MaximumInterpassTemp", Type: "S" },

  { Column: "PQRD_Gas_ShieldingType", Type: "S" },
  { Column: "PQRD_Gas_ShieldingFlowRate", Type: "S" },

  { Column: "PQRD_Gas_BackingType", Type: "S" },
  { Column: "PQRD_Gas_BackingFlowRate", Type: "S" },

  { Column: "PQRD_Gas_TrailingType", Type: "S" },
  { Column: "PQRD_Gas_TrailingFlowRate", Type: "S" },

  { Column: "PQRD_Electrical_FillerMetalSize", Type: "A" },
  { Column: "PQRD_Electrical_Amperes", Type: "A" },
  { Column: "PQRD_Electrical_Volts", Type: "A" },
  { Column: "PQRD_Electrical_TravelSpeed", Type: "A" },
  { Column: "PQRD_Electrical_minBeadLength", Type: "A" },
  { Column: "PQRD_Electrical_ArcTransferMode", Type: "A" },
  { Column: "PQRD_Electrical_MaxHeatInput", Type: "A" },
  { Column: "PQRD_Electrical_TungstenType", Type: "A" },
  { Column: "PQRD_Electrical_CurrentPolarity", Type: "A" },
  { Column: "PQRD_Electrical_DCPulsatingCurrent", Type: "A" },

  { Column: "PQRD_Technique_StringOrWeave", Type: "S" },
  { Column: "PQRD_Technique_Orifice_GasCupSize", Type: "S" },
  { Column: "PQRD_Technique_CTWD", Type: "S" },

  { Column: "PQRD_Technique_Oscillation", Type: "S" },
  { Column: "PQRD_Technique_Multi_SingleElectrode", Type: "S" },
  { Column: "PQRD_Technique_ElectrodeAngle", Type: "S" },
  { Column: "PQRD_Technique_SinglePassPerSide", Type: "S" },
  { Column: "PQRD_Technique_Multiple_SingleLayer", Type: "S" },
  { Column: "PQRD_Technique_Peening", Type: "S" },
  { Column: "PQRD_Technique_InitialInterpassCleaning", Type: "S" },
  { Column: "PQRD_Technique_Technique_Other", Type: "S" },
  { Column: "PQRD_Technique_BackGaugingMethod", Type: "S" },
  { Column: "PQRD_Technique_GroovePreparation", Type: "S" },
  









  
];


export var PQR_field_Val = [
  { Column: "PQRD_FM_FillerMetalSize", Type: "A" },
  { Column: "PQRD_Position_PositionofGroove", Type: "A" },
  { Column: "PQRD_Position_WeldProgression", Type: "A" },
  { Column: "PQRD_Electrical_Amperes", Type: "A" },
  { Column: "PQRD_Electrical_FillerMetalSize", Type: "A" },
  { Column: "PQRD_Electrical_Volts", Type: "A" },
  { Column: "PQRD_Electrical_TravelSpeed", Type: "A" },
  { Column: "PQRD_Electrical_ArcTransferMode", Type: "A" },
  { Column: "PQRD_Electrical_minBeadLength", Type: "A" },
  { Column: "PQRD_Electrical_MaxHeatInput", Type: "A" },
  { Column: "PQRD_Electrical_TungstenType", Type: "A" },
  { Column: "PQRD_Electrical_CurrentPolarity", Type: "A" },
  { Column: "PQRD_Electrical_DCPulsatingCurrent", Type: "A" },
 
];

export var PQR_field_ValOverlay = [
  { Column: "PQRD_FM_FillerMetalSize", Type: "A" },
  { Column: "PQRD_Position_PositionofGroove", Type: "A" },
  { Column: "PQRD_Electrical_Amperes", Type: "A" },
  { Column: "PQRD_Position_WeldProgression", Type: "A" },
  { Column: "PQRD_Electrical_FillerMetalSize", Type: "A" },
  { Column: "PQRD_Electrical_Volts", Type: "A" },
  { Column: "PQRD_Electrical_TravelSpeed", Type: "A" },
  { Column: "PQRD_Electrical_MaxHeatInput", Type: "A" },
  { Column: "PQRD_Electrical_TungstenType", Type: "A" },
  { Column: "PQRD_Electrical_CurrentPolarity", Type: "A" },
  { Column: "PQRD_Electrical_DCPulsatingCurrent", Type: "A" },
  { Column: "PQRD_Electrical_WireFeedType", Type: "A" },
  { Column: "PQRD_Electrical_WireFeedSpeed", Type: "A" },
 
];


export var PQR_GroovePage2 = {
  PQR_Tensile_RemarksHorizontal: "",
  PQR_GB_RemarksHorizontal: "",
  PQR_TT_RemarksHorizontal: "",
  PQR_HT_RemarksHorizontal: "",
  PQR_OT_RemarksHorizontal: "",
  PQR_Tensile_ReportNumHorizontal: "",
  PQR_GB_ReportNumHorizontal: "",
  PQR_TT_ReportNumHorizontal: "",
  PQR_HT_ReportNumHorizontal: "",
  PQR_OT_ReportNumHorizontal: "",

  // PQR_Certification_Welder_Name: "",
  // PQR_Certification_ID: "",
  PQR_Certification_ConductedBy: "",
  PQR_Certification_Mechanical_TestsBy: "",
  // PQR_Certification_Lab_RefNo: "",


};
export var PQR_OverlayPage2 = {
  PQR_GB_RemarksHorizontal: "",
  // PQR_HT_RemarksHorizontal: "",
  PQR_OT_RemarksHorizontal: "",

  // PQR_Certification_Welder_Name: "",
  // PQR_Certification_ID: "",
  PQR_Certification_ConductedBy: "",
  PQR_Certification_Mechanical_TestsBy: "",
  // PQR_Certification_Lab_RefNo: "",


};

export var PQR_OverlayPage1 = {
  //now commenting non mandatory fields
  PQR_RecordNo: "",
  // PQR_BM_BM_AndTested: "",
  PQR_BM_BM_Notes: "",

  

  PQR_JD_JointDesign: "",
  PQR_JD_Notes: "",
};

export var PQR_OverlayCheckbox = {
  PQR_PWHeatTre_Temperature: "",
  PQR_PWHeatTre_Time: "",
  PQR_PWHeatTre_Type: "",
  PQR_PWHeatTre_Heating_Rate: "",
  PQR_PWHeatTre_Heating_Method: "",
  PQR_PWHeatTre_Cooling_Rate: "",
  PQR_PWHeatTre_Cooling_Method: "",
  PQR_PWHeatTre_Notes: "",
  PQR_PWHeatTre_Maintenance: "",
};

export var PQR_Groove_Detail = [
  { Column: "PQR_Detail_Backing", Type: "S" },

  { Column: "PQR_FM_Specification", Type: "S" },

  { Column: "PQR_FM_Classification", Type: "S" },

  { Column: "PQR_FM_filler_Wire", Type: "S" },

  { Column: "PQR_FM_F_No", Type: "S" },
  { Column: "PQR_FM_Arc_transfermode", Type: "S" },
  { Column: "PQR_FM_Consumable_Insert", Type: "S" },
  { Column: "PQR_FM_Product_Form", Type: "S" },
  { Column: "PQR_FM_No_of_LayerDeposit", Type: "S" },
  { Column: "PQR_FM_Thickness_Limit", Type: "S" },
  { Column: "PQR_FM_Welding_Position", Type: "S" },

  { Column: "PQR_FM_Progression", Type: "S" },
  { Column: "PQR_FM_Current_Polarity", Type: "S" },
  { Column: "PQR_FM_Backing_Gas", Type: "S" },

  { Column: "PQR_FM_Direct_VisualControl", Type: "S" },
  { Column: "PQR_FM_Automatic_jointTracking", Type: "S" },
  { Column: "PQR_FM_Single_Pass_PerSide", Type: "S" },

  { Column: "PQR_FM_Automatic_AVC_System", Type: "S" },
  { Column: "PQR_FM_Notes", Type: "S" },
];

export const PQRHeader_Tube = (wpstype) => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const todayDate = yyyy + "-" + mm + "-" + dd;
  const maxDate = today;
  const minDate = yyyy + "-" + mm + "-" + dd;
  return {
    Wld_createdon_year: new Date().getFullYear().toString(),
    wld_WPSType: wpstype,

    WPSH_PQR_Process: "",
    WPSH_PQR_Type: "",
    WPSH_PQR_Classification:"",
    WPSH_PQR_TradeName:"",
    PQR_CompanyName:"",
    CheckboxPostWHT:"",
    pullout_check:"",
    tearCheck:"",
    hardnessCheck:"",
    radioCheck:"",
    otherCheck:"",

    WPSH_Status: Status.StatusTempSaved,
    WPSH_Approval_Status: Status.StatusTempSaved,

    PQR_RecordNo: "",
    PQR_Date: todayDate,
    PQR_Qualificationcode: "",
    PQR_pWPSNo: "",
    PQR_WPS_ReviNo: "",
    PQR_LastUpdate_Date: todayDate,
    PQR_Last_UpdatedBy: "",
    // PQR_MatchStatus: "",
    PQR_Last_WeldDate: todayDate,
    PQR_Address: "",

    PQR_BM_BM_ProductForm: "",
    PQR_BM_BM_Spec: "",
    PQR_BM_BM_Pno: "",
    PQR_BM_BM_Gno: "",
    PQR_BM_BM_Thk: "",
    PQR_BM_BM_Dia: "",
    PQR_FM_PreFillerMetal: "",
    PQR_FM_WithFillerMetal: "",
    PQR_FM_FillerProduct: "",
    PQR_FM_Inserts: "",
    PQR_FM_FillerFlux: "",
    PQR_FM_FillerOthers: "",

    PQR_JD_JointType: wpstype === "Tubesheet" ? "Tube to Tubesheet" : "",
    PQR_JD_TubePitch: "",
    PQR_JD_TubeProj: "",
    PQR_JD_Pattern: "",
    PQR_JD_GrooveAngle: "",
    PQR_JD_RootOpening: "",
    PQR_JD_RootFace: "",
    PQR_JD_Notes: "",
    

    PQR_PWHeatTre_Temperature: "",
    PQR_PWHeatTre_Time: "",
    PQR_PWHeatTre_Type: "",
    PQR_PWHeatTre_Heating_Rate: "",
    PQR_PWHeatTre_Cooling_Rate: "",
    PQR_PWHeatTre_Notes: "",

    PQR_Certification_Welder_Name: "",
    PQR_Certification_ID: "",
    PQR_Certification_Process: "",
    PQR_Certification_ConductedBy: "",
    PQR_Certification_Mechanical_TestsBy: "",
    PQR_Certification_Lab_RefNo: "",

    PQR_DocImage: "",
    PQR_DocImageOriginal: "",
    PQR_DocImageUrl: "",
    PQR_DocImageData: {
      pdfsize: "",
      pdfname: "",
      pdftype: "",
    },
    wld_pdf_image: [],
    //wld_pdf_image: "",

    Pdf_images: [
      { tube_Hole_ligament: [""] },
      { tube_Hole: [""] },
      { tube_Weld_Joint: [""] },
    ],

    PQR_signaturePrepared: "",
    PQR_signatureApproved: "",

    WPSH_PreparedBy_Date: todayDate,
    WPSH_PreparedBy_Name: sessionStorage.getItem("UserName"),
    WPSH_PreparedBy_Remark: "",
    WPSH_PreparedBy: sessionStorage.getItem("User"),
    WPSH_ApprovedBy_Date: todayDate,
    WPSH_ApprovedBy_Name: "",
    WPSH_ApprovedBy_Remark: "",

    WPSH_UpdatedBy: "",
    WPSH_UpdatedOn: "",
    WPSH_ApprovedBy: "",

    WPSH_WeldingEnggBy_Date: todayDate,
    WPSH_WeldingEnggBy_Name: "",
    WPSH_WeldingEnggBy: "",

    WPSH_WitnessBy_Date: todayDate,
    WPSH_WitnessBy_Name: "",
    WPSH_WitnessBy: "",
  };
};
export var PQR_tubePage1 = {
  //now commenting non mandatory fields
  PQR_RecordNo: "",
  PQR_Qualificationcode: "",
  PQR_LastUpdate_Date: "",
  PQR_Last_UpdatedBy: "",
  // PQR_MatchStatus: "",
  PQR_Last_WeldDate: "",
  PQR_Address: "",

  
  PQR_JD_JointType: "",
  PQR_JD_TubePitch: "",
  PQR_JD_TubeProj: "",
  PQR_JD_Pattern: "",
  PQR_JD_GrooveAngle: "",
  PQR_JD_Notes: "",

  
  PQR_Image_Notes: "",
  PQR_Certification_Welder_Name: "",
  PQR_Certification_ID: "",
  PQR_Certification_ConductedBy: "",
  PQR_Certification_Mechanical_TestsBy: "",
  PQR_Certification_Lab_RefNo: "",

  PQR_PWHeatTre_Temperature: "",
  PQR_PWHeatTre_Time: "",
  PQR_PWHeatTre_Type: "",
  PQR_PWHeatTre_Heating_Rate: "",
  PQR_PWHeatTre_Cooling_Rate: "",
  PQR_PWHeatTre_Notes: "",
};

export var PQR_tubesheet_pg1_array = [
  { Column: "PQRD_WP_LayerNo", Type: "S" },
  { Column: "PQRD_WP_FillerMetalSize", Type: "S" },
  { Column: "PQRD_WP_Amperes", Type: "S" },
  { Column: "PQRD_WP_DC_Pulsing_current", Type: "S" },
  { Column: "PQRD_WP_DC_Pulsing_current_Others", Type: "S" },
  { Column: "PQRD_WP_Volatage_Volts", Type: "S" },
  { Column: "PQRD_WP_Travel_Speed", Type: "S" },
  { Column: "PQRD_WP_Heat_Input", Type: "S" },
  { Column: "PQRD_WP_PolarityOrCurrent", Type: "S" },
];

export var PQR_Tube_Page1_Process = {
  WPSD_Process: "GTAW",
  WPSD_FM_SFA: "",
  // WPSD_FM_FNo: "",
  // WPSD_FM_Ano: "",
};

export var PQR_Detail_TubePage1 = {
  // PQRD_WP_WeldingProcess: "",
  //PQRD_WP_Type: "",
  PQRD_WP_MinimumPreheatTemp: "",
  PQRD_WP_MaximumInterpassTemp: "",
  PQRD_WP_PostHeating: "",
  PQRD_WP_TungstenType: "",
  PQRD_WP_WeldPosition: "",
  PQRD_WP_WeldProgression: "",
  PQRD_WP_NoofPasses: "",

  ////PQRD_WP_ArcVoltageControl: "", non mandatory
  PQRD_WP_ShieldingGasType: "",
  PQRD_WP_ShieldingFlowRate: "",
  PQRD_WP_Auxiliarygas: "",
  PQRD_WP_Expansion: "",
  PQRD_WP_MethodofCleaning: "",
};

export var PQR_tubesheet_page1_2input = [
  { Column: "PQRD_WP_LayerNo", Type: "S" },
  { Column: "PQRD_WP_FillerMetalSize", Type: "S" },
  { Column: "PQRD_WP_Amperes", Type: "S" },
  { Column: "PQRD_WP_DC_Pulsing_current", Type: "S" },
  { Column: "PQRD_WP_DC_Pulsing_current_Others", Type: "S" },
  { Column: "PQRD_WP_Volatage_Volts", Type: "S" },
  { Column: "PQRD_WP_Travel_Speed", Type: "S" },
  { Column: "PQRD_WP_Heat_Input", Type: "S" },
  { Column: "PQRD_WP_PolarityOrCurrent", Type: "S" },
];

export var PQR_tubePage2 = {
  PQR_VEX_Notes: "",
  PQR_LPEX_Notes: "",
  PQR_MEX_Result: "",
  PQR_MLP_Result: "",
};

export var PQR_tubePage2array = {
  Visual_Examination: "",
};


export var PQR_tubePage3 = {
  PQR_Tear_Notes: "",

  PQR_PullOut_Notes: "",
  // PQR_PullOut_Calculation: "",
  PQR_PullOut_MinBreakLoad: "",
  PQR_PullOut_AreaTubeMaterial: "",
  PQR_PullOut_MinTensilStrength: "",
  PQR_PullOut_MinBreakStress: "",
  PQR_PullOut_FrValue: "",

  PQR_HT_Notes: "",
  PQR_PullOut_AccCriteria_Notes: "",
  PQR_Radio_Notes: "",
  PQR_OT_Notes: "",
};

export var pqrJointType = [
  { value: "Tube to Tubesheet", name: "PQR_JD_JointType" },
];

export var StatusPQR = [
  { value: "", name: "Select" },
  { value: "Active", name: "Active" },
  { value: "Inactive", name: "Inactive" },
];

export var WeldingProcess = [
  { value: "NA", name: "Na" },
  { value: "No Value", name: "No Value" },
  { value: "SMAW", name: "SMAW" },
];

export var detailProcessPQR = (resultdata) => {


  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  return {
    SK: "",
    PK: "",
    PQR_RecordNo: "",
    wld_createdon: formattedDate,
    PQR_Process_Category: "",
    PQR_Process: "",
    PQR_Type: "",
    PQR_Layer: "",
    PQRD_FM_SFA: {},
    PQRD_FM_Classification: {},
    PQRD_FM_FNo: {},
    PQRD_FM_Ano: {},
    PQRD_FM_Nominal: {},
    PQRD_FM_TradeName: {},


  };
};