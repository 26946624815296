import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DragDropRender from "../../utils/DragDropRender";
import Delete_green from "../../icons/Delete_green.svg";
import Delete from "../../icons/Delete.svg";
import {
  handleDeleteDocument,
  handleFileSelect,
} from "../WCSTransaction/WCSObjects/WCS_entity";

const UploadWCSExcel = (props) => {
  
  let action = props.action;
  let formName = "WCSExcelUpload";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [excelData, setexcelData] = useState([]);
  let wcsHeader = {};
  wcsHeader.SK = props.ProjNo;

  const history = useNavigate();



  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setAno",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    setshowConfirmModel(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've uploaded excel successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Upload data for Project Number - <b>{props.ProjNo}</b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col lg={12} md={12} xs={12}>
                  <Form.Label className="wcs-dropzone">Upload File</Form.Label>
                </Col>
                {fileName === "" || fileName === undefined ? (
                  <Col lg={12} md={12} xs={12} className="container-dropzone">
                    <DragDropRender
                      handleChangeEvent={(params) =>
                        handleFileSelect(
                          params,
                          setFileName,
                          setFile,
                          setexcelData
                        )
                      }
                    />
                  </Col>
                ) : (
                  <>
                    <Col lg={12} md={12} xs={12} className="fileupload-name">
                      <label>
                        {fileName}
                        &nbsp;&nbsp;&nbsp;
                      </label>
                      <img
                        onMouseOver={(e) =>
                          (e.currentTarget.src = Delete_green)
                        }
                        onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        src={Delete}
                        alt="delete"
                        onClick={(params) =>
                          handleDeleteDocument(
                            fileName,
                            setFile,
                            fileName,
                            setFileName
                          )
                        }
                        hidden={action === "View" ? true : false}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md={12} xs={12} className="submitexcelbtn">
                  <Button
                    variant="success"
                    className="submitbtn"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>
        </Form>
      </Modal>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default UploadWCSExcel;
