import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";
import GolbalSearch from "../../Components/utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import {
  getAllUserMaster,

  UpdateUser,
} from "../../Services/user-service";
import AddEditUserMaster from "./AddEditUserMaster";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GlobalColumnDefinations, { getHeader_FieldName } from "../utility/AgGridDefination";
import GridMaster from "../utility/GridMaster";
import ActionRenderer from "../utility/ActionRenderer";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";

const UserMaster = forwardRef((props, ref) => {
  let formName = "User";

  const [rowData, setRowData] = useState({});

  const [filteredResult, setFilteredResult] = useState([]);
  const [users, setUsers] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [show1, setShow1] = useState(false);



  const [excelData1, setExcelData] = useState([]);
  const childRef = useRef();

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "um_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "statusRenderer",
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: "User Id",
      field: "um_email",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "User Name",
      field: "um_name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
    {
      headerName: "User Role",
      field: "um_role",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Location",
      field: "um_location",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Contact Number",
      field: "um_contactNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
  
    },
  ];



  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ field: "", cellRenderer: actionRenderer, width: 150 ,  suppressMenu: true,
    sortable:false,});
    setcolumnDefs(result);
  };

  const fetchUserMaster = async () => {
    const result = await getAllUserMaster();
    setUsers(result);
    setFilteredResult(result);
  };

  useEffect(() => {
    fetchUserMaster();
    fetchColumnDefns();
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(users);
  };

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    
    childRef.current.setSearch(text);

  };

  const handleGlobalExport = () => {
    let filename = "Usermaster";
    setExcelData(filteredResult);
    handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

 

  const handleDelete = (row) => {
    let result;
    data.um_status = "Inactive";
    result = UpdateUser(data);
    result
      .then((resp) => {
        if (action === "Delete") {
          setShowForm(false);
          toast.success(`Selected Welder has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        } else {
          toast.warning("Error!!!, Not able to deactivate  Welder", {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        console.log("Unable to process request");
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  return (
    <Container fluid>
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />

      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Users list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={"Search for  User Id, User Name, Location"}
              filterFunc={handleGlobalSearch}
            />
            {/* <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
      />

      {showForm && (
        <AddEditUserMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          fetchUserMaster={fetchUserMaster}
          allRecs={users}
          backdrop="static"
        />
      )}

      <Toaster />
    </Container>
  );
});

export default UserMaster;
