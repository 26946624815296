import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";


import SuccessAlertModal from "../Modals/successAlertModal";
import { useLocation, useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import Export from "../../icons/Export.svg";

import { getGridHeaderData } from "../../Services/wps_groove-services";


import GlobalColumnDefinations , { getHeader_FieldName } from "../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";

import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import ActionRendererWps from "../utility/ActionRendererWps";
import Pdf_Download from "./PDF/Pdf_Download";


import { DeleteGroove } from "../../Services/wps_groove-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkWPSNoPresent } from "../../Services/wcs-services";
import { GridLoader } from "react-spinners";


const WPSGroove_Overlay = forwardRef((props, ref) => {

  const [rowData, setRowData] = useState({});

  const [filteredResult, setFilteredResult] = useState([]);


  const [columnDefs, setColumnDefs] = useState([]);
  const [Groove, setGroove] = useState(null);
  

  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");




  const [reload, setReload] = useState(false);

  const [excelData1, setExcelData] = useState([]);


  const [cancel, setCancel] = useState(false);



  const navigate = useNavigate();

  const location = useLocation();




  let userprofile = sessionStorage.getItem("UserProfile");

  let Band = sessionStorage.getItem("Band");


  const [WPStype, setWPStype] = useState(props.type);

  const [userObj, setuserObj] = useState("");


  const [loading, setLoading] = useState(true);

  const childRef = useRef();
  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year);

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "WPSH_Status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WPSCellRenderer",
      sort:"desc",
      minWidth:200

    },
    {
      headerName: "Project No.",
      field: "WPSH_Project_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "WPS Number",
      field: "WPSH_WPS_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Supporting PQR",
      field: "WPSH_SupportingPQR",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Process",
      field: "WPSH_WPS_Process",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },

    {
      headerName: "Pno.",
      field: "WPSH_BM_Type_PNo",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },

    {
      headerName: "WPS Change No.",
      field: "WPS_Change_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];


  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchGroove = async () => {
    

    let result = await getGridHeaderData(
      WPStype,
      sessionStorage.getItem("Band"),
      sessionStorage.getItem("User"),
      selectedYear
    );

   
    setGroove(result);
    setLoading(false);
    setFilteredResult(result);
  };


  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ headerName: "", cellRenderer: actionRenderer, minWidth: 260 ,  suppressMenu: true,
    sortable:false,});

    setColumnDefs(result);
  };

  useEffect(() => {


    fetchGroove();
    fetchColumnDefns();
  }, [location.key, selectedYear,cancel]);

  function handleClick() {
    if (WPStype === "Groove") {
      navigate("/wps/Groove/Groovetrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    } else if (WPStype === "Overlay") {
      navigate("/wps/Overlay/Overlaytrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    } else if (WPStype === "Tubesheet") {
      navigate("/wps/Tubesheet/Tubesheettrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    }
  }

  function handleClickedit(row) {
    

    if (row.WPSH_Status === "Approved") {
      row.WPS_Change_No = String(Number(row.WPS_Change_No) + 1);
    }

    if (WPStype === "Groove") {
      navigate("/wps/Groove/Groovetrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    } else if (WPStype === "Overlay") {
      navigate("/wps/Overlay/Overlaytrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    } else if (WPStype === "Tubesheet") {
      navigate("/wps/Tubesheet/Tubesheettrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    }
  }


  function handleClickcopy(row) {
    if (WPStype === "Tubesheet") {
      navigate("/wps/Tubesheet/Tubesheettrans", {
        state: { data: row, action: "Copy", WPStype: WPStype },
      });
    } else {
      navigate("/wps/Groove/Groovetrans", {
        state: { data: row, action: "Copy", WPStype: WPStype },
      });
    }
  }

  function handleClickTemp(row) {
    navigate("/wps/Groove/Groovetrans", {
      state: { data: row, action: "Temp", WPStype: WPStype },
    });
  }

  useImperativeHandle(ref, () => ({
    handleAdd: () => {
      handleClick();
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Groove);
  };


  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setGroove",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };




  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);
  };

  const handleGlobalExport = () => {
    if (WPStype === "Groove") {
      let filename = "WpsGrooveTransaction";
       handleExport1(filteredResult, filename, tmpcolumnDefs);
    } else if (WPStype === "Overlay") {
      let filename = "WpsOverlayTransaction";
       handleExport1(filteredResult, filename, tmpcolumnDefs);
    } else {
      let filename = "WpsTubesheetTransaction";
       handleExport1(filteredResult, filename, tmpcolumnDefs);
    }
  };

  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
  };

  const handlepdf = (row) => {
   
    if(row?.WPSH_Status === "Approved"){

    setAction("pdf");
    setRowData(row);
    setShowForm(true);
    setuserObj(userprofile);
   }
    else{
      alert("Cannot Print PDF.Please Approve the Record")
    }
  };

  let isPresent;

  const handleDeleteEvent = async (row) => {
    try {
    
      let gridDetail = {
        WCS_Project_No: row.WPSH_Project_No,
        WPSH_WPS_No: row.WPSH_WPS_No,
      };
      isPresent = await checkWPSNoPresent(gridDetail);
     

      if (isPresent) {
        toast.warning("WPS already in use, cannot be deleted", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      } else {
        setRowData(row);
        row.WI_Slip_Status = "Deleted";
        await DeleteGroove(row);
        toast.success(`Selected DetailGrid is Deleted`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        setCancel(true);

      

      }
     
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  const actionRenderer = (params) => {
    return (
      <ActionRendererWps
        selectedData={params}
      
        handleDeleteEvent={(params) => handleDeleteEvent(params.data)}
        handleEditEvent={(params) => handleClickedit(params.data)}
        handleCopyEvent={(params) => handleClickcopy(params.data)}
        handleTempEvent={(params) => handleClickTemp(params.data)}
        handlePdfEvent={(params) => handlepdf(params.data)}
      />
    );
  };

  const handleYearChange = (e) => {
   
    setSelectedYear(e.value);
  };

  return (
    <>
          {loading && (
  <div className="loader-overlay">
    <GridLoader size={10} className="pageloader" />
  </div>
)}
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />

      <Row className="RowHeight">
        <Col md={2} sm={2} xs={2} className="ListTitle">
          WPS Transaction list
        </Col>

        <Col className="ListTitle">
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for " +
                WPStype +
                " Project No., WPS Number ,Supporting PQR,WPS Change No."
              }
              withYear={true}
              filterFunc={handleGlobalSearch}
              YearHandleChange={handleYearChange}
              YearSelected={selectedYear}
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
     
        setExcelData={setExcelData}
        ref={childRef}
      />

      {showForm && (
        <Pdf_Download
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          userObj={userObj}
          allRecs={Groove}
          backdrop="static"
          Band={Band}
        />
      )}        

      <Toaster />
    </Container>
    
    </>
  );
});

export default WPSGroove_Overlay;
