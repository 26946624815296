import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";

//-----below in use

export const PQR_Process_Grid_InputProcess = ({
  detailObj,
  ColumnHeader,
  Columndimension,
  type,
  name,
  onInput,
  disabled,
  maxLength,
}) => {
  
  return (
    <tr className={"border WpsInput-md"}>
      <th
        className={"text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
        </div>
      </th>
      <td
            className={"border WPSSelect"}
            // key={id}
          >
            <input
              style={{width:"275px"}}
              type={type}
              name={name}
              value={"GTAW"}
              readOnly
              disabled={disabled}
              maxLength={maxLength}
            />
          </td>

       </tr>
  );
};



export const PQR_TTS_Process_Grid_Input = ({
  status,
  message,
  ColumnHeader,
  Columndimension,
  type,
  name,
  handleChangedetailsarray,
  onInput,
  detailObj,
  maxLength,
  width1
}) => {
  
  let textwidth = width1 != undefined ? width1 : "";
  return (   
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      <td className={status ? "border WPSSelect error" : "border WPSSelect"}>
        <input
        style={{position:"relative",top:"4px",width:textwidth}}
          className={status ? "error-input" : ""}
          type={type}
          name={name}
          value={detailObj?.[name]}
          maxLength={maxLength}
          onChange={(e) => {
            handleChangedetailsarray(e);
          }}
          onInput={onInput}
        />
      </td>
    </tr>
  );
};

export const PQR_Process_Grid_Input_2 = ({
  status,
  message,
  detailObj,
  ColumnHeader,
  Columndimension,
  type,
  name,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  onInput,
  ColumnHeader1,
  name1,
  width1
}) => {
 let textwidth = width1 !=undefined ? width1 : "" ; 
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div >
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3">
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-center">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      <td className={status ? "border WPSSelect error" : "border WPSSelect"}>
        <div className="d-flex flex-column align-items-center">
          <input
            type={type}
            className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
            name={name}
            value={detailObj?.[name]}
            style={{width:textwidth}}
            readOnly={readOnly}
            maxLength={maxLength}
            onChange={(e) => {
              handleChangedetailsarray(e);
            }}
            onInput={onInput}
          />

          <input
            type={type}
            className={status ? "error-input mt-2" : "mt-2"}
            name={name1}
            value={detailObj?.[name1]}
            style={{width:textwidth}}
            readOnly={readOnly}
            maxLength={maxLength}
            onChange={(e) => {
              handleChangedetailsarray(e);
            }}
            onInput={onInput1}
          />
        </div>
      </td>
    </tr>
  );
};

