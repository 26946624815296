import { checkValidJointNo } from "../../../Services/wcs-services";

function getArrayList(start) {
  let arr_list = [];
  start.forEach((element, index) => {


    if (element.WCS_Joint_No !== "" && element.WCS_Joint_No !== undefined)
      arr_list.push({ jnt: element.WCS_Joint_No });
  });

  return arr_list;
}

export const validateWps = async (wcsDetail) =>{

  let checkVal;
 

  if (wcsDetail.length > 0) {
    let finaled = {
      project: wcsDetail[0].WCS_Project_No,
      JointNo_Array: getArrayList(wcsDetail),
    };


    checkVal =  await checkValidJointNo(finaled);

    let WCSError 
    if (checkVal.length > 0) {
      WCSError =await Promise.resolve(checkVal)

      return WCSError
    }

  }
  return undefined
}