
import "../Css/groove_pdf.css";


const WI_PDF_TTS_3Hindi = ({ wi, rowdata, wpsde, setpdf }, props, wld) => {
 
  let header = rowdata[0];
  let detail = rowdata[1];



  let slipDate = header?.WI_SlipDate;
  let date = new Date(slipDate);
  let formattedDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

 




const WeldDepThick = header?.WI_WeldDepThick.split("|")
let WI_WeldDepThick 

if(WeldDepThick[0] === "9999.00")
{
    WI_WeldDepThick = "NO Limit"
}
else{
    WI_WeldDepThick =  WeldDepThick[0]
}


  return (
    <div className="MainPDF">
      <header>
        <table class="tablenoborder">
          <div style={{ display: "flex" }}>
            <tr>
              <td class="tablenoborder godrejTitle" align="left" valign="top" style={{fontWeight:"200"}}>
                Godrej & Boyce Mfg. Co. Ltd
              </td>
            </tr>
            <tr>
              <td
                class="tablenoborder godrejAddress"
                align="right"
                valign="top"
                style={{ position: "relative", left: "390px" }}
              >
                {header?.WI_print >= 1
                  ? `Reprinted On: ${new Date().toLocaleDateString("en-GB")}`
                  : ""}{" "}
              </td>
            </tr>
          </div>

          <tr>
            <td
              class="tablenoborder godrejAddress "
              align="right"
              style={{ position: "relative", right: "50px" }}
            >
               प्रिन्ट दिनांक : {formattedDate}
            </td>
          </tr>

          <tr>


            <td
              class="tablenoborder pageTitle"
              align="center"
              valign="top"
              style={{ verticalAlign: "top", padding: 0, height: ".1%" }}
            >
            वेल्डींग प्रारंभ करने से पहले कृपया अपनी वेल्डींग स्लीप अच्छी तरह से पढ़ ले
            </td>

          </tr>
          <tr>


            <td
              class="tablenoborder pageTitle"
              align="center"
              valign="top"
              style={{ verticalAlign: "top", padding: 0, height: ".1%" }}
            >
            निम्नलिखित निर्देश जाँच लें और उनका पालन करें
            </td>

          </tr>
        
        </table>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
            <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            १. मशीन कॅलिब्रेटेड है
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            ८. जॉब सेटअप सही है
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
          <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            २. वेल्डींग कार्ड अपडेटेड है
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            ९ . प्रिहिट सही है
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
          <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            ३. टेंपरेचर स्टीक उपलब्ध  है
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            १०. आप अब येल्डिंग के लिए पूरी तरह से तैयार है
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
          <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            ४. कन्स्युमेबल  सही है
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            ११. SAW बूम स्पीड सेट किया
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
          <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            ५. वेल्डिंग एरिया साफ है
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            १२. जॉब में ड्रिफ्ट नहीं है
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
          <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            ६. वेल्डिंग के सभी साधन सही है
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            १३. वेल्डिंग एरिया का पहुच सही है 
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
          <div className="WittsReportHeadersleftHindi" align="left" style={{width:"60%"}}>
            ७. पोर्टेबल ओवन उपलब्ध है
            </div>
            <div
              className="WittshindiBox"
              align="left"
             
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
          <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            १४. जॉब मे जर्क नहीं है  
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width:"35%"}}>
            <div className="WittsReportHeadersleftHindi" align="left">
            </div>
            <div
              align="left"
             
            >
            </div>
          </div>
          <div style={{ display: "flex", width:"50%"}}>
            <div className="WittsReportHeadersrightHindi" align="left" style={{width:"60%"}}>
            १५. गैस फ़्लो मीटर का गेज सही है  
            </div>
            <div
              className="WittshindiBox"
              align="left"
              
            >
            </div>
          </div>
        </div>
        <br></br>

        
        <div >
          <div >
            <div className="WittsReportHeadersleftHindi" align="left">
            सूचनाई<br></br>
            १. कृपया शुकव्हर पहनिए, यदि आप स्टेनलिस स्टिल जॉब पर काम कर रहे है।<br></br>
            २. कृपया कार्बन स्टिल की चिपींग हॅमर का इस्तमाल ना करें, यदि आप स्टेनलिस स्टिल जॉबपर काम कर रहे है।<br></br>
            ३. कृपया वेल्डिंग की शुरुआत करने के पहले वेल्डींग सीम को 50 मी मी दोनो तरफ से वायर व्हील से साफ करे।<br></br>
            ४. कृपया अपने हिंटिंग बर्नर की ज्योती (फ्लेट) निले रंग की होनी चाहिए जिससे की जॉबपर कालिख ना पडे।<br></br>
            ५. अपना हर वेल्डींग पास खत्म होने के तुरंत बाद उसे वायरब्रश या ग्राइंडींग व्हील से साफ करे।<br></br>
            ५. अपना हर वेल्डींग पास खत्म होने के तुरंत बाद उसे वायरब्रश या ग्राइंडींग व्हील से साफ करे।<br></br>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>


<div style={{display:"flex"}} >
  
        <div className="borderline1WItts3hindi" style={{position:"relative", left:"10%"}}></div>

        <div className="borderline1WItts3hindi" style={{position:"relative", left:"30%"}}></div>
        
        </div>

        <div style={{display:"flex"}} >
  
  <span style={{position:"relative", left:"15%"}}>Shop Supervisor</span>
  <span style={{position:"relative", left:"49%"}}>Welder</span>


  
  </div>
  <br></br>


        


        <table class="tablenoborder">
        


          <tr>
           

            <td
              class="tablenoborder pageTitle"
              align="center"
              valign="top"
              style={{ verticalAlign: "top", padding: 0, height: ".1%" }}
            >
              ।। आपकी सुरक्षा आपका सामाजिक कर्तव्य है ।।
            </td>

          </tr>
          </table>

<br></br>
          <div >
          <div >
            <div className="WittsReportHeadersleftHindi" align="left">
            स्टोअर्स के लिए निर्देश :<br></br>
            १. कृपया जाँच ले की कन्स्युमेबल दिए गए प्रोजेक्ट में रिजर्व्ह है /<br></br>
            २. कृपया सुनिश्चित करे की दिए जा रहे कन्स्युमेबल अच्छी तरह से बेकींग किए हुए है /<br></br>
            ३. कृपया कन्युमेबल का AWS नं. और ब्रान्ड जांच ले /<br></br>
            ४. कृपया ओवन के कॅलिब्रशन की वैधता जाँच ले /<br></br>
            ५. कृपया सुनिश्चित करे की बेकिंग किए जा रहे फ्लक्स एवम  इलेक्ट्रोडस् को नियमित स्थान पर ही रखे है /<br></br>
            ६. वापस किए हुए इलेक्ट्रोडस् के आखिर में इंक से मार्क करे एवम होल्डींग ओवन में रखे /<br></br>
            </div>
          </div>
        </div>
<br></br>

        <table class="tablenoborder">
        


        <tr>
          

          <td
            class="tablenoborder pageTitle"
            align="center"
            valign="top"
            style={{ verticalAlign: "top", padding: 0, height: ".1%" }}
          >
            ।। आपकी सुरक्षा आपका सामाजिक कर्तव्य है ।।
          </td>

        </tr>
        </table>

     
      </header>
    </div>
  );
};
export default WI_PDF_TTS_3Hindi;

