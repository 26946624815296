import {  useState } from "react";

export const PMItemGroup = (props) => {


  const [correctionFactor, setCorrectionFactor] = useState([
    { value: "Select", name: "Select" },
    { value: "ESSC flux", name: "0.700" },
    { value: "GTAW/SAW wire", name: "1.000" },
    { value: "Strip", name: "1.050" },
    { value: "FCAW wire", name: "1.300" },
    { value: "SAW flux", name: "1.300" },
    { value: "Electrode", name: "2.500" },
  ]);


  const onChange = (e) => {
    props.node.setDataValue("WI_CorrectionFactor", e.target.value);
    props.node.setDataValue(
      "WI_ItemGroup",
      e.target[e.nativeEvent.target.selectedIndex].label
    );

  };
  return (
    <>
      <div className="itemGroup" >
        <select onChange={onChange}>
          {correctionFactor.map((item) => (


            <option key={item.value} value={item.name}>
              {item.value}-{item.name}
            </option>
          ))}
        </select>

     
      </div>
    </>
  );
};
