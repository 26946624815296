import "../Css/Download_pdf.css";
import Groove_pdf_header from "./Groove_pdf_header WPS";


import PageFooter from "./PageFooter";
import Tubesheet_Other_variable from "./WPS_Tubesheet_OtherVariable";
import Tubesheet_Post_weld_heat_Treatment from "./WPS_tubesheet_PostWeldHeatTreatment";

const Tubesheet_Pdf_Page2 = ({
  page,
  pdf,
  PreviewUrl,
  previewuser,
  previewapproved,
}) => {



  const height = 595 - window.innerHeight + "px";






  return (
    // ---------------------------------header---------------------------------------------
    <div className="WPSMainPDF">
      <div id="container" className="WPSflexb">
        <div className="WPSheaderRow">
          {window.innerHeight < 595 ? (
            <div style={{ height: { height } }}>
       
            </div>
          ) : (
            ""
          )}
          <br></br>

                   {/* <Groove_pdf_header_common from={"WPS"} pdf={pdf}></Groove_pdf_header_common> */}
                   <Groove_pdf_header page={page} pdf={pdf}></Groove_pdf_header>

        </div>

        {/* <!-- ----------------------------------POST WELD HEAT TREATMENT---------------------------------------------------- --> */}
         
        <div id="body" className="WPSContent">

        <div className="WPSgroup" style={{position:"relative",top:"5px"}}>
            <div className="WPSbasemetal" style={{ width: "100%" }}>
              POST WELD HEAT TREATMENT (QW-407)
            </div>
          </div>
          <Tubesheet_Post_weld_heat_Treatment pdf={pdf}></Tubesheet_Post_weld_heat_Treatment>
          <br></br>
{/* ------------------------------Other variables-------------------------------- */}

          <div className="WPSgroup">
            <div className="WPSbasemetal">OTHER VARIABLES</div>
          </div>
          <Tubesheet_Other_variable pdf={pdf}></Tubesheet_Other_variable>

    {/* ----------------------------------Notes-----------------------------------------------    */}
          <br></br>
      <div className="WPSgroup" >
            <div className="WPSbasemetal">NOTES</div>
          </div>

          <table id="table" className="WPSTable10">
            <tbody>
              <tr>
                <td className="Noteswrap">
                  {pdf[0]?.WPSH_Notes != undefined
                    ? pdf[0]?.WPSH_Notes.split("\n").map((i) => {
                        return (
                          <>
                            {i}

                            <br></br>
                          </>
                        );
                      })
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      </div>
      {/* --------------------------Footer------------------------------------------------------------- */}
      <div style={{position:"fixed",top:"1590px"}}>
      <PageFooter pdf={pdf} previewuser={previewuser} previewapproved={previewapproved}></PageFooter>
      </div>
      <br></br>      <br></br>

        <div  style={{position:"fixed", top:"1680px"}}>
          <table className="WPSfooter" >
          <tr>
            <td>Page 2 of 2</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Tubesheet_Pdf_Page2;
