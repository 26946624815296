import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";

import calenderIcon from "../../../icons/calendericon.svg";

function MuiIcon() {
  return <Image src={calenderIcon} />;
}

export const Date_Input = ({
  label,
  className,
  name,
  todayDate,
  setobj,
  obj,
  datevalue,
  setdatevalue,
  divClass,
  status,
  message,
  maxDate,
  disabled,
  readOnly,
  setError,
  fromdate,
  todate,
}) => {
  return (
    <>
      <div className="groove_date">
        <div class={divClass}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                slots={{ openPickerIcon: MuiIcon }}
                label={label}
                className={className}
                format="DD-MM-YYYY"
                name={name}
                sx={{
                  fontSize: "6px",
                  fontFamily: "GE-BodyCopy",
                  zIndex: "0",
                }}
                disabled={disabled}
                readOnly={readOnly}
                value={
                  datevalue === false || obj[name] === ""
                    ? null
                    : obj && obj[name]
                    ? dayjs(obj[name])
                    : dayjs(todayDate)
                }
                maxDate={
                  dayjs(maxDate) === dayjs(maxDate) ? dayjs(maxDate) : ""
                }
                onChange={(e) => {
                  let date = dayjs(e).format("YYYY-MM-DD");

                  setobj((prev) => ({
                    ...prev,
                    [name]: date,
                  }));

                  if (name === "WI_From_Date") {
                    if (todate) {
                      if (date > todate) {
                        setError((prevState) => ({
                          ...prevState,
                          [name]: {
                            status: true,
                          },
                        }));
                        toast.warning(
                          "Selected date cannot be greater than To date",
                          {
                            position: toast.POSITION.TOP_LEFT,
                            className: "toast-message",
                          }
                        );

                        setobj((prev) => ({
                          ...prev,
                          [name]: date,
                        }));
                      }
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "",
                        },
                      }));
                    }
                  }

                  if (name === "WI_To_Date") {
                    if (date < fromdate) {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: true,
                        },
                      }));
                      toast.warning(
                        "Selected date cannot be less than From date",
                        {
                          position: toast.POSITION.TOP_LEFT,
                          className: "toast-message",
                        }
                      );
                      setdatevalue(false);
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "",
                        },
                      }));
                    }
                  }

                  if (name !== "WI_To_Date" && name !== "WI_From_Date") {
                    setobj((prev) => ({
                      ...prev,
                      [name]: date,
                    }));

                    setError((prevState) => ({
                      ...prevState,
                      [name]: {
                        status: false,
                        message: "",
                      },
                    }));
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="required-text-select mt-2">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const Date_InputUser = ({
  label,
  className,
  name,
  todayDate,
  setobj,
  obj,
  datevalue,
  setdatevalue,
  divClass,
  status,
  message,
  disabled,
  readOnly,
  setError,
  fromdate,
  todate,
}) => {
  return (
    <>
      <div className="groove_date">
        <div class={divClass}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                slots={{ openPickerIcon: MuiIcon }}
                label={label}
                className={className}
                format="DD-MM-YYYY"
                name={name}
                sx={{
                  fontSize: "6px",
                  fontFamily: "GE-BodyCopy",
                  zIndex: "0",
                }}
                disabled={disabled}
                readOnly={readOnly}
                value={
                  name === "WI_To_Date"
                    ? obj && obj[name]
                      ? dayjs(obj[name])
                      : dayjs(todayDate)
                    : datevalue === false || obj[name] === ""
                    ? null
                    : obj && obj[name]
                    ? dayjs(obj[name])
                    : dayjs(todayDate)
                }
                onChange={(e) => {
                  let date = dayjs(e).format("YYYY-MM-DD");

                  setobj((prev) => ({
                    ...prev,
                    [name]: date,
                  }));

                  if (name === "WI_From_Date") {
                    if (todate) {
                      if (date > todate) {
                        setError((prevState) => ({
                          ...prevState,
                          [name]: {
                            status: true,
                          },
                        }));
                        toast.warning(
                          "Selected date cannot be greater than To date",
                          {
                            position: toast.POSITION.TOP_LEFT,
                            className: "toast-message",
                          }
                        );

                        setobj((prev) => ({
                          ...prev,
                          [name]: date,
                        }));
                      }
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "",
                        },
                      }));
                    }
                  }

                  if (name === "WI_To_Date") {
                    if (date < fromdate) {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: true,
                        },
                      }));
                      toast.warning(
                        "Selected date cannot be less than From date",
                        {
                          position: toast.POSITION.TOP_LEFT,
                          className: "toast-message",
                        }
                      );
                      setdatevalue(false);
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "",
                        },
                      }));
                    }
                  }

                  if (name !== "WI_To_Date" && name !== "WI_From_Date") {
                    setobj((prev) => ({
                      ...prev,
                      [name]: date,
                    }));

                    setError((prevState) => ({
                      ...prevState,
                      [name]: {
                        status: false,
                        message: "",
                      },
                    }));
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="required-text-select mt-2">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};
