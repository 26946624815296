//TABLE 3 WITH 3 ROWS AND 6 COLUMNS (BASE METAL THICKNESS)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {
  isAlphanumericAllSpecialCharacters,
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
export const WPQ_BaseMetalThichkness_Table = ({
  headerObj,
  error,
  isReadonly,
  handleChange,
  setError,
}) => {
  return (
    <Table className="text-center ">
      <thead>
        <tr className="border thead-color ">
          <th className="thickness_title" style={{ width: "18%" }}></th>
          <th className="thickness_title"></th>
          <th className="thickness_title">Actual Value</th>
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
          <th className="thickness_title">Range Qualified</th>
          <th className="thickness_title"></th>
        </tr>

        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
            <th  className="thickness_title" key={head}>{head}</th>
          ))} */}
          <th className="thickness_title"></th>
          <th className="border thickness_title" style={{ width: "13%" }}>Groove</th>
          <th className="border thickness_title">Fillet</th>
          <th className="border thickness_title">Overlay</th>
          <th className="border thickness_title">Groove</th>
          <th className="border thickness_title">Fillet</th>
          <th className="border thickness_title">Overlay</th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            // error.WPQ_BMT_Plate_thickness_ActualV_Groove &&
            (error.WPQ_BMT_Plate_thickness_ActualV_Groove.status ||
              error.WPQ_BMT_Plate_thickness_ActualV_Fillet.status ||
              error.WPQ_BMT_Plate_thickness_ActualV_Overlay.status ||
              error.WPQ_BMT_Plate_thickness_RangeQ_Groove.status ||
              error.WPQ_BMT_Plate_thickness_RangeQ_Fillet.status ||
              error.WPQ_BMT_Plate_thickness_RangeQ_Overlay.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              // error.WPQ_BMT_Plate_thickness_ActualV_Groove &&
              (error.WPQ_BMT_Plate_thickness_ActualV_Groove.status ||
                error.WPQ_BMT_Plate_thickness_ActualV_Fillet.status ||
                error.WPQ_BMT_Plate_thickness_ActualV_Overlay.status ||
                error.WPQ_BMT_Plate_thickness_RangeQ_Groove.status ||
                error.WPQ_BMT_Plate_thickness_RangeQ_Fillet.status ||
                error.WPQ_BMT_Plate_thickness_RangeQ_Overlay.status)
                ? "text-start error-head thead-color"
                : "text-start border thead-color"
            }
          >
            <div>
              <label className="greenfont">Plate Thickness mm</label>
              {/* {error.WPQ_BMT_Plate_thickness_ActualV_Groove && */}{
                (error.WPQ_BMT_Plate_thickness_ActualV_Groove.status ||
                  error.WPQ_BMT_Plate_thickness_ActualV_Fillet.status ||
                  error.WPQ_BMT_Plate_thickness_ActualV_Overlay.status ||
                  error.WPQ_BMT_Plate_thickness_RangeQ_Groove.status ||
                  error.WPQ_BMT_Plate_thickness_RangeQ_Fillet.status ||
                  error.WPQ_BMT_Plate_thickness_RangeQ_Overlay.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BMT_Plate_thickness_ActualV_Groove.message ||
                      error.WPQ_BMT_Plate_thickness_ActualV_Fillet.message ||
                      error.WPQ_BMT_Plate_thickness_ActualV_Overlay.message ||
                      error.WPQ_BMT_Plate_thickness_RangeQ_Groove.message ||
                      error.WPQ_BMT_Plate_thickness_RangeQ_Fillet.message ||
                      error.WPQ_BMT_Plate_thickness_RangeQ_Overlay.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="Thickness_data" style={{paddingLeft:"19px"}}>
            <input
            style={{width:"93px"}}
              type="text"
              className={
                error.WPQ_BMT_Plate_thickness_ActualV_Groove &&
                error.WPQ_BMT_Plate_thickness_ActualV_Groove.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BMT_Plate_thickness_ActualV_Groove"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Plate_thickness_ActualV_Groove}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Plate_thickness_ActualV_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              style={{width:"93px"}}
              type="text"
              className={
                error.WPQ_BMT_Plate_thickness_ActualV_Fillet &&
                error.WPQ_BMT_Plate_thickness_ActualV_Fillet.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BMT_Plate_thickness_ActualV_Fillet"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Plate_thickness_ActualV_Fillet}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Plate_thickness_ActualV_Fillet: error,
                }));
              }}
            />
          </td>
          <td style={{borderRight:"0.5px solid var(--col-ddd)"}}>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Plate_thickness_ActualV_Overlay &&
                error.WPQ_BMT_Plate_thickness_ActualV_Overlay.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BMT_Plate_thickness_ActualV_Overlay"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Plate_thickness_ActualV_Overlay}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Plate_thickness_ActualV_Overlay: error,
                }));
              }}
            /> 
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Plate_thickness_RangeQ_Groove &&
                error.WPQ_BMT_Plate_thickness_RangeQ_Groove.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BMT_Plate_thickness_RangeQ_Groove"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Plate_thickness_RangeQ_Groove}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Plate_thickness_RangeQ_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Plate_thickness_RangeQ_Fillet &&
                error.WPQ_BMT_Plate_thickness_RangeQ_Fillet.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BMT_Plate_thickness_RangeQ_Fillet"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Plate_thickness_RangeQ_Fillet}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Plate_thickness_RangeQ_Fillet: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Plate_thickness_RangeQ_Overlay &&
                error.WPQ_BMT_Plate_thickness_RangeQ_Overlay.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_BMT_Plate_thickness_RangeQ_Overlay"}
              maxLength={5}
              value={headerObj?.WPQ_BMT_Plate_thickness_RangeQ_Overlay}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Plate_thickness_RangeQ_Overlay: error,
                }));
              }}
            /> Min.
          </td>
        </tr>
        <tr
          className={
            // error.WPQ_BMT_Pipe_thickness_ActualV_Groove &&
            (error.WPQ_BMT_Pipe_thickness_ActualV_Groove.message ||
              error.WPQ_BMT_Pipe_thickness_ActualV_Fillet.message ||
              error.WPQ_BMT_Pipe_thickness_ActualV_Overlay.status ||
              error.WPQ_BMT_Pipe_thickness_RangeQ_Groove.status ||
              error.WPQ_BMT_Pipe_thickness_RangeQ_Fillet.status ||
              error.WPQ_BMT_Pipe_thickness_RangeQ_Overlay.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              // error.WPQ_BMT_Pipe_thickness_ActualV_Groove &&
              (error.WPQ_BMT_Pipe_thickness_ActualV_Groove.message ||
                error.WPQ_BMT_Pipe_thickness_ActualV_Fillet.message ||
                error.WPQ_BMT_Pipe_thickness_ActualV_Overlay.status ||
                error.WPQ_BMT_Pipe_thickness_RangeQ_Groove.status ||
                error.WPQ_BMT_Pipe_thickness_RangeQ_Fillet.status ||
                error.WPQ_BMT_Pipe_thickness_RangeQ_Overlay.status)
                ? "text-start error-head thead-color"
                : "text-start border thead-color"
            }
          >
            <div>
              Pipe/Tube Thickness mm
              {/* {error.WPQ_BMT_Pipe_thickness_ActualV_Groove && */}
                {(error.WPQ_BMT_Pipe_thickness_ActualV_Groove.message ||
                  error.WPQ_BMT_Pipe_thickness_ActualV_Fillet.message ||
                  error.WPQ_BMT_Pipe_thickness_ActualV_Overlay.status ||
                  error.WPQ_BMT_Pipe_thickness_RangeQ_Groove.status ||
                  error.WPQ_BMT_Pipe_thickness_RangeQ_Fillet.status ||
                  error.WPQ_BMT_Pipe_thickness_RangeQ_Overlay.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BMT_Pipe_thickness_ActualV_Groove.message ||
                      error.WPQ_BMT_Pipe_thickness_ActualV_Fillet.message ||
                      error.WPQ_BMT_Pipe_thickness_ActualV_Overlay.message ||
                      error.WPQ_BMT_Pipe_thickness_RangeQ_Groove.message ||
                      error.WPQ_BMT_Pipe_thickness_RangeQ_Fillet.message ||
                      error.WPQ_BMT_Pipe_thickness_RangeQ_Overlay.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td style={{paddingLeft:"19px"}}>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_thickness_ActualV_Groove &&
                error.WPQ_BMT_Pipe_thickness_ActualV_Groove.status &&
                error.WPQ_BMT_Pipe_thickness_ActualV_Groove.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_thickness_ActualV_Groove"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_thickness_ActualV_Groove}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_thickness_ActualV_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_thickness_ActualV_Fillet &&
                error.WPQ_BMT_Pipe_thickness_ActualV_Fillet.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_thickness_ActualV_Fillet"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_thickness_ActualV_Fillet}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_thickness_ActualV_Fillet: error,
                }));
              }}
            />
          </td>
          <td style={{borderRight:"0.5px solid var(--col-ddd)"}}> 
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_thickness_ActualV_Overlay &&
                error.WPQ_BMT_Pipe_thickness_ActualV_Overlay.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_thickness_ActualV_Overlay"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_thickness_ActualV_Overlay}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_thickness_ActualV_Overlay: error,
                }));
              }}
            /> 
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_thickness_RangeQ_Groove &&
                error.WPQ_BMT_Pipe_thickness_RangeQ_Groove.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_thickness_RangeQ_Groove"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_thickness_RangeQ_Groove}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_thickness_RangeQ_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_thickness_RangeQ_Fillet &&
                error.WPQ_BMT_Pipe_thickness_RangeQ_Fillet.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_thickness_RangeQ_Fillet"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_thickness_RangeQ_Fillet}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_thickness_RangeQ_Fillet: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_thickness_RangeQ_Overlay &&
                error.WPQ_BMT_Pipe_thickness_RangeQ_Overlay.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_thickness_RangeQ_Overlay"}
              maxLength={5}
              value={headerObj?.WPQ_BMT_Pipe_thickness_RangeQ_Overlay}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_thickness_RangeQ_Overlay: error,
                }));
              }}
            /> Min.
          </td>
        </tr>

        <tr
          className={
            // error.WPQ_BMT_Pipe_Diameter_ActualV_Groove &&
            (error.WPQ_BMT_Pipe_Diameter_ActualV_Groove.message ||
              error.WPQ_BMT_Pipe_Diameter_ActualV_Fillet.message ||
              error.WPQ_BMT_Pipe_Diameter_ActualV_Overlay.status ||
              error.WPQ_BMT_Pipe_Diameter_RangeQ_Groove.status ||
              error.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet.status ||
              error.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              // error.WPQ_BMT_Pipe_Diameter_ActualV_Groove &&
              (error.WPQ_BMT_Pipe_Diameter_ActualV_Groove.message ||
                error.WPQ_BMT_Pipe_Diameter_ActualV_Fillet.message ||
                error.WPQ_BMT_Pipe_Diameter_ActualV_Overlay.status ||
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Groove.status ||
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet.status ||
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay.status)
                ? "text-start error-head thead-color"
                : "text-start border thead-color"
            }
          >
            <div>
              <label className="greenfont">Pipe Diameter mm</label>

              {/* {error.WPQ_BMT_Pipe_Diameter_ActualV_Groove && */}
                {(error.WPQ_BMT_Pipe_Diameter_ActualV_Groove.message ||
                  error.WPQ_BMT_Pipe_Diameter_ActualV_Fillet.message ||
                  error.WPQ_BMT_Pipe_Diameter_ActualV_Overlay.status ||
                  error.WPQ_BMT_Pipe_Diameter_RangeQ_Groove.status ||
                  error.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet.status ||
                  error.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BMT_Pipe_Diameter_ActualV_Groove.message ||
                      error.WPQ_BMT_Pipe_Diameter_ActualV_Fillet.message ||
                      error.WPQ_BMT_Pipe_Diameter_ActualV_Overlay.message ||
                      error.WPQ_BMT_Pipe_Diameter_RangeQ_Groove.message ||
                      error.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet.message ||
                      error.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td  style={{paddingLeft:"19px"}}>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_Diameter_ActualV_Groove &&
                error.WPQ_BMT_Pipe_Diameter_ActualV_Groove.status &&
                error.WPQ_BMT_Pipe_Diameter_ActualV_Groove.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_Diameter_ActualV_Groove"}
              maxLength={9}
              value={headerObj.WPQ_BMT_Pipe_Diameter_ActualV_Groove}
              onChange={handleChange}
              // onClick={(e)=>{ e.target.value ? " " : 73}}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_Diameter_ActualV_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_Diameter_ActualV_Fillet &&
                error.WPQ_BMT_Pipe_Diameter_ActualV_Fillet.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_Diameter_ActualV_Fillet"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_Diameter_ActualV_Fillet}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_Diameter_ActualV_Fillet: error,
                }));
              }}
            />
          </td>
          <td style={{borderRight:"0.5px solid var(--col-ddd)"}}>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_Diameter_ActualV_Overlay &&
                error.WPQ_BMT_Pipe_Diameter_ActualV_Overlay.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_Diameter_ActualV_Overlay"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_Diameter_ActualV_Overlay}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_Diameter_ActualV_Overlay: error,
                }));
              }}
            />
          </td>
          <td style={{paddingRight:"39px"}}> {'Min.: '}
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Groove &&
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Groove.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_Diameter_RangeQ_Groove"}
              maxLength={5}
              value={headerObj?.WPQ_BMT_Pipe_Diameter_RangeQ_Groove}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_Diameter_RangeQ_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet &&
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_Diameter_RangeQ_Fillet"}
              maxLength={9}
              value={headerObj?.WPQ_BMT_Pipe_Diameter_RangeQ_Fillet}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_Diameter_RangeQ_Fillet: error,
                }));
              }}
            />
          </td>
          <td style={{paddingRight:"39px"}}>
            <input
              type="text"
              style={{width:"93px"}}
              className={
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay &&
                error.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPQ_BMT_Pipe_Diameter_RangeQ_Overlay"}
              maxLength={5}
              value={headerObj?.WPQ_BMT_Pipe_Diameter_RangeQ_Overlay}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_Pipe_Diameter_RangeQ_Overlay: error,
                }));
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};