import { Row, Col,  Form } from "react-bootstrap";

import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Single_Input_Superviser_remark } from "./WPQObjects/WPQ_Process_Grid";
import {

  DisableFieldsWPQ,
} from "../WPSTransaction/WPSObjects/WPS_common";
import defaultimg from "../../icons/noimage.png";
import {
  MessageTypes,
  Action,
  Status,
  Tubesheet_WPSPage1,

} from "../WPSTransaction/WPSObjects/WPSGroove_entity";

import { Single_Input } from "../utility/CommonComponents/Inputs_Transaction";
import {
  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import {AddNewCountWPQ, AddNewGrooveWPQ } from "../../Services/wpq_groove-services";
import { getPresignedURL } from "../../Services/Welder-services";
import { IsUserMasterBand, getAllUserMaster } from "../../Services/user-service";
import { WPQHeader } from "./WPQObjects/WPQHeader";

import {
  WPQ_Page3_Table1,
  WPQ_Page3_Table2,
  WPQ_Page3_Table3,
  WPQ_Page3_Table3observation
} from "../utility/CommonComponents/wpq_Page3_table_components";
import {
  check_Wpq_Pag3_LPEX,
  check_Wpq_Pag3_MEX,
  check_Wpq_Pag3_VEX,
  check_Wpq_Page4_MLP,
  check_Wpq_Page4_MLPT5,
  check_Wpq_Page4_OT,
  check_Wpq_Page4_Radio,
} from "./WPQObjects/WPQ_Validations";
import {
  WPQPage1,
  WPQ_tubePage1,
  WPQ_tubePage4,
  WPQ_tubesheet_page2,
  WPQ_Tube_Page2,
  WPQ_tubePage4_Header,
  WPQ_tubesheet_page4table,
  errorTubepage4,
} from "./WPQObjects/WPQ_entity";

import { Date_InputUser } from "../utility/CommonComponents/Date_Component";
import { UpdateWPQDetailComplete } from "../WPQTransaction/WPQObjects/WPQ_Validations";
import { getbywpsno } from "../../Services/wps_groove-services";
import { SearchSelectProject } from "../utility/search-select";
import { emptyError } from "./WPQObjects/WPQ_common";
import { errorstyle, normalstyle } from "../../functions/welding-functions";

const WPQ_Tubesheet_Page4 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,

    },
    ref
  ) => {
    const location = useLocation();
    const avatar = { defaultimg };

    const [users, setUsers] = useState(null);

    const [checkwpsdata, setcheckwpsdata] = useState([]);


 

    let Band = sessionStorage.getItem("Band");
    let username = sessionStorage.getItem("UserName");

    let folderwld = "wld_signatures";
    let folderwpq = "WPQ_Groove";

    let formattedDate

    let initiator = Band !== "P" && Band !== "T";




    let finalApprover = Band === "P" || Band === "T";


    let error_message
    const [WPStype, setWPStype] = useState(location.state?.WPStype);


    var disableButton =
      initiator && headerObj?.WPSH_Status === Status.StatusPending
        ? true
        : false;
    const [isDisabled, setIsDisabled] = useState(disableButton);


    let readonly =
      location.state?.action === Action.ActionCopy ||
      (initiator && headerObj?.WPSH_Status === Status.StatusPending)
        ? true
        : false;
    const [isReadonly, setIsReadonly] = useState(readonly);

    const [IsEdited, setIsEdited] = useState(false);
    const [action, setaction] = useState(location.state?.action);
    const [Readonly, setReadonly] = useState("");

    const [previewUrl, setPreviewUrl] = useState([]);
    const [previewuser, setPreviewuser] = useState( headerObj?.wpq_signaturePrepared
      ? headerObj?.wpq_signaturePrepared
      : avatar.defaultimg);
    const [previewapproved, setPreviewapproved] = useState(headerObj?.wpq_signatureApproved
      ? headerObj?.wpq_signatureApproved
      : avatar.defaultimg);

    let Radiography_Test = detailObj?.Radiography_Test;
    let Other_Test = detailObj?.Other_Test;

    let Visual_Examination = detailObj?.Visual_Examination;
    let Liquid_Penetrant_Examination = detailObj?.Liquid_Penetrant_Examination;
    let object3 = detailObj?.Macro_Examination;

    let array3 = { ...object3 };
    let element3 = array3[0];
    let data3 = { ...element3 };
    let P3_Table1 = data3.Table1;
    let Macro_Examination1 = P3_Table1;


    let object = detailObj?.Minimum_Leak_Path;
    let Minimum_Leak_Path5 = detailObj?.Minimum_Leak_Path5;

    let array = { ...object };
    let element = array[0];
    let data = { ...element };
    let Table1 = data.Table1;
    let Table2 = data.Table2;
    let Table3 = data.Table3;
    let Table4 = data.Table4;
    let Table5 = data.Table5;



    let Minimum_Leak_Path1 = Table1;
    let Minimum_Leak_Path2 = Table2;
 
    const [profile, setProfile] = useState("");
    const [today, setToday] = useState("");

    const [degLayerObj, setdegLayerObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [fillerMetalObj, setFillerMetalObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [itemsObj, setItemsObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [batchObj, setBatchObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [amperesObj, setAmperesObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentPulseObj, setCurrentPulseObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentOthersObj, setcurrentOthersObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [voltsObj, setVoltsObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [travelSObj, setTravelSObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [heatObj, setHeatObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentPObj, setcurrentPObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    
    const fetchUserMaster = async () => {
      const result = await getAllUserMaster();

      const active = result.filter((item) => item.um_status === "Active")
      setUsers(active);

    };



  useEffect(() => {
    fetchUserMaster();
  }, []);



    const [error, setError] = useState({
      WPQ_Radio_TubeNo: {},
      WPQ_Radio_Acceptance_Criteria: {},
      WPQ_Radio_Observation: {},
      WPQ_Radio_Results: {},

      WPQ_OT_TypeofTest: {},
      WPQ_OT_Acceptance_Criteria: {},
      WPQ_OT_Observation: {},
      WPQ_OT_Results: {},

      WPQ_MLP_Tube_No: {},
      WPQ_MLP_LocA: {},
      WPQ_MLP_LocB: {},
      WPQ_MLP_LocC: {},
      WPQ_MLP_LocD: {},

      WPQ_MLP_Tube_No1: {},
      WPQ_MLP_LocA1: {},
      WPQ_MLP_LocB1: {},
      WPQ_MLP_LocC1: {},
      WPQ_MLP_LocD1: {},

      WPQ_MLP_Tube_No2: {},
      WPQ_MLP_LocA2: {},
      WPQ_MLP_LocB2: {},
      WPQ_MLP_LocC2: {},
      WPQ_MLP_LocD2: {},

      WPQ_MLP_Tube_No3: {},
      WPQ_MLP_LocA3: {},
      WPQ_MLP_LocB3: {},
      WPQ_MLP_LocC3: {},
      WPQ_MLP_LocD3: {},

      WPQ_MLP_Magnification: {},
      WPQ_MLP_Acceptance_Criteria: {},
      WPQ_MLP_Observation: {},

      WPSH_PWHeatTre_Temperature: {},

      WPQ_Radio_Notes: {},
      WPQ_OT_Notes: {},

      WPQ_MLP_Result: {},

      WPSH_PreparedBy_Name: {},
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Remark: {},

      WPSH_ApprovedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_Approval_Status: {},
      WPSH_ApprovedBy_Remark: {},
      WPQ_Wps_RecordNo:{},
    });


    const validate = async () => {
      
      let tubePage1 = Object.entries(WPQ_tubePage1);

      let tubePage2 = Object.entries(WPQ_Tube_Page2);

      let tubePage3 = Object.entries(Tubesheet_WPSPage1);

      let tubePage4 = Object.entries(WPQ_tubePage4);

      let errorpage4 = Object.entries(errorTubepage4);

      

      let tubePage4_Header = Object.entries(WPQ_tubePage4_Header);

      let isError = false;

      let norows

      tubePage1.map(([key, val]) => {
        if (headerObj[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage2.map(([key, val]) => {
        if (detailObj?.[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage3.map(([key, val]) => {
        if (headerObj[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage4_Header.map(([key, val]) => {
        if (headerObj[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      tubePage4.map(([key, val]) => {
        if (detailObj?.[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      errorpage4.map(([key, val]) => {
        if (error?.[key] === "" || error?.[key].status === true) {

          isError = true;
          error[key] = {
            status: true,
            message: `Special Characters not allowed`,
          };
        }
      });

      if (Band === "T" || Band === "P") {
        if (headerObj?.WPSH_ApprovedBy_Remark === "") {

          isError = true;
          error.WPSH_ApprovedBy_Remark = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error.WPSH_ApprovedBy_Remark = {
            status: false,
            message: "",
          };
        }
      }
      

        norows = check_Wpq_Pag3_VEX(Visual_Examination);
      

      if (norows) {
        Visual_Examination[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Visual_Examination[0].error = true;

        if (
          Visual_Examination[0].WPQ_VEX_WeldPass !== "" &&
          Visual_Examination[0].WPQ_VEX_Acceptance_Criteria !== "" &&
          Visual_Examination[0].WPQ_VEX_Observation !== "" &&
          Visual_Examination[0].WPQ_VEX_Results !== ""
        ) {
          Visual_Examination[0].error = false;
        }
      }

       norows = check_Wpq_Pag3_LPEX(Liquid_Penetrant_Examination);
      

      if (norows) {
        Liquid_Penetrant_Examination[0].errormessage =
          "Atleast one Full Row is required";
        isError = true;
        Liquid_Penetrant_Examination[0].error = true;

        if (
          Liquid_Penetrant_Examination[0].WPQ_LPEX_WeldPass !== "" &&
          Liquid_Penetrant_Examination[0].WPQ_LPEX_Acceptance_Criteria !== "" &&
          Liquid_Penetrant_Examination[0].WPQ_LPEX_Observation !== "" &&
          Liquid_Penetrant_Examination[0].WPQ_LPEX_Results !== ""
        ) {
          Liquid_Penetrant_Examination[0].error = false;
        }
      }


        norows = check_Wpq_Pag3_MEX(Macro_Examination1);
      

      if (norows) {
        Macro_Examination1[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Macro_Examination1[0].error = true;

        if (
          Macro_Examination1[0].WPQ_MEX_Tube_No !== "" &&
          Macro_Examination1[0].WPQ_MEX_LocA !== "" &&
          Macro_Examination1[0].WPQ_MEX_LocB !== "" &&
          Macro_Examination1[0].WPQ_MEX_LocC !== "" &&
          Macro_Examination1[0].WPQ_MEX_LocD !== ""
        ) {
          Macro_Examination1[0].error = false;
        }
      }

       norows = check_Wpq_Page4_Radio(Radiography_Test);

      if (norows) {
        Radiography_Test[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Radiography_Test[0].error = true;

        if (
          Radiography_Test[0].WPQ_Radio_TubeNo !== "" &&
          Radiography_Test[0].WPQ_Radio_Acceptance_Criteria !== "" &&
          Radiography_Test[0].WPQ_Radio_Observation !== "" &&
          Radiography_Test[0].WPQ_Radio_Results !== ""
        ) {
          Radiography_Test[0].error = false;
        }
      }

        norows = check_Wpq_Page4_OT(Other_Test);

      if (norows) {
        Other_Test[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Other_Test[0].error = true;

        if (
          Other_Test[0].WPQ_OT_TypeofTest !== "" &&
          Other_Test[0].WPQ_OT_Acceptance_Criteria !== "" &&
          Other_Test[0].WPQ_OT_Observation !== "" &&
          Other_Test[0].WPQ_OT_Results !== ""
        ) {
          Other_Test[0].error = false;
        }
      }

       norows = check_Wpq_Page4_MLP(Minimum_Leak_Path1);

      if (norows) {
        Minimum_Leak_Path1[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Minimum_Leak_Path1[0].error = true;


        if (
          Minimum_Leak_Path1[0].WPQ_MLP_Tube_No !== "" &&
          Minimum_Leak_Path1[0].WPQ_MLP_LocA !== "" &&
          Minimum_Leak_Path1[0].WPQ_MLP_LocB !== "" &&
          Minimum_Leak_Path1[0].WPQ_MLP_LocC !== "" &&
          Minimum_Leak_Path1[0].WPQ_MLP_LocD !== ""
        ) {
          Minimum_Leak_Path1[0].error = false;
        }
      }
      var errortype = WPQ_tubesheet_page2;

      errortype.forEach((cols) => {


        let value = "";
        var value1 = "";

        var msg = "";
        if (cols.Type === "S") {
          value = detailObj[cols.Column][0].Value_A[0];
          value1 = detailObj[cols.Column][1].Value_B[0];
          msg = `This field is required`;
        }

        if (
          value === undefined ||
          value === "" ||
          value === null ||
          value1 === undefined ||
          value1 === "" ||
          value1 === null
        ) {
          isError = true;

          setError((prevState) => ({
            ...error,
            ...prevState,

            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: true,
                    message: msg,
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: true,
                    message: msg,
                  },
                ],
              },
            ],
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            // ...error,
            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: false,
                    message: "",
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: false,
                    message: "",
                  },
                ],
              },
            ],
          }));
        }
      });

    

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const checkWPSNO = async (type, wpsno) => {
      
      if (headerObj?.WPQ_Wps_RecordNo !== "") {
        let result = await getbywpsno(type, wpsno);


        setcheckwpsdata(result[0]);
        return (
          result[0] === undefined || result[0] === "" || result[0] === null
        );
      } else {
        return null;
      }
    };



    const handleCount = () => {
      
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.WPSH_Status === Status.StatusApproved) {

          headerObj.WPS_Change_No = location.state?.WPS_Change_No;

          result = AddNewCountWPQ(headerObj);
        }
      }
    };


    let Radio = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_Radio_Notes: detailObj.WPQ_Radio_Notes,
      Radiography_Test: detailObj.Radiography_Test,
 
    };

    let Other = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_OT_Notes: detailObj.WPQ_OT_Notes,
      Other_Test: detailObj.Other_Test,
 
    };

    
    let Minimum_Leak_PathArray= [ 
      {
      Table1: Minimum_Leak_Path1,
      Table2: Minimum_Leak_Path2,

      }

    ];


    let MacroEx = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_MLP_Result: detailObj.WPQ_MLP_Result,
      Minimum_Leak_Path: Minimum_Leak_PathArray,
 
    };
    let MacroEx5 = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_MLP_Result: detailObj.WPQ_MLP_Result,
      WPQ_MLP_Observation: detailObj.WPQ_MLP_Observation,
      Minimum_Leak_Path5:detailObj.Minimum_Leak_Path5,
 
    };


    const postList = [MacroEx,Radio,Other,MacroEx5];

    const handleAdd = async (approvalstatus) => {
      
      let result = false;
      if (
        checkwpsdata !== undefined ||
        checkwpsdata !== "" ||
        checkwpsdata !== null
      ) {
        headerObj.WPS_Key = checkwpsdata?.SK;
      }




      if (headerObj?.WPSH_Status === Status.StatusApproved) {
        handleCount();
      }

      if (
        headerObj?.WPSH_Approval_Status !== Status.StatusTempSaved &&
        headerObj?.WPSH_Approval_Status !== undefined
      )
        headerObj.WPSH_Status = headerObj?.WPSH_Approval_Status;
      else headerObj.WPSH_Status = approvalstatus;





      
      if (Band === "P" || Band === "T") {
    
        headerObj.wpq_signatureApproved = headerObj.wpq_signatureApproved;
        headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name;
        headerObj.WPSH_ApprovedBy =  headerObj.WPSH_ApprovedBy_Name
      } else if (Band !== "T" || Band !== "P") {
      
        headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
                      headerObj.wpq_signaturePrepared = headerObj.wpq_signaturePrepared;
      }



      
 
    let res = await AddNewGrooveWPQ(headerObj)
    let resdet = await UpdateWPQDetailComplete(postList)
    debugger
   


    if(res !== undefined && resdet !== "SpecialCharacters" ){

      result = true;

          setaction(Action.ActionEdit);
          result = true;
      if (
        action === Action.ActionEdit &&
        approvalstatus !== Status.StatusTempSaved
      ) {
        result = true;

      }
  } else{
    alert(process.env.REACT_APP_SPECIAL_CHARACTER_MSG);
   }
  return result
  };

    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };

    useEffect(() => {

    }, [IsEdited]);

    

    const validateTemp = async () => {
      debugger
      let isError = false;

       let temperror = emptyError(error, setError, isError);




      return {isError ,temperror};
    };

    const handleSubmitTemp = async () => {
      debugger
      let result = false;
      var val = await validateTemp();


        if (IsEdited) {
          if (val.isError === false){
          if ( val.temperror === false) {
            setIsEdited(false);
            if (headerObj?.WPSH_Status === Status.StatusApproved) {
              result = await handleAdd(Status.StatusApproved);
            } else if (headerObj?.WPSH_Status === Status.StatusPending) {
              result = await handleAdd(Status.StatusPending);
            } else {
              result = await handleAdd(Status.StatusTempSaved);
            }

            if (result) {
              return MessageTypes.Temp;
            }
            else {
              return MessageTypes.Fail;
            }
     
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;

        }
        return MessageTypes.None;
      
  }

    const handleSubmit = async () => {
      
      setIsEdited(false);
      if ((await validate())) {
        setIsEdited(true);
      }

 
  
      let result = false;
      
      const isUsernamePresent = await checkIfUsernameExists();
      

      if (isUsernamePresent) {

        headerObj.WPSH_Status = Status.StatusTempSaved;
      }




      if (!(await validate())) {
        
        result = await handleAdd(Status.StatusPending);

        
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data Before Leaving this Page")
          }
        }, 500);
        return ([MessageTypes.RequiredAll, error_message, headerObj?.wld_WPSType]);

      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext4: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp4: async () => {
        
        var res = false;

        res = await handleSubmitTemp();

        return res;
      },
    }));








    const fetchbyuserprofile = async (userprofile) => {
      try {


        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);


          const geturl = result.url;
          setPreviewuser(geturl);

        }
        else{
          setPreviewuser(avatar.defaultimg);

        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };


    const fetchapproveUserProfile = async (userprofile) => {
      
      try {


        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);
          const geturl = result.url;
          setPreviewapproved(geturl);

        }
        else{
          setPreviewapproved(avatar.defaultimg);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };
    useEffect(() => {

      const currentDate = new Date();
      formattedDate = new Date().toLocaleDateString("en-GB")
      setToday(formattedDate);

      setReadonly(
        DisableFieldsWPQ(headerObj, Status, Action, action) ? "disabled" : ""
      );

    }, []);

    useEffect(() => {
      
            if(action === Action.ActionEdit && headerObj?.wpq_signaturePrepared !== ""){

      
              fetchbyuserprofile(headerObj?.wpq_signaturePrepared)
            }
      
            if(action === Action.ActionEdit && headerObj?.wpq_signatureApproved !== ""){

            fetchapproveUserProfile(headerObj?.wpq_signatureApproved)
            }
      
          }, []);



    const handleChange = (e) => {
      
      setIsEdited(true);
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangeRadio = async (e) => {

      setIsEdited(true);
      if (!(await validate())) {

      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    else{
      alert("Cannot Approve or Reject the Record, Please Fill Required Fields of all 3 Pages.")
    }
    };


    const handleChangetestarray = (Index, key, newValue) => {
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };

      updatedheadertestObj.Radiography_Test[Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };

    const handleChangeOther_Testarray = (Index, key, newValue) => {
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };

      updatedheadertestObj.Other_Test[Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };

    const handleChangeMinimum_Leak_Patharray5 = (
      Index,
      key,
      table,
      newValue
    ) => {
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };

      updatedheadertestObj.Minimum_Leak_Path5[Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };
    const handleChangeMinimum_Leak_Patharray = (
      Index,
      key,
      table,
      newValue
    ) => {
      
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };

      updatedheadertestObj.Minimum_Leak_Path[0][table][Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };



    const handleChangedetail = (e, i) => {
      
      setIsEdited(true);
      const { name, value } = e.target;
      // const onChangeVal = [...detailObj];
      // onChangeVal[i][name] = value;
      // setdetailObj(onChangeVal);
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


    
    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines = 2; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
     setIsEdited(true);
      // setIsEditedPage3(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 45) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,45}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n'); // Use 'let' here instead of 'const'
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setdetailObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };



    return (
      //style="width:70%"
      <fieldset disabled={isReadonly}>
      <Row className="font">
        <WPQHeader headerObj={headerObj}></WPQHeader>

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Minimum Leak Path </p>

            {/* ////////////////////////2nd table Start ////////////////////////////////////////////*/}
            <Col md={6}>
              
              <WPQ_Page3_Table2
              
                array={Minimum_Leak_Path1}
                setheaderObj={setheaderObj}
                setError={setError}
                name1="WPQ_MLP_Tube_No"
                name2="WPQ_MLP_LocA"
                name3="WPQ_MLP_LocB"
                name4="WPQ_MLP_LocC"
                name5="WPQ_MLP_LocD"
                table="Table1"
                error1={"WPQ_MLP_Tube_No"}
                error2={"WPQ_MLP_LocA"}
                error3={"WPQ_MLP_LocB"}
                error4={"WPQ_MLP_LocC"}
                error5={"WPQ_MLP_LocD"}

                status={error.WPQ_MLP_Tube_No.status}
                message={error.WPQ_MLP_Tube_No.message}
                status1={error.WPQ_MLP_LocA.status}
                message1={error.WPQ_MLP_LocA.message}
                status2={error.WPQ_MLP_LocB.status}
                message2={error.WPQ_MLP_LocB.message}
                status3={error.WPQ_MLP_LocC.status}
                message3={error.WPQ_MLP_LocC.message}
                status4={error.WPQ_MLP_LocD.status}
                message4={error.WPQ_MLP_LocD.message}
   
                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>

            <Col md={6}>
              <WPQ_Page3_Table2
                array={Minimum_Leak_Path2}
                setheaderObj={setheaderObj}
                setError={setError}
                name1="WPQ_MLP_Tube_No"
                name2="WPQ_MLP_LocA"
                name3="WPQ_MLP_LocB"
                name4="WPQ_MLP_LocC"
                name5="WPQ_MLP_LocD"
                table="Table2"
                error1={"WPQ_MLP_Tube_No1"}
                error2={"WPQ_MLP_LocA1"}
                error3={"WPQ_MLP_LocB1"}
                error4={"WPQ_MLP_LocC1"}
                error5={"WPQ_MLP_LocD1"}

                  status={error.WPQ_MLP_Tube_No1.status}
                message={error.WPQ_MLP_Tube_No1.message}
                status1={error.WPQ_MLP_LocA1.status}
                message1={error.WPQ_MLP_LocA1.message}
                status2={error.WPQ_MLP_LocB1.status}
                message2={error.WPQ_MLP_LocB1.message}
                status3={error.WPQ_MLP_LocC1.status}
                message3={error.WPQ_MLP_LocC1.message}
                status4={error.WPQ_MLP_LocD1.status}
                message4={error.WPQ_MLP_LocD1.message}

                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>

                       {/* ////////////////////////3nd table ////////////////////////////////////////////*/}

            <Col md={12} style={{height:"215px"}}>
              <WPQ_Page3_Table3
                array={Minimum_Leak_Path5}
                setheaderObj={setheaderObj}
                setError={setError}
                name1="WPQ_MLP_Magnification"
                name2="WPQ_MLP_Acceptance_Criteria"
                name3="WPQ_MLP_Observation"
                table="Table5"
                value3={headerObj?.WPQ_MLP_Observation}
                status={error.WPQ_MLP_Magnification.status}
                message={error.WPQ_MLP_Magnification.message}
                status1={error.WPQ_MLP_Acceptance_Criteria.status}
                message1={error.WPQ_MLP_Acceptance_Criteria.message}
                status2={error.WPQ_MLP_Observation.status}
                message2={error.WPQ_MLP_Observation.message}
             
                handleChangetestarray={handleChangeMinimum_Leak_Patharray5}
                maxLength1={16}
                maxLength2={11}
                />
                <WPQ_Page3_Table3observation
                  
                  setheaderObj={setheaderObj}
                  setError={setError}
          
                  name3="WPQ_MLP_Observation"
                  table="Table5"
                  value3={detailObj?.WPQ_MLP_Observation}

                  status2={error.WPQ_MLP_Observation.status}
                  message2={error.WPQ_MLP_Observation.message}
      
                  handleChangetestarray={handleChangeNotes}

                  maxLength3={180}
                  valueNotes={valueNotes}


               
              />
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "0px 60px" , position:"relative", bottom:"20px"}}>
          <span className="WPS-blue-text"  style={{position:"relative",right:"11px",top:"10px"}}>Result</span>
          <Row className="mb-1">
            <Col md={12}>
              <div class="T-input w-100">
                <input
                  type="text"
                  id="WPQ_MLP_Result"
                  name="WPQ_MLP_Result"
                  
                  maxLength={60}
                  placeholder=" "
                  value={detailObj?.WPQ_MLP_Result}
                  onChange={handleChangedetail}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_MLP_Result: error,
                    }));
                  }}
                />
                <label for="WPQ_MLP_Result">Result</label>
              </div>
              {error.WPQ_MLP_Result.status && (
                <Form.Text className="text-danger">
                  {error.WPQ_MLP_Result.message}
                </Form.Text>
              )}
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "20px 60px", position:"relative", bottom:"30px"}}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Radiography Test </p>
              

              <WPQ_Page3_Table1
                array={Radiography_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title1="Tube No."
                title2="Acceptance Criteria"
                title3="Results"
                title4="Remark"
                name1="WPQ_Radio_TubeNo"
                name2="WPQ_Radio_Acceptance_Criteria"
                name3="WPQ_Radio_Observation"
                name4="WPQ_Radio_Results"
                status={error.WPQ_Radio_TubeNo.status}
                message={error.WPQ_Radio_TubeNo.message}
                status1={error.WPQ_Radio_Acceptance_Criteria.status}
                message1={error.WPQ_Radio_Acceptance_Criteria.message}
                status2={error.WPQ_Radio_Observation.status}
                message2={error.WPQ_Radio_Observation.message}
                status3={error.WPQ_Radio_Results.status}
                message3={error.WPQ_Radio_Results.message}
                readOnly={isReadonly}
                handleChangetestarray={handleChangetestarray}
                maxLengthname1={21}
                maxLengthname2={13}
                maxLengthname3={14}
                maxLengthname4={21}
              />
            </Col>

            <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="WPQ_Radio_Notes"
                type="text"
                // style={{ height: "100px" }}
                maxLength={80}
                
                status={error.WPQ_Radio_Notes.status}
                value={detailObj?.WPQ_Radio_Notes}
                onChange={handleChangedetail}
                label="Notes"
                message={error.WPQ_Radio_Notes.message}
              setError={setError}
              error={error}
              ></Single_Input>
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "20px 60px" , position:"relative",bottom:"70px"}}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Other Test </p>
             

              <WPQ_Page3_Table1
                array={Other_Test}
                setheaderObj={setheaderObj}
                setError={setError}
                title1="Type of Test"
                title2="Acceptance Criteria"
                title3="Results"
                title4="Remark"
                maxLengthother={100}
                name1="WPQ_OT_TypeofTest"
                name2="WPQ_OT_Acceptance_Criteria"
                name3="WPQ_OT_Observation"
                name4="WPQ_OT_Results"
                status={error.WPQ_OT_TypeofTest.status}
                message={error.WPQ_OT_TypeofTest.message}
                status1={error.WPQ_OT_Acceptance_Criteria.status}
                message1={error.WPQ_OT_Acceptance_Criteria.message}
                status2={error.WPQ_OT_Observation.status}
                message2={error.WPQ_OT_Observation.message}
                status3={error.WPQ_OT_Results.status}
                message3={error.WPQ_OT_Results.message}
                maxLengthname1={21}
                maxLengthname2={13}
                maxLengthname3={14}
                maxLengthname4={21}
                handleChangetestarray={handleChangeOther_Testarray}
              />
            </Col>

            <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="WPQ_OT_Notes"
                type="text"
                // style={{ height: "100px" }}
                maxLength={80}
                
                status={error.WPQ_OT_Notes.status}
                value={detailObj?.WPQ_OT_Notes}
                onChange={handleChangedetail}
                label="Notes"
                message={error.WPQ_OT_Notes.message}
                setError={setError}
                error={error}
              ></Single_Input>
            </Col>
          </Row>

 {/* -------------------------prepared by and approved by Adjacent --------------------------------*/}

          <Row style={{ padding: "20px 13px" }}>
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">Supervised By - Welding Engineer</span>
              </Col>
              <Col style={{paddingLeft:"101px"}}>
                <span className="WPS-blue-text"> Approved By - Welding Engineer</span>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_PreparedBy_Name"
                      name="WPSH_PreparedBy_Name"
                      className={
                        error.WPSH_PreparedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_PreparedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      
                      valueField={"um_name"}
                      // disabled={action === Action.ActionEdit ? true : false}
                      value={headerObj?.WPSH_PreparedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        setIsEdited(true);

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: prename.value,
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });

                        fetchbyuserprofile(a[0].wld_profile);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: a[0].um_name,
                          wpq_signaturePrepared: a[0].wld_profile,
                          WPSH_PreparedBy: a[0].SK,
                        }));

                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_PreparedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PreparedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
          
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Col md={3} style={{position:"relative",left:"38px"}}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_ApprovedBy_Name"
                      name="WPSH_ApprovedBy_Name"
                      className={ error.WPSH_ApprovedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_ApprovedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      
                      valueField={"um_name"}
                      // disabled={action === Action.ActionEdit ? true : false}
                      value={headerObj?.WPSH_ApprovedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        setIsEdited(true);

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: prename.value,
                        }));
                        


                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });

                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: a[0].um_name,
                          wpq_signatureApproved: a[0].wld_profile,
                          WPSH_ApprovedBy: a[0].SK,
                        }));

                        fetchapproveUserProfile(a[0].wld_profile
                        );
                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_ApprovedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_ApprovedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3} style={{position:"relative", left:"30px"}}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
                                   obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Row className="mb-4" >
                <Col style={{position:"relative", left:"13px"}}>
                  <span>Signature Image</span>
                </Col>
                <Col style={{position:"relative", left:"57px"}}>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row style={{position:"relative", bottom:"10px"}}>
                <Col md={6}style={{position:"relative", left:"14px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>

                <Col md={6} style={{position:"relative", left:"58px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
              </Row>
            
              <Row className="mb-4" style={{padding:"0px 15px",position:"relative", bottom:"10px",paddingTop:"3%"}}>
                  {/* <div className="Supervised_remark">
                  <div className=""> */}
                <Col md={3} style={{width:"50%"}}>
                  <Single_Input_Superviser_remark
                    disabled={copydata}
                    name="WPSH_PreparedBy_Remark"
                    type="text"
                    style={{ height: "90px" }}
                    maxLength={50}
                    readOnly={isReadonly}
                    status={error.WPSH_PreparedBy_Remark.status}
                    value={headerObj?.WPSH_PreparedBy_Remark}
                    handleChange={handleChange}
                    label="Remark"
                    message={error.WPSH_PreparedBy_Remark.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PreparedBy_Remark: error,
                      }));
                    }}
                  ></Single_Input_Superviser_remark>
                </Col>
                
                {Band === "P" || Band === "T" ? (
                  <Col md={3} style={{paddingLeft:"58px",width:"50%"}}>
                    <div class="T-input w-100">
                      <input
                        style={{ height: "90px",width:"112%",position:"relative" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        maxLength={200}
                        readOnly={isReadonly}
                        // disabled={copydata}
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        // value={ProjectObj.wld_ProjectNumber}
                        // onChange={handleChange}
                        value={headerObj?.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    <div className="required-text1">
                      {error.WPSH_ApprovedBy_Remark.status && (
                        <Form.Text className="text-danger">
                          {error.WPSH_ApprovedBy_Remark.message}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                ) : (
                  <Col md={3} style={{paddingLeft:"58px",width:"50%"}}>
                    <div class="T-input w-100" >
                      <input
                        style={{ height: "90px",width:"112%",position:"relative", left:"6px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        readOnly
                        maxLength={200}
                        // disabled={copydata}
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        // value={ProjectObj.wld_ProjectNumber}
                        // onChange={handleChange}
                        value={headerObj?.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                   
                  </Col>
                )}
             
                
                {/* </div>
                </div> */}
               

                </Row>
                 {Band === "P" || Band === "T" ? (
                <Col style={{position:"relative",bottom:"40px" ,left:"54%"}}> 
               <span className="WPS-blue-text">Action</span>
             
                <div className="d-flex mb-4" style={{paddingTop:"10px"}}>
                    <div>
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Approved"
                        // checked={this.state.site === result.SITE_NAME}
                        // onChange={this.onSiteChanged}
                        checked={headerObj?.WPSH_Approval_Status === "Approved"}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Approve</span>
                    </div>
                    <div className="ms-5">
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Rejected"
                        // checked={this.state.site === result.SITE_NAME}
                        // onChange={this.onSiteChanged}
                        checked={headerObj?.WPSH_Approval_Status === "Rejected"}
                        onChange={(e) => handleChangeRadio(e)}
                     in />
                      <span className="ms-2">Reject</span>
                    </div>
                  </div>
                </Col>    
              ) : (
                ""
              )}
            </Row>
          </Row>
        </Row>
      </Row> 
      </fieldset>
    );
  }
);

export default WPQ_Tubesheet_Page4;
