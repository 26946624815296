import axios from "axios";

let INTERFACE_URL
let MASTER_API_URL
if (process.env.REACT_APP_NODE_ENV === "local") {
   INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "CRData/";
   MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
   INTERFACE_URL = process.env.REACT_APP_API_URL + "interface/CRData/";
   MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getAllItem() {

  const response = await axios
    .get(MASTER_API_URL + "/Item/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getActiveItem() {
  
  const response = await axios
    .get(MASTER_API_URL + "/item/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export async function getActiveItemForProject(proj) {
  
  let reqBody = {Project:proj}
  const response = await axios
    .post(MASTER_API_URL + `/item/project`,reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export const AddNewItem = async (Item) => {
  let url = `${MASTER_API_URL}/item`;
  const response = await axios
    .post(url, Item)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateItem = async (Item) => {
  let url = `${MASTER_API_URL}/item/update`;
  const response = await axios
    .post(url, Item)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getItemsByProject = async (projectNo) => {

  let reqBody = {Project:projectNo}
  let url = `${INTERFACE_URL}items/projectNo`;

  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteItem(skid) {
  let url = `${MASTER_API_URL}/Item/delete/${skid}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

// ITEM DATA FROM LN
export async function getAllItems() {
  const response = await axios
    .get(INTERFACE_URL + "items")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getItemsByItem = async (itemNo) => {
  let url = `${INTERFACE_URL}items/itemNo/${itemNo}`;
  const response = await axios
    .get(url, itemNo)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
