
import "../Css/groovePQR_pdf.css"

import Groove_pdf_header_common from "../../../WPSTransaction/PDF/Pages/Groove_pdf_header_common";


const TTS_pdf_headerWPQpage3 = ({pdf, setpdf},props,wld) => {
  const currentDate = new Date(pdf[0]?.PQR_Date);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
 return(
       <header>
         <Groove_pdf_header_common
        currentDate={currentDate}
        from="PQR"
      ></Groove_pdf_header_common>

     
<table id="table" className="WPSTable1" style={{width:"570px"}}>
        <tbody>
          <tr>
            <th
              style={{
                width: "25%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
                borderLeft: "1px solid",whiteSpace:"nowrap"
              }}
            >
                Welder Name
                 <br></br>
                 ID Number
                 <br></br>
            </th>
            <td
              style={{
                width: "45%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <span className="WPSforfont" style={{ float: "left",whiteSpace:"nowrap" }}>
                {pdf[0]?.PQR_Certification_Welder_Name}
              </span><br></br>
              <span className="WPSforfont" style={{ float: "left",whiteSpace:"nowrap" }}>
                {pdf[0]?.PQR_Certification_ID}
              </span>
            </td>
            <th
              className="WPSright-th"
              style={{
                width: "25%",
                borderLeft: "1px solid",
                borderBottom: "1px solid",
                borderTop: "1px solid",
                borderRight: "1px solid",
              }}
            >
              Test Date
             <br></br>
             WPQ record Number
            </th>

            <td
              style={{
                width: "35%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <span className="WPSforfont" style={{ float: "left",whiteSpace:"nowrap" }}>
              {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
              <br></br>
              </span>
              <br />
              <span className="WPSforfont" style={{ float: "left",whiteSpace:"nowrap" }}>
                {pdf[0]?.PQR_RecordNo} <br></br>
              </span>
            </td>

            <td
              style={{
                width: "35%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
              }}
            >
              {pdf[0]?.WPS_QualifiedTo}
              <br></br>
              {pdf[0]?.WPSH_CompanyName}
            </td>
          </tr>
        </tbody>
      </table>



</header> )
}
export default TTS_pdf_headerWPQpage3
