import React, { useEffect, useState } from "react";
import "./Css/Download_pdf.css";
import {  Modal } from "react-bootstrap";
import { getdetailsById } from "../../../Services/wps_details-service";
import {getbyprojectno} from "../../../Services/wps_groove-services";
import { jsPDF } from "jspdf";
import Groove_pdf_page1 from "./Pages/Groove_pdf_page1";
import Groove_pdf_page2 from "./Pages/Groove_pdf_page2";
import Tubesheet_Pdf_Page2 from "./Pages/Tubesheet_pdf_page2"
import {
  getPresignedURL,
} from "../../../Services/Welder-services";

import BarLoader
from "react-spinners/BarLoader";

import TubeToTubesheet_pdf_page1 from "./Pages/TubeToTubesheet_pdf_page1";
import Jspdf_font from "../../../Assests/font/jsPdf_font";



const   Pdf_Download = (props,wld) => {


  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);



  const [PreviewUrl, setPreviewUrl] = useState([]);

  const [previewUrl1, setPreviewUrl1] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  const [previewUrl3, setPreviewUrl3] = useState();





  const [pdf, setpdf] = useState([]);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");


  let folderwld = "wld_signatures"
  let folderwps = "WPS_Groove"



  const fetchbyid = async (IddetailPK, IddetailSK) => {
    const result = await getdetailsById(IddetailPK, IddetailSK);
 
    const wpshead = await getbyprojectno(props.rowdata.wld_WPSType,IddetailPK, IddetailSK)

    setpdf([...wpshead, ...result]);

  };

 

  const createPDF = () => {
    fetchbyid(props.rowdata.WPSH_Project_No, props.rowdata.WPSH_WPS_No);

   
  };
 

  useEffect(() => {
    createPDF();
  }, []);




  const fetchimagespdf = async () => {

    if (props.rowdata.Pdf_images[0].tube_Hole_ligament[0] !== "") {
     
      const result = await getPresignedURL(folderwps,props.rowdata.Pdf_images[0].tube_Hole_ligament[0]);
     
      const geturl = result.url;
      setPreviewUrl1(geturl);
     
    }

    if (props.rowdata.Pdf_images[1].tube_Hole[0] !== "") {
    
      const result = await getPresignedURL(folderwps,props.rowdata.Pdf_images[1].tube_Hole[0]);
    
      const geturl = result.url;
      setPreviewUrl2(geturl);
     
    }
  
  };

  const fetchimagespdfGroove = async () => {

    if (props.rowdata.wld_pdf_image[0].Image1[0] !== "") {

      const result = await getPresignedURL(folderwps,props.rowdata.wld_pdf_image[0].Image1[0]);
    
      const geturl = result.url;
      setPreviewUrl1(geturl);
     
    }

    if (props.rowdata.wld_pdf_image[1].Image2[0] !== "") {
    
      const result = await getPresignedURL(folderwps,props.rowdata.wld_pdf_image[1].Image2[0]);
     
      const geturl = result.url;
      setPreviewUrl2(geturl);
  
    }
  
  };

  
  const fetchimages3 = async () => {

    if (props.rowdata.Pdf_images[2].tube_Weld_Joint[0] !== "") {
      
   
      const result = await getPresignedURL(folderwps,props.rowdata.Pdf_images[2].tube_Weld_Joint[0]);

      const geturl = result.url;
      setPreviewUrl3(geturl);
   
    }

  }

  const fetchimages3Groove = async () => {

    if (props.rowdata.wld_pdf_image[2].Image3[0] !== "") {

      const result = await getPresignedURL(folderwps,props.rowdata.wld_pdf_image[2].Image3[0]);

      const geturl = result.url;
      setPreviewUrl3(geturl);
    
    }

  }


    const fetchapproveUserProfileswld = async () => {
    try {
      

      if (props.rowdata.wps_signaturePrepared !== ""  &&  props.rowdata.wps_signaturePrepared !== undefined) 
      {
          const result = await getPresignedURL(folderwld,
            props.rowdata.wps_signaturePrepared
          );
        
          const geturl = result.url;
          setPreviewuser(geturl);
        
        
      }
      if (props.rowdata.wps_signatureApproved !== "" &&  props.rowdata.wps_signatureApproved !== undefined) 

      {
       
          const result = await getPresignedURL(folderwld,
            props.rowdata.wps_signatureApproved
          );

          const geturl = result.url;
          setPreviewapproved(geturl);
        
       
   
      }
    } catch (error) {
      if (error.request) {
       console.log(error.request);
      } else {
       console.log(`Error: ${error.message}`);
      }
    }
  };


 


  const downloadPdf = () => {
    setTimeout(() => {
          let doc = new jsPDF('p', 'pt', "a4");

          doc = Jspdf_font(doc)
          let pdfjs = document.querySelector('#MainDiv');
          
          doc.html(pdfjs, {
            
        callback: function(doc) {

           var Filename = `WPS ${pdf[0].wld_WPSType} - ${pdf[0].WPSH_WPS_No}`
          doc.save(Filename);
          props.onHide();
         props.setReload(!props.reload);

         setLoading(false)
        


        

        },margin: [-25, 10, 10,10]
        
       
    });      
   
  },9000);
}


  useEffect(() => {

      fetchimagespdf()

      fetchimages3()

      fetchimagespdfGroove()
      fetchimages3Groove()

    fetchapproveUserProfileswld()
    if (pdf.length > 0) {
      downloadPdf();
      // setShowForm(false)
    }
  
  }, [pdf]);

  let page1 = "1"
  let page2 = "2"



return(

<>
      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}

          </p>
        </Modal.Body>
      </Modal>



        {pdf.length>0  && <div id="MainDiv" >
          <br></br>
        {pdf[0]?.wld_WPSType === "Tubesheet" ? (
      <TubeToTubesheet_pdf_page1 page={page1} pdf={pdf} PreviewUrl= {PreviewUrl}  previewUrl1= {previewUrl1}   previewUrl2= {previewUrl2}  previewUrl3= {previewUrl3} setpdf={setpdf} />
    ) : (
      <Groove_pdf_page1 pdf={pdf} setpdf={setpdf} />
    )}
         {pdf[0]?.wld_WPSType === "Tubesheet" ? (
     <> <br></br> <Tubesheet_Pdf_Page2 page={page2} pdf={pdf} Band={props.Band} PreviewUrl= {PreviewUrl} previewuser = {previewuser} previewapproved={previewapproved}  /></>
    ) : (
         <div style={{pageBreakAfter:"always",paddingTop:"4px"}}> <br></br>
         <Groove_pdf_page2  pdf = {pdf} Band = {props.Band} PreviewUrl= {PreviewUrl} previewuser = {previewuser} previewapproved={previewapproved}  previewUrl1= {previewUrl1}   previewUrl2= {previewUrl2} previewUrl3={previewUrl3} />
         </div>
         )}
       </div>}

</>
)
    }


export default Pdf_Download;
