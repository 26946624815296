import { Button, Modal, Alert } from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState, useEffect, useMemo } from "react";
import { getAllRoles } from "../../Services/role-service";
import GolbalSearch from "./GolbalSearch";
import CustomPopUpTable from "../layout/CustomPopUpTable";
import { getPEDUsers } from "../../Services/user-service";
import tick from "../../icons/tick.svg";
import { getWPS } from "../WPSTransaction/WPSObjects/WPS_Validations";
import { getGridHeaderData } from "../../Services/wpq_groove-services";

import { getActiveWelder } from "../../Services/Welder-services";

const SingleValuePopUp = (props) => {
  const gridRef = useRef();
  const [dataMethod, setDataMethod] = useState();
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState(null);

  const [filteredCol, setFilteredCol] = useState([]);
  const [globalText, setGlobalText] = useState("");
  const [filteredResult, setFilteredResult] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");

  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year);


  const fetchData = async () => {

    switch (masterName) {
      case "PED User":
        {
          const result = await getPEDUsers();


          setRowData(result);
          setFilteredResult(result);
        }
        break;
      case "User Role":
        {
          const result = await getAllRoles();


          setRowData(result);
          // setFilteredResult(result);
          setFilteredResult(
            result.filter((data) => data.rm_status === "Active")
          );
        }
        break;
      case "WPS":
        {
          const result = await getWPS(props.Project);

          setRowData(result);
          // setFilteredResult(result);
          setFilteredResult(result);
        }
        break;
      case "Welder":
        {
          const result = await getActiveWelder();


          setRowData(result);
          setFilteredResult(result);
        }
        break;
        case "WPQ":
          {
            const result = await getGridHeaderData(
              props.JointType,
              sessionStorage.getItem("Band"),
              sessionStorage.getItem("User"),
              selectedYear
            );

            setRowData(result);
            // setFilteredResult(result);
            setFilteredResult(result);
          }
        break;
    }

  };

  let arr = props.masterName.split(":");
  let masterName = arr[0];

  useEffect(() => {

    switch (masterName) {
      case "PED User":
        {
          setColumnDefs([
            {
              width: 80,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "empSalCode",
              headerName: "Employee Code",
              tooltipField: "empSalCode",
            },
            {
              field: "empEmailId",
              headerName: "Email ID",
              tooltipField: "empEmailId",
            },
            {
              field: "empshortName",
              headerName: "Employee Name",
              tooltipField: "empShortName",
            },
            {
              field: "empL1",
              headerName: "Employee L1",
              tooltipField: "EmpL1",
            },
            {
              field: "empBand",
              headerName: "Employee Band",
              tooltipField: "EmpBand",
            },
          ]);
        }
        break;
      case "User Role":
        {
          setColumnDefs([
            {
              width: 80,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "SK",
              headerName: "Role Code",
              tooltipField: "SK",
            },
            {
              field: "rm_name",
              headerName: "Role Name",
              tooltipField: "rm_name",
            },
          ]);
        }
        break;
      case "WPS":
        {
          setColumnDefs([
            {
              width: 80,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "WPSH_WPS_No",
              headerName: "WPS Number",
              tooltipField: "WPS",
            },
            {
              field: "SK",
              headerName: "SK",
              tooltipField: "WPS",
              hide: true,
            },
          ]);
        }
        break;

      case "WPQ":
        {
          setColumnDefs([
            {
              width: 60,
              cellRenderer: CheckboxCellRenderer,
            },
            {
              width: 150,
              field: "WPQ_WelderId",
              headerName: "Welder ID",
              tooltipField: "WPQ_WelderId",
            },
            {
              width: 150,
              field: "WPQ_WelderName",
              headerName: "Welder Name",
              tooltipField: "WPQ_WelderName",
            },
            {
              width: 150,
              field: "WPQ_RecordNo",
              headerName: "WPQ No",
              tooltipField: "WPQ_RecordNo",
            },
            {
              field: "wld_WPSType",
              headerName: "Joint Type",
              tooltipField: "wld_WPSType",
            },
            {
              width: 150,
              field: "WPQ_Inspection",
              headerName: "Inspection agency",
              tooltipField: "WPQ_Inspection",
            },
            {
              width: 150,
              field: "WPQ_Qualificationcode",
              headerName: "Qualification Code",
              tooltipField: "WPQ_Qualificationcode",
            },
            {
              width: 150,
              field: "WPQ_PlantLocation",
              headerName: " Plant Location",
              tooltipField: "WPQ_PlantLocation",
            },
            {
              width: 150,

              field: "WPQ_specialNote",
              headerName: " Special Notes",
              tooltipField: "WPQ_specialNote",
            },
          ]);
        }
          break;
      case "Welder":
        {
          setColumnDefs([
            {
              width: 80,
              cellRenderer: radioCellRenderer,
            },
            {
              width: 150,
              field: "wld_name",
              headerName: "Welder Name",
              tooltipField: "Welder Name",
            },
            {
              width: 150,
              field: "wld_code",
              headerName: "Welder Code",
              tooltipField: "Welder Code",
            },
            {
              width: 150,
              field: "SK",
              headerName: "Salary Code",
              tooltipField: "Salary coce",
            },
            {
              field: "wld_email",
              headerName: "Email",
              tooltipField: "Email",
            }])
        }
        break;
    }

    fetchData();

  }, [dataMethod]);

  const radioCellRenderer = (params) => {
    let cellValue = (
      <div className="container">
        <input
          name="selection"
          type="radio"
          id={`selection${params.node.rowIndex}`}
          defaultChecked={
            // eval("params.data." + props.paramkey) === props.paramvalue
            params.data[props.paramkey] === props.paramvalue
              ? true
              : false
          }
        ></input>
        <span class="greenTick">
          <img src={tick} />
        </span>
      </div>
    );
    // eval("params.data." + props.paramkey) === props.paramvalue
    params.data[props.paramkey] === props.paramvalue
      ? params.node.setSelected(true)
      : params.node.setSelected(false);
    return cellValue;
  };

  const CheckboxCellRenderer = (params) => {

    let cellValue = (
      <div className="container">
        <input
          name="selection"
          type="checkbox"
          id={`selection${params.node.rowIndex}`}
          defaultChecked={
            // eval("params.data." + props.paramkey) === props.paramvalue
            params.data[props.paramkey] === props.paramvalue
              ? true
              : false
          }
        ></input>
        <span class="greenTick">
          <img src={tick} />
        </span>
      </div>
    );
    // eval("params.data." + props.paramkey) === props.paramvalue
    params.data[props.paramkey] === props.paramvalue
      ? params.node.setSelected(true)
      : params.node.setSelected(false);
    return cellValue;
  };

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
  };
  const assignData = () => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    

    let L1;
    if (props.masterName === "PED User") {
      
      let L1Data = rowData.find(
        (i) => i.empSalCode === selectedRows[0].empL1
      );

      if (L1Data) {
        L1 = { empL1Name: L1Data.empshortName, empL1Email: L1Data.empEmailId };
      }
    }

    if (selectedRowsCount > 0) {
      props.setSelected({
        name: props.masterName,
        result: selectedRows[0],
        L1: L1,
      });
      props.onHide();
    } else
      setAlertMessage(
        "Please select a record before clicking on Assign button."
      );
  };

  const assignCustomData = () => {
    let selectedRows = selectedCard;
    if (selectedRows.length > 0) {

      props.setSelected({ name: props.masterName, result: selectedRows[0] });
      props.onHide();
    } else {
      setAlertMessage(
        "Please select a record before clicking on Assign button."
      );
    }
  };

  const onGridReady = () => {
    gridRef.current.api.sizeColumnsToFit();
  };
  const handleRadioSelection = () => {
    let nodeEl = gridRef.current.api.getSelectedNodes();
    let node = document.getElementById(`selection${nodeEl[0].rowIndex}`);
    node.checked = true;
  };

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    setGlobalText(searchText);
    let arr = props.masterName.split(":");
    let masterName = arr[0];
    if (searchText) {
      let result = rowData?.filter((item) => {
        switch (masterName) {
          case "User Role":
            return (
              item.SK.toString().indexOf(searchText) >= 0 ||
              item.rm_name.toLowerCase().indexOf(searchText) >= 0
            );
          case "PED User":
            return (
              item.empEmailId.toString().indexOf(searchText) >= 0 ||
              item.empSalCode.toString().indexOf(searchText) >= 0 ||
              item.empshortName.toLowerCase().indexOf(searchText) >= 0 ||
              item.empL1.toLowerCase().indexOf(searchText) >= 0
            );
          case "WPS":
            return item.WPSH_WPS_No.toString().indexOf(searchText) >= 0;

          case "Welder":
            return (
              item.SK.toString().indexOf(searchText) >= 0 ||
              item.wld_name.toLowerCase().indexOf(searchText) >= 0 ||
              item.wld_code.toLowerCase().indexOf(searchText) >= 0 
              ||
              item.wld_email.toLowerCase().indexOf(searchText) >= 0 
            );
        }
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(rowData);
    }
  };

  const resetAllFilter = () => {
    gridRef.current.api.setFilterModel(null);
  };

  function resetFilter(item) {
    let item1 = item.substring(0, item.indexOf(":") - 1);

    let col = gridRef.current.api.getFilterModel();
    for (const key of Object.keys(col)) {
      if (key === feildName(item1)) {
        delete col[key];
      }
    }
    gridRef.current.api.setFilterModel(col);
  }

  const headerName = (field) => {
    var name;
    switch (field) {
      case "SK":
        if(masterName != "Welder") 
            name = "Role Code";
        else
          name = "Salary Code";
        break;
        break;
      case "rm_name":
        name = "Role Name";
        break;
      case "empCode":
        name = "Employee Code";
        break;
      case "empEmailId":
        name = "Email ID";
        break;
      case "empShortName":
        name = "Employee Name";
        break;
      case "WPQ_WelderId":
        name = "Welder Id";
        break;
      case "WPQ_WelderName":
        name = "Welder Name";
        break;
      case "wld_code":
          name = "Welder Code";
          break;
      case "wld_name":
          name = "Welder name";
          break;

      case "wld_email":
        name = "Welder Email";
      break;

      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;

    switch (header) {
      case "Role Code":
        name = "SK";
        break;
      case "Role Name":
        name = "rm_name";
        break;

      case "Employee Code":
        name = "empCode";
        break;

      case "Email ID":
        name = "empEmailId";
        break;
      case "Employee Name":
        name = "empShortName";
        break;

      case "Welder Id":
        name = "WPQ_WelderId";
        break;

      case "Welder Name":
        name = "WPQ_WelderName";
        break;
      case "Welder Code" :
          name = "wld_code";
          break;
      case "Welder name":
          name =  "wld_name";
          break;
      case "Salary Code" :
        name = "SK";
        break;
      case "Welder Email" :
        name = "wld_email";
      break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };
  const onFilterChanged = (param) => {
    let a = document.getElementById("filterDisplayPop");

    setFilteredCol([]);
    let col = param.api.getFilterModel();

    if (Object.keys(col).length === 0) {
      a.classList.add("d-none");
    } else {
      a.classList.remove("d-none");
      for (const key of Object.keys(col)) {
        let filter;
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
        setFilteredCol((prev) => {
          return [...prev, filter];
        });
      }
    }
  };



  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <Modal {...props} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.masterName.split(":")[0] === "WPS"
              ? "WPS Number For " + props.Project
              : props.masterName.split(":")[0]}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="global-search-container">
              <GolbalSearch
                filterFunc={handleGlobalSearch}
                searchplaceholdertext={"Search for Code, Name, ID, Email"}
              />
            </div>
            <div
              className="d-none mt-3 d-flex"
              id="filterDisplayPop"
              style={{ gap: "10px" }}
            >
              {filteredCol &&
                filteredCol.map((item) => {

                  return (
                    <div className="filter-col pr-0  me-2">
                      <span className="ps-2 btn-text">{item}</span>
                      <div onClick={() => resetFilter(item)}>
                        <BsX size={32} />
                      </div>
                    </div>
                  );
                })}

              <Button
                variant="outline-primary"
                className="ms-2 lh-1 clear-all-btn"
                onClick={resetAllFilter}
              >
                Clear All
              </Button>
            </div>
            <div
              className="ag-theme-material d-none d-md-block d-lg-block"
              style={{ height: 350, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={filteredResult}
                columnDefs={columnDefs}
                rowSelection={"single"}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                headerHeight={50}
                rowHeight={50}
                pagination={true}
                paginationPageSize={5}
                onSelectionChanged={handleRadioSelection}
                onFilterChanged={onFilterChanged}
                enableBrowserTooltips={true}
                domLayout="autoHeight"
              ></AgGridReact>
            </div>
            <div className="d-md-none d-lg-none table-container1">
              <CustomPopUpTable
                masterName={masterName}
                data={filteredResult}
                setSelectedCard={setSelectedCard}
                paramvalue={props.paramvalue}
                paramkey={props.paramkey}
                type="single"
              />
            </div>
          </div>
        </Modal.Body>

        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
            {alertMessage}
          </Alert>
        )}

        <Modal.Footer className="justify-content-between flex-row-reverse">
          <Button
            className="submitbtn"
            // className="float-start me-2 d-none d-md-block d-lg-block"
            onClick={assignData}
          >
            Assign
          </Button>
          <Button

            size="sm"
            className="float-start me-2 d-md-none d-lg-none btn-primary"
            onClick={assignCustomData}
          >
            Assign
          </Button>
          <Button
            style={{top: "0px"}}
            className="resetbtn"
            onClick={() => props.onHide()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleValuePopUp;
