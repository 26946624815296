import axios from "axios";

let WI_API_URL;
let PROD_API_URL;
let VALIDATE_API_URL;
let WPQ_Linking_API_URL;


if (process.env.REACT_APP_NODE_ENV === "local") {
  WI_API_URL = process.env.REACT_APP_WI_API_URL;
  PROD_API_URL = process.env.REACT_APP_PROD_API_URL;
  VALIDATE_API_URL = process.env.REACT_APP_VALID_API_URL;
  WPQ_Linking_API_URL = process.env.REACT_APP_LINKING_API_URL;

} else {
  WI_API_URL = process.env.REACT_APP_API_URL + "wi";
  PROD_API_URL = process.env.REACT_APP_API_URL + "wi/prod";
  VALIDATE_API_URL = process.env.REACT_APP_API_URL + "wi/wiValidate";
  WPQ_Linking_API_URL = process.env.REACT_APP_API_URL + "wi/wpqlinking";

}





export async function getallwelders() {
  try {
    const response = await axios.get(WI_API_URL + "/getallWelder");
    return response.data;
  } catch (error) {
    throw new Error("Unable to find request");
  }
}


export async function getwiwelders() {
  let url = `${WI_API_URL}/getwidata/excel`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getWIdataexcelbyProjectno = async (projectNumber, jointNumber) => {
  debugger
  let reqbody={PK:projectNumber,SK:jointNumber}
  let url = `${WI_API_URL}/getWIdataexcelbyProjectno/excel`;
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getwiwelderswcr() {
  let url = `${WI_API_URL}/getwidata/wcr/excel`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getallWeldersearch() {
  
  let url = `${WI_API_URL}/getallWelder/search`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getallWelderid(id) {
  let url = `${WI_API_URL}/getallWelderid/${id}`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getCount() {
  
  const response = await axios
    .get(WI_API_URL + "/getcount")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const addwelder = async (welder) => {
  


  let url = `${WI_API_URL}/addwelder`;
  const response = await axios
    .post(url, welder)
    .then(function (resp) {

      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
};

export const UpdateSlip = async (row) => {
  
  let url = `${WI_API_URL}/updateslip`;
  const response = await axios
    .post(url, row)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const CancelSlip = async (slip) => {
  
  let url = `${WI_API_URL}/cancelslip`;
  const response = await axios
    .post(url, slip)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const DeleteSlip = async (slip) => {
  

  let url = `${WI_API_URL}/deleteslip`;
  const response = await axios
    .post(url, slip)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//Used in list of welder and Welder record sheet
export async function WIexportreport(wi) {

  
  let url = `${WI_API_URL}/exportreport`;

  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}


export async function WCRexportreport(wi) {

  
  let url = `${WI_API_URL}/WCRexportreport`;

  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}

export async function getLastWeldDate(wi) {
  

  
  let url = `${WI_API_URL}/getlatestwelddate`;

  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });


  return resp;
}

//below prod screen and report servises started
export async function getAllOnlySlipNos() {
  
  const response = await axios
    .get(PROD_API_URL + "/getAllSlipNos")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export async function getWIDataHeader(wiheader) {
  let url = `${PROD_API_URL}/slipNo`;

  const resp = await axios
    .post(url, wiheader)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export const addNewProductivity = async (prodMeasure) => {
  let url = `${PROD_API_URL}/addNewProductivity`;
  const response = await axios
    .post(url, prodMeasure)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function PRODexportreport(wi) {

  
  let url = `${PROD_API_URL}/exportreportPROD`;

  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}

export async function getProdMeasure(slipId) {
  let url = `${PROD_API_URL}/slipId`;

  const resp = await axios
    .post(url, slipId)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}

export async function checkJointNoPresent(gridDetail) {

  let url = `${VALIDATE_API_URL}/checkJointNo`;

  const resp = await axios
    .post(url, gridDetail)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export async function getForFilters() {
  let url = `${WI_API_URL}/getForFilters/inReport`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const addwpqlink = async (link) => {
  let url = `${WPQ_Linking_API_URL}/addlinking`;
  const response = await axios
    .post(url, link)
    .then(function (resp) {

      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getwpqlink() {
  let url = `${WPQ_Linking_API_URL}/getlinking`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function linkbyproject(link) {
  
  let url = `${WPQ_Linking_API_URL}/link/project`;

  const resp = await axios
    .post(url, link)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}


export async function linkbyprojecttype(projectno,type) {
  
  let url = `${WPQ_Linking_API_URL}/link/byprojecttype/${projectno}/${type}`;

  const resp = await axios
    .post(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}


export async function linkbyprojecttypetube(type) {
  
  let url = `${WPQ_Linking_API_URL}/link/byprojecttypetube/${type}`;

  const resp = await axios
    .post(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}
export async function getAllWiProjects() {
  let url = `${WI_API_URL}/getAllWiProjects`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getWIweldingprocess(projectNumber, jointNumber) {
  let keyObj = {
    projectNumber: projectNumber,
    jointNumber: jointNumber
  }
  let url = `${WI_API_URL}/getWiWeldingProcessProjectJointWise`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getWiWeldingNumbersProjJointProcessWise(projectNumber, jointNumber, processNumber) {
  let keyObj = {
    projectNumber: projectNumber,
    jointNumber: jointNumber,
    processNumber: processNumber
  }

  let url = `${WI_API_URL}/getWiWeldingNumbersProjJointProcessWise`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function getWIReqCount(ReqCount) {
  

  let url = `${WI_API_URL}/getJointNumbersLength/${ReqCount}`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function getListofProjectno() {

  //   "getListofProjectno",
  //   WI_API_URL + "/getListofProjectno"
  // );
  const response = await axios
    .get(WI_API_URL + "/getListofProjectno")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}