import React from "react";
import uploadImage from "../../icons/uploadImage.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";
import { BarLoader, ClockLoader } from "react-spinners";

export const ImageUploadRenderer = (props) => {
  
  return (
    <div>
      {props.profileOg !== "" && props.progress === 0 ? (
        <>
          <label className="label-color"
            onClick={() => {
              props.handleDownload(props.selectedData, props.imageData);
            }}
          >
            {props.downloadloading === true && (
              <ClockLoader
                size={15}
                className="gridimgdownloadloader"
              />
            )}{" "}
            &nbsp;
            {props.profileOg}
          </label>
          <img
            onMouseOver={(e) => (e.currentTarget.src = Delete)}
            onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
            src={Delete_gray}
            alt="edit"
            onClick={() => props.handleDelete(props.selectedData)}
          />
        </>
      ) : (
        // <a href="#" download>{props.profileOg}</a>

        <>
          {props.progress !== 0 ? (
            <>
              {/* <p>Upload Progress: {props.progress}%</p>
 <div
      className="progress-bar"
      style={{ width: `${props.progress}%` }}
    ></div> */}
              {/* <label className="uploading">Uploading Pdf</label> */}
              <label className="uploadingpdfname">{props.profileOg}</label>

              {props.Imgloading && (
                <BarLoader
                  height={4}
                  width={170}
                  className="gridimgloader"
                />
              )}
            </>
          ) : (
            <>
              <img for="fileInput" src={uploadImage} />

              <label for={props.id} className="label-color">
                Upload File <span style={{fontSize:"12px"}}> Size:Max 40MB</span>
              </label>
              <input
                id={props.id}
                name={props.name}
                type={props.type}
                accept={props.accept}
                hidden
                ref={props.ref}
                onChange={(a) =>
                  props.handleFile(a, props.selectedData, props.imageData)
                }
              />
            </>
          )}
        </>
      )}

      {/* <div className="Hyperlink" onClick={() => props.handleimage(props.selectedData)}>
    {props.Title}    </div> */}
    </div>
  );
};

export const QualificationDueInRenderer = (props) => {
  





  let row = props.selectedData.data;
  const [year, month, day] = props.selectedDate.split("-");

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  const todayDate = yyyy + "/" + mm + "" + dd;

  const date = todayDate.split("-");



  const future = new Date(year, month - 1, day);
  future.setDate(future.getDate() + 178);

  const timeDifferenceInMilliseconds = future - today;
  const daysDifference = Math.ceil(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  // const futureDateString = `${future.getFullYear()}/${String(future.getMonth() + 1).padStart(2, '0')}/${String(future.getDate()).padStart(2, '0')}`;
  props.setFutureDate(daysDifference);

  const monthsValue = Math.floor(props.futureDate / 30); // Assuming 30 days in a month
  const daysValue = props.futureDate % 30;

  props.setMonths(monthsValue);
  props.setDays(daysValue);

 

  let result = "";
  if (monthsValue < 1 && daysValue > 0) {
    result = `${daysValue} day`;
  } else if (monthsValue >= 1) {
    result = `${monthsValue} month ${daysValue} day`;
  } else if (daysValue <= 0) {
    result = "Expired";
  }



  props.handleResult(result);
  row.wpq_QualificationDueIn = result;

  return result;

 
};
