import { Row, Col, Table, Form } from "react-bootstrap";
import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {  DisableFields } from "./WPSObjects/WPS_common";
import { AddNewDetail } from "../../Services/wps_details-service";

import { isAlphanumericAllSpecialCharacters } from "../../functions/validations";
import { WPSHeader } from "./WPSObjects/WPSHeader";
import {
  WPS_Groove_Detail,
  Weld_Progression,
  Tungsten_size,
  Layer_No,
  Position_of_groove,
  Tungsten_type,
  WPS_Overlay_Detail,
  TypeData,
  Status,
  Action,
  MessageTypes,
  WPSPage2,
  Arc_Transfer,
  Single_Pass,
  FM_Product_Form,
  Current_Polarity,
  wps_field_Val,
  wps_field_Val_single,
  wps_field_Val_Overlay,
} from "./WPSObjects/WPSGroove_entity";
import { AddLogs } from "../../Services/wps-logs-services";
import {
  WPS_Process_Grid_Input,
  WPS_Process_Grid_Input_2,
  WPS_Process_Grid_Input_2_Amperes,
  WPS_Process_Grid_Input_3,
  WPS_Process_Grid_Input_3Filermetal,
  WPS_Process_Grid_Select,
  WPS_Process_Grid_Select_3,
  WPS_Process_Grid_Select_master_data,
} from "./WPSObjects/WPS_Process_Grid";

import { AddNewCount, AddNewGroove } from "../../Services/wps_groove-services";

import { getActiveItemForProject } from "../../Services/item-services";
import { emptyError } from "./WPSObjects/WPS_common";
import { DotLoader } from "react-spinners";

const WeldingProcedure = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      logObj,
      setKey,
      tabno,
      setIsEditedPage2,
    },
    ref
  ) => {
    // const [error, setError] = useState(false);
    let temp = [];
    const [ItemActive, setItemActive] = useState([]);
    const [Readonly, setReadonly] = useState("");
    const [loading, setLoading] = useState(false);


    const bluestyle = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "blue" : "blue",
      }),
    };

    const errorstyle= {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    };
    const location = useLocation();

    const [WPStype, setWPStype] = useState(location.state?.WPStype);
    const [action, setaction] = useState(Action.ActionEdit);
    const [IsEdited, setIsEdited] = useState(false);

    let isoverlay = WPStype === "Groove" ? false : true;
    let isgroove = WPStype === "Groove" ? true : false;


    const [isOverlay, setOverlay] = useState(isoverlay);
    const [isGroove, setGroove] = useState(isgroove);

    const fetchitem = async () => {
      const result = await getActiveItemForProject(headerObj.WPSH_Project_No);
      // const Item = [{ wld_itemcode: "" },{ wld_itemcode: "NA" }, ...result];
      const Item = [{ wld_itemcode: "" }, ...result];
      setItemActive(Item);


    };
    useEffect(() =>{
    
      let isDisabled = DisableFields(headerObj, Status, Action, action) ? "disabled" : ""

      setReadonly(
        isDisabled
      );
      
    },[])
    useEffect(() => {
     
      fetchitem();
    }, [headerObj.WPSH_Project_No]);

    useEffect(()=>{
       let isDisabled = DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      setReadonly(
       isDisabled
      );


    },[action])


    useEffect(() => {
     
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );


    }, [headerObj.WPSH_Status,headerObj.WPSH_Prepared_By]);

    useImperativeHandle(ref, () => ({
      funcOnNext2: async () => {
        return handleSubmit();

        // setError(true);
      },

      funcOnNextTemp2: async () => {
        return handleSubmitTemp();
      },
    }));




    const validateTemp = async () => {
      debugger
      let isError = false;

       let temperror = emptyError(error, setError, isError);

      return {isError ,temperror};
    };

    const handleSubmitTemp = async () => {

      let result = false;
      let val = await validateTemp();

     let detaildata = detailObj.filter((item) => item.WPSD_Process !== "");
   
          if (val.isError === false){
          if ( val.temperror === false) {
            setLoading(true)
              result =await handleAdd("",detaildata);
              setLoading(false)

              setIsEdited(false);
          setIsEditedPage2(false);

            if (result == true) {
              return MessageTypes.Temp;
            }
            else if(result == MessageTypes.SpecialCharacters )
              return MessageTypes.SpecialCharacters
            else{
              return MessageTypes.Fail
            }
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;
      
        
      
  }


    const handleSubmit = async () => {


      let detaildata = detailObj.filter((item) => item.WPSD_Process !== "");
   
      let result = false;

      if (!(await validate(detaildata)) ) {
        setLoading(true)
        result = await handleAdd("", detaildata);
        setLoading(false)

        if (result == true) {
          setKey(+tabno + 1);
          return MessageTypes.Success;
        }
        else if(result == MessageTypes.SpecialCharacters )
            return MessageTypes.SpecialCharacters
        else{
            return MessageTypes.Fail
          }
      } else {
        return MessageTypes.Required;
        // toast.warning("Required Fields not filled ")
      }
    };

   let wps_detail = detailObj.filter((item) => item.WPSD_Process !== "");

    if (wps_detail.length <= 0) {
      wps_detail = detailObj;
    }


    const [fillerMetalObj, setFillerMetalObj] = useState([]);
    const [batchnumber, setbatchnumber] = useState([]);
    const [ElecObj, setElcObj] = useState([]);
    const [positionoffilletObj, setPositionoffilletObj] = useState([]);
    const [minimumbeadlengthObj, setMinimumbeadlengthObj] = useState([]);
    const [degLayerObj, setdegLayerObj] = useState([]);
    const [positionGObj, setPositionGObj] = useState([]);
    const [consumableitem, setconsumableitem] = useState([]);
    const [weldPObj, setWeldPObj] = useState([]);
    const [currentPObj, setcurrentPObj] = useState([]);
    const [amperesObj, setAmperesObj] = useState([]);
    const [amperesObj1, setAmperesObj1] = useState([]);

    const [voltsObj, setVoltsObj] = useState([]);
    const [travelSObj, setTravelSObj] = useState([]);
    const [maxHeatObj, setMaxHeatObj] = useState([]);
    const [positionOvlObj, setPositionOvlObj] = useState([]);




    const initializeArray = (wps_detail, key) => {
      const initialArray = Array.from({ length: 3 }, () =>
        Array.from({ length: 3 }, () => "")
      );
      const mappedArray = initialArray.map((row, rowIndex) =>
        row.map(
          (_, colIndex) =>
          wps_detail[rowIndex * 3 + colIndex]?.[key] || ""
        )
      );

      const finalArray = mappedArray[0];

      return finalArray;
    };
      useEffect(() => {
        const fillerMetalArray = initializeArray(wps_detail, 'WPSD_WP_FillerMetalSIze');
        const batchNumberArray = initializeArray(wps_detail, 'WPSD_WP_BatchNumber');
        const PositionFArray = initializeArray(wps_detail, 'WPSD_WP_PositionOfFillet');
        const MinimumArray = initializeArray(wps_detail, 'WPSD_WP_MinimumBeadLength');
        const LayerArray = initializeArray(wps_detail, 'WPSD_WP_LayerNumber');
        const PositionGArray = initializeArray(wps_detail, 'WPSD_WP_PositionOfGroove');
        const ConsumableArray = initializeArray(wps_detail, 'WPSD_WP_WeldConsumable');
        const WeldPArray = initializeArray(wps_detail, 'WPSD_WP_WeldProgression');
        const CurrentArray = initializeArray(wps_detail, 'WPSD_WP_CurrentPolarity');
        const AmpereArray = initializeArray(wps_detail, 'WPSD_WP_Amperes');
        const AmpereArray1 = initializeArray(wps_detail, 'WPSD_WP_Amperes1');
        const VoltsArray = initializeArray(wps_detail, 'WPSD_WP_Volts');
        const TraveArray = initializeArray(wps_detail, 'WPSD_WP_TravelSpeed');
        const MaxheatArray = initializeArray(wps_detail, 'WPSD_WP_MaximumHeatInput');
        const PositionOvlArray = initializeArray(wps_detail, 'WPSD_WP_PositionOfOverlay');


        setFillerMetalObj(fillerMetalArray);
        setbatchnumber(batchNumberArray);
        setPositionoffilletObj(PositionFArray)
        setMinimumbeadlengthObj(MinimumArray)
        setdegLayerObj(LayerArray)
        setPositionGObj(PositionGArray)
        setconsumableitem(ConsumableArray)
        setWeldPObj(WeldPArray)
        setcurrentPObj(CurrentArray)
        setAmperesObj(AmpereArray)
        setAmperesObj1(AmpereArray1)
        setVoltsObj(VoltsArray)
        setTravelSObj(TraveArray)
        setMaxHeatObj(MaxheatArray)
        setPositionOvlObj(PositionOvlArray)
      }, [detailObj]);


    const [error, setError] = useState({
      WPSD_WP_MinimumPreheatTemp: {},
      WPSD_WP_MaximumInterpassTemp: {},
      WPSD_WP_TungstenSIze: {},
      WPSD_WP_TungstenType: {},
      WPSD_WP_FillerMetalProductForm: {},
      WPSD_WP_WeldConsumable: {},
      WPSD_WP_FillerMetalSIze: {},
      WPSD_WP_LayerNumber: {},
      WPSD_WP_BatchNumber: {},
      WPSD_WP_PositionOfGroove: {},
      WPSD_WP_PositionOfOverlay: {},
      WPSD_WP_PositionOfFillet: {},
      WPSD_WP_WeldProgression: {},
      WPSD_WP_CurrentPolarity: {},
      WPSD_WP_Amperes: {},
      WPSD_WP_Amperes1: {},
      WPSD_WP_Pulsing_current: {},
      WPSD_WP_Volts: {},
      WPSD_WP_TravelSpeed: {},
      WPSD_WP_MaximumHeatInput: {},
      WPSD_WP_ShieldingGasType: {},
      WPSD_WP_ShieldingFlowRate: {},
      WPSD_WP_BackingFlowRate: {},
      WPSD_WP_BackingGasType: {},
      WPSD_WP_TrailingFlowRate: {},
      WPSD_WP_TrailingGasType: {},
      WPSD_WP_ArcTransferMode: {},
      WPSD_WP_StringOrWeave: {},
      WPSD_WP_Orifice_GasCupSize: {},
      WPSD_WP_CTWD: {},
      WPSD_WP_Multiple_SinglePassPerSide: {},
      WPSD_WP_Multiple_SingleLayer: {},
      WPSD_WP_Multi_SingleElectrode: {},
      WPSD_WP_ElectrodeSpacing_Late: {},
      WPSD_WP_ElectrodeSpacing_Logit: {},
      // WPSD_WP_Elec_char_others: {},
      WPSD_WP_Max_Pass_Thickness: {},
      WPSD_WP_ElectrodeAngleDegree: {},
      WPSD_WP_Weld_Bead_Overlap: {},
      WPSD_WP_MinimumBeadLength: {},
      WPSD_WP_MinimumPreheatTemp_DataLogger: {},
      WPSD_WP_MaximumInterpassTemp_DataLogger: {},
      WPSD_WP_Maximumtravelspeed_DataLogger: {},
    });





  
    const handleInput = (e, validatorFn, fieldName) => {
      


      let isError = false;

      const inputError = validatorFn(e.target.value);

  
      setError((prevState) => ({
        ...prevState,
        [fieldName]: inputError,
      }));

      isError = inputError.status


      return isError
    };


const validate = async (detaildata) => {
  debugger


  let errortype = WPStype === "Groove" ? WPS_Groove_Detail : WPS_Overlay_Detail;
  let errortypeVal = WPStype === "Groove" ? wps_field_Val : wps_field_Val_Overlay;
  

  

  let entries = Object.entries(detaildata);
  let entries3 = Object.entries(WPSPage2);
  let localError = { ...error }; // Use a local variable to track errors
  let regExp = /^[A-Za-z0-9\s,:.@$()*!/_]+$/;
 
  let isError = false;

  errortype.forEach((cols) => {
    entries.forEach(([key, val]) => {
    

      let value = "";
      var msg = "";


      if (cols.Type === "S") {

        value = detailObj[key][cols.Column];
        msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;

        if (!value.match(regExp)) {
       
          isError = true;
          localError[cols.Column] = {
            status: true,
            message: msg,
          };
        }

      } else if (cols.Type === "A") {
    
        const values = [
          detailObj[key]?.[cols.Column]?.[0],
          detailObj[key]?.[cols.Column]?.[1],
          detailObj[key]?.[cols.Column]?.[2],
        ];

        // Check if at least one value is non-empty
        const hasValue = values.some(val => val !== undefined && val !== "" && val !== null);
        const msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;

        if (!hasValue) {
   
          isError = true;
          localError[cols.Column] = {
            status: true,
            message: msg,
          };
        } else {
          // Check each value for special characters
          let errorFound = values.some(val => val !== undefined && val !== "" && val !== null && !val.match(regExp));
          if (errorFound) {
            isError = true;
            localError[cols.Column] = {
              status: true,
              message: msg,
            };
          } else {
   
            localError[cols.Column] = {
              status: false,
              message: "",
            };
          }
        }
      }

    });
  });



  entries3.forEach(([key, val]) => {
    if (headerObj[key] === "") {
      isError = true;
      localError[key] = {
        status: true,
        message: "This field is required",
      };
    }
  });

  errortypeVal.forEach((cols) => {
    entries.forEach(([key, val]) => {
 
  
      if (cols.Type === "A") {
        
  
       
        const values = [
          detailObj[key]?.[cols.Column]?.[0],
          detailObj[key]?.[cols.Column]?.[1],
          detailObj[key]?.[cols.Column]?.[2],
        ];
  
        // Initialize error status for this column
        let columnHasError = false;
        const msg = `1) At least one field of each process is Mandatory.\n2) No Special Characters other than space, comma, +, -, colon, semicolon, dot, @, #, $, &, (), *, !, _, / are allowed.`;
  
        // Check if at least one value is non-empty
        const hasValue = values.some(val => val !== undefined && val !== "" && val !== null);
  
        if (!hasValue) {
        
          columnHasError = true;
        } else {
          // Check each value for special characters
          for (let i = 0; i < values.length; i++) {
            const val = values[i];
            if (val !== undefined && val !== "" && val !== null && !val.match(regExp)) {
       
              columnHasError = true;
              break; // Break out of the loop as soon as an error is found
            }
          }
        }
  
        // Update the error status for this column
        if (columnHasError) {
        
          isError = true;
          localError[cols.Column] = {
            status: true,
            message: msg,
          };
        } else {
      
          // Only update to false if there wasn't an error previously
          if (!localError[cols.Column]?.status) {
            localError[cols.Column] = {
              status: false,
              message: "",
            };
          }
        }
      }
    });
  });
  
  // Update the state only once at the end
  setError(localError);
  
  // Check if any error status is true in the localError object
  const hasError = Object.values(localError).some((err) => err.status === true);
  if (!hasError) {
    isError = false;
  }
  

  return isError;
};

   
    const handleChangeFillermetal = (row, col, key, obj, setfunction, e, metal) => {

    
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][key] = temp[row];
      setdetailObj(onChangeVal);

    
    };

    const handleChange = (row, col, key, obj, setfunction, e, metal) => {
     
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);



      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][key] = temp[row];
      setdetailObj(onChangeVal);
    
    };


    const handleChangeempty = (row, col, key, obj, setfunction, e, metal) => {
      
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);


      let temp = [...obj];

      temp[row][col] = ""
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][key] = temp[row];
      setdetailObj(onChangeVal);
    
    };

  


    const handleChangeselect = (row, col, key, obj, setfunction, e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][key] = temp[row];
      setdetailObj(onChangeVal);
      setError((prevState) => ({
        ...prevState,
        [key]: { status: false, message: "" },
      }));
    };

    const handleChangeHeader = (e) => {
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangedetailsarray = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);

      setIsEditedPage2(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
    };

    const handleChangedetailsarrayselect = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage2(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };


    const handleCount = () => {
      let result;

      headerObj.WPS_Change_No = location.state?.WPS_Change_No;
      result = AddNewCount(headerObj);
    };

    const handleAdd = async (savetype, detaildata) => {
      debugger
      let result = true;
      let approvaltype = headerObj.WPSH_Status;


      if (
        headerObj.WPSH_Status === Status.StatusApproved ||
        headerObj.WPSH_Status === Status.StatusRejected
      ) {
        if (headerObj.WPSH_Status === Status.StatusApproved) {

          handleCount();
        }
        headerObj.WPSH_Status = Status.StatusPending;
      }
      debugger
      try {
        
      await AddNewGroove(headerObj).then(async (r) => {

       
        
        if(r!= undefined && r.data != undefined && r.data.message == "Validate"){
     //     alert("Special Characters # & % + - $' < > = ; not allowed")
          result=MessageTypes.SpecialCharacters
         
        }
      });
      }
      catch (error) {

        console.error("Error adding header:", error);
        result=false
       
      }
      
  
      ///changes to see if process has been edited
      let detailres = []
      for (const item of detaildata) {

        try {
          debugger
        await AddNewDetail(item).then(async (r) => {
     
          if(r!= undefined && r.data != undefined && r.data.message == "Validate"){
          
           detailres.push(MessageTypes.SpecialCharacters)
            
          }
       
        });
      }
    catch (error) {
      detailres.push(false)
      console.error("Error adding header:", error);
    }
  }
  
  if(detailres.filter((i) => i == false).length > 0)
    result = false

  if(detailres.filter((i) => i == MessageTypes.SpecialCharacters).length > 0)
    result = MessageTypes.SpecialCharacters
     
   
      return result;
    };

    
    return (
      <Row className="font">
           {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <WPSHeader headerObj={headerObj}></WPSHeader>
        <Row className="mb-4" style={{ padding: "20px 30px" }}>
          <span className="WPS-blue-text">Welding Procedure</span>
          <div className="scrollwps">
          <Col>
            <Table
              bordered
              responsive="sm"
              className="text-center same-col-widths align-middle"
            >
              {isoverlay ? (
                <thead>
                  <tr className="align-middle">
                    <th className="text-start thead-color">
                      Weld Layer
                    
                    </th>

                    {wps_detail.map((item, id) => {
                      return (
                        <th
                          key={id}
                          className={
                            item.WPSD_Process === "" && item.WPSD_Type === ""
                              ? true
                              : false
                          }
                        >
                          {item.WPSH_WeldLayer}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              ) : (
                ""
              )}
              <thead>
                <tr className="align-middle">
                  <th className="text-start thead-color" style={{width:"310px"}}>
                    Process - Type
                
                  </th>

                  {wps_detail.map((item, id) => {
                    return (
                      <th
                        key={id}
                        className={
                          item.WPSD_Process === "" && item.WPSD_Type === ""
                            ? true
                            : false
                        }
                      >
                        {item.WPSD_Process}-{item.WPSD_Type}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="Minimum Preheat"
                  Columndimension="°C"
                  status={error.WPSD_WP_MinimumPreheatTemp.status}
                  message={error.WPSD_WP_MinimumPreheatTemp.message}
                  type="text"
                  maxLength={24}
                  name="WPSD_WP_MinimumPreheatTemp"
                

                  onInput={(e)=>handleInput(e,isAlphanumericAllSpecialCharacters,"WPSD_WP_MinimumPreheatTemp")}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader=" Maximum Interpass Temp"
                  Columndimension="°C"
                  maxLength={24}
                  status={error.WPSD_WP_MaximumInterpassTemp.status}
                  message={error.WPSD_WP_MaximumInterpassTemp.message}
                  type="text"
                  name="WPSD_WP_MaximumInterpassTemp"
                 
                  onInput={(e)=>handleInput(e,isAlphanumericAllSpecialCharacters,"WPSD_WP_MaximumInterpassTemp")}

                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Select
                  wps_detail={wps_detail}
                  ColumnHeader="Filler Metal Product Form"
                  status={error.WPSD_WP_FillerMetalProductForm.status}
                  message={error.WPSD_WP_FillerMetalProductForm.message}
                  name="WPSD_WP_FillerMetalProductForm"
                  array={FM_Product_Form}
                  handleChangedetailsarray={handleChangedetailsarrayselect}
                ></WPS_Process_Grid_Select>

                <WPS_Process_Grid_Input_3Filermetal
                  wps_detail={wps_detail}
                  ColumnHeader="Filler Metal Size Ø"
                  Columndimension="mm"
                  status={error.WPSD_WP_FillerMetalSIze.status}
                  message={error.WPSD_WP_FillerMetalSIze.message}
                  obj={fillerMetalObj}
                  objbatch={batchnumber}
                  maxLength={5}
                  setfunction={setFillerMetalObj}
                  setfunctionb={setbatchnumber}
                  type="text"
                  name="WPSD_WP_FillerMetalSIze"
                  metal="WPSD_WP_FillerMetalSIze"
              
                  handleChangeFillermetal={handleChangeFillermetal}
                  
                  
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_FillerMetalSIze: error,
                    }));
                  }}
                
                ></WPS_Process_Grid_Input_3Filermetal>

                <WPS_Process_Grid_Select_master_data
                  data={ItemActive}
                  bluestyle={bluestyle}
                  errorstyle={errorstyle}
                  valueField="wld_itemcode"
                  labelField="wld_itemcode"
                  name="WPSD_WP_WeldConsumable"
                  name1="WPSD_WP_WeldDesc"
                  setdetailObj={setdetailObj}
                  detailObj={detailObj}
                  setError={setError}
                  error={error}
                  onInput={(e) => {
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_WeldConsumable: { status: false, message: "" },
                    }));
                  }}
                  wps_detail={wps_detail}
                  ColumnHeader="Welding Consumable Item Code"
                  Columndimension="mm"
                  status={error.WPSD_WP_WeldConsumable.status}
                  message={error.WPSD_WP_WeldConsumable.message}
                  handleChangedetailsarray={handleChangedetailsarray}
                  fieldfiller="WPSD_WP_FillerMetalSIze"

                ></WPS_Process_Grid_Select_master_data>

            

                    <WPS_Process_Grid_Input_3
                  wps_detail={wps_detail}
                  ColumnHeader="Batch No"
                  status={error.WPSD_WP_BatchNumber.status}
                  message={error.WPSD_WP_BatchNumber.message}
                  obj={batchnumber}                  
                  maxLength={9}
                  setfunction={setbatchnumber}
                  type="text"
                  name="WPSD_WP_BatchNumber"
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  handleChange={handleChange}
                  handleChangeempty={handleChangeempty}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_BatchNumber: error,
                    }));
                  }}
                
                ></WPS_Process_Grid_Input_3>

                {isoverlay ? (
                  ""
                ) : (
                  <WPS_Process_Grid_Select_3
                    wps_detail={wps_detail}
                    ColumnHeader="Layer No."
                    status={error.WPSD_WP_LayerNumber.status}
                    message={error.WPSD_WP_LayerNumber.message}
                    obj={degLayerObj}
                    array={Layer_No}
                    setfunction={setdegLayerObj}
                    type="text"
                    name="WPSD_WP_LayerNumber"
                    handleChange={handleChangeselect}
                    fieldfiller="WPSD_WP_FillerMetalSIze"
                  ></WPS_Process_Grid_Select_3>
                )}

                {isoverlay ? (
                  <WPS_Process_Grid_Select_3
                    wps_detail={wps_detail}
                    ColumnHeader="Position Of Overlay"
                    Columndimension=""
                    status={error.WPSD_WP_PositionOfOverlay.status}
                    message={error.WPSD_WP_PositionOfOverlay.message}
                    obj={positionOvlObj}
                    setfunction={setPositionOvlObj}
                    array={Position_of_groove}
                    type="text"
                    name="WPSD_WP_PositionOfOverlay"
                    handleChange={handleChangeselect}
                    fieldfiller="WPSD_WP_FillerMetalSIze"
                  ></WPS_Process_Grid_Select_3>
                ) : (
                  <WPS_Process_Grid_Select_3
                    wps_detail={wps_detail}
                    ColumnHeader="Position Of Groove"
                    status={error.WPSD_WP_PositionOfGroove.status}
                    message={error.WPSD_WP_PositionOfGroove.message}
                    obj={positionGObj}
                    setfunction={setPositionGObj}
                    array={Position_of_groove}
                    type="text"
                    name="WPSD_WP_PositionOfGroove"
                    handleChange={handleChangeselect}
                    fieldfiller="WPSD_WP_FillerMetalSIze"
                  ></WPS_Process_Grid_Select_3>
                )}
                {isoverlay ? (
                  ""
                ) : (
                 
                  <WPS_Process_Grid_Input_3
                  wps_detail={wps_detail}
                  ColumnHeader="Position Of Fillet"
                  Columndimension=""
                  status={error.WPSD_WP_PositionOfFillet.status}
                  message={error.WPSD_WP_PositionOfFillet.message}
                  obj={positionoffilletObj}
                  maxLength={5}
                  setfunction={setPositionoffilletObj}
                  type="text"
                  name="WPSD_WP_PositionOfFillet"
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  handleChange={handleChange}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_PositionOfFillet: error,
                    }));
                  }}
                  

                ></WPS_Process_Grid_Input_3>
                )}

                <WPS_Process_Grid_Select_3
                  wps_detail={wps_detail}

                  ColumnHeader={
                    <span className="greenfont" style={{ fontWeight: "700" }}>
                      Weld Progression
                    </span>
                  }
  
                  status={error.WPSD_WP_WeldProgression.status}
                  message={error.WPSD_WP_WeldProgression.message}
                  obj={weldPObj}
                  setfunction={setWeldPObj}
                  array={Weld_Progression}
                  type="text"
                  name="WPSD_WP_WeldProgression"
                  handleChange={handleChangeselect}
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                ></WPS_Process_Grid_Select_3>

                <WPS_Process_Grid_Select_3
                  wps_detail={wps_detail}

                  ColumnHeader={
                    <span className="greenfont" style={{ fontWeight: "700" }}>
                      Current Polarity
                    </span>
                  }

                  status={error.WPSD_WP_CurrentPolarity.status}
                  message={error.WPSD_WP_CurrentPolarity.message}
                  obj={currentPObj}
                  setfunction={setcurrentPObj}
                  type="text"
                  array={Current_Polarity}
                  name="WPSD_WP_CurrentPolarity"
                  handleChange={handleChangeselect}
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                ></WPS_Process_Grid_Select_3>

{isoverlay ? (
                    <WPS_Process_Grid_Input_2_Amperes
                    wps_detail={wps_detail}
                    ColumnHeader="Amperes"
                    Columndimension="Amps"
                    maxLength={5}
                    status={error.WPSD_WP_Amperes.status}
                    message={error.WPSD_WP_Amperes.message}
                    status1={error.WPSD_WP_Amperes1.status}
                    message1={error.WPSD_WP_Amperes1.message}
                    obj={amperesObj}
                    obj1={amperesObj1}

                    setfunction={setAmperesObj}
                    setfunction1={setAmperesObj1}

                    type="text"
                    name="WPSD_WP_Amperes"
                    name1="WPSD_WP_Amperes1"
                    handleChange={handleChange}
                    fieldfiller="WPSD_WP_FillerMetalSIze"
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Amperes: error,
                        WPSD_WP_Amperes1: error,

                      }));
                    }}
                  ></WPS_Process_Grid_Input_2_Amperes>
                ) : (
                <WPS_Process_Grid_Input_3
                  wps_detail={wps_detail}
                  ColumnHeader="Amperes"
                  Columndimension="Amps"
                  maxLength={7}
                  status={error.WPSD_WP_Amperes.status}
                  message={error.WPSD_WP_Amperes.message}
                  obj={amperesObj}
                  setfunction={setAmperesObj}
                  type="text"
                  name="WPSD_WP_Amperes"
                  handleChange={handleChange}
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_Amperes: error,
                    }));
                  }}
                ></WPS_Process_Grid_Input_3>
                )}

                
                {isoverlay ? (
                  ""
                ) : (
                  <WPS_Process_Grid_Input
                    wps_detail={wps_detail}
                    ColumnHeader="Pulsing Current"
                    maxLength={24}
                    Columndimension = "Amps"
                    status={error.WPSD_WP_Pulsing_current.status}
                    message={error.WPSD_WP_Pulsing_current.message}
                    type="text"
                    name="WPSD_WP_Pulsing_current"
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Pulsing_current: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input>
                )}

                <WPS_Process_Grid_Input_3 
                  wps_detail={wps_detail}
                  ColumnHeader="Voltage"
                  Columndimension="Volts"
                  maxLength={6}
                  status={error.WPSD_WP_Volts.status}
                  message={error.WPSD_WP_Volts.message}
                  obj={voltsObj}
                  setfunction={setVoltsObj}
                  type="text"
                  name="WPSD_WP_Volts"
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  handleChange={handleChange}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_Volts: error,
                    }));
                  }}
                ></WPS_Process_Grid_Input_3>

                <WPS_Process_Grid_Input_3
                  wps_detail={wps_detail}
                  ColumnHeader="Travel Speed"
                  Columndimension="mm/min"
                  status={error.WPSD_WP_TravelSpeed.status}
                  message={error.WPSD_WP_TravelSpeed.message}
                  obj={travelSObj}
                  maxLength={7}
                  setfunction={setTravelSObj}
                  type="text"
                  name="WPSD_WP_TravelSpeed"
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  handleChange={handleChange}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_TravelSpeed: error,
                    }));
                  }}
                ></WPS_Process_Grid_Input_3>


<WPS_Process_Grid_Input_3
                  wps_detail={wps_detail}
                  ColumnHeader="Minimum bead Length (SMAW)"
                  Columndimension="mm"
                  status={error.WPSD_WP_MinimumBeadLength.status}
                  message={error.WPSD_WP_MinimumBeadLength.message}
                  obj={minimumbeadlengthObj}
                  maxLength={5}
                  setfunction={setMinimumbeadlengthObj}
                  type="text"
                  name="WPSD_WP_MinimumBeadLength"
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  handleChange={handleChange}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_MinimumBeadLength: error,
                    }));
                  }}
                ></WPS_Process_Grid_Input_3>

                <WPS_Process_Grid_Input_3
                  wps_detail={wps_detail}
                  ColumnHeader="Maximum Heat Input"
                  maxLength={5}
                  Columndimension="KJ/mm or KJ/mm2"
                  status={error.WPSD_WP_MaximumHeatInput.status}
                  message={error.WPSD_WP_MaximumHeatInput.message}
                  obj={maxHeatObj}
                  setfunction={setMaxHeatObj}
                  type="text"
                  name="WPSD_WP_MaximumHeatInput"
                  fieldfiller="WPSD_WP_FillerMetalSIze"
                  handleChange={handleChange}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_MaximumHeatInput: error,
                    }));
                  }}
                ></WPS_Process_Grid_Input_3>

                <WPS_Process_Grid_Select
                  wps_detail={wps_detail}
                  ColumnHeader="Tungsten Size"
                  Columndimension="mm"
                  status={error.WPSD_WP_TungstenSIze.status}
                  message={error.WPSD_WP_TungstenSIze.message}
                  name="WPSD_WP_TungstenSIze"
                  handleChangedetailsarray={handleChangedetailsarrayselect}
                  option
                  array={Tungsten_size}
                ></WPS_Process_Grid_Select>

                <WPS_Process_Grid_Select
                  wps_detail={wps_detail}
                  ColumnHeader="Tungsten Type"
                  Columndimension=""
                  status={error.WPSD_WP_TungstenType.status}
                  message={error.WPSD_WP_TungstenType.message}
                  name="WPSD_WP_TungstenType"
                  handleChangedetailsarray={handleChangedetailsarrayselect}
                  option
                  array={Tungsten_type}
                ></WPS_Process_Grid_Select>


                <WPS_Process_Grid_Input_2
                  wps_detail={wps_detail}
                  mainheading="Shielding"
                  maxLength={18}
                  ColumnHeader="Gas Type - % comp"
                  ColumnHeader1="Flow Rate"
                  Columndimension="I/min"
                  status={
                    error.WPSD_WP_ShieldingGasType.status ||
                    error.WPSD_WP_ShieldingFlowRate.status
                  }
                  message={
                    error.WPSD_WP_ShieldingGasType.message ||
                    error.WPSD_WP_ShieldingFlowRate.message
                  }
                  type="text"
                  name="WPSD_WP_ShieldingGasType"
                  name1="WPSD_WP_ShieldingFlowRate"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_ShieldingGasType: error,
                    }));
                  }}
                  onInput1={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_ShieldingFlowRate: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input_2>
                {isoverlay ? (
                  ""
                ) : (
                  <WPS_Process_Grid_Input_2
                    wps_detail={wps_detail}
                    mainheading="Backing"
                    ColumnHeader="Gas Type - % comp"
                    ColumnHeader1="Flow Rate"
                    Columndimension="I/min"
                    maxLength={18}
                    status={
                      error.WPSD_WP_BackingGasType.status ||
                      error.WPSD_WP_BackingFlowRate.status
                    }
                    message={
                      error.WPSD_WP_BackingGasType.message ||
                      error.WPSD_WP_BackingFlowRate.message
                    }
                    type="text"
                    name="WPSD_WP_BackingGasType"
                    name1="WPSD_WP_BackingFlowRate"
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_BackingGasType: error,
                      }));
                    }}
                    onInput1={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_BackingFlowRate: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input_2>
                )}

                <WPS_Process_Grid_Input_2
                  wps_detail={wps_detail}
                  mainheading="Trailing"
                  ColumnHeader="Gas Type - % comp"
                  ColumnHeader1="Flow Rate"
                  Columndimension="I/min"
                  maxLength={18}
                  status={
                    error.WPSD_WP_TrailingGasType.status ||
                    error.WPSD_WP_TrailingFlowRate.status
                  }
                  message={
                    error.WPSD_WP_TrailingGasType.message ||
                    error.WPSD_WP_TrailingFlowRate.message
                  }
                  type="text"
                  name="WPSD_WP_TrailingGasType"
                  name1="WPSD_WP_TrailingFlowRate"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_TrailingGasType: error,
                    }));
                  }}
                  onInput1={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_TrailingFlowRate: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input_2>

                <WPS_Process_Grid_Select
                  wps_detail={wps_detail}
                  ColumnHeader={
                    <span className="greenfont" style={{ fontWeight: "700" }}>
                      Arc Transfer Mode(FCAW/GMAW)
                    </span>
                  }
                  Columndimension="mm"
                  status={error.WPSD_WP_ArcTransferMode.status}
                  message={error.WPSD_WP_ArcTransferMode.message}
                  name="WPSD_WP_ArcTransferMode"
                  handleChangedetailsarray={handleChangedetailsarrayselect}
                  option
                  array={Arc_Transfer}
                ></WPS_Process_Grid_Select>
                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="String or Weave"
                  // Columndimension = "mm/min"
                  status={error.WPSD_WP_StringOrWeave.status}
                  message={error.WPSD_WP_StringOrWeave.message}
                  type="text"
                  maxLength={24}
                  name="WPSD_WP_StringOrWeave"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_StringOrWeave: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="Orifice / Gas Cup Size"
                  Columndimension="mm"
                  status={error.WPSD_WP_Orifice_GasCupSize.status}
                  message={error.WPSD_WP_Orifice_GasCupSize.message}
                  type="text"
                  maxLength={24}
                  name="WPSD_WP_Orifice_GasCupSize"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_Orifice_GasCupSize: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="CTWD"
                  maxLength={24}
                  Columndimension="mm"
                  status={error.WPSD_WP_CTWD.status}
                  message={error.WPSD_WP_CTWD.message}
                  type="text"
                  name="WPSD_WP_CTWD"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_CTWD: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Select
                  wps_detail={wps_detail}
                  ColumnHeader={
                    <span className="greenfont" style={{ fontWeight: "700" }}>
                      Multiple / Single Pass Per Side
                    </span>
                  }
                  Columndimension="mm"
                  status={error.WPSD_WP_Multiple_SinglePassPerSide.status}
                  message={error.WPSD_WP_Multiple_SinglePassPerSide.message}
                  name="WPSD_WP_Multiple_SinglePassPerSide"
                  handleChangedetailsarray={handleChangedetailsarrayselect}
                  option
                  array={Single_Pass}
                ></WPS_Process_Grid_Select>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="Multi or Single Layer"
                  // Columndimension = "mm"
                  status={error.WPSD_WP_Multiple_SingleLayer.status}
                  message={error.WPSD_WP_Multiple_SingleLayer.message}
                  type="text"
                  name="WPSD_WP_Multiple_SingleLayer"
                  maxLength={24}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_Multiple_SingleLayer: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="Multi / Single Electrode"
                  maxLength={24}
                  // Columndimension = "mm"
                  status={error.WPSD_WP_Multi_SingleElectrode.status}
                  message={error.WPSD_WP_Multi_SingleElectrode.message}
                  type="text"
                  name="WPSD_WP_Multi_SingleElectrode"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_Multi_SingleElectrode: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="Electrode Spacing Lateral"
                  maxLength={24}
                  Columndimension="mm"
                  status={error.WPSD_WP_ElectrodeSpacing_Late.status}
                  message={error.WPSD_WP_ElectrodeSpacing_Late.message}
                  type="text"
                  name="WPSD_WP_ElectrodeSpacing_Late"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_ElectrodeSpacing_Late: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  wps_detail={wps_detail}
                  ColumnHeader="Electrode Spacing Longitudinal"
                  Columndimension="mm"
                  maxLength={24}
                  status={error.WPSD_WP_ElectrodeSpacing_Logit.status}
                  message={error.WPSD_WP_ElectrodeSpacing_Logit.message}
                  type="text"
                  name="WPSD_WP_ElectrodeSpacing_Logit"
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPSD_WP_ElectrodeSpacing_Logit: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>


<WPS_Process_Grid_Input
                    wps_detail={wps_detail}
                    ColumnHeader="Electrode Angle"
                    Columndimension="deg."
                    maxLength={24}
                    status={error.WPSD_WP_ElectrodeAngleDegree.status}
                    message={error.WPSD_WP_ElectrodeAngleDegree.message}
                    // obj={ElecObj}
                    // setfunction={setElcObj}
                    type="text"
                    name="WPSD_WP_ElectrodeAngleDegree"
                    handleChangedetailsarray={handleChangedetailsarray}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_ElectrodeAngleDegree: error,
                      }));
                    }}
                  ></WPS_Process_Grid_Input>

                {isoverlay ? (
                  <WPS_Process_Grid_Input
                    wps_detail={wps_detail}
                    ColumnHeader="Weld Bead Overlap"
                    maxLength={24}
                    Columndimension=""
                    status={error.WPSD_WP_Weld_Bead_Overlap.status}
                    message={error.WPSD_WP_Weld_Bead_Overlap.message}
                    type="text"
                    name="WPSD_WP_Weld_Bead_Overlap"
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Weld_Bead_Overlap: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input>
                ) : (
                  <WPS_Process_Grid_Input
                    wps_detail={wps_detail}
                    ColumnHeader="Maximum Pass Thickness"
                    Columndimension="mm"
                    maxLength={24}
                    status={error.WPSD_WP_Max_Pass_Thickness.status}
                    message={error.WPSD_WP_Max_Pass_Thickness.message}
                    type="text"
                    name="WPSD_WP_Max_Pass_Thickness"
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_WP_Max_Pass_Thickness: error,
                      }));
                    }}
                    handleChangedetailsarray={handleChangedetailsarray}
                  ></WPS_Process_Grid_Input>
                )}
              </tbody>
            </Table>
          </Col>
          </div>

        </Row>
        <Row
          className="mb-4"
          style={{ padding: "0px 30px", position: "relative", bottom: "28px" }}
        >
          <Col md={3}>
            <div class="T-input w-100">
              <input
                type="text"
                id="WPSD_WP_MinimumPreheatTemp_DataLogger"
                name="WPSD_WP_MinimumPreheatTemp_DataLogger"

                maxLength={50}
                placeholder=" "
                value={headerObj.WPSD_WP_MinimumPreheatTemp_DataLogger}
                onChange={handleChangeHeader}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPSD_WP_MinimumPreheatTemp_DataLogger: error,
                  }));
                }}
              />
              <label for="WPSD_WP_MinimumPreheatTemp_DataLogger">
                DataLogger - Preheat Temp
              </label>
            </div>
            <div className="required-text">
              {error.WPSD_WP_MinimumPreheatTemp_DataLogger.status && (
                <Form.Text className="text-danger">
                  {error.WPSD_WP_MinimumPreheatTemp_DataLogger.message}
                </Form.Text>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div class="T-input w-100">
              <input
                type="text"
                id="WPSD_WP_MaximumInterpassTemp_DataLogger"
                name="WPSD_WP_MaximumInterpassTemp_DataLogger"
           
                maxLength={50}
                placeholder=" "
                value={headerObj.WPSD_WP_MaximumInterpassTemp_DataLogger}
                onChange={handleChangeHeader}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPSD_WP_MaximumInterpassTemp_DataLogger: error,
                  }));
                }}
              />
              <label for="WPSD_WP_MaximumInterpassTemp_DataLogger">
                DataLogger - Interpass Temp
              </label>
            </div>
            <div className="required-text">
              {error.WPSD_WP_MaximumInterpassTemp_DataLogger.status && (
                <Form.Text className="text-danger">
                  {error.WPSD_WP_MaximumInterpassTemp_DataLogger.message}
                </Form.Text>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div class="T-input w-100">
              <input
                type="text"
                id="WPSD_WP_Maximumtravelspeed_DataLogger"
                name="WPSD_WP_Maximumtravelspeed_DataLogger"
              
                maxLength={50}
                placeholder=" "
                value={headerObj.WPSD_WP_Maximumtravelspeed_DataLogger}
                onChange={handleChangeHeader}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPSD_WP_Maximumtravelspeed_DataLogger: error,
                  }));
                }}
              />
              <label for="WPSD_WP_Maximumtravelspeed_DataLogger">
                DataLogger - Travel Speed
              </label>
            </div>
            <div className="required-text">
              {error.WPSD_WP_Maximumtravelspeed_DataLogger.status && (
                <Form.Text className="text-danger">
                  {error.WPSD_WP_Maximumtravelspeed_DataLogger.message}
                </Form.Text>
              )}
            </div>
          </Col>
        </Row>
      </Row>
    );
  }
);

export default WeldingProcedure;
