import React, {
    useState,
    forwardRef,
    useEffect,
  } from "react";
 
  import { Row, Col, Container, Form, Button } from "react-bootstrap";

  import {ToastContainer } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
 
import { getwiwelders } from "../../Services/WI-services";
import { todayDate } from "../utility/CommonComponents/TodayDate";

import { SearchSelectWelder } from "../utility/search-select";
import { getAllOnlyProject } from "../../Services/project-services";

import { Date_Input, Date_InputDisabled } from "./Excel_Headers";
import WCR_PDF from "./WCR_PDF";
import { getAllRecordsWeldingProcess } from "../../Services/weldingprocess-services";
import { normalstyle } from "../../functions/welding-functions";
  
  const Welder_Continuity_Record = forwardRef((props, ref) => {


    const [widata, setwidata] = useState(null);

    const [reload, setReload] = useState(false);

    const [ProjectDetails, setProjectDetails] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const [pcategories, setPcategories] = useState(null);
    const [filteredPcategories, setFilteredPcategories] = useState(null);










    const [datevalue, setdatevalue] = useState(false);

    const [wiobj, setwiobj] = useState({
      WI_Project_No:"",
      WI_Joint_No:"",
      WI_Welder_Id:"",
      WI_Welder_Name:"",
      WI_Process:"",
      WI_ProcessCategory:"",
      WI_From_Date:"",
      WI_To_Date:"",
      WI_Slip_Status:"",
      WPS_Rev_no:"",
      WI_FileFormat:"",
      WI_From_Date:"",
      WI_To_Date:"",
      WI_Last_Weld: false,
      WI_SlipDate:"",
      
      
    });



    const [error, setError] = useState({
      WI_Project_No:{},
      WI_Joint_No:{},
      WI_Welder_Id:{},
      WI_From_Date:{},
      WI_To_Date:{},
      WI_Slip_Status:{},

    });

    


  

    const fetchProject = async () => {  
      let result = await getAllOnlyProject();
      setProjectDetails(result);
    };

    

    const fetchwidata = async () => {
      
      const result = await getwiwelders();
     
    setwidata(result);
    };


  


    useEffect(() => {
      fetchProject()    
    }, []);
  

    useEffect(() => {
      fetchwidata();
    }, [reload]);

    useEffect(() => {
      if (reload) {
        setReload(false);
      }
    }, [reload]);


    





    const activewelder = widata?.filter(item => item.WI_Slip_Status === "Created");


    
    
    const filteredData = activewelder?.filter((entry, index, array) => {
      return (
        array.findIndex(
          (e) =>
            e.WI_Welder_Id === entry.WI_Welder_Id &&
            e.WI_Welder_Name === entry.WI_Welder_Name
        ) === index
      );
    });
    


    const removeDuplicates = (keyFn, array) => {
      var mySet = new Set();
      return array.filter(function(x) {
          var key = keyFn(x), isNew = !mySet.has(key);
          if (isNew) mySet.add(key);
          return isNew;
      });
    }

    const fetchWeldingProcess = async () => {
      const result = await getAllRecordsWeldingProcess();
      setFilteredPcategories(removeDuplicates(x => x.wld_p_category, result))
      setPcategories(result);
    };

    useEffect(() => {
      fetchWeldingProcess();
    }, []);
    
    
    




      const handleSubmit = async (e) => {
        

        setShowForm(true);
        

  };


  



  
  const handleCheckboxClick = () => {
    setwiobj(prevState => ({
      ...prevState,
      WI_Last_Weld: !prevState.WI_Last_Weld,
      WI_From_Date: "",

    }));

setdatevalue(false)

    setShowForm(false);
  };


 

      
    return (
      <>
      <Container fluid>
        <Row className="RowHeight mb-4 ml-2">
          <Col md={12} sm={12} xs={12} className="ListTitle">
          Welder Continuity Record Filters
          </Col>
        </Row>
        <div className="px-2 pb-2">
        <Row className="gx-3" style={{ marginBottom: "2rem" }}>
        
                <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWelder
                  id="WI_Welder_Id"
                  name="WI_Welder_Id"
                  className={""}
                  stylegreen={normalstyle}
                  data={filteredData}
                  
                  valueField={"WI_Welder_Id"}
                  value={wiobj?.WI_Welder_Id}
                  labelField1={"WI_Welder_Id"}
                  labelField2={"WI_Welder_Name"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    let a = filteredData.filter((b) => {
                      return b.WI_Welder_Id === ProjectList.value;
                    });

                    setwiobj((prev) => ({
                      ...prev,
                      WI_Welder_Id: ProjectList.value,
                      WI_Welder_Name: a[0].WI_Welder_Name,
                      WI_Process:""
                    }));
                    setShowForm(false);


                  }}
                 
                ></SearchSelectWelder>
              </div>
              <label>Welder Code</label>
             
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWelder
                  // className="inspdrop"
                  id="WI_ProcessCategory"
                  name="WI_ProcessCategory"
                  className={""}
                  stylegreen={normalstyle}
                  data={filteredPcategories}
                  
                  valueField={"wld_p_category"}
                  value={wiobj?.WI_ProcessCategory}
                  labelField={"wld_p_category"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    let a = pcategories.filter((b) => {
                      return b.wld_p_category === ProjectList.value;
                    });
             
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Process: "",
                      WI_ProcessCategory: ProjectList.value 
                    }));
                    setShowForm(false);

                  }}
                 
                ></SearchSelectWelder>
              </div>
              <label>Process</label>
             
            </Form.Group>
          </Col>

{wiobj.WI_Last_Weld === false ?
            <Col md={3}>
            <Date_Input 
            divClass="Date-input w-100"
            label="Slip Creation Date From"
            className="groove_datecolor"
            name="WI_From_Date"
            sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy,sans-serif", zIndex:"0" }}
            todayDate={todayDate}
            obj={wiobj}
            setobj={setwiobj}
            datevalue={datevalue}
            setdatevalue={setdatevalue}
            todate={wiobj.WI_To_Date}
            setError={setError}
            status={error.WI_From_Date.status}
            message={error.WI_From_Date.message}
            form ={setShowForm}

            />
            </Col>

            : 
            <Col md={3}>
            <Date_InputDisabled 
            divClass="Date-input w-100"
            label="Slip Creation Date From"
            className="groove_datecolor"
            name="WI_From_Date"
            sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy,sans-serif", zIndex:"0" }}
            todayDate={todayDate}
            obj={wiobj}
            setobj={setwiobj}
            datevalue={datevalue}
            setdatevalue={setdatevalue}
            todate={wiobj.WI_To_Date}
            setError={setError}
            status={error.WI_From_Date.status}
            message={error.WI_From_Date.message}
            form ={setShowForm}

            />
            </Col>}


            {wiobj.WI_Last_Weld === false ?


            <Col md={3}>
            <Date_Input 
            divClass="Date-input w-100"
            label="Slip Creation Date To"
            className="groove_datecolor"
            name="WI_To_Date"
            sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy,sans-serif", zIndex:"0", width: "300px",}}
            todayDate={todayDate}
            obj={wiobj}
            WI_Last_Weld={wiobj.WI_Last_Weld}
            fromdate={wiobj.WI_From_Date}
            setobj={setwiobj}
            setdatevalue={setdatevalue}
            datevalue={datevalue}
            setError={setError}
            status={error.WI_To_Date.status}
            message={error.WI_To_Date.message}
            form ={setShowForm}
            />
            </Col>

: 
<Col md={3}>
<Date_InputDisabled 
 divClass="Date-input w-100"
 label="Slip Creation Date To"
 className="groove_datecolor"
 name="WI_To_Date"
 sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy,sans-serif", zIndex:"0", width: "300px",}}
 todayDate={todayDate}
 obj={wiobj}
 WI_Last_Weld={wiobj.WI_Last_Weld}
 fromdate={wiobj.WI_From_Date}
 setobj={setwiobj}
 setdatevalue={setdatevalue}
 datevalue={datevalue}
 setError={setError}
 status={error.WI_To_Date.status}
 message={error.WI_To_Date.message}
 form ={setShowForm}

/>
</Col>}

            <Col md={3}>

            <div>
            <span>
             Last Weld Date
           </span>
           &nbsp;&nbsp;
           <input
      type="checkbox"
      name="WI_Last_Weld"
      className="checkboxproject form-check-input"
      onClick={()=>{handleCheckboxClick()
        setwiobj(prevState => ({
          ...prevState,
          WI_From_Date: "",
        }));
      }}
      style={{ position: "relative", top: "2px" }}
      checked={wiobj.WI_Last_Weld}
    />          
      </div>
        </Col>

          </Row>
              </div>
              <Row>
            <Col>
              <Button onClick={handleSubmit} className="btn-add-color semiBoldToggleButton rightAlignedButton" >
                Download
              </Button>
            </Col>
          </Row>

        
<div style={{position:"relative", top:"10000px"}}>
{showForm && (
        <WCR_PDF
          show={showForm}
          widata={widata}
          
          rowdata={wiobj}
          onHide={() => setShowForm(false)}
         
          setReload={setReload}
          reload={reload}
          
          backdrop="static"
        />
      )}
      </div>
       </Container>

<ToastContainer
className="toast-position"
autoClose={3000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
</>
    );
  });
  
  export default Welder_Continuity_Record;
  