import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";

import Export from "../../icons/Download_Blue_Big.svg"

import Export_gray from "../../icons/Download_Grey_Big.svg"

export default (props) => {
  return (
    <div className="cell-action">
      {/* <img
        src={Delete_gray}
        onMouseOver={(e) => (e.currentTarget.src = Delete)}
        onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
        alt="delete"
        className="p-1"
        onClick={() => props.handleDeleteEvent(props.selectedData)}
      /> */}
      <img
        onMouseOver={(e) => (e.currentTarget.src = Edit)}
        onMouseOut={(e) => (e.currentTarget.src = Edit_gray)}
        src={Edit_gray}
        alt="edit"
        style={{position:"relative",bottom:"8px"}}

        onClick={() => props.handleEditEvent(props.selectedData)}
      />
      <img
        onMouseOver={(e) => (e.currentTarget.src = Export)}
        onMouseOut={(e) => (e.currentTarget.src = Export_gray)}
        src={Export_gray}
        alt="Export"
        style={{position:"relative",bottom:"8px"}}

        onClick={() => props.handlePdfEvent(props.selectedData)}
      />
      {/* <img
        src={copy}
        onMouseOver={(e) => (e.currentTarget.src = copy)}
        onMouseOut={(e) => (e.currentTarget.src = copy)}
        alt="copy"
        className="p-1"
        onClick={() => props.handleCopyEvent(props.selectedData)}
      /> */}
      {/* <img
        src={Temp_Save}
        onMouseOver={(e) => (e.currentTarget.src = Temp_Save)}
        onMouseOut={(e) => (e.currentTarget.src = Temp_Save)}
        alt="Temp_Save"
        className="p-1"
        onClick={() => props.handleTempEvent(props.selectedData)}
      /> */}
    </div>
  );
};
