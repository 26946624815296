import { MessageTypes, characterserror} from "./WPSGroove_entity";

export const emptyError = (err, seterr,iserror) => {
  debugger
  let entries = Object.keys(err);

  entries.forEach((i) => {

   
    if(err[i].message ===  MessageTypes.characterserror){
      iserror = true
      err[i] = {
        status: true,
        message: err[i].message,
      };    
    }
    else{
    err[i] = {
      status: false,
      message: ``,
    };
  }
   
  });

  seterr((prevState) => ({
    ...prevState,
    ...err,
  }));
  return iserror
};

export const DisableFields = ((headerObj,Status,Action,action) =>{

 

 
  if(action === Action.ActionCopy){

    return true
  }
  else{
      if(headerObj.WPSH_Prepared_By !== sessionStorage.getItem("User") && (headerObj.WPSH_Status === Status.StatusApproved
        || headerObj.WPSH_Status === Status.StatusRejected || headerObj.WPSH_Status  === Status.StatusTempSaved) ){
          
          
          return true
      
    }
    else if (headerObj.WPSH_Status === Status.StatusPending && (sessionStorage.getItem("Band") !== "P" &&  sessionStorage.getItem("Band") !== "T" ))
    {
     

      return true
    }
  }
 
 return false
})

export const DisableFieldsWPQ = ((headerObj,Status,Action,action) =>{

  
   if(action === Action.ActionCopy){

     return true
   }
   else{
       if(headerObj.WPSH_PreparedBy !== sessionStorage.getItem("User") && (headerObj.WPSH_Status === Status.StatusApproved
         || headerObj.WPSH_Status === Status.StatusRejected || headerObj.WPSH_Status  === Status.StatusTempSaved) ){
           
           
           return true
       
     }
     else if (headerObj.WPSH_Status === Status.StatusPending && (sessionStorage.getItem("Band") !== "P" &&  sessionStorage.getItem("Band") !== "T" ))
     {
      
       return true
     }
   }
  
  return false
 })

 export const  createDetailObj = (start,WPStype) => {

  let temparray = []
  for (let i = start; i <= 3; i++) {
    temparray.push({
      SK: "",
      PK: "",

      WPSH_Project_No: "",
      WPSH_WPS_No: "",
      WPSD_Process: "",
      WPSD_Type: "",
      WPSD_FM_SFA: "",
      WPSD_FM_FNo: "",
      WPSD_FM_Ano: "",
      WPSH_WeldLayer: "",
      WPSD_FM_Classification: "",
      WPSD_FM_ChemicalAnalysis_TradeName: "",
      
      WPSD_FM_TRQ_Max: "",
      WPSD_FM_TRQ_Min: "",
      wld_createdby: "",
      wld_createdon: "",
      wld_updatedby: "",
      wld_updatedon: "",
      WPSH_Project_No: "",
      WPSH_WPS_No: "",
      WPSD_WP_MinimumPreheatTemp: "",
      WPSD_WP_MaximumInterpassTemp: "",
      WPSD_WP_TungstenSIze: "",
      WPSD_WP_TungstenType: "",
      WPSD_WP_FillerMetalProductForm: "",
      WPSD_WP_FillerMetalSIze: [],
      WPSD_WP_LayerNumber: [],
      WPSD_WP_PositionOfGroove: [],
      WPSD_WP_PositionOfFillet: [],
      WPSD_WP_WeldProgression: [],
      WPSD_WP_CurrentPolarity: [],
      WPSD_WP_Elec_char_others: "",
      WPSD_WP_Amperes: [],
      WPSD_WP_Amperes1: [],
      WPSD_WP_Pulsing_current: "",
      WPSD_WP_Volts: [],
      WPSD_WP_TravelSpeed: [],
      WPSD_WP_MaximumHeatInput: [],
      WPSD_WP_ShieldingFlowRate: "",
      WPSD_WP_ShieldingGasType: "",
      WPSD_WP_BackingGasType: "",
      WPSD_WP_BackingFlowRate: "",
      WPSD_WP_TrailingFlowRate: "",
      WPSD_WP_TrailingGasType: "",
      WPSD_WP_BatchNumber: [],
      WPSD_WP_WeldConsumable: "",
      WPSD_WP_PositionOfOverlay: [],
      WPSD_WP_Weld_Bead_Overlap: "",
      WPSD_WP_ArcTransferMode: "",
      WPSD_WP_StringOrWeave: "",
      WPSD_WP_Orifice_GasCupSize: "",
      WPSD_WP_CTWD: "",
      WPSD_WP_Multiple_SinglePassPerSide: "",
      WPSD_WP_Multiple_SingleLayer: "",
      WPSD_WP_Multi_SingleElectrode: "",
      WPSD_WP_ElectrodeSpacing_Late: "",
      WPSD_WP_ElectrodeSpacing_Logit: "",
      WPSD_WP_ElectrodeAngleDegree: "",
      WPSD_WP_Max_Pass_Thickness: "",
      status: "",
      wld_WPSType: WPStype,
      ActiveProcess:"Active",
      WPSH_WPS_Revi_No:"Active",
      WPSD_WP_WeldDesc:[],
      WPSD_WP_WeldWeight:[],
      welddesc:[],
      weldweight:[],
      WPSD_WP_MinimumBeadLength:[],
    });
  }

  return temparray;
}

export const AddRowsDetailsoBj = (result,setdetailObj) =>{

  var len = result.length; 
  var loop = 3 - len;
  let temparray1 = [];
  
  if (loop > 0) {
    temparray1 = createDetailObj(len + 1);

    setdetailObj([...result, ...temparray1]);
  
  } else {

    setdetailObj([...result]);
  }

}
