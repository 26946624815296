import { useEffect, useState } from "react";
import {
  getReqByType,
  updateTrainingRequest,
} from "../../../Services/trainingRequisition-service";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import Track from "../../../icons/Track.svg";
import DownArrow from "../../../icons/DownArrow.svg";
import { isAlphanumericWithSpecialChars } from "../../../functions/validations";
import { toast } from "react-toastify";
import { getInitials } from "../../../functions/welding-functions";
import { SearchSelectWelder } from "../search-select";
import { getPTUsers } from "../../../Services/user-service";

const ClosureRemarks = (props) => {

  const [closureremarksobj, setClosureRemarksObj] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [Approver2, setApprover2] = useState([]);
  const [approver2Name, setApprover2Name] = useState("");
  const [accRejStatus, setAccRejStatus] = useState("");
  const [disabledRadio, setDisabledRadio] = useState(true);
  const [closureRemarksData, SetClosureRemarksData] = useState();
  const [showForm, setShowForm] = useState(false);

  let levelCount = [
    { levelName: "1", approverDesignation: "L+1" },
    { levelName: "2", approverDesignation: "Department Head" },
    { levelName: "Final", approverDesignation: "Training Department Head" },
  ];

  const getAllRecords = async () => {
    let tmpObj = { fieldValue: props.rowData.SK, flag: "reqNumber" };
    const Records = await getReqByType(tmpObj);
    if (Records?.length > 0) {
      setClosureRemarksObj(Records);

      let currRevision = Records.filter(
        (item) => item.RevisionNo === props.rowData.RevisionNo
      );
      if (currRevision?.length > 0) {
        SetClosureRemarksData(currRevision[0]);
      }


      setCollapse({ activeItem: props.rowData.RevisionNo });
    }
  };
  const [error, setError] = useState({
    wld_TrainingReqApproverComment: {},
    wld_TrainingReqApprover2: {},
  });

  const handleCollapsible = (collpasedItem) => {
    if (collapse.activeItem === collpasedItem) {
      setCollapse({ activeItem: -1 });
    } else {
      setCollapse({ activeItem: collpasedItem });
    }
  };

  function handleChange(e) {
    SetClosureRemarksData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  const handleRadioChange = (e) => {
    setDisabledRadio(false);
    setAccRejStatus(e.target.value);
    SetClosureRemarksData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const validateData = () => {
    let currLevel = props.rowData.wld_pendingStatus;
    let err = {};
    let isError = false;
    if (closureRemarksData["wld_TrainingReq" + currLevel + "Comment"] === "") {
      isError = true;
      err.wld_TrainingReqApproverComment = {
        status: true,
        message: "Comment is required.",
      };
    }
    if (currLevel === "Approver1" && approver2Name === "") {
      isError = true;
      err.wld_TrainingReqApprover2 = {
        status: true,
        message: "Approver 2 is required.",
      };
    }
    setError((prevState) => ({
      ...prevState,
      ...err,
    }));
    return isError;
  };

  const handleSubmit = async (flag) => {
    var valid = false
    if(flag != "Delete") valid = validateData()
    if (!valid) {
      let trainingObj = Object.assign({}, props.rowData);
      

      if (flag === "Delete") {
        closureRemarksData.wld_status = "Deleted";
        closureRemarksData.wld_pendingStatus = "Deleted";
        closureRemarksData.wld_TrainingReqPendingWith = "system";
      } else {
        if (props.rowData.wld_pendingStatus === "Rejected") {
          closureRemarksData.wld_status = "In Progress";
          closureRemarksData.wld_pendingStatus = "Approver1";
          closureRemarksData.wld_TrainingReqPendingWith =
            closureRemarksData.wld_TrainingReqApprover1EmailId;
          trainingObj.wld_TrainingReqExperience = props.ExperienceNewRev;
          
        } else {
          let ind = levelCount.findIndex(
            (item) =>
              props.rowData.wld_pendingStatus === "Approver" + item.levelName
          );
          closureRemarksData[
            "wld_TrainingReqApprover" + levelCount[ind].levelName + "CreatedOn"
          ] = new Date().toLocaleDateString("en-GB");

          if (accRejStatus === "Rejected") {
            closureRemarksData.wld_status = "Rejected";
            closureRemarksData.wld_pendingStatus = "Rejected";
            closureRemarksData.wld_TrainingReqPendingWith =
              props.rowData.wld_TrainingReqRequesterEmailId;
          } else if (accRejStatus === "Approved") {
            if (ind === levelCount.length - 1) {
              closureRemarksData.wld_status = "Approved";
              closureRemarksData.wld_pendingStatus = "Approved";
              closureRemarksData.wld_TrainingReqPendingWith = "system";
            } else {
              closureRemarksData.wld_status = "In Progress";
              closureRemarksData.wld_pendingStatus =
                "Approver" + levelCount[ind + 1].levelName;
              closureRemarksData.wld_TrainingReqPendingWith =
                closureRemarksData[
                  "wld_TrainingReqApprover" +
                    levelCount[ind + 1].levelName +
                    "EmailId"
                ];
            }
          }
        }
      }
      let obj = { closureRemarksData, trainingObj };
      let result = updateTrainingRequest(obj);
      result
        .then((resp) => {
          toast.success(`Training Request has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          
          props.callReload();
          setShowForm(false)
        })
        .catch((error) => {

        });
    }
  };

  function handleCancel() {
    props.close(true, "Cancel");
  }

  const fetchApprover2 = async () => {
    const result = await getPTUsers();
    if (result.data != undefined) {
      for (let dt of result.data) {
        dt.key = dt.SK + "~" + dt.um_name;
      }
      setApprover2(result.data);
    }
  };

  useEffect(() => {
    getAllRecords();
    fetchApprover2();
  }, [showForm]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 1000);
    return () => clearTimeout(timer);
  }, [alertMessage]);


  const successapprove = ()=>{
    return(
<div
className="sucessapproved">
  
</div>
    )
  }

  return (
    <>
      <Accordion
        defaultActiveKey="1"
        style={{
          marginTop: "20px",
        }}
      >
        {closureremarksobj?.map((value, index) => {
          return (
            <Accordion.Item
              eventKey={index}
              style={{
                backgroundColor: "white",
                marginBottom: "2rem",
              }}
              key={index}
            >
              <Accordion.Header
                className={
                  collapse?.activeItem === index
                    ? `dispaly-none accordion-header`
                    : `accordion-header`
                }
                onClick={() => handleCollapsible(index)}
              >
                <Image
                  src={Track}
                  alt="Track"
                  className="profile-initials top-3 left43"
                ></Image>
                <label
                  className="remarkLabel size-20"
                  style={{ position:"relative",left:"63px" }}
                >
                  Track Report - Rev.{index}
                </label>
                <Image
                  src={DownArrow}
                  alt="DownArrow"
                  className="blue-arrow downarrow"
                  style={{position:"relative", left:"45px"}}
                ></Image>
              </Accordion.Header>
              <Accordion.Body
                className={
                  collapse?.activeItem !== index ? `dispaly-none` : `show-block`
                }
              >
                <Form>
                  <ListGroup as="ol">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start "
                      style={{borderLeft:"none",borderTop:"none",borderRight:"none"}}
                    >
                      <div
                        className={"profile-initialsClosure"}
                        style={{ marginLeft: "-0.4%" }}
                      >
                        {getInitials(
                          props.rowData.wld_TrainingReqRequesterName
                        )}
                      </div>

                      <div className="ms-5 me-auto">
                        <div className="fw-bold">
                          {props.rowData.wld_TrainingReqRequesterName}
                        </div>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            paddingTop: "125px",
                            position:"relative",
                            bottom:"7px"

                          }}
                        >
                          Engineer
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          paddingRight: "32px",
                        }}
                      >
                        {props.rowData.wld_TrainingReqDate}
                      </span>
                      <Badge bg="light" style={{ color: "black" }} >
                        Initiated
                      </Badge>
                    </ListGroup.Item>
                    {levelCount.map((ind, rowNo) => {
                      return (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                          style={{borderLeft:"none",borderTop:"none",borderRight:"none"}}

                        >
                          {" "}
                          <div
                            className={"profile-initialsClosure "}
                            style={{ marginLeft: "-0.4%" }}
                          >
                            {getInitials(
                              value["wld_TrainingReqApprover" + ind.levelName]
                            )}
                          </div>
                          <div
                            className="ms-5 me-auto"
                            style={{ width: "27%" }}
                          >
                            {props.calledFrom === "Approval" &&
                            value.RevisionNo === props.rowData.RevisionNo &&
                            ind.levelName === "2" &&
                            props.rowData.wld_pendingStatus === "Approver1" ? (
                              <>
                           
                              <Form.Group>
                                <div className="fw-bold">
                                  {value[
                                    "wld_TrainingReqApprover" + ind.levelName
                                  ] === "" && (
                                    <Form.Text className="text-danger">
                                      {"Kindly Select Approver 2"}
                                    </Form.Text>
                                  )}
                                  <SearchSelectWelder
                                    className="inspdrop"
                                    id="ApproverName"
                                    data={Approver2}
                                    valueField={"key"}
                                    labelField2={"um_band"}
                                    required
                                    labelField1={"um_name"}
                                    placeholder="Approver 2"
                                    onChange={(ItemList) => {
                                      let selectedData =
                                        ItemList.value.split("~");
                                      setApprover2Name(selectedData[1]);
                                      SetClosureRemarksData((prevState) => ({
                                        ...prevState,
                                        wld_TrainingReqApprover2:
                                          selectedData[1],
                                        wld_TrainingReqApprover2EmailId:
                                          selectedData[0],
                                      }));
                                    }}
                                  />
                                </div>
                                {error.wld_TrainingReqApprover2.status && (
                                  <Form.Text className="text-danger">
                                    {error.wld_TrainingReqApprover2.message}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              </>
                            ) : (
                              <div className="fw-bold">
                                {
                                  value[
                                    "wld_TrainingReqApprover" + ind.levelName
                                  ]
                                }
                              </div>
                            )}
                            <span style={{ fontSize: "12px", color: "grey", position:"relative", bottom:"7px" }}>
                              {ind.approverDesignation}
                            </span>
                            <br />
                            <br />
                            {props.calledFrom === "Approval" &&
                            value.RevisionNo === props.rowData.RevisionNo &&
                            props.rowData.wld_pendingStatus ===
                              "Approver" + ind.levelName ? (
                              <>
                                <Form.Group>
                                  <div
                                    class="g-input"
                                    style={{ width: "400%", position:"relative", bottom:"13px" }}
                                  >
                                    <input
                                      id={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      }
                                      name={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      }
                                      type="text"
                                      className={
                                        error.wld_TrainingReqApproverComment
                                          .status
                                          ? "placeHolderInput inputText text-field-with-error"
                                          : "placeHolderInput inputText"
                                      }
                                      placeholder=" "
                                      onChange={(e) => handleChange(e)}
                                      style={{ width: "80%", }}
                                      onInput={(e) => {
                                        let error =
                                          isAlphanumericWithSpecialChars(
                                            e.target.value
                                          );
                                        setError((prevState) => ({
                                          ...prevState,
                                          wld_TrainingReqApproverComment: error,
                                        }));
                                      }}
                                    />
                                    <label
                                      for={
                                        "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      }
                                    >
                                      Comments
                                    </label>
                                  </div>
                                  &nbsp;&nbsp;
                                  {error.wld_TrainingReqApproverComment
                                    .status && (
                                    <Form.Text className="text-danger" style={{position:"relative", bottom:"20px", fontSize:"12px"}}>
                                      {
                                        error.wld_TrainingReqApproverComment
                                          .message
                                      }
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                <div className="m-input"  style={{position:"relative",bottom:"15px"}}>
                                  <div className="d-flex">
                                      <input
                                        id={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        type="radio"
                                        name={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        value="Approved"
                                        onChange={(e) => handleRadioChange(e)}
                                      />
                                      <span
                                      >
                                         &nbsp;Approve
                                      </span>
                                    <div className="d-flex" style={{position:"relative", left:"60px"}}>
                                      <input
                                        id={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        type="radio"
                                        name={
                                          "wld_TrainingReqApprover" +
                                          ind.levelName +
                                          "Status"
                                        }
                                        value="Rejected"
                                        onChange={(e) => handleRadioChange(e)}
                                      />
                                      <span
                                      >
                                       &nbsp;Reject
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <i style={{ fontSize: "12px", color: "grey" }}>
                                {
                                  value[
                                    "wld_TrainingReqApprover" +
                                      ind.levelName +
                                      "Comment"
                                  ]
                                }
                              </i>
                            )}
                          </div>
                          <div className="ms-2 me-auto"></div>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              paddingRight: "32px",
                            }}
                          >
                            {
                              value[
                                "wld_TrainingReqApprover" +
                                  ind.levelName +
                                  "CreatedOn"
                              ]
                            }
                          </span>
                          <Badge
                            
                            style={{
                              position: "relative",
                              color:
                                value[
                                  "wld_TrainingReqApprover" +
                                    ind.levelName +
                                    "Status"
                                ] === "Approved"
                                  ? "var(--godrej-green-alt)"
                                  : value[
                                      "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Status"
                                    ] === "In Progress"
                                  ? "var(--godrej-orange)"
                                  : "var(--godrej-red)",
                              background: 
                                value[
                                  "wld_TrainingReqApprover" +
                                    ind.levelName +
                                    "Status"
                                ] === "Approved"
                                  ? "var(--godrej-green-bg)"
                                  : value[
                                      "wld_TrainingReqApprover" +
                                        ind.levelName +
                                        "Status"
                                    ] === "In Progress"
                                  ? "var(--godrej-orange-bg)"
                                  : "var(--godrej-red-bg)"
                              
                            }}
                            bg={
                              value[
                                "wld_TrainingReqApprover" +
                                  ind.levelName +
                                  "Status"
                              ] === "Approved"
                                ? "sucessapproved"
                                : value[
                                    "wld_TrainingReqApprover" +
                                      ind.levelName +
                                      "Status"
                                  ] === "In Progress"
                                ? "inprogressapproved"
                                : "rejctedapproved"
                            }
                          >
                            {
                              value[
                                "wld_TrainingReqApprover" +
                                  ind.levelName +
                                  "Status"
                              ]
                            }
                          </Badge>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Form>
                <Row style={{ alignContent: "right" }}>
                  <Col style={{ padding: "0" }}>
                    <div className="container history-box"></div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Row>
        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {alertMessage}
          </Alert>
        )}
        <div>
          <div className="float-start m-1 visible">
            <Button variant="outline-primary" onClick={handleCancel}  style={{
                      width: "100px",
                      height: "38px",
                      position:"relative",
                      left:"10px",
                      top:"10px"
                    }} >
              Cancel
            </Button>
          </div>
          {props.rowData.wld_status !== "Deleted" &&
            props.rowData.wld_status !== "Approved" && (
              <div class="float-end m-1">
                {(props.calledFrom === "Initiated" && props.rowData.wld_status != "Rejected")? (
                  <Button
                    className="btn-add-custom"
                    onClick={() => handleSubmit("Delete")}
                    style={{
                      width: "100px",
                      height: "38px",
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    className="btn-add-custom"
                    onClick={() => handleSubmit("Save")}
                    style={{
                      width: "100px",
                      height: "38px",
                    }}
                    disabled={
                     ( props.rowData.wld_status === "Rejected" && props.calledFrom === "Initiated") || !disabledRadio
                        ? false
                        : true
                    }
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
        </div>
      </Row>
    </>
  );
};
export default ClosureRemarks;
