import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import calenderIcon from "../../icons/calendericon.svg";

export let tableHeader = [
  {
    A: "",
    B: "Project",
    C: "Slip Date",
    D: "Plant",
    E: "Slip No ",
    F: "Cancelled",
    G: "Joint",
    H: "WPS No",
    I: "Process",
    J: "Type",
    K: "Welder Code",
    L: "Welder Name",
  },
];

export let prodReportHeader = [
  {
    A: "",
    B: "Project",
    C: "Slip Date",
    D: "Plant",
    E: "Slip No ",
    F: "Cancel Status",
    G: "Joint Type",
    H: "WPS",
    I: "Process",
    J: "Type",
    K: "Welder Code",
    L: "Welder Name",
    M: "Slip Created By",
    N: "Item Code",
    O: "Item Desc",
    P: "Item Group",
    Q: "WCinKgs",
    R: "WMinKgs",
  },
];

function MuiIcon() {
  return <Image src={calenderIcon} />;
}

export const Date_Input = ({
  label,
  className,
  name,
  sx,
  value,
  todayDate,
  setobj,
  obj,
  datevalue,
  setdatevalue,
  divClass,
  status,
  message,
  maxDate,
  disabled,
  readOnly,
  setError,
  handleDateChange,
  onChange,
  mindate,
  fromdate,
  todate,
}) => {


  return (
    <>
      <div className="groove_date">
        <div class={divClass}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                slots={{ openPickerIcon: MuiIcon }}
                label={label}
                className={className}
                format="DD-MM-YYYY"
                name={name}
                sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy", zIndex: "0" }}
                disabled={disabled}
                readOnly={readOnly}
                value={
                  datevalue === false || obj[name] === ""
                    ? null
                    : obj && obj[name]
                    ? dayjs(obj[name])
                    : dayjs(todayDate)
                }
                maxDate={dayjs(maxDate)}
                onChange={(e) => {
                  
                  let date = dayjs(e).format("YYYY-MM-DD");

                  setobj((prev) => ({
                    ...prev,
                    [name]: date,
                  }));

                  if (name === "YYYY-MM-DD") {
                    if (todate) {
                      if (date > todate) {
                        
                        setError((prevState) => ({
                          ...prevState,
                          [name]: {
                            status: true,
                          },
                        }));
                        toast.warning(
                          "WI_From_Date",
                          {
                            position: toast.POSITION.TOP_LEFT,
                            className: "Selected date cannot be greater than To date",
                          }
                        );
                        

                        setobj((prev) => ({
                          ...prev,
                          [name]: date,
                        }));
                      }
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "toast-message",
                        },
                      }));
                    }
                  }

                  if (name === "") {
                    if (date < fromdate) {
                   
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: true,
                        },
                      }));
                      toast.warning(
                        "WI_To_Date",
                        {
                          position: toast.POSITION.TOP_LEFT,
                          className: "Selected date cannot be less than From date",
                        }
                      );
                      setdatevalue(false);
                      
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "toast-message",
                        },
                      }));
                    }
                  }

                  if (name !== "vall" && name !== "") {
                    setobj((prev) => ({
                      ...prev,
                      [name]: date,
                    }));

                    setError((prevState) => ({
                      ...prevState,
                      [name]: {
                        status: false,
                        message: "WI_To_Date",
                      },
                    }));
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="WI_From_Date">
        {status && <Form.Text className="">{message}</Form.Text>}
      </div>
    </>
  );
};
