// ---------------------------------------------------------------------------------------------------------------//

//Date - 28/3/2024.
//Author - Sagar Gawade.
//Session - PQR Transaction(Tube to Tubesheet) - Page_2.
//Contains - ADD / EDIT / GET API'S of PQR Tube to Tubesheet Session.
//Workflow - 1) There is no approval process,user can select Welding Engg Name and Witness ByClicks on save button to save the data.
//         - 2) Initiator Can Edit Record in both Freezed and Temp Saved Status.
//         - 3) In 2st Page API is called in such a way that  it will only update data in batches(UpdatePQRHeaderComplete)
//              and it will be save in Header of respective tables as there is no field on this page relataid to detail or PQR Process

// ---------------------------------------------------------------------------------------------------------------//
import { Row, Col } from "react-bootstrap";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MessageTypes, Action, Status } from "./PQRObjects/PQR_entity";
import { Single_Input } from "../utility/CommonComponents/Inputs_Transaction"

import { IsUserMasterBand } from "../../Services/user-service";
import { PQRHeader } from "./PQRObjects/PQRHeader";
import {
  PQR_tubePage2,
} from "./PQRObjects/PQR_entity";
import {
  PQR_Page3_Table3,
  PQR_Page3_Table_ColumnN,
  PQR_Page3_Table_ColumnNindex,
} from "../utility/CommonComponents/wpq_Page3_table_components";
import {
  check_Pqr_Pag3_LPEX,
  check_Pqr_Pag3_MEX,
  check_Pqr_Pag3_VEX,
  check_Pqr_Page4_MLP,
  AddUpdatePQRHeader,
} from "./PQRObjects/PQR_Validations";
import { emptyError } from "./PQRObjects/PQR_common";
import { DotLoader } from "react-spinners";

const PQRTubesheetPage2 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      tabno,
      WPStype,
    },
    ref
  ) => {
    const location = useLocation();
    


    const [IsEdited, setIsEdited] = useState(false);
    const [action, setaction] = useState(location.state?.action);

    const [loading, setLoading] = useState(false);

    const[Visual_Examination_Disabled,setVisual_Examination_Disabled] = useState(false)

    const[Liquid_Penetrant_Examination_Disabled,setLiquid_Penetrant_Examination_Disabled] = useState(false)

    let Visual_Examination = headerObj?.Visual_Examination;
    let Liquid_Penetrant_Examination = headerObj?.Liquid_Penetrant_Examination;
    let object = headerObj?.Minimum_Leak_Path;

    let objectMacro = headerObj?.Macro_Examination;

    let array = { ...object };
    let element = array[0];
    let data = { ...element };
    let Table1 = data.Table1;
    let Table2 = data.Table2;
    let Table3 = data.Table3;
    let Table4 = data.Table4;
    let Table5 = data.Table5;

    let arrayMac = { ...objectMacro };
    let elementMac = arrayMac[0];
    let deatils = { ...elementMac };
    let Table6 = deatils.Table6;
    let Table7 = deatils.Table7;
    let Table8 = deatils.Table8;
    let Table9 = deatils.Table9;
    let Table10 = deatils.Table10;

    let Minimum_Leak_Path1 = Table1;
    let Minimum_Leak_Path2 = Table2;
    let Minimum_Leak_Path3 = Table3;
    let Minimum_Leak_Path4 = Table4;
    let Minimum_Leak_Path5 = Table5;

    let Macro_Examination1 = Table6;
    let Macro_Examination2 = Table7;
    let Macro_Examination3 = Table8;
    let Macro_Examination4 = Table9;
    let Macro_Examination5 = Table10;

    let sessionName = "PQRTubesheet";
    const [error, setError] = useState({
      PQR_VEX_WeldPass: {},
      PQR_VEX_Acceptance_Criteria: {},
      PQR_VEX_Observation: {},
      PQR_VEX_Results: {},
      PQR_VEX_Notes: {},

      PQR_LPEX_WeldPass: {},
      PQR_LPEX_Acceptance_Criteria: {},
      PQR_LPEX_Observation: {},
      PQR_LPEX_Results: {},
      PQR_LPEX_Notes: {},

      PQR_MLP_Tube_No: {},
      PQR_MLP_LocA: {},
      PQR_MLP_LocB: {},
      PQR_MLP_LocC: {},
      PQR_MLP_LocD: {},

      PQR_MLP_Tube_No1: {},
      PQR_MLP_LocA1: {},
      PQR_MLP_LocB1: {},
      PQR_MLP_LocC1: {},
      PQR_MLP_LocD1: {},

      PQR_MLP_Tube_No2: {},
      PQR_MLP_LocA2: {},
      PQR_MLP_LocB2: {},
      PQR_MLP_LocC2: {},
      PQR_MLP_LocD2: {},

      PQR_MLP_Tube_No3: {},
      PQR_MLP_LocA3: {},
      PQR_MLP_LocB3: {},
      PQR_MLP_LocC3: {},
      PQR_MLP_LocD3: {},

      PQR_MLP_Magnification: {},
      PQR_MLP_Acceptance_Criteria: {},
      PQR_MLP_Observation: {},
      PQR_MLP_Result: {},

      PQR_MEX_Tube_No0: {},
      PQR_MEX_LocA0: {},
      PQR_MEX_LocB0: {},
      PQR_MEX_LocC0: {},
      PQR_MEX_LocD0: {},

      PQR_MEX_Tube_No1: {},
      PQR_MEX_LocA1: {},
      PQR_MEX_LocB1: {},
      PQR_MEX_LocC1: {},
      PQR_MEX_LocD1: {},

      PQR_MEX_Tube_No2: {},
      PQR_MEX_LocA2: {},
      PQR_MEX_LocB2: {},
      PQR_MEX_LocC2: {},
      PQR_MEX_LocD2: {},

      PQR_MEX_Tube_No3: {},
      PQR_MEX_LocA3: {},
      PQR_MEX_LocB3: {},
      PQR_MEX_LocC3: {},
      PQR_MEX_LocD3: {},

      PQR_MEX_Magnification: {},
      PQR_MEX_Acceptance_Criteria: {},
      PQR_MEX_Observation: {},
      PQR_MEX_Result: {},

      PQR_WP_Comments: {},

      PQR_WP_Report_Nummber: {},

      PQR_WP_TypeOfTest: {},
      PQR_WP_Acceptance_Criteria: {},
      PQR_WP_Result: {},
    });


    useEffect(() =>{
      let filledval
      if(headerObj.Visual_Examination != undefined){
        filledval = headerObj.Visual_Examination.slice(0,1).filter((i) => {
      
        return( i.PQR_VEX_WeldPass == "" ||
       i.PQR_VEX_Acceptance_Criteria == "" ||  i.PQR_VEX_Observation == "" ||
       i.PQR_VEX_Results == "")
      } )
      setVisual_Examination_Disabled(filledval.length > 0 ? true : false)
    }


    if(headerObj.Liquid_Penetrant_Examination != undefined){
      filledval = headerObj.Liquid_Penetrant_Examination.slice(0,1).filter((i) => {
    
      return( i.PQR_LPEX_WeldPass == "" ||
     i.PQR_LPEX_Acceptance_Criteria == "" ||  i.PQR_LPEX_Observation == "" ||
     i.PQR_LPEX_Results == "")
    } )
    setLiquid_Penetrant_Examination_Disabled(filledval.length > 0 ? true : false)
  }    

    },[headerObj])

    const handleChangetestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Visual_Examination[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeMacro_Examinationarray = (
      Index,
      key,
      table,
      newValue
    ) => {

      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Macro_Examination[0][table][Index][key] = newValue;

      setheaderObj(updatedheadertestObj);
    };

    const handleChangeLiquid_Penetrant_Examinationarray = (
      Index,
      key,
      newValue
    ) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Liquid_Penetrant_Examination[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeMinimum_Leak_Patharray = (
      Index,
      key,
      table,
      newValue
    ) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Minimum_Leak_Path[0][table][Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const validate = async () => {
      
      let isError = false;
      let norows

      let tubePage2 = Object.entries(PQR_tubePage2);

      tubePage2.map(([key, val]) => {
        if (detailObj?.[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      
      norows = check_Pqr_Pag3_VEX(Visual_Examination, 4);

      if (norows < 1) {
        Visual_Examination[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Visual_Examination[0].error = true;
      }
      else{

        Visual_Examination[0].errormessage = ""
        Visual_Examination[0].error = false;
      }
      

       norows = check_Pqr_Pag3_LPEX(Liquid_Penetrant_Examination);

   

      
      if (norows < 1) {
        Liquid_Penetrant_Examination[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Liquid_Penetrant_Examination[0].error = true;
      }
      else{

        Liquid_Penetrant_Examination[0].errormessage = ""
        Liquid_Penetrant_Examination[0].error = false;
      }

       norows = check_Pqr_Pag3_MEX(Macro_Examination1);


      if (norows < 1) {
        Macro_Examination1[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Macro_Examination1[0].error = true;
      }
      else{

        Macro_Examination1[0].errormessage = ""
        Macro_Examination1[0].error = false;
      }
       norows = check_Pqr_Page4_MLP(Minimum_Leak_Path1);

   

      if (norows < 1) {
        Minimum_Leak_Path1[0].errormessage = "Minimum 1 Full Row is required";
        isError = true;
        Minimum_Leak_Path1[0].error = true;
      }
      else{

        Minimum_Leak_Path1[0].errormessage = ""
        Minimum_Leak_Path1[0].error = false;
      }

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };


    const handleAdd = async (approvalstatus) => {
      let result = true;
      
      headerObj.WPSH_Status = approvalstatus
      

    let VEX = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Visual_Examination: headerObj.Visual_Examination,
    };

    let LPEX = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Liquid_Penetrant_Examination: headerObj.Liquid_Penetrant_Examination,
    };

    let MLP = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Minimum_Leak_Path: headerObj.Minimum_Leak_Path,
    };

    let MEX = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Macro_Examination: headerObj.Macro_Examination,
    };

    let OTHERData = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      PQR_VEX_Notes: headerObj.PQR_VEX_Notes,
      PQR_LPEX_Notes: headerObj.PQR_LPEX_Notes,
    };

    let OTHERData1 = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      PQR_MLP_Result: headerObj.PQR_MLP_Result,
      PQR_MEX_Result: headerObj.PQR_MEX_Result,
      WPSH_Status: Status.StatusTempSaved,
      WPSH_Approval_Status: headerObj.WPSH_Status,
    };
    
    const postList = [VEX, LPEX, MLP, MEX, OTHERData, OTHERData1];

      await AddUpdatePQRHeader(undefined,postList)
        .catch((e) => {
          result = false;
        })
        .then(async (r) => {
          setaction(Action.ActionEdit);
         // alert(r)
          if(r == MessageTypes.SpecialCharacters)
            result = MessageTypes.SpecialCharacters
       
        });
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };

  

    const validateTemp = async () => {
      debugger
      let isError = false;

       let temperror = emptyError(error, setError, isError);

      return {isError ,temperror};
    };

    const handleSubmitTemp = async () => {
      let result = false;
      var val = await validateTemp();

          setIsEdited(false);
          if (val.isError === false){
            if ( val.temperror === false) {
              setLoading(true)
              result = await handleAdd(headerObj?.WPSH_Status ,"")
             setLoading(false)
              if (result == true) {
                return MessageTypes.Temp;
              }
              else if(result == MessageTypes.SpecialCharacters){
                return MessageTypes.SpecialCharacters
              }
               else {
                return MessageTypes.Fail;
              }
      
            }
            return MessageTypes.SpecialCharacters;
          }
          return MessageTypes.Required;
        


     
    };

    const handleSubmit = async () => {
      setIsEdited(false);

      if ((await validate())) {
        setIsEdited(true);
      }
      let result = false;
      const isUsernamePresent = await checkIfUsernameExists();

      if (!isUsernamePresent) {
        headerObj.WPSH_Status = Status.StatusTempSaved;
      } 

      if (!(await validate())) {
        setLoading(true)
        result = await handleAdd(Status.StatusTempSaved);
setLoading(false)
        if (result == true) {
          return MessageTypes.Success;
        }
        else if(result == MessageTypes.SpecialCharacters){
          return MessageTypes.SpecialCharacters
        }
        else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext2: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp2: async () => {
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));

    const handleChangeHeader = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


    return (
      <Row className="font">
          {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <PQRHeader headerObj={headerObj} WPStype={WPStype}></PQRHeader>
        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text "  style={{position:"relative" , top:"10px"}}>Visual Examination </p>
        
              <PQR_Page3_Table_ColumnN
              columns={4}
                sessionName={sessionName}
                maxLength={[25,25,25,25]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                title={["Weld Pass","Acceptance Criteria","Observation","Results"]}
                array={Visual_Examination}
                disabled={Visual_Examination_Disabled}
                numberofrowsdisabled={1}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_VEX_WeldPass","PQR_VEX_Acceptance_Criteria","PQR_VEX_Observation","PQR_VEX_Results"]}
                status={[error.PQR_VEX_WeldPass.status,error.PQR_VEX_Acceptance_Criteria.status,error.PQR_VEX_Observation.status,error.PQR_VEX_Results.status]}
                message={[error.PQR_VEX_WeldPass.message,error.PQR_VEX_Acceptance_Criteria.message,error.PQR_VEX_Observation.message,error.PQR_VEX_Results.message]}
                handleChangetestarray={handleChangetestarray}
              />
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_VEX_Notes"
                type="text"
                maxLength={100}
                status={error.PQR_VEX_Notes.status}
                value={headerObj?.PQR_VEX_Notes}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_VEX_Notes.message}
                setError={setError}
                error={error} 
              
              ></Single_Input>
            </Col>
          </Row>
        </Row>
        <Row style={{padding: "20px 60px" }} className="PQR-Page-row-padding">
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative" , top:"10px"}}>Liquid Penetrant Examination </p>
             
              <PQR_Page3_Table_ColumnN
                columns={4}
                sessionName={sessionName}
                maxLength={[25,25,25,25]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                title={["Weld Pass","Acceptance Criteria","Observation","Results"]}
                array={Liquid_Penetrant_Examination}
                setheaderObj={setheaderObj}
                disabled={Liquid_Penetrant_Examination_Disabled}
                numberofrowsdisabled={1}
                setError={setError}
                name={["PQR_LPEX_WeldPass","PQR_LPEX_Acceptance_Criteria","PQR_LPEX_Observation","PQR_LPEX_Results"]}
                status={[error.PQR_LPEX_WeldPass.status,error.PQR_LPEX_Acceptance_Criteria.status,error.PQR_LPEX_Observation.status,error.PQR_LPEX_Results.status]}
                message={[error.PQR_LPEX_WeldPass.message,error.PQR_LPEX_Acceptance_Criteria.message,error.PQR_LPEX_Observation.message,error.PQR_LPEX_Results.message]}
                handleChangetestarray={
                  handleChangeLiquid_Penetrant_Examinationarray
                }
              />
            </Col>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_LPEX_Notes"
                type="text"
                maxLength={100}
                status={error.PQR_LPEX_Notes.status}
                value={headerObj?.PQR_LPEX_Notes}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_LPEX_Notes.message}
                setError={setError}
                error={error} 
              ></Single_Input>
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <p className="WPS-blue-text " style={{position:"relative" , top:"10px"}}>Macro Examination </p>
            <Col md={6}>
              <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Macro_Examination1}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MEX_Tube_No","PQR_MEX_LocA","PQR_MEX_LocB","PQR_MEX_LocC","PQR_MEX_LocD"]}
                table="Table6"
                index={0}
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}

                numberofrowsdisabled={0}
                status={[error.PQR_MEX_Tube_No0.status,error.PQR_MEX_LocA0.status,error.PQR_MEX_LocB0.status,error.PQR_MEX_LocC0.status,error.PQR_MEX_LocD0.status]}
                message={[error.PQR_MEX_Tube_No0.message,error.PQR_MEX_LocA0.message,error.PQR_MEX_LocB0.message,error.PQR_MEX_LocC0.message,error.PQR_MEX_LocD0.message]}
                handleChangetestarray={handleChangeMacro_Examinationarray}
              />
            </Col>

            <Col md={6}>
              <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Macro_Examination2}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MEX_Tube_No","PQR_MEX_LocA","PQR_MEX_LocB","PQR_MEX_LocC","PQR_MEX_LocD"]}
                table="Table7"
                index={1}
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}
                numberofrowsdisabled={0}
                status={[error.PQR_MEX_Tube_No1.status,error.PQR_MEX_LocA1.status,error.PQR_MEX_LocB1.status,error.PQR_MEX_LocC1.status,error.PQR_MEX_LocD1.status]}
                message={[error.PQR_MEX_Tube_No1.message,error.PQR_MEX_LocA1.message,error.PQR_MEX_LocB1.message,error.PQR_MEX_LocC1.message,error.PQR_MEX_LocD1.message]}
                handleChangetestarray={handleChangeMacro_Examinationarray}
              />
            </Col>

            <Col md={6}>
            <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Macro_Examination3}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MEX_Tube_No","PQR_MEX_LocA","PQR_MEX_LocB","PQR_MEX_LocC","PQR_MEX_LocD"]}
                table="Table8"
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}
                status={[error.PQR_MEX_Tube_No2.status,error.PQR_MEX_LocA2.status,error.PQR_MEX_LocB2.status,error.PQR_MEX_LocC2.status,error.PQR_MEX_LocD2.status]}
                message={[error.PQR_MEX_Tube_No2.message,error.PQR_MEX_LocA2.message,error.PQR_MEX_LocB2.message,error.PQR_MEX_LocC2.message,error.PQR_MEX_LocD2.message]}
                handleChangetestarray={handleChangeMacro_Examinationarray}
              />
            </Col>

            <Col md={6}>
            <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                width  ={245}
                width1 ={245}
                width2 ={245}
                width3 ={245}
                array={Macro_Examination4}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MEX_Tube_No","PQR_MEX_LocA","PQR_MEX_LocB","PQR_MEX_LocC","PQR_MEX_LocD"]}
                table="Table9"
                index={3}
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}
                status={[error.PQR_MEX_Tube_No3.status,error.PQR_MEX_LocA3.status,error.PQR_MEX_LocB3.status,error.PQR_MEX_LocC3.status,error.PQR_MEX_LocD3.status]}
                message={[error.PQR_MEX_Tube_No3.message,error.PQR_MEX_LocA3.message,error.PQR_MEX_LocB3.message,error.PQR_MEX_LocC3.message,error.PQR_MEX_LocD3.message]}
                handleChangetestarray={handleChangeMacro_Examinationarray}
              />
            </Col>

            <Col md={12}>
              <PQR_Page3_Table3
                sessionName={sessionName}
                maxLength1={30}
                maxLength2={30}
                maxLength3={40}
                array={Macro_Examination5}
                setheaderObj={setheaderObj}
                setError={setError}
                title1="Magnification"
                title2="Acceptance Criteria"
                title3="Observation"
                name1="PQR_MEX_Magnification"
                name2="PQR_MEX_Acceptance_Criteria"
                name3="PQR_MEX_Observation"
                table="Table10"
                status={error.PQR_MEX_Magnification.status}
                message={error.PQR_MEX_Magnification.message}
                status1={error.PQR_MEX_Acceptance_Criteria.status}
                message1={error.PQR_MEX_Acceptance_Criteria.message}
                status2={error.PQR_MEX_Observation.status}
                message2={error.PQR_MEX_Observation.message}
                status3={error.PQR_WP_Comments.status}
                message3={error.PQR_WP_Comments.message}
                status4={error.PQR_WP_Report_Nummber.status}
                message4={error.PQR_WP_Report_Nummber.message}
                handleChangetestarray={handleChangeMacro_Examinationarray}
              />
            </Col>
          </Row>
        </Row>

        <Row style={{ padding: "0px 60px", position:"relative",bottom:"30px" }}>
          <span className="WPS-blue-text" style={{position:"relative" , top:"10px", right:"10px"}}>Result</span>
          <Row className="mb-1">
            <Col md={12}>
            <Single_Input
                disabled={copydata}
                name="PQR_MEX_Result"
                type="text"
                maxLength={20}
                status={error.PQR_MEX_Result.status}
                value={headerObj?.PQR_MEX_Result}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_MEX_Result.message}
                setError={setError}
                error={error} 
              ></Single_Input>
           
            </Col>
          </Row>
        </Row>

        <Row style={{padding: "0px 60px" }} className="PQR-Page-row-padding"> 
          <Row className="mb-1">
            <p className="WPS-blue-text " style={{position:"relative" , top:"10px"}}>Minimum Leak Path </p>

            {/* ////////////////////////2nd table Start ////////////////////////////////////////////*/}
            <Col md={6}>

              <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                array={Minimum_Leak_Path1}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MLP_Tube_No","PQR_MLP_LocA","PQR_MLP_LocB","PQR_MLP_LocC","PQR_MLP_LocD"]}
                table="Table1"
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}
                status={[error.PQR_MLP_Tube_No.status,error.PQR_MLP_LocA.status,error.PQR_MLP_LocB.status,error.PQR_MLP_LocC.status,error.PQR_MLP_LocD.status]}
                message={[error.PQR_MLP_Tube_No.message,error.PQR_MLP_LocA.message,error.PQR_MLP_LocB.message,error.PQR_MLP_LocC.message,error.PQR_MLP_LocD.message]}
                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>

            <Col md={6}>
            <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                array={Minimum_Leak_Path2}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MLP_Tube_No","PQR_MLP_LocA","PQR_MLP_LocB","PQR_MLP_LocC","PQR_MLP_LocD"]}
                table="Table2"
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}

                status={[error.PQR_MLP_Tube_No1.status,error.PQR_MLP_LocA1.status,error.PQR_MLP_LocB1.status,error.PQR_MLP_LocC1.status,error.PQR_MLP_LocD1.status]}
                message={[error.PQR_MLP_Tube_No1.message,error.PQR_MLP_LocA1.message,error.PQR_MLP_LocB1.message,error.PQR_MLP_LocC1.message,error.PQR_MLP_LocD1.message]}
                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>

            <Col md={6}>
            <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                array={Minimum_Leak_Path3}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MLP_Tube_No","PQR_MLP_LocA","PQR_MLP_LocB","PQR_MLP_LocC","PQR_MLP_LocD"]}
                table="Table3"
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}
 
                status={[error.PQR_MLP_Tube_No2.status,error.PQR_MLP_LocA2.status,error.PQR_MLP_LocB2.status,error.PQR_MLP_LocC2.status,error.PQR_MLP_LocD2.status]}
                message={[error.PQR_MLP_Tube_No2.message,error.PQR_MLP_LocA2.message,error.PQR_MLP_LocB2.message,error.PQR_MLP_LocC2.message,error.PQR_MLP_LocD2.message]}
                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>

            <Col md={6}>
            <PQR_Page3_Table_ColumnNindex
                columns={5}
                sessionName={sessionName}
                maxLength={[3,4,4,4,4]}
                array={Minimum_Leak_Path4}
                setheaderObj={setheaderObj}
                setError={setError}
                name={["PQR_MLP_Tube_No","PQR_MLP_LocA","PQR_MLP_LocB","PQR_MLP_LocC","PQR_MLP_LocD"]}
                table="Table4"
                title={["Tube No.","Loc A","Loc B","Loc C","Loc D"]}

                status={[error.PQR_MLP_Tube_No3.status,error.PQR_MLP_LocA3.status,error.PQR_MLP_LocB3.status,error.PQR_MLP_LocC3.status,error.PQR_MLP_LocD3.status]}
                message={[error.PQR_MLP_Tube_No3.message,error.PQR_MLP_LocA3.message,error.PQR_MLP_LocB3.message,error.PQR_MLP_LocC3.message,error.PQR_MLP_LocD3.message]}
                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>

            {/* ////////////////////////3nd table ////////////////////////////////////////////*/}

            <Col md={12}>
              <PQR_Page3_Table3
                sessionName={sessionName}
                maxLength1={30}
                maxLength2={30}
                maxLength3={40}
                array={Minimum_Leak_Path5}
                setheaderObj={setheaderObj}
                setError={setError}
                title1="Magnification"
                title2="Acceptance Criteria"
                title3="Observation"
                name1="PQR_MLP_Magnification"
                name2="PQR_MLP_Acceptance_Criteria"
                name3="PQR_MLP_Observation"
                table="Table5"
                status={error.PQR_MLP_Magnification.status}
                message={error.PQR_MLP_Magnification.message}
                status1={error.PQR_MLP_Acceptance_Criteria.status}
                message1={error.PQR_MLP_Acceptance_Criteria.message}
                status2={error.PQR_MLP_Observation.status}
                message2={error.PQR_MLP_Observation.message}
                handleChangetestarray={handleChangeMinimum_Leak_Patharray}
              />
            </Col>
          </Row>
          <Row style={{ padding: "0px 10px" }}>
          <span className="WPS-blue-text" style={{position:"relative" , top:"10px", right:"10px"}}>Result</span>
          <Row className="mb-1">
            <Col md={12}>
            <Single_Input
                disabled={copydata}
                name="PQR_MLP_Result"
                type="text"
                maxLength={40}
                status={error.PQR_MLP_Result.status}
                value={headerObj?.PQR_MLP_Result}
                onChange={handleChangeHeader}
                label="Notes"
                message={error.PQR_MLP_Result.message}
                setError={setError}
                error={error} 
              ></Single_Input>
           
            </Col>
          </Row>
        </Row>
        </Row>

     
      </Row>
    );
  }
);

export default PQRTubesheetPage2;
