import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,

  useEffect,
} from "react";
import SuccessAlertModal from "../../Components/Modals/successAlertModal";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";

import GlobalColumnDefinations , { getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import AddEditWIPage from "./AddEditWIPage";
import {
  CancelSlip,
  DeleteSlip,
  getallWelderid,
  getallwelders,
  getallWeldersearch,
  UpdateSlip,
} from "../../Services/WI-services";
import WI_PDF_Download from "./PDF/WI_PDF_Download";
import ActionRenderer_WI from "./ActionRenderer_WI";
import { getdetailsById } from "../../Services/wps_details-service";
import { BarLoader, BeatLoader, BounceLoader, ClockLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PropagateLoader, SyncLoader } from "react-spinners";
import Avatar from "react-avatar";

const WIGridPage = forwardRef((props, ref) => {
  let formName = "WI Slip";

  const [loading, setLoading] = useState(true);

  const [rowData, setRowData] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [widata, setwidata] = useState(null);

  const [showForm, setShowForm] = useState(false);
  const [wpsde, setwpsde] = useState({});

  const [showFormadd, setShowFormadd] = useState(false);

  const [cancel, setCancel] = useState(false);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);


  const [excelData1, setExcelData] = useState([]);


  const childRef = useRef();

  const tmpcolumnDefs = [];


  const handleCancelEvent = async (row) => {
    try {
      console.log("ddsDasad",row);
      // setAction("Edit");
      setRowData(row);
      // setShowFormadd(true);
      row.WI_Slip_Status = "Cancelled";
      await CancelSlip(row);
      toast.success(`Selected Slip is Cancelled`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if(cancel === false){
        setCancel(true);
        }else{
          setCancel(false);
        }      // window.location.reload();
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  const handlepdf = async (row) => {
    

    console.log("gggg",row)


    const resultWI = await getallWelderid(row?.SK);
    console.log("44441", resultWI[0]);


    const resultWPS = await getdetailsById(row?.WI_Project_No, resultWI[0]?.WI_WPS_No);
    console.log("4444", resultWPS[0]);
    setwpsde(resultWPS[0]);

    const combinedData = [resultWI[0], ...resultWPS];
    setRowData(combinedData);

    UpdateSlip(row);

   

    console.log("rowwwww", row?.WI_print);
    setAction("pdf");
    // setRowData(row);
    setShowForm(true);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchwidata = async () => {
    setLoading(true); // Start loading
    
    setTimeout(async () => {
      const result = await getallwelders();
      console.log("reeeeeew", result);
      setwidata(result);
      setFilteredResult(result);
      setLoading(false); // End loading
    }, 2000); // Delay of 2 seconds
  };


  console.log("dataaaaa", widata);

  console.log("dataaaaawps", wpsde);

  const fetchColumnDefns = async (params) => {
    
    console.log("paramssssssssss", params);
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({
      headerName: "Project Number",
      field: "WI_Project_No",
      type: "Text",
      isCheckbox: false,
    });

    result.push({
      headerName: "Joint Number",
      field: "WI_Joint_No",
      type: "Text",
      isCheckbox: false,
    });
    result.push({
      headerName: "Welder Code",
      field: "WI_Welder_Id",
      type: "Text",
      isCheckbox: false,
    });
    result.push({
      headerName: "Welder Name",
      field: "WI_Welder_Name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: AvatarRenderer,
      

    });
    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      Width: 250,
      suppressMenu: true,
      sortable:false,
    });

    setcolumnDefs(result);
  };

  console.log("rowData",rowData, rowData[1]);

  useEffect(() => {
    
    fetchwidata();
    fetchColumnDefns();
  }, [reload,cancel]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowFormadd(true);
 
    },
  }));
  console.log("Reloaddddd",reload)


  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(widata);
  };

  const [showSuccess, setShowSuccess] = useState(false);
 const msg = "";
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setWeldingProcess",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };


  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);
 
  };
 

  console.log("filteredResultii", filteredResult);

  const handleGlobalExport = () => {
    
    let filename = "WI";
    console.log("filteredResultiiww", filteredResult);
    setExcelData(filteredResult);
     handleExport1(excelData1, filename, columnDefs);
  };

  const actionRenderer = (params) => {
    console.log("kakakaka", params.data.WI_Slip_Status);
    return (
      <>
        {params.data.WI_24hrs === "Completed" ? (
          ""
        ) : (
          <ActionRenderer_WI
            selectedData={params}
            handleCancelEvent={(params) => handleCancelEvent(params.data)}
         
            handleDeleteEvent={(params) => handleDeleteEvent(params.data)}
            handlePdfEvent={(params) => handlepdf(params.data)}
          />
        )}
      </>
    );
  };

 
  const AvatarRenderer = (params) => {
    const colors = ["avatarcolor1", "avatarcolor2", "avatarcolor3"]; // Define the available colors
    const name = params.data.WI_Welder_Name;
    const index = params.rowIndex; // Get the index of the current row
  
    // Calculate the index of the color based on the row index
    const colorIndex = index % colors.length;
    const selectedColor = colors[colorIndex];
  
    return (
      <>
        <Avatar
          className={selectedColor}
          maxInitials={2}
          name={name}
          round={true}
          marginTop="120"
          size="35"
        />
        &nbsp;&nbsp;
        <span>{name}</span>
      </>
    );
  };

  const handleDeleteEvent = async (row) => {
    try {
      console.log(row);
      setRowData(row);
      row.WI_Slip_Status = "Deleted";
      await DeleteSlip(row);
      toast.success(`Selected Slip is Deleted`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if(cancel === false){
      setCancel(true);
      }else{
        setCancel(false);
      }
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  console.log("cannnnnnnnnc",cancel)

  // useEffect(() => {
  //   if (reload) {
  //     setReload(false);
  //   }
  // }, [reload]);

  return (
    <>
    {loading && (
  <div className="loader-overlay">
    <GridLoader size={10} className="pageloader" />
  </div>
)}
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        //handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
   
            <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          WI list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              filterFunc={handleGlobalSearch}
              searchplaceholdertext={
                "Search for Project Number, Joint Number, Welder Code, Welder Name"
              }
            />
            {/* <img
                className="allexport"
                src={Export}
                alt="Export"
                onClick={handleGlobalExport}
              /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

<GridMaster
    //  onGridReady={onGridReady}
    rowDetails={filteredResult}
    colDetails={columnDefs}
    fieldNames={fieldName}
    headerNames={headerName}
   
    setExcelData={setExcelData}
    ref={childRef}
  />

      {showForm && (
        <WI_PDF_Download
          show={showForm}
          action={action}
          rowdata={rowData}
          wpsde={wpsde}
          onHide={() => setShowForm(false)}
          // fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          // userObj={userObj}
          widata={widata}
          backdrop="static"
        />
      )}

      {showFormadd && (
        <AddEditWIPage
          show={showFormadd}
          action={action}
          rowdata={rowData}
          onHide={() => setShowFormadd(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          // fetchUserMaster={fetchWeldingProcess}
          allRecs={widata}
          backdrop="static"
        />
      )}
      <Toaster />
    </Container>
    </>
  );
});

export default WIGridPage;
