
import "../Css/WPQgroove_pdf.css"

import Groove_pdf_header_common from "../../../WPSTransaction/PDF/Pages/Groove_pdf_header_common"



const Groove_pdf_headerWPQ = ({pdf, from,Page}) => {
    const currentDate = new Date(pdf[0]?.WPQ_Date);
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
   const formattedDate = `${day}-${month}-${year}`;

   const currentDOB = new Date(pdf[0]?.WPQ_DOB);
   const dayDOB = currentDOB.getDate();
   const monthDOB = currentDOB.getMonth() + 1;
   const yearDOB = currentDOB.getFullYear();
  const formattedDOB = `${dayDOB}-${monthDOB}-${yearDOB}`;


   

   
 return(
       <header>
        <Groove_pdf_header_common currentDate={currentDate} from={from}></Groove_pdf_header_common>
    


        <table id="table" className="WPQheaderTable1" >
            <tbody>
                <tr >
                        <th style={{width:"22%"}}> 
                            Welder Name
                            <br>
                            </br>  
                            ID Number
                           {Page == "1" && <><br>
                            </br>  
                            Date of Birth
                            <br>
                            </br>  
                            Welded on coupon
                            <br>
                            </br>  
                            Company Name
                           </>}
                        </th>   
                    <td style={{width:"27%"}} className="pdfTopBottom">
                    
                    
                        <span className="WPQforfont" style={{float:"left"}} >{pdf[0]?.WPQ_WelderName}</span>
                        <br>
                            </br>
                        <span className="WPQforfont"  style={{float:"left"}}>  {pdf[0]?.WPQ_WelderId}</span>
                        {Page == 1 && <><br>
                        </br>
                        <span className="WPQforfont"  style={{float:"left"}}>  {formattedDOB}</span>
                        <br>
                        </br>
                        <span className="WPQforfont"  style={{float:"left"}}>  {pdf[0]?.WPQ_Welded_on_coupon}</span>
                        <br>
                        </br>
                        <span className="WPQforfont"  style={{float:"left"}}>  {pdf[0]?.WPQ_CompanyName == undefined ? "--" : pdf[0]?.WPQ_CompanyName}
                         </span>
                        </> }
                    </td>
                   
                    <th className="" style={{width:"20%"}} >
                            Welding Date
                            <br></br>
                            WPQ record Number
                            {Page == "1" && <>
                            <br></br>
                            WPS record Number 
                            <br></br>
                            Qualification Code
                            <br></br>
                            Division </>}
                    </th>

                    <td style={{width:"32%"}} className="pdfnoleft">
                    {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
                        <br></br>
                        {pdf[0]?.WPQ_RecordNo}
                        {Page == "1" && <>
                        <br></br>
                        {pdf[0]?.WPQ_Wps_RecordNo}  
                        <span className="WPSforfont" style={{ float: "right" }}>
                        Revision: {pdf[0]?.WPQ_ReviNo} </span>
                        <br></br>
                        {pdf[0]?.WPQ_Qualificationcode} 
                        <br></br>
                        {pdf[0]?.WPQ_Division} </>}
                    </td>
            </tr>
               
                
               
           
            

            </tbody>
            
        </table>

</header> )
}
export default Groove_pdf_headerWPQ
