
import {
  AddNewPQR,
  UpdatePQRHeader,
} from "../../../Services/pqr-header-service";

import { Action, MessageTypes } from "./PQR_entity";

export const checkPQRProcessAdded = (detailObj) => {
  var norows = true;

  for (var i = 0; i < 4; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;

    if (detailObj[i].PQR_Process !== "" && detailObj[i].PQR_Process !== "NA") {
      norows = false;

      if (detailObj[i].PQR_Type === "") {
        detailObj[i].errormessage = "Type is required";
        detailObj[i].error = true;
        norows = true;
      }
    }
  }
  return norows;
};

export const checkProcessAdded = (detailObj) => {
  var norows = true;

  for (var i = 0; i < 2; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;

    if (detailObj[i].PQR_Process !== "" && detailObj[i].PQR_Process !== "NA") {
      norows = false;

      if (detailObj[i].PQR_Type === "") {
        detailObj[i].errormessage = "Type is required";
        detailObj[i].error = true;
        norows = true;
      }
    }

    
  }
  return norows;
};

export const checkTestAdded = (array) => {
  var norows = true;

  for (var i = 0; i < 5; i++) {
    array[i].errormessage = "";
    array[i].error = false;
    

    if (
      array[i].WPQ_WP_TypeOfTest !== "" ||
      array[i].WPQ_WP_Acceptance_Criteria !== "" ||
      array[i].WPQ_WP_Result !== "" ||
      array[i].WPQ_WP_Comments !== "" ||
      array[i].WPQ_WP_Report_Nummber !== ""
    ) {
      norows = false;

      if (array[i].WPQ_WP_TypeOfTest === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Acceptance_Criteria === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Result === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Comments === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
      if (array[i].WPQ_WP_Report_Nummber === "") {
        array[i].errormessage = "All Fields are required";
        array[i].error = true;
        norows = true;
        return norows;
      }
    }
  }

  return norows;
};

export const check_Pqr_Pag3_VEX = (Visual_Examination, rowCount) => {

  var norows = 0;


  for (var i = 0; i < rowCount -1; i++) {
    Visual_Examination[i].errormessage = "";
    Visual_Examination[i].error = false;
    

    if (
      Visual_Examination[i].PQR_VEX_WeldPass !== "" ||
      Visual_Examination[i].PQR_VEX_Acceptance_Criteria !== "" ||
      Visual_Examination[i].PQR_VEX_Observation !== "" ||
      Visual_Examination[i].PQR_VEX_Results !== ""
    ) {
      

      if (Visual_Examination[i].PQR_VEX_WeldPass === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        
        return norows;
      }
      if (Visual_Examination[i].PQR_VEX_Acceptance_Criteria === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        
        return norows;
      }
      if (Visual_Examination[i].PQR_VEX_Observation === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        
        return norows;
      }
      if (Visual_Examination[i].PQR_VEX_Results === "") {
        Visual_Examination[i].errormessage = "All Fields are required";
        Visual_Examination[i].error = true;
        
        return norows;
      }
      norows = norows + 1
    }
  }

  return norows;
};

export const check_Pqr_Pag3_LPEX = (Liquid_Penetrant_Examination) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    Liquid_Penetrant_Examination[i].errormessage = "";
    Liquid_Penetrant_Examination[i].error = false;
    

    if (
      Liquid_Penetrant_Examination[i].PQR_LPEX_WeldPass !== "" ||
      Liquid_Penetrant_Examination[i].PQR_LPEX_Acceptance_Criteria !== "" ||
      Liquid_Penetrant_Examination[i].PQR_LPEX_Observation !== "" ||
      Liquid_Penetrant_Examination[i].PQR_LPEX_Results !== ""
    ) {
      

      if (Liquid_Penetrant_Examination[i].PQR_LPEX_WeldPass === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        
        return norows;
      }
      if (Liquid_Penetrant_Examination[i].PQR_LPEX_Acceptance_Criteria === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        
        return norows;
      }
      if (Liquid_Penetrant_Examination[i].PQR_LPEX_Observation === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        
        return norows;
      }
      if (Liquid_Penetrant_Examination[i].PQR_LPEX_Results === "") {
        Liquid_Penetrant_Examination[i].errormessage =
          "All Fields are required";
        Liquid_Penetrant_Examination[i].error = true;
        
        return norows;
      }
      norows = norows + 1

    }
  }

  return norows;
};

export const check_Pqr_Pag3_MEX = (Macro_Examination1) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    Macro_Examination1[i].errormessage = "";
    Macro_Examination1[i].error = false;
    

    if (
      Macro_Examination1[i].PQR_MEX_Tube_No !== "" ||
      Macro_Examination1[i].PQR_MEX_LocA !== "" ||
      Macro_Examination1[i].PQR_MEX_LocB !== "" ||
      Macro_Examination1[i].PQR_MEX_LocC !== "" ||
      Macro_Examination1[i].PQR_MEX_LocD !== ""
    ) {
      

      if (Macro_Examination1[i].PQR_MEX_Tube_No === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        
        return norows;
      }
      if (Macro_Examination1[i].PQR_MEX_LocA === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        
        return norows;
      }
      if (Macro_Examination1[i].PQR_MEX_LocB === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        
        return norows;
      }
      if (Macro_Examination1[i].PQR_MEX_LocC === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        
        return norows;
      }
      if (Macro_Examination1[i].PQR_MEX_LocD === "") {
        Macro_Examination1[i].errormessage = "All Fields are required";
        Macro_Examination1[i].error = true;
        
        return norows;
      }
      norows = norows + 1
    }
  }

  return norows;
};

export const check_Pqr_Page4_MLP = (Minimum_Leak_Path1) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    Minimum_Leak_Path1[i].errormessage = "";
    Minimum_Leak_Path1[i].error = false;
    

    if (
      Minimum_Leak_Path1[i].WPQ_MLP_Tube_No !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocA !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocB !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocC !== "" ||
      Minimum_Leak_Path1[i].WPQ_MLP_LocD !== ""
    ) {
      

      if (Minimum_Leak_Path1[i].WPQ_MLP_Tube_No === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocA === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocB === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocC === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        
        return norows;
      }
      if (Minimum_Leak_Path1[i].WPQ_MLP_LocD === "") {
        Minimum_Leak_Path1[i].errormessage = "All Fields are required";
        Minimum_Leak_Path1[i].error = true;
        
        return norows;
      }
      norows = norows + 1
    }
  }

  return norows;
};

export const page1characters = (error) => {
  var data = true;

  if (
    error.WPQ_WelderId.status === true ||
    error.WPQ_WelderName.status === true ||
    error.WPQ_Date.status === true ||
    error.WPQ_RecordNo.status === true ||
    error.WPQ_Wps_RecordNo.status === true ||
    error.WPQ_ReviNo.status === true ||
    error.WPQ_Inspection.status === true ||
    error.WPQ_Qualificationcode.status === true
  )
    return data;
};

export const checkduplicateProcess = (detailObj) => {
  var isError = false;
  const unique = detailObj.filter(
    (obj, index) =>
      detailObj.findIndex(
        (item) =>
          item.PQR_Process + "#" + item.PQR_Type ===
          obj.PQR_Process + "#" + obj.PQR_Type
      ) !== index
  );

  if (
    unique.length >= 1 &&
    unique[0].PQR_Process !== "" &&
    unique[0].PQR_Process !== "NA"
  ) {
    detailObj[0].errormessage =
      "Process '" +
      unique[0].PQR_Process +
      "' and Type '" +
      unique[0].PQR_Type +
      "' is  Duplicate";
    isError = true;
    detailObj[0].error = true;
  } else {
    if (!detailObj[0].error) {
      detailObj[0].errormessage = "";
      // isError = false;
      detailObj[0].error = false;
    }
  }
  return isError;
};

export const checkFirstPageValidation = (
  radio,
  ANo,
  ChemicalAnalysis_TradeName,
  Classification,
  FNo,
  SFA,
  errObj,
  seterrobj
) => {
  if (
    radio === "Required" &&
    (ANo === "" ||
      ChemicalAnalysis_TradeName === "" ||
      Classification === "" ||
      FNo === "" ||
      SFA === "" ||
      ANo === undefined ||
      ChemicalAnalysis_TradeName === undefined ||
      Classification === undefined ||
      FNo === undefined ||
      SFA === undefined)
  ) {

    seterrobj((result) => [
      ...result,
      {
        id: "all",
        msg: "All fields are Mandatory",
        className: "border radioInput error",
      },
    ]);
  } else {

    seterrobj((result) => result.filter((i) => i.id !== "all"));
  }
};


export const checkPQRTubeProcessAdded = (detailObj) => {

  var norows = true;

  detailObj.errormessage = "";
  detailObj.error = false;

  if (detailObj.PQR_Process !== "" && detailObj.PQR_Process !== "NA") {
    norows = false;

    if (detailObj.WPSD_FM_FNo === "") {
      detailObj.errormessage = "FNo is required";
      detailObj.error = true;
      
    }

    if (detailObj.WPSD_FM_SFA === "") {
      detailObj.errormessage = "SFA is required";
      detailObj.error = true;
      norows = true;
    }
  }

  

  return norows;
};

export const checkduplicateTubeProcess = (detailObj) => {
  var isError = false;
  const unique = detailObj.filter(
    (obj, index) =>
      detailObj.findIndex(
        (item) =>
          item.WPQ_Process + "#" + item.WPSD_FM_SFA ===
          obj.WPQ_Process + "#" + obj.WPSD_FM_SFA
      ) !== index
  );

  if (
    unique.length >= 1 &&
    unique[0].WPQ_Process !== "" &&
    unique[0].WPQ_Process !== "NA"
  ) {
    detailObj[0].errormessage =
      "Process '" +
      unique[0].WPQ_Process +
      "' and WPSD_FM_SFA '" +
      unique[0].WPSD_FM_SFA +
      "' is  Duplicate";
    isError = true;
    detailObj[0].error = true;
  } else {
    if (!detailObj[0].error) {
      detailObj[0].errormessage = "";
      detailObj[0].error = false;
    }
  }
  return isError;
};

export const check_Pqr_Page4_Tear = (Tear_Test) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    Tear_Test[i].errormessage = "";
    Tear_Test[i].error = false;
    

    if (
      Tear_Test[i].PQR_Tear_TubeNo !== "" ||
      Tear_Test[i].PQR_Tear_Observation !== "" ||
      Tear_Test[i].PQR_Tear_Results !== ""
    ) {
      

      if (Tear_Test[i].PQR_Tear_TubeNo === "") {
        Tear_Test[i].errormessage = "All Fields are required";
        Tear_Test[i].error = true;
        
        return norows;
      }
     
      if (Tear_Test[i].PQR_Tear_Observation === "") {
        Tear_Test[i].errormessage = "All Fields are required";
        Tear_Test[i].error = true;
        
        return norows;
      }
      if (Tear_Test[i].PQR_Tear_Results === "") {
        Tear_Test[i].errormessage = "All Fields are required";
        Tear_Test[i].error = true;
        
        return norows;
      }
      norows = norows + 1

    }
  }

  return norows;
};

export const check_Pqr_Page4_PullOut = (PullOut_Test) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    PullOut_Test[i].errormessage = "";
    PullOut_Test[i].error = false;
    

    if (
      PullOut_Test[i].PQR_PullOut_TubeNo !== "" ||
      PullOut_Test[i].PQR_PullOut_Location !== "" ||
      PullOut_Test[i].PQR_PullOut_Load !== "" ||
      PullOut_Test[i].PQR_PullOut_Fracture !== ""
    ) {
      

      if (PullOut_Test[i].PQR_PullOut_TubeNo === "") {
        PullOut_Test[i].errormessage = "All Fields are required";
        PullOut_Test[i].error = true;
        
        return norows;
      }
      if (PullOut_Test[i].PQR_PullOut_Location === "") {
        PullOut_Test[i].errormessage = "All Fields are required";
        PullOut_Test[i].error = true;
        
        return norows;
      }
      if (PullOut_Test[i].PQR_PullOut_Load === "") {
        PullOut_Test[i].errormessage = "All Fields are required";
        PullOut_Test[i].error = true;
        
        return norows;
      }
      if (PullOut_Test[i].PQR_PullOut_Fracture === "") {
        PullOut_Test[i].errormessage = "All Fields are required";
        PullOut_Test[i].error = true;
        
        return norows;
      }
      norows = norows + 1

    }
  }


  return norows;
};

export const check_Pqr_Page4_PullOut_Table = (PullOut_Test_Table) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    PullOut_Test_Table[i].errormessage = "";
    PullOut_Test_Table[i].error = false;
    

    if (
      PullOut_Test_Table[i].PQR_PullOut_Acceptance_Criteria !== "" ||
      PullOut_Test_Table[i].PQR_PullOut_Observation !== "" ||
      PullOut_Test_Table[i].PQR_PullOut_Results !== ""
    ) {
      

      if (PullOut_Test_Table[i].PQR_PullOut_Acceptance_Criteria === "") {
        PullOut_Test_Table[i].errormessage = "All Fields are required";
        PullOut_Test_Table[i].error = true;
        
        return norows;
      }
      if (PullOut_Test_Table[i].PQR_PullOut_Observation === "") {
        PullOut_Test_Table[i].errormessage = "All Fields are required";
        PullOut_Test_Table[i].error = true;
        
        return norows;
      }
      if (PullOut_Test_Table[i].PQR_PullOut_Results === "") {
        PullOut_Test_Table[i].errormessage = "All Fields are required";
        PullOut_Test_Table[i].error = true;
        
        return norows;
      }
      norows = norows + 1

    }
  }


  return norows;
};

export const check_Pqr_Page4_Hardness = (Hardness_Test) => {

  var norows = 0;

  for (var i = 0; i < 8; i++) {
    Hardness_Test[i].errormessage = "";
    Hardness_Test[i].error = false;
    

    if (
      Hardness_Test[i].PQR_HT_TypeofTest !== "" ||
      Hardness_Test[i].PQR_HT_Tube_No !== "" ||
      Hardness_Test[i].PQR_HT_BM_Tubesheet !== "" ||
      Hardness_Test[i].PQR_HT_HAZ_Tubesheet !== "" ||
      Hardness_Test[i].PQR_HT_Weld !== "" ||
      Hardness_Test[i].PQR_HT_HAZ_Tube !== "" ||
      Hardness_Test[i].PQR_HT_BM_Tube !== "" ||
      Hardness_Test[i].PQR_HT_Results !== ""
    ) {

      if (Hardness_Test[i].PQR_HT_TypeofTest === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_Tube_No === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_BM_Tubesheet === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_HAZ_Tubesheet === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_Weld === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_HAZ_Tube === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_BM_Tube === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      if (Hardness_Test[i].PQR_HT_Results === "") {
        Hardness_Test[i].errormessage = "All Fields are required";
        Hardness_Test[i].error = true;
        return norows;
      }
      norows = norows + 1

    }
  }


  return norows;
};

export const check_Pqr_Page4_Radio = (Radiography_Test) => {

  var norows = 0;

  for (var i = 0; i < 4; i++) {
    Radiography_Test[i].errormessage = "";
    Radiography_Test[i].error = false;

    if (
      Radiography_Test[i].PQR_Radio_TubeNo !== "" ||
      Radiography_Test[i].PQR_Radio_Acceptance_Criteria !== "" ||
      Radiography_Test[i].PQR_Radio_Observation !== "" ||
      Radiography_Test[i].PQR_Radio_Results !== ""
    ) {

      if (Radiography_Test[i].PQR_Radio_TubeNo === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        return norows;
      }
      if (Radiography_Test[i].PQR_Radio_Acceptance_Criteria === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        return norows;
      }
      if (Radiography_Test[i].PQR_Radio_Observation === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        return norows;
      }
      if (Radiography_Test[i].PQR_Radio_Results === "") {
        Radiography_Test[i].errormessage = "All Fields are required";
        Radiography_Test[i].error = true;
        return norows;
      }
      norows = norows + 1

    }
  }

  return norows;
};

export const check_Pqr_Page4_OT = (Other_Test, rowCount) => {

  var norows = 0;

  for (var i = 0; i < rowCount; i++) {
    Other_Test[i].errormessage = "";
    Other_Test[i].error = false;
    

    if (
      Other_Test[i].PQR_OT_TypeofTest !== "" ||
      Other_Test[i].PQR_OT_Acceptance_Criteria !== "" ||
      Other_Test[i].PQR_OT_Remarks !== "" ||
      Other_Test[i].PQR_OT_Results !== ""
    ) {

      if (Other_Test[i].PQR_OT_TypeofTest === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
    
      }
      if (Other_Test[i].PQR_OT_Acceptance_Criteria === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
    
      }
      if (Other_Test[i].PQR_OT_Remarks === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
        
      }
      if (Other_Test[i].PQR_OT_Results === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
      
      }
      norows = norows +1
    }
  }
 

  return norows;
};


export const PQR_Page2_Tensile = (Tensile_Test, rowCount) => {

  var norows = 0;

  for (var i = 0; i < rowCount-1; i++) {
    Tensile_Test[i].errormessage = "";
    Tensile_Test[i].error = false;
    

    if (
      Tensile_Test[i].PQR_Tensile_SpecimenNo !== "" ||
      Tensile_Test[i].PQR_Tensile_Width !== "" ||
      Tensile_Test[i].PQR_Tensile_Thickness !== "" ||
      Tensile_Test[i].PQR_Tensile_Area !== "" ||
      Tensile_Test[i].PQR_Tensile_UltimateLoad !== "" ||
      Tensile_Test[i].PQR_Tensile_UltimateStress !== "" ||
      Tensile_Test[i].PQR_Tensile_FailureLocation !== ""
    ) {
  

      if (Tensile_Test[i].PQR_Tensile_SpecimenNo === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      if (Tensile_Test[i].PQR_Tensile_Width === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      if (Tensile_Test[i].PQR_Tensile_Thickness === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      if (Tensile_Test[i].PQR_Tensile_Area === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      if (Tensile_Test[i].PQR_Tensile_UltimateLoad === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      if (Tensile_Test[i].PQR_Tensile_UltimateStress === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      if (Tensile_Test[i].PQR_Tensile_FailureLocation === "") {
        Tensile_Test[i].errormessage = "All Fields are required";
        Tensile_Test[i].error = true;

        return norows;
      }
      norows = norows + 1
    }
  }


  return norows;
};

export const PQR_Page2_Certification = (Certification, rowCount) => {
  

  var norows = 0;

  for (var i = 0; i < rowCount; i++) {
    Certification[i].errormessage = "";
    Certification[i].error = false;
    

    if (
      Certification[i].PQR_Certification_Welder_Name !== "" ||
      Certification[i].PQR_Certification_ID !== "" ||
      Certification[i].PQR_Certification_Process !== ""
    ) {

      if (Certification[i].PQR_Certification_Welder_Name === "") {
        Certification[i].errormessage = "All Fields are required";
        Certification[i].error = true;

        return norows;
      }
      if (Certification[i].PQR_Certification_ID === "") {
        Certification[i].errormessage = "All Fields are required";
        Certification[i].error = true;

        return norows;
      }
      if (Certification[i].PQR_Certification_Process === "") {
        Certification[i].errormessage = "All Fields are required";
        Certification[i].error = true;
      return norows;
      }
      norows = norows + 1
    }
  }


  return norows;
};
export const PQR_Page2_CertificationLabRef = (CertificationLabRef, rowCount) => {
  
  var norows = 0;

  for (var i = 0; i < rowCount; i++) {
    CertificationLabRef[i].errormessage = "";
    CertificationLabRef[i].error = false;
    

    if (
      CertificationLabRef[i].PQR_Certification_Lab_RefNo !== ""
    ) {

      if (CertificationLabRef[i].PQR_Certification_Lab_RefNo === "") {
        CertificationLabRef[i].errormessage = "All Fields are required";
        CertificationLabRef[i].error = true;

        return norows;
      }
      norows = norows + 1
    }
  }


  return norows;
};
export const PQR_Page2_Other_test = (Other_Test, rowCount) => {
  

  var norows = 0;

  for (var i = 0; i < rowCount; i++) {
    Other_Test[i].errormessage = "";
    Other_Test[i].error = false;
    

    if (
      Other_Test[i].PQR_OT_TypeofTest !== "" ||
      Other_Test[i].PQR_OT_Acceptance_Criteria !== "" ||
      Other_Test[i].PQR_OT_Results !== "" ||
      Other_Test[i].PQR_OT_Remarks !== ""
    ) {

      if (Other_Test[i].PQR_OT_TypeofTest === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;

        return norows;
      }
      if (Other_Test[i].PQR_OT_Acceptance_Criteria === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;

        return norows;
      }
      if (Other_Test[i].PQR_OT_Results === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
      return norows;
      }
      if (Other_Test[i].PQR_OT_Remarks === "") {
        Other_Test[i].errormessage = "All Fields are required";
        Other_Test[i].error = true;
      return norows;
      }
      norows = norows + 1
    }
  }


  return norows;
};
export const PQR_Page2_Other_test_overlay = (Other_Test_overlay, rowCount) => {
  

  var norows = 0;

  for (var i = 0; i < rowCount; i++) {
    Other_Test_overlay[i].errormessage = "";
    Other_Test_overlay[i].error = false;
    

    if (
      Other_Test_overlay[i].PQR_OT_TypeofTest !== "" ||
      Other_Test_overlay[i].PQR_OT_Acceptance_Criteria !== "" ||
      Other_Test_overlay[i].PQR_OT_Results !== "" ||
      Other_Test_overlay[i].PQR_OT_Remarks !== ""
    ) {

      if (Other_Test_overlay[i].PQR_OT_TypeofTest === "") {
        Other_Test_overlay[i].errormessage = "All Fields are required";
        Other_Test_overlay[i].error = true;

        return norows;
      }
      if (Other_Test_overlay[i].PQR_OT_Acceptance_Criteria === "") {
        Other_Test_overlay[i].errormessage = "All Fields are required";
        Other_Test_overlay[i].error = true;

        return norows;
      }
      if (Other_Test_overlay[i].PQR_OT_Results === "") {
        Other_Test_overlay[i].errormessage = "All Fields are required";
        Other_Test_overlay[i].error = true;
      return norows;
      }
      if (Other_Test_overlay[i].PQR_OT_Remarks === "") {
        Other_Test_overlay[i].errormessage = "All Fields are required";
        Other_Test_overlay[i].error = true;
      return norows;
      }
      norows = norows + 1
    }
  }


  return norows;
};
export const PQR_Page2_Guided = (GuidedBend_Test, rowCount) => {
  

  var norows = 0;

  for (var i = 0; i < rowCount; i++) {
    GuidedBend_Test[i].errormessage = "";
    GuidedBend_Test[i].error = false;
    

    if (
      GuidedBend_Test[i].PQR_GB_TypeofTest !== "" ||
      GuidedBend_Test[i].PQR_GB_Acceptance_Criteria !== "" ||
      GuidedBend_Test[i].PQR_GB_Results !== "" ||
      GuidedBend_Test[i].PQR_GB_Remarks !== ""
    ) {

      if (GuidedBend_Test[i].PQR_GB_TypeofTest === "") {
        GuidedBend_Test[i].errormessage = "All Fields are required";
        GuidedBend_Test[i].error = true;

        return norows;
      }
      if (GuidedBend_Test[i].PQR_GB_Acceptance_Criteria === "") {
        GuidedBend_Test[i].errormessage = "All Fields are required";
        GuidedBend_Test[i].error = true;

        return norows;
      }
      if (GuidedBend_Test[i].PQR_GB_Results === "") {
        GuidedBend_Test[i].errormessage = "All Fields are required";
        GuidedBend_Test[i].error = true;
      return norows;
      }
      if (GuidedBend_Test[i].PQR_GB_Remarks === "") {
        GuidedBend_Test[i].errormessage = "All Fields are required";
        GuidedBend_Test[i].error = true;

        return norows;
      }
      norows = norows + 1
    }
  }


  return norows;
};


export const PQR_Page2_Toughness = (Toughness_Test, rowCount) => {
  

  var norows = 0;
  
  for (var i = 0; i < rowCount-1; i++) {
    Toughness_Test[i].errormessage = "";
    Toughness_Test[i].error = false;
    









    if (
      Toughness_Test[i].PQR_TT_SpecimenNo !== "" ||
      Toughness_Test[i].PQR_TT_NotchLocation !== "" ||
      Toughness_Test[i].PQR_TT_NotchType !== "" ||
      Toughness_Test[i].PQR_TT_SpecimenSize !== "" ||
      Toughness_Test[i].PQR_TT_TestTemp !== "" ||
      Toughness_Test[i].PQR_TT_ImpactValues !== "" ||
      Toughness_Test[i].PQR_TT_Avg !== "" ||
      Toughness_Test[i].PQR_TT_LateralExpansion !== "" ||
      Toughness_Test[i].PQR_TT_Shear !== "" 

    ) {
     
      
      
      if (Toughness_Test[i].PQR_TT_SpecimenNo === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
       
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_NotchLocation === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
       
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_NotchType === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
       
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_SpecimenSize === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
      
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_TestTemp === "") {
        
        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
      
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_ImpactValues === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
     
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_Avg === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
        //norows = true;
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_LateralExpansion === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
        return norows;
      }
      if (Toughness_Test[i].PQR_TT_Shear === "") {

        Toughness_Test[i].errormessage = "All Fields are required";
        Toughness_Test[i].error = true;
   
        return norows;
      }
      norows = norows + 1

    }

    
  
  }


  return norows;
};

export const PQR_Groove_Page2_Hardness = (Hardness_Test_Groove, rowCount) => {

  var norows = true;

  for (var i = 0; i < rowCount; i++) {
    Hardness_Test_Groove[i].errormessage = "";
    Hardness_Test_Groove[i].error = false;
    

    if (
      Hardness_Test_Groove[i].PQR_HT_Type !== "" ||
      Hardness_Test_Groove[i].PQR_HT_Location !== "" ||
      Hardness_Test_Groove[i].PQR_HT_ParentMetal_Left !== "" ||
      Hardness_Test_Groove[i].PQR_HT_HAZ_Left !== "" ||
      Hardness_Test_Groove[i].PQR_HT_Weld !== "" ||
      Hardness_Test_Groove[i].PQR_HT_HAZ_Right !== "" ||
      Hardness_Test_Groove[i].PQR_HT_ParentMetal_Right !== "" ||
      Hardness_Test_Groove[i].PQR_HT_Remarks !== ""
    ) {
      norows = false;

      if (Hardness_Test_Groove[i].PQR_HT_Type === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_Location === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_ParentMetal_Left === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_HAZ_Left === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_Weld === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_HAZ_Right === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_ParentMetal_Right === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
      if (Hardness_Test_Groove[i].PQR_HT_Remarks === "") {
        Hardness_Test_Groove[i].errormessage = "All Fields are required";
        Hardness_Test_Groove[i].error = true;
        norows = true;
        return norows;
      }
    }
  }


  return norows;
};

export const PQR_Page2_Guided_Overlay = (GuidedBend_Test_Overlay, rowCount) => {

  var norows = true;

  for (var i = 0; i < rowCount; i++) {
    GuidedBend_Test_Overlay[i].errormessage = "";
    GuidedBend_Test_Overlay[i].error = false;
    

    if (
      GuidedBend_Test_Overlay[i].PQR_GBV_SpecimenNo !== "" &&
      GuidedBend_Test_Overlay[i].PQR_GBV_Thickness !== "" &&
      GuidedBend_Test_Overlay[i].PQR_GBV_BM_WeldOverlay !== "" &&
      GuidedBend_Test_Overlay[i].PQR_GBV_FormerDiam !== "" &&
      GuidedBend_Test_Overlay[i].PQR_GBV_BendAngle !== "" &&
      GuidedBend_Test_Overlay[i].PQR_GBV_Acceptance_Criteria !== "" &&
      GuidedBend_Test_Overlay[i].PQR_GBV_Remarks !== ""
    ) {
      norows = false;

      if (GuidedBend_Test_Overlay[i].PQR_GBV_SpecimenNo === "") {
        GuidedBend_Test_Overlay[i].errormessage = "All Fields are required";
        GuidedBend_Test_Overlay[i].error = true;
        norows = true;
        return norows;
      }
      if (GuidedBend_Test_Overlay[i].PQR_GBV_BM_WeldOverlay === "") {
        GuidedBend_Test_Overlay[i].errormessage = "All Fields are required";
        GuidedBend_Test_Overlay[i].error = true;
        norows = true;
        return norows;
      }
      if (GuidedBend_Test_Overlay[i].PQR_GBV_FormerDiam === "") {
        GuidedBend_Test_Overlay[i].errormessage = "All Fields are required";
        GuidedBend_Test_Overlay[i].error = true;
        norows = true;
        return norows;
      }
      if (GuidedBend_Test_Overlay[i].PQR_GBV_BendAngle === "") {
        GuidedBend_Test_Overlay[i].errormessage = "All Fields are required";
        GuidedBend_Test_Overlay[i].error = true;
        norows = true;
        return norows;
      }
      if (GuidedBend_Test_Overlay[i].PQR_GBV_Acceptance_Criteria === "") {
        GuidedBend_Test_Overlay[i].errormessage = "All Fields are required";
        GuidedBend_Test_Overlay[i].error = true;
        norows = true;
        return norows;
      }
      if (GuidedBend_Test_Overlay[i].PQR_GB_Remarks === "") {
        GuidedBend_Test_Overlay[i].errormessage = "All Fields are required";
        GuidedBend_Test_Overlay[i].error = true;
        norows = true;
        return norows;
      }
    }
  }


  return norows;
};

/* export const UpdatePQRHeaderComplete = async (postAdd,postList) => {
  debugger
  let results = [],
    checkarray = [];
    for (var eachstatus of postAdd) {

      try{
       let result = await UpdatePQRHeader(eachstatus).catch((error) => {

       });

       alert(result)
       if (result !== undefined) results.push(result);
      }
      catch(e){

      }
     }
  for (var eachTable of postList) {

    let result = await UpdatePQRHeader(eachTable).catch((error) => {

    });

    if (result !== undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }

  if (checkarray.length === 0) {

    return false;
  } else {

    return true;
  }
}; */



export const AddUpdatePQRHeader = async (postAdd, postUpdate, action,setaction) => {
  
  let results = [],
    checkarray = [];
  let addresult

  debugger
  if (action === Action.ActionAdd) {
    for (var reqData of postAdd) {

      let result = AddNewPQR(reqData).catch((error) => {

      });

      if (result !== undefined) 
        addresult = result;

    }
  }
  for (var eachTable of postUpdate) {

    let result = await UpdatePQRHeader(eachTable).catch((error) => {

      return false
    }); 

    if (result !== undefined) results.push(result);
  }
  debugger
  for (let result of results) {
    let count=0
    try{
    let check = await Promise.resolve(result);

    count = count + 1
    checkarray = [...checkarray, check];
    }catch(e){

    }
  }
  let addPqrcheck = await Promise.resolve(addresult);
  //alert(addPqrcheck.message)
  if(addPqrcheck != undefined && addPqrcheck.message == "Success")
    setaction(Action.ActionEdit)

  if(addPqrcheck != undefined) checkarray = [...checkarray, addPqrcheck]

  debugger
  if (checkarray.length === 0) {
 
    return false;
  } 
  else if(checkarray.filter((i) => i.message == MessageTypes.SpecialCharacters).length >0)
    {

      return MessageTypes.SpecialCharacters
    }
  else {

    return true;
  }
};