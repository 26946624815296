import React, { useEffect, useState } from "react";
import {  Form, Modal, Row, Col, Alert } from "react-bootstrap";
import { AddNewRole } from "../../Services/role-service";
import {
  getAllMenu,
  getUserMenu,
} from "../../Services/menu-service";
import SuccessAlertModal from "../Modals/successAlertModal";
import { useNavigate } from "react-router-dom";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Assests/Css/checkbox.css";

import { statusChange } from "../utility/FormControl";
import ButtonModal from "../Modals/buttonModal";

import {

  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";

const AddEditRoleMaster = (props) => {
  if (props.rowdata.rm_status === "Active") {
    props.rowdata.rm_status = true;
  } else if (props.rowdata.rm_status === "Inactive") {
    props.rowdata.rm_status = false;
  }

  let formName = "Roles";

  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);

  const history = useNavigate();
  let action = props.action;
  let roledata = props.roles;
  const [alertMessage, setAlertMessage] = useState("");
  const roleSK  = props.rowdata.SK

  const [usermenu,setusermenu] = useState([]);
  const [allMenu, setAllMenu] = useState([]);
  const[prevusermenu,setprevusermenu] = useState([])
  let cntmenu = 1,
    cntsubmenu = 1;

  const [roleObj, setRoleObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          rm_status: true,
          SK: "",
          rm_name: "",
          rm_roleaccess: [],
        }
  );

  const [error, setError] = useState({
    SK: {},
    rm_name: {},
    rm_roleaccess: {},
  });



  const fetchMenuMaster = async () => {
    if (allMenu.length === 0) {
      const result1 = await getAllMenu();
      const useraccess = []
      let rm_roleaccess = []
      if (action === "Edit") {
        const useraccess = await getUserMenu(props.rowdata.SK,"W");
        useraccess.map((i)=>{
            rm_roleaccess.push(i.SK)
        })
      }
      setAllMenu(result1);
      setusermenu([...rm_roleaccess])
      setprevusermenu([...rm_roleaccess])
    }
  };

  useEffect(() => {
    fetchMenuMaster();

  }, []);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setUser",
    });
  };
  function CreateSessionList() {
    let menus = usermenu;
    let SessionList = "";

    menus.map((menu) => {
      let arr_menu_name = allMenu.filter((i) => i.SK === menu);
      if (arr_menu_name[0] !== undefined) {
        SessionList += arr_menu_name[0].menu_name + ",";
       
      }});
    return SessionList;
  }


  const handleClose = () => {
    setShow(false);
    props.setReload(!props.reload);
    props.onHide();
  };

  const handleAdd = async (e) => {
    let result;

    if (roleObj.rm_status === true) {
      roleObj.rm_status = "Active";
    } else {
      roleObj.rm_status = "Inactive";
    }
    

    roleObj.rm_role_session = CreateSessionList();
    roleObj.rm_roleaccess = usermenu
    if (action === "Add") {
      roleObj.PK = "Roles";
      roleObj.createdon = Date.now().toString();
      roleObj.createdby = sessionStorage.getItem("UserId");

      
    } else if (action === "Edit") {
      roleObj.PK = "Roles";
      roleObj.SK = roleSK
    }
    result = AddNewRole(roleObj);
    
     result.then((resp) => {
      if (resp.message === "Success") {
        
        setShow1(false);
        setShow(false);
        toast.success("Role Added/Modified successfuly", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        props.setReload(!props.reload);
        setShow1(false);
        setShow(false);
        props.onHide();     
        
        
      }}).catch((error) => {
        toast.warning(`User Add/Update Failed:`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        props.setReload(!props.reload);
        setShow(false);
        setShow1(false);
        props.onHide();
      })
     
     
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setRoleObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setError((prevState) => ({
      ...prevState,
      SK: {},
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setRoleObj((prevState) => ({
      ...prevState,
      rm_status: e.target.checked,
    }));
    setError((prevState) => ({
      ...prevState,
    }));
  };

  const resetForm = (e) => {
    if (action === "Add") {
      setRoleObj((prevState) => ({
        ...prevState,
        rm_status: true,
        SK: "",
        rm_name: "",
      }));

      setError((prevState) => ({
        ...prevState,
        SK: {},
        rm_name: {},
        rm_roleaccess: {},
      }));
      setusermenu([])
    } else if (action === "Edit") {
      document.rolemaster.reset();
      setRoleObj((prevState) => ({
        ...prevState,
        rm_status: props.rowdata.rm_status,
        SK: props.rowdata.SK,
        rm_name: props.rowdata.rm_name,
      }));
      setusermenu([...prevusermenu])
      setError((prevState) => ({
        ...prevState,
        SK: {},
        rm_name: {},
      }));

    }
  };

  const SelectAll = (e) => {
    let arr = [];

    setError((prevState) => ({
      ...prevState,
      rm_roleaccess: {},
    }));

    let useraccess = []
    if(e.target.checked)
    {
     allMenu.map((mnu) =>{
        useraccess.push(mnu.SK)
     })
    }
    
    setusermenu([...useraccess])
  };

 
  const SelectParentCheckbox = (e) => {
    let parentid = e.target.value;
    setError((prevState) => ({
      ...prevState,
      rm_roleaccess: {},
    }));
    let usermenuaccess = usermenu
   
      let parentmenu = allMenu.filter((item) => item.menu_parent_id === parentid);
      if(usermenu.filter((rm) =>  rm == parentid).length <= 0 && e.target.checked)
          usermenuaccess.push(parentid)
      else
          usermenuaccess  = usermenuaccess.filter((rm)=> rm != parentid)

      if (parentmenu !== undefined ) {

          parentmenu.map(async(i) => {
            if (e.target.checked === true) {
                if(usermenu.filter((rm) =>  rm == i.SK ).length <= 0) {
                await usermenuaccess.push(parentid+"#"+i.menu_id)
              }
            }
            else
                usermenuaccess  = usermenuaccess.filter((rm)=> rm != i.SK )
          });
        
      }
     setusermenu([...usermenuaccess])
    } 


  const SelectChildCheckbox = (e) => {
    let arr = e.target.value.split("|");
    let parentid, childid;

    setError((prevState) => ({
      ...prevState,
      rm_roleaccess: {},
    }));
    if (arr.length > 1) {
      parentid = arr[1];
      childid = arr[0];

    }
    let useraccess = usermenu
    let exist = usermenu.filter((i) => i == parentid)
    let child = usermenu.filter((i) => i == child)

    if(e.target.checked){
      if(exist.length <= 0)
        useraccess.push(parentid)

      if(child.length <= 0){
        useraccess.push(childid)
      }
    }
    else
    {
      useraccess = useraccess.filter((rm)=> rm != childid)
      let parent = useraccess.filter((rm) => rm.indexOf(parentid) >=0 )
      if(parent.length <=1)
        useraccess = useraccess.filter((rm)=> rm != parentid)
    }
    setusermenu([...useraccess])
  };

  function validateInput() {
    let isError = false;
    if (action === "Add") {
      if (roleObj.SK === "") {
        isError = true;
        error.SK = {
          status: true,
          message: "Role Code is required.",
        };
      }
      if (roleObj.rm_name === "") {
        isError = true;
        error.rm_name = {
          status: true,
          message: "Role Name is required.",
        };
      }

      if (usermenu.length === 0) {
        isError = true;
        error.rm_roleaccess = {
          status: true,
          message: "Please select at least one session",
        };
      }
    }
 

    if (action === "Add") {
      let result = roledata.find(
        (item) => item["SK"].toLowerCase() === roleObj.SK.toLowerCase()
      );
      if (result) {
        isError = true;
        error.SK = {
          status: true,
          message: "Role Code already exists",
        };
      }
      result = roledata.find(
        (item) =>
          item["rm_name"].toLowerCase() === roleObj.rm_name.toLowerCase() &&
          item["PK"].toLowerCase() === roleObj.PK.toLowerCase()
      );

      if (result) {
        isError = true;
        error.rm_name = {
          status: true,
          message: "Role Name already exists",
        };
      }
    } else {
      let result = roledata.find(
        (item) =>
          item["rm_name"].toLowerCase() === roleObj.rm_name.toLowerCase() &&
          item["PK"].toLowerCase() === roleObj.PK.toLowerCase() &&
          item["SK"].toLowerCase() !== roleObj.SK.toLowerCase()
      );

      if (result) {
        isError = true;
        error.rm_name = {
          status: true,
          message: "Role Name already exists",
        };
      }
      if (roleObj.rm_name === "") {
        isError = true;
        error.rm_name = {
          status: true,
          message: "Role Name is required.",
        };
      }

      if (usermenu.length === 0) {
        isError = true;
        error.rm_roleaccess = {
          status: true,
          message: "Please select at least one session",
        };
      }
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    return isError;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    roleObj.PK = "Roles";
    if (!validateInput()) {
   
        setShow1(true);
      
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You’ve ${action}ed Role and Authorization successfully.`}
        show={show}
        handleClose={handleClose}
      />
      <ConfirmAlertModal
        handleYes={handleAdd}
        action={action}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      <Modal
        {...props}
        size="lg"
        className={
          show || show1
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Role And Authorization</Modal.Title>

          <span className="req-input">(All Fields are Mandatory)</span>
        </Modal.Header>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body className="roleStyle">
            <div className="px-2">
              <Row className="gx-3">
                <Col>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        className={
                          error.SK.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        name="SK"
                        id="SK"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={roleObj.SK}
                        onChange={handleChange}
                        disabled={action === "Edit" ? true : false}
                        onBlur={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            SK: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="SK">
                        Role code
                      </label>
                    </div>
                    {error.SK.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "8px" }}>
                          {error.SK.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="rm_name"
                        name="rm_name"
                        type="text"
                        className={
                          error.rm_name.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={roleObj.rm_name}
                        onChange={handleChange}
                        maxLength="50"
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            rm_name: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="rm_name">
                        Role name{" "}
                      </label>
                      {error.rm_name.status && (
                        <Form.Text className="text-danger">
                          {error.rm_name.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        <Form.Label className="mnlabel">Status</Form.Label>{" "}
                      </Col>
                      <Col style={{ paddingTop: "0.5rem" }}>
                        <Form.Switch
                          label={roleObj.rm_status ? "Active" : "Inactive"}
                          className={roleObj.rm_status ? "green" : "gray"}
                          name="rm_status"
                          checked={roleObj.rm_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row className="gx-3 ">
                <Form.Group>
                  <Form.Check
                    label="Select All Menu Item"
                    className="mnlabel"
                    onChange={SelectAll}
                  ></Form.Check>

                  {error.rm_roleaccess.status && (
                    <Form.Text className="text-danger">
                      {error.rm_roleaccess.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Row>
            </div>
            <hr />
            <Row style={{ height: "16rem", overflowX: "auto" }}>
              <Col md={10} width="60%">
                <div className="row">
                  {allMenu.filter((i) => i.menu_parent_id == undefined).map((item, index) => {
                    cntmenu = cntmenu + 1;
                    let menu_children = allMenu.filter((i) => i.menu_parent_id == item.menu_id)
                    return (
                      <div className="column">
                        <Form.Check
                          label={item.menu_name}
                          value={item.menu_id}
                          key={"parent" + { cntmenu }}
                          id={"parent" + cntmenu}
                          className="mnlabel"
                          onChange={SelectParentCheckbox}
                          checked={
                            usermenu.find(
                              (i) => i === item.SK
                            ) !== undefined
                              ? true
                              : false
                          }
                        ></Form.Check>
                        {menu_children &&
                          menu_children.map((child, key) => {
                            cntsubmenu = cntsubmenu + 1;
                            return (
                              <>
                                <Form.Check
                                  label={child.menu_name}
                                  key={"submenu" + cntsubmenu}
                                  id={"submenu" + cntsubmenu}
                                  className="sblabel"
                                  value={child.SK + "|" + item.SK}
                                  onChange={SelectChildCheckbox}
                                  checked={
                                    usermenu.find(
                                      (i) => i === child.SK
                                    ) !== undefined
                                      ? true
                                      : false
                                  }
                                ></Form.Check>
                              </>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          {alertMessage && (
            <Alert className="alertBox" variant="warning">
              {alertMessage}
            </Alert>
          )}
          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>
          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditRoleMaster;
