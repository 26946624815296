import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

export let tableHeader = [{
    A: "",
    B: "Project",
    C: "Slip Date",
    D: "Plant",
    E: "Slip No ",
    F: "Cancelled",
    G: "Joint",
    H: "WPS No",
    I: "Process",
    J: "Type",
    K: "Welder Code",
    L: "Welder Name",
  }]


  export let Welder_Record_tableHeader = [{
    A: "Sr No",
    B: "Joint No",
    C: "WPS No",
    D: "WPS Rev No",
    E: "Process ",
    F: "Welder Code",
   
  }]


  export let Alphabets = {
    A: "A",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    H: "H",
    I: "I",
    J: "J",
    K: "K",
    L: "L",
    M: "M",
    N: "N",
    O: "O",
    P: "P",
    Q: "Q",
    R: "R",
    S: "S",
    T: "T",
    U: "U",
    V: "V",
    W: "W",
    X: "X",
    Y: "Y",
    Z: "Z",
  }

  export const Date_Input = ({
    label,
    className,
    name,
    sx,
    value,
    todayDate,
    setobj,
    obj,
    datevalue,
    setdatevalue,
    divClass,
    status,
    message,
    maxDate,
    disabled,
    readOnly,
    setError,
    handleDateChange,
    onChange,
    mindate,
    fromdate,
    todate,
    form,
    WI_Last_Weld
  }) => {
    

  
    return (
      <>
  <div className="groove_date">
  <div class={divClass}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label={label}
          className={className}
          format="DD-MM-YYYY"
          name={name}
          sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy", zIndex: "0" }}         
           disabled={disabled}
          readOnly={readOnly}
          value={
            datevalue === false || obj[name] === ""  ? 
            null :
            obj && obj[name] || WI_Last_Weld === true
              ? dayjs(obj[name])
              : dayjs(todayDate)
          }
          maxDate={dayjs(maxDate)}
          onChange={(e) => {
            

            let date = dayjs(e).format("YYYY-MM-DD");

            setobj((prev) => ({
              ...prev,
              [name]: date,
            }));
            form(false)


            if(name === "WI_From_Date"){
              if (todate){
                if( date > todate){
               
               setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: true,
                 
                },
              }));
              toast.warning("Selected date cannot be greater than To date", {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
        

               setobj((prev) => ({
                 ...prev,
                 [name]: date,
               }));
            }
          }
            else{


              setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: false,
                  message: "",
                },
              }));
            }
            }

            if(name === "WI_To_Date"){
              if (date < fromdate){
              
               setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: true,
                  
                },
              }));
              toast.warning("Selected date cannot be less than From date", {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
               setdatevalue(false);

            }
            else{
              setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: false,
                  message: "",
                },
              }));
              


            }
            }

    
            if(name !== "WI_To_Date" && name !== "WI_From_Date" ){
            setobj((prev) => ({
              ...prev,
              [name]: date,
            }));

            setError((prevState) => ({
              ...prevState,
              [name]: {
                status: false,
                message: "",
              },
            }));
          }
          
          }}
      
          
          
            
        />
      </DemoContainer>
    </LocalizationProvider>
  </div>
</div>
<div className="required-text-select mt-2">
                {status && (
                  <Form.Text className="text-danger">
                    {message}
                  </Form.Text>
                )}
              </div>


</>
    )}


    export const Date_InputDisabled = ({
      label,
      className,
      name,
      sx,
      value,
      todayDate,
      setobj,
      obj,
      datevalue,
      setdatevalue,
      divClass,
      status,
      message,
      maxDate,
      disable,
      readOnly,
      setError,
      handleDateChange,
      onChange,
      mindate,
      fromdate,
      todate,
      form,
      WI_Last_Weld,
    }) => {




  
    
      return (
        <>
    <div className="groove_date">
    <div class={divClass}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={label}
            className={className}
            format="DD-MM-YYYY"
            name={name}
            sx={{ fontSize: "6px", fontFamily: "GE-BodyCopy,sans-serif", zIndex: "0" }}            disabled
            // readOnly={readOnly}
            value={
              datevalue === false || obj[name] === ""  ? 
              null :
              obj && obj[name] || WI_Last_Weld === true
                ? dayjs(obj[name])
                : dayjs(todayDate)
            }
            maxDate={dayjs(maxDate)}
            onChange={(e) => {
              

              let date = dayjs(e).format("YYYY-MM-DD");
  
              setobj((prev) => ({
                ...prev,
                [name]: date,
              }));
              form(false)
  
  
              if(name === "WI_From_Date"){
                if (todate){
                  if( date > todate){

                 setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: true,
                    // message: "Selected date cannot be greater than To date",
                  },
                }));
                toast.warning("Selected date cannot be greater than To date", {
                  position: toast.POSITION.TOP_LEFT,
                  className: "toast-message",
                });
                //  setdatevalue(false);

  
                 setobj((prev) => ({
                   ...prev,
                   [name]: date,
                 }));
              }
            }
              else{
  
  
                setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: false,
                    message: "",
                  },
                }));
              }
              }
  
              if(name === "WI_To_Date"){
                if (date < fromdate){

                 setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: true,
                    // message: "Selected date cannot be less than From date",
                  },
                }));
                toast.warning("Selected date cannot be less than From date", {
                  position: toast.POSITION.TOP_LEFT,
                  className: "toast-message",
                });
                 setdatevalue(false);

              }
              else{
                setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: false,
                    message: "",
                  },
                }));
                
  
  
              }
              }
  
      
              if(name !== "WI_To_Date" && name !== "WI_From_Date" ){
              setobj((prev) => ({
                ...prev,
                [name]: date,
              }));
  
              setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: false,
                  message: "",
                },
              }));
            }
            
            }}
        
            
              
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  </div>
  <div className="required-text-select mt-2">
                  {status && (
                    <Form.Text className="text-danger">
                      {message}
                    </Form.Text>
                  )}
                </div>
  
  
  </>
      )}