import { Row, Col } from "react-bootstrap";
export const WPQHeader = ({ headerObj }) => {
  return (
    <Row style={{ padding: "20px 60px", backgroundColor: "var(--bg-alt-2)" }}  className="WPQ-Page-row-padding">
      <Row className="mb-4">
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>Welder Id</small>
            <strong>{headerObj?.WPQ_WelderId}</strong>
          </div>
        </Col>
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>Welder Name</small>
            <strong>{headerObj?.WPQ_WelderName}</strong>
          </div>
        </Col>
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>Welding Date</small>
            <strong>{headerObj?.WPQ_Date}</strong>
          </div>
        </Col>
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>WPQ Record Number</small>
            <strong>{headerObj?.WPQ_RecordNo}</strong>
          </div>
        </Col>
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>Status</small>
            <strong>{headerObj?.WPQ_Status}</strong>
          </div>
        </Col>
        <Col md={2}>
          <div className="d-flex flex-column">
            <small>Last Weld Date</small>
            <strong>{headerObj?.WPQ_Last_UpdatedDate}</strong>
          </div>
        </Col>
      </Row>
    </Row>
  );
};
