import { Row, Col, Form } from "react-bootstrap";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  AddNewCountWPQ,
  AddNewGrooveWPQ,
} from "../../Services/wpq_groove-services";
import { useLocation } from "react-router-dom";
import {

  isAlphanumericAllSpecialCharacters
  
} from "../../functions/validations";
import { getPresignedURL } from "../../Services/Welder-services";

import defaultimg from "../../icons/noimage.png";
import { AddLogs } from "../../Services/wps-logs-services";
import { Single_Input } from "../utility/CommonComponents/Inputs_Transaction"
import { Single_Input_Superviser_remark } from "./WPQObjects/WPQ_Process_Grid";
import { IsUserMasterBand, getAllUserMaster } from "../../Services/user-service";
import {
  MessageTypes,
  WPQPage3,

  WPQPage1,

  WPQ_Groove_Detail,


} from "./WPQObjects/WPQ_entity";
import { WPQHeader } from "./WPQObjects/WPQHeader";
import { Action, Status } from "./WPQObjects/WPQ_entity";
import { WPQ_TEST_Table } from "./WPQObjects/WPQ_TEST_Table";
import { checkTestAdded } from "./WPQObjects/WPQ_Validations";

import { Date_InputUser } from "../utility/CommonComponents/Date_Component";
import { SearchSelectProject } from "../utility/search-select";
import { emptyError } from "./WPQObjects/WPQ_common";
import { errorstyle, normalstyle } from "../../functions/welding-functions";
import { DotLoader } from "react-spinners";




const Test_and_Certificate = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      testObj,
      detailObj,
      setdetailObj,
      setTestObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      isReadonly,
      isDisabled,
      setIsReadonly,
    },
    ref
  ) => {
    const location = useLocation();

    let userprofile = sessionStorage.getItem("UserProfile");
    let Band = sessionStorage.getItem("Band");
    let username = sessionStorage.getItem("UserName");

    let folderwld = "wld_signatures";
    let folderwpq = "WPQ_Groove";

    let folderName2 = "wps_groove";
    let folderName3 = "wpq_groove";

    let formattedDate
    let error_message

    const avatar = { defaultimg };
    const [action, setaction] = useState(location.state?.action);
    const [loading, setLoading] = useState(false);

   

    const [WPStype, setWPStype] = useState(location.state?.WPStype);

    const [users, setUsers] = useState(null);
    const [IsEdited, setIsEdited] = useState(false);

    const [userApproveSignature, setuserApproveSignature] = useState(null);

    const [checkwpsdata, setcheckwpsdata] = useState([]);

    const [previewUrl, setPreviewUrl] = useState([]);
    const [previewuser, setPreviewuser] = useState( headerObj?.wpq_signaturePrepared
      ? headerObj?.wpq_signaturePrepared
      : avatar.defaultimg);
    const [previewapproved, setPreviewapproved] = useState(headerObj?.wpq_signatureApproved
      ? headerObj?.wpq_signatureApproved
      : avatar.defaultimg);

    const [today, setToday] = useState("");

    let array = headerObj?.testarray;

 


    const handleChange = async (e) => {

      if (action === Action.ActionEdit) setIsEdited(true);

      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
   

    const handleChangeRadio = async (e) => {

      if (action === Action.ActionEdit) setIsEdited(true);
      if (!(await validate(detaildata))) {

      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    else{
      alert("Cannot Approve or Reject the Record, Please Fill Required Fields of all 3 Pages.")
    }
    };


    const fetchUserMaster = async () => {
      
      //change
      const result = await getAllUserMaster();

      const active = result.filter((item) => item.um_status === "Active")
      setUsers(active);

    };



    const fetchUserId = async () => {
      
      try {
          // Ensure users and headerObj are defined and have the necessary properties
          if (!headerObj || !headerObj.WPSH_ApprovedBy_Name) {
              console.error("users or headerObj.WPSH_ApprovedBy_Name is not defined");
              return;
          }
  
          // Filter users to find the one matching the approved name
          const Approveduserid = users?.filter((item) => item?.um_name === headerObj?.WPSH_ApprovedBy_Name);

  
          // Check if the user was found
          if (Approveduserid.length === 0) {
              console.error("No user found with the approved name");
              return;
          }
  
          // Update the user approval signature if data is returned
          if (Approveduserid && Approveduserid.length > 0) {
              setuserApproveSignature(Approveduserid[0]);
          } else {
              console.error("No data returned from getbyuserid");
          }
      } catch (error) {
          console.error("An error occurred:", error);
      }
  };


  useEffect(() => {
    fetchUserMaster();
  }, []);

  useEffect(() => {
    
    fetchUserId();
  }, [users, headerObj?.WPSH_ApprovedBy_Name]);

    const handleChangetestarray = (Index, key, newValue) => {
      setIsEdited(true)
      const updatedheadertestObj = { ...headerObj };

      updatedheadertestObj.testarray[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const [error, setError] = useState({
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_ApprovedBy_Name: {},
      WPSH_PreparedBy_Remark: {},
      WPSH_ApprovedBy_Remark: {},

      WPQ_WP_TypeOfTest: [],
      WPQ_WP_Acceptance_Criteria: [],
      WPQ_WP_Result: {},
      WPQ_WP_Comments: {},
      WPQ_WP_Report_Nummber: {},

      WPQ_Notes: {},
      WPQ_Certi_test_conductedby: {},
      WPQ_Certi_Mechanical_Tesstby: {},
      WPQ_Certi_RT_Film_Rvaluatedby: {},
    });



    const validate = async (detaildata) => {
      
      var errortype =
        WPStype === "Groove" ? WPQ_Groove_Detail : WPQ_Groove_Detail;
      let entries1 = Object.entries(WPQPage1);
      let entries2 = Object.entries(detaildata);
      let entries3 = Object.entries(WPQPage3);
      let isError = false;
     
      entries1.map(([key, val]) => {
        if (headerObj[key] === "") {
        
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
      });

      entries3.map(([key, val]) => {
        if (headerObj[key] === "") {

          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
      });
      if (Band === "T" || Band === "P") {
        if (headerObj.WPSH_ApprovedBy_Remark === "") {
        
          isError = true;
          error.WPSH_ApprovedBy_Remark = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error.WPSH_ApprovedBy_Remark = {
            status: false,
            message: "",
          };
        }
      }
      errortype.forEach((cols) => {
        entries2.forEach(([key, val]) => {
         

          let value = "";
          var value1 = "";

          var msg = "";
          if (cols.Type === "S") {
            value = detailObj[key][cols.Column][0].Actual_Value[0];
            value1 = detailObj[key][cols.Column][1].Range_Qualified[0];

            msg = `This field is required`;
            //  isError = true
            // isError =false
          }
          if (cols.Type === "SA") {

            value = detailObj[key][cols.Column][0].Actual_Value[0];
            value1 = detailObj[key][cols.Column][1].Range_Qualified[0].Range_Qualified1[0];

            msg = `This field is required`;

            if (
              value === undefined ||
              value === "" ||
              value === null || 
              value1 === undefined ||
              value1 === "" ||
              value1 === null 
            
            ) {
              isError = true;
           
  
              setError((prevState) => ({
                ...prevState,
  
                [cols.Column]: [
                  {
                    Actual_Value: [
                      {
                        status: true,
                        message: msg,
                      },
                    ],
                  },
                  {
                    Range_Qualified: [
                      {
                        status: true,
                        message: msg,
                      },
                    ],
                  },
                  
                ],
  
              }));
            } else {
              setError((prevState) => ({
                ...prevState,
                [cols.Column]: [
                  {
                    Actual_Value: [
                      {
                        status: false,
                        message: "",
                      },
                    ],
                  },
                  {
                    Range_Qualified: [
                      {
                        status: false,
                        message: "",
                      },
                    ],
                  },
                  
                ],
              }));
            }
          }

          if (
            value === undefined ||
            value === "" ||
            value === null ||
            value1 === undefined ||
            value1 === "" ||
            value1 === null
          ) {
            isError = true;
            
            

            setError((prevState) => ({
              ...prevState,

              [cols.Column]: [
                {
                  Actual_Value: [
                    {
                      status: true,
                      message: msg,
                    },
                  ],
                },
                {
                  Range_Qualified: [
                    {
                      status: true,
                      message: msg,
                    },
                  ],
                },
              ],
              // dataaaa: {
              //   status: true,
              //   message: msg,
              // },
            }));
          } else {
            setError((prevState) => ({
              ...prevState,
              // ...error,
              [cols.Column]: [
                {
                  Actual_Value: [
                    {
                      status: false,
                      message: "",
                    },
                  ],
                },
                {
                  Range_Qualified: [
                    {
                      status: false,
                      message: "",
                    },
                  ],
                },
              ],
            }));
          }
        });
      });

         
      let norows = checkTestAdded(array);
      

      if (norows) {
        array[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        array[0].error = true;

        if (
          array[0].WPQ_WP_TypeOfTest !== "" &&
          array[0].WPQ_WP_Acceptance_Criteria !== ""
        ) {
          array[0].error = false;
        }
      }

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const handleCount = () => {
      
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.WPSH_Status === Status.StatusApproved) {


          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCountWPQ(headerObj);
        }
      }
    };




    const handleAdd = async (approvalstatus) => {

      let result = false;
      if (
        checkwpsdata !== undefined ||
        checkwpsdata !== "" ||
        checkwpsdata !== null
      ) {
        headerObj.WPS_Key = checkwpsdata?.SK;
      }

      if (headerObj?.WPSH_Status === Status.StatusApproved) {
        handleCount();
      }


      if (
        headerObj?.WPSH_Approval_Status !== Status.StatusTempSaved &&
        headerObj?.WPSH_Approval_Status !== undefined
      )
        headerObj.WPSH_Status = headerObj?.WPSH_Approval_Status;
      else headerObj.WPSH_Status = approvalstatus;


      if (Band === "P" || Band === "T") {
        headerObj.wpq_signatureApproved = headerObj.wpq_signatureApproved;
        headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name;
        headerObj.WPSH_ApprovedBy =  headerObj.WPSH_ApprovedBy_Name
      }
      if (Band !== "T" || Band !== "P") {
        headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
        headerObj.wpq_signaturePrepared = headerObj.wpq_signaturePrepared;
      }



      let PG1Add = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        WPQ_RecordNo: headerObj.WPQ_RecordNo,
        WPQ_WelderId:headerObj.WPQ_WelderId,
        WPSH_Status: headerObj.WPSH_Status,
        testarray: headerObj.testarray

      };

      let PG1Update = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        WPQ_RecordNo: headerObj.WPQ_RecordNo,
        WPQ_WelderId:headerObj.WPQ_WelderId,
        WPSH_Status: headerObj.WPSH_Status,
        WPQ_Notes: headerObj.WPQ_Notes,
        WPQ_Certi_Mechanical_Tesstby: headerObj.WPQ_Certi_Mechanical_Tesstby,
        WPQ_Certi_RT_Film_Rvaluatedby: headerObj.WPQ_Certi_RT_Film_Rvaluatedby,
        WPQ_Certi_test_conductedby: headerObj.WPQ_Certi_test_conductedby,
        WPSH_PreparedBy_Name:headerObj.WPSH_PreparedBy_Name,
        WPSH_PreparedBy_Remark:headerObj.WPSH_PreparedBy_Remark,
        WPSH_PreparedBy_Date:headerObj.WPSH_PreparedBy_Date,
        WPSH_ApprovedBy_Name:headerObj.WPSH_ApprovedBy_Name,
        WPSH_ApprovedBy_Remark:headerObj.WPSH_ApprovedBy_Remark,
        WPSH_ApprovedBy_Date:headerObj.WPSH_ApprovedBy_Date,
        wpq_signatureApproved:headerObj.wpq_signatureApproved,
        wpq_signaturePrepared:headerObj.wpq_signaturePrepared,
        WPSH_Status:headerObj.WPSH_Status,
        Process_Status:headerObj.Process_Status
      };


    


      try {

      let res = await AddNewGrooveWPQ(headerObj)
    
      if(res !== undefined){
       
        
          result = true;
         
          if (
            action === Action.ActionEdit &&
            approvalstatus !== Status.StatusTempSaved
          ) {
            await AddLogs(logObj);
            
          }
           }  
        else{
          alert(process.env.REACT_APP_SPECIAL_CHARACTER_MSG);
        }
      }
        catch (error) {
          console.error("Error adding header:", error);
        }
      
      return result;

    };

    const checkIfUsernameExists = async () => {
      
    
      let res = await IsUserMasterBand();
      
      if (res !== undefined) return res.data.message;
      else return false;
    };

    

    const validateTemp = async () => {
      debugger
      let isError = false;

       let temperror = emptyError(error, setError, isError);

      return {isError ,temperror};
    };


    const handleSubmitTemp = async () => {
      debugger
      let result = false;
      var val = await validateTemp();


          if (val.isError === false){
          if ( val.temperror === false) {
            // setIsEdited(false);
            setLoading(true);

              result = handleAdd(headerObj?.WPSH_Status);
              setLoading(false);


            if (result) {
              return MessageTypes.Temp;
            }
           
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;

        
   
  }

    var detaildata = testObj.filter((item) => item.WPSD_Process !== "");


    const handleSubmit = async () => {
      setIsEdited(false);


      var detaildata = detailObj.filter((item) => item.WPQ_Process !== "");


      let result = false;
      const isUsernamePresent = await checkIfUsernameExists();
      
      if (isUsernamePresent) {
        headerObj.WPSH_Status = Status.StatusTempSaved;
      }

      //Va;idate All pages
      if (!(await validate(detaildata))) {
        setLoading(true);

        result = await handleAdd(Status.StatusPending);
        setLoading(false);

        if (result) {
      
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
          
        }
      } else {
     
        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data Before Leaving this Page")
          }
        }, 500);
       
        return ([MessageTypes.RequiredAll, error_message, headerObj?.wld_WPSType]);
        
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
       
      },

      funcOnNextTemp: async () => {
        var res = false;
    
        res = await handleSubmitTemp();
   
        return res;
      },
    }));

    const fetchImages = async (item) => {
      

      try {
        
        let unique = headerObj?.WPSH_Project_No + "_" + headerObj?.WPSH_WPS_No;
        if (item.value !== "" || item.value !== undefined) {
          const result = await getPresignedURL(folderwpq, item.value);
          const geturl = result.url;

          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          // await axios.get(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      
      if (headerObj?.wld_pdf_image) {
        for (let item of headerObj?.wld_pdf_image) {
          fetchImages(item);
        }
      }
    }, []);

  

    const fetchbyuserprofile = async (userprofile) => {
      try {
     
        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);
   

          const geturl = result.url;
          setPreviewuser(geturl);
         
        }
        else{
          setPreviewuser(avatar.defaultimg);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

//common function
    const fetchapproveUserProfile = async (userprofile) => {
      try {
      
        if (userprofile !== "") {
          const result = await getPresignedURL(folderwld, userprofile);
          const geturl = result.url;
          setPreviewapproved(geturl);
        }
        else{
          setPreviewapproved(avatar.defaultimg);
        }

      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };
 

    useEffect(() => {
      
      const currentDate = new Date();
      formattedDate = new Date().toLocaleDateString("en-GB")
      setToday(formattedDate);

    }, []);

    useEffect(() => {
      
            if(action === Action.ActionEdit && headerObj?.wpq_signatureApproved !== ""){
             
              fetchbyuserprofile(headerObj?.wpq_signaturePrepared)
            }
      
            if(action === Action.ActionEdit && headerObj?.wpq_signatureApproved !== ""){
            
            fetchapproveUserProfile(headerObj?.wpq_signatureApproved)
            }
      
          }, []);

  




    return (
      <Row className="font">
        {loading && (
      <div className="loader-overlay">
        <DotLoader size={80} className="pageloader" />
      </div>
    )}
        <WPQHeader headerObj={headerObj}></WPQHeader>

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative",top:"10px"}}>Tests </p>
             
              <WPQ_TEST_Table
                array={array}
                setheaderObj={setheaderObj}
                setError={setError}
                status={error.WPQ_WP_TypeOfTest.status}
                message={error.WPQ_WP_TypeOfTest.message}
                status1={error.WPQ_WP_Acceptance_Criteria.status}
                message1={error.WPQ_WP_Acceptance_Criteria.message}
                status2={error.WPQ_WP_Result.status}
                message2={error.WPQ_WP_Result.message}
                status3={error.WPQ_WP_Comments.status}
                message3={error.WPQ_WP_Comments.message}
                status4={error.WPQ_WP_Report_Nummber.status}
                message4={error.WPQ_WP_Report_Nummber.message}
                // ProcessActive={ProcessActive}
                readOnly={isReadonly}
                handleChangetestarray={handleChangetestarray}
              />
            </Col>
          </Row>

          <Row style={{ padding: "20px 0px" }}>
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Notes</span>
            <Col md={12}>
              <div class="T-input w-100">
                <textarea
                  type="text"
                  name="WPQ_Notes"
                  style={{ height: "100px" }}
                  id="WPQ_Notes"
                  readOnly={isReadonly}
                  maxLength={90}
                  placeholder=" "
                  className={
                    error.WPQ_Notes.status
                      ? "T-input placeHolderInput inputText text-field-with-error"
                      : "T-input placeHolderInput inputText"
                  }
                  value={headerObj?.WPQ_Notes}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Notes: error,
                    }));
                  }}
                />
                <label for="WPQ_Notes">Notes</label>
              </div>
              <div className="required-text1">
                {error.WPQ_Notes.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Notes.message}
                  </Form.Text>
                )}
              </div>
            </Col>
          </Row>

          <Row style={{padding: "20px 0px" }} className="PQR-Page-row-padding">
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Certification</span>
            <Col md={6}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Certi_test_conductedby"
                  type="text"
                  maxLength={30}
                  readOnly={isReadonly}
                  status={error.WPQ_Certi_test_conductedby.status}
                  value={headerObj?.WPQ_Certi_test_conductedby}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="Test Conducted By"
                  message={error.WPQ_Certi_test_conductedby.message}
                  
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Certi_Mechanical_Tesstby"
                  type="text"
                  maxLength={30}
                  status={error.WPQ_Certi_Mechanical_Tesstby.status}
                  value={headerObj?.WPQ_Certi_Mechanical_Tesstby}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  readOnly={isReadonly}
                  label="Mechanical Test By"
                  message={error.WPQ_Certi_Mechanical_Tesstby.message}
                  
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Certi_RT_Film_Rvaluatedby"
                  type="text"
                  maxLength={82}
                  readOnly={isReadonly}
                  status={error.WPQ_Certi_RT_Film_Rvaluatedby.status}
                  value={headerObj?.WPQ_Certi_RT_Film_Rvaluatedby}
                  onChange={handleChange}
                  setError={setError}
                  error={error}
                  label="RT Film Evaluated By"
                  message={error.WPQ_Certi_RT_Film_Rvaluatedby.message}
                  
                ></Single_Input>
              </Form.Group>
            </Col>
          </Row>
{/* ---------------prepared by---------------------------- */}


         <Row style={{ padding: "20px 13px" }}>
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">Supervised By - Welding Engineer</span>
              </Col>
              <Col style={{paddingLeft:"101px"}}>
                <span className="WPS-blue-text"> Approved By - Welding Engineer</span>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_PreparedBy_Name"
                      name="WPSH_PreparedBy_Name"
                      className={
                        error.WPSH_PreparedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_PreparedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      
                      valueField={"um_name"}
                    
                      value={headerObj?.WPSH_PreparedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: prename.value,
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                  
                        fetchbyuserprofile(a[0].wld_profile);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: a[0].um_name,
                          wpq_signaturePrepared: a[0].wld_profile,
                          WPSH_PreparedBy: a[0].SK,
                        }));

                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_PreparedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PreparedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>



              <Col md={3}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
                                  //  todayDate={todayDate}
                  // maxDate={maxDate}
                  //  disabled={Readonly}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                 
                />
              </Col>

              <Col md={3} style={{position:"relative",left:"38px"}}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_ApprovedBy_Name"
                      name="WPSH_ApprovedBy_Name"
                      className={ error.WPSH_ApprovedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_ApprovedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      
                      valueField={"um_name"}
                     
                      value={headerObj?.WPSH_ApprovedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: prename.value,
                        }));
                        

                      
                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                      
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: a[0].um_name,
                          wpq_signatureApproved: a[0].wld_profile,
                          WPSH_ApprovedBy: a[0].SK,
                        }));

                        fetchapproveUserProfile(a[0].wld_profile
                        );
                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_ApprovedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_ApprovedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
          
              </Col>

              <Col md={3} style={{position:"relative", left:"30px"}}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
                                  //  todayDate={todayDate}
                  // maxDate={maxDate}
                  //  disabled={Readonly}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
              
                />
              </Col>

              <Row className="mb-4" >
                <Col style={{position:"relative", left:"13px"}}>
                  <span>Signature Image</span>
                </Col>
                <Col style={{position:"relative", left:"57px"}}>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row style={{position:"relative", bottom:"10px"}}>
                <Col md={6}style={{position:"relative", left:"14px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>

                <Col md={6} style={{position:"relative", left:"58px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
              </Row>
            
              <Row className="mb-4" style={{padding:"0px 15px",position:"relative", bottom:"10px",paddingTop:"3%"}}>
               
                <Col md={3} style={{width:"50%"}}>
                  <Single_Input_Superviser_remark
                    disabled={copydata}
                    name="WPSH_PreparedBy_Remark"
                    type="text"
                    style={{ height: "90px" }}
                    maxLength={50}
                    readOnly={isReadonly}
                    status={error.WPSH_PreparedBy_Remark.status}
                    value={headerObj?.WPSH_PreparedBy_Remark}
                    handleChange={handleChange}
                    label="Remark"
                    message={error.WPSH_PreparedBy_Remark.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PreparedBy_Remark: error,
                      }));
                    }}
                  ></Single_Input_Superviser_remark>
                </Col>
                
                {Band === "P" || Band === "T" ? (
                  <Col md={3} style={{paddingLeft:"58px",width:"50%"}}>
                    <div class="T-input w-100">
                      <input
                        style={{ height: "90px",width:"112%",position:"relative", left:"6px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        maxLength={200}
                        readOnly={isReadonly}
                       
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        value={headerObj?.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    <div className="required-text1">
                      {error.WPSH_ApprovedBy_Remark.status && (
                        <Form.Text className="text-danger">
                          {error.WPSH_ApprovedBy_Remark.message}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                ) : (
                  <Col md={3} style={{paddingLeft:"58px",width:"50%"}}>
                    <div class="T-input w-100" >
                      <input
                        style={{ height: "90px",width:"112%",position:"relative", left:"6px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        readOnly
                        maxLength={200}
                        
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        value={headerObj?.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                   
                  </Col>
                )}
             
                </Row>
                 {Band === "P" || Band === "T" ? (
                <Col style={{position:"relative",bottom:"40px" ,left:"54%"}}> 
               <span className="WPS-blue-text">Action</span>
             
                <div className="d-flex mb-4" style={{paddingTop:"10px"}}>
                    <div>
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Approved"
                        checked={headerObj?.WPSH_Approval_Status === "Approved"}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Approve</span>
                    </div>
                    <div className="ms-5">
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Rejected"
                        checked={headerObj?.WPSH_Approval_Status === "Rejected"}
                        onChange={(e) => handleChangeRadio(e)}
                     in />
                      <span className="ms-2">Reject</span>
                    </div>
                  </div>
                </Col>
              
              ) : (
                ""
              )}

            </Row>
          </Row>
        </Row>
      </Row> 
    );
  }
);

export default Test_and_Certificate;
