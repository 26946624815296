import React, { useEffect, useState } from "react";
import "./Css/WPQgroove_pdf.css";
import {  Modal } from "react-bootstrap";

import { jsPDF } from "jspdf";

import {
  getPresignedURL,
} from "../../../Services/Welder-services";   



import BarLoader
  from "react-spinners/BarLoader";


import Groove_pdf_page1WPQ from "./Pages/Groove_pdf_page1WPQ";

import { getdetailsById } from "../../../Services/wpq_details-service";
import { getbyrecordno } from "../../../Services/wpq_groove-services";
import WPQTubeToTubesheet_pdf_page1 from "./Pages/WPQTubeToTubesheet_pdf_page1";
import WPQTubeToTubesheet_pdf_page2 from "./Pages/WPQTubeToTubesheet_pdf_page2";

import Jspdf_font from "../../../Assests/font/jsPdf_font";

const Pdf_DownloadWPQ = (props, wld) => {
  let action = props.action;


  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);



  const [PreviewUrl, setPreviewUrl] = useState([]);

  const [previewUrl1, setPreviewUrl1] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  // const [previewUrl3, setPreviewUrl3] = useState();


  const [pdf, setpdf] = useState([]);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");
  let userprofile = sessionStorage.getItem("UserProfile");

  let Token = sessionStorage.getItem("Token");

  let folderwld = "wld_signatures"
  let folderwps = "WPS_Groove"
  let folderwpq = "WPQ_Groove"




  const fetchbyid = async (IddetailPK, IddetailSK) => {
    
    const result = await getdetailsById(IddetailPK, IddetailSK);

    const wpqhead = await getbyrecordno(props.WPQtype, IddetailPK, IddetailSK)

    
    setpdf([...wpqhead, ...result]);

  };



  const createPDF = () => {
    fetchbyid(props?.rowdata?.WPQ_WelderId, props?.rowdata?.WPQ_RecordNo);

  };


  useEffect(() => {
    createPDF();
  }, []);




  const fetchimagespdf = async () => {
    try {
      
      if (props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0] !== "") {
      
        const result = await getPresignedURL(folderwpq, props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0]);
      
        const geturl = result.url;
        setPreviewUrl1(geturl);
       
      }

      if (props?.rowdata?.Pdf_images[1].tube_Hole[0] !== "") {
        
        const result = await getPresignedURL(folderwpq, props?.rowdata?.Pdf_images[1].tube_Hole[0]);
      
        const geturl = result.url;
        setPreviewUrl2(geturl);
      
      }

    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }

  };

 

  const fetchapproveUserProfileswld = async () => {
    try {
     
      
      if (props?.rowdata?.wpq_signaturePrepared !== "" &&  props?.rowdata?.wpq_signaturePrepared != undefined) {
     
        const result = await getPresignedURL(folderwld,
          props?.rowdata?.wpq_signaturePrepared
        );
       
        const geturl = result.url;
        setPreviewuser(geturl);
        
      }

    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchapproveUserProfileswldapproved = async () => {
    try {
    
      if (props?.rowdata?.wpq_signatureApproved !== "" &&  props?.rowdata?.wpq_signatureApproved != undefined) {
       

        const result = await getPresignedURL(folderwld,
          props?.rowdata?.wpq_signatureApproved
        );
      
        const geturl = result.url;
        setPreviewapproved(geturl);



      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const downloadPdf = () => {
    setTimeout(() => {
      let doc = new jsPDF('p', 'pt', [595.28, 1020]);
      doc = Jspdf_font(doc)
      let pdfjs = document.querySelector('#MainDiv');
      doc.html(pdfjs, {

        callback: function (doc) {


          var Filename = `WPQ Report ${pdf[0].wld_WPSType}`
          doc.save(Filename);
          props.onHide();
          props.setReload(!props.reload);
          setLoading(false)

          doc.setFontSize(10);


        }, margin: [-25, 10, 10, 10]


      });
     
    }, 5000);
  }


  useEffect(() => {

    fetchimagespdf()

    fetchapproveUserProfileswld()
    fetchapproveUserProfileswldapproved()
    if (pdf.length > 0) {
      downloadPdf();

     
    }
  }, [pdf]);



  return (

    <>

      {/* {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">

    </Spinner></div>} */}

      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}

            {/* {loading &&  <Spinner animation="border" className="spinner">

</Spinner>}      */}
          </p>
        </Modal.Body>
      </Modal>

      {pdf.length > 0 && (
        <div id="MainDiv">
          <br></br>
          {pdf[0]?.wld_WPSType === "Tubesheet" ? (
            <>
              <WPQTubeToTubesheet_pdf_page1
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                previewUrl1={previewUrl1}
                previewUrl2={previewUrl2}
                // previewUrl3= {previewUrl3}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
            <div style={{backgroundColor:"none", height:"45px", position: "relative" ,top:"10px"}}></div>
             <br></br>
             <WPQTubeToTubesheet_pdf_page2
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
               <div style={{backgroundColor:"none", height:"230px", position: "relative" ,top:"10px"}}></div>
             
           
            </>
          ) : (
            <Groove_pdf_page1WPQ
              pdf={pdf} Band={props.Band}
              setpdf={setpdf}
              previewuser={previewuser}
              previewapproved={previewapproved}
            />
          )}

        </div>
      )}








    </>
  )
}


export default Pdf_DownloadWPQ;
