import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";


export const WPS_Process_Grid_Input = ({
  status,

  message,
 
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
}) => {
 
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              // style={{position:"relative", top:"0px"}}
              style={{
                width: "275px",
           
              }}
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={item[name]}
              readOnly={readOnly}
              disabled={disabled}
              maxLength={maxLength}
              // value={item.WPSD_Process !== "" &&  item.WPSD_Type !== "" ? item.WPSD_WP_MinimumPreheatTemp : "NA"}
              // disabled={ location.state?.action === "Edit" && item.WPSD_WP_MinimumPreheatTemp === ""}
              // readOnly={item.WPSD_Process === "" &&  item.WPSD_Type === ""}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input_Tubesheet = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
}) => {
  
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              // style={{position:"relative", top:"0px"}}
              style={{
                width: "512px",
           
              }}
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={item[name]}
              readOnly={readOnly}
              disabled={disabled}
              maxLength={maxLength}
             
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};



export const WPS_Process_Grid_InputProcess = ({
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  onInput,
  disabled,
  maxLength,
}) => {

  return (
    <tr className={"border WpsInput-md"}>
      <th
        className={"text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
        
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={"border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              style={{width:"275px"}}
              type={type}
              name={name}
              value={"GTAW"}
              readOnly
              disabled={disabled}
              maxLength={maxLength}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_InputProcess_tubesheet = ({
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  onInput,
  disabled,
  maxLength,
}) => {

  return (
    <tr className={"border WpsInput-md"}>
      <th
        className={"text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
       
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={"border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              style={{width:"512px"}}
              type={type}
              name={name}
              value={"GTAW"}
              readOnly
              disabled={disabled}
              maxLength={maxLength}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_3Filermetal = ({
  status,

  message,
 
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,

  handleChangeFillermetal,
  maxLength,
  obj,
  setfunction,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  objbatch,
  setfunctionb,
  fields,
}) => {


  
      
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
              
                name={name + "1"}
                value={item[name][0]}
              
                onChange={(e) => {handleChangeFillermetal(id, 0, name, obj, setfunction, e, metal) 

                }}
                onInput={onInput}
             
              />

              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
              

                name={name + "2"}
                value={item[name][1]}
               
                onChange={(e) => {handleChangeFillermetal(id, 1, name, obj, setfunction, e, metal) 
                }}               
                onInput={onInput}
              />
              <input
                type={type}
             
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
              

                name={name + "3"}
                value={item[name][2]}
              

                onChange={(e) => {handleChangeFillermetal(id, 2, name, obj, setfunction, e, metal) 
                }}    
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_2_Amperes = ({
  status,
  status1,
  item,
  message,
  message1,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  name1,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  obj1,
  setfunction1,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  handleChangeempty
}) => {

  
      
  return (
    <tr className={status || status1  ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status || status1  ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status || status1 && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {



        if (!Array.isArray(item[fieldfiller])) {
          item[fieldfiller] = ["", "", ""];
        }
        if (!Array.isArray(item[name])) {
          item[name] = ["", "", ""];
        }

        if (!Array.isArray(item[name1])) {
          item[name1] = ["", "", ""];
        }


        const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
        const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
        const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;
      
      
        if (isDisabled1) item[name][0] = ""
        if (isDisabled2) item[name][1] = ""
        if (isDisabled3) item[name][2] = ""

        if (isDisabled1) item[name1][0] = ""
        if (isDisabled2) item[name1][1] = ""
        if (isDisabled3) item[name1][2] = ""

        return (
          <td
            className={message || message1 ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                disabled={isDisabled1}
                name={name + "1"}
                value={isDisabled1 ? "" : item[name][0]}
                onChange={(e) =>
                  handleChange(id, 0, name, obj, setfunction, e)              
              }
                onInput={onInput}

              />
              

              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
             disabled={isDisabled2}
                name={name + "2"}
                value={isDisabled2 ? "" : item[name][1]}
             
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "3"}
                disabled={isDisabled3}

                value={isDisabled3 ? "" : item[name][2]}



                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
            <br></br>
            <span style={{position:"relative", left:"100px", bottom:"7px"}}>|</span>
            <span style={{position:"relative", right:"1px", bottom:"7px"}}>|</span>
            <span style={{position:"relative", right:"100px", bottom:"7px"}}>|</span>


            <div className="dInput">
              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status1 ? "error-input" : ""}
                disabled={isDisabled1}
                name={name1 + "1"}
                value={isDisabled1 ? "" : item[name1][0]}
                onChange={(e) =>
                  handleChange(id, 0, name1, obj1, setfunction1, e)              
              }
                onInput={onInput}

              />

              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status1 ? "error-input" : ""}
    
                disabled={isDisabled2}
                name={name1 + "2"}
                value={isDisabled2 ? "" : item[name1][1]}
               
                onChange={(e) => handleChange(id, 1, name1, obj1, setfunction1, e)}
                onInput={onInput}
              />
              <input
                type={type}
               
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status1 ? "error-input" : ""}
              
                name={name1 + "3"}
                disabled={isDisabled3}

                value={isDisabled3 ? "" : item[name1][2]}

           

                onChange={(e) => handleChange(id, 2, name1, obj1, setfunction1, e)}
                onInput={onInput}
              />
            </div>
            
          </td>
          
        );
      })}
    </tr>
  );
};


export const WPS_Process_Grid_Input_3 = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  handleChangeempty
}) => {


  
      
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {



        if (!Array.isArray(item[fieldfiller])) {
          item[fieldfiller] = ["", "", ""];
        }
        if (!Array.isArray(item[name])) {
          item[name] = ["", "", ""];
        }


        const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
        const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
        const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;
      
      
        if (isDisabled1) item[name][0] = ""
        if (isDisabled2) item[name][1] = ""
        if (isDisabled3) item[name][2] = ""

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                disabled={isDisabled1}
                name={name + "1"}
                value={isDisabled1 ? "" : item[name][0]}
                onChange={(e) =>
                  handleChange(id, 0, name, obj, setfunction, e)              
              }
                onInput={onInput}

              />

              <input
                type={type}

                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}

                disabled={isDisabled2}
                name={name + "2"}
                value={isDisabled2 ? "" : item[name][1]}
  
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              <input
                type={type}
        
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}


                name={name + "3"}
                disabled={isDisabled3}

                value={isDisabled3 ? "" : item[name][2]}

 

                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select = ({
  status,
  
  message,

  wps_detail,
  ColumnHeader,
  Columndimension,

  name,

  readOnly,
  array,
  handleChangedetailsarray,

  disabled,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <select
              style={{
                width: "275px",
                height: "30px",

                borderRadius: "3px",
              }}
              className={status ? "error-input" : ""}
              readOnly={readOnly}
              disabled={disabled}
              name={name}
              value={item[name]}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
         
              }}
            >
              {array &&
                array.map((e, id) => {
                  return (
                    <option key={id} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}

            
            </select>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_2 = ({
  status,

  message,
  
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,

  onInput,
  ColumnHeader1,
  name1,
}) => {

  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3" style={{width:"135px"}}>
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-end">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                maxLength={maxLength ? maxLength : 18}
                type={type}
                style={{width:"275px"}}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                value={item[name]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput}
              />

              <input
                maxLength={maxLength ? maxLength : 8}
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                style={{width:"275px"}}
                value={item[name1]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput1}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input_2_Tubesheet = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,

  onInput,
  ColumnHeader1,
  name1,
}) => {

  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3" style={{width:"135px"}}>
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-end">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                maxLength={maxLength ? maxLength : 18}
                type={type}
                style={{width:"512px"}}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                value={item[name]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput}
              />

              <input
                maxLength={maxLength ? maxLength : 8}
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                style={{width:"512px"}}
                value={item[name1]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput1}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select_3 = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,

  name,

  array,
  handleChange,

  setfunction,
  obj,
  fieldfiller,

}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {

if (!Array.isArray(item[fieldfiller])) {
  item[fieldfiller] = ["", "", ""];
}
if (!Array.isArray(item[name])) {
  item[name] = ["", "", ""];
}


const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;


if (isDisabled1) item[name][0] = ""
if (isDisabled2) item[name][1] = ""
if (isDisabled3) item[name][2] = ""

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div>
              <select
                style={{
                  width: "85px",
                  height: "32px",

                  borderRadius: "4px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={isDisabled1 ? "" : item[name][0]}
                disabled={isDisabled1}
                onChange={(e) => {
                  handleChange(id, 0, name, obj, setfunction, e);

                }}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",

                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={isDisabled2 ? "" : item[name][1]}
                disabled={isDisabled2}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",

                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "3"}

                value={isDisabled3 ? "" : item[name][2]}
                disabled={isDisabled3}
                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </td>
        );
      })}
    </tr>
  );
};


export const WPS_Process_Grid_Select_master_data_TTS = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  data,

  name,
  setError,

  valueField,
  labelField,


  setdetailObj,
  detailObj,

}) => {
  const handleChange = (id, index, name, obj, setfunction, e) => {
    let selectedItem = data.find(b => b[valueField] === e.target.value);

    let updatedItem = {
      ...obj[id],
      [name]: [
        ...(obj[id][name] || []),
      ],
      welddesc: [
        ...(obj[id].welddesc || []),
      ],
      weldweight: [
        ...(obj[id].weldweight || []),
      ],
    };

    updatedItem[name][index] = e.target.value;


    if (e.target.value === "") {
      updatedItem.welddesc[index] = "";
      updatedItem.weldweight[index] = "";
    } else if (e.target.value === "NA") {
      updatedItem.welddesc[index] = "NA";
      updatedItem.weldweight[index] = "NA";
    } else {
      updatedItem.welddesc[index] = selectedItem?.wld_description || "";
      updatedItem.weldweight[index] = selectedItem?.wld_weight || "";
    }


    setfunction(prevDetailObj => {
      const updatedDetailObj = [...prevDetailObj];
      updatedDetailObj[id] = updatedItem;
      return updatedDetailObj;
    });

    setError((prevState) => ({
      ...prevState,
      [name]: { status: false, message: "" },
    }));

   
  };

  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th className={status ? "text-start thead-color" : "text-start thead-color"}>
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {

        return (
          <td
            className={message ? "border WPSSelect error " : "border WPSSelect "}
            key={id}
          >
            <div>
              {[0, 1].map((index) => (
                <select
                  key={index}
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                    marginRight: "10px",
                    marginLeft: index === 0 ? "10px" : "0",
                  }}
                  className={status ? "error-input" : ""}
                  name={`${name}${index + 1}`}
                  value={item[name] && item[name][index]}
                  onChange={(e) => handleChange(id, index, name, detailObj, setdetailObj, e)}
                >
                  {data &&
                    data.map((e, idx) => (
                      <option key={idx} value={e[valueField]}>
                        {e[valueField] === "" ? "Select" : e[labelField]}
                      </option>
                    ))}
                </select>
              ))}
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Select_master_data = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  data,

  name,
  setError,

  valueField,
  labelField,

  setdetailObj,
  detailObj,

  fieldfiller

}) => {
  const handleChange = (id, index, name, obj, setfunction, e) => {
    let selectedItem = data.find(b => b[valueField] === e.target.value);

    let updatedItem = {
      ...obj[id],
      [name]: [
        ...(obj[id][name] || []),
      ],
      welddesc: [
        ...(obj[id].welddesc || []),
      ],
      weldweight: [
        ...(obj[id].weldweight || []),
      ],
    };

    updatedItem[name][index] = e.target.value;


    if (e.target.value === "") {
      updatedItem.welddesc[index] = "";
      updatedItem.weldweight[index] = "";
    } else if (e.target.value === "NA") {
      updatedItem.welddesc[index] = "NA";
      updatedItem.weldweight[index] = "NA";
    } else {
      updatedItem.welddesc[index] = selectedItem?.wld_description || "";
      updatedItem.weldweight[index] = selectedItem?.wld_weight || "";
    }

    // Store the updated item in state
    setfunction(prevDetailObj => {
      const updatedDetailObj = [...prevDetailObj];
      updatedDetailObj[id] = updatedItem;
      return updatedDetailObj;
    });

    setError(prevState => ({
      ...prevState,
      [name]: { status: false, message: "" },
    }));
  };

  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th className={status ? "text-start thead-color" : "text-start thead-color"}>
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {


if (!Array.isArray(item[fieldfiller])) {
  item[fieldfiller] = ["", "", ""];
}
if (!Array.isArray(item[name])) {
  item[name] = ["", "", ""];
}


const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;


if (isDisabled1) item[name][0] = ""
if (isDisabled2) item[name][1] = ""
if (isDisabled3) item[name][2] = ""

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div>
              {[0, 1, 2].map((index) => (
                <select
                  key={index}
                  style={{
                    width: "85px",
                    height: "32px",
                    borderRadius: "4px",
                    marginRight: "10px",
                    marginLeft: index === 0 ? "10px" : "0",
                  }}
                  className={status ? "error-input" : ""}
                  name={`${name}${index + 1}`}
                  value={item[name] && item[name][index]}
                  onChange={(e) => handleChange(id, index, name, detailObj, setdetailObj, e)}
                  disabled={item[fieldfiller]?.[index] === "" || item[fieldfiller]?.[index] === undefined}

                >
                  {data &&
                    data.map((e, idx) => (
                      <option key={idx} value={e[valueField]}>
                        {e[valueField] === "" ? "Select" : e[labelField]}
                      </option>
                    ))}
                </select>
              ))}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_Horizon_2 = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};



export const WPS_Grid_Input_2 = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px", width:"131px"}}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", width:"131px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Grid_Input_2_Tubesheet = ({
  status,

  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px", width:"250px"}}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", width:"250px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
